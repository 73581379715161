import React from 'react';
import PropTypes from 'prop-types';

import CSecBase from './CSecBase';

import Usertypes from '../../util/Usertypes';
import AuthService from '../../util/authentication/auth_service';

const CSecPageUpdateTimeInfo = ({
    str_duration
}) => {
    
    return (
        Usertypes.student !== AuthService.getUsertype() &&
        <CSecBase b_hasCenteredText={true} str_classes='page-update-info-bar no-margin-bottom'>
            <p>
                Info: Es kann bis zu {str_duration} dauern, bis die Änderungen deiner 
                Einstellungen hier aktualisiert sind.
            </p>
        </CSecBase>
    )
}

CSecPageUpdateTimeInfo.propTypes = {
    str_duration: PropTypes.string
}

export default CSecPageUpdateTimeInfo
