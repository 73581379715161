import React, { useState, useEffect } from 'react';

import BoolField from '../fields/BoolField';
import SingleChoiceField from '../fields/SingleChoiceField';
import ContactPersonFormComponent from './ContactPersonFormComponent';

import {
    useInputData,
    useChangeData
} from '../context-provider/InputFormContextProvider';
import { triggerFieldErrors } from '../../../util/dom_interaction';
import { IndustryJobFormData as FormData } from '../util/form-config-data/InputFormIndustryDataConfigs';

const CONTACT_PERSON_FIELD_IDS = [
    'firstName',
    'lastName',
    'jobPosition',
    'workLocation',
    'phone',
    'email',
    'linkedin'
];

const USE_CONTACT_PERSON_ID = 'useCompanyContactPersonAsJobContactPerson';

const JobFormContactPersonFormComponent = () => {
    
    const [errorCache, setErrorCache] = useState() /* Track errors of contact person input fields. */
    const data = useInputData()
    const changeData = useChangeData()

    useEffect(() => {
        const useAccountContactPerson = data[FormData.fields.useCompanyContactPersonAsJobContactPerson.str_id][0]
        if (useAccountContactPerson) {
            /* Turn off error of contact person input fields in IFCP.js. */
            let contactData = {}
            let errorCache = {}
            CONTACT_PERSON_FIELD_IDS.forEach(fieldId => {
                contactData[fieldId] = [data[fieldId][0], false]
                errorCache[fieldId] = [data[fieldId][0], data[fieldId][1]]
            })
            changeData(contactData)
            setErrorCache(errorCache)
        } else if (errorCache) {
            let contactData = {}
            CONTACT_PERSON_FIELD_IDS.forEach(fieldId => {
                contactData[fieldId] = errorCache[fieldId]
            })
            triggerFieldErrors(CONTACT_PERSON_FIELD_IDS, FormData.fields.useCompanyContactPersonAsJobContactPerson.str_id)
            changeData(contactData)
        }
    }, [data[USE_CONTACT_PERSON_ID]])

    return (
        <>
            <BoolField
                {...FormData.fields.useCompanyContactPersonAsJobContactPerson}
                b_boolValue={data[FormData.fields.useCompanyContactPersonAsJobContactPerson.str_id][0]}
            />
            <SingleChoiceField
                {...FormData.fields.numContactPerson}
                str_currentOption={data[FormData.fields.numContactPerson.str_id][0]}
                b_isFieldDisplayed={data[FormData.fields.useCompanyContactPersonAsJobContactPerson.str_id][0]}
            />
            <ContactPersonFormComponent
                obj_fields={FormData.fields}
                b_areFieldsDisplayed={!data[FormData.fields.useCompanyContactPersonAsJobContactPerson.str_id][0]}
            />
        </>
    )
}

export default JobFormContactPersonFormComponent
