import React from 'react';
import PropTypes from 'prop-types';

import CSecGridBase from '../content-sections/CSecGridBase';
import CardAbo from '../cards/CardAbo';

const AboExtensionCompany = ({
    str_title,
    b_isProfilePage=false
}) => {
    return (
        <CSecGridBase
            str_title={str_title}
            str_gridClass='pp-abo-card-grid'
            str_secBaseClasses={b_isProfilePage ? 'no-padding' : ''}
        >
            <div className="grid-item">
                <CardAbo
                    nbr_extension={6}
                    str_extensionUnit={'Monate'}
                    nbr_price={600}
                    str_url={'/abo-extension/payment/6'}
                />
            </div>
            <div className="grid-item">
                <CardAbo
                    nbr_extension={12}
                    str_extensionUnit={'Monate'}
                    nbr_price={1080}
                    str_url={'/abo-extension/payment/12'}
                />
            </div>
        </CSecGridBase>
    )
}

AboExtensionCompany.propTypes = {
    str_title: PropTypes.string,
    b_isProfilePage: PropTypes.bool
}

export default AboExtensionCompany
