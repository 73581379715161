/**
 * Every component that want to use a sidebar must have this component
 * and the <Sidebar> component. 
 * This component provides the functionality for the animations whereas
 * <Sidebar> provides the HTML and styling. 
 * 
 * Every sb item that should be able to call the animatino function 
 * must import the hook - useRunAnimationContext() - seen below. 
 * It must then be used as part of an onClick event on that sb item.
 * See the profile page sb items as examples. 
 * 
 */

import React, { useState, useEffect, useRef, useContext} from 'react'

const IsOpenContext = React.createContext()
const RunAnimationContext = React.createContext()
const SbRefContext = React.createContext()

export function useIsOpenContext() {
    return useContext(IsOpenContext);
}

export function useRunAnimationContext() {
    return useContext(RunAnimationContext);
}

export function useSbRefContext() {
    return useContext(SbRefContext);
}

const bodyTag = document.querySelector('body');
const str_sbWdidthClass = 'sb-full-screen-width';
const str_sbOpenClass = 'sb-open';

const SbAnimationContextProvider = ({ children }) => {
    
    const [isOpen, setIsOpen] = useState(true)
    const sbRef = useRef()

    useEffect(() => {
        const handleResize = () => {
            const int_innerWidth = window.innerWidth
            if (int_innerWidth < 480) {
                bodyTag.classList.add(str_sbWdidthClass)
            } else {
                bodyTag.classList.remove(str_sbWdidthClass)
            }
        }

        if (window.innerWidth < 480) {
            bodyTag.classList.add(str_sbOpenClass)
            if (!bodyTag.classList.contains(str_sbWdidthClass)) {
                bodyTag.classList.add(str_sbWdidthClass)
            }
        } else {
            if (bodyTag.classList.contains(str_sbWdidthClass)) {
                bodyTag.classList.remove(str_sbWdidthClass)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const runAnimation = () => {
        /* Above 768px innerWidth the sb is always visible. */
        if (window.innerWidth > 768) {
            if (!isOpen) setIsOpen(true)
            return
        }

        if (isOpen) {
            sbRef.current.style.animation = 'sidebarFadeOut 0.7s'
            bodyTag.classList.remove(str_sbOpenClass)
        } else {
            sbRef.current.style.animation = 'sidebarFadeIn 0.5s'
            bodyTag.classList.add(str_sbOpenClass)
        }
        setTimeout(() => {
            sbRef.current.style.animation = ''
        }, 700)
        setIsOpen(!isOpen)
    }
    
    return (
        <IsOpenContext.Provider value={isOpen}>
            <RunAnimationContext.Provider value={runAnimation}>
                <SbRefContext.Provider value={sbRef}>
                    {children}
                </SbRefContext.Provider>
            </RunAnimationContext.Provider>
        </IsOpenContext.Provider>
    )
}

export default SbAnimationContextProvider
