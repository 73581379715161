import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import DataStateCheckWrapper from '../../../general/DataStateCheckWrapper';
import PPFormBase from '../PPFormBase';
import CSecBase from '../../../content-sections/CSecBase';
import InputFormCoupon from '../../forms/coupons/InputFormCoupon';
import NoPPDataAvailable from '../../../profile-pages/NoPPDataAvailable';

import {
    useCouponsThisSemesterContext,
    useCouponsNextSemesterContext,
    useCouponsOldContext,
    useAddCouponContext
} from '../../../profile-pages/context-provider/PPCouponsContextProvider';
import { db2inputFormData, static2dynamicPanelConfig } from '../../util/input_form_data_handler';
import { fetchDBdata } from '../../../../util/db_ls_query_handler';
import { CouponsFormData as FormData, SEMESTER_OPTIONS } from '../../util/form-config-data/InputFormCouponDataConfigs';
import ErrorMsgs from '../../../../util/error_msgs.json';

const LIST_URL = '/api/store/gastronomer/coupons/list/';
const DETAIL_BASE_URL = '/api/store/gastronomer/coupons/detail/';

const SEMESTER_COUPON_LIM_REACHED_EMSG = ErrorMsgs.request.coupon.tooManySemsterCoupons;
const OLD_COUPON_LIM_REACHED_EMSG = ErrorMsgs.request.coupon.tooManyOldCoupons;

/* Limit of different coupoons that can coexist in a semester. */
const MAX_NUM_COUPONS = 20;

const getSemBlockMsg = (numDifferentCouponsCurrentSem, numDifferentCouponsNextSem) => {
    /**
     * Checks if the limit of different coupons for a specific semester was reached.
     * :Returns
     *  String that represents which semester(s) is(are) blocked from creating a new coupon for.
     *  Empty string if coupons can be created for both semesters.
     */
    if (numDifferentCouponsCurrentSem >= MAX_NUM_COUPONS && numDifferentCouponsNextSem >= MAX_NUM_COUPONS)
        return 'both';
    else if (numDifferentCouponsCurrentSem >= MAX_NUM_COUPONS)
        return 'this';
    else if (numDifferentCouponsNextSem >= MAX_NUM_COUPONS)
        return 'next';
    else
        return '';
}

const genInitSemState = (semBlockMsg) => {
    /**
     * Generates the init state for the 'semester' single choice field of the coupon input form.
     * The values are generated from the array that is also used in the form config file.
     */
    if (semBlockMsg === 'this')
        return [SEMESTER_OPTIONS[1], false]; /* [value, no error] */
    else if (semBlockMsg === 'next')
        return [SEMESTER_OPTIONS[0], false]; /* [value, no error] */
    else
        return ['', true] /* [init. value, has error (requ. field)] */
}

const genSemOptions = (semBlockMsg) => {
    /**
     * Creates the options array for the 'semester' single choice field.
     * The values are generated from the array that is also used in the form config file.
     */
    if (semBlockMsg === 'this')
        return [SEMESTER_OPTIONS[1]]
    else if (semBlockMsg === 'next')
        return [SEMESTER_OPTIONS[0]]
    else
        return SEMESTER_OPTIONS
}

const CouponForm = () => {

    const params = useParams()
    const [data, setData] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    const [hasQuery, setHasQuery] = useState(true) /* True first to show 'loading' while query decision is made. */

    const couponsCurrentSem = useCouponsThisSemesterContext()
    const couponsNextSem = useCouponsNextSemesterContext()
    const couponsOld = useCouponsOldContext()
    const addNewCoupon = useAddCouponContext()

    /* Check data num. limits to know if user is allowed to create a new food offer. */
    const numDifferentCouponsCurrentSem = couponsCurrentSem.length
    const numDifferentCouponsNextSem = couponsNextSem.length
    const numOldCoupons = couponsOld.length
    /* Check if coupons for either this sem. or next sem. cannot be created. */
    const semBlockMsg = getSemBlockMsg(numDifferentCouponsCurrentSem, numDifferentCouponsNextSem);

    /* Configure form data. */
    
    useEffect(() => {
        const fetchFormData = async () => {
            const queryData = await fetchDBdata(DETAIL_BASE_URL + `${params.couponId}/`)
            if (queryData.isQuerySuccess) {
                setData(queryData.response.data)
                setIsFirstFetchSuccess(true)
            } else {
                setErrorMsg(queryData.errorMsg)
                setIsFirstFetchSuccess(false)
            }
        }
        
        /* Check if DB query to reuse data should be executed. */
        let hasQuery = true
        if (
            (params.couponId === undefined) ||
            (numOldCoupons >= MAX_NUM_COUPONS) ||
            (numDifferentCouponsCurrentSem >= MAX_NUM_COUPONS && numDifferentCouponsNextSem >= MAX_NUM_COUPONS)
        ) {
            hasQuery = false
        }

        if (hasQuery) {
            fetchFormData()
        } else {
            setHasQuery(false) /* Render form instead of displaying the 'loading ...' message. */
        }
    }, [])

    const dynConfig = { str_queryURL: LIST_URL }
    if (hasQuery && isFirstFetchSuccess) {
        dynConfig['obj_initState'] = db2inputFormData(data)
        dynConfig['obj_initState']['semester'] = genInitSemState(semBlockMsg)
    } else {
        dynConfig['obj_initState'] = { semester: genInitSemState(semBlockMsg) }
    }

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={hasQuery}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            firstQueryDataEntry={-1} /* Only a placeholder to render the children. */
            str_errorMsg={errorMsg}
            b_isContentSection={true}
        >
            {
                numOldCoupons >= MAX_NUM_COUPONS
                ?
                <CSecBase str_title='Info'>
                    <NoPPDataAvailable str_message={OLD_COUPON_LIM_REACHED_EMSG} />
                </CSecBase>
                :
                semBlockMsg === 'both'
                ?
                <CSecBase str_title='Info'>
                    <NoPPDataAvailable str_message={SEMESTER_COUPON_LIM_REACHED_EMSG} />
                </CSecBase>
                :
                <PPFormBase
                    {...static2dynamicPanelConfig(FormData, dynConfig)}
                    formChild={<InputFormCoupon arr_semOptions={genSemOptions(semBlockMsg)} />}
                    str_requestType='post'
                    fct_response2parent={addNewCoupon}
                />
            }
        </DataStateCheckWrapper>
    )
}

export default CouponForm
