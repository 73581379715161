import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TbMessageCircle } from 'react-icons/tb';

import HandleNoData from '../../general/HandleNoData';
import Votes from '../../votes/Votes';
import AuthService from '../../../util/authentication/auth_service';
import { string2URLstring } from '../../../util/data_handler';
import { genDynUrl } from '../../../util/db_ls_query_handler';

const BASE_VOTE_URL = '/api/coursedocs/vote/';

const TbCoursesDocs = ({ arr_data }) => {
    const userId = AuthService.getUserId()
    return (
        arr_data[0] === undefined
        ?
        <HandleNoData />
        :
        <div className='tb-wrapper'>
            <table cellSpacing={0} className='tb-base'>
                <thead>
                    <tr>
                        <td>Titel</td>
                        <td className='hide-max-w-480px'>Votes</td>
                        <td className='hide-max-w-1200px'>Autor</td>
                        <td className='hide-max-w-1024px'>Hochgeladen</td>
                        <td className='hide-max-w-576px'>Offiziell</td>
                        <td>Chat</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        arr_data.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <a
                                        href={item.docFile}
                                        target='_blank'
                                        rel='noopener noreferrer'    
                                        className='file-link'
                                    >
                                        {item.title}
                                    </a>
                                </td>
                                <td className='hide-max-w-480px'>
                                    <Votes
                                        nbr_itemId={item.id}
                                        nbr_userId={userId}
                                        nbr_itemAuthorId={item.authorId}
                                        nbr_upvotes={item.numUpvotes}
                                        nbr_downvotes={item.numDownvotes}
                                        b_userVoted={item.userVoted}
                                        str_url={genDynUrl(BASE_VOTE_URL, item.id)}
                                    />
                                </td>
                                <td className='hide-max-w-1200px text-capitalize'>{item.author}</td>
                                <td className='hide-max-w-1024px'>{item.uploadDate}</td>
                                <td className='text-capitalize hide-max-w-576px'>
                                    {item.isOfficial ? 'ja' : 'nein'}
                                </td>
                                <td className='td-symbol'>
                                    <Link to={`${string2URLstring(item.title)}/${item.id}`}>
                                        <TbMessageCircle />
                                    </Link>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

TbCoursesDocs.propTypes = {
    arr_data: PropTypes.array
}

export default TbCoursesDocs
