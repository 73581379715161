import React, { useState, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AiOutlineEdit } from 'react-icons/ai';
import { MdVisibilityOff } from 'react-icons/md';

import { useHandleTakeOffline } from './IndustryPPJobsContextProvider';
import { string2URLstring } from '../../../../util/data_handler';
import { browserDateToGermanDateFormat } from '../../../../util/date_time_handler';
import { sendData2db } from '../../../../util/db_ls_query_handler';

const TAKE_OFFLINE_SUCCESS_MSG = 'Erfolg!\nDer Job wurde erfolgreich zu den offline Jobs hinzugefügt.\n' +
    'Du kannst die Stelle nun unter \'Offline Jobs\' finden.';
const DETAIL_BASE_URL = '/api/industry/job/detail/company-profile-page/';
const TAKE_OFFLINE_ERROR_MSG = 'Der Job konnte nicht offline genommen werden! ' +
    'Bitte versuche es durch klicken noch einmal. ' +
    'Sollte derselbe Fehler danach wieder auftauchen, dann versuche es später wieder.';

const genDetailUrl = (jobId) => {
    return DETAIL_BASE_URL + jobId + '/';
}

const TbRowIndustryOnlineJobs = ({ obj_rowData }) => {
    
    const [isCollapsed, setIsCollapsed] = useState(false)
    const tableRowRef = useRef()
    const isTakeOfflineLockedRef = useRef(false) /* Prevent multiple clicks on clicking 'take offline'. */

    const id = obj_rowData.id
    const handleTakeOffline = useHandleTakeOffline()
    const str_confirmQuestion = `Möchtest du wirklich folgende Stelle offline nehmen?\n${obj_rowData.title}`;
    
    useMemo(() => {
        /* If the input arg changes due to a delete event on a <tr>,
         * remove collapsed class and set height of prev. animation. */
        setIsCollapsed(false)
        if (tableRowRef.current) {
            tableRowRef.current.style.height = ''
        }
    }, [obj_rowData])

    const takeJobOffline =  async () => {
        if (isTakeOfflineLockedRef.current) return

        /* Check if user really intends to take the position offline. */
        if (!window.confirm(str_confirmQuestion)) return

        isTakeOfflineLockedRef.current = true

        const url = genDetailUrl(id)
        const pl = { action: 'takeOffline' }
        const queryData = await sendData2db('put', url, pl)
        if (queryData.isQuerySuccess) {
            /* Animation. */
            /* Fix the height of <tr> so that removing the <td> does 
             * not collapse the <tr> before the animation. */
            const height = tableRowRef.current.clientHeight;
            tableRowRef.current.style.height = height + 'px';
            /* Run disappear animation by adding a class. */
            setIsCollapsed(true)
            /* Wait for animation to finish before context provider state is updated. */
            setTimeout(() => {
                handleTakeOffline(id)
                alert(TAKE_OFFLINE_SUCCESS_MSG)
                isTakeOfflineLockedRef.current = false
            }, 1500)
        } else {
            alert(TAKE_OFFLINE_ERROR_MSG)
            isTakeOfflineLockedRef.current = false
        }
    }

    return (
        <tr ref={tableRowRef} className={`${isCollapsed ? "tr-collapse-animation" : ""}`}>
            {
                !isCollapsed &&
                <>
                    <td>
                        <Link
                            to={`/industry/jobs/${string2URLstring(obj_rowData.companyName)}/${string2URLstring(obj_rowData.title)}/${obj_rowData.id}`}
                            target="_blank"
                            rel="noopener noreferrer"    
                            className="file-link"
                        >
                            {obj_rowData.title}
                        </Link>
                    </td>
                    <td className="hide-max-w-576px">{browserDateToGermanDateFormat(obj_rowData.launchDate)}</td>
                    <td className="hide-max-w-1024px">{browserDateToGermanDateFormat(obj_rowData.startDate)}</td>
                    <td>
                        <MdVisibilityOff onClick={takeJobOffline} />
                    </td>
                    <td>
                        <Link to={`online/job/${obj_rowData.id}/edit`}>
                            <AiOutlineEdit />
                        </Link>
                    </td>
                </>
            }
        </tr>
    )
}

TbRowIndustryOnlineJobs.propTypes = {
    obj_rowData: PropTypes.object
}

export default TbRowIndustryOnlineJobs
