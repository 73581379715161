import React from 'react';
import PropTypes from 'prop-types';

import CSecBase from '../content-sections/CSecBase';

const DiscussionHeader = ({
    b_hasDarkDesign=false,
    children
}) => {
    return (
        <CSecBase
            str_id='discussion-topic-section-id'
            str_classes='discussion-topic-wrapper'
            b_hasDarkDesign={b_hasDarkDesign}
        >
            {children}
        </CSecBase>
    )
}

DiscussionHeader.propTypes = {
    b_hasDarkDesign: PropTypes.bool
}

export default DiscussionHeader
