import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MdOutlineArrowBackIos } from 'react-icons/md';

const CSecOverviewBackLink = ({
    str_linkUrl,
    str_linkText
}) => {
    return (
        <section className="section-discussion-to-overview">
            <div>
                <Link to={str_linkUrl ? str_linkUrl : '..'} className='page-link'>
                    <span>
                        <MdOutlineArrowBackIos />
                    </span>
                    <span>{str_linkText ? str_linkText : 'Übersicht'}</span>
                </Link>
            </div>
        </section>
    )
}

CSecOverviewBackLink.propTypes = {
    str_linkUrl: PropTypes.string,
    str_linkText: PropTypes.string
}

export default CSecOverviewBackLink
