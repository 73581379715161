import React from 'react';

import FormBase from '../../../input-forms/FormBase';
import InFieldsGroup from '../../../input-forms/InFieldsGroup';
import TextField from '../../../input-forms/fields/TextField';
import NumberField from '../../../input-forms/fields/NumberField';

import { useInputData } from '../../../input-forms/context-provider/InputFormContextProvider';
import { FilterFormCourseSwapData as FormData } from '../../../input-forms/util/form-config-data/InputFormFilterDataConfigs';

const FilterFormCourseSwap = () => {
    const data = useInputData()
    return (
        <FormBase b_isFilterForm={true}>
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.courseTitle}
                    str_chars={data[FormData.fields.courseTitle.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.targetGroup}
                    nbr_value={data[FormData.fields.targetGroup.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default FilterFormCourseSwap
