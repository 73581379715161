import React from 'react'
import PropTypes from 'prop-types'

const CSecGridNumbers = ({
    arr_gridItems,
    b_hasDarkDesign=false,
    str_classes=''
}) => {
    return (
        arr_gridItems && arr_gridItems[0] &&
        <section className={`
            content-section nums-grid-section
            ${b_hasDarkDesign ? 'dark' : 'bright'}
            ${str_classes}
        `}
        >
            <div className="nums-grid-wrapper">
                <div className="grid-container nums-grid">
                    {
                        arr_gridItems.map((item, index) => (
                            <div key={index} className="grid-item">
                                <div className="grid-item-wrapper">
                                    <div className="number">{item.data}</div>
                                    <div className="title">{item.title}</div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

CSecGridNumbers.propTypes = {
    arr_gridItems: PropTypes.array.isRequired,
    b_hasDarkDesign: PropTypes.bool,
    str_classes: PropTypes.string
}

export default CSecGridNumbers
