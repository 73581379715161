import React from 'react';
import { Link } from 'react-router-dom';
import { TbMessageCircle } from 'react-icons/tb';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import DynamicContentLoadBtn from '../../buttons/DynamicContentLoadBtn';
import Votes from '../../votes/Votes';

import {
    useQueriedDataContext,
    useIsFirstFetchSuccessContext
} from '../../context-provider/DynamicContentLoadContextProvider';
import { string2URLstring } from '../../../util/data_handler';
import AuthService from '../../../util/authentication/auth_service';

const TbStudentPPUploadedDocs = () => {
    const docs = useQueriedDataContext()
    const isFirstFetchSuccessRef = useIsFirstFetchSuccessContext()
    const userId = AuthService.getUserId()
    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccessRef.current}
            firstQueryDataEntry={docs[0]}
        >
            <div className='tb-wrapper'>
                <table cellSpacing={0} className='tb-base'>
                    <thead>
                        <tr>
                            <td>Titel</td>
                            <td className='hide-max-w-480px'>Votes</td>
                            <td className='hide-max-w-1024px'>Gepostet</td>
                            <td>Kategorie</td>
                            <td>Chat</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            docs.map((doc, index) => (
                                <tr key={index}>
                                    <td>
                                        <a
                                            href={doc.docFile}
                                            target='_blank'
                                            rel='noopener noreferrer'    
                                            className='file-link'
                                        >
                                            {doc.title}
                                        </a>
                                    </td>
                                    <td className='hide-max-w-480px'>
                                        <Votes
                                            nbr_itemId={doc.id}
                                            nbr_userId={userId}
                                            nbr_itemAuthorId={userId}
                                            nbr_upvotes={doc.numUpvotes}
                                            nbr_downvotes={doc.numDownvotes}
                                            str_url=''
                                        />
                                    </td>
                                    <td className='hide-max-w-1024px'>{doc.uploadDate}</td>
                                    <td className='text-capitalize'>{doc.category}</td>
                                    <td className='td-symbol'>
                                        <Link to={
                                            `/courses/${string2URLstring(doc.courseTitle)}/` +
                                            `${doc.courseId}/docs/${string2URLstring(doc.category)}/` +
                                            `${string2URLstring(doc.title)}/${doc.id}`
                                        }>
                                            <TbMessageCircle />
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <DynamicContentLoadBtn />
        </DataStateCheckWrapper>
    )
}

export default TbStudentPPUploadedDocs
