import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
} from 'react-router-dom';

import './css/App.css';
import './css/hack.css';
import './css/animation.css';

/* Data and util. */

import PPFormConfig from './components/input-forms/profile-pages/util/PPFormConfig';
import RoutesConfig from './util/config/RoutesConfig';
import RouteSections from './util/config/RouteSections';
import StageRouteRedirect from './components/general/StageRouteRedirect';

/* Generic components and pages. */

import HeaderFooterPageBase from './components/page-layout/HeaderFooterPageBase';
import AboExtensionPage from './pages/abo/AboExtensionPage';
import AboExtensionSelectionPage from './pages/abo/AboExtensionSelectionPage';
import AboExtensionPaymentPage from './pages/abo/AboExtensionPaymentPage';
import AboExtensionSuccessPage from './pages/abo/AboExtensionSuccessPage';
import NotFoundPage from './pages/not-found/NotFoundPage';

/* Welcome pages. */
import WelcomePage from './pages/welcome/WelcomePage';
import Login from './components/welcome/Login';
import PwForgot from './components/welcome/PwForgot';
import PwReset from './components/welcome/PwReset';
import Registration from './components/welcome/Registration';
import RegistrationStudent from './components/welcome/RegistrationStudent';
import RegistrationIndustry from './components/welcome/RegistrationIndustry';
// import RegistrationGastronomy from './components/welcome/RegistrationGastronomy';

/* Profile setup. */
import ProfileSetupPage from './pages/profile-setup/ProfileSetupPage';
import ProfileSetupLegalPage from './pages/profile-setup/ProfileSetupLegalPage';
import ProfileSetupWelcomePage from './pages/profile-setup/ProfileSetupWelcomePage';
import ProfileSetupStep from './pages/profile-setup/ProfileSetupStep';
import ProfileSetupSuccessPage from './pages/profile-setup/ProfileSetupSuccessPage';

/* Profile pages. */

/* General. */
import PPForm from './components/input-forms/profile-pages/PPForm';
import PPAboSettings from './components/profile-pages/PPAboSettings';
import PPContact from './components/profile-pages/PPContact';
import PPCoupons from './components/profile-pages/PPCoupons';
import CouponForm from './components/input-forms/profile-pages/general/CouponForm';

/* Student. */
import StudentPP from './pages/profile-pages/students/StudentPP';
import StudentPPCourses from './components/profile-pages/students/StudentPPCourses';
import StudentPPCourseUploadedDocs from './components/profile-pages/students/StudentPPCourseUploadedDocs';
import StudentPPCourseThreads from './components/profile-pages/students/StudentPPCourseThreads';
import StudentPPJobs from './components/profile-pages/students/StudentPPJobs';
import StudentPPFoodOffers from './components/profile-pages/students/StudentPPFoodOffers';
import StudentPPFoodOffersBase from './components/profile-pages/students/StudentPPFoodOffersBase';
import StudentPPCoupons from './components/profile-pages/students/StudentPPCoupons';
import StudentPPCompanyAcquisitions from './components/profile-pages/students/StudentPPCompanyAcquisitions';

/* Industry. */
import IndustryPP from './pages/profile-pages/industry/IndustryPP';
import IndustryPPInfo from './components/profile-pages/industry/IndustryPPInfo';
import IndustryPPAssociates from './components/profile-pages/industry/IndustryPPAssociates';
import IndustryPPInternshipOpts from './components/profile-pages/industry/IndustryPPInternshipOpts';
import IndustryPPJobs from './components/profile-pages/industry/IndustryPPJobs';
import IndustryProfileDetailPage from './pages/profile-pages/industry/IndustryProfileDetailPage';

import JobForm from './components/input-forms/profile-pages/industry/JobForm'
import CompaniesListingPage from './pages/companies/CompaniesListingPage';
import JobsIndustryListingPage from './pages/jobs/job-listing/industry/JobsIndustryListingPage';
import JobIndustryDetailPage from './pages/jobs/job-details/industry/JobIndustryDetailPage';

/* Gastronomy. */
import GastroPP from './pages/profile-pages/gastronomy/GastroPP';
import GastroPPInfo from './components/profile-pages/gastronomy/GastroPPInfo';
import GastroPPFoodOffersRegular from './components/profile-pages/gastronomy/GastroPPFoodOffersRegular';
import GastroPPFoodOffersDgfs from './components/profile-pages/gastronomy/GastroPPFoodOffersDgfs';
import GastroPPCouponsPage from './components/profile-pages/gastronomy/GastroPPCouponsPage';
import GastroPPFoodOffers from './components/profile-pages/gastronomy/GastroPPFoodOffers';
import GastroPPMenus from './components/profile-pages/gastronomy/GastroPPMenus';

import GastroListingPage from './pages/gastronomy/GastroListingPage';
import GastroProfileDetailPage from './pages/profile-pages/gastronomy/GastroProfileDetailPage';
import FoodOffersListingPage from './pages/food/FoodOffersListingPage';
import FoodOffers from './components/listing-pages/food/FoodOffers';

import FoodOffersForm from './components/input-forms/profile-pages/gastro/FoodOffersForm';

/* Courses. */
import CourseListingPage from './pages/courses/CourseListingPage';
import CourseTutoringPageBase from './pages/courses/tutoring/CourseTutoringPageBase';
import CourseTutoringPage from './pages/courses/tutoring/CourseTutoringPage';
import CourseTutoringFormPage from './pages/courses/tutoring/CourseTutoringFormPage';
import CourseSwapPageBase from './pages/courses/course-swap/CourseSwapPageBase';
import CourseSwapPage from './pages/courses/course-swap/CourseSwapPage';
import CourseSwapFormPage from './pages/courses/course-swap/CourseSwapFormPage';

import CourseDetailPage from './pages/courses/CourseDetailPage';
import TasksDateChangePage from './pages/courses/tasks/TasksDateChangePage';
import TasksQuestionVariantsPage from './pages/courses/tasks/TasksQuestionVariantsPage';
import TasksNewPage from './pages/courses/tasks/TasksNewPage';

import DocsPage from './pages/courses/docs/DocsPage';
import DocsListingPage from './pages/courses/docs/DocsListingPage';
import DocsDetailPage from './pages/courses/docs/DocsDetailPage';
import DocsNewPage from './pages/courses/docs/DocsNewPage';

import ForumPage from './pages/forum/ForumPage';
import CourseForumPage from './pages/courses/CourseForumPage';
import CourseForumThreadPage from './pages/courses/CourseForumThreadPage';

import TasksYearListingPage from './pages/courses/tasks/TasksYearListingPage';
import TasksListingPage from './pages/courses/tasks/TasksListingPage';
import TasksDetailPage from './pages/courses/tasks/TasksDetailPage';

import TagsPage from './pages/tags/TagsPage';

/* Store. */
import FoodStore from './components/listing-pages/store/FoodStore';

/* Mining. */
import MiningPage from './pages/mining/MiningPage';

/* Contact. */
import GeneralContactPage from './pages/contact/GeneralContactPage';
import ImprovementsAndSuggestionsPage from './pages/contact/ImprovementsAndSuggestionsPage';
import BugReportingPage from './pages/contact/BugReportingPage';
import UserReportingPage from './pages/contact/UserReportingPage';

/* Legal. */
import TermsOfUsagePage from './pages/legal/TermsOfUsagePage';
import PrivacyPolicyPage from './pages/legal/PrivacyPolicyPage';

/* Password. */
import PPPasswordForm from './components/input-forms/forms/general/password/PPPasswordForm';

/* Muplaza. */
import UserguidePage from './pages/userguide/UserguidePage';

/* TEST PAGES. */
// import AllInputFormsTestPage from './pages/input-forms/dev/AllInputFormsTestPage';

const routesPrinter = (routeSectionKey) => {
    /**
     * Function to render repetitive route patterns.
     * The configs that are rendered are stored in RouteSections.js.
     */
    return RouteSections[routeSectionKey].map((route, index) => (
        route.path
        ?
            route.routes
            ?
            <Route key={index} path={route.path} element={route.component}>
                {
                    route.routes.map((subRoute, subIndex) => (
                        subRoute.path
                        ?
                        <Route key={subIndex} path={subRoute.path} element={subRoute.component} />
                        :
                        <Route key={subIndex} index element={subRoute.component} />
                    ))
                }
            </Route>
            :
            <Route key={index} path={route.path} element={route.component} />
        :
        <Route key={index} index element={route.component} />
    ))
}

function App() {
    return (
        /*  The basename must only be declared if react works with the amazon origin domain  name. */
        /* <BrowserRouter basename='/dev'> /*/
        <BrowserRouter>
            <Routes>
                <Route element={<StageRouteRedirect />}>
                    {/* TEST PAGES. */}
                    {/* <Route path='dev-page/input-fields/test/' element={<AllInputFormsTestPage />} /> */}

                    <Route path='/' element={<WelcomePage />}>
                        <Route index element={<Login />} />
                        <Route path='login' element={<Login />} />
                        <Route path='registration' element={<Registration />}>
                            <Route path='student' element={<RegistrationStudent />} />
                            <Route path='industry' element={<RegistrationIndustry />} />
                            {/* <Route path='gastronomy' element={<RegistrationGastronomy />} /> */}
                        </Route>
                        <Route path='password/forgot' element={<PwForgot />} />
                        <Route path='password/reset/:hash' element={<PwReset />} />
                    </Route>

                    <Route path='/abo-extension' element={<AboExtensionPage />}>
                        <Route index element={<AboExtensionSelectionPage />} />
                        <Route path='payment/:aboDuration' element={<AboExtensionPaymentPage />} />
                        <Route path='success' element={<AboExtensionSuccessPage />} />
                    </Route>

                    <Route path='/profile-set-up/:userType/:username' element={<ProfileSetupPage />}>
                        <Route index element={<ProfileSetupWelcomePage />} />
                        <Route path=':setupStep' element={<ProfileSetupStep />} />
                        <Route path='success' element={<ProfileSetupSuccessPage />} />
                    </Route>
                    <Route path='/profile-set-up/legal-info' element={<ProfileSetupLegalPage />}>
                        <Route path='privacy-policy' element={<PrivacyPolicyPage />} />
                        <Route path='terms-of-usage' element={<TermsOfUsagePage />} />
                        <Route path='user-guide' element={<UserguidePage />} />
                    </Route>

                    <Route path='/profile/company/:name/:id' element={<IndustryPP />}>
                        <Route path='info'>
                            <Route index element={<IndustryPPInfo />} />
                            <Route path='slogan/edit' element={<PPForm {...PPFormConfig.company.slogan} />} />
                            <Route path='numbers/edit' element={<PPForm {...PPFormConfig.company.numbers} />}/>
                            <Route path='descriptions/edit' element={<PPForm {...PPFormConfig.company.descriptions} />} />
                            <Route path='benefits/edit' element={<PPForm {...PPFormConfig.company.benefits} />} />
                            <Route path='equal-opportunity-employer/edit' element={<PPForm {...PPFormConfig.company.equalOpsEmployer} />} />
                            <Route path='logo/edit' element={<PPForm {...PPFormConfig.company.logo} />} />
                            <Route path='password/change' element={<PPPasswordForm />} />
                        </Route>
                        <Route path='internship-options'>
                            <Route index element={<IndustryPPInternshipOpts />} />
                            <Route path='edit' element={<PPForm {...PPFormConfig.company.internshipOptions} />} />
                        </Route>
                        <Route path='job-postings'>
                            <Route index element={<IndustryPPJobs />} />
                            <Route path=':category/job/:jobId/:editReuse' element={<JobForm />} />
                        </Route>
                        <Route path='new-job-posting' element={<JobForm />} />
                        <Route path='associates' element={<IndustryPPAssociates />} />
                        <Route path='contact'>
                            <Route index element={<PPContact str_userType='industry' />} />
                            <Route path='industry/edit' element={<PPForm {...PPFormConfig.company.contact} />} />
                            <Route path='contact-person-1/edit' element={<PPForm {...PPFormConfig.company.contactPerson0} />} />
                            <Route path='contact-person-2/edit' element={<PPForm {...PPFormConfig.company.contactPerson1} />} />
                        </Route>
                    </Route>

                    <Route path='/profile/gastronomer/:name/:id' element={<GastroPP />}>
                        <Route path='info'>
                            <Route index element={<GastroPPInfo />} />
                            <Route path='slogan/edit' element={<PPForm {...PPFormConfig.gastronomer.slogan} />} />
                            <Route path='consume-options/edit' element={<PPForm {...PPFormConfig.gastronomer.consumeOptions} />} />
                            <Route path='special-offers/edit' element={<PPForm {...PPFormConfig.gastronomer.specialOffers} />} />
                            <Route path='logo/edit' element={<PPForm {...PPFormConfig.gastronomer.logo} />} />
                            <Route path='password/change' element={<PPPasswordForm />} />
                        </Route>
                        <Route path='menus'>
                            <Route index element={<GastroPPMenus />} />
                            <Route path='drinks/edit' element={<PPForm {...PPFormConfig.gastronomer.drinksMenus} />} />
                            <Route path='food/edit' element={<PPForm {...PPFormConfig.gastronomer.foodMenus} />} />
                        </Route>
                        <Route path='food-offer/regular' element={<GastroPPFoodOffersRegular />}>
                            <Route path='this-week' element={<GastroPPFoodOffers str_dataName='thisWeek' />} />
                            <Route path='next-week' element={<GastroPPFoodOffers str_dataName='nextWeek' />} />
                            <Route path='repeating' element={<GastroPPFoodOffers str_dataName='repeating' />} />
                            <Route path='old' element={<GastroPPFoodOffers str_dataName='old' />} />
                            <Route path='new' element={<FoodOffersForm />} />
                            <Route path='reuse/:offerId' element={<FoodOffersForm />} />
                        </Route>
                        <Route path='food-offer/do-good-for-students' element={<GastroPPFoodOffersDgfs />}>
                            <Route path='today' element={<GastroPPFoodOffers str_offerType='dgfs' str_dataName='today' />} />
                            <Route path='tomorrow' element={<GastroPPFoodOffers str_offerType='dgfs' str_dataName='tomorrow' />} />
                            <Route path='old' element={<GastroPPFoodOffers str_offerType='dgfs' str_dataName='old' />} />
                            <Route path='new' element={<FoodOffersForm b_isDgfs={true} b_hasRepeatOptions={false} />} />
                            <Route path='reuse/:offerId' element={<FoodOffersForm b_isDgfs={true} b_hasRepeatOptions={false} />} />
                        </Route>
                        <Route path='coupons' element={<GastroPPCouponsPage />}>
                            <Route path='this-semester' element={<PPCoupons b_isCurrentSemester={true} />} />
                            <Route path='next-semester' element={<PPCoupons b_isCurrentSemester={false} />} />
                            <Route path='old' element={<PPCoupons b_isOld={true} />} />
                            <Route path='new' element={<CouponForm />} />
                            <Route path='reuse/:couponId' element={<CouponForm />} />
                        </Route>
                        <Route path='contact'>
                            <Route index element={<PPContact />} />
                            <Route path='gastronomer/edit' element={<PPForm {...PPFormConfig.gastronomer.contact} />} />
                            <Route path='contact-person-1/edit' element={<PPForm {...PPFormConfig.gastronomer.contactPerson0} />} />
                            <Route path='contact-person-2/edit' element={<PPForm {...PPFormConfig.gastronomer.contactPerson1} />} />
                        </Route>
                    </Route>

                    <Route path='/profile/student/:username/:id' element={<StudentPP />}>
                        <Route path='course'>
                            <Route path='pinned' element={<StudentPPCourses />} />
                            <Route path='my-docs' element={<StudentPPCourseUploadedDocs />} />
                            <Route path='my-threads' element={<StudentPPCourseThreads />} />
                        </Route>
                        <Route path='pinned-jobs' element={<StudentPPJobs />} />
                        <Route path='food-offers/regular' element={<StudentPPFoodOffersBase />}>
                            <Route path='this-week' element={<StudentPPFoodOffers />} />
                            <Route path='next-week' element={<StudentPPFoodOffers b_isPresent={false} />} />
                        </Route>
                        <Route path='food-offers/do-good-for-students' element={<StudentPPFoodOffersBase b_isDgfs={true} />}>
                            <Route path='today' element={<StudentPPFoodOffers b_isDgfs={true} />} />
                            <Route path='tomorrow' element={<StudentPPFoodOffers b_isDgfs={true} b_isPresent={false} />} />
                        </Route>
                        <Route path='coupons' element={<StudentPPCoupons />} />
                        <Route path='company-acquisitions' element={<StudentPPCompanyAcquisitions />} />
                        <Route path='abo' element={<PPAboSettings str_userType='student' />} />
                        <Route path='password/change' element={<PPPasswordForm />} />
                    </Route>

                    <Route path='/gastronomy' element={
                        <HeaderFooterPageBase
                            {...RoutesConfig.basePages.gastronomy}
                            b_isPrivatePage={false}
                        />
                    }>
                        <Route path='gastronomers' element={<GastroListingPage />} />
                        <Route path='food-offers' element={<FoodOffersListingPage />}>
                            <Route path='regular/this-week' element={<FoodOffers str_offerType='regular' str_time='now' />} />
                            <Route path='regular/next-week' element={<FoodOffers str_offerType='regular' str_time='then' />} />
                            <Route path='regular/daily' element={<FoodOffers str_offerType='daily' str_time='now' />} />
                            <Route path='do-good-for-students/today' element={
                                <FoodOffers
                                    str_offerType='dgfs'
                                    str_time='now'
                                    str_pageContentBgColorClasses='col-environment-green'
                                />
                            } />
                            <Route path='do-good-for-students/tomorrow' element={
                                <FoodOffers
                                    str_offerType='dgfs'
                                    str_time='then'
                                    str_pageContentBgColorClasses='col-environment-green'
                                />
                            } />
                        </Route>
                    </Route>
                    <Route path='/gastronomer/info-page/:restaurantName/:userId' element={<GastroProfileDetailPage />} />

                    <Route path='/industry' element={<HeaderFooterPageBase {...RoutesConfig.basePages.industry} />}>
                        <Route index element={<NotFoundPage str_redirectUrl='../jobs' />} />
                        <Route path='companies' element={<CompaniesListingPage />} />
                        <Route path='jobs' element={<JobsIndustryListingPage />} />
                    </Route>
                    <Route path='/company/info-page/:companyName/:companyId' element={<IndustryProfileDetailPage />} />
                    <Route path='/industry/jobs/:companyName/:jobTitle/:jobId' element={<JobIndustryDetailPage />} />

                    <Route path='/courses' element={<HeaderFooterPageBase {...RoutesConfig.basePages.course} />}>
                        <Route path='overview' element={<CourseListingPage />} />
                        <Route path='tutoring' element={<CourseTutoringPageBase />}>
                            <Route index element={<CourseTutoringPage />} />
                            <Route path='new' element={<CourseTutoringFormPage />} />
                            <Route path='edit/:id' element={<CourseTutoringFormPage />} />
                        </Route>
                        <Route path='course-swap' element={<CourseSwapPageBase /> }>
                            <Route index element={<CourseSwapPage />} />
                            <Route path='new' element={<CourseSwapFormPage />} />
                            <Route path='edit/:id' element={<CourseSwapFormPage />} />
                        </Route>
                        <Route path='tags' element={<TagsPage />}>
                            { routesPrinter('tags') }
                        </Route>
                    </Route>
                    
                    <Route path='/courses/:name/:id' element={<CourseDetailPage />}>
                        <Route index element={<NotFoundPage str_redirectUrl='../docs' />} />
                        <Route path='docs' element={<DocsPage />} >
                            <Route path=':category' element={<DocsListingPage />} />
                            <Route path=':category/:title/:docId' element={<DocsDetailPage />} />
                            <Route path='new' element={<DocsNewPage />} />
                        </Route>
                        <Route path='exams'>
                            <Route index element={<TasksYearListingPage {...RoutesConfig.studyMaterial.TasksYearListingPage.exam} />} />
                            <Route path=':year' element={<TasksListingPage {...RoutesConfig.studyMaterial.TasksListingPage.exam} />}>
                                <Route path='new' element={<TasksNewPage {...RoutesConfig.studyMaterial.TasksNewPage.task.exam} />} />
                                <Route path='exam/:examId/:date'>
                                    <Route path='new-question' element={
                                        <TasksNewPage {...RoutesConfig.studyMaterial.TasksNewPage.question.exam} />
                                    }/>
                                    <Route path='date-change' element={
                                        <TasksDateChangePage {...RoutesConfig.studyMaterial.TasksDateChangePage.exam} />
                                    }/>
                                    <Route path={RoutesConfig.paths.courses.tasksQuestionVariantsPage} element={
                                        <TasksQuestionVariantsPage {...RoutesConfig.studyMaterial.TasksQuestionVariantsPage.exam} />
                                    }/>
                                    <Route path={RoutesConfig.paths.courses.tasksDetailPage} element={
                                        <TasksDetailPage {...RoutesConfig.studyMaterial.TasksDetailPage.exam} />
                                    }/>
                                </Route>
                            </Route>
                        </Route>
                        <Route path='exercises'>
                            <Route index element={<TasksYearListingPage {...RoutesConfig.studyMaterial.TasksYearListingPage.exercise} />} />
                            <Route path=':year' element={<TasksListingPage {...RoutesConfig.studyMaterial.TasksListingPage.exercise} />}>
                                <Route path='new' element={<TasksNewPage {...RoutesConfig.studyMaterial.TasksNewPage.task.exercise} />} />
                                <Route path='exercise/:exerciseId/:date'>
                                    <Route path='new-question' element={
                                        <TasksNewPage {...RoutesConfig.studyMaterial.TasksNewPage.question.exercise} />
                                    }/>
                                    <Route path='date-change' element={
                                        <TasksDateChangePage {...RoutesConfig.studyMaterial.TasksDateChangePage.exercise} />
                                    }/>
                                    <Route path={RoutesConfig.paths.courses.tasksQuestionVariantsPage} element={
                                        <TasksQuestionVariantsPage {...RoutesConfig.studyMaterial.TasksQuestionVariantsPage.exercise} />
                                    }/>
                                    <Route path={RoutesConfig.paths.courses.tasksDetailPage} element={
                                        <TasksDetailPage {...RoutesConfig.studyMaterial.TasksDetailPage.exercise} />
                                    }/>
                                </Route>
                            </Route>
                        </Route>
                        <Route path='forum' element={<ForumPage />}>
                            <Route index element={<CourseForumPage />} />
                            <Route path='thread/:forumMsgId' element={<CourseForumThreadPage />} />
                        </Route>
                        <Route path='tags' element={<TagsPage />}>
                            { routesPrinter('tags') }
                        </Route>
                    </Route>

                    <Route path='/mining' element={
                        <HeaderFooterPageBase 
                            {...RoutesConfig.permissions.student}
                            {...RoutesConfig.basePages.mining}
                        />
                    }>
                        <Route path=':category' element={<MiningPage />} />
                    </Route>

                    <Route path='/store' element={
                        <HeaderFooterPageBase
                            {...RoutesConfig.permissions.gastronomerStudent}
                            {...RoutesConfig.basePages.store}
                        />
                    }>
                        <Route index element={<FoodStore />} />
                    </Route>

                    <Route path='contact' element={
                        <HeaderFooterPageBase
                            {...RoutesConfig.basePages.contact}
                            b_checkAboValidity={false}
                        />
                    }>
                        <Route index element={<NotFoundPage str_redirectUrl='../general' />} />
                        <Route path='general' element={<GeneralContactPage />} />
                        <Route path='improvements-and-suggestions' element={<ImprovementsAndSuggestionsPage />} />
                        <Route path='bug-reporting' element={<BugReportingPage />} />
                        <Route path='user-reporting' element={<UserReportingPage />} />
                    </Route>
                    
                    <Route path='data-and-usage' element={
                        <HeaderFooterPageBase
                            {...RoutesConfig.basePages.dataAndUsage}
                            b_checkAboValidity={false}
                        />
                    }>
                        <Route index element={<NotFoundPage str_redirectUrl='../user-guide' />} />
                        <Route path='user-guide' element={<UserguidePage />} />
                        <Route path='terms-of-usage' element={<TermsOfUsagePage />} />
                        <Route path='privacy-policy' element={<PrivacyPolicyPage />} />
                    </Route>

                    <Route path='*' element={<NotFoundPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
