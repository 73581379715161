import React, { useState } from 'react';

import DataStateCheckWrapper from '../../../components/general/DataStateCheckWrapper';
import CSecBase from '../../../components/content-sections/CSecBase';
import TbCoursesTutoring from '../../../components/tables/courses/TbCoursesTutoring';
import FilterStdTextField from '../../../components/filters/filters/std/FilterStdTextField';
import DynamicContentLoadContextProvider from '../../../components/context-provider/DynamicContentLoadContextProvider';
import DynamicContentLoadBtn from '../../../components/buttons/DynamicContentLoadBtn';
import PageNewBtn from '../../../components/buttons/PageNewBtn';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import useGetLSorDBdata from '../../../custom-hooks/useGetLSorDBdata';
import { setLSdata } from '../../../util/db_ls_query_handler';

import DynLoadPackageSizes from '../../../util/config/data_load_package_sizes.json';
import { FilterLsKeys } from '../../../util/LocalStorageVariables';

const URL = '/api/courses/tutoring/list/';
const MY_OFFERS_LS_KEY = FilterLsKeys.courseTutorMyOffers;
const OFFERS_LS_KEY = FilterLsKeys.courseTutorOfferList;

const CourseTutoringPage = () => {

    const [myOffersData, setMyOffersData] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    useSetBrowserTabTitle('Tutoring')

    /* Query my offers data separately from other offers, which are queried dynamically. */
    useGetLSorDBdata(
        URL,
        setMyOffersData,
        setIsFirstFetchSuccess,
        setErrorMsg,
        true,
        null,
        null,
        MY_OFFERS_LS_KEY
    )

    const deleteMyOffer = (id) => {
        /**
         * Deletes offer of 'id' from logged in user's offers.
         */
        const myOffers = myOffersData.filter(item => item.id !== id)
        setMyOffersData(myOffers)
        setLSdata(myOffers, MY_OFFERS_LS_KEY)
    }

    return (
        <>
            {/* My offers. */}
            {
                /* Do not show my offers if there are none. */
                (myOffersData !== undefined && myOffersData[0] !== undefined)
                ?
                <DataStateCheckWrapper
                    b_hasQueryCheck={true}
                    b_isFirstFetchSuccess={isFirstFetchSuccess}
                    str_errorMsg={errorMsg}
                    firstQueryDataEntry={-1} /* Placeholder entry. */
                >
                    <CSecBase
                        str_title='Meine Tutorangebote'
                        str_classes='no-padding-bottom'
                    >
                        <TbCoursesTutoring
                            arr_data={myOffersData}
                            b_isDynamic={false}
                            b_isMyOffer={true}
                            fct_deleteMyOffer={deleteMyOffer}
                        />
                    </CSecBase>
                    <CSecBase str_classes='no-padding no-margin-bottom'>
                        <PageNewBtn str_urlPath='new' />
                    </CSecBase>
                </DataStateCheckWrapper>
                :
                (myOffersData !== undefined && myOffersData[0] === undefined)
                ?
                <CSecBase
                    str_title='Meine Kurstausche'
                    str_classes='no-padding-bottom'
                >
                    <PageNewBtn str_urlPath='new' />
                </CSecBase>
                :
                ''
            }
            {/* All other offers. */}
            <DynamicContentLoadContextProvider
                str_queryURL={URL}
                b_useLS={true}
                str_lsKey={OFFERS_LS_KEY}
                nbr_packageSize={DynLoadPackageSizes.courses.courseTutorOffers}
            >
                <CSecBase
                    str_title='Tutorangebote'
                    b_isUsedAsTitle={true}
                    b_isDiscussionContentSection={true}
                />
                <FilterStdTextField str_filterFieldName='Kursname' />
                <CSecBase>
                    <TbCoursesTutoring
                        b_isDynamic={true}
                        b_isMyOffer={false}
                    />
                    <DynamicContentLoadBtn />
                </CSecBase>
            </DynamicContentLoadContextProvider>
        </>
    )
}

export default CourseTutoringPage
