/**
 * This file contains the base config of all input fields and the serializer
 * functions to put the data into specific formats. 
 */

import FieldLimits from '../db_field_limits.json';
import BottomTexts from '../input_form_bottom_info_texts.json';

export const STD_ALLOWED_FILE_TYPES = ['pdf', 'jpg', 'jpeg', 'png'];
export const IMAGE_FILE_TYPES = ['avif', 'jpg', 'jpeg', 'png', 'webp'];

const TextFieldStdConfig = {
    str_id: 'textField',
    str_fieldTitle: '',
    str_placeholder: '',
    str_bottomInfoText: '',
    b_isTextarea: true,
    b_isLink: false,
    b_isRequired: true,
    int_minLength: undefined,
    int_maxLength: 1000
};

const PhoneFieldStdConfig = {
    str_id: 'phoneField',
    str_fieldTitle: '',
    str_chars: '',
    str_bottomInfoText: BottomTexts.contact.phone,
    int_maxLength: FieldLimits.contact.phone,
    b_isPhone: true,
    b_isRequired: true
    /* The maxLength config is done by the component itself. */
};

const EmailFieldStdConfig = {
    str_id: 'emailField',
    str_fieldTitle: '',
    str_chars: '',
    str_bottomInfoText: '',
    b_isEmail: true,
    int_maxLength: FieldLimits.contact.email,
    b_isRequired: true
    /* The maxLength config is done by the component itself. */
};

const TagFieldStdConfig = {
    str_id: 'tagnames',
    str_fieldTitle: 'Tags',
    str_bottomInfoText: BottomTexts.course.tag,
    str_tags: '',
    int_minLength: FieldLimits.course.tag.length.min,
    int_maxLength: FieldLimits.course.tag.length.max,
    b_isRequired: false
};

const NumberFieldStdConfig = {
    str_id: 'numField',
    str_fieldTitle: '',
    nbr_value: undefined,
    /* nbr_minValue: ..., not known per se */
    /* nbr_maxValue: ..., not known per se */
    nbr_numDecimals: 2, /* Defaul for float field. */
    b_isIntField: true,
    b_isRequired: true,
    b_isYearField: false, /* If input data is year (do not make thousander separation in top text).*/
    str_bottomInfoText: ''
};

const FileFieldStdConfig = {
    str_id: 'fileField',
    str_fieldTitle: '',
    str_bottomInfoText: '',
    str_fileName: '',
    str_fileURL: '',
    inFile: null,   /* null represents no file object. */
    nbr_maxSize: 5, /* Results in 4.8 MB on the page due to 1 KB = 1024 B (not exact 1000 B). */
    arr_allowedFileTypes: STD_ALLOWED_FILE_TYPES,
    b_isRequired: true,
    b_isImageField: false /* If true, no file types array necessary, FileField handles this case. */
};

const BoolFieldStdConfig = {
    str_id: 'boolField',
    str_fieldTitle: '',
    str_boolInfoText: '',
    str_bottomInfoText: '',
    b_boolValue: false
};

const SCFieldStdConfig = {
    str_id: 'scField',
    str_fieldTitle: '',
    str_currentOption: 'Keine Option gewählt',
    /* arr_options ... not known in advance */
    str_bottomInfoText: '',
    nbr_heightInPixels: undefined,
    b_isRequired: true
}

const MCFieldStdConfig = {
    str_id: 'mcField',
    str_fieldTitle: '',
    str_bottomInfoText: '',
    nbr_heightInPixels: undefined, /* Corresponds to 6 visible items. */
    arr_options: [],
    arr_currentOptions: [],
    b_isRequired: true
};

const DateFieldStdConfig = {
    str_id: 'dateField',
    str_fieldTitle: '',
    /* str_minDate ... not known in advance */
    /* str_maxDate ... not known in advance */
    str_errorMsg: '', /* Can be served from span parent. */
    str_bottomInfoText: BottomTexts.datetime.date,
    b_isRequired: true,
    b_isSpanField: false,
    b_isStartDate: false,
    b_hasSpanChanged: false,
    fct_isDateSpanPartValid: null,
    fct_sendDataToParent: null
};

const TimeFieldStdConfig = {
    str_id: 'timeField',
    str_fieldTitle: '',
    /* str_minTime ... not known in advance */
    /* str_maxTime ... not known in advance */
    str_errorMsg: '', /* Can be served from span parent. */
    str_bottomInfoText: BottomTexts.datetime.time,
    b_isRequired: true,
    b_isSpanField: false,
    b_isStartTime: false,
    b_hasSpanChanged: false,
    fct_isTimeSpanPartValid: null,
    fct_sendDataToParent: null
};

const DateTimeFieldStdConfig = {
    str_id: 'dateTimeField',
    str_fieldTitle: 'datetime field',
    /* str_minDateTime ... not known in advance */
    /* str_maxDateTime ... not known in advance */
    str_errorMsg: '', /* Can be served from span parent. */
    str_bottomInfoText: BottomTexts.datetime.datetime,
    b_isRequired: true,
    b_isSpanField: false,
    b_isStartDateTime: false,
    b_hasSpanChanged: false,
    fct_isDateTimeSpanPartValid: null,
    fct_sendDataToParent: null
};

const DateSpanFieldsStdConfig = {
    str_id: 'dateSpanFields',
    str_startTitle: '',
    str_endTitle: '',
    /* str_startMinDate ... not known in advance */
    /* str_startMaxDate ... not known in advance */
    /* str_endMinDate   ... not known in advance */
    /* str_endMaxDate   ... not known in advance */
    str_startBottomInfoText: '', /* The date field has a std value for this field itself. */
    str_endBottomInfoText: '',   /* The date field has a std value for this field itesl. */
    b_isRequired: true
};

const TimeSpanFieldsStdConfig = {
    str_id: 'timeSpanFields',
    str_startTitle: '',
    str_endTitle: '',
    /* str_startMinTime ... not known in advance */
    /* str_startMaxTime ... not known in advance */
    /* str_endMinTime   ... not known in advance */
    /* str_endMaxTime   ... not known in advance */
    str_startBottomInfoText: '', /* The time field has a std value for this field itself. */
    str_endBottomInfoText: '',   /* The time field has a std value for this field itself. */
    b_isRequired: true
};

const DateTimeSpanFieldsStdConfig = {
    str_id: 'dateTimeSpanFields',
    str_startTitle: '',
    str_endTitle: '',
    /* str_startMinDateTime ... not known in advance */
    /* str_startMaxDateTime ... not known in advance */
    /* str_endMinDateTime   ... not known in advance */
    /* str_endMaxDateTime   ... not known in advance */
    str_startBottomInfoText: '', /* The datetime field has a std value for this field itself. */
    str_endBottomInfoText: '',   /* The datetime field has a std value for this field itself. */
    b_isRequired: true
};


export const genFieldData = (str_category, obj_config=null, nbr_id=0) => {
    /**
     * Creates a config obect for TextField. 
     * :Input
     *  str_category: Defines which field config is generated ('text', 'file', 'sc'). 
     *  obj_config: Config JSON (for potential keys look at 'TextFieldStdConfig'.)
     *  nbr_id: ID of the textfield.
     */
    let obj_genConfig = {};
    switch (str_category) {
        case 'text':
            obj_genConfig = {...TextFieldStdConfig};
            break;
        case 'phone':
            obj_genConfig = {...PhoneFieldStdConfig};
            break;
        case 'email':
            obj_genConfig = {...EmailFieldStdConfig};
            break;
        case 'nbr':
            obj_genConfig = {...NumberFieldStdConfig};
            break;
        case 'file':
            obj_genConfig = {...FileFieldStdConfig};
            obj_genConfig['str_id'] = obj_genConfig['str_id'] + nbr_id.toString();
            break;
        case 'b': /* bool */
            obj_genConfig = {...BoolFieldStdConfig};
            break;
        case 'sc':
            obj_genConfig = {...SCFieldStdConfig};
            break;
        case 'mc':
            obj_genConfig = {...MCFieldStdConfig};
            break;
        case 'date':
            obj_genConfig = {...DateFieldStdConfig};
            break;
        case 'time':
            obj_genConfig = {...TimeFieldStdConfig};
            break;
        case 'dateTime':
            obj_genConfig = {...DateTimeFieldStdConfig};
            break;
        case 'dateSpan':
            obj_genConfig = {...DateSpanFieldsStdConfig};
            break;
        case 'timeSpan':
            obj_genConfig = {...TimeSpanFieldsStdConfig};
            break;
        case 'dateTimeSpan':
            obj_genConfig = {...DateTimeSpanFieldsStdConfig};
            break;
        case 'tags':
            obj_genConfig = {...TagFieldStdConfig};
            break;
        default:
            return obj_genConfig;
    }
    /* Adjust std settings according to inputs. */
    if (obj_config !== null) {
        Object.keys(obj_config).forEach(key => {
            obj_genConfig[key] = obj_config[key];
        })
    }
    return obj_genConfig;
}