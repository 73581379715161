import React from 'react';

import CSecBase from '../../components/content-sections/CSecBase';
import DocuPageHeader from '../../components/docu-pages/DocuPageHeader';
import DocuPageParagraph from '../../components/docu-pages/DocuPageParagraph';
import CompanyUserguide from './CompanyUserguide';
import GastronomerUserguide from './GastronomerUserguide';
import StudentUserguide from './StudentUserguide';

import AuthService from '../../util/authentication/auth_service';
import Usertypes from '../../util/Usertypes';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const UserguidePage = () => {
    const usertype = AuthService.getUsertype()
    useSetBrowserTabTitle('Dokumentation')
    return (
        <CSecBase str_classes='docu-page-section-content'>
            <DocuPageHeader
                str_title='Userguide'
                str_date='20.05.2023'
            >
                <DocuPageParagraph>
                    Der Userguide von MUPLAZA ist ein Verzeichnis der wichtigsten Inhalte 
                    der Plattform und beinhaltet Beschreibungen ihrer Funktionalitäten.
                </DocuPageParagraph>
            </DocuPageHeader>
            {
                usertype === Usertypes.company
                ?
                <CompanyUserguide />
                :
                usertype === Usertypes.gastronomer
                ?
                <GastronomerUserguide />
                :
                <StudentUserguide />
            }
        </CSecBase>
    )
}

export default UserguidePage
