import React from 'react';
import PropTypes from 'prop-types';

import CSecBase from '../content-sections/CSecBase';
import CSecDiscussionNewPost from '../content-sections/CSecDiscussionNewPost';
import HandleNoData from '../general/HandleNoData';
import DiscussionNewPostContextProvider from '../content-sections/context-provider/DiscussionNewPostContextProvider';
import { useIsNewPostPermitted } from './context-provider/DiscussionContextProvider';

const DiscussionNewPost = ({
    str_newBtnTitle='neu',
    children
}) => {
    const isNewPostPermittedData = useIsNewPostPermitted()
    const isNewPostPermitted = isNewPostPermittedData[0]
    const errorMsg = isNewPostPermittedData[1]
    return (
        isNewPostPermitted
        ?
        <DiscussionNewPostContextProvider>
            <CSecDiscussionNewPost
                str_newBtnTitle={str_newBtnTitle}
            >
                {children}
            </CSecDiscussionNewPost>
        </DiscussionNewPostContextProvider>
        :
        <CSecBase str_classes='no-padding'>
            <HandleNoData str_errorMsg={errorMsg} />
        </CSecBase>
    )
}

DiscussionNewPost.propTypes = {
    str_newBtnTitle: PropTypes.string
}

export default DiscussionNewPost
