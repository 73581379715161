/**
 * Entails the differnt navbars that are available. 
 * Is used to structure the page layout correctly (e.g. that
 * the footer remains at the bottom if content height is small).
 */

import React from 'react';

const HeaderWrapper = ({ children }) => {
    return (
        <header>
            {children}
        </header>
    )
}

export default HeaderWrapper
