import React from 'react';
import PropTypes from 'prop-types';

import FormBaseContextProvider from '../../context-provider/FormBaseContextProvider';
import FormBase from '../../FormBase';
import InFieldsGroup from '../../InFieldsGroup';
import TextField from '../../fields/TextField';
import FileField from '../../fields/FileField';
import NumberField from '../../fields/NumberField';
import SingleChoiceField from '../../fields/SingleChoiceField';
import BoolField from '../../fields/BoolField';
import TagField from '../../fields/TagField';
import DocNameDisplay from './DocNameDisplay';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { CoursesDocsFormData as FormData } from '../../util/form-config-data/InputFormCoursesDataConfigs';

const HEADER_INFOS = [
    'Die folgenden Informationen sollen deinen Mitstudierenden helfen mit deinem ' +
    'Dokument schneller und einfach lernen zu können. Bitte nimm dir die Zeit die ' +
    'Felder auszufüllen, deine Peers sind dir dankbar.'
];

const InputFormCourseDocs = ({
    arr_categoryOptions
}) => {
    const data = useInputData()
    return (
        <FormBaseContextProvider>
            <FormBase
                str_title={'Neues Dokument'}
                b_hasFileField={true}
                b_hasDarkDesign={true}
                arr_headerInfos={HEADER_INFOS}
            >
                <InFieldsGroup b_hasBorder={false}>
                    <FileField
                        {...FormData.fields.docFile}
                        inFile={data[FormData.fields.docFile.str_id][0]}
                    />
                    <TextField
                        {...FormData.fields.title}
                        str_chars={data[FormData.fields.title.str_id][0]}
                    />
                    <SingleChoiceField
                        {...FormData.fields.category}
                        str_currentOption={data[FormData.fields.category.str_id][0]}
                        arr_options={arr_categoryOptions}
                    />
                    <BoolField
                        {...FormData.fields.isOffical}
                        b_boolValue={data[FormData.fields.isOffical.str_id][0]}
                    />
                    <BoolField
                        {...FormData.fields.hasValidatedAnswers}
                        b_boolValue={data[FormData.fields.hasValidatedAnswers.str_id][0]}
                    />
                    <BoolField
                        {...FormData.fields.isPeerReviewed}
                        b_boolValue={data[FormData.fields.isPeerReviewed.str_id][0]}
                    />
                    <NumberField
                        {...FormData.fields.qualityRank}
                        nbr_value={data[FormData.fields.qualityRank.str_id][0]}
                    />
                    <TextField
                        {...FormData.fields.description}
                        str_chars={data[FormData.fields.description.str_id][0]}
                    />
                    <TagField
                        {...FormData.fields.tagnames}
                        str_chars={data[FormData.fields.tagnames.str_id][0]}
                    />
                </InFieldsGroup>
                <InFieldsGroup b_hasBorder={false}>
                    <DocNameDisplay />
                </InFieldsGroup>
            </FormBase>
        </FormBaseContextProvider>
    )
}

InputFormCourseDocs.propTypes = {
    arr_categoryOptions: PropTypes.array.isRequired
}

export default InputFormCourseDocs
