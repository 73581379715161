import React, { useState } from 'react';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import PPDataGroup from '../PPDataGroup';
import TbAbos from '../../tables/users/TbAbos';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import useGetPPdataGroupData from '../../../custom-hooks/useGetPPdataGroupData';

import GastroProfileData from './GastroProfileData';
import PPMainContentBase from '../PPMainContentBase';

const CATEGORY = 'info';
const URL = '/api/profiles/gastronomer/profile-page/info/';

const GastroPPInfo = () => {

    const [data, setData] = useState(null)
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    useSetBrowserTabTitle('Info', true)
    useGetPPdataGroupData(URL, CATEGORY, setData, setIsFirstFetchSuccess, setErrorMsg)

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            b_isContentSection={true}
            str_errorMsg={errorMsg}
            firstQueryDataEntry={1} /* Only placeholder value (data is always available here). */
        >
            <PPDataGroup
                str_header='Slogan/Kurzbeschreibung'
                str_editUrl='slogan/edit'
                obj_data={data ? data.slogan : null}
                obj_dataStructure={GastroProfileData.slogan}
            />
            <PPDataGroup
                str_header='Konsummöglichkeiten'
                str_editUrl='consume-options/edit'
                obj_data={data ? data.consumeOptions : null}
                obj_dataStructure={GastroProfileData.consumeOptions}
            />
            <PPDataGroup
                str_header='Spezialangebote'
                str_editUrl='special-offers/edit'
                obj_data={data ? data.specialOffers : null}
                obj_dataStructure={GastroProfileData.specialOffers}
            />
            <PPDataGroup
                str_header='Logo'
                str_editUrl='logo/edit'
                obj_data={data ? data.logo : null}
                obj_dataStructure={GastroProfileData.logo}
                b_isDataFilePath={true}
            />
            <PPMainContentBase str_header='Abos'>
                <TbAbos arr_abos={data ? data.abos : null} />
            </PPMainContentBase>
            <PPMainContentBase
                str_header='Passwort'
                str_editUrl='password/change'
            />
        </DataStateCheckWrapper>
    )
}

export default GastroPPInfo
