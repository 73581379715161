import React from 'react';

import InFieldsGroup from '../../InFieldsGroup';
import FormBase from '../../FormBase';
import TextField from '../../fields/TextField';
import NumberField from '../../fields/NumberField';
import MultipleChoiceField from '../../fields/MultipleChoiceField';
import SelectiveProposalField from '../../fields/SelectiveProposalField';

import FieldLimits from '../../util/db_field_limits.json';
import { useInputData } from '../../context-provider/InputFormContextProvider';
import { CoursesCourseSwapFormData as FormData } from '../../util/form-config-data/InputFormCoursesDataConfigs';

const MAX_NUM_COURSE_SWAP_OFFERS = FieldLimits.course.tutor.offer.maxNumOnline;
const MAX_NUM_DAYS_ONLINE = FieldLimits.course.tutor.offer.maxNumDaysOnline;
const HEADER_INFOS = [
    `Du kannst max. ${MAX_NUM_COURSE_SWAP_OFFERS} Kurstausch-Angebote gleichzeitig posten.`,
    `Kurstausch-Angebote werden nach ${MAX_NUM_DAYS_ONLINE} Tagen automatisch von der ` +
    'Plattform genommen. Damit wird gewährleistet, dass nur aktuelle Angebote online sind.'
]

const InputFormCoursesCourseSwap = () => {
    const data = useInputData()
    return (
        <FormBase
            str_title='Tausche Kursplätze'
            arr_headerInfos={HEADER_INFOS}
        >
            <InFieldsGroup b_hasBorder={false}>
                <SelectiveProposalField
                    {...FormData.fields.courseTitle}
                    arr_proposals={data[FormData.fields.courseTitle.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.myGroup}
                    nbr_value={data[FormData.fields.myGroup.str_id][0]}
                />
                <MultipleChoiceField
                    {...FormData.fields.targetGroups}
                    arr_currentOptions={data[FormData.fields.targetGroups.str_id][0]}
                />
                <TextField
                    {...FormData.fields.email}
                    str_chars={data[FormData.fields.email.str_id][0]}
                />
                <TextField
                    {...FormData.fields.phone}
                    str_chars={data[FormData.fields.phone.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormCoursesCourseSwap
