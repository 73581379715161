import React from 'react';
import { Outlet } from 'react-router-dom';

import PrivatePage from '../../../components/network/PrivatePage';

import Usertypes from '../../../util/Usertypes';

const CourseSwapPageBase = () => {
    return (
        <PrivatePage
            arr_usertypes={[Usertypes.student]}
            b_isStudentFreeTier={true}
        >
            <Outlet />
        </PrivatePage>
    )
}

export default CourseSwapPageBase
