import React from 'react';
import PropTypes from 'prop-types';

import CSecOverviewBackLink from '../../components/content-sections/CSecOverviewBackLink';

const CourseStudyMaterialDiscussionPage = ({
    str_linkUrl='../..',
    comp_discussion
}) => {
    return (
        <>
            <CSecOverviewBackLink str_linkUrl={str_linkUrl} />
            {comp_discussion}
        </>
    )
}

CourseStudyMaterialDiscussionPage.propTypes = {
    str_linkUrl: PropTypes.string
}

export default CourseStudyMaterialDiscussionPage
