import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import PrivatePage from '../../../components/network/PrivatePage';
import HeaderAndFooter from '../../../components/page-layout/HeaderAndFooter';
import SbProfilePage from '../../../components/sidebars/profile-pages/SbProfilePage';
import PPHeader from '../../../components/profile-pages/PPHeader';
import PPLayout from '../../../components/profile-pages/PPLayout';
import ModalContextProvider from '../../../components/modal/context-provider/ModalContextProvider';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import Usertypes from '../../../util/Usertypes';

const StudentPP = () => {

    const navigate = useNavigate()

    useSetBrowserTabTitle('', true)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [navigate])

    return (
        <PrivatePage
            b_isStudentFreeTier={true}
            arr_usertypes={[Usertypes.student]}
        >
            <HeaderAndFooter>
                <PPLayout sidebar={<SbProfilePage str_userCategory='student' />}>
                    <PPHeader />
                    <ModalContextProvider>
                        <Outlet />
                    </ModalContextProvider>
                </PPLayout>
            </HeaderAndFooter>
        </PrivatePage>
    )
}

export default StudentPP
