import React from 'react';
import { Outlet } from 'react-router-dom';

import GastroPPDgfsContextProvider from './context-provider/GastroPPDgfsContextProvider';
import PPMcNavbar from '../../navbars/PPMcNavbar';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';

const GastroPPFoodOffersDgfs = () => {
    useSetBrowserTabTitle('DoG4S', true)
    return (
        <GastroPPDgfsContextProvider>
            <PPMcNavbar str_dataKey='foodOffersDgfs' />
            <Outlet />
        </GastroPPDgfsContextProvider>
    )
}

export default GastroPPFoodOffersDgfs
