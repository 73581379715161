/**
 * Component is used instead of a normal <p> to make sure that the
 * docu page content always has the same tags.
 */

import React from 'react';

const DocuPageParagraph = ({children}) => {
    return (
        <p>
            {children}
        </p>
    )
}

export default DocuPageParagraph
