import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import CSecBase from '../../../components/content-sections/CSecBase';
import DataStateCheckWrapper from '../../../components/general/DataStateCheckWrapper';
import TbTaskDateChanges from '../../../components/tables/courses/TbTaskDateChanges';
import InputFormTaskDateChange from '../../../components/input-forms/forms/courses/InputFormTaskDateChange';
import InputFormContextProvider from '../../../components/input-forms/context-provider/InputFormContextProvider';

import { TaskDateChangeFormData as FormData } from '../../../components/input-forms/util/form-config-data/InputFormCoursesDataConfigs';
import { static2dynamicPanelConfig } from '../../../components/input-forms/util/input_form_data_handler';
import { genDynUrl } from '../../../util/db_ls_query_handler';
import { useUpdateTaskDateContext } from '../../../components/sidebars/context-provider/SbCourseTasksContextProvider';
import useGetLSorDBdata from '../../../custom-hooks/useGetLSorDBdata';

const TasksDateChangePage = ({
    str_getUrl,
    str_postUrl,
    str_urlParam /* Param to select the task id (e.g. examId). */
}) => {

    const params = useParams()
    const [data, setData] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    /* Hook to update the date of a task stored in the sb context provider. */
    const updateTaskDateSb = useUpdateTaskDateContext()

    const dynConfig = { str_queryURL: genDynUrl(genDynUrl(str_postUrl, params.id), params[str_urlParam]) }
    useGetLSorDBdata(
        genDynUrl(str_getUrl, params[str_urlParam]),
        setData,
        setIsFirstFetchSuccess,
        setErrorMsg,
        false,
        undefined,
        undefined,
        '', '', '',
        true
    )
    
    const post = (response) => {
        /**
         * Add newly posted date change to the existing date changes and
         * set date changed today to true.
         */
        updateTaskDateSb(response.data.taskId, response.data.date)
        const newData = {
            dateChanges: [response.data, ...data.dateChanges],
            hasUserChangedDate: true,
            hasDateChangedToday: true
        }
        setData(newData)
    }

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            b_isContentSection={true}
            b_hasContentSectionDarkDesign={true}
            str_errorMsg={errorMsg}
            firstQueryDataEntry={-1} /* Placeholder, data not present must not block the HTML. */
        >
            <CSecBase
                str_id='task-date-changes-sec'
                str_title='Datumsänderungen'
            >
                <TbTaskDateChanges arr_data={data ? data.dateChanges : []} />
            </CSecBase>

            <CSecBase
                str_id='task-date-change-form-sec'
                str_title='Datum ändern'
                str_subtitle='Korrigiere das momentane Datum sollte es nicht stimmen.'
            >
                {
                    data && data.hasDateChangedToday
                    ?
                    <p>
                        Das Datum kann heute nicht mehr verändert werden, da es heute
                        bereits einmal adaptiert wurde. Es ist nur eine Änderung pro Tag
                        zulässig. Sollte das momentane Datum tatsächlich falsch sein, kann
                        es morgen erneut geändert werden.
                    </p>
                    :
                    data && data.hasUserChangedDate
                    ?
                    <p>
                        Du hast bereits einmal ein neues Datum gepostet. Das Datum kann nur
                        einmal pro User verändert werden.
                    </p>
                    :
                    <InputFormContextProvider
                        {...static2dynamicPanelConfig(FormData, dynConfig)}
                        b_hasDiscardBtn={false}
                        fct_response2parent={post}
                    >
                        <InputFormTaskDateChange />
                    </InputFormContextProvider>
                }
            </CSecBase>
        </DataStateCheckWrapper>
    )
}

TasksDateChangePage.propTypes = {
    str_getUrl: PropTypes.string.isRequired,
    str_postUrl: PropTypes.string.isRequired,
    str_urlParam: PropTypes.string.isRequired
}

export default TasksDateChangePage
