import React from 'react'

const AccordionParagraph = ({ children }) => {
    return (
        <p>
            {children}
        </p>
    )
}

export default AccordionParagraph
