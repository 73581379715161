import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { AiOutlineLike, AiOutlineDislike } from 'react-icons/ai';

import { sendData2db, fetchLSdata, setLSdata } from '../../util/db_ls_query_handler';
import { useUpdatePostVotesContext } from '../discussion-pages/context-provider/DiscussionContextProvider';
import { useUpdateDiscussionMsgVotesContext } from '../discussion-pages/context-provider/DiscussionCacheContextProvider';
import { useIsDiscussionMsgContext } from '../messages/context-provider/MessagesContextProvider';
import { useUpdateMessageVotes } from '../messages/context-provider/MessageCacheContextProvider';
import { useUpdateDocVoteContext } from '../context-provider/CourseDocsCacheContextProvider';
import { useUpdateFilterDataVotes } from '../context-provider/FilterCacheDynamicContentLoadContextProvider';

const ERROR_MSG = 'Oops! Es ist ein Fehler aufgetreten. Es konnte nicht gevotet werden.\n' +
    'Bitte versuche erneut zu voten. Sollte derselbe Fehler wieder auftreten, dann probiere ' +
    'es später noch einmal.';

const getUsersVoteStatus = (deltaUpvote, deltaDownvote) => {
    /**
     * :Input
     *  deltaUpvote   (int): 1 (vote up), 0 (not voted), -1 (voted down)
     *  deltaDownvote (int): 1 (vote up), 0 (not voted), -1 (voted down)
     * :Returns
     *  True : If user's vote status is up.
     *  False: If user's vote status is down.
     *  null : If user's vote status is not voted.
     */
    if (deltaUpvote === -1) {
        if (deltaDownvote === 0) {
            return null;  /* Undo vote. */
        } else {
            return false; /* Downvoted. */
        }
    }
    else if (deltaUpvote === 0) {
        if (deltaDownvote === -1) {
            return null;  /* Undo vote. */
        } else {
            return false; /* Downvoted. */
        }
    }
    else if (deltaUpvote === 1) {
        return true;      /* Upvoted. */
    }
}

const Votes = ({
    nbr_itemId,           /* Item that this vote component belongs to (only rel. for LS, e.g document id). */
    nbr_userId,           /* Id of the logged in user. */
    nbr_itemAuthorId,     /* Id of the user that created the item that can be voted for. */
    nbr_upvotes,
    nbr_downvotes,
    b_userVoted=null,     /* true = user voted up, false = user voted down */
    str_url,
    str_prLsKey='',       /* Primary localStorage key. */ 
    str_secLsKey='',      /* Secondary localStorage key. */
    str_terLsKey='',      /* Tercary localStorage key. */
    fct_customLsUpdate    /* Parent company handles LS update as it is a special case. */
}) => {
    
    const [upvotes, setUpvotes] = useState()
    const [downvotes, setDownvotes] = useState()
    const [isUpvoted, setIsUpvoted] = useState()
    const [isDownvoted, setIsDownvoted] = useState()
    const isBlockedRef = useRef(false)

    /* Hook to update the votes of a discussion post (post of the chat). */
    const updateDiscussionPostVotes = useUpdatePostVotesContext()
    /* Hook to update the votes of a discussion message (e.g. exam question). */
    const updateDiscussionMsgVotes = useUpdateDiscussionMsgVotesContext()
    /* Hook to identify if msg is a discussion msg. */
    const isDiscussionMsg = useIsDiscussionMsgContext()
    /* Hook to update the votes of the message cache (e.g. used for forum). */
    const updateMsgCacheVotes = useUpdateMessageVotes()
    /* Hook to update the votes of a course document. */
    const updateDocVote = useUpdateDocVoteContext()
    /* Hook to update the votes of data cached according to the used filter by the DCLCP. */
    const updateFilterDataVotes = useUpdateFilterDataVotes()

    /* Flag to decide if the user can vote this item. */
    const isUserAuthor = nbr_userId === nbr_itemAuthorId

    useEffect(() => {
        setUpvotes(nbr_upvotes)
        setDownvotes(nbr_downvotes)
        setIsUpvoted(b_userVoted === null ? false : b_userVoted)
        setIsDownvoted(b_userVoted === null ? false : !b_userVoted)
    }, [nbr_upvotes, nbr_downvotes, b_userVoted])

    const postVote = async (b_isUpvote) => {
        /**
         * Send vote to the DB.
         * :Returns
         *  true: If successful.
         *  false: Otherwise
         */
        const pl = { 'vote': b_isUpvote }
        const queryData = await sendData2db('post', str_url, pl)
        if (queryData.isQuerySuccess) return true
        return false
    }

    const updateVotesLs = (deltaUpvote, deltaDownvote) => {
        /**
         * Updates the vote numbers of the LS item.
         * :Input
         *  deltaUpvote   (int): Num by which the upvote num is increased/decreased.
         *  deltaDownvote (int): Num by which the downvote num is increased/decreased.
         */
        /* Check if parent company should handle LS update. */
         if (fct_customLsUpdate) {
            fct_customLsUpdate(deltaUpvote, deltaDownvote, nbr_itemId)
            return
        }
        
        /* Check if LS should be updated. */
        if (!str_prLsKey) return
        
        const lsItem = fetchLSdata(str_prLsKey, str_secLsKey, str_terLsKey)
        if (lsItem === null) return
        try {
            if (Array.isArray(lsItem)) {
                const newLsItem = lsItem.map(item => {
                    if (item.id === nbr_itemId) {
                        const upvotes = item['numUpvotes'] + deltaUpvote
                        const downvotes = item['numDownvotes'] + deltaDownvote
                        const newItem = {
                            ...item,
                            numUpvotes: upvotes,
                            numDownvotes: downvotes,
                            userVoted: getUsersVoteStatus(deltaUpvote, deltaDownvote)
                        }
                        return newItem
                    } else {
                        return item
                    }
                })
                setLSdata(newLsItem, str_prLsKey, str_secLsKey, str_terLsKey)
            } else {
                lsItem['numUpvotes'] += deltaUpvote
                lsItem['numDownvotes'] += deltaDownvote
                lsItem['userVoted'] = getUsersVoteStatus(deltaUpvote, deltaDownvote)
                setLSdata(lsItem, str_prLsKey, str_secLsKey, str_terLsKey)
            }
        } catch {}
    }

    const vote = async (b_isUpvote) => {
        /**
         * Handles the different possibilities of set votes. 
         */
        if (isBlockedRef.current) return
        isBlockedRef.current = true

        const isVoteSuccess = await postVote(b_isUpvote)
        if (!isVoteSuccess) {
            alert(ERROR_MSG)
            isBlockedRef.current = false
            return
        }

        let deltaUp, deltaDown;
        if (b_isUpvote) {
            if (isUpvoted) {
                setUpvotes(upvotes => --upvotes)
                setIsUpvoted(!isUpvoted)
                deltaUp = -1
                deltaDown = 0
            }
            else if (isDownvoted) {
                setUpvotes(upvotes => ++upvotes)
                setDownvotes(downvotes => --downvotes)
                setIsUpvoted(!isUpvoted)
                setIsDownvoted(!isDownvoted)
                deltaUp = 1
                deltaDown = -1
            }
            else {
                setUpvotes(upvotes => ++upvotes)
                setIsUpvoted(true)
                deltaUp = 1
                deltaDown = 0
            }
        } else {
            if (isUpvoted) {
                setUpvotes(upvotes => --upvotes)
                setDownvotes(downvotes => ++downvotes)
                setIsUpvoted(!isUpvoted)
                setIsDownvoted(!isDownvoted)
                deltaUp = -1
                deltaDown = 1
            }
            else if (isDownvoted) {
                setDownvotes(downvotes => --downvotes)
                setIsDownvoted(!isDownvoted)
                deltaUp = 0
                deltaDown = -1
            }
            else {
                setDownvotes(downvotes => ++downvotes)
                setIsDownvoted(true)
                deltaUp = 0
                deltaDown = 1
            }
        }

        dispatchVotes(deltaUp, deltaDown)
        isBlockedRef.current = false
    }

    const dispatchVotes = (deltaUp, deltaDown) => {
        /**
         * Dispatch function to update votes in LS or other react components.
         */
        updateVotesLs(deltaUp, deltaDown)
        const userVoted = getUsersVoteStatus(deltaUp, deltaDown)
        if (updateMsgCacheVotes && isDiscussionMsg) {
            updateMsgCacheVotes(nbr_itemId, deltaUp, deltaDown, userVoted)
        }
        if (isDiscussionMsg === false && updateDiscussionPostVotes) {
            updateDiscussionPostVotes(nbr_itemId, deltaUp, deltaDown, userVoted)
        }
        if (isDiscussionMsg === true && updateDiscussionMsgVotes) {
            updateDiscussionMsgVotes(nbr_itemId, deltaUp, deltaDown, userVoted)
        }
        if (updateDocVote) {
            updateDocVote(nbr_itemId, deltaUp, deltaDown, userVoted)
        }
        if (updateFilterDataVotes) {
            updateFilterDataVotes(nbr_itemId, deltaUp, deltaDown, userVoted)
        }
    }

    return (
        <div className="votes no-symbol-hover">
            <span
                className={`icon icon-votes icon-upvotes ${isUpvoted ? 'is-voted' : ''} ${isUserAuthor ? 'is-author' : ''}`}
                onClick={isUserAuthor ? undefined : () => vote(true)}
            >
                <AiOutlineLike />
            </span>
            <span className={`upvotes ${isUpvoted ? 'is-voted' : ''}`}>
                {upvotes}
            </span>
            <span
                className={`icon icon-votes icon-downvotes ${isDownvoted ? 'is-voted' : ''} ${isUserAuthor ? 'is-author' : ''}`}
                onClick={isUserAuthor ? undefined : () => vote(false)}
            >
                <AiOutlineDislike />
            </span>
            <span className={`downvotes ${isDownvoted ? 'is-voted' : ''}`}>
                {downvotes}
            </span>
        </div>
    )
}

Votes.propTypes = {
    nbr_itemId: PropTypes.number,
    nbr_userId: PropTypes.number.isRequired,
    nbr_itemAuthorId: PropTypes.number.isRequired,
    nbr_upvotes: PropTypes.number.isRequired,
    nbr_downvotes: PropTypes.number.isRequired,
    b_userVoted: PropTypes.bool,
    str_url: PropTypes.string.isRequired,
    str_prLsKey: PropTypes.string,
    str_secLsKey: PropTypes.string,
    str_terLsKey: PropTypes.string,
    fct_customLsUpdate: PropTypes.func
}

export default Votes
