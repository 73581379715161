/**
 * Intercepts DB requests that are labelled as 'private', that is, the user must authenticate
 * himself. For such requests a access token is sent along in the request header.
 * If the access token expires than another request is made trying to get a new access token
 * from the backend via the refresh token. If the refresh token is also invalid, the user
 * is logged out and has to log back in to use the platform.
 */
import axios from 'axios';
import AuthService from './auth_service';

const API_URL = 'http://127.0.0.1:8000';

const interceptRequest = () => {

    const instance = axios.create({
        API_URL,
        headers: { Authorization: `Bearer ${AuthService.getAccessToken()}`}
    });

    instance.interceptors.request.use((request) => {
        /* Check if access token is expired. */
        const nbr_exp = AuthService.getExpTime() * 1000; /* Backend time is [s]. */
        const nbr_now = new Date().getTime();            /* JS time is [ms]. */
        const b_isExpired = nbr_exp < nbr_now;

        if (!b_isExpired) return request;

        /* Request new access token via refresh token (if not expired). */
        const newRequest = axios.post(
            '/api/token/refresh/',
            { refresh: AuthService.getRefreshToken() }
        ).then(
            (response) => {
                /* Upate token data in LS. */
                AuthService.genUserLs(response.data);
                /* Update header of intercepted request. */
                request.headers = AuthService.authHeader();
                return request;
            },
            (error) => {
                /**
                 * The error that is expected from the refresh token URL is usually 401.
                 * This means the token has expired and the user must login in again.
                 * However, if there is any other thing that goes wrong with the refresh 
                 * token URL, log the user out; this is the safest error handling here.
                 */
                AuthService.logout();
                window.location.href = '/login';
            }
        )
        return newRequest;
    })
    
    return instance;
}

const AuthInterceptor = {
    interceptRequest: interceptRequest
};

export default AuthInterceptor;
