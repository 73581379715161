import React from 'react';

import InFieldsGroup from '../../../InFieldsGroup';
import FormBase from '../../../FormBase';
import BoolField from '../../../fields/BoolField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { GastroConsumeOptionsFormData as FormData } from '../../../util/form-config-data/InputFormGastroDataConfigs';

const InputFormConsumeOptions = () => {
    const data = useInputData()
    return (
        <FormBase str_title='Konsummöglichkeiten'>
            <InFieldsGroup b_hasBorder={false}>
                <BoolField
                    {...FormData.fields.hasLocalConsumption}
                    b_boolValue={data[FormData.fields.hasLocalConsumption.str_id][0]}
                />
                <BoolField
                    {...FormData.fields.hasPickupConsumption}
                    b_boolValue={data[FormData.fields.hasPickupConsumption.str_id][0]}
                />
                <BoolField
                    {...FormData.fields.hasDeliveryConsumption}
                    b_boolValue={data[FormData.fields.hasDeliveryConsumption.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormConsumeOptions
