import React, { useState } from 'react';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import PPDataGroup from '../../../components/profile-pages/PPDataGroup';
import PPMainContentBase from '../PPMainContentBase';
import TbAbos from '../../tables/users/TbAbos';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import useGetPPdataGroupData from '../../../custom-hooks/useGetPPdataGroupData';

import IndustryProfileData from '../../../components/profile-pages/industry/IndustryProfileData';

const CATEGORY = 'info';
const URL = '/api/profiles/company/profile-page/info/';

const IndustryPPInfo = () => {
    
    const [data, setData] = useState('')
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    useSetBrowserTabTitle('Info', true)
    useGetPPdataGroupData(URL, CATEGORY, setData, setIsFirstFetchSuccess, setErrorMsg)

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            b_isContentSection={true}
            str_errorMsg={errorMsg}
            firstQueryDataEntry={1} /* Only placeholder value (data is always available here). */   
        >
            <PPDataGroup
                str_header='Firmenzahlen'
                str_editUrl='numbers/edit'
                obj_data={data ? data.numbers : null}
                obj_dataStructure={IndustryProfileData.numbers}
            />
            <PPDataGroup
                str_header='Slogan/Kurzbeschreibung'
                str_editUrl='slogan/edit'
                obj_data={data ? data.slogan : null}
                obj_dataStructure={IndustryProfileData.slogan}
            />
            <PPDataGroup
                str_header='Firmenbeschreibung'
                str_editUrl='descriptions/edit'
                obj_data={data ? data.descriptions : null}
                obj_dataStructure={IndustryProfileData.descriptions}
            />
            <PPDataGroup
                str_header='Mitarbeitervorteile'
                str_editUrl='benefits/edit'
                obj_data={data ? data.benefits : null}
                obj_dataStructure={IndustryProfileData.benefits}
            />
            <PPDataGroup
                str_header='Arbeitgeber mit Chancengleichheit'
                str_editUrl='equal-opportunity-employer/edit'
                obj_data={data ? data.equalOpsEmployer : null}
                obj_dataStructure={IndustryProfileData.equalOpsEmployer}
            />
            <PPDataGroup
                str_header='Logo'
                str_editUrl='logo/edit'
                obj_data={data ? data.logo : null}
                obj_dataStructure={IndustryProfileData.logo}
                b_isDataFilePath={true}
            />
            <PPMainContentBase str_header='Abos'>
                <TbAbos arr_abos={data ? data.abos : null} />
            </PPMainContentBase>
            <PPMainContentBase
                str_header='Passwort'
                str_editUrl='password/change'
            />
        </DataStateCheckWrapper>
    )
}

export default IndustryPPInfo
