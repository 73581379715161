import React from 'react';
import PropTypes from 'prop-types';
import { HiOutlineMail } from 'react-icons/hi';

import { copyData2Clipboard, trimStudmail } from '../../../util/data_handler';

const TdEmailTextAndSymbol = ({
    str_email,
    str_classes=''
}) => {
    
    const handleIconOnClick = (e) => {
        const str_value = e.target.closest('td').getAttribute('data-value')
        if (!str_value) return
        copyData2Clipboard(str_value)
    }
    
    return (
        <>
            {
                str_email
                ?
                <td className={`td-text-lg-screen text-lowercase ${str_classes}`}>
                    {trimStudmail(str_email)}
                </td>
                :
                <td className={`td-text-lg-screen text-lowercase ${str_classes}`}>—</td>
            }
            {
                str_email
                ?
                <td
                    className={`td-icon td-icon-sm-screen td-email-icon ${str_classes}`}
                    data-value={str_email}
                >
                    <HiOutlineMail onClick={e => handleIconOnClick(e)} />
                </td>   
                :
                <td className={`td-icon td-icon-sm-screen ${str_classes}`}>—</td>
            }
        </>
    )
}

TdEmailTextAndSymbol.propTypes = {
    str_email: PropTypes.string.isRequired,
    str_classes: PropTypes.string
}

export default TdEmailTextAndSymbol
