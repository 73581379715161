import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CgClose } from 'react-icons/cg';

import FieldWrapper from './FieldWrapper';
import InFieldConfirmationValue from '../InFieldConfirmationValue';

import { isRequiredFulfilled } from '../util/input_checks';
import { 
    useHandleInputChange,
    useAreRequiredErrorsActive
} from '../context-provider/InputFormContextProvider';
import FieldErrorMsgs from '../util/input_form_fields_error_msgs.json';
import { computeDropdownHeight } from '../util/input_form_data_handler';
import { useIsConfirmationViewOpenContext } from '../context-provider/FormBaseContextProvider';

const REQUIRED_EMSG = FieldErrorMsgs.required;
const TOP_INFO_TEXT = "Min. eine Option muss gewählt werden.";
const MAX_DRODPDOWN_HEIGHT = 189; /* px */

const MultipleChoiceField = ({
    str_id,
    str_fieldTitle='',
    str_bottomInfoText='',
    /* Multiples of 30 required. Digit input only (e.g. 30 NOT 30px). Default are 6 entries. */
    nbr_heightInPixels=MAX_DRODPDOWN_HEIGHT, /* Max. num of dropdown items shown. */
    arr_options,
    arr_currentOptions=[],
    b_isRequired=false
}) => {

    const [isOpen, setIsOpen] = useState(false)
    const [isClosing, setIsClosing] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState(arr_currentOptions)
    const [errorMsg, setErrorMsg] = useState('')
    const displayBarRef = useRef()
    const dropdownHeightInPixelsRef = useRef(computeDropdownHeight(arr_options.length))
    /* Flag to avoid error with dropdown error display. If the user clicks
     * somewhere on the page, the event listener of this component fires 
     * to keep track of closing the dropdown menu. If the meny has never been
     * open, the event listener must not show any errors (e.g. required error). */
    const wasMenuOpenRef = useRef(false)

    /* Varibles to keep track of dropdown elements that the 
     * use scrolled through via the 'tab' button. */
    const kint_dropdownElsMaxLength = arr_options.length
    const arr_dropdownEls = useRef([])
    
    const handleInputChange = useHandleInputChange()
    const isRequiredErrorActivate = useAreRequiredErrorsActive()
    const isConfirmationViewOpen = useIsConfirmationViewOpenContext()
    
    const hasError = (arr_currentOptions) => {
        /**
         * Provide the option to hand the current array options to this function.
         * In case React has too little time to update its state before this function
         * is called, use the input value instead of the state 'selectedOptions'.
         * :Input
         *  arr_currentOptions: Latest selected options.
         */
        if (!b_isRequired) return false
        const data = arr_currentOptions ? arr_currentOptions : selectedOptions
        if (isRequiredFulfilled(data, false, true)) return false
        return true
    }

    useEffect(() => {
        /**
         * Listen to click on the page to be able to close the dropdown if it is
         * open and the user clicks elements on the page that are neither the dropdown bar
         * nor one of the dropdown elements.
         */
        const setStates = () => {
            if (wasMenuOpenRef.current) {
                if (hasError()) setErrorMsg(REQUIRED_EMSG)
                else setErrorMsg('')
                setIsClosing(true)
            }
        }

        const handleClick = (e) => {
            /**
             * Closes the dropdown menu if it is open and the user clicks somewhere on the page
             * that is not the dropdown menu bar or one of its items.
             */
            const target = e.target
            const isSelectedBar = target.classList.contains('mselect-selected')
            if (isSelectedBar) {
                const selectTag = target.previousElementSibling
                if (selectTag.id !== str_id) setIsOpen(false)
            } else {
                const openEl = document.querySelector('.is-open')
                if (openEl) {
                    try {
                        const parent = target.parentElement
                        if (parent.classList.contains('mselect-items')) return
                    } catch {}
                    const select = openEl.previousElementSibling
                    if (select.id === str_id) setStates()
                }
            }
        }

        window.addEventListener('click', handleClick)
        return () => { window.removeEventListener('click', handleClick) }
    
    }, [selectedOptions])

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        /* Clean up component on un-mount. */
        return () => window.removeEventListener('keydown', handleKeyDown)
    }, [isOpen, selectedOptions])
    
    useMemo(() => {
        /* When the input form context provider renders, check if this
         * components state must be updated. If, for example, a filter 
         * input form is cleard, this hook allows changing the state. */
        setSelectedOptions(arr_currentOptions)
    }, [arr_currentOptions])

    useMemo(() => {
        /* All the required fields that are blank and do not show erros,
         * are activated when a form is submitted. The line below is only
         * executed once. After the first execution, the required field
         * displays error messages until the input is correct. */
        if (isRequiredErrorActivate && b_isRequired && hasError()) setErrorMsg(REQUIRED_EMSG) 
    }, [isRequiredErrorActivate])

    useEffect(() => {
        /**
         * If the component's state is set to isClosing, this hook triggers
         * a wait function to set the state to isOpen = false after waiting
         * for the animation to be done running. The animation duration must
         * be in sync with the animation duration in animations.scss.
         */
        if (isClosing) {
            setTimeout(() => {
                setIsOpen(false)
                setIsClosing(false)
            }, 400)
        }
    }, [isClosing])
    
    const handleDataToParent = (arr_currentOptions=null, b_hasErrorInput=false) => {
        /* If the input argument is not null, then the latest options might not yet 
            * have been applied to the state, thus, the code sends the input argument
            * up to the parent component. */
        const arr_tempSelectedOptions = arr_currentOptions ? [...arr_currentOptions] : [...selectedOptions]
        const b_hasError = b_hasErrorInput ? b_hasErrorInput : hasError(arr_currentOptions)
        handleInputChange({id: str_id, value: arr_tempSelectedOptions, hasError: b_hasError})
    }

    const handleKeyDown = (e) => {
        /* Close dropdown menu if 'esc' is pressed. */
        if ((e.key === 'Escape') && isOpen) {
            const b_hasError = hasError()
            if (b_hasError) setErrorMsg(REQUIRED_EMSG)
            else setErrorMsg('')
            setIsClosing(true)
            handleDataToParent(null, b_hasError)
            displayBarRef.current.focus()
        }
    }

    const barOnClick = () => {
        /* Sequence is important! First open/close, then display the error. */
        if (isOpen) {
            /* The menu is going to be closed. Check/display error when it is closed. */
            const b_hasError = hasError()
            if (b_hasError) setErrorMsg(REQUIRED_EMSG)
            else setErrorMsg('')
            handleDataToParent(null, b_hasError)
        } else {
            /* Check if any other menu is open in the DOM. This is indicated by the 
             * class .custom-select-selected. If one is found, click it, this triggers 
             * the function handleCustomSelectSelected() in this file. This function 
             * then closes the other menu and displays its error message accordingly. */
            const dom_el = document.querySelector('.custom-select-selected')
            if (dom_el) dom_el.click()
            /* Set was menu open flag. */
            wasMenuOpenRef.current = true;
        }
        if (!isOpen) setIsOpen(true)
        else setIsClosing(true)
    }

    const handleCustomSelectSelected = (e) => {
        /* This element 'e' can ONLY be clicked programmatically by the code 
         * in the function barOnClick(). Therefore, it listens to a click event.
         * If the user clicks the bar or an item, it fires an onMouseDown event to
         * avoid problems with the char counter of the text field. */
        if (e.target.classList.contains('custom-select-selected')) {
            setIsOpen(!isOpen)
            setIsClosing(false)
            if (hasError()) setErrorMsg(REQUIRED_EMSG)
            else setErrorMsg('')
        }
    }

    /* Functionality for dropdown menu items. */

    const itemOnClick = (e) => {
        const kstr_el = e.target.innerHTML
        let arr_newOptions;
        if (selectedOptions.includes(kstr_el)) {
            arr_newOptions = selectedOptions.filter(soption => soption !== kstr_el)
        } else {
            arr_newOptions = [...selectedOptions, kstr_el]
        }
        setSelectedOptions(arr_newOptions)
        handleDataToParent(arr_newOptions)
    }

    const itemOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            itemOnClick(e)
        }
        else if (e.key === 'ArrowUp') {
            /* Make sure that at least one item is stored in the array, 
             * otherwise pop() causes an error. */
            if (arr_dropdownEls.current.length > 0) {
                /* Prevent scrolling the window. */
                e.preventDefault()
                /* Prop the last focused element. */
                const el = arr_dropdownEls.current.pop()
                /* Set focus to the previous element. */
                el.focus()
            }
        }
        else if (e.key === 'Tab') {
            arr_dropdownEls.current.push(e.target)
            if (arr_dropdownEls.current.length === kint_dropdownElsMaxLength) {
                setIsOpen(false)
                if (b_isRequired && isRequiredFulfilled(selectedOptions, setErrorMsg, false, true)) {
                    setErrorMsg('')
                }
                arr_dropdownEls.current = []
            }
        }
    }

    const removeItem = (e) => {
        /**
         * Removes a selected option from the selected options when
         * user clicks on the item boxes listed below the input field.
         */
        e.preventDefault()
        const target = e.target
        let removedOption;
        if (target.tagName === 'path') {
            removedOption = target.parentElement.getAttribute('value')
        } else if (target.tagName === 'svg') {
            removedOption = target.getAttribute('value')
        }
        const newSelectedOptions = selectedOptions.filter(option => option !== removedOption)
        const b_hasError = hasError(newSelectedOptions)
        if (b_hasError) setErrorMsg(REQUIRED_EMSG)
        else setErrorMsg('')
        setSelectedOptions(newSelectedOptions)
        handleDataToParent(newSelectedOptions, b_hasError)
    }

    return (
        isConfirmationViewOpen
        ?
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            b_isRequired={false}
        >
            <InFieldConfirmationValue value={selectedOptions.map(item => item.toString().join(', '))} />
        </FieldWrapper>
        :
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            str_errorMsg={errorMsg}
            str_topInfoText={b_isRequired ? TOP_INFO_TEXT : ''}
            str_bottomInfoText={str_bottomInfoText}
            b_isRequired={b_isRequired}
        >
            <div 
                className={`in-field custom-mselect ${isOpen ? 'custom-select-selected' : ''}`}
                onClick={e => handleCustomSelectSelected(e)}
            >
                {/* Elemements for the input form. */}
                <select
                    id={str_id}
                    required={b_isRequired}
                    multiple
                    value={selectedOptions}
                    readOnly
                >
                    {arr_options.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>

                {/* Dropdown bar. */ }
                <div className={`mselect-selected ${isOpen ? 'is-open' : ''}`}
                    tabIndex={0}
                    ref={displayBarRef}
                    onMouseDown={() => barOnClick()}
                    onKeyDown={(e) => { if (e.key === 'Enter') {barOnClick()} }}
                >
                    Wähle Optionen
                </div>

                {
                    /* Elements the user engages with (artifical elements). */
                    isOpen &&
                    <div
                        className={
                            `mselect-items dropdown-rolldown-animation
                            ${isClosing ? 'dropdown-rollup-animation' : ''}`
                        }
                        style={
                            nbr_heightInPixels < dropdownHeightInPixelsRef.current
                            ?
                            {height: `${nbr_heightInPixels}px`, borderBottomRightRadius: '0'}
                            :
                            dropdownHeightInPixelsRef.current.toFixed(1) > MAX_DRODPDOWN_HEIGHT
                            ?
                            {height: `${MAX_DRODPDOWN_HEIGHT}px`, borderBottomRightRadius: '0'}
                            :
                            {height: `${dropdownHeightInPixelsRef.current}px`, overflow: 'hidden'}
                        }
                    >
                        {arr_options.map((option, index) => (
                            <div
                                key={index}
                                className={selectedOptions.includes(option) ? 'is-selected' : ''}
                                tabIndex={0}
                                onClick={itemOnClick}
                                onKeyDown={itemOnKeyDown}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                }
            </div>

            <div className="selected-options-btns">
                {
                    selectedOptions.map((item, index) => (
                        <span key={index}>
                            <div className="selected-options-btn">
                                <span className="value">{item}</span>
                                <span className="close-icon">
                                    <CgClose
                                        value={item}
                                        onClick={removeItem}
                                    />
                                </span>
                            </div>
                        </span>
                    ))
                }
            </div>
        </FieldWrapper>
    )
}

MultipleChoiceField.propTypes = {
    str_id: PropTypes.string.isRequired,
    str_fieldTitle: PropTypes.string,
    nbr_heightInPixels: PropTypes.number,
    str_bottomInfoText: PropTypes.string,
    arr_options: PropTypes.array.isRequired,
    arr_currentOptions: PropTypes.array,
    b_isRequired: PropTypes.bool
}

export default MultipleChoiceField
