import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import SingleChoiceField from './SingleChoiceField';

import FieldLimits from '../util/db_field_limits.json';
import { useHandleInputChange } from '../context-provider/InputFormContextProvider';

const IS_ABROAD_OPTIONS = ['Ja', 'Nein'];
const COUNTY_OPTIONS = FieldLimits.work.counties;
const DISTRICT_OPTIONS = FieldLimits.work.districts;

const JobCountyDistrictFields = ({
    str_isAbroadFieldId,
    str_countyFieldId,
    str_districtFieldId,
    str_currentAbroadJobOption,
    str_currentCountyOption='',
    str_currentDistrictOption=''
}) => {

    const handleInputChange = useHandleInputChange()

    useEffect(() => {
        /**
         * Hide county and district fields if job is marked as abroad job.
         */
        if (str_currentAbroadJobOption === 'Ja') {
            handleInputChange({
                id: str_countyFieldId,
                value: '',
                hasError: false
            })
        }
    }, [str_currentAbroadJobOption])

    useEffect(() => {
        /**
         * Hide the district input field, if no county is selected.
         */
        if (!str_currentCountyOption) {
            str_currentDistrictOption = '';
            handleInputChange({
                id: str_districtFieldId,
                value: '',
                hasError: false
            })
        }
    }, [str_currentCountyOption])

    return (
        <>
            {/* Is job abroad. */}
            <SingleChoiceField
                str_id={str_isAbroadFieldId}
                str_fieldTitle='Im Ausland'
                str_currentOption={str_currentAbroadJobOption}
                arr_options={IS_ABROAD_OPTIONS}
            />
            {
                /* County field. */
                str_currentAbroadJobOption !== 'Ja' &&
                <SingleChoiceField
                    str_id={str_countyFieldId}
                    str_fieldTitle='Bundesland'
                    str_currentOption={str_currentCountyOption}
                    arr_options={COUNTY_OPTIONS}
                />
            }
            {
                /* District field */
                str_currentAbroadJobOption !== 'Ja' && str_currentCountyOption &&
                <SingleChoiceField
                    str_id={str_districtFieldId}
                    str_fieldTitle='Bezirk'
                    str_currentOption={str_currentDistrictOption}
                    arr_options={DISTRICT_OPTIONS[str_currentCountyOption]}
                />
            }
        </>
    )
}

JobCountyDistrictFields.propTypes = {
    str_isAbroadFieldId: PropTypes.string.isRequired,
    str_countyFieldId: PropTypes.string.isRequired,
    str_districtFieldId: PropTypes.string.isRequired,
    str_currentAbroadJobOption: PropTypes.string,
    str_currentCountyOption: PropTypes.string,
    str_currentDistrictOption: PropTypes.string,
}

export default JobCountyDistrictFields
