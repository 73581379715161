import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from './TextField';

import { PasswordCreationFormData as FormData } from '../util/form-config-data/InputFormPasswordDataConfigs';
import { useHandleInputChange } from '../context-provider/InputFormContextProvider';

const PasswordCreationFields = ({ str_id }) => {
    
    const [password, setPassword] = useState('')
    const [confirmation, setConfirmation] = useState('')
    const [hasPasswordError, setHasPasswordError] = useState(false)
    const [hasConfirmationError, setHasConfirmationError] = useState(false)
    const [hasEqualError, setHasEqualError] = useState(true)

    const handleInputChange = useHandleInputChange()

    const handleErrors = (b_isEqual) => {
        if (b_isEqual) {
            setHasEqualError(false)
        } else {
            setHasEqualError(true)
        }
    }

    const isPwConfirmEqual = (str_data, b_isPwConfirmation) => {
        /**
         * Checks if the password and confirmation values are identical.
         */
        if (b_isPwConfirmation) {
            setConfirmation(str_data)
            /* No check against empty field or if field itself has an error. */
            if (!password || hasPasswordError) return true
            const b_isEqual = str_data === password
            handleErrors(b_isEqual)
            return b_isEqual
        } else {
            setPassword(str_data)
            /* No check against empty field or if field itself has an error. */
            if (!confirmation || hasConfirmationError) return true
            const b_isEqual = str_data === confirmation
            handleErrors(b_isEqual)
            return b_isEqual
        }
    }

    const data2contextProvider = (obj_data) => {
        if (obj_data.isPwConfirmation) {
            setConfirmation(obj_data.value)
            setHasConfirmationError(obj_data.hasError)
        } else {
            setPassword(obj_data.value)
            setHasPasswordError(obj_data.hasError)
        }

        const arr_value = obj_data.isPwConfirmation ?
                          [password, obj_data.value] :
                          [obj_data.value, confirmation]
        const b_hasError = obj_data.isPwConfirmation ?
            obj_data.hasError || hasPasswordError || obj_data.value !== password :
            obj_data.hasError || hasConfirmationError || obj_data.value !== confirmation
        
        handleInputChange({
            id: str_id,
            value: arr_value,
            hasError: b_hasError
        })
    }
    
    return (
        <>
            <TextField
                {...FormData.fields.password}
                str_chars={password}
                fct_isPwConfirmEqual={isPwConfirmEqual}
                fct_pwData2ContextProvider={data2contextProvider}
            />
            <TextField
                {...FormData.fields.confirmation}
                str_chars={confirmation}
                b_hasPwConfirmEqualError={hasEqualError}
                fct_isPwConfirmEqual={isPwConfirmEqual}
                fct_pwData2ContextProvider={data2contextProvider}
                b_isPwConfirmation={true}
            />
        </>
    )
}

PasswordCreationFields.propTypes = {
    str_id: PropTypes.string.isRequired
}

export default PasswordCreationFields
