import React from 'react'

const AccordionListItem = ({ children }) => {
    return (
        <li>
            <p>
                {children}
            </p>
        </li>
    )
}

export default AccordionListItem
