import React from 'react';
import { useParams } from 'react-router-dom';

import NoPageContentPlaceholder from '../../components/general/NoPageContentPlaceholder';
import CSecBase from '../../components/content-sections/CSecBase';
import DynamicContentLoadBtn from '../../components/buttons/DynamicContentLoadBtn';
import TbRankingBase from '../../components/tables/mining/TbRankingBase';
import DynamicContentLoadContextProvider from '../../components/context-provider/DynamicContentLoadContextProvider';
import FilterStateContextProvider from '../../components/filters/context-provider/FilterStateContextProvider';
import FilterMining from '../../components/filters/filters/mining/FilterMining';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';
import useRemoveLSitemsUnmount from '../../custom-hooks/useRemoveLSitemsUnmount';
import { genDynUrl } from '../../util/db_ls_query_handler';

import DynLoadPackageSizes from '../../util/config/data_load_package_sizes.json';
import { FilterLsKeys } from '../../util/LocalStorageVariables';

const genSubtitle = (param) => {
    /**
     * Creates the section content subtitle according to the displayed mining data.
     * :Input
     *  param (str): Url param to decide which mining content to load.
     * :Returns
     *  Subtitle (str)
     */
    if (param === 'overall') {
        return 'Gesamt';
    } else if (param === 'this-semester') {
        return 'Dieses Semester';
    } else {
        return '';
    }
}

const filterLsKey = (param) => {
    /**
     * Returns the filter LS key according to the displayed mining data.
     */
    if (param === 'overall') return FilterLsKeys.overallMiningScoreboard;
    return FilterLsKeys.thisSemesterMiningScoreboard;
}

const isUrlParamAllowed = (param) => {
    /**
     * Checks it the dyn. url param for the mining data exists.
     * :Input
     *  param (str): Url param to decide which mining content to load.
     * :Returns
     *  Bool: True: If param is allowed. False: Otherwise.
     */
    const allowedParams = ['this-semester', 'overall']
    if (allowedParams.includes(param)) return true;
    return false;
}

const BASE_URL = '/api/mining/mining-standings/';
const LS_ITEMS = [
    FilterLsKeys.overallMiningUser,
    FilterLsKeys.overallMiningScoreboard,
    FilterLsKeys.thisSemesterMiningUser,
    FilterLsKeys.thisSemesterMiningScoreboard
];

const MiningPage = () => {
    
    const params = useParams()
    const subtitle = genSubtitle(params.category)
    const isParamAllowed = isUrlParamAllowed(params.category)
    const url = genDynUrl(BASE_URL, params.category)
    const filterKey = filterLsKey(params.category)
    
    useSetBrowserTabTitle('Mining')

    /* Remove the queried mining data when the user leaves the mining page. */
    useRemoveLSitemsUnmount(LS_ITEMS)

    return (
        /* Block any data queries if category does not exist. */
        isParamAllowed
        ?
        <FilterStateContextProvider>
            <DynamicContentLoadContextProvider
                str_queryURL={url}
                nbr_packageSize={DynLoadPackageSizes.mining.mining}
                b_useLS={true}
                str_lsKey={filterKey}
                b_hasFetchById={false}
                nbr_avoidRefreshForMin={3.6e6} /* Do not refresh LS for 1h. */
            >
                <CSecBase
                    str_title='Mining Table'
                    str_subtitle={subtitle}
                    b_isUsedAsTitle={true}
                />
                <FilterMining />
                <CSecBase>
                    <TbRankingBase />
                    <DynamicContentLoadBtn />
                </CSecBase>
            </DynamicContentLoadContextProvider>
        </FilterStateContextProvider>
        :
        <NoPageContentPlaceholder />
    )
}

export default MiningPage
