import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import InputFormPageBase from '../../components/input-forms/InputFormPageBase';
import ProfileSetupProgress from '../../components/profile-setup/ProfileSetupProgress';
import ProfileSetupSettingsChangeMsg from '../../components/profile-setup/ProfileSetupSettingsChangeMsg';
import { static2dynamicPanelConfig } from '../../components/input-forms/util/input_form_data_handler';
import {
    useConfigDataContext,
    useSetupStepContext,
    useNavigate2nextSetupPageContext
} from '../../components/profile-setup/context-provider/ProfileSetupContextProvider';

const ProfileSetupStep = () => {

    const navigate = useNavigate()
    const params = useParams()
    const configData = useConfigDataContext()
    const setupStep = useSetupStepContext()
    const navigate2nextSetupPage = useNavigate2nextSetupPageContext()
    const [isNavigateChecked, setIsNavigateChecked] = useState(false)

    useEffect(() => {
        /**
         * Redirect user to active setup step if wrong URL is submitted.
         */
        /* Check for setup completeness. */
        if (setupStep === 'complete') {
            navigate('../success', { replace: true })
        }
        /* Check for correct setup step. Always link to the current step. */
        else if (params.setupStep !== configData[setupStep].urlFormat) {
            navigate(`../${configData[setupStep].urlFormat}`, { replace: true })
        }
        setIsNavigateChecked(true)
        window.scrollTo(0, 0)
    }, [navigate])

    return (
        isNavigateChecked &&
        <InputFormPageBase
            {...static2dynamicPanelConfig(
                configData[setupStep].formConfig,
                { str_queryURL: configData[setupStep].url }
            )}
            str_requestType='put'
            str_gridClass='progress-ladder-grid'
            b_hasMediaData={configData[setupStep].hasMediaData}
            b_hasDiscardBtn={false}
            fct_handlePost={navigate2nextSetupPage}
            comp_form={configData[setupStep].form}
            comp_header={<ProfileSetupProgress />}
            comp_footer={<ProfileSetupSettingsChangeMsg />}
        />
    )
}

export default ProfileSetupStep
