import React from 'react';
import PropTypes from 'prop-types';

import CardPriceTag from '../CardPriceTag';

const CardFoodFooterGastroRepeat = ({
    nbr_price,
    nbr_available,
    fct_handleStop 
}) => {
    return (
        <div className="card-footer-offer">
            <CardPriceTag nbr_price={nbr_price} />
            <div className="action-el">
                <button
                    className="btn btn-sm btn-submit"
                    onClick={fct_handleStop}
                >
                    Stoppen
                </button>
                <p>
                    Möchtest du das Angebot beenden?
                    <br />
                    Dann klicke 'Stoppen'.
                </p>
            </div>
            {
                nbr_available &&
                <div className="add-info">
                    <div className="add-info-wrapper">
                        <p className="nowrap">
                            <span className="info-nums">
                                <span className="info">{nbr_available}</span>
                            </span>
                            <span className="info num-title">Stück pro Angebot</span>
                        </p>
                    </div>
                </div>
            }
        </div>
    )
}

CardFoodFooterGastroRepeat.propTypes = {
    nbr_price: PropTypes.number.isRequired,
    nbr_available: PropTypes.number,
    fct_handleStop: PropTypes.func.isRequired
}

export default CardFoodFooterGastroRepeat
