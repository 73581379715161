import React from 'react';
import PropTypes from 'prop-types';

const CardCouponBody = ({ description }) => {
    return (
        <div className="card-body-store-coupon">
            <div className="description">
                <p>{description}</p>
            </div>
        </div>
    )
}

CardCouponBody.propTypes = {
    description: PropTypes.string.isRequired
}

export default CardCouponBody
