import React from 'react';
import { useMsgContext } from './context-provider/MessageContextProvider';

const MessageStdHeader = () => {
    const msg = useMsgContext()
    return (
        <div className="msg-header">
            {
                msg.courseTitle &&
                <div className="msg-add-info-header">
                    {
                        msg.courseTitle &&
                        <span>{msg.courseTitle}</span>
                    }
                    {
                        msg.courseCategory &&
                        <span>{msg.courseCategory.toUpperCase()}</span>
                    }
                </div>
            }

            {
                msg.category &&
                <div className={`msg-type msg-type-${msg.category.toLowerCase()}`}>
                    {msg.category}
                </div>
            }

            {
                msg.author &&
                <div className="msg-header-content author">
                    <div className="msg-header-content-overview">
                        <span className="author-name">
                            <span className="value">
                                <span className="full-name">
                                    <span className="author">{msg.author}</span>
                                </span>
                                <span className="username">@{msg.username}</span>
                            </span>
                        </span>
                        {
                            msg.postDate &&
                            <span className="date-posted">
                                <span className="value">{msg.postDate}</span>
                            </span>
                        }
                        {
                            msg.updateDate &&
                            <span className="date-updated">
                                {
                                    <span>
                                        <span className="key">Akt.</span>
                                        <span className="value">{msg.updateDate}</span>
                                    </span>
                                }
                            </span>
                        }
                    </div>
                </div>
            }
        </div>
    )
}

export default MessageStdHeader
