import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import FieldErrorMsgs from '../util/input_form_fields_error_msgs.json';
import {
    useSetFilterDataContext,
    useIsDynContextLoading,
    useLoadedFromCacheTriggerContext
} from '../../context-provider/DynamicContentLoadContextProvider';
import { inputForm2dbData } from '../util/input_form_data_handler';
import { useUpdatePostsContext } from '../../discussion-pages/context-provider/DiscussionContextProvider';
import { useOpenCloseFormContext } from '../../content-sections/context-provider/DiscussionNewPostContextProvider';
import {
    useSetIsCommentFormOpenContext,
    useSetIsEditFormOpenContext
 } from '../../messages/context-provider/MessageContextProvider';
import {
    useFilterStateContext,
    useSetFilterStateContext
} from '../../filters/context-provider/FilterStateContextProvider';
import { sendData2db, sendFileData2db } from '../../../util/db_ls_query_handler';

const InputDataContext = React.createContext();
const HandleDiscardSubmitFctContext = React.createContext();
const HandleInputChangeContext = React.createContext();
const ChangeDataContext = React.createContext();
const AreRequiredErrorsActiveContext = React.createContext();
const SetAreRequiredErrorsActiveContext = React.createContext();
const HasFormDiscardBtnContext = React.createContext();
const HasInputErrorContext = React.createContext();
const RequestErrorMsgContext = React.createContext();
const IsLoadingContext = React.createContext();
const DiscardPressedContext = React.createContext();

export function useInputData() {
    return useContext(InputDataContext);
}

export function useHandleDiscardSubmitFct() {
    return useContext(HandleDiscardSubmitFctContext);
}

export function useHandleInputChange() {
    return useContext(HandleInputChangeContext);
}

export function useChangeData() {
    return useContext(ChangeDataContext);
}

export function useAreRequiredErrorsActive() {
    return useContext(AreRequiredErrorsActiveContext);
}

export function useSetAreRequiredErrorsActive() {
    return useContext(SetAreRequiredErrorsActiveContext);
}

export function useHasFormDiscardBtn() {
    return useContext(HasFormDiscardBtnContext);
}

export function useHasInputError() {
    return useContext(HasInputErrorContext);
}

export function useRequestErrorMsg() {
    return useContext(RequestErrorMsgContext);
}

export function useIsLoading() {
    return useContext(IsLoadingContext);
}

export function useDiscardPressed() {
    return useContext(DiscardPressedContext);
}

const SUBMIT_DATA_ERROR_MSG = FieldErrorMsgs.submission.fail;
const INPUT_FORM_ID_MANIPULATION_ERROR_MSG = FieldErrorMsgs.malicious.inputFormIdManipulation;

const selectiveProposalField2searchBarValue = (fieldValue) => {
    /**
     * Converts the field value of the SelectiveProposalField.js to a value
     * that is human readable for the browser search bar.
     * :Input
     *  fieldValue (arr): Items are objects of format {id: ...(int), <someKey>: ...(char)}
     * :Returns
     *  On success: Search bar value (str)
     *  Otherwise: null
     */
    let retValue = '';
    try {
        fieldValue.forEach(fieldValue => {
            let cnt = 0; /* Check for the correct item format. */
            Object.keys(fieldValue).forEach(key => {
                if (key !== 'id') {
                    retValue += fieldValue[key] + '+';
                }
                cnt++;
            })
            if (cnt > 2) return null;
        })
        return retValue.slice(0, retValue.length-1);
    } catch {
        return null;
    }
}

const InputFormContextProvider = ({
    str_queryURL,
    str_requestType='post',
    str_navigationURLonDiscard='..',
    obj_initState,               /* Data that is present in the input fields on init. */
    obj_clearedState,            /* State of all input fields being empty. */
    b_isFilterContext=false,     /* Context for a filter panel. */
    b_isDiscussionContext=false, /* Context for discussion content. */
    b_hasDiscardBtn=true,        /* Button to discard from. */
    b_isPrivatePost=true,        /* True if user must be authenticated for the post request. */
    b_hasMediaData=false,        /* True if files are sent along with the form data. */
    b_isLoginForm=false,
    fct_response2parent=null,    /* Send the successfully retrieved response data to parent component. */
    children
}) => {
    
    let navigate = useNavigate()
    const [, setSearchParams] = useSearchParams()
    
    const [data, setData] = useState(obj_initState)
    const hasDataChangedRef = useRef(false)
    const isLockedRef = useRef(false)
    /* Spinner state if form is submitted. */
    const [isLoading, setIsLoading] = useState(false)
    /* This state is used by the input fields to display required field error messages 
     * if they are not yet displayed. Show the user that these fields must be filled. */
    const [areRequiredErrorsActive, setAreRequiredErrorsActive] = useState(false)
    /* Error message that is set if DB request goes wrong. */
    const [requestErrorMsg, setRequestErrorMsg] = useState('')
    /* State that changes its binary value, if the user clicks discard.
     * Init. with undefined to notice if it the form has been submitted at least once. */
    const [discardPressedSwitch, setDiscardPressedSwitch] = useState()

    /* Custom hook for pages with filter functionality. */
    const setFilterContextData = useSetFilterDataContext()
    const filterState = useFilterStateContext()
    const setFilterState = useSetFilterStateContext()
    /* Custom hook to update the posts of a discussion content. */
    const updateDiscussionPosts = useUpdatePostsContext()
    /* Custom hook to open/close the form of a new discussion content post. */
    const setFormToOpen = useOpenCloseFormContext()
    /* Custom hook to open/close the form insidea message body (comments/edits). */
    const setIsCommentFormOpen = useSetIsCommentFormOpenContext()
    const setIsEditFormOpen = useSetIsEditFormOpenContext()
    /* Custom hook to keep track of the DynamicContentLoadContextProvider load state. */
    const isDynContentLoading = useIsDynContextLoading()
    const loadedFromLsTrigger = useLoadedFromCacheTriggerContext()

    const isLoginFormSubmitted = useRef(false)

    useEffect(() => {
        /**
         * Only relevant if the context provider is a filter context provider.
         * Clear the search params of the URL, as filtering via the URL is not possible.
         */
        if (b_isFilterContext) {
            if (filterState) {
                setBrowserSearchParams(filterState)
            } else {
                setSearchParams({})
            }
        }
    }, [])

    useEffect(() => {
        /* Newly incoming obj_initStates overwrite the current data.
         * This is not used for filters! As for filters there are no 
         * init state changes expected. Also, the filter values would
         * be overwritten with the empty state every time submit is hit. */
        if (b_isFilterContext) return
        setData(obj_initState)
    }, [obj_initState])

    useEffect(() => {
        /**
         * Unblocks the submit form when it receives the signal from the
         * DynamicContentLoadContextProvider.js.
         */
        if (!isDynContentLoading) {
            isLockedRef.current = false
            setIsLoading(false)
        }
    }, [isDynContentLoading])

    useEffect(() => {
        /**
         * If data is loaded from the LS, the 'loadFromLsTrigger' changes
         * its value. This indicates that the loading is done.
         */
        isLockedRef.current = false
        setIsLoading(false)
    }, [loadedFromLsTrigger])

    const hasError = () => {
        let b_hasError = false
        Object.values(data).forEach(val => { if (val[1]) { 
            b_hasError = true } })
        if (b_hasError) {
            /* Display error messages for unfilled required fields. */
            if (!areRequiredErrorsActive) setAreRequiredErrorsActive(true)
            /* Remove barrier, user is again able to submit data. */
            isLockedRef.current = false
            alert(SUBMIT_DATA_ERROR_MSG)
        }
        return b_hasError
    }

    const setBrowserSearchParams = (inFilterData) => {
        /**
         * Rel. only if this context provider is used as filter.
         * Converts the data stored in the input fields into a format
         * that can be displayed as search params in the browser search bar.
         */
        if (!inFilterData) setSearchParams({})
        let obj_params = {}
        Object.keys(inFilterData).forEach(fieldId => {
            const dataItem = inFilterData[fieldId][0]
            if (dataItem && dataItem !== []) {
                if (typeof(dataItem[0]) === 'object') {
                    const selectiveProposalSarchBarValue = selectiveProposalField2searchBarValue(dataItem)
                    if (selectiveProposalSarchBarValue) {
                        obj_params[fieldId] = selectiveProposalSarchBarValue
                    }
                } else {
                    obj_params[fieldId] = dataItem
                }
            }
        })
        setSearchParams(obj_params)
    }

    useEffect(() => {
        /**
         * This function is only relevant for the login input form.
         * It reacts on the autofill state setting.
         */
        if (isLoginFormSubmitted.current && data['username'][0] && data['password'][0]) {
            isLoginFormSubmitted.current = false
            hasDataChangedRef.current = true
            handleDiscardSubmit(true, null)
        }
    }, [data])

    const handleDiscardSubmit = (isSubmit, event) => {
        if (event) event.preventDefault()
        
        /* Set a barrier so that the user cannot cause multiple submissions 
         * by clicking the submit button multiple times. If a submission error
         * occurs, the barrier is removed again. */
        if (isLockedRef.current) return
        isLockedRef.current = true
        
        if (isSubmit) {
            
            /* Auto-fill check of the login panel. */
            if (b_isLoginForm) {
                const isFormAutofilled = handleAutofill()
                if (isFormAutofilled) return
            }

            setIsLoading(true)

            if (hasError()) {
                isLockedRef.current = false
                setIsLoading(false)
                return
            }
            
            if (hasDataChangedRef.current) {
                if (b_isFilterContext) {
                    /* For filtered data, the request  is made by the DynamicContentLoadContextProvider.js. */
                    setBrowserSearchParams(data)
                    setFilterContextData(inputForm2dbData(data))
                    /* Store filter in FilterStateContextProvider.js (preserve it over various URLs). */
                    if (setFilterState) setFilterState(data)
                    /* Note: The form submit is unblocked with a useEffect hook. */
                } else {
                    makeRequest()
                    /* Note: The form submit is unblocked by makeRequest(). */
                }
            } else {
                /* Filters: Do nothing, displayed data is the correct one. */
                if (!b_isFilterContext) {
                    /* Submission of error free unchanged data. Just display success page
                        * without any localStorage or DB intaractions. */
                    if (fct_response2parent) fct_response2parent()
                }
                isLockedRef.current = false
                setIsLoading(false)
            }
        } else {
            /* Discard button was pressed. */
            if (b_isFilterContext) {
                /* Clear filter field values. */
                setData(obj_clearedState ? obj_clearedState : obj_initState)
                /* Clear search params in URL. */
                setSearchParams({})
                /* Clear filter of DynamicContentLoadContextProvider.js (queries for no filter). */
                setFilterContextData(null)
                /* Clear FilterStateContextProvider.js. */
                if (setFilterState) setFilterState(null)
            }
            else if (b_isDiscussionContext) {
                if (setFormToOpen) {
                    /* Close input form of new message. */
                    setFormToOpen(false)
                } else {
                    /* Close input form of comment/edit (close both as only one can be open). */
                    setIsEditFormOpen(false)
                    setIsCommentFormOpen(false)
                }
            }
            else {
                navigate(str_navigationURLonDiscard, { replace: false })
            }
            isLockedRef.current = false
            /* Change switch value to indicate to other components that discard was pressed. */
            setDiscardPressedSwitch(!discardPressedSwitch)
        }
    }

    const makeRequest = async () => {
        /**
         * Send the input form data to the backend.
         * Success: Send response data to parent component.
         * Error: Display error message in input form.
         */
        const queryData = b_hasMediaData ?
            await sendFileData2db(str_requestType, str_queryURL, inputForm2dbData(data)) :
            await sendData2db(str_requestType, str_queryURL, inputForm2dbData(data), b_isPrivatePost)
        
        if (queryData.isQuerySuccess) {
            if (b_isDiscussionContext) {
                const msg = queryData.response.data
                if (setFormToOpen) {
                    /* New message. */
                    updateDiscussionPosts(msg, true)
                    setFormToOpen(false)
                } else {
                    /* Edit message. */
                    updateDiscussionPosts(msg, false)
                    /* Close input form of comment/edit (close both as only one can be open). */
                    setIsEditFormOpen(false)
                    setIsCommentFormOpen(false)
                }
                return
            }
            if (fct_response2parent) fct_response2parent(queryData.response)
            window.scrollTo(0, 0);
        }
        else {
            isLockedRef.current = false
            setIsLoading(false)
            setRequestErrorMsg(queryData.errorMsg)
        }
    }

    const handleAutofill = () => {
        /**
         * Checks if the form autofill state is in place.
         * In this form state, the 'data' state does not have any values, but
         * the form <input>s do have values, which were set by the browser.
         * Update the 'data' state if form is in autofill state.
         */
        if (!data['username'][0] || !data['password'][0]) {
            const usernameEl = document.querySelector('#username')
            const pwEl = document.querySelector('#password')
            if (usernameEl.value && pwEl.value) {
                setData(
                    {
                        'username': [usernameEl.value, false],
                        'password': [pwEl.value, false]
                    }
                )
                isLoginFormSubmitted.current = true
                isLockedRef.current = false
                return true
            }
        }
        return false
    }

    const handleInputChange = (inputData) => {
        /**
         * FLAG: If an ID is served from an input field which does not exist in 
         * data, it is a wrong ID. Either the configuration is false, or the user
         * changed the ID maliciously. In both cases, prevent the data from being 
         * stored/updated. Show an alert.
         */
        if (data[inputData.id] === undefined) {
            alert(INPUT_FORM_ID_MANIPULATION_ERROR_MSG)
            return
        }
        if (!hasDataChangedRef.current) {
            hasDataChangedRef.current = true
        }
        let dataCopy = {...data}
        dataCopy[inputData.id] = [inputData.value, inputData.hasError]
        setData(dataCopy)
    }

    const changeData = (inputData) => {
        /**
         * Changes multiple values of the 'data' state.
         * :Input
         *  inputData (obj): Keys (ids of form fields, value: data of form fields).
         */
        let newData = {...data}
        Object.entries(inputData).forEach(([fieldId, fieldValue]) => {
            newData[fieldId] = fieldValue
        })
        setData(newData)
    }

    return (
        data &&
        <InputDataContext.Provider value={data}>
            <AreRequiredErrorsActiveContext.Provider value={areRequiredErrorsActive}>
                <SetAreRequiredErrorsActiveContext.Provider value={setAreRequiredErrorsActive} >
                    <HandleDiscardSubmitFctContext.Provider value={handleDiscardSubmit}>
                        <HandleInputChangeContext.Provider value={handleInputChange}>
                            <ChangeDataContext.Provider value={changeData}>
                                <HasFormDiscardBtnContext.Provider value={b_hasDiscardBtn}>
                                    <HasInputErrorContext.Provider value={hasError}>
                                        <RequestErrorMsgContext.Provider value={requestErrorMsg}>
                                            <IsLoadingContext.Provider value={isLoading}>
                                                <DiscardPressedContext.Provider value={discardPressedSwitch}>
                                                    {children}
                                                </DiscardPressedContext.Provider>
                                            </IsLoadingContext.Provider>
                                        </RequestErrorMsgContext.Provider>
                                    </HasInputErrorContext.Provider>
                                </HasFormDiscardBtnContext.Provider>
                            </ChangeDataContext.Provider>
                        </HandleInputChangeContext.Provider>
                    </HandleDiscardSubmitFctContext.Provider>
                </SetAreRequiredErrorsActiveContext.Provider>
            </AreRequiredErrorsActiveContext.Provider>
        </InputDataContext.Provider>
    )
}

InputFormContextProvider.propTypes = {
    str_queryURL: PropTypes.string.isRequired,
    str_requestType: PropTypes.string,
    str_navigationURLonDiscard: PropTypes.string,
    obj_initState: PropTypes.object.isRequired,
    obj_clearedState: PropTypes.object,
    b_isFilterContext: PropTypes.bool,
    b_isDiscussionContext: PropTypes.bool,
    b_hasDiscardBtn: PropTypes.bool,
    b_isPrivatePost: PropTypes.bool,
    b_hasMediaData: PropTypes.bool,
    b_isLoginForm: PropTypes.bool,
    fct_response2parent: PropTypes.func,
}

export default InputFormContextProvider
