import React, { useState, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { RiDeleteBack2Line } from 'react-icons/ri';
import { AiOutlineEdit } from 'react-icons/ai';

import TdEmailTextAndSymbol from '../td/TdEmailTextAndSymbol';
import TdPhoneTextAndSymbol from '../td/TdPhoneTextAndSymbol';

import { deleteDBdata } from '../../../util/db_ls_query_handler';

const BASE_URL = '/api/courses/course-swap/detail/';
const DELETE_ERROR_MSG = 'Das Kurstausch-Angebot konnte nicht gelöscht werden! ' +
    'Bitte versuche es durch klicken noch einmal. ' +
    'Sollte derselbe Fehler danach wieder auftauchen, dann versuche es später wieder.';

const genUrl = (id) => {
    return BASE_URL + id + '/';
}

const genConfirmMsg = (courseTitle) => {
    return `Möchtest du folgendes Angebot wirklich unwiderruflich löschen?\n${courseTitle}`;
}

const TrCourseSwapMyOffers = ({
    obj_data,
    fct_deleteMyOffer
}) => {

    const [isCollapsed, setIsCollapsed] = useState(false)
    const isDeleteLockedRef = useRef(false) /* Prevent multiple clicks on 'delete'. */
    const tableRowRef = useRef()

    useMemo(() => {
        /**
         * If the input arg changes due to a delete event on a <tr>,
         * remove collapsed class and set height of prev. animation.
         * This happens as the different table data elements move up by
         * one item (the one that got deleted).
         */
        setIsCollapsed(false)
        if (tableRowRef.current) {
            tableRowRef.current.style.height = ''
        }
    }, [obj_data])

    const deleteItem = async () => {
        if (isDeleteLockedRef.current) return

        /* Check if user really intends to delete the position. */
        if (!window.confirm(genConfirmMsg(obj_data.courseTitle))) return

        isDeleteLockedRef.current = true

        const url = genUrl(obj_data.id)
        const queryData = await deleteDBdata(url)
        if (queryData.isQuerySuccess) {
            /* Animation. */
            /* Fix the height of <tr> so that removing the <td> does 
             * not collapse the <tr> before the animation. */
            const height = tableRowRef.current.clientHeight;
            tableRowRef.current.style.height = height + 'px';
            /* Run disappear animation by adding a class. */
            setIsCollapsed(true)
            /* Wait for animation to finish before context provider state is updated. */
            setTimeout(() => {
                fct_deleteMyOffer(obj_data.id)
                isDeleteLockedRef.current = false
            }, 1500)
        } else {
            alert(DELETE_ERROR_MSG)
            isDeleteLockedRef.current = false
        }
    }

    return (
        <tr ref={tableRowRef} className={`${isCollapsed ? "tr-collapse-animation" : ""}`}>
            {
                !isCollapsed &&
                <>
                    <td className="td-course-name">{obj_data.courseTitle}</td>
                    <td>{obj_data.myGroup}</td>
                    <td>{obj_data.targetGroups}</td>
                    <TdEmailTextAndSymbol
                        str_email={obj_data.email}
                        str_classes='hide-max-w-576px'
                    />
                    <TdPhoneTextAndSymbol
                        str_phone={obj_data.phone}
                        str_classes='hide-max-w-576px'
                    />
                    <td className="hide-max-w-1024px">{obj_data.postedDate}</td>
                    <td className='td-icon'>
                        <Link to={`edit/${obj_data.id}/`}>
                            <AiOutlineEdit />
                        </Link>
                    </td>
                    <td>
                        <RiDeleteBack2Line onClick={() => deleteItem(obj_data.id)} />
                    </td>
                </>
            }
        </tr>
    )
}

TrCourseSwapMyOffers.propTypes = {
    obj_data: PropTypes.object.isRequired,
    fct_deleteMyOffer: PropTypes.func.isRequired
}

export default TrCourseSwapMyOffers
