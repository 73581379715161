/**
 * This file entails routes that are used in App.js.
 * The goal of this file is to reuse specific route sections if they appear
 * more than once in App.js.
 */
import React from 'react';
import { Outlet } from 'react-router-dom';

import NoPageContentPlaceholder from "../../components/general/NoPageContentPlaceholder";
import TagsSearchPage from "../../pages/tags/TagsSearchPage";
import DocsDetailPage from "../../pages/courses/docs/DocsDetailPage";
import CourseStudyMaterialDiscussionPage from "../../pages/courses/CourseStudyMaterialDiscussionPage";
import CourseForumThreadPage from "../../pages/courses/CourseForumThreadPage";
import TasksDetailPage from '../../pages/courses/tasks/TasksDetailPage';
import TasksQuestionVariantsPage from "../../pages/courses/tasks/TasksQuestionVariantsPage";

import RoutesConfig from './RoutesConfig';

const tags = [
    {
        component: <TagsSearchPage />
    },
    {
        path: ':category/:title/:docId/discussion',
        component: <CourseStudyMaterialDiscussionPage str_linkUrl='..' comp_discussion={<DocsDetailPage />} />
    },
    {
        path: 'thread/:forumMsgId',
        component: <CourseForumThreadPage str_backLinkText='Zurück zur Übersicht' />
    },
    {
        path: 'exam',
        component: <Outlet />,
        routes: [
            {
                component: <NoPageContentPlaceholder />
            },
            {
                path: RoutesConfig.paths.courses.tasksDetailPage,
                component: <CourseStudyMaterialDiscussionPage comp_discussion={<TasksDetailPage {...RoutesConfig.studyMaterial.TasksDetailPage.exam} />} />
            },
            {
                path: RoutesConfig.paths.courses.tasksQuestionVariantsPage,
                component: <TasksQuestionVariantsPage {...RoutesConfig.studyMaterial.TasksQuestionVariantsPage.exam} />
            },
        ]
    },
    {
        path: 'exercise',
        component: <Outlet />,
        routes: [
            {
                component: <NoPageContentPlaceholder />
            },
            {
                path: RoutesConfig.paths.courses.tasksDetailPage,
                component: <CourseStudyMaterialDiscussionPage comp_discussion={<TasksDetailPage {...RoutesConfig.studyMaterial.TasksDetailPage.exercise} />} />
            },
            {
                path: RoutesConfig.paths.courses.tasksQuestionVariantsPage,
                component: <TasksQuestionVariantsPage {...RoutesConfig.studyMaterial.TasksQuestionVariantsPage.exercise} />
            }
        ]
    }
];

const RouteSections = {
    tags: tags
};

export default RouteSections;
