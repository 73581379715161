import React from 'react';

import FormBaseWelcome from '../../FormBaseWelcome';
import InFieldsGroup from '../../InFieldsGroup';
import PasswordCreationFields from '../../fields/PasswordCreationFields';

const InputFormPasswordReset = () => {
    return (
        <FormBaseWelcome b_isRequiredInfoShown={false}>
            <InFieldsGroup b_hasBorder={false}>
                <PasswordCreationFields str_id='passwordReset' />
            </InFieldsGroup>
        </FormBaseWelcome>
    )
}

export default InputFormPasswordReset
