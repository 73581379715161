import React from 'react';

import InFieldsGroup from '../../InFieldsGroup';
import FormBase from '../../FormBase';
import TextField from '../../fields/TextField';
import FileField from '../../fields/FileField';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { ImprovementsAndSuggestionsFormData as FormData } from '../../util/form-config-data/InputFormContactDataConfigs';

const InputFormImprovementsAndSuggestions = () => {
    const data = useInputData()
    return (
        <FormBase
            str_title='Verbesserungen & Vorschläge'
            b_hasFileField={true}
        >
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.title}
                    str_chars={data[FormData.fields.title.str_id][0]}
                />
                <TextField
                    {...FormData.fields.description}
                    str_chars={data[FormData.fields.description.str_id][0]}
                />
                <TextField
                    {...FormData.fields.url}
                    str_chars={data[FormData.fields.url.str_id][0]}
                />
                <FileField
                    {...FormData.fields.sugFile}
                    inFile={data[FormData.fields.sugFile.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormImprovementsAndSuggestions
