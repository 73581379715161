import React from 'react';
import PropTypes from 'prop-types';

import CardPriceTag from '../CardPriceTag';
import CardSellNums from '../CardSellNums';

const CardFoodFooterGastroInfo = ({
    nbr_price,
    nbr_reserved,
    nbr_available,
    nbr_redeemed
}) => {
    return (
        <div className="card-footer-offer">
            <CardPriceTag nbr_price={nbr_price} />
            {
                nbr_available &&
                <CardSellNums
                    nbr_available={nbr_available}
                    nbr_soldOrReserved={nbr_reserved}
                    nbr_redeemed={nbr_redeemed}
                    str_sellNumTitle={'reserviert'}
                />
            }
        </div>
    )
}

CardFoodFooterGastroInfo.propTypes = {
    nbr_price: PropTypes.number.isRequired,
    nbr_reserved: PropTypes.number.isRequired,
    nbr_available: PropTypes.number.isRequired,
    nbr_redeemed: PropTypes.number.isRequired
}

export default CardFoodFooterGastroInfo
