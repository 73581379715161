/**
 * This component handles the visual appearance of written messages to show
 * the user how his message is going to look like after posting it.
 */
import React, { useState, useEffect } from 'react';

import { useInputData } from '../input-forms/context-provider/InputFormContextProvider';
import { isImageFile } from '../input-forms/util/input_checks';
import { string2Html } from '../util/user_input_handler';
import { extractFileNameFromPath } from '../../util/data_handler';

const PanelPreview = () => {
    
    const [imageFiles, setImageFiles] = useState([])
    const [nonImageFiles, setNonImageFiles] = useState([])
    const data = useInputData()

    useEffect(() => {
        /**
         * Extract the file URLs from the input form data.
         * Splits the files into image files and non-image files and
         * stores the data as arrays in the corresponding states.
         */
        let imageFiles = []
        let nonImageFiles = []
        const fstring = 'file'
        Object.entries(data).forEach(([key, value]) => {
            const data = value[0]
            if (key.indexOf(fstring) === 0) {
                /* Already uploaded files have their static file path as value, which is a string. */
                if (typeof(data) === 'string') {
                    isImageFile(data) ? imageFiles.push(data) : nonImageFiles.push(data)
                }
                /* Files uploaded from the computer and stored in FileField are of type object. */
                else if (typeof(data) === 'object' && data !== null && data.constructor !== Array) {
                    isImageFile(data.name) ? imageFiles.push(data) : nonImageFiles.push(data)
                }
            }
        })
        setImageFiles(imageFiles)
        setNonImageFiles(nonImageFiles)
    }, [])
    
    return (
        <div className="post-msg-preview">
            {/* Message display, as will be shown on the page. */}
            <div className="msg">
                {/* Message category. */}
                {
                    data.scField &&
                    <div className={`msg-type msg-type-${data.scField[0]}`}>
                        {data.scField[0]}
                    </div>
                }
                <div className="msg-body">
                    {/* Text input. */}
                    {
                        data.text &&
                        <div className="msg-text">
                            <p className="p-preview"
                                dangerouslySetInnerHTML={{ __html: string2Html(data.text[0]) }}
                            />
                        </div>
                    }
                    
                    {/* File inputs. */}
                    <>
                        {/* Non-image files. */}
                        <div className="msg-files">
                        {
                            nonImageFiles.map((item, index) => (
                                /* File is object (not null, not Array). */
                                <div key={index} className="msg-file">
                                    <a href={typeof(item) === 'string' ? item : URL.createObjectURL(item)}
                                        target="_blank"
                                        className="file-link"
                                        rel="noopener noreferrer"
                                    >
                                        {typeof(item) === 'string' ? extractFileNameFromPath(item) : item.name}
                                    </a>
                                </div>
                            ))
                        }
                        </div>

                        {/* Image files. */}
                        <div className="msg-images">
                        {
                            imageFiles.map((item, index) => (
                                /* File is object (not null, not Array). */
                                <div key={index} className="msg-image">
                                    <a
                                        href={typeof(item) === 'string' ? item : URL.createObjectURL(item)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={typeof(item) === 'string' ? item : URL.createObjectURL(item)}
                                            alt={`message ${index}`}
                                            className="modal-item modal-item-img"
                                        />
                                    </a>
                                </div>
                            ))
                        }
                        </div>
                    </>
                </div>
            </div>
        </div>
    )
}

export default PanelPreview
