import React from 'react';
import PropTypes from 'prop-types';

import CSecBase from './CSecBase';
import GridBase from '../grids/GridBase';
import DynamicContentLoadBtn from '../buttons/DynamicContentLoadBtn';

const CSecGridBase = ({
    str_title='',
    str_subtitle,
    b_hasDarkDesign=false,
    b_isContentLoadedDynamically=false,
    str_gridClass='',
    str_secBaseClasses='',
    children
}) => {
    return (
        <CSecBase
            str_title={str_title}
            str_subtitle={str_subtitle}
            b_hasDarkDesign={b_hasDarkDesign}
            str_classes={str_secBaseClasses}
        >
            <GridBase str_gridClass={str_gridClass}>
                {children}
            </GridBase>
            {
                b_isContentLoadedDynamically &&
                <DynamicContentLoadBtn />
            }
        </CSecBase>
    )
}

CSecGridBase.propTypes = {
    str_title: PropTypes.string,
    str_subtitle: PropTypes.string,
    b_hasDarkDesign: PropTypes.bool,
    b_isContentLoadedDynamically: PropTypes.bool,
    str_gridClass: PropTypes.string,
    str_secBaseClasses: PropTypes.string
}

export default CSecGridBase
