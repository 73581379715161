import React from 'react';
import PropTypes from 'prop-types';

import CSecBase from '../../../content-sections/CSecBase';
import InputFormContextProvider from '../../../input-forms/context-provider/InputFormContextProvider';
import FilterFormStdTextField from '../../filter-forms/std/FilterFormStdTextField';

import useSetBrowserSearchParamsFromFilterState from '../../../../custom-hooks/useSetBrowserSearchParamsFromFilterState';
import { useDataExistsContext } from '../../../context-provider/DynamicContentLoadContextProvider';
import { useFilterStateContext } from '../../context-provider/FilterStateContextProvider';
import { static2dynamicPanelConfig } from '../../../input-forms/util/input_form_data_handler';

import { FilterFormStdTextFieldData as FormData } from '../../../input-forms/util/form-config-data/InputFormFilterDataConfigs';

const FilterStdTextField = ({
    str_localStorageKey='',
    str_filterTitle,
    str_filterFieldName,
    str_classes='',
    b_isDiscussionContentSection=false,
    b_hasDarkDesign=false,
    b_hasPadding=false,
    b_hasMarginBottom=false,
    b_isTransparent=false
}) => {

    const dataExists = useDataExistsContext()
    const filterState = useFilterStateContext()

    useSetBrowserSearchParamsFromFilterState(filterState)

    let dynConfig = {}
    /* Only add the new local storage key to the dyn. config if ther is one. 
     * Otherwise, use the one of the static config in 'InputFormFilterDataConfigs.js'. */
    if (str_localStorageKey) {
        dynConfig['str_localStorageKey'] = str_localStorageKey
    }
    if (filterState) {
        dynConfig['obj_initState'] = filterState
    }

    return (
        dataExists &&
        <CSecBase
            str_classes={`
                ${b_hasPadding ? '' : 'no-padding'}
                ${b_hasMarginBottom ? '' : 'no-margin-bottom'}
                ${str_classes}
            `}
            b_hasDarkDesign={b_hasDarkDesign}
            b_isDiscussionContentSection={b_isDiscussionContentSection}
        >
            <InputFormContextProvider
                {...static2dynamicPanelConfig(FormData, dynConfig)}
                obj_clearedState={FormData.obj_initState}
                b_isFilterContext={true}
            >
                <FilterFormStdTextField
                    str_filterTitle={str_filterTitle}
                    str_filterFieldName={str_filterFieldName}
                    b_hasDarkDesign={b_hasDarkDesign}
                    b_isTransparent={b_isTransparent}
                />
            </InputFormContextProvider>
        </CSecBase>
    )
}

FilterStdTextField.propTypes = {
    str_localStorageKey: PropTypes.string,
    str_filterTitle: PropTypes.string,
    str_filterFieldName: PropTypes.string,
    str_classes: PropTypes.string,
    b_isDiscussionContentSection: PropTypes.bool,
    b_hasDarkDesign: PropTypes.bool,
    b_hasPadding: PropTypes.bool,
    b_hasMarginBottom: PropTypes.bool,
    b_isTransparent: PropTypes.bool
}

export default FilterStdTextField
