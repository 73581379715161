import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import PrivatePage from '../../components/network/PrivatePage';
import HeaderAndFooter from '../../components/page-layout/HeaderAndFooter';
import Usertypes from '../../util/Usertypes';

const CourseDetailPage = () => {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [navigate])
    return (
        <PrivatePage arr_usertypes={[Usertypes.student]}>
            <HeaderAndFooter
                str_secNavPrDataKey='course'
                str_secNavSecDataKey='courseName'
            >
                <Outlet />
            </HeaderAndFooter>
        </PrivatePage>
    )
}

export default CourseDetailPage
