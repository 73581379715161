import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CSecBase from '../../components/content-sections/CSecBase';
import DynamicContentLoadContextProvider from '../../components/context-provider/DynamicContentLoadContextProvider';
import FilterTags from '../../components/filters/filters/courses/FilterTags';
import StudyMaterialMessages from '../../components/messages/courses/StudyMaterialMessages';

import { useClearCachContextProvider } from '../../components/discussion-pages/context-provider/DiscussionCacheContextProvider';

import { genDynUrl } from '../../util/db_ls_query_handler';
import DynLoadPackageSizes from '../../util/config/data_load_package_sizes.json';

const URL = '/api/courses/tags/search/';
const OVERVIEW_URL = URL + 'overview/';

const TagsSearchPage = () => {
    
    const params = useParams()
    const url = params.id ? genDynUrl(URL, params.id) : OVERVIEW_URL
    const packageSize = DynLoadPackageSizes.courses.courseMaterialTagSearch
    const clearDiscussionCache = useClearCachContextProvider()

    useEffect(() => {
        /**
         * Clear the discussion cache when visiting this page.
         * This must be done, to ensure that going to a discussion of a different
         * post than the previous one does not take the values from the cache.
         * This would lead to presenting always the same discussion values.
         * By clearing the cache, the data is loaded from the DB and is the correct one.
         */
        clearDiscussionCache()
    }, [])

    return (
        <DynamicContentLoadContextProvider
            str_queryURL={url}
            nbr_packageSize={packageSize}
            b_useFilterCache={true}
            b_queryOnlyIfFilterExists={true}
        >
            <CSecBase b_isUsedAsTitle={true} str_title='Suche Lerninhalte bei Tags' />
            <FilterTags
                str_classes='no-padding-top'
                b_hasPadding={true}
                b_isTransparent={true}
            />
            <StudyMaterialMessages />
        </DynamicContentLoadContextProvider>
    )
}

export default TagsSearchPage
