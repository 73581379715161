import React from 'react';

import InFieldsGroup from '../../InFieldsGroup';
import FormBase from '../../FormBase';
import TextField from '../../fields/TextField';
import SingleChoiceField from '../../fields/SingleChoiceField';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { GeneralContactFormData as FormData } from '../../util/form-config-data/InputFormContactDataConfigs';

const InputFormGeneralContact = () => {
    const data = useInputData()
    return (
        <FormBase str_title='Kontakt'>
            <InFieldsGroup b_hasBorder={false}>
                <SingleChoiceField
                    {...FormData.fields.category}
                    str_currentOption={data[FormData.fields.category.str_id][0]}
                />
                <SingleChoiceField
                    {...FormData.fields.severityLevel}
                    str_currentOption={data[FormData.fields.severityLevel.str_id][0]}
                />
                <TextField
                    {...FormData.fields.title}
                    str_chars={data[FormData.fields.title.str_id][0]}
                />
                <TextField
                    {...FormData.fields.description}
                    str_chars={data[FormData.fields.description.str_id][0]}
                />
                <TextField
                    {...FormData.fields.phone}
                    str_chars={data[FormData.fields.phone.str_id][0]}
                />
                <TextField
                    {...FormData.fields.email}
                    str_chars={data[FormData.fields.email.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormGeneralContact
