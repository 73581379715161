import React from 'react';
import PropTypes from 'prop-types';

const CardCompanyAcquisitionBody = ({
    str_createDate,
    str_expirationDate
}) => {
    return (
        <div className='card-bod'>
            <div className='key-infos'>
                <div className='key-value-pair'>
                    <div className='key'>Erstelldatum</div>
                    <div className='value'>
                        {str_createDate}
                    </div>
                </div>
                {/* <div className='key-value-pair'>
                    <div className='key'>Ablaufdatum</div>
                    <div className='value'>
                        {str_expirationDate}
                    </div>
                </div> */}
            </div>
        </div>
    )
}

CardCompanyAcquisitionBody.propTypes = {
    str_createDate: PropTypes.string.isRequired,
    str_expirationDate: PropTypes.string.isRequired
}

export default CardCompanyAcquisitionBody
