import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthService from '../../util/authentication/auth_service';

const STD_SUCCESS_MSG = 'Die Daten wurden erfolgreich gespeichert.';
const STD_ERROR_MSG = 'Die Daten konnten nicht gespeichert werden. Bitte lade die Seite neu und versuche ' +
                        'erneut die Daten zu übermitteln. Sollte derselbe Fehler wieder auftreten, ' +
                        'dann versuche es später noch einmal.'

const FormSuccessErrorMessage = ({
    b_isSuccess,
    str_successMsg,
    str_errorMsg,
    b_hasProfilePageLink=false
}) => {
    return (
        b_isSuccess
        ?
        <div className="form-result-container">
            <div className="form-result">
                <div>
                    <h2>Erfolg!</h2>
                </div>
                <div>
                    <p>{str_successMsg ? str_successMsg : STD_SUCCESS_MSG}</p>
                </div>
                {
                    b_hasProfilePageLink &&
                    <div>
                        <Link
                            to={AuthService.getProfileUrl()}
                            className="page-link"
                        >
                            Zum Account
                        </Link>
                    </div>
                }
            </div>
        </div>
        :
        <div className="form-result-container">
            <div className="form-result">
                <div>
                    <h2>Fehler!</h2>
                </div>
                <div>
                    <p>
                        Oops! Es ist ein Fehler aufgetreten.
                        <br />
                        { 
                            str_errorMsg ? str_errorMsg : STD_ERROR_MSG
                        }
                    </p>
                </div>
                {
                    b_hasProfilePageLink &&
                    <div>
                        <Link
                            to={AuthService.getProfileUrl()}
                            className="page-link"
                        >
                            Zum Account
                        </Link>
                    </div>
                }
            </div>
        </div>
    )
}

FormSuccessErrorMessage.propTypes = {
    b_isSuccess: PropTypes.bool,
    str_successMsg: PropTypes.string,
    str_errorMsg: PropTypes.string,
    b_hasProfilePageLink: PropTypes.bool
}

export default FormSuccessErrorMessage
