import React, { useState } from 'react';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import PPMainContentBase from '../PPMainContentBase';
import CardGridInfoList from '../../cards/CardGridInfoList';
import ObjectSerializer from '../../../util/serializers/ObjectSerializer';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import useGetLSorDBdata from '../../../custom-hooks/useGetLSorDBdata';

import { PPLsKeys, CompanyLsKeys } from '../../../util/LocalStorageVariables';

const HEADER = 'Weitere Accounts';
const SUB_HEADER = 'Alle weiteren registrierten Accounts deiner Firma sind hier gelistet.';

const SERIALIZER_LAYOUT = {
    data: [
        ['Name', 'companyName'],
        ['Standort', 'location'],
        ['Telefon', 'phone'],
        ['Email', 'email'],
        ['Beitrittsdatum', 'dateJoined'],
        ['Stellen Online', 'numOnlineJobs'],
        ['Infoseite', 'detailPagePath', 'Zur Seite']
    ]
};

const URL = '/api/profiles/company/associates/';
const PR_LS_KEY = PPLsKeys.primaryDataKey;
const SEC_LS_KEY = CompanyLsKeys.pp.associates;
const NO_ASSOCIATES_MSG = 'Es sind keine weiteren Accounts registriert.';

const IndustryPPAssociates = () => {

    const [data , setData] = useState([])
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    useSetBrowserTabTitle('Weitere Accounts', true)

    useGetLSorDBdata(
        URL,
        setData,
        setIsFirstFetchSuccess,
        setErrorMsg,
        true,
        ObjectSerializer.serializeMany,
        SERIALIZER_LAYOUT,
        PR_LS_KEY,
        SEC_LS_KEY,
        ''
    )
    
    return (
        <PPMainContentBase
            str_header={HEADER}
            str_subHeader={SUB_HEADER}
        >
            <DataStateCheckWrapper
                b_hasQueryCheck={true}
                b_isFirstFetchSuccess={isFirstFetchSuccess}
                b_isContentSection={true}
                str_errorMsg={errorMsg}
                str_noDataMsg={NO_ASSOCIATES_MSG}
                firstQueryDataEntry={data[0]}
            >
            {
                <CardGridInfoList
                    str_gridClass={'pp-card-grid'}
                    arr_data={data}
                />
            }
            </DataStateCheckWrapper>
        </PPMainContentBase>
    )
}

export default IndustryPPAssociates
