/**
 * Contains the items of the navbars of the profile page main contents.
 * 
 * The 'title' is what is displayed on the front end (innerHTML). 
 * The 'name' is used to highlight the currently active item of the navbar. 
 * The 'name' must be featured in the URL, as the JS code checks the URL against
 * the 'name' and if it finds it, sets the active styling to this item.
 * 
 */

const regularBase = {
    thisWeek: {
        title: 'Diese Woche',
        name: 'this-week',
        link: 'this-week'
    },
    nextWeek: {
        title: 'Nächste Woche',
        name: 'next-week',
        link: 'next-week'
    }
};

const dgfsBase = {
    today: {
        title: 'Heute',
        name: 'today',
        link: 'today'
    },
    tomorrow: {
        title: 'Morgen',
        name: 'tomorrow',
        link: 'tomorrow'
    }
};

export const PPMcNavbarData = {
    student: {
        foodOffersRegular: {
            ...regularBase       
        },
        foodOffersDgfs: {
            ...dgfsBase
        }
    },
    gastronomer: {
        foodOffersRegular: {
            ...regularBase,
            repeating: {
                title: 'Wiederkehrend',
                name: 'repeating',
                link: 'repeating'
            },
            old: {
                title: 'Alt',
                name: 'old',
                link: 'old'
            },
            new: {
                title: 'Neu',
                name: 'new',
                link: 'new',
                isButton: true
            }
        },
        foodOffersDgfs: {
            ...dgfsBase,
            old: {
                title: 'Alt',
                name: 'old',
                link: 'old'
            },
            new: {
                title: 'Neu',
                name: 'new',
                link: 'new',
                isButton: true
            }
        },
        coupons: {
            thisSemester: {
                title: 'Dieses Semester',
                name: 'this-sem',
                link: 'this-semester'
            },
            nextSemester: {
                title: 'Nächstes Semester',
                name: 'next-sem',
                link: 'next-semester'
            },
            old: {
                title: 'Alt',
                name: 'old',
                link: 'old',
            },
            new: {
                title: 'Neu',
                name: 'new',
                link: 'new',
                isButton: true
            }
        }
    }
};
