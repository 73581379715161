/**
 * This file contains all the configs of the post panel data. 
 */

import { genFieldData } from './InputFormBaseDataAndSerializer';

/* Functions to generate the static form data from user input configs. */

const formSelector = (hasTextField, hasSCField, numFiles, hasTagField) => {
    switch(hasTextField) {
        case (true):
            switch(hasSCField) {
                case (true):
                    switch (numFiles) {
                        case (0):
                            return PanelTextSCFormData;
                        case (1):
                            return PanelText1FileSCFormData;
                        default:
                            switch(hasTagField) {
                                case (true):
                                    return PanelText2FilesSCFormDataTags
                                default:
                                    return PanelText2FilesSCFormData;
                            }
                    }
                default:
                    return PanelTextFormData;
            }
        default:
            return Panel1FileFormData;
    }
}

export const getFormData = (obj_formChoice, fileConfig=null) => {
    /**
     * Select which input form of the different panel forms
     * should be used for the message commenting/editing.
     * :Input
     *  The following line shows the how the input must look like.
     *  obj_formChoice (obj): Config to select the correct post panel content.
     *      {
     *          hasTextField: true/false,
     *          hasSCfield  : true/false,
     *          numFiles    : 0, 1, or 2,
     *          hasTagField : true/false
     *      }
     *  fileConfig (obj): Config for allowed file types and size.
     *      {
     *          nbr_maxSize: x (int),
     *          arr_allowedFileTypes: ['...', '...', ...],
     *          b_isImageField: true/false    
     *      }
     */
    if (!obj_formChoice) return null;

    let formData = formSelector(
        obj_formChoice.hasTextField,
        obj_formChoice.hasSCField,
        obj_formChoice.numFiles,
        obj_formChoice.hasTagField
    );

    /* Overwrite default file configs with custom ones. */
    if (obj_formChoice.numFiles && fileConfig) {
        let fileFieldConfigs = formData.fields.fileFields;
        Object.entries(fileFieldConfigs).forEach(([key, value]) => {
            /* key: Key of file field (e.g. file0, file1, ...). */
            fileFieldConfigs[key] = {...value, ...fileConfig};
        })
    }

    return formData;
}

/* Static form data. */

const TEXT_FIELD_CONFIG = {str_id: 'text'};
const MSG_CHOICES = {
    str_id: 'category',
    arr_options: ['Bemerkung', 'Frage', 'Antwort'],
    str_bottomInfoText: 'Wähle einen Nachrichtentyp.',
};

/* Text panel. */
export const PanelTextFormData = {
    str_localStorageKey: 'PanelTextFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        text: ['', true]
    },
    fields: {
        text: genFieldData('text', TEXT_FIELD_CONFIG)
    }
};

/* Text, category panel. */
export const PanelTextSCFormData = {
    str_localStorageKey: 'PanelTextSCFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        text    : ['', true],
        category: ['', true]
    },
    fields: {
        text: genFieldData('text', TEXT_FIELD_CONFIG),
        category: genFieldData('sc', {...MSG_CHOICES})
    }
};

/* Text, 2 files, category panel. */
export const Panel1FileFormData = {
    str_localStorageKey: 'Panel1FileFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        file0: [null, true],
    },
    fields: {
        fileFields: [
            genFieldData('file', {
                str_id: 'file0',   
                b_isRequired: true
            }),
        ]
    }
};

/* Text, 1 file, category panel. */
export const PanelText1FileSCFormData = {
    str_localStorageKey: 'PanelText1FileSCFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        text    : [''  , true ],
        category: [''  , true ],
        file0   : [null, false]
    },
    fields: {
        text: genFieldData('text', TEXT_FIELD_CONFIG),
        category: genFieldData('sc', {...MSG_CHOICES}),
        fileFields: [
            genFieldData('file', {
                str_id: 'file0',    
                b_isRequired: false
            })
        ]
    }
};

/* Text, 2 files, category panel. */
export const PanelText2FilesSCFormData = {
    str_localStorageKey: 'PanelText2FilesSCFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        text    : ['',   true ],
        category: ['',   true ],
        file0   : [null, false],
        file1   : [null, false],
    },
    fields: {
        text: genFieldData('text', TEXT_FIELD_CONFIG),
        category: genFieldData('sc', {...MSG_CHOICES}),
        fileFields: [
            genFieldData('file', {
                str_id: 'file0',
                b_isRequired: false
            }),
            genFieldData('file', {
                str_id: 'file1',
                b_isRequired: false
            })
        ]
    }
};

export const PanelText2FilesSCFormDataTags = {
    str_localStorageKey: 'PanelText2FilesSCFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        text    : [''  , true ],
        category: [''  , true ],
        file0   : [null, false],
        file1   : [null, false],
        tagnames: [''  , false]
    },
    fields: {
        text: genFieldData('text', TEXT_FIELD_CONFIG),
        category: genFieldData('sc', {...MSG_CHOICES}),
        fileFields: [
            genFieldData('file', {
                str_id: 'file0',
                b_isRequired: false
            }),
            genFieldData('file', {
                str_id: 'file1',
                b_isRequired: false
            })
        ],
        tagnames: genFieldData('tags', null)
    }
};