import React, { useState } from 'react';

import InputFormContextProvider from '../input-forms/context-provider/InputFormContextProvider';
import InputFormStudentUserRegistration from '../input-forms/forms/welcome/InputFormStudentUserRegistration';
import FormSuccessErrorMessage from '../input-forms/FormSuccessErrorMessage';

import { StudentUserRegistrationFormData as FormData } from '../input-forms/util/form-config-data/InputFormWelcomeDataConfigs';
import { static2dynamicPanelConfig } from '../input-forms/util/input_form_data_handler';

const URL = '/api/profiles/student/registration/';
const SUCCESS_MSG = 'Es wurde ein Link an die angegebene E-Mail-Adresse gesendet.\n' +
    'Checke deine Inbox und auch den Spamordner innerhalb der nächsten Minuten, ' +
    'um dich zu registrieren';

const RegistrationStudent = () => {
    
    const [isPosted, setIsPosted] = useState(false)
    const posted = (response) => { setIsPosted(true) }

    return (
        isPosted
        ?
        <FormSuccessErrorMessage
            b_isSuccess={true}
            str_successMsg={SUCCESS_MSG}
        />
        :
        <InputFormContextProvider
            {...static2dynamicPanelConfig(FormData)}
            str_requestType='post'
            str_queryURL={URL}
            b_isPrivatePost={false}
            b_hasDiscardBtn={false}
            fct_response2parent={posted}
        >
            <InputFormStudentUserRegistration />
        </InputFormContextProvider>
    )
}

export default RegistrationStudent
