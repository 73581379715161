import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import PageBase from '../../components/page-layout/PageBase';

import {
    useConfigDataContext,
    useSetupStepContext
} from '../../components/profile-setup/context-provider/ProfileSetupContextProvider';
import AuthService from '../../util/authentication/auth_service';
import Usertypes from '../../util/Usertypes';

const ABO_URL = '/abo-extension';

const ProfileSetupSuccessPage = () => {
    
    const navigate = useNavigate()
    const configData = useConfigDataContext()
    const setupStep = useSetupStepContext()
    const [isNavigateChecked, setIsNavigateChecked] = useState(false)
    
    const usertype = AuthService.getUsertype()

    useEffect(() => {
        if (setupStep !== 'complete') {
            const navigationUrl = `../${configData[setupStep].urlFormat}`
            navigate(navigationUrl, { replace: true })
        }
        setIsNavigateChecked(true)
    }, [navigate])

    return (
        isNavigateChecked &&
        <PageBase>
            <div className='setup-container'>
                <div className='setup-wrap'>
                    <div>
                        <h2>Perfekt!</h2>
                    </div>
                    <div>
                        <p>Dein Account ist fertig konfiguriert.</p>
                        <p>
                            Wenn du Fragen zur App hast, dann empfehlen wir dir einen Blick in die
                            Nutzeranleitung zu werfen. Dort findest du alle wichtigen Informationen
                            rund um MUPLAZA. Die Nutzeranleitung findest du am Ende jeder Seite unter 
                            'Nutzung & Datenschutz'.
                        </p>
                    </div>
                    <div className='link'>
                        {
                            usertype === Usertypes.company
                            ?
                            <Link
                                to={ABO_URL}
                                className='page-link'
                            >
                                Abos
                            </Link>
                            :
                            <Link
                                to={AuthService.getProfileUrl()}
                                className='page-link'
                            >
                                Zum Account
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </PageBase>
    )
}

export default ProfileSetupSuccessPage
