import React from 'react'
import { Link  } from 'react-router-dom'
import PropTypes from 'prop-types'

import CSecBase from '../content-sections/CSecBase'

const PPMainContentBase = ({
    str_id='',
    str_header='',
    str_subHeader='',
    str_editLinkText='edit',
    str_editUrl='',
    children
}) => {
    return (
        <CSecBase
            str_id={str_id}
            str_title={str_header}
            str_subtitle={str_subHeader}
            str_classes='pp-mc-section'
        >
            <div className="pp-mc-group-wrapper">
                <div className="pp-mc-group">
                    {children}
                </div>
                {
                    str_editUrl &&
                    <div className="pp-mc-group-edit">
                        <Link
                            to={str_editUrl}
                            className="page-link"
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            {str_editLinkText}
                        </Link>
                    </div>
                }
            </div>
        </CSecBase>
    )
}

PPMainContentBase.propTypes = {
    str_id: PropTypes.string,
    str_header: PropTypes.string,
    str_subHeader: PropTypes.string,
    str_editUrl: PropTypes.string,
    str_editLinkText: PropTypes.string
}

export default PPMainContentBase
