import React from 'react'

const AccordionList = ({ children }) => {
    return (
        <ul className="accordion-content-list">
            {children}
        </ul>
    )
}

export default AccordionList
