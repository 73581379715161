/**
 * This file entails functions that allow you to create different
 * data items that are needed more often.
 */

export const createAbc = (isArray=true, isLowerCase=false) => {
    /**
     * Creates the standard abc without any German Umlaute.
     * :Returns
     *  Array or string of the abc given the input args.
     */
    const abcLen = 26;
    const alpha = Array.from(Array(abcLen)).map((e, i) => i + 65);
    const abcArr = alpha.map((x) => String.fromCharCode(x));
    if (isLowerCase) {
        for (let i = 0; i < abcLen; i++)
            abcArr[i] = abcArr[i].toLowerCase();
    }
    if (isArray) return abcArr;
    const abcStr = abcArr.toString();
    return abcStr;
}
