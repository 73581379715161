import React from 'react';
import PropTypes from 'prop-types';
import { MdOutlineArrowForwardIos } from 'react-icons/md';

const Accordion = ({
    str_title,
    children
}) => {
    return (
        <div className="accordion">
            <ul>
                <li className="accordion-item">
                    <div className="accordion-key">
                        <div
                            className="accordion-label"
                            onClick={e => e.target.closest('li').classList.toggle('active')}
                        >
                            <h2>{str_title}</h2>
                            <div className="arrow"><MdOutlineArrowForwardIos /></div>
                        </div>
                        <div className="accordion-content">{children}</div>
                    </div>
                </li>                
            </ul>
        </div>
    )
}

Accordion.propTypes = {
    str_title: PropTypes.string.isRequired
}

export default Accordion
