import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { AiOutlineEdit } from 'react-icons/ai';
import { string2URLstring } from '../../../../util/data_handler';
import { browserDateToGermanDateFormat } from '../../../../util/date_time_handler';

const TbRowIndustryScheduledJobs = ({ obj_rowData }) => {
    return (
        <tr>
            <td>
                <Link
                    to={`/industry/jobs/${string2URLstring(obj_rowData.companyName)}/${string2URLstring(obj_rowData.title)}/${obj_rowData.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="file-link"
                >
                    {obj_rowData.title}
                </Link>
            </td>
            <td>{browserDateToGermanDateFormat(obj_rowData.launchDate)}</td>
            <td className="hide-max-w-1024px">{browserDateToGermanDateFormat(obj_rowData.startDate)}</td>
            <td>
                <Link to={`scheduled/job/${obj_rowData.id}/edit`}>
                    <AiOutlineEdit />
                </Link>
            </td>
        </tr>
    )
}

TbRowIndustryScheduledJobs.propTypes = {
    obj_rowData: PropTypes.object
}

export default TbRowIndustryScheduledJobs
