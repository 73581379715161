import React from 'react';

import InFieldsGroup from '../../../InFieldsGroup';
import FormBase from '../../../FormBase';
import TextField from '../../../fields/TextField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { IndustryBenefitsFormData as FormData } from '../../../util/form-config-data/InputFormIndustryDataConfigs';


const InputFormIndustryBenefits = () => {    
    const data = useInputData()
    return (
        <FormBase str_title='Mitarbeitervorteile'>
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.benefit0}
                    str_chars={data[FormData.fields.benefit0.str_id][0]}
                />
                <TextField
                    {...FormData.fields.benefit1}
                    str_chars={data[FormData.fields.benefit1.str_id][0]}
                />
                <TextField
                    {...FormData.fields.benefit2}
                    str_chars={data[FormData.fields.benefit2.str_id][0]}
                />
                <TextField
                    {...FormData.fields.benefit3}
                    str_chars={data[FormData.fields.benefit3.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormIndustryBenefits
