import React from 'react';

import CSecBase from '../../content-sections/CSecBase';
import GridStoreCoupons from '../../grids/GridStoreCoupons';
import CoinBudget from '../../store/CoinBudget';
import FilterStdTextField from '../../filters/filters/std/FilterStdTextField';
import DynamicContentLoadBtn from '../../buttons/DynamicContentLoadBtn';
import DynamicContentLoadContextProvider from '../../context-provider/DynamicContentLoadContextProvider';
import CoinBudgetContextProvider from '../../context-provider/CoinBudgetContextProvider';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';

import DynLoadPackageSizes from '../../../util/config/data_load_package_sizes.json';
import CouponSerializer from '../../../util/serializers/backend_serializers/coupon_serializers';
import DynamicContentLoadUpdateItemService from '../../context-provider/util/DynamicContentLoadUpdateItemService';

/* Data for the launch semester. */
import HandleNoData from '../../general/HandleNoData';
const COUPON_PLACEHOLDER = 'Hier erscheinen ab dem nächsten Semester die Gutscheine.';

const URL = '/api/store/coupons/list/';

const FoodStore = () => {
    useSetBrowserTabTitle('Store')
    return (
        <CoinBudgetContextProvider>
            <CSecBase
                str_title='Gutscheine'
                str_subtitle='Gesponsert von unseren Partnern aus der Leobener Gastronomie'
                b_isUsedAsTitle={true}
            >
                <HandleNoData str_errorMsg={COUPON_PLACEHOLDER} />
            </CSecBase>
            {/* <DynamicContentLoadContextProvider
                str_queryURL={URL}
                fct_serializer={CouponSerializer.dbList2couponCardsArray}
                fct_updateItem={DynamicContentLoadUpdateItemService.updateCoupon}
                nbr_packageSize={DynLoadPackageSizes.store.coupons}
            >
                <CSecBase
                    str_title='Gutscheine'
                    str_subtitle='Gesponsert von unseren Partnern aus der Leobener Gastronomie'
                    b_isUsedAsTitle={true}
                />
                <FilterStdTextField str_filterFieldName='Ausstellername' />
                <CSecBase str_classes='no-padding-bottom no-margin-bottom'>
                    <CoinBudget />
                </CSecBase>
                <CSecBase>
                    <GridStoreCoupons />
                    <DynamicContentLoadBtn />
                </CSecBase>
            </DynamicContentLoadContextProvider> */}
        </CoinBudgetContextProvider>
    )
}

export default FoodStore
