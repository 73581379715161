import React from 'react'
import PropTypes from 'prop-types'
import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa'

const SOCIAL_MEDIA_SYMBOLS = {
    linkedin: <FaLinkedin />, 
    twitter: <FaTwitter />,
    facebook: <FaFacebook />,
    instagram: <FaInstagram />,
    tiktok: <FaTiktok />
};

const hasSocialMedia = (data) => {
    let hasSocialMedia = false;
    try {
        Object.values(data).forEach(value => {
            if (value !== undefined && value !== '') {
                hasSocialMedia = true
            }
        })
    } catch {
        return false;
    }
    return hasSocialMedia;
}

const hasData = (data) => {
    if (data === '' || data === null || data === undefined) return false;
    if (typeof(data) === 'object') return hasSocialMedia(data)
    return true;
}

const CardBodyInfoList = ({ arr_data }) => {
    return (
        <ul>
            {
                arr_data.map((item, index) => (
                    hasData(item.data) &&
                    <li key={index}>
                        {
                            item.title &&
                            <p className="title">
                                {item.title}
                            </p>
                        }
                        <p className={'content-name'}>
                            {
                                item.linkText
                                ?
                                <a
                                    href={item.data}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="page-link-extern"
                                >
                                    {item.linkText}
                                </a>
                                :
                                typeof(item.data) === 'object'
                                ?
                                Object.keys(item.data).map((smKey, index) => (
                                    item.data[smKey] &&
                                    <a 
                                        key={index}
                                        href={item.data[smKey]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="social-media"
                                    >
                                        {SOCIAL_MEDIA_SYMBOLS[smKey]}
                                    </a>
                                ))
                                :
                                item.data
                            }
                        </p>
                    </li>
                ))
            }
        </ul>
    )
}

CardBodyInfoList.propTypes = {
    arr_data: PropTypes.array.isRequired
}

export default CardBodyInfoList
