import React from 'react';

const InFieldConfirmationValue = ({ value }) => {
    return (
        <div className="in-field-confirmation-value">
            <p>{value ? value : '—'}</p>
        </div>
    )
}

export default InFieldConfirmationValue
