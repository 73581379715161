import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import PageBase from '../../components/page-layout/PageBase';

import {
    useConfigDataContext,
    useSetupStepContext,
} from '../../components/profile-setup/context-provider/ProfileSetupContextProvider';

const ProfileSetupWelcomePage = () => {

    const navigate = useNavigate()
    const configData = useConfigDataContext()
    const setupStep = useSetupStepContext()
    const [isNavigateChecked, setIsNavigateChecked] = useState(false)

    useEffect(() => {
        /* Always link to success page if setup is complete. */
        if (setupStep === 'complete') {
            navigate('../success', { replace: true })
        }
        setIsNavigateChecked(true)
    }, [navigate])

    return (
        isNavigateChecked &&
        <PageBase>
            <div className='setup-container'>
                <div className='setup-wrap'>
                    <div>
                        <h2>Willkommen!</h2>
                    </div>
                    <div>
                        <p>
                            Die nächsten Schritte helfen dir dein Konto einzurichten.
                            <br />
                            Die Konfiguration sollte nicht länger als 10 - 15 Minuten dauern.
                            <br />
                            Nachdem ein Konfigurationsschritt bestätigt wurde, kann zu diesem 
                            nicht mehr zurückgekehrt werden. Nach der Einrichtung deines Kontos 
                            kannst du jedoch alle Einstellungen, die du jetzt vornimmst, 
                            jederzeit über deine Profilseite ändern.
                        </p>
                    </div>
                    <div className='link'>
                        <Link
                            to={configData[setupStep].urlFormat}
                            className='page-link'
                        >
                            {
                                configData[setupStep].psStepIndex > 1 ?
                                'Fortsetzen' :
                                'Starten'
                            }
                        </Link>
                    </div>
                </div>
            </div>
        </PageBase>
    )
}

export default ProfileSetupWelcomePage
