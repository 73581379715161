import React from 'react';
import PropTypes from 'prop-types';

import CSecBase from '../content-sections/CSecBase';
import InfiniteScrollInfoMessages from '../general/InfiniteScrollInfoMessages';
import { useInfiniteScrollContext } from '../context-provider/DynamicContentLoadContextProvider';

const DiscussionContent = ({
    b_hasDarkDesign=false,
    children
}) => {
    const infiniteScrollRef = useInfiniteScrollContext()
    return (
        <CSecBase
            str_id='answer-section-id'
            b_hasDarkDesign={b_hasDarkDesign}
            b_isDiscussionContentSection={true}
        >
            <div
                ref={infiniteScrollRef}
                className="answer-wrapper"
            >
                <div className="msgs-wrapper">
                    {children}
                </div>
                {
                    infiniteScrollRef && /* Flag for content that has no dyn. content loading. */
                    <InfiniteScrollInfoMessages />
                }
            </div>
        </CSecBase>
    )
}

DiscussionContent.propTypes = {
    b_hasDarkDesign: PropTypes.bool
}

export default DiscussionContent
