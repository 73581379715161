import React from 'react';
import PropTypes from 'prop-types';

import { browserDateToGermanDateFormat } from '../../../util/date_time_handler';

const JobHeader = ({ obj_data }) => {
    return (
        <section className="job-pos-header">
            <div className="job-pos-header-wrapper">
                <div className="title">{obj_data.title}</div>

                <div className="header-details">
                    <span className="value-wrapper">
                        <span className="key">Start</span>
                        <span className="value">
                            <span>{browserDateToGermanDateFormat(obj_data.startDate)}</span>
                            {
                                obj_data.isStartDateFlexible &&
                                <span>&nbsp;(flexibel)</span>
                            }
                        </span>
                        <span className="separator">|</span>
                    </span>
                    <span className="value-wrapper">
                        <span className="key">Ort</span>
                        <span className="value">{obj_data.location}</span>
                        <span className="separator">|</span>
                    </span>
                    <span className="value-wrapper">
                        <span className="key">Zeitmodell</span>
                        <span className="value">{obj_data.timeModel}</span>
                        <span className="separator">|</span>
                    </span>
                    <span className="value-wrapper">
                        <span className="key">Abschlussarbeit</span>
                        <span className="value">
                            {
                                obj_data.thesisOptions ?
                                obj_data.thesisOptions :
                                'keine'
                            }
                        </span>
                    </span>
                </div>

                {
                    obj_data.isInternship &&
                    <div className="header-details">
                        <span className="value-wrapper">
                            <span className="key">{obj_data.isPaid ? 'bezahltes praktikum': 'unbezahltes praktikum'}</span>
                            <span className="value">&nbsp;{obj_data.duration}</span>
                        </span>
                    </div>
                }

                <div className="entity">
                    {
                        obj_data.webpage
                        ?
                        <div className="entity-page-link">
                            <a
                                href={obj_data.webpage}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="page-link"
                            >
                                {obj_data.companyName}
                            </a>
                        </div>
                        :
                        <div className="entity-name">{obj_data.companyName}</div>
                    }
                </div>
            </div>
        </section>
    )
}

JobHeader.propTypes = {
    obj_data: PropTypes.object.isRequired
}

export default JobHeader
