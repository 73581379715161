/**
 * Sets the title of the browser tab.
 */
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthService from '../util/authentication/auth_service';

const MUPLAZA_IDENTIFIER = ' | MUPLAZA';

const genTabCourseName = (courseName) => {
    /**
     * Creates an uppercase course name without the URL hyphens
     * from the url course name and restricts the course name length.
     */
    let tabCourseName = courseName.replace('-', ' ').toUpperCase();
    const length = tabCourseName.length;
    if (length > 16) {
        tabCourseName = tabCourseName.slice(0, 13) + '...';
    }
    return tabCourseName;
}

export default function useSetBrowserTabTitle(
    title,
    hasUsername=false,
    hasCourseName=false,
    execOnUrlChange=false
) {
    /**
     * Sets the title of the browser tab onmount.
     * On unmount the title is set back to the title that was
     * present before the component that uses this hook got mounted.
     * This ensures that the title is still correct if a component that
     * got mounted dynamically disappears again.
     * :Input
     *  title (str or fct): Title to be set as browser tab title. A function can be
     *      necessary to change something dynamically (e.g. using a URL parameter).
     *  hasUsername (bool): true if username should be added to the tab nane.
     *  hasCourseName (bool): true if course name should be added to the tab name.
     *  execOnUrlChange (bool): true if tab title should be refreshed on url change.
     */
    const params = useParams()
    const navigate = useNavigate()
    const changeIndicator = execOnUrlChange ? [navigate] : []
    useEffect(() => {
        const prevTitle = document.title
        
        if (typeof(title) !== 'string') {
            title = title()
        }

        if (hasUsername) {
            const base = '@' + AuthService.getUsername()
            if (title) {
                document.title = base + ' - ' + title + MUPLAZA_IDENTIFIER
            } else {
                document.title = base + MUPLAZA_IDENTIFIER
            }
        }
        else if (hasCourseName) {
            const courseName = params.name
            if (courseName) {
                const tabCourseName = genTabCourseName(courseName)
                if (title) {
                    document.title = tabCourseName + ' - ' + title + MUPLAZA_IDENTIFIER
                } else {
                    document.title = tabCourseName + MUPLAZA_IDENTIFIER
                }
            } else{
                if (title) {
                    document.title = title + MUPLAZA_IDENTIFIER
                } else {
                    document.title = 'MUPLAZA'
                }
            }
        }
        else {
            document.title = title + MUPLAZA_IDENTIFIER
        }

        return () => {
            document.title = prevTitle
        }
    }, changeIndicator)
}
