import React from 'react';

import InFieldsGroup from '../../InFieldsGroup';
import FormBase from '../../FormBase';
import TextField from '../../fields/TextField';
import SingleChoiceField from '../../fields/SingleChoiceField';
import BoolField from '../../fields/BoolField';
import DateField from '../../fields/DateField';
import FileField from '../../fields/FileField';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { BugReportingFormData as FormData } from '../../util/form-config-data/InputFormContactDataConfigs';

const InputFormBugReporting = () => {
    const data = useInputData()
    return (
        <FormBase
            str_title='Fehler melden'
            b_hasFileField={true}
        >
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.title}
                    str_chars={data[FormData.fields.title.str_id][0]}
                />
                <TextField
                    {...FormData.fields.description}
                    str_chars={data[FormData.fields.description.str_id][0]}
                />
                <SingleChoiceField
                    {...FormData.fields.numMaxOccurrences}
                    str_chars={data[FormData.fields.numMaxOccurrences.str_id][0]}
                />
                <BoolField
                    {...FormData.fields.isKnownByOtherUsers}
                    b_boolValue={data[FormData.fields.isKnownByOtherUsers.str_id][0]}
                />
                <DateField
                    {...FormData.fields.firstNoticedDate}
                    str_date={data[FormData.fields.firstNoticedDate.str_id][0]}
                />
                <SingleChoiceField
                    {...FormData.fields.severityLevel}
                    str_currentOption={data[FormData.fields.severityLevel.str_id][0]}
                />
                <TextField
                    {...FormData.fields.url}
                    str_chars={data[FormData.fields.url.str_id][0]}
                />
                <FileField
                    {...FormData.fields.bugFile}
                    inFile={data[FormData.fields.bugFile.str_id][0]}
                />
                <TextField
                    {...FormData.fields.suggestedSolution}
                    str_chars={data[FormData.fields.suggestedSolution.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormBugReporting
