import React from 'react';
import PropTypes from 'prop-types';
import { BsTags } from 'react-icons/bs';

const MessageTags = ({ str_tagnames }) => {
    return (
        str_tagnames &&
        <div className="tags">
            <div className="tag-icon"><BsTags /></div>
            <div>
            {
                str_tagnames.split(',').map((tagname, index) => (
                    <span key={index} className="tag">{tagname}</span>
                ))
            }
            </div>
        </div>
    )
}

MessageTags.propTypes = {
    str_tagnames: PropTypes.string
}

export default MessageTags
