import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import HeaderFooterPageBase from '../../components/page-layout/HeaderFooterPageBase';
import AuthService from '../../util/authentication/auth_service';

import Usertypes from '../../util/Usertypes';

const ProfileSetupLegalPage = () => {

    const [hasPermission, setHasPermission] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        /**
         * Provide user with permissions only if he is setting up the profile
         * and does not yet have a valid abo.
         */
        const user = AuthService.getUser()
        if (
            !user ||
            user.isProfileSetup ||
            user.isProfileSetup === undefined ||
            (user.isAboValid && user.usertype !== Usertypes.student) ||
            user.isAboValid === undefined
        ) {
            navigate('/login')
        } else {
            setHasPermission(true)
        }
    }, [])

    return (
        hasPermission &&
        <HeaderFooterPageBase
            str_mainNavDataKey='profileSetupLegal'
            b_hasMainNavLogoLink={false}
            b_hasFooter={false}
            b_hasPageBase={true}
            b_isPrivatePage={false}
        >
            <Outlet />
        </HeaderFooterPageBase>
    )
}

export default ProfileSetupLegalPage
