import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import CSecBase from '../../components/content-sections/CSecBase';
import DocuPageHeader from '../../components/docu-pages/DocuPageHeader';
import DocuPageParagraph from '../../components/docu-pages/DocuPageParagraph';
import Accordion from '../../components/accordion/Accordion';
import AccordionHeader from '../../components/accordion/AccordionHeader';
import AccordionParagraph from '../../components/accordion/AccordionParagraph';
import AccordionList from '../../components/accordion/AccordionList';
import AccordionListItem from '../../components/accordion/AccordionListItem';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const PrivacyPolicyPage = () => {
    
    const history = useNavigate()
    
    useSetBrowserTabTitle('Datenschutzrichtlinie')
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [history])
    
    return (
        <CSecBase str_classes='docu-page-section-content'>
            <DocuPageHeader
                str_title='Datenschutzrichtlinie'
                str_date='18.04.2023'
            >
                <DocuPageParagraph>
                    MUPLAZA's Datenschutzrichtlinie erklärt wie MUPLAZA persönliche Daten sammelt, verwendet und teilt.
                </DocuPageParagraph>
            </DocuPageHeader>

            <Accordion str_title='Einleitung'>
                <AccordionParagraph>
                    Wir verpflichten uns, die Privatsphäre der Nutzer unserer Dienste zu schützen.
                </AccordionParagraph>
                <AccordionParagraph>
                    Diese Datenschutzrichtlinie findet Anwendung, wenn wir als der für die Verarbeitung Verantwortliche in Bezug auf die personenbezogenen Daten unserer Nutzer handeln, d.h. wenn wir die Zwecke und Mittel der Verarbeitung dieser personenbezogenen Daten bestimmen.
                </AccordionParagraph>
                <AccordionParagraph>
                    Wir verwenden Cookies auf unserer Plattform. Soweit diese Cookies nicht unbedingt für die Bereitstellung unserer Plattform und unserer Dienste erforderlich sind, bitten wir Sie bei Ihrem ersten Besuch unserer Plattform um Ihre Einwilligung zur Verwendung dieser Cookies.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Die von uns gesammelten personenbezogenen Daten'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>Die uns von Ihnen zur Verfügung gestellten Daten</AccordionHeader>
                <AccordionParagraph>
                    Um einige unserer Dienste zu nutzen, benötigen Sie ein Konto und um ein Konto zu erstellen, müssen Sie uns bestimmte Informationen zur Verfügung stellen. Wenn Sie unsere kostenpflichtigen Dienste nutzen möchten, können wir Ihnen diese ohne Zahlungsinformationen nicht zur Verfügung stellen. Grundsätzlich sind bestimmte Informationen erforderlich, wenn Sie unsere Dienste nutzen möchten.
                </AccordionParagraph>
                <AccordionParagraph>
                    <span className='text-bold'>Persönliches Benutzerkonto. </span>
                    Wenn Sie ein Benutzerkonto erstellen, müssen Sie einige Angaben machen, damit wir Ihnen unsere Dienste anbieten können. Dazu gehören je nach Benutzergruppe ein Passwort, eine E-Mail-Adresse, eine Telefonnummer, ein Vor- und Nachname sowie der Name der Organisation, der Sie angehören. Bestimmte Profilinformationen, zu denen Ihr Vor- und Nachname, Ihr Benutzername und je nach Benutzergruppe der Name der Organisation, für die das Benutzerkonto eröffnet wurde, sind immer öffentlich und können von anderen Benutzern eingesehen werden.
                </AccordionParagraph>
                <AccordionParagraph>
                    <span className='text-bold'>Gastronomie- und Industriekonto. </span>
                    Wenn Sie ein Gastronomie- oder Industriekonto erstellen, müssen Sie zusätzliche Informationen angeben, wie z.B. Ihre Kontaktdaten, Kontaktdaten von Ansprechpersonen und allgemeine Informationen über die Organisation selbst.
                </AccordionParagraph>
                <AccordionParagraph>
                    <span className='text-bold'>Kontaktdaten. </span>
                    Wir können Daten verarbeiten, die es uns ermöglichen, mit Ihnen in Kontakt zu treten. Zu den Kontaktdaten können Ihr Name, Ihre E-Mail-Adresse, Ihre Telefonnummer und Ihre Postanschrift gehören. Die Quelle der Kontaktdaten sind Sie und/oder Ihr Arbeitgeber.
                </AccordionParagraph>
                <AccordionParagraph>
                    <span className='text-bold'>Zahlungsinformation. </span>
                    Um unserer kostenpflichtigen Dienste nutzen zu können, müssen Sie auf der Plattform Zahlungsinformationen angeben, einschließlich Ihrer Kredit- oder Debitkartennummer, des Ablaufdatums der Karte, des CVV-Codes und der Rechnungsadresse. Ihre Zahlungsinformationen werden nicht auf den Servern der MUPLAZA Dienste gespeichert. Der Zahlungsvorgang wird vollständig von unserem beauftragten Zahlungsabwickler Stripe (www.stripe.com – nachfolgend kurz als "Stripe" bezeichnet) abgewickelt.
                </AccordionParagraph>

                <AccordionHeader>Die von uns durch Ihre Nutzung gesammelten Daten</AccordionHeader>
                <AccordionParagraph>
                    <span className='text-bold'>Nutzungsdaten. </span>
                    Wir sammeln Daten über Ihre Aktivität auf der Plattform, wie z.B.:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        Dateien, die Sie hochladen, Nachrichten, die Sie erstellen und andere Inhalte, die Sie auf der Plattform kreieren und/oder teilen;
                    </AccordionListItem>
                    <AccordionListItem>
                        Ihre Interaktionen mit den Inhalten anderer Nutzer, z.B. Kommentare, Likes, Shares, Antworten, wenn andere Nutzer Sie in Inhalten erwähnen oder markieren oder wenn Sie sie erwähnen oder markieren;
                    </AccordionListItem>
                    <AccordionListItem>
                        wie Sie mit anderen auf der Plattform interagieren, z.B. mit Personen in den verschiedenen Foren, einschließlich des Inhalts der Nachrichten, sowie Datum und Uhrzeit der Nachrichten;
                    </AccordionListItem>
                    <AccordionListItem>
                        wenn Sie mit uns kommunizieren, z.B. per E-Mail, erfassen wir Informationen über die Kommunikation und deren Inhalt;
                    </AccordionListItem>
                    <AccordionListItem>
                        Informationen über die Links, mit denen Sie in unseren Diensten interagieren (einschließlich in den E-Mails, die wir Ihnen senden).
                    </AccordionListItem>
                </AccordionList>
                <AccordionParagraph>
                    <span className='text-bold'>Käufe und Zahlungen. </span>
                    Wenn Sie über MUPLAZA eine Zahlung vornehmen oder Geld senden, auch über einen Vermittler, können wir Informationen über Ihre Transaktion erhalten, z.B. wann die Transaktion durchgeführt wurde, wann ein Abonnement abläuft oder automatisch verlängert wird und welche Beträge Sie bezahlt oder erhalten haben.
                </AccordionParagraph>
                <AccordionParagraph>
                    <span className='text-bold'>Gerätedaten. </span>
                    Wir sammeln Informationen von und über die Geräte, die Sie für den Zugriff auf MUPLAZA verwenden:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        Daten über Ihre Verbindung, wie z.B. IP-Adresse oder Internetbrowser;
                    </AccordionListItem>
                    <AccordionListItem>
                        Informationen über Ihr Gerät und seine Einstellungen, z.B. Geräte- und Werbe-ID, Betriebssystem, Anbieter, Sprache, Speicher, installierte Anwendungen und Akkustand.
                    </AccordionListItem>
                </AccordionList>
                <AccordionParagraph>
                    <span className='text-bold'>Standortdaten. </span>
                    Wenn Sie unsere Dienste nutzen, sammeln wir einige Informationen über Ihren ungefähren Standort, um den von Ihnen erwarteten Dienst bereitzustellen.
                </AccordionParagraph>
                <AccordionParagraph>
                    <span className='text-bold'>Protokolldaten. </span>
                    Wir können Informationen erhalten, wenn Sie Inhalte unserer Dienste ansehen oder anderweitig mit diesen interagieren, wie z.B.: IP-Adresse, Browsertyp und -sprache, Betriebssystem, die verweisende Webseite, Zugriffszeiten, besuchte Seiten, Standort, Ihr Mobilfunkanbieter, Geräteinformationen (einschließlich Geräte- und Anwendungs-IDs) und Suchbegriffe und -IDs (einschließlich solcher, die nicht als Anfrage übermittelt wurden).
                </AccordionParagraph>
                <AccordionParagraph>
                    <span className='text-bold'>Cookies und ähnliche Technologien. </span>
                    Wie viele andere Webseiten verwenden auch wir Cookies und ähnliche Technologien, um zusätzliche Informationen über die Nutzung der Webseite zu sammeln und unsere Dienste zu betreiben.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Wie wir gesammelte personenbezogene Daten verwenden'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>Betreibung und Verbesserung unserer Dienste</AccordionHeader>
                <AccordionParagraph>
                    Wir verwenden die von uns erfassten Daten, um unsere Dienste bereitzustellen und zu betreiben. Wir verwenden die erhobenen Daten auch, um unsere Produkte und Dienste zu verbessern. Die Rechtsgrundlage für diese Verarbeitung ist unser berechtigtes Interesse an der ordnungsgemäßen Verwaltung unserer Plattform und unserer Dienste.
                </AccordionParagraph>

                <AccordionHeader>Beziehungen und Kommunikation</AccordionHeader>
                <AccordionParagraph>
                    Wir können Kontakt-, Konto-, Transaktions- und/oder Kommunikationsdaten verarbeiten, um unsere Beziehungen zu verwalten, mit Ihnen per E-Mail, SMS, Post, Fax und/oder Telefon zu kommunizieren, Support zu leisten und Beschwerden zu bearbeiten. Die Rechtsgrundlage für diese Verarbeitung ist unser berechtigtes Interesse an der Kommunikation mit den Nutzern unserer Dienste, der Pflege unserer Beziehungen und der ordnungsgemäßen Verwaltung unserer Plattform und Dienste.
                </AccordionParagraph>

                <AccordionHeader>Direktmarketing</AccordionHeader>
                <AccordionParagraph>
                    Wir können Kontaktdaten, Kontodaten und/oder Transaktionsdaten für die Erstellung, Ausrichtung und Versendung von Direktmarketingkommunikation per E-Mail, SMS, Post und/oder Fax sowie für die telefonische Kontaktaufnahme zu Marketingzwecken verarbeiten. Die Rechtsgrundlage für diese Verarbeitung ist unser berechtigtes Interesse an der Förderung unserer Dienste und der Übermittlung von Marketingnachrichten und -angeboten an unsere Dienstleistungsnutzer.
                </AccordionParagraph>

                <AccordionHeader>Analyse unserer Dienste</AccordionHeader>
                <AccordionParagraph>
                    Wir verwenden die von uns gesammelten Informationen, um die Wirksamkeit unserer Dienste zu messen und zu analysieren und um besser zu verstehen, wie Sie sie nutzen, damit wir sie verbessern können.
                </AccordionParagraph>

                <AccordionHeader>Produktentwicklung</AccordionHeader>
                <AccordionParagraph>
                    Wir verwenden die Informationen, die Sie uns zur Verfügung stellen oder die wir sammeln, um Produkttests, Produktanalysen und Fehlerbehebungen durchzuführen, die uns helfen, unsere Dienste zu betreiben und zu verbessern.
                </AccordionParagraph>

                <AccordionHeader>Führung von Aufzeichnungen</AccordionHeader>
                <AccordionParagraph>
                    Wir können Ihre personenbezogenen Daten zum Zweck der Erstellung und Führung unserer Datenbanken, von Sicherungskopien unserer Datenbanken und unserer Dienstunterlagen im Allgemeinen verarbeiten. Die Rechtsgrundlage für diese Verarbeitung ist unser berechtigtes Interesse an der Sicherstellung des Zugangs zu allen Informationen, die wir für die ordnungsgemäße und effiziente Erbringung unserer Dienstleistungen gemäß dieser Richtlinie benötigen.
                </AccordionParagraph>

                <AccordionHeader>Förderung der Sicherheit</AccordionHeader>
                <AccordionParagraph>
                    Wir verwenden die von uns erfassten Informationen, um die Sicherheit unserer Nutzer, unserer Dienste und Ihres Kontos zu gewährleisten. Dies beinhaltet die Überprüfung Ihrer Identität, die Authentifizierung Ihres Kontos und den Schutz vor Betrug, unbefugter Nutzung und illegalen Aktivitäten. Wir verwenden diese Informationen auch, um die Sicherheit und Qualität der Inhalte unserer Dienste zu bewerten und zu verbessern.
                </AccordionParagraph>

                <AccordionHeader>Versicherung und Risikomanagement</AccordionHeader>
                <AccordionParagraph>
                    Wir können Ihre personenbezogenen Daten verarbeiten, wenn dies für den Abschluss oder die Aufrechterhaltung eines Versicherungsschutzes, für das Risikomanagement und/oder für die Einholung einer fachlichen Beratung erforderlich ist. Dies dient unserem Interesse, unsere Dienste angemessen gegen Risiken abzusichern.
                </AccordionParagraph>

                <AccordionHeader>Rechtliche Ansprüche</AccordionHeader>
                <AccordionParagraph>
                    Wir können Ihre personenbezogenen Daten verarbeiten, wenn dies zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist, sei es im Rahmen eines Gerichts-, Verwaltungs- oder außergerichtlichen Verfahrens. Diese Verarbeitung dient dem Schutz und der Geltendmachung unserer gesetzlichen Rechte, Ihrer gesetzlichen Rechte und der gesetzlichen Rechte Dritter.
                </AccordionParagraph>

                <AccordionHeader>Einhaltung der Rechtsvorschriften und lebenswichtiger Interessen</AccordionHeader>
                <AccordionParagraph>
                    Wir können Ihre personenbezogenen Daten auch verarbeiten, wenn die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung, der wir unterliegen, oder zum Schutz lebenswichtiger Interessen von Ihnen oder anderen natürlichen Person erforderlich ist.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Teilen von Daten'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>Erstellte Inhalte</AccordionHeader>
                <AccordionParagraph>
                    Die Daten auf der Plattform können nur mit einem registrierten Benutzerkonto eingesehen werden und sind somit nicht öffentlich zugänglich. Daten, die Sie auf der Plattform erstellen oder hochladen, können von anderen Nutzern derselben oder einer anderen Nutzergruppe eingesehen und geteilt werden.
                </AccordionParagraph>

                <AccordionHeader>Infrastruktur</AccordionHeader>
                <AccordionParagraph>
                        Ihre persönlichen Daten in der Datenbank unserer Plattform werden auf den Servern unseres Hosting-Dienstleisters (
                    <a href='https://aws.amazon.com' target='_blank' rel="noopener noreferrer" className='page-link'>
                        https://aws.amazon.com
                    </a>
                    ) gespeichert.
                </AccordionParagraph>

                <AccordionHeader>Versicherung</AccordionHeader>
                <AccordionParagraph>
                    Wir können Ihre personenbezogenen Daten an unsere Versicherer und/oder professionellen Berater weitergeben, soweit dies für den Abschluss oder die Aufrechterhaltung eines Versicherungsschutzes, das Risikomanagement und die Einholung professioneller Beratung erforderlich ist.
                </AccordionParagraph>

                <AccordionHeader>Zahlungsvorgänge</AccordionHeader>
                <AccordionParagraph>
                    Finanzielle Transaktionen im Zusammenhang mit unserer Plattform und unseren Diensten können über unsere Zahlungsdienstleister abgewickelt werden. Wir geben Transaktionsdaten an unsere Zahlungsdienstleister nur in dem Umfang weiter, der für die Bearbeitung Ihrer Zahlungen, die Rückerstattung solcher Zahlungen und die Bearbeitung von Beschwerden und Anfragen im Zusammenhang mit solchen Zahlungen und Rückerstattungen erforderlich ist.
                </AccordionParagraph>

                <AccordionHeader>Um Gesetzen nachzukommen, Schäden vorzubeugen oder im öffentlichen Interesse zu handeln</AccordionHeader>
                <AccordionParagraph>
                    Wir können Ihre Daten weitergeben oder offenlegen, wenn wir der Meinung sind, dass dies vernünftigerweise erforderlich ist, um:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        einem Gesetz, einer Verordnung, einem Gerichtsverfahren oder einer behördlichen Aufforderung nachzukommen;
                    </AccordionListItem>
                    <AccordionListItem>
                        die Sicherheit einer Person oder die Sicherheit oder Integrität unserer Plattform zu schützen, einschließlich der Verhinderung von Spam, Missbrauch oder böswilligen Handlungen auf unseren Diensten;
                    </AccordionListItem>
                    <AccordionListItem>
                        unsere Rechte oder unser Eigentum oder die Rechte oder das Eigentum derjenigen, die unsere Dienste nutzen, zu schützen.
                    </AccordionListItem>
                </AccordionList>
            </Accordion>

            <Accordion str_title='Datentransfer'>
                <AccordionParagraph>
                    Um globale Unterhaltungen zwischen den Nutzern zur ermöglichen, müssen wir Informationen über Grenzen hinweg und in verschiedene Länder auf der ganzen Welt übertragen, um den sicheren und zuverlässigen Dienst zu unterstützen, auf den Sie sich verlassen. Wenn zum Beispiel eine Kommunikation zwischen Nutzern in Europa und den USA stattfindet, müssen Informationen zwischen diesen Ländern ausgetauscht werden, um diese Erfahrung zu ermöglichen.
                </AccordionParagraph>
                <AccordionParagraph>
                    Wir können Ihre personenbezogenen Daten aus dem Europäischen Wirtschaftsraum (EWR) in das Vereinigte Königreich oder die Vereinigten Staat übertragen und diese personenbezogenen Daten im Vereinigten Königreich oder in den Vereinigten Staaten (für die in dieser Richtlinie dargelegten Zwecke) verarbeiten, und wir können unseren Lieferanten und Unterauftragnehmern gestatten, dies während eines Zeitraums zu tun, in dem das Vereinigte Königreich oder die Vereinigten Staaten nach dem EU-Datenschutzrecht nicht als Drittländer behandelt werden oder nicht unter einen Angemessenheitsbeschluss nach dem EU-Datenschutzrecht fallen; und wir können Ihre personenbezogenen Daten aus dem Vereinigten Königreich oder den Vereinigten Staaten in den EWR übertragen und diese personenbezogenen Daten im EWR für die in dieser Richtlinie dargelegten Zwecke verarbeiten und können unseren Lieferanten und Unterauftragnehmern gestatten, dies während eines Zeitraums zu tun, in dem die EWR-Staaten nach dem Datenschutzrecht des Vereinigten Königreichs oder der Vereinigten Staaten nicht als Drittländer behandelt werden oder unter die Angemessenheitsbestimmungen nach dem Datenschutzrecht des Vereinigten Königreichs oder der Vereinigten Staaten fallen.
                </AccordionParagraph>
                <AccordionParagraph>
                    Sie erkennen an, dass personenbezogene Daten, die Sie zur Veröffentlichung über unsere Plattform oder Dienste übermitteln, über das Internet weltweit zugänglich sein können. Wir können die Nutzung (oder den Missbrauch) dieser personenbezogenen Daten durch Dritte nicht verhindern.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Wie lange werden Daten gespeichert'>
                <AccordionParagraph>
                    Je nach Datentyp werden Daten von uns für unterschiedliche Zeiträume aufbewahrt:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        Wir speichern Ihre Profilinformationen und Inhalte für die Dauer Ihres Kontos.
                    </AccordionListItem>
                    <AccordionListItem>
                        Andere personenbezogene Daten, die wir bei der Nutzung unserer Produkte und Dienstleistungen erheben, speichern wir in der Regel nicht länger als 18 Monate.
                    </AccordionListItem>
                    <AccordionListItem>
                        Wenn Sie gegen unsere Regeln verstoßen und Ihr Konto gesperrt wird, können wir die Identifizierungsdaten, die Sie zur Erstellung des Kontos verwendet haben (z.B. E-Mail-Adresse oder Telefonnummer), auf unbestimmte Zeit speichern, um zu verhindern, dass Wiederholungstäter neue Konten erstellen.
                    </AccordionListItem>
                    <AccordionListItem>
                        Es kann sein, dass wir bestimmte Informationen länger aufbewahren, als in unserer Richtlinie angegeben, um gesetzliche Anforderungen zu erfüllen und aus Sicherheitsgründen.
                    </AccordionListItem>
                </AccordionList>
            </Accordion>

            <Accordion str_title='Kontrolle über Ihre Daten'>
                <AccordionParagraph>
                    Nachfolgend finden Sie Ihre Rechte in Bezug auf Ihre persönlichen Daten gemäß dem Datenschutzgesetz.
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        <span className='text-bold'>Zugriff. </span>
                        Sie können Kopien Ihrer persönlichen Daten anfordern.
                    </AccordionListItem>
                    <AccordionListItem>
                        <span className='text-bold'>Berichtigung. </span>
                        Sie können auf die Informationen, die Sie uns zur Verfügung gestellt haben, einsehen, sie korrigieren oder ändern, indem Sie Ihr Profil bearbeiten und Ihre Kontoeinstellungen anpassen.
                    </AccordionListItem>
                    <AccordionListItem>
                        <span className='text-bold'>Löschung. </span>
                        <span>Sie können die Löschung Ihrer Daten über das MUPLAZA </span>
                        <Link to='../../contact/general' target='_blank' className='page-link'>Kontaktformular</Link>
                        <span> beantragen.</span>
                        Nach der Deaktivierung sind Ihre persönlichen Profildaten auf der Plattform nicht mehr sichtbar. Es kann einige Wochen dauern bis Ihre Daten vollständig von der Plattform verschwunden sind, da ein solcher Vorgang mit Wartungsintervallen oder anderen Systemprozessen kollidieren kann.
                    </AccordionListItem>
                    <AccordionListItem>
                        <span className='text-bold'>Transfer. </span>
                        Sie können uns auffordern, Ihre personenbezogenen Daten an Sie selbst weiterzugeben.
                    </AccordionListItem>
                </AccordionList>

                <AccordionHeader>Anfrage zur Ausübung Ihrer Rechte auf Ihre personenbezogenen Daten</AccordionHeader>
                <AccordionParagraph>
                    Zum Schutz Ihrer Privatsphäre und zur Aufrechterhaltung der Sicherheit ergreifen wir Maßnahmen, zur Überprüfung Ihrer Identität, bevor wir Ihnen Zugang zu Ihren personenbezogenen Daten gewähren oder einem Antrag auf Löschung, Übertragung oder andere damit zusammenhängende Angelegenheiten bearbeiten. In bestimmten Situationen können wir Ihren Antrag auf Zugang, Berichtigung oder Übertragbarkeit ablehnen, z.B. wenn Sie Ihre Identität nicht nachweisen können.
                </AccordionParagraph>
                <AccordionParagraph>
                    Sie können alle Ihre Rechte in Bezug auf Ihre personenbezogenen Daten ausüben, indem Sie uns dies schriftlich über unser&nbsp; 
                    <Link to='../../contact/general' target='_blank' className='page-link'>Kontaktformular</Link>
                    &nbsp;mitteilen.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Cookies'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>Allgemeine Informationen zu Cookies</AccordionHeader>
                <AccordionParagraph>
                    Ein Cookie ist eine Datei, die eine Kennung (eine Zeichenfolge aus Buchstaben und Zahlen) enthält, die von einem Webserver an einen Webbrowser gesendet und vom Browser gespeichert wird. Die Kennung wird dann jedes Mal an den Server zurückgesandt, wenn der Browser eine Seite vom Server anfordert.
                </AccordionParagraph>
                <AccordionParagraph>
                    Bei Cookies kann es sich entweder um "dauerhafte" Cookies oder um "Sitzungscookies" handeln: Ein permanenter Cookie wird vom Webbrowser gespeichert und bleibt bis zu seinem festgelegten Verfallsdatum gültig, es sei denn, er wird vom Nutzer vor dem Verfallsdatum gelöscht; ein Sitzungscookie hingegen verfällt am Ende der Nutzersitzung, wenn der Webbrowser geschlossen wird.
                </AccordionParagraph>
                <AccordionParagraph>
                    Cookies dürfen keine Informationen enthalten, die einen Benutzer persönlich identifizieren, aber die persönlichen Daten, die wir über Sie speichern, können mit den in Cookies gespeicherten und von ihnen erhaltenen Informationen verknüpft werden.
                </AccordionParagraph>

                <AccordionHeader>Unsere verwendeten Cookies</AccordionHeader>
                <AccordionParagraph>
                    <span className='text-bold'>Sicherheit. </span>
                    Wir verwenden Cookies als Teil der Sicherheitsmaßnahmen und zum Schutz unserer Plattform und Dienste im Allgemeinen (sog. Benutzer-Cookies).
                </AccordionParagraph>
                <AccordionParagraph>
                    <span className='text-bold'>Analyse. </span>
                    Wir verwenden Cookies, um die Nutzung und Leistung unserer Plattform und Dienste zu analysieren (sog. Benutzer-Cookies).
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Benutzergruppen'>
                <AccordionParagraph>
                    Unsere Dienste werden von den folgenden Benutzergruppen in Anspruch genommen:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        <span className='text-bold'>Studierende. </span>
                        Die Nutzung unserer Dienste durch Studierende beschränkt sich auf immatrikulierte Studierende der Montanuniversität Leoben.
                    </AccordionListItem>
                    <AccordionListItem>
                        <span className='text-bold'>Gastronomen. </span>
                        Die Nutzung unserer Dienste durch Gastronomen wird hauptsächlich, aber nicht nur, durch Gastronomen aus der Stadt Leoben und seiner umliegenden Region in Anspruch genommen.
                    </AccordionListItem>
                    <AccordionListItem>
                        <span className='text-bold'>Industrie. </span>
                        Die Nutzung unserer Dienste durch Unternehmen aus der Industrie.
                    </AccordionListItem>
                </AccordionList>
            </Accordion>

            <Accordion str_title='Änderungen dieser Datenschutzrichtlinie'>
                <AccordionParagraph>
                    Die aktuellste Version dieser Datenschutzrichtlinie regelt unsere Verarbeitung Ihrer personenbezogenen Daten. Wir können diese Datenschutzrichtlinie von Zeit zu Zeit nach Bedarf überarbeiten. Wenn wir diese Datenschutzrichtlinie überarbeiten und Änderungen vornehmen, die wir als wesentlich erachten, können wir Sie darüber informieren und Ihnen die Möglichkeit geben, die überarbeitete Datenschutzrichtlinie zu lesen, bevor Sie unsere Dienste weiter nutzen.
                </AccordionParagraph>
            </Accordion>
        </CSecBase>
    )
}

export default PrivacyPolicyPage
