import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import SingleChoiceField from './SingleChoiceField';
import SelectiveProposalField from './SelectiveProposalField';

import { useHandleInputChange } from '../context-provider/InputFormContextProvider';
import SelectiveProposalFieldConfigData from '../util/field-config-data/SelectiveProposalFieldConfigData';

/* Field config. */

const CHOICE_FIELD_TITLE = 'Kurse';
const COURSE_THIS_COURSE_CHOICE = 'Dieser Kurs';
const COURSE_ALL_COURSES_CHOICE = 'Alle Kurse';
const COURSE_PROPOSAL_CHOICE = 'Wähle Kurse';
const COURSE_CHOICES_0 = [
    COURSE_THIS_COURSE_CHOICE,
    COURSE_ALL_COURSES_CHOICE,
    COURSE_PROPOSAL_CHOICE
];
const COURSE_CHOICES_1 = [
    COURSE_ALL_COURSES_CHOICE,
    COURSE_PROPOSAL_CHOICE
];

const PROPOSAL_FIELD_TITLE = SelectiveProposalFieldConfigData.courseTitle.fieldTitle;
const PROPOSAL_MIN_LENGTH = 3;
const PROPOSAL_MAX_LENGTH = 14;
const MAX_NUM_PROPOSALS = 4;
const PROPOSAL_FIELD_BOTTOM_INFO_TEXT = `Wähle bis zu ${MAX_NUM_PROPOSALS} Kurse gleichzeitig.`;
const PROPOSAL_QUERY_URL = SelectiveProposalFieldConfigData.courseTitle.proposalQueryUrl;
const PROPOSAL_REQUEST_DATA_KEY = SelectiveProposalFieldConfigData.courseTitle.requestDataKey;
const PROPOSAL_CHAR_KEY = SelectiveProposalFieldConfigData.courseTitle.charKey; /* DB field name. */

const CourseProposalField = ({
    str_choiceFieldId,
    str_proposalFieldId,
    str_currentCourseOption='',
    arr_currentCourseProposals=[]
}) => {

    const params = useParams()
    const handleInputChange = useHandleInputChange()

    const courseChoices = params.id === undefined ? COURSE_CHOICES_1 : COURSE_CHOICES_0

    useEffect(() => {
        /**
         * Hide the proposal field depending on the current option of the choice field.
         * Reset its value if it is hidden (no selection possible, no data stored).
         */
        if (str_currentCourseOption !== COURSE_PROPOSAL_CHOICE) {
            arr_currentCourseProposals = []
            handleInputChange(
                {
                    id: str_proposalFieldId,
                    value: [],
                    hasError: false
                }
            )
        } else {
            /* If the course proposal field gets activated, its error state must be set
             * to true as it is a required field. If it is hidden, its error state is
             * always false. */
            if (!arr_currentCourseProposals.length) {
                handleInputChange(
                    {
                        id: str_proposalFieldId,
                        value: [],
                        hasError: true
                    }
                )
            }
        }
    }, [str_currentCourseOption])

    return (
        <>
            <SingleChoiceField
                str_id={str_choiceFieldId}
                str_fieldTitle={CHOICE_FIELD_TITLE}
                str_currentOption={str_currentCourseOption}
                arr_options={courseChoices}
                b_isRequired={true}
                b_displayTopInfoText={false}
            />
            {
                str_currentCourseOption === COURSE_PROPOSAL_CHOICE &&
                <SelectiveProposalField
                    str_id={str_proposalFieldId}
                    str_fieldTitle={PROPOSAL_FIELD_TITLE}
                    arr_proposals={arr_currentCourseProposals}
                    str_bottomInfoText={PROPOSAL_FIELD_BOTTOM_INFO_TEXT}
                    str_proposalQueryUrl={PROPOSAL_QUERY_URL}
                    str_requestDataKey={PROPOSAL_REQUEST_DATA_KEY}
                    str_charKey={PROPOSAL_CHAR_KEY}
                    int_maxNumProposals={MAX_NUM_PROPOSALS}
                    int_minLength={PROPOSAL_MIN_LENGTH}
                    int_maxLength={PROPOSAL_MAX_LENGTH}
                    b_isRequired={true}
                />
            }
        </>
    )
}

CourseProposalField.propTypes = {
    str_choiceFieldId: PropTypes.string.isRequired,
    str_proposalFieldId: PropTypes.string.isRequired,
    str_currentCourseOption: PropTypes.string,
    arr_currentCourseProposals: PropTypes.array
}

export default CourseProposalField
