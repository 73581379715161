import React from 'react';
import PropTypes from 'prop-types';

import CSecBase from '../../../content-sections/CSecBase';
import InputFormContextProvider from '../../../input-forms/context-provider/InputFormContextProvider';
import FilterFormTags from '../../filter-forms/courses/FilterFormTags';

import { useFilterStateContext } from '../../context-provider/FilterStateContextProvider';

import { static2dynamicPanelConfig } from '../../../input-forms/util/input_form_data_handler';
import { FilterFormTagsData as FormData } from '../../../input-forms/util/form-config-data/InputFormFilterDataConfigs';

const FilterTags = ({
    str_classes='',
    b_isTransparent=false,
    b_hasPadding=false
}) => {

    const filterState = useFilterStateContext()

    let dynConfig = null;
    if (filterState) {
        dynConfig = { obj_initState: filterState }
    }

    return (
        <CSecBase str_classes={`
            ${b_hasPadding ? '' : 'no-padding'}
            ${str_classes}
        `}>
            <InputFormContextProvider
                {...static2dynamicPanelConfig(FormData, dynConfig)}
                obj_clearedState={FormData.obj_initState}
                b_isFilterContext={true}
                b_hasDiscardBtn={false}
            >
                <FilterFormTags b_isTransparent={b_isTransparent} />
            </InputFormContextProvider>
        </CSecBase>
    )
}

FilterTags.propTypes = {
    str_classes: PropTypes.string,
    b_isTransparent: PropTypes.bool,
    b_hasPadding: PropTypes.bool
}

export default FilterTags
