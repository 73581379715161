import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PPMainContentBase from '../../profile-pages/PPMainContentBase';
import InputFormContextProvider from '../context-provider/InputFormContextProvider';
import FormSuccessErrorMessage from '../FormSuccessErrorMessage';
import { fetchLSdata, setLSdata } from '../../../util/db_ls_query_handler';
import { PPLsKeys } from '../../../util/LocalStorageVariables';

const PR_LS_KEY = PPLsKeys.primaryDataKey;

const PPFormBase = ({
    str_requestType='put',
    str_localStorageKey='',
    str_queryURL,
    str_navigationURLonDiscard,
    b_hasDiscardBtn=true,
    b_hasMediaData=false, /* True if files are sent along with the form data. */
    obj_initState,
    arr_lsUpdateKeys, /* Update LS data if desired with these keys. */
    fct_response2parent,
    formChild
}) => {
    const [isDataPosted, setIsDataPosted] = useState(false)

    const posted = (response) => {
        setIsDataPosted(true)
        if (fct_response2parent) {
            fct_response2parent(response)
        }
        if (arr_lsUpdateKeys) {
            const str_category = arr_lsUpdateKeys[0]
            const str_subcategory = arr_lsUpdateKeys[1]
            /**
             * The posted data is only written to the LS if the data of 'category' is already there.
             * The only way how this category is set, is by visiting the pages that show the different
             * subcategories. E.g. the page that shows the different entries of 'info'.
             * The form pages cannot init. this LS data as they would only write one 'subcategory' to
             * the 'category'. If the overview page is loaded afterwards, it detects that category is
             * set and uses the LS although the other 'subcategories' might not be present yet.
             *  
             * If an import form is discarded without any data changes, no response is received.
             */
            const lsData = fetchLSdata(PR_LS_KEY, str_category)
            if (response && lsData) {
                setLSdata(response.data, PR_LS_KEY, str_category, str_subcategory)
            }
        }
    }
    
    return (
        isDataPosted
        ?
        <PPMainContentBase>
            <FormSuccessErrorMessage b_isSuccess={true} />
        </PPMainContentBase>
        :
        <PPMainContentBase>
            <InputFormContextProvider
                str_localStorageKey={str_localStorageKey}
                str_navigationURLonDiscard={str_navigationURLonDiscard}
                str_queryURL={str_queryURL}
                str_requestType={str_requestType}
                b_isPrivatePost={true}
                b_hasDiscardBtn={b_hasDiscardBtn}
                b_hasMediaData={b_hasMediaData}
                obj_initState={obj_initState}
                fct_response2parent={posted}
            >
                {formChild}
            </InputFormContextProvider>
        </PPMainContentBase>
    )
}

PPFormBase.propTypes = {
    str_requestType: PropTypes.string,
    str_localStorageKey: PropTypes.string,
    str_queryURL: PropTypes.string.isRequired,
    str_navigationURLonDiscard: PropTypes.string.isRequired,
    b_hasDiscardBtn: PropTypes.bool,
    b_hasMediaData: PropTypes.bool,
    obj_initState: PropTypes.object.isRequired,
    arr_lsUpdateKeys: PropTypes.array,
    fct_response2parent: PropTypes.func
}

export default PPFormBase
