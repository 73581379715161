/**
 * This file entails all the input form configs for course pages. 
 */

import { dateFromTodayAsBrowserString } from '../../../../util/date_time_handler';
import FieldLimits from '../db_field_limits.json';
import BottomTexts from '../input_form_bottom_info_texts.json';
import PlaceholderTexts from '../input_form_placeholder_texts.json';
import { genFieldData } from "./InputFormBaseDataAndSerializer";
import { TemplateFields } from './InputFormTemplateConfigs';
import { isCourseDocumentTitleFormatValid } from '../input_checks';
import SelectiveProposalFieldConfigData from '../field-config-data/SelectiveProposalFieldConfigData';

const DATE = new Date();
const TARGET_GROUP_NUMS = [...Array(21).keys()].map(item => { return item.toString() });

const COURSE_TITLE_SPF_CONFIG = {
    str_id: 'courseTitle',
    str_fieldTitle: SelectiveProposalFieldConfigData.courseTitle.fieldTitle,
    str_proposalQueryUrl: SelectiveProposalFieldConfigData.courseTitle.proposalQueryUrl,
    str_requestDataKey: SelectiveProposalFieldConfigData.courseTitle.requestDataKey,
    str_charKey: SelectiveProposalFieldConfigData.courseTitle.charKey,
    int_maxNumProposals: 1,
    int_minLength: 4,
    int_maxLength: 14,
    b_isRequired: true
};

/* Forms. */

export const CoursesTutoringFormData = {
    str_localStorageKey: '',
    str_queryURL: '/courses/tutoring',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        courseTitle          : [[]       , true ],
        yearCoursePassed     : [undefined, true ],
        numCompletedSemesters: [undefined, false],
        numHoursTutored      : [undefined, true ],
        pricePerHour         : [undefined, false],
        email                : [''       , false],
        phone                : [''       , false]
    },
    fields: {
        courseTitle: COURSE_TITLE_SPF_CONFIG,
        yearCoursePassed: genFieldData('nbr', {
            str_id: 'yearCoursePassed',
            str_fieldTitle: 'Bestanden im Jahr',
            str_bottomInfoText: BottomTexts.course.tutor.offer.yearCoursePassed,
            nbr_minValue: FieldLimits.course.tutor.offer.yearCoursePassed.min,
            nbr_maxValue: DATE.getFullYear(),
            b_isYearField: true
        }),
        numCompletedSemesters: genFieldData('nbr', {
            str_id: 'numCompletedSemesters',
            str_fieldTitle: 'Abgeschlossene Semester',
            str_bottomInfoText: BottomTexts.course.tutor.offer.numCompletedSemester,
            nbr_minValue: 0,
            nbr_maxValue: 10,
            b_isRequired: false
        }),
        numHoursTutored: genFieldData('nbr', {
            str_id: 'numHoursTutored',
            str_fieldTitle: 'Erfahrung als Tutor',
            str_bottomInfoText: BottomTexts.course.tutor.offer.numHoursTutored,
            nbr_minValue: FieldLimits.course.tutor.offer.numHoursTutored.min,
            nbr_maxValue: FieldLimits.course.tutor.offer.numHoursTutored.max
        }),
        pricePerHour: genFieldData('nbr', {
            str_id: 'pricePerHour',
            str_fieldTitle: 'Preis pro Stunde',
            str_bottomInfoText: BottomTexts.course.tutor.offer.price,
            nbr_minValue: FieldLimits.course.tutor.offer.price.min,
            nbr_maxValue: FieldLimits.course.tutor.offer.price.max,
            b_isRequired: false
        }),
        email: {
            ...TemplateFields.contact.email,
            str_bottomInfoText: BottomTexts.course.swap.form.email,
            b_isRequired: false
        },
        phone: {
            ...TemplateFields.contact.phone,
            b_isRequired: false
        }
    }
};

export const CoursesCourseSwapFormData = {
    str_localStorageKey: '',
    str_queryURL: '/courses/course-swap',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        courseTitle:  [''       , true ],
        myGroup:      [undefined, true ],
        targetGroups: [[]       , true ],
        email:        [''       , false],
        phone:        [''       , false]
    },
    fields: {
        courseTitle: COURSE_TITLE_SPF_CONFIG,
        myGroup: genFieldData('nbr', {
            str_id: 'myGroup',
            str_fieldTitle: 'Meine Gruppe',
            nbr_minValue: FieldLimits.course.group.min,
            nbr_maxValue: FieldLimits.course.group.max
        }),
        targetGroups: genFieldData('mc', {
            str_id: 'targetGroups',
            str_fieldTitle: 'Zielgruppen',
            arr_options: TARGET_GROUP_NUMS
        }),
        email: {
            ...TemplateFields.contact.email,
            b_isRequired: false,
            str_bottomInfoText: BottomTexts.course.swap.form.email
        },
        phone: {
            ...TemplateFields.contact.phone,
            b_isRequired: false
        }
    }
};

export const CoursesDocsFormData = {
    str_localStorageKey: '',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        docFile            : [null , true ],
        title              : [''   , true ],
        category           : [''   , true ],
        isOfficial         : [false, false],
        hasValidatedAnswers: [false, false],
        isPeerReviewed     : [false, false],
        qualityRank        : [''   , true ],
        description        : [''   , true ],
        tagnames           : [''   , false]
    },
    fields: {
        docFile: genFieldData('file', {
            str_id: 'docFile',
            str_fieldTitle: 'Datei',
            arr_allowedFileTypes: FieldLimits.course.doc.file.extensions,
            nbr_maxSize: FieldLimits.course.doc.file.size
        }),
        title: genFieldData('text', {
            str_id: 'title',
            str_fieldTitle: 'Titel',
            str_bottomInfoText: BottomTexts.course.doc.title,
            b_isTextarea: false,
            int_maxLength: FieldLimits.course.doc.title,
            fct_isInputFormatValid: isCourseDocumentTitleFormatValid
        }),
        category: genFieldData('sc', {
            str_id: 'category',
            str_fieldTitle: 'Kategorie',
            arr_options: FieldLimits.course.doc.categories
        }),
        isOffical: genFieldData('b', {
            str_id: 'isOfficial',
            str_fieldTitle: 'Offiziell',
            str_boolInfoText: 'Ist das Dokument offiziell?',
            str_bottomInfoText: BottomTexts.course.doc.isOfficial
        }),
        hasValidatedAnswers: genFieldData('b', {
            str_id: 'hasValidatedAnswers',
            str_fieldTitle: 'Validierte Antworten',
            str_boolInfoText: 'Sind die Antworten gegen offizielle Lösungen gecheckt?',
        }),
        isPeerReviewed: genFieldData('b', {
            str_id: 'isPeerReviewed',
            str_fieldTitle: 'Peer Review',
            str_boolInfoText: 'Haben einige deiner Peers das Dokument kontrolliert?'
        }),
        qualityRank: genFieldData('nbr', {
            str_id: 'qualityRank',
            str_fieldTitle: 'Qualitätsstufe',
            str_bottomInfoText: BottomTexts.course.doc.qualityRank,
            nbr_minValue: FieldLimits.course.doc.qualityRank.min,
            nbr_maxValue: FieldLimits.course.doc.qualityRank.max
        }),
        description: genFieldData('text', {
            str_id: 'description',
            str_fieldTitle: 'Beschreibung',
            str_bottomInfoText: BottomTexts.course.doc.description,
            int_maxLength: FieldLimits.course.doc.description
        }),
        tagnames: genFieldData('tags', null)
    }
};

export const TaskFormData = {
    str_localStorageKey: '',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        date                  : [''       , true ],
        isExerciseTestQuestion: [false    , false],
        category              : [''       , true ],
        number                : [undefined, true ],
        completenessRank      : [undefined, true ],
        text                  : [''       , false],
        comment               : [''       , false],
        tagnames              : [''       , false],
        file0                 : [null     , false],
        file1                 : [null     , false],
    },
    fields: {
        date: genFieldData('date', {
            str_id: 'date',
            str_fieldTitle: 'Datum',
            str_minDate: FieldLimits.course.task.earliestDate,
            str_maxDate: dateFromTodayAsBrowserString(0)
        }),
        isExerciseTestQuestion: genFieldData('b', {
            str_id: 'isExerciseTestQuestion',
            str_fieldTitle: 'Testfrage',
            str_boolInfoText: 'Ist die Frage eine Testfrage?',
            str_bottomInfoText: BottomTexts.course.exam.question.isExerciseTest
        }),
        category: genFieldData('sc', {
            str_id: 'category',
            str_fieldTitle: 'Kategorie',
            arr_options: FieldLimits.course.task.question.categories,
            str_bottomInfoText: BottomTexts.course.exam.question.category
        }),
        number: genFieldData('nbr', {
            str_id: 'number',
            str_fieldTitle: 'Fragennummer',
            nbr_minValue: FieldLimits.course.task.question.number.min,
            nbr_maxValue: FieldLimits.course.task.question.number.max
        }),
        completenessRank: genFieldData('nbr', {
            str_id: 'completenessRank',
            str_fieldTitle: 'Vollständigkeit',
            str_bottomInfoText: BottomTexts.course.exam.question.completenessRank,
            nbr_minValue: FieldLimits.course.task.question.completenessRank.min,
            nbr_maxValue: FieldLimits.course.task.question.completenessRank.max
        }),
        text: genFieldData('text', {
            str_id: 'text',
            str_fieldTitle: 'Frage',
            str_placeholder: PlaceholderTexts.course.exam.question,
            str_bottomInfoText: BottomTexts.course.exam.question.text,
            int_maxLength: FieldLimits.course.task.question.text,
            b_isRequired: false
        }),
        comment: genFieldData('text', {
            str_id: 'comment',
            str_fieldTitle: 'Kommentar',
            str_bottomInfoText: BottomTexts.course.exam.question.comment,
            b_isRequired: false,
            int_maxLength: FieldLimits.course.task.question.comment
        }),
        tagnames: genFieldData('tags', null),
        file0: genFieldData('file', {
            str_id: 'file0',
            str_fieldTitle: '1. Datei',
            nbr_maxSize: FieldLimits.course.task.file.size,
            arr_allowedFileTypes: FieldLimits.course.task.file.extensions,
            b_isRequired: false
        }),
        file1: genFieldData('file', {
            str_id: 'file1',
            str_fieldTitle: '2. Datei',
            nbr_maxSize: FieldLimits.course.task.file.size,
            arr_allowedFileTypes: FieldLimits.course.task.file.extensions,
            b_isRequired: false
        })
    }
};

export const TaskDateChangeFormData = {
    str_localStorageKey: '',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        date: ['', true]
    },
    fields: {
        date: genFieldData('date', {
            str_id: 'date',
            str_fieldTitle: 'Neues Datum',
            str_minDate: FieldLimits.course.task.earliestDate,
            str_maxDate: dateFromTodayAsBrowserString(0),
            str_bottomInfoText: BottomTexts.course.exam.question.dateChange
        })
    }
};