import React from 'react';

import FormBaseWelcome from '../../FormBaseWelcome';
import InFieldsGroup from '../../InFieldsGroup';
import TextField from '../../fields/TextField';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { LoginFormData as FormData } from '../../util/form-config-data/InputFormWelcomeDataConfigs';
import { errorMsgs } from '../../../welcome/util/welcome_util';

const InputFormLogin = () => {
    const data = useInputData()
    return (
        <FormBaseWelcome
            str_btnTitle='Login'
            str_submitErrorMsg={errorMsgs.login}
            b_isRequiredInfoShown={false}
        >
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.username}
                    str_chars={data[FormData.fields.username.str_id][0]}
                />
                <TextField
                    {...FormData.fields.password}
                    str_chars={data[FormData.fields.password.str_id][0]}
                />
            </InFieldsGroup>
        </FormBaseWelcome>
    )
}

export default InputFormLogin
