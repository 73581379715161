/**
 * Contains functionality to handle different data.
 */

export const hostNameFromUrl = (url) => {
    /**
     * Extracts the host name from a URL.
     */
    try {
        const totalUrl = url.split('://');
        const hostPath = totalUrl[1];
        const host = hostPath.split('/')[0];
        return host;
    } catch {
        return url;
    }
}

export const getNumBytesFromValue = (nbr_num, str_unit) => {
    /**
     * Returns the number in bytes given the input unit.
     * :Input
     *  str_unit: b, kb, mb, gb
     * :Returns
     *  Number in bytes given str_unit. 
     *  null on failure.
     */
    if (typeof(str_unit) !== 'string' || typeof(nbr_num) !== 'number') return null;    
    
    const unit = str_unit.toLowerCase();
    const units = ['kb', 'mb', 'gb'];
    
    if (!units.includes(unit)) return null;

    if (unit === 'kb') {
        return nbr_num * 1024;
    } else if (unit === 'mb') {
        return nbr_num * 1048576;
    } else if (unit === 'gb') {
        return nbr_num * 1073741824;
    } else {
        return nbr_num; /* Bytes. */
    }
}

export const isActiveURLitem = (str_url, str_s) => {
    /**
     * Checks if str_s is in str_url. 
     * Used to detect active navbar and sidebar items. 
     * :Returns
     *  True: If is in. 
     *  False: Otherwise. 
     */
    if (typeof(str_s) !== 'string') return false
    const str_ss = str_s.trim().replaceAll(' ', '-').toLowerCase()
    return str_url.indexOf(str_ss) !== -1
}

export const array2string = (arr_data) => {
    /**
     * Joins array eleemnts with ', '.
     * :Returns
     *  Joined array if everything is ok.
     *  '' otherwise.
     */
    if (!arr_data || arr_data.constructor !== Array) return '';
    return arr_data.join(', ');
}

export const num2StringWithNumDecimals = (nbr_num, nbr_digits=2) => {
    /**
     * Converts a number to a string representing that number 
     * with a specific number of digits.
     * :Input
     *  nbr_num: Number to be converted to a string. 
     *  nbr_digits: #digits fo the string.
     * :Returns 
     *  String representing a number. 
     *  Null: If an error occurs. 
     */
    if (typeof(nbr_num) !== 'number' ||
        typeof(nbr_digits) !== 'number' ||
        nbr_digits < 0
    ) return nbr_num;

    return nbr_num.toFixed(nbr_digits);
}

export const fileSizeToString = (nbr) => {
    if (nbr < 1024) {
      return nbr + ' B';
    } else if (nbr >= 1024 && nbr < 1048576) {
      return (nbr/1024).toFixed(1) + ' KB';
    } else {
      return (nbr/1048576).toFixed(1) + ' MB';
    }
}

export const nbrToStringWithThousanderSeparation = (nbr_value) => {
    /**
     * Converts the input argument into a string with thousender separators.
     */
    let str_return = ''
    const str_value = nbr_value.toString()
    /* Separate comma values. */
    const arr_valueParts = str_value.split('.')
    let str_fullPart = arr_valueParts[0]
    const str_commaPart = arr_valueParts[1]
    const int_len = str_fullPart.length
    const int_nbrCommas = parseInt((int_len-1)/3)
    /* First group. */
    const int_startEnd = int_len-int_nbrCommas*3
    str_return += str_fullPart.slice(0, int_startEnd)
    /* Other groups. */
    str_return += ','
    for (let i = 0; i < int_nbrCommas; i++) {
        const int_k = int_startEnd+(i*3)
        str_return += str_fullPart.slice(int_k, int_k+3)
        str_return += ','
    }
    /* Merge string parts. */
    if (str_commaPart) {
        str_return = str_return.slice(0, str_return.length-1) + '.' + str_commaPart
    } else {
        str_return = str_return.slice(0, str_return.length-1)
    }
    return str_return
}

export const extractFileNameFromPath = (str_filePath) => {
    /**
     * Extracts the file name of a path string and returns it. 
     */
    if (typeof(str_filePath) !== 'string') return '';

    /* Check if forward or backward slashes are used as file path separators. */
    let str_separator;
    if (str_filePath.indexOf('/') > -1) {
        str_separator = '/';
    } else if (str_filePath.indexOf('\\')) {
        str_separator = '\\';
    } else {
        /* No separator exists, file name only received. */
        return str_filePath;
    }

    const karr_path = str_filePath.split(str_separator);
    const str_fileName = karr_path[karr_path.length - 1];
    return str_fileName;
}

export const extractFileExtension = (str_filePath) => {
    /** 
     * Extracts the file extension of a file path/filename.
     * Returns file extension in lower case format. 
     */
    if (typeof(str_filePath) !== 'string') return null;
    const arr_split = str_filePath.split('.');
    const int_arrLen = arr_split.length;
    /* Check if file has an extension. */
    if (int_arrLen < 2) return '';
    return arr_split[int_arrLen-1].toLowerCase();
}

export const extractFileExtensionsFromFiles = (arr_filePaths) => {
    /**
     * Gets the file extension of all the strings items in arr. 
     * :Input
     *  arr_filePaths (array of strings): Stores the file names/paths. 
     * :Returns
     *  Array of strings: Stores the file extensions. If a string 
     *  in the input array is not valid, its value is null or ''. 
     */
    const arr_extensions = arr_filePaths.map((item) => {
        return extractFileExtension(item);
    })
    return arr_extensions;
}

export const gridColsFromNumItems = (int_numGridItems, int_maxCols=4) => {
    /**
     * Computes the grid columns according to the #grid-items.
     * :Input
     *  int_numGridItems: Numbers of items the grid features.
     *  int_maxCols: Number of cols allowed at max. 4 is max. #cols allowed.
     * :Retuns
     *  Correct CSS grid cols class (e.g. three-cols, four-cols, etc.). 
     */
    if (
        typeof(int_numGridItems) !== 'number' ||
        typeof(int_maxCols) !== 'number' ||
        !int_numGridItems
    ) return ''

    const mapping = {
        1: 'one',
        2: 'two',
        3: 'three',
        4: 'four'
    };
    const str_ending = '-cols';

    /* If more than 1 row is possible, #cols = #max-cols. */
    if (int_numGridItems / int_maxCols > 1)
        return mapping[int_maxCols] + str_ending;

    const int_max = Math.max(1, int_maxCols > 4 ? 4 : int_maxCols);
    for (let i = int_max; i > 0; i--)
        if (!(int_numGridItems % i))
            return mapping[i] + str_ending;
}

export const fillBlankTableCell = (tbCellData) => {
    /**
     * Fills an empty table cell with a dash.
     * :Returns
     *  '—' if tbCellData is 'null', 'undefined' or ''
     *  tbCellData otherwise
     */
    if (!tbCellData && typeof(tbCellData) !== 'number') return '—';
    return tbCellData;
}

const genClipboardMsg = (str_text, b_isSuccess) => {
    if (b_isSuccess)
        return `${str_text}\nwurde in die Zwischenablage kopiert.`;
    else
        return `Der Text '${str_text}' konnte nicht in die Zwischenablage kopiert werden.\n` +
            'Dein Browser unterstützt die Befehle nicht.';
}

const fallbackCopyTextToClipboard = (str_text) => {
    /**
     * Copies text data to the clipboard via the deprecated function
     * 'document.execCommand('copy');'.
     * This function is only called if the standard is not supported
     * by the browser: navigator.clipboard.writeText(<someText>). 
     */
    /* Create textarea to copy clipboard str_text from. */
    let textArea = document.createElement('textarea');
    textArea.value = str_text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    /* Copy to clipboard, show user success/error. */
    try {
        const b_isSuccess = document.execCommand('copy');
        document.body.removeChild(textArea);
        alert(genClipboardMsg(str_text, b_isSuccess));
    } catch (err) {
        document.body.removeChild(textArea);
        alert(genClipboardMsg(str_text, false));
    }
}

export const copyData2Clipboard = (str_text) => {
    /**
     * Copies data from the DOM to the clipboard.
     * Alerts the user of success/error.
     */
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(str_text);
        return;
    }
    navigator.clipboard.writeText(str_text).then(function() {
        alert(genClipboardMsg(str_text, true));
    }, function() {
        alert(genClipboardMsg(str_text, false));
    });
}

export const trimStudmail = (str_email) => {
    /**
     * Checks if str_email is an MUL studmail address, if it is, it trims it:
     * ...@studmail.unileoben.ac.at -> ...@stud...
     * :Input
     *  str_email: Email address.
     * :Return
     *  Trimmed studmail email address. If email is not studmail, return str_email.
     */
    const identifier = '@stud.unileo';
    try {
        if (str_email.indexOf(identifier) < 0) {
            return str_email;
        } else {
            const emailName = str_email.split('@')[0];
            const emailTrimmed = emailName + '@stud...';
            return emailTrimmed;
        }
    } catch {
        return str_email;
    }
}

export const formatPhoneNumber = (str_phoneNum) => {
    /**
     * Converts a phone number string without ' ' into one with ' '.
     */
    const regex = /^\+[0-9]{4,15}$/;
    if (!regex.test(str_phoneNum)) return str_phoneNum;

    const str_dialCode = str_phoneNum.slice(0, 3);
    let str_remainder = str_phoneNum.slice(3,);

    const int_lenDigits = Math.floor(str_remainder.length/2);
    let str_spacedDigits = '';
    for (let i = 0; i < int_lenDigits; i++) {
        str_spacedDigits += ' ';
        str_spacedDigits += str_remainder.slice(0, 2);
        str_remainder = str_remainder.slice(2,);
    }
    return str_dialCode + str_spacedDigits + str_remainder;
}

export const bool2yesNo = (b_value, b_capitalize=false) => {
    /**
     * Convert a bool value into 'ja' 'nein' strings.
     * true = 'ja', false = 'nein'
     * :Input
     *  b_value: Bool value to convert.
     *  b_capitalize: Capitalize response string if true.
     * :Returns
     *  String 'ja' or 'nein'.
     */
    if (typeof(b_value) !== 'boolean') return null;
    let s = b_value ? 'ja' : 'nein';
    if (b_capitalize) {
        const firstChar = s.charAt(0).toUpperCase();
        return firstChar + s.slice(1);
    }
    return s;
}

export const symDifArray = (...arrays) => {
    /* Finds the symmetric difference between various input arrays
     * and returns it. */
    return [].concat(arrays
        .map((array, i) => array
        .filter(elt => !arrays
        .some((a, j) => i !== j && a.indexOf(elt) >= 0))))[0];
}

export const string2URLstring = (str_s) => {
    /* Takes string as input and reformats it to the standard URL string format. */
    if (str_s === undefined) return '';
    return str_s.replaceAll(' ', '-')
                .replaceAll('.', '-')
                .replaceAll('_', '-')
                .replaceAll('/', '-')
                .toLowerCase();
};

export const array2CommaSepString = (arr_strings) => {
    /* Joins the entries of an array of strings with ', '. */
    return arr_strings.join(', ');
};

export const objectValues2array = (object) => {
    /**
     * Creates an array of the values of the input object.
     */
    if (typeof(object) !== 'object') return null;
    return Object.values(object).map(item => {return item;});
}

export const getCurrentDateInGermanFormat = () => {
    /* Creates the current date in the german format and returns it (str). */
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); /* January is 0! */
    const yyyy = today.getFullYear();
    return dd + '.' + mm + '.' + yyyy;
}

export const getHostFromUrl = (url) => {
    /**
     * Extracts the host name from the URL and returns it (str).
     */
    if (
        !url ||
        typeof(url) !== 'string' ||
        url.indexOf('//') === -1
    ) return '';

    const hostAndPath = url.split('//')[1];
    const host = hostAndPath.split('/')[0];
    return host;
}