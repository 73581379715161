import React from 'react';
import { useParams } from 'react-router-dom';

import DocInfoMessage from '../../../components/messages/docs/DocInfoMessage';
import Discussion from '../../../components/discussion-pages/Discussion';
import DynamicContentLoadContextProvider from '../../../components/context-provider/DynamicContentLoadContextProvider';
import DiscussionContextProvider from '../../../components/discussion-pages/context-provider/DiscussionContextProvider';

import DynLoadPackageSizes from '../../../util/config/data_load_package_sizes.json';
import DiscussionConfigs from '../../../components/discussion-pages/DiscussionConfigs';

import { getFormData } from '../../../components/input-forms/util/form-config-data/InputFormPanelDataConfigs';
import { genDynUrl } from '../../../util/db_ls_query_handler';

const DOC_MSG_BASE_URL = '/api/coursedocs/detail/';
const DOC_MSG_VOTE_BASE_URL = '/api/coursedocs/vote/';
const MSGS_BASE_URL = '/api/coursedocs/message/list/';
const MSG_VOTE_BASE_URL = '/api/coursedocs/message/vote/';
const MSG_CREATE_POST_URL = '/api/coursedocs/message/create/';

const DocsDetailPage = () => {
    const params = useParams()
    const docMsgUrl = genDynUrl(DOC_MSG_BASE_URL, params.docId)
    const docMsgsListUrl = genDynUrl(MSGS_BASE_URL, params.docId)
    const newDocMsgPostUrl = genDynUrl(MSG_CREATE_POST_URL, params.docId)
    const postPanelFormConfig = getFormData(
        DiscussionConfigs.CourseDocsDiscussion.formConfig,
        DiscussionConfigs.CourseDocsDiscussion.fileFieldConfig
    )
    const dynConfig = { str_queryURL: newDocMsgPostUrl }
    return (
        <DynamicContentLoadContextProvider
            str_queryURL={docMsgsListUrl}
            b_hasInfiniteScroll={true}
            nbr_packageSize={DynLoadPackageSizes.coursedocs.docMsgs}
        >
            <DiscussionContextProvider>
                <Discussion
                    obj_postPanelFormConfig={postPanelFormConfig}
                    obj_postPanelDynConfig={dynConfig}
                    str_discussMsgUrl={docMsgUrl}
                    str_discussMsgVoteBaseUrl={DOC_MSG_VOTE_BASE_URL}
                    comp_discussMsg={<DocInfoMessage />}
                    str_msgVoteBaseUrl={MSG_VOTE_BASE_URL}
                    b_isMsgBodyHideable={false}
                    b_isMsgCommentable={false}
                    b_isMsgEditable={false}
                />
            </DiscussionContextProvider>
        </DynamicContentLoadContextProvider>
    )
}

export default DocsDetailPage
