import React from 'react';

import InFieldsGroup from '../../../InFieldsGroup';
import FormBase from '../../../FormBase';
import TextField from '../../../fields/TextField';
import { SloganFormData as FormData } from '../../../util/form-config-data/InputFormAccountSettingsDataConfigs';
import { useInputData } from '../../../context-provider/InputFormContextProvider';

const InputFormSlogan = () => {
    const data = useInputData()
    return (
        <FormBase str_title='Slogan/Kurzbeschreibung'>
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.slogan}
                    str_chars={data[FormData.fields.slogan.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormSlogan
