import React from 'react';
import PropTypes from 'prop-types';

import { extractFileNameFromPath } from '../../util/data_handler';

const MessageStdBodyImages = ({
    arr_files /* Items are the file urls (str). */
}) => {
    return (
        arr_files && arr_files[0] &&
        <div className="msg-images">
            {
                arr_files.map((fileUrl, index) => (
                    <div key={index} className="msg-image">
                        <a
                            href={fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={fileUrl}
                                alt={extractFileNameFromPath(fileUrl)}
                                className="modal-item modal-item-img"
                            />
                        </a>
                    </div>
                ))
            }
        </div>
    )
}

MessageStdBodyImages.propTypes = {
    arr_files: PropTypes.array
}

export default MessageStdBodyImages
