/**
 * This file entails code that interacts with the DOM.
 */
export const removeThemeIndicator = () => {
    /**
     * Removes the dark-theme indicator from the DOM.
     * This is relevant for pages that should always be 
     * displayed in the same way, no matter if the user
     * is in light or dakr mode.
     */
    const el = document.querySelector('main');
    el.classList.remove('dark-theme');
}

export const triggerFieldErrors = (fieldIds, fieldId2focusLast) => {
    /**
     * This function can be used to focus/blur input fields to cause their
     * respective error functions to run and display error messages if necessary.
     * :Input
     *  fieldIds (arr): Items are the ids (str) of input fields.
     *  fieldId2focusLast (str): Id of an input field that is focused last.
     *      This allows to cause an onBlur event on the last input field that has a value.
     */
    fieldIds.forEach(fieldId => {
        /* If an ID cannot be found or a form field is not present given the input
         * value of an other form field. */
        try { document.querySelector(`#${fieldId}`).focus() }
        catch {}
    })
    document.querySelector(`#${fieldId2focusLast}`).focus()
}
