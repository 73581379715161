import React from 'react';

import InFieldsGroup from '../../../InFieldsGroup';
import FormBase from '../../../FormBase';
import TextField from '../../../fields/TextField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { IndustryDescriptionsFormData as FormData } from '../../../util/form-config-data/InputFormIndustryDataConfigs';

const SUBTITLE = 'Hier kannst du dein Unternehmen mit den wichtigsten Informationen ' +
    'beschreiben (z.B. Warum wurde das Unternehmen gegründet? Welche Ideen und Ziele ' +
    'hat das Unternehmen für die Zukunft? Wie sind die Teams zusammengesetzt?). '+
    'Kurzum: Hier findet man die Informationen, die man unbedingt über das ' +
    'Unternehmen wissen muss.';

const InputFormIndustryDescriptions = () => {
    const data = useInputData()
    return (
        <FormBase
            str_title='Firmenbeschreibung'
            str_subtitle={SUBTITLE}
        >
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.description0}
                    str_chars={data[FormData.fields.description0.str_id][0]}
                />
                <TextField
                    {...FormData.fields.description1}
                    str_chars={data[FormData.fields.description1.str_id][0]}
                />
                <TextField
                    {...FormData.fields.description2}
                    str_chars={data[FormData.fields.description2.str_id][0]}
                />
                <TextField
                    {...FormData.fields.description3}
                    str_chars={data[FormData.fields.description3.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormIndustryDescriptions
