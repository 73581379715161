import React from 'react';

import InFieldsGroup from '../../../InFieldsGroup';
import FormBase from '../../../FormBase';
import TextField from '../../../fields/TextField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { IndustryEqualOpportunityEmployerData as FormData } from 
    '../../../util/form-config-data/InputFormIndustryDataConfigs';

const InputFormIndustryEqualOps = () => {
    const data = useInputData()
    return (
        <FormBase str_title='Arbeitgeber mit Chancengleichheit'>
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.equalOpsMsg}
                    str_chars={data[FormData.fields.equalOpsMsg.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormIndustryEqualOps
