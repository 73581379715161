/**
 * Entails all the config form for input forms that are 
 * used by multiple user types.
 */

import FieldLimits from '../db_field_limits.json';
import PlaceholderTexts from '../input_form_placeholder_texts.json';
import BottomTexts from '../input_form_bottom_info_texts.json';
import { genFieldData } from "./InputFormBaseDataAndSerializer";
import { TemplateFields } from './InputFormTemplateConfigs';

/* Forms. */

export const PrivacyTermsFormData = {
    str_localStorageKey: 'PrivacyTermsData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        privacy: [false, true],
        terms  : [false, true]
    },
    fields: {
        privacy: genFieldData('b', {
            str_id: 'privacy',
            str_fieldTitle: 'Datenschutzrichtlinie',
            str_boolInfoText: 'Ich bin mit der Datenschutzrichtlinie einverstanden.',
            str_bottomInfoText: BottomTexts.legal.privacy,
            b_isRequired: true
        }),
        terms: genFieldData('b', {
            str_id: 'terms',
            str_fieldTitle: 'Nutzungsbedingungen',
            str_boolInfoText: 'Ich bin mit den Nutzungsbedingungen einverstanden.',
            str_bottomInfoText: BottomTexts.legal.terms,
            b_isRequired: true
        })
    }
};

export const LogoFormData = {
    str_localStorageKey: 'LogoFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        logo: [null, false]
    },
    fields: {
        logo: genFieldData('file', {
            str_id: 'logo',
            str_fieldTitle: 'Logo',
            arr_allowedFileTypes: FieldLimits.user.logo.file.extensions,
            nbr_maxSize: FieldLimits.user.logo.file.size,
            b_isRequired: false
        })
    }
};

export const SloganFormData = {
    str_localStorageKey: 'SloganFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        slogan: ['', true]
    },
    fields: {
        slogan: genFieldData('text', {
            str_id: 'slogan',
            str_fieldTitle: 'Slogan/Kurzbeschreibung',
            str_placeholder: PlaceholderTexts.slogan,
            int_maxLength: FieldLimits.misc.slogan,
            str_bottomInfoText: BottomTexts.accountSettings.slogan
        })
    }
};

export const EntityContactFormData = {
    str_localStorageKey: 'EntityContactFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        country   : ['', true ],
        county    : ['', false],
        district  : ['', false],
        streetName: ['', true ],
        streetNum : ['', true ],
        postalCode: ['', true ],
        cityName  : ['', true ],
        phone     : ['', true ],
        email     : ['', true ],
        webpage   : ['', false],
        linkedin  : ['', false],
        twitter   : ['', false],
        facebook  : ['', false],
        instagram : ['', false],
        tiktok    : ['', false]
    },
    fields: {
        country: TemplateFields.address.country,
        county: genFieldData('sc', { str_id: 'county' }),
        district: genFieldData('sc', { str_id: 'district' }),
        streetName: genFieldData('text', {
            str_id: 'streetName',
            str_fieldTitle: 'Straße',
            b_isTextarea: false,
            int_maxLength: FieldLimits.address.streetName
        }),
        streetNum: genFieldData('text', {
            str_id: 'streetNum',
            str_fieldTitle: 'Hausnummer',
            b_isTextarea: false,
            int_maxLength: FieldLimits.address.streetNum
        }),
        postalCode: genFieldData('text', {
            str_id: 'postalCode',
            str_fieldTitle: 'Postleitzahl',
            b_isTextarea: false,
            int_maxLength: FieldLimits.address.postalCode
        }),
        cityName: genFieldData('text', {
            str_id: 'cityName',
            str_fieldTitle: 'Stadt',
            b_isTextarea: false,
            int_maxLength: FieldLimits.address.cityName
        }),
        phone: genFieldData('phone', {
            str_id: 'phone',
            str_fieldTitle: 'Telefon',
            b_isRequired: true,
            int_maxLength: FieldLimits.contact.phone
        }),
        email: genFieldData('email', {
            str_id: 'email',
            str_fieldTitle: 'Email',
            b_isRequired: true,
        }),
        webpage: genFieldData('text', {
            str_id: 'webpage',
            str_fieldTitle: 'Webseite',
            b_isTextarea: false,
            b_isLink: true,
            b_isRequired: false,
            int_maxLength: FieldLimits.contact.url
        }),
        linkedin: genFieldData('text', {
            str_id: 'linkedin',
            str_fieldTitle: 'LinkedIn',
            b_isTextarea: false,
            b_isLink: true,
            b_isRequired: false,
            int_maxLength: FieldLimits.contact.url
        }),
        twitter: genFieldData('text', {
            str_id: 'twitter',
            str_fieldTitle: 'twitter',
            b_isTextarea: false,
            b_isLink: true,
            b_isRequired: false,
            int_maxLength: FieldLimits.contact.url
        }),
        facebook: genFieldData('text', {
            str_id: 'facebook',
            str_fieldTitle: 'facebook',
            b_isTextarea: false,
            b_isLink: true,
            b_isRequired: false,
            int_maxLength: FieldLimits.contact.url
        }),
        instagram: genFieldData('text', {
            str_id: 'instagram',
            str_fieldTitle: 'instagram',
            b_isTextarea: false,
            b_isLink: true,
            b_isRequired: false,
            int_maxLength: FieldLimits.contact.url
        }),
        tiktok: genFieldData('text', {
            str_id: 'tiktok',
            str_fieldTitle: 'TikTok',
            b_isTextarea: false,
            b_isLink: true,
            b_isRequired: false,
            int_maxLength: FieldLimits.contact.url
        })
    }
};