/**
 * This file entails all the configs for the contact forms.
 */

import FieldLimits from '../db_field_limits.json';
import BottomTexts from '../input_form_bottom_info_texts.json';
import  { genFieldData } from './InputFormBaseDataAndSerializer';
import { dateFromTodayAsBrowserString } from '../../../../util/date_time_handler';
import { TemplateFields } from './InputFormTemplateConfigs';

const CONTACT_CATEGORY_CHOICES = [
    'Abo',
    'Nutzungsbedingungen',
    'Datenschutz',
    'Dokumentation',
    'Mining',
    'Andere'
];
const MAX_OCCURRENCE_CHOICES = [1, 10, 25, 50];
const SEVERITY_LEVEL_CHOICES = [1, 2, 3, 4, 5, 6];

/* Fields that are used multiple times are accessed via this object. */

const FieldTemplates = {
    title: genFieldData('text', {
        str_id: 'title',
        str_fieldTitle: 'Titel',
        b_isTextarea: false,
        int_maxLength: FieldLimits.contactForm.title
    }),
    description: genFieldData('text', {
        str_id: 'description',
        str_fieldTitle: 'Beschreibung',
        int_maxLength: FieldLimits.contactForm.description
    }),
    url: genFieldData('text', {
        str_id: 'url',
        str_fieldTitle: 'Webseite bzw. URL',
        str_bottomInfoText: BottomTexts.muplazaContact.url,
        b_isTextarea: false,
        b_isRequired: false,
        b_isLink: true,
        int_maxLength: FieldLimits.contact.url
    }),
    numMaxOccurrences: genFieldData('sc', {
        str_id: 'numMaxOccurrences',
        str_fieldTitle: 'Häufigkeit des Vorfalls',
        arr_options: MAX_OCCURRENCE_CHOICES,
        str_bottomInfoText: BottomTexts.number.occurrences
    }),
    file: genFieldData('file', {
        str_id: 'file',
        str_fieldTitle: 'Datei',
        str_bottomInfoText: BottomTexts.muplazaContact.file,
        b_isRequired: false
    })
};

/* Configs. */

export const GeneralContactFormData = {
    str_localStorageKey: '',
    str_queryURL: '/contact/general',
    str_navigationURLonDiscard: '',
    obj_initState: {
        severityLevel: ['', true],
        category     : ['', true],
        title        : ['', true], 
        description  : ['', true],
        phone        : ['', false],
        email        : ['', false]
    },
    fields: {
        category: genFieldData('sc', {
            str_id: 'category',
            str_fieldTitle: 'Kategorie',
            arr_options: CONTACT_CATEGORY_CHOICES
        }),
        title: FieldTemplates['title'],
        description: FieldTemplates['description'],
        severityLevel: genFieldData('sc', {
            str_id: 'severityLevel',
            str_fieldTitle: 'Dringlichkeit',
            str_bottomInfoText: BottomTexts.muplazaContact.generalContactSeverityLevel,
            arr_options: SEVERITY_LEVEL_CHOICES
        }),
        email: TemplateFields['contact']['email'],
        phone: TemplateFields['contact']['phone']
    }
};

export const ImprovementsAndSuggestionsFormData = {
    str_localStorageKey: '',
    str_queryURL: '/contact/feedback/improvements-and-suggestions',
    str_navigationURLonDiscard: '',
    obj_initState: {
        title:       [''  , true ],
        description: [''  , true ],
        sugFile:     [null, false],
        url:         [''  , false]
    },
    fields: {
        title: FieldTemplates['title'],
        description: FieldTemplates['description'],
        url: FieldTemplates['url'],
        sugFile: genFieldData('file', {
            str_id: 'sugFile',
            str_fieldTitle: 'Datei',
            str_bottomInfoText: BottomTexts.muplazaContact.file,
            b_isRequired: false
        })
    }
};

export const BugReportingFormData = {
    str_localStorageKey: '',
    str_queryURL: '/contact/feedback/bug-reporting',
    str_navigationURLonDiscard: '',
    obj_initState: {
        title:               [''   , true ],
        description:         [''   , true ],
        numMaxOccurrences:   [''   , true ],
        isKnownByOtherUsers: [false, false],
        firstNoticedDate:    [''   , true ],
        severityLevel:       [''   , true ],
        url:                 [''   , false],
        suggestedSolution:   [''   , false],
        bugFile:             [null , false]
    },
    fields: {
        title: FieldTemplates['title'],
        description: FieldTemplates['description'],
        numMaxOccurrences: FieldTemplates['numMaxOccurrences'],
        isKnownByOtherUsers: genFieldData('b', {
            str_id: 'isKnownByOtherUsers',
            str_fieldTitle: 'Verbreitung',
            str_boolInfoText: 'Hatten andere User dasselbe Problem?'
        }),
        firstNoticedDate: genFieldData('date', {
            str_id: 'firstNoticedDate',
            str_fieldTitle: 'Erstes Auftreten',
            str_minDate: dateFromTodayAsBrowserString(-30),
            str_maxDate: dateFromTodayAsBrowserString()
        }),
        severityLevel: genFieldData('sc', {
            str_id: 'severityLevel',
            str_fieldTitle: 'Grad des Fehlers',
            str_bottomInfoText: BottomTexts.muplazaContact.bugSeverityLevel,
            arr_options: SEVERITY_LEVEL_CHOICES
        }),
        url: FieldTemplates['url'],
        suggestedSolution: genFieldData('text', {
            str_id: 'suggestedSolution',
            str_fieldTitle: 'Lösungsvorschlag',
            str_bottomInfoText: BottomTexts.muplazaContact.solutionIdea,
            int_maxLength: FieldLimits.contactForm.description,
            b_isRequired: false
        }),
        bugFile: genFieldData('file', {
            str_id: 'bugFile',
            str_fieldTitle: 'Datei',
            str_bottomInfoText: BottomTexts.muplazaContact.file,
            b_isRequired: false
        })
    }
};

export const UserReportingFormData = {
    str_localStorageKey: '',
    str_queryURL: '/contact/user-reporting',
    str_navigationURLonDiscard: '',
    obj_initState: {
        username:          [''  , true ],
        title:             [''  , true ],
        description:       [''  , true ],
        numMaxOccurrences: [''  , true ],
        severityLevel:     [''  , true ],
        url:               [''  , false],
        violationFile:     [null, false],
    },
    fields: {
        username: genFieldData('text', {
            str_id: 'username',
            str_fieldTitle: 'Username',
            str_bottomInfoText: BottomTexts.muplazaContact.username,
            b_isTextarea: false,
            int_maxLength: FieldLimits.user.username
        }),
        title: FieldTemplates['title'],
        description: FieldTemplates['description'],
        numMaxOccurrences: FieldTemplates['numMaxOccurrences'],
        severityLevel: genFieldData('sc', {
            str_id: 'severityLevel',
            str_fieldTitle: 'Grad des Verstoßes',
            str_bottomInfoText: BottomTexts.muplazaContact.userViolationSeverityLevel,
            arr_options: SEVERITY_LEVEL_CHOICES
        }),
        url: FieldTemplates['url'],
        violationFile: genFieldData('file', {
            str_id: 'violationFile',
            str_fieldTitle: 'Datei',
            str_bottomInfoText: BottomTexts.muplazaContact.file,
            b_isRequired: false
        })
    }
};