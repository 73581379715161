import React from 'react';
import PropTypes from 'prop-types';

import MessageStdHeader from '../MessageStdHeader';
import QuestionMessageBody from './QuestionMessageBody';
import MessageQuestionFooter from '../MessageQuestionFooter';
import { useIsMsgBodyOpenContext, useMsgContext } from '../context-provider/MessageContextProvider';
import MessageStdFooter from '../MessageStdFooter';

const QuestionMessage = ({
    /* If std footer should be used instead of the question footer. */
    b_hasStdFooter=false
}) => {
    const msg = useMsgContext()
    const isBodyOpen = useIsMsgBodyOpenContext()
    return (
        msg &&
        <div className="msg">
            <MessageStdHeader />
            {
                isBodyOpen &&
                <QuestionMessageBody />
            }
            {
                b_hasStdFooter
                ?
                <MessageStdFooter />
                :
                <MessageQuestionFooter />
            }
        </div>
    )
}

QuestionMessage.propTypes = {
    b_hasStdFooter: PropTypes.bool
}

export default QuestionMessage
