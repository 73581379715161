import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CSecBase from '../../components/content-sections/CSecBase';
import FormSuccessErrorMessage from '../../components/input-forms/FormSuccessErrorMessage';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';
import { fetchLSdata } from '../../util/db_ls_query_handler';
import AuthService from '../../util/authentication/auth_service';
import { IsPaymentSuccessKey as PAYMENT_SUCCESS_LS_KEY } from '../../util/LocalStorageVariables';

const SUCCESS_MSG = 'Dein Abo wurde erfolgreich verlängert.';

const AboExtensionSuccessPage = () => {
    
    const navigate = useNavigate()
    const [isPaid, setIsPaid] = useState(false)

    useSetBrowserTabTitle('Aboverlängerung erfolgreich')
    
    useEffect(() => {
        /* Check if indicator for successful payment is set. */
        if (fetchLSdata(PAYMENT_SUCCESS_LS_KEY)) {
            AuthService.setIsAboValid(true)
            localStorage.removeItem(PAYMENT_SUCCESS_LS_KEY)
            setIsPaid(true)
        } else {
            navigate(AuthService.getProfileUrl())
        }
    }, [])
    
    return (
        <CSecBase>
        {
            isPaid &&
            <FormSuccessErrorMessage
                str_successMsg={SUCCESS_MSG}
                b_isSuccess={true}
                b_hasProfilePageLink={true}
            />
        }
        </CSecBase>
    )
}

export default AboExtensionSuccessPage
