import React, { useState, useEffect, useRef, useContext } from 'react';

import DataStateCheckWrapper from '../../../general/DataStateCheckWrapper';

import FoodOffersSerializer from '../../../../util/serializers/backend_serializers/food_offers_serializer';
import { fetchDBdata, fetchLSdata, setLSdata, deleteDBdata } from '../../../../util/db_ls_query_handler';
import { hasTimestampExpired } from '../../../../util/date_time_handler';
import { TimestampLsKey, PPLsKeys, GastronomerLsKeys } from '../../../../util/LocalStorageVariables';
import { Messages } from '../util/data';

const DgfsTodayContext = React.createContext();
const DgfsTomorrowContext = React.createContext();
const DgfsOldContext = React.createContext();
const DeleteOldContext = React.createContext();
const AddNewOfferContex = React.createContext();

export function useDgfsTodayContext() {
    return useContext(DgfsTodayContext);
}

export function useDgfsTomorrowContext() {
    return useContext(DgfsTomorrowContext);
}

export function useDgfsOldContext() {
    return useContext(DgfsOldContext);
}

export function useDeleteOldDgfsContext() {
    return useContext(DeleteOldContext);
}

export function useAddNewDgfsOfferContext() {
    return useContext(AddNewOfferContex);
}

/* LS keys. */
const PR_KEY = PPLsKeys.primaryDataKey;
const SEC_KEY = GastronomerLsKeys.pp.foodOffersDgfs.base;
const TODAY_KEY = GastronomerLsKeys.pp.foodOffersDgfs.today;
const TOMORROW_KEY = GastronomerLsKeys.pp.foodOffersDgfs.tomorrow;
const OLD_KEY = GastronomerLsKeys.pp.foodOffersDgfs.old;
const TIMESTAMP_KEY = TimestampLsKey;

const LIST_URL = '/api/gastronomy/gastronomer/food-offers/dgfs/';
const DETAIL_BASE_URL = '/api/gastronomy/gastronomer/food-offers/detail/';

const GastroPPDgfsContextProvider = ({ children }) => {
    
    const [offersToday, setOffersToday] = useState()
    const [offersTomorrow, setOffersTomorrow] = useState()
    const [offersOld, setOffersOld] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    const isButtonLockedRef = useRef(false)

    useEffect(() => {
        const makeDBrequest = async () => {
            const queryData = await fetchDBdata(LIST_URL)
            if (queryData.isQuerySuccess) {
                const data = queryData.response.data

                let serializedData = FoodOffersSerializer.dbList2foodOfferCardsArray(data.today)
                setOffersToday(serializedData)
                setLSdata(serializedData, PR_KEY, SEC_KEY, TODAY_KEY)

                serializedData = FoodOffersSerializer.dbList2foodOfferCardsArray(data.tomorrow)
                setOffersTomorrow(serializedData)
                setLSdata(serializedData, PR_KEY, SEC_KEY, TOMORROW_KEY)

                serializedData = FoodOffersSerializer.dbList2foodOfferCardsArray(data.old)
                setOffersOld(serializedData)
                setLSdata(serializedData, PR_KEY, SEC_KEY, OLD_KEY)

                setIsFirstFetchSuccess(true)

                /* Set timestamp of query time. */
                const timestamp = new Date().getTime()
                setLSdata(timestamp, PR_KEY, SEC_KEY, TIMESTAMP_KEY)
            } else {
                setErrorMsg(queryData.errorMsg)
                setIsFirstFetchSuccess(false)
            }
        }

        const makeLSrequest = () => {
            const lsData = fetchLSdata(PR_KEY, SEC_KEY)
            setOffersToday(lsData.today)
            setOffersTomorrow(lsData.tomorrow)
            setOffersOld(lsData.old)
            setIsFirstFetchSuccess(true)
        }

        /* Fetch data from LS or DB depending on timestamp expiration. */
        const timestamp = fetchLSdata(PR_KEY, SEC_KEY, TIMESTAMP_KEY);
        if (timestamp && !hasTimestampExpired(timestamp, 10, 'min')) {
            makeLSrequest()
        } else {
            makeDBrequest()
        }
    }, [])

    const addNewOffer = (response) => {
        /**
         * Add new offer to state and LS.
         * :Input
         *  response: Response received from the DB query function.
         */
        const offer = response.data
        if (offer.isPresent) {
            const offers = [FoodOffersSerializer.dbJson2foodOfferCard(offer), ...offersToday]
            setOffersToday(offers)
            setLSdata(offers, PR_KEY, SEC_KEY, TODAY_KEY)
        } else {
            const offers = [FoodOffersSerializer.dbJson2foodOfferCard(offer), ...offersTomorrow]
            setOffersTomorrow(offers)
            setLSdata(offers, PR_KEY, SEC_KEY, TOMORROW_KEY)
        }
    }

    const deleteOldDgfs = async (nbr_offerId, cardRef) => {
        /**
         * Makes a DB request to delete an old offer from the DB.
         * If DB query is success, states and LS are updated.
         */
        if (isButtonLockedRef.current) return
        isButtonLockedRef.current = true

        if (!window.confirm(Messages.deleteConfirmMsg)) {
            isButtonLockedRef.current = false
            return
        }

        const queryData = await deleteDBdata(DETAIL_BASE_URL + `${nbr_offerId}/`)
        if (!queryData.isQuerySuccess) {
            alert(Messages.dbQueryErrorMsg)
            isButtonLockedRef.current = false
            return
        }

        /* Run animation, then delete old offer from react state and LS. */
        runCardBlurAnimation(cardRef)
        setTimeout(() => {
            const newOld = offersOld.filter(offer => offer.id !== nbr_offerId)
            setOffersOld(newOld)
            setLSdata(newOld, PR_KEY, SEC_KEY, 'old')
            isButtonLockedRef.current = false
        }, 900)
    }

    const runCardBlurAnimation = (cardRef) => {
        cardRef.current.style.animation = 'blur 1s'
    }

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            firstQueryDataEntry={-1} /* Placeholder to avoid 'no data' rendering (details handled by children). */
            str_errorMsg={errorMsg}
            b_isContentSection={true}
        >
            <DgfsTodayContext.Provider value={offersToday}>
                <DgfsTomorrowContext.Provider value={offersTomorrow}>
                    <DgfsOldContext.Provider value={offersOld}>
                        <AddNewOfferContex.Provider value={addNewOffer}>
                            <DeleteOldContext.Provider value={deleteOldDgfs}>
                                {children}
                            </DeleteOldContext.Provider>
                        </AddNewOfferContex.Provider>
                    </DgfsOldContext.Provider>
                </DgfsTomorrowContext.Provider>
            </DgfsTodayContext.Provider>
        </DataStateCheckWrapper>
    )
}

export default GastroPPDgfsContextProvider
