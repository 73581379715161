import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { sendData2db } from '../../util/db_ls_query_handler'

const ERROR_MSG = 'Oops! Es ist ein Fehler aufgetreten. Dein Abo konnte nicht ' +
    'verlängert werden. Bitte versuche es erneut. Sollte derselbe Fehler wieder ' +
    'auftreten, dann versuche es später noch einmal.';

const CardAbo = ({
    nbr_extension,
    str_extensionUnit,
    nbr_price,
    str_url,
    b_isLink=true,      /* true if clicking on buy button links to another page. */
    fct_response2parent /* Send successful query response to parent component. */ 
}) => {

    const isPostBlockedRef = useRef(false)

    const post = async () => {
        if (isPostBlockedRef.current) return
        isPostBlockedRef.current = true
        
        const queryData = await sendData2db('post', str_url)
        if (queryData.isQuerySuccess) {
            fct_response2parent(queryData.response)
        } else {
            alert(ERROR_MSG)
            isPostBlockedRef.current = false
        }
    }

    return (
        <div className="card-wrapper">
            <div className='card abo-card'>
                <div className="card-content-wrapper">
                    <div className="card-content">
                        <div className="card-header">
                            <div className="card-title">
                                <span className="num-months">{nbr_extension}</span>
                                <span className="months-text">{str_extensionUnit}</span>
                            </div>
                            <div className="card-subtitle">Aboverlängerung für</div>
                        </div>
                        <div className="card-body">
                            <div className="price">
                                <p>
                                    <span className="value">{nbr_price}</span>
                                    <span className="currency-symbol">&#8364;</span>
                                </p>
                            </div>
                            <div className="tax-info">
                                <p>inkl. USt.</p>
                            </div>
                        </div>
                        <div className="button-wrapper">
                            {
                                b_isLink
                                ?
                                <Link to={str_url}>
                                    <button className="btn btn-sm btn-submit">kaufen</button>
                                </Link>
                                :
                                <button
                                    className="btn btn-sm btn-submit"
                                    onClick={() => post()}
                                >
                                    kaufen
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

CardAbo.propTypes = {
    nbr_extension: PropTypes.number.isRequired,
    str_extensionUnit: PropTypes.string.isRequired,
    nbr_price: PropTypes.number.isRequired,
    str_url: PropTypes.string.isRequired,
    b_isLink: PropTypes.bool,
    fct_response2parent: PropTypes.func
}

export default CardAbo
