import React from 'react';
import PropTypes from 'prop-types';
import ClipLoader from "react-spinners/ClipLoader";

const InFieldProposal = ({
    b_isLoading=false,
    str_errorMsg,
    b_itemsFound=false,
    b_hasItems, /* If undefined, the children are not displayed. */
    children
}) => {
    return (
        b_isLoading
        ?
        <div className="in-field-proposals">
            <p className="loading-spinner">
                <ClipLoader
                    loading={true}
                    color={"#1a1a1a"}
                    size="1.2rem"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </p>
        </div>
        :
        str_errorMsg
        ?
        <div className="in-field-proposals">
            <p className="error-msg">{str_errorMsg}</p>
        </div>
        :
        b_itemsFound
        ?
            b_hasItems
            ?
            <div className="in-field-proposals">
                <div className="proposal-wrapper">
                    <div className="proposal-container">
                        {children}
                    </div>
                </div>
            </div>
            :
            <div className="in-field-proposals">
                <p>Keine passenden Tags gefunden.</p>
            </div>
        :
        ''
    )
}

InFieldProposal.propTypes = {
    b_isLoading: PropTypes.bool,
    str_errorMsg: PropTypes.string,
    b_itemsFound: PropTypes.bool,
    b_hasItems: PropTypes.bool
}

export default InFieldProposal
