import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthService from '../../util/authentication/auth_service';
import { isActiveURLitem } from '../../util/data_handler';
import { SecNavbarData } from './data/SecNavbarData';

const urlParam2secnavTitle = (str_urlParam) => {
    return str_urlParam.replaceAll('-', ' ').toUpperCase();
}

const countActiveSecItems = (arr) => {
    /**
     * Counts all the items that are not 'empty'. The push to the
     * items array of entriesRef adds 'empty' if the element is not present
     * in the DOM (weird behaviour).
     */
    let count = 0;
    arr.forEach(item => { if (item) count++; })
    return count;
}

/* ID that identifies the active sec navbar item. */
const ACTIVE_ITEM = 'sec-nav-item-active';

const SecondaryNavbar = ({
    str_prDataKey,
    str_secDataKey
}) => {

    const [isVerticalDisplayOn, setIsVerticalDisplayOn] = useState(false)
    const [secDataKey, setSecDataKey] = useState(str_secDataKey)
    const [hasVerticalDisplay, setHasVerticalDisplay] = useState(false)
    const entriesRef = useRef([])
    const history = useNavigate()
    const location = useLocation()
    const params = useParams()

    const str_locationPath = location.pathname

    const isAboValid = AuthService.isAboValid()

    useEffect(() => {
        /* Find the active secondary key and compute how many secondary keys there are. */
        const str_pathname = location.pathname
        Object.keys(SecNavbarData[str_prDataKey]).forEach(key => {
            /* If there is no name to check against the URL, set vertical display according to config. */
            const name = SecNavbarData[str_prDataKey][key].name
            if (name === undefined || str_pathname.indexOf(name) > -1) {
                setSecDataKey(key)
                setHasVerticalDisplay(SecNavbarData[str_prDataKey][key].hasVerticalDisplay)
            }
        })
    }, [history])
    
    const handleRightItemOnClick = () => {
        /* Activate/deactivate vertical view only when it is displayed (below <= 768px). */
        if (window.innerWidth > 768 || !hasVerticalDisplay) return

        /* Activate/deactivate the vertical navbar display. */
        const isOn = !isVerticalDisplayOn
        setIsVerticalDisplayOn(isOn)
        
        /* Block page scrollbar when navbar is vertically displayed. */
        if (document.body.style.overflowY === '' || document.body.style.overflow === 'auto') {
            document.body.style.overflowY = 'hidden'
        } else {
            document.body.style.overflowY = ''
        }

        /* Run animation. */
        const lenSecItems = entriesRef.current.length
        const lenActiveSecItems = countActiveSecItems(entriesRef.current)
        if (isOn) {
            for (let i = 0; i < lenSecItems; i++) {
                try { entriesRef.current[i].style.animation = `navFade 0.5s ease forwards ${i/lenActiveSecItems + 0.3}s`}
                catch {}
            }
        } else {
            for (let i = 0; i < lenSecItems; i++) {
                try { entriesRef.current[i].style.animation = '' }
                catch {}
            }
        }
    }

    const handleLeftItemOnClick = () => {
        if (isVerticalDisplayOn) {
            setIsVerticalDisplayOn(false)
            /* If a left item is clicked, the page scroll must be unblocked. */
            if (document.body.style.overflowY === 'hidden') {
                document.body.style.overflowY = ''
            }
        }
    }

    return (
        <div className="sec-nav-wrapper">
            <nav id="sec-nav-id" className="sec-nav">
                {/* <!-- Left elements. --> */}
                <ul className="nav-links nav-links-left">
                    {
                        Object.values(SecNavbarData[str_prDataKey]).map((entry, index) => (
                            (entry.isFreeTier || isAboValid) &&
                            <li
                                key={index}
                                id={isActiveURLitem(str_locationPath, entry.name) ? ACTIVE_ITEM : ''}
                                className="nav-item nav-item-overview"
                                onClick={() => handleLeftItemOnClick()}
                            >
                                {
                                    entry.link
                                    ?
                                    <Link to={entry.link}>
                                    {
                                        entry.symbol
                                        ?
                                        entry.symbol
                                        :
                                            entry.title
                                            ?
                                            entry.title
                                            :
                                                entry.urlParam
                                                ?
                                                urlParam2secnavTitle(params[entry.urlParam])
                                                :
                                                ''
                                    }
                                    </Link>
                                    :
                                    entry.symbol
                                    ?
                                    entry.symbol
                                    :
                                        entry.title
                                        ?
                                        entry.title
                                        :
                                            entry.urlParam
                                            ?
                                            urlParam2secnavTitle(params[entry.urlParam])
                                            :
                                            ''
                                }
                            </li>
                        ))
                    }
                </ul>

                {/* Right elements. */}
                {
                    /* On change of the URL, the useEffect is no yet run. This means that the combination
                     * of primary and secondary key might not exist as they have not yet been updated.
                     * Once the useEffect was run everything is fine, but for the first render need to check. */
                    SecNavbarData[str_prDataKey][secDataKey] &&
                    SecNavbarData[str_prDataKey][secDataKey].items &&
                    <>
                        <ul className={`nav-links nav-links-right
                            ${(isVerticalDisplayOn) ? 'nav-active' : ''}
                            ${hasVerticalDisplay ? '' : 'vertical-display-blocked'}
                        `} >
                            {
                                SecNavbarData[str_prDataKey][secDataKey].items.map((entry, index) => (
                                    (entry.isFreeTier || isAboValid) &&
                                    <li
                                        key={index}
                                        ref={e => entriesRef.current[index] = e}
                                        id={isActiveURLitem(str_locationPath, entry.name) ? ACTIVE_ITEM : ''}
                                        className="nav-item"
                                        onClick={() => handleRightItemOnClick()}
                                    >
                                        <Link to={entry.link}>
                                            {
                                                entry.isButton
                                                ?
                                                <button className="btn btn-sec-nav">
                                                    {entry.symbol ? entry.symbol : entry.title}
                                                </button>
                                                :
                                                entry.symbol ? entry.symbol : entry.title
                                            }
                                        </Link>
                                    </li>
                                ))
                            }
                        </ul>

                        {/* Burger for small screens. */}
                        <div className={
                            `nav-collapse-burger
                            ${(isVerticalDisplayOn) ? 'burger-toggle' : ''}
                            ${hasVerticalDisplay ? '' : 'vertical-display-blocked'}
                            `}
                            onClick={() => handleRightItemOnClick()}
                        >
                            <div className="line-one"></div>
                            <div className="line-two"></div>
                            <div className="line-three"></div>
                        </div>
                    </>
                }
            </nav>
        </div>
    )
}

SecondaryNavbar.propTypes = {
    str_prDataKey: PropTypes.string.isRequired,
    str_secDataKey: PropTypes.string.isRequired
}

export default SecondaryNavbar
