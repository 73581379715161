import React from 'react';
import { Link } from 'react-router-dom';

import { useFooterDataContext } from './WelcomeContextProvider';

const WelcomeFooter = () => {
    const footerData = useFooterDataContext()
    return (
        <>
            {
                (footerData.firstEntry || footerData.secondEntry) &&
                <div className="footer-wf">
                    <div>
                        <Link to={footerData.firstEntry.link} className="link-wf">
                            {footerData.firstEntry.innerHTML}
                        </Link>
                    </div>
                    {
                        footerData.secondEntry &&
                        <div className="link">
                            <Link to={footerData.secondEntry.link} className="link-wf">
                                {footerData.secondEntry.innerHTML}
                            </Link>
                        </div>
                    }
                </div>
            }
            <div className="slogan">
                <p>Weil wir in Leoben gemeinsam studieren.</p>
            </div>
        </>
    )
}

export default WelcomeFooter
