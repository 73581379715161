import React, { useState } from 'react';

import PPDataGroup from '../PPDataGroup';
import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import GastroProfileData from './GastroProfileData';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import useGetPPdataGroupData from '../../../custom-hooks/useGetPPdataGroupData';

const CATEGORY = 'menus';
const URL = '/api/profiles/gastronomer/menus/';

const GastroPPMenus = () => {
    
    const [data, setData] = useState(null)
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState(undefined)
    const [errorMsg, setErrorMsg] = useState('')
    
    useSetBrowserTabTitle('Karten', true)
    useGetPPdataGroupData(URL, CATEGORY, setData, setIsFirstFetchSuccess, setErrorMsg)

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            firstQueryDataEntry={-1} /* Only placeholder value (data is always available here). */
            str_errorMsg={errorMsg}
            b_isContentSection={true}
        >
            <PPDataGroup
                str_header='Getränkekarten'
                str_editUrl='drinks/edit'
                obj_data={data ? data.drinks : null}
                obj_dataStructure={GastroProfileData.drinksMenus}
                b_isDataFilePath={true}
            />
            <PPDataGroup
                str_header='Speisekarten'
                str_editUrl='food/edit'
                obj_data={data ? data.food : null}
                obj_dataStructure={GastroProfileData.foodMenus}
                b_isDataFilePath={true}
            />
        </DataStateCheckWrapper>
    )
}

export default GastroPPMenus
