import React from 'react';
import PropTypes from 'prop-types';

const SbMcGrid = ({
    str_sbClasses='',
    str_mcClasses='',
    comp_sb,
    children
}) => {
    return (
        <div className="page-sb-mc-grid">
            <div className={`grid-item grid-sb ${str_sbClasses}`}>
                {comp_sb}
            </div>
            <div className="grid-item grid-mc">
                <div className="main-content">
                    <div className={`mc-wrapper ${str_mcClasses}`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

SbMcGrid.propTypes = {
    str_sbClasses: PropTypes.string,
    str_mcClasses: PropTypes.string
}

export default SbMcGrid
