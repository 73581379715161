import React from 'react';
import PropTypes from 'prop-types';

import GridBase from './GridBase';
import HandleNoData from '../general/HandleNoData';

import { gridColsFromNumItems } from '../../util/data_handler';
import { extractFileNameFromPath } from '../../util/data_handler';

const WordGrid = ({
    str_gridClass='',
    arr_data=[],
    b_areItemsUrls=false
}) => {
    const numGridCols = gridColsFromNumItems(arr_data.length)
    return (
        arr_data[0] === undefined
        ?
        <HandleNoData />
        :
        <GridBase str_gridClass={`word-grid ${numGridCols} ${str_gridClass}`}>
            {
                arr_data.map((item, index) => (
                    item &&
                    <div key={index} className="grid-item">
                        {
                            b_areItemsUrls
                            ?
                            <p key={index}>
                                <a href={item} target="blank" rel="noopener noreferrer">
                                    <span>{extractFileNameFromPath(item)}</span>
                                </a>
                            </p>
                            :
                            <p>
                                <span>{item}</span>
                            </p>
                        }
                    </div>
                ))
            }
        </GridBase>
    )
}

WordGrid.propTypes = {
    str_gridClass: PropTypes.string,
    arr_data: PropTypes.array,
    b_areItemsUrls: PropTypes.bool
}

export default WordGrid
