import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import PrivatePage from '../../components/network/PrivatePage';
import HeaderAndFooter from '../../components/page-layout/HeaderAndFooter';
import ProfileSetupContextProvider from '../../components/profile-setup/context-provider/ProfileSetupContextProvider';
import AuthService from '../../util/authentication/auth_service';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const ProfileSetupPage = () => {
    
    const navigate = useNavigate()
    const [hasPermission, setHasPermission] = useState(false)

    useSetBrowserTabTitle('Profilkonfiguration')

    useEffect(() => {
        const user = AuthService.getUser()
        if (!user) {
            navigate('/login')
        } else if (!user.isProfileSetup) {
            setHasPermission(true) /* Private page handles the other checks from here. */
        } else {
            navigate(AuthService.getProfileUrl())
        }
    }, [])
    
    return (
        hasPermission &&
        <PrivatePage>
            <HeaderAndFooter
                str_mainNavDataKey='profileSetup'
                b_hasMainNavLogoLink={false}
                b_hasFooter={false}
            >
                <ProfileSetupContextProvider>
                    <Outlet />
                </ProfileSetupContextProvider>
            </HeaderAndFooter>
        </PrivatePage>
    )
}

export default ProfileSetupPage
