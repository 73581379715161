import React from 'react';
import PropTypes from 'prop-types';

const CardBase = ({
    str_title,
    str_subtitle='',
    str_eyebrow='',
    str_imageURL='',
    str_cardClass='',
    b_hasBrightDesign=false,
    b_hasDarkDesign=false,
    b_hasCardShadow=false,
    b_hasBoldTitle=false,
    comp_cardBody,
    comp_cardFooter=null,
    cardRef /* Can be used to interact with the card. */
}) => {
    return (
        <div ref={cardRef} className="card-wrapper">
            <div className={`
                card
                ${str_cardClass}
                ${b_hasBrightDesign ? 'bright' : ''}
                ${b_hasDarkDesign ? 'dark' : ''}
                ${b_hasCardShadow ? 'card-shadow' : ''}
                ${b_hasBoldTitle ? 'card-title-bold' : ''}    
            `}>
                {
                    str_imageURL &&
                    <div className="card-img">
                        <img src="{% static {str_imageURL} %}" alt="cards" />
                    </div>
                }
                <div className="card-content-wrapper">
                    <div className="card-content">
                        <div className="card-header">
                            {
                                str_eyebrow &&
                                <div className="card-eyebrow">{str_eyebrow}</div>
                            }
                            {
                                str_title &&
                                <div className="card-title">{str_title}</div>
                            }
                            {
                                str_subtitle &&
                                <div className="card-subtitle">{str_subtitle}</div>
                            }
                        </div>

                        {   
                            comp_cardBody &&
                            <div className="card-body">
                                {comp_cardBody}
                            </div>
                        }

                        {
                            comp_cardFooter &&
                            <div className="card-footer">
                                {comp_cardFooter}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

CardBase.propTypes = {
    str_title: PropTypes.string,
    str_subtitle: PropTypes.string,
    str_eyebrow: PropTypes.string,
    str_imageURL: PropTypes.string,
    str_cardClass: PropTypes.string,
    b_hasBrightDesign: PropTypes.bool,
    b_hasDarkDesign: PropTypes.bool,
    b_hasCardShadow: PropTypes.bool,
    b_hasBoldTitle: PropTypes.bool
}

export default CardBase
