import React, { useState, useContext } from 'react';

const IsConfirmationViewOpenContext = React.createContext()
const SetIsConfirmationViewOpenContext = React.createContext()

export function useIsConfirmationViewOpenContext() {
    return useContext(IsConfirmationViewOpenContext);
}

export function useSetIsConfirmationViewOpenContext() {
    return useContext(SetIsConfirmationViewOpenContext);
}

const FormBaseContextProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <IsConfirmationViewOpenContext.Provider value={isOpen}>
            <SetIsConfirmationViewOpenContext.Provider value={setIsOpen}>
                {children}
            </SetIsConfirmationViewOpenContext.Provider>
        </IsConfirmationViewOpenContext.Provider>
    )
}

export default FormBaseContextProvider
