import React, { useState, useContext, useEffect } from 'react';

const IsModalOpenContext = React.createContext();
const SetModalOpenCloseContext = React.createContext();
const SetModalOpenCloseIdContext = React.createContext();
const ModalObjectId = React.createContext();

export function useIsModalOpenContext() {
    return useContext(IsModalOpenContext);
}

export function useSetModalOpenCloseContext() {
    return useContext(SetModalOpenCloseContext);
}

export function useSetModalOpencloseIdContext() {
    return useContext(SetModalOpenCloseIdContext);
}

export function useModalObjectId() {
    return useContext(ModalObjectId);
}

const ModalContextProvider = ({ children }) => {

    const [isOpen, setIsOpen] = useState(false)
    const [id, setId] = useState() /* ID of an object that is modal window content (e.g. coupon ID). */

    const setIsOpenId = (id) => {
        setId(id)
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        /* Hide scrollbar if modal is open. */
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen])

    return (
        <IsModalOpenContext.Provider value={isOpen}>
            <SetModalOpenCloseContext.Provider value={setIsOpen}>
                <SetModalOpenCloseIdContext.Provider value={setIsOpenId}>
                    <ModalObjectId.Provider value={id}>
                        {children}
                    </ModalObjectId.Provider>
                </SetModalOpenCloseIdContext.Provider>
            </SetModalOpenCloseContext.Provider>
        </IsModalOpenContext.Provider>
    )
}

export default ModalContextProvider
