import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';

import { FilterLsKeys } from '../../../util/LocalStorageVariables';
import {
    useQueriedDataContext,
    useIsFirstFetchSuccessContext
} from '../../context-provider/DynamicContentLoadContextProvider';
import { genDynUrl } from '../../../util/db_ls_query_handler';
import useGetLSorDBdata from '../../../custom-hooks/useGetLSorDBdata';

const getLsKey = (param) => {
    /**
     * Returns the filter LS key for this user's mining standings according
     * to the displayed mining standings (according to the url param category).
     */
    if (param === 'overall') return FilterLsKeys.overallMiningUser;
    return FilterLsKeys.thisSemesterMiningUser;
}

const BASE_URL = '/api/mining/mining-standings/';

const TbRankingBase = () => {

    const params = useParams()
    const [usersMiningStanding, setUsersMiningStanding] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [, setErrorMsg] = useState('')

    /* Data of the mining scoreboard. */
    const miningStandings = useQueriedDataContext()
    const isFirstFetchSuccessMiningRef = useIsFirstFetchSuccessContext()

    const isOverall = params.category === 'overall' ? true : false
    
    useGetLSorDBdata(
        genDynUrl(BASE_URL, params.category),
        setUsersMiningStanding,
        setIsFirstFetchSuccess,
        setErrorMsg,
        true,
        null,
        null,
        getLsKey(params.category),
        '',
        '',
        true
    )

    return (
        /* Check mining standings data. */
        <DataStateCheckWrapper
            firstQueryDataEntry={miningStandings[0]}
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccessMiningRef.current}
            b_isContentSection={true}
        >
            <div className="tb-wrapper">
                <table cellSpacing="0" className="tb-base text-left">
                    <thead>
                        <tr>
                            <td>Position</td>
                            <td>Username</td>
                            <td>Doks</td>
                            <td className="hide-max-w-768px">Prüfungen</td>
                            <td>Übungen</td>
                            <td>Forum</td>
                            <td>Gesamt</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            /* Check user's mining standing data. */
                            isFirstFetchSuccess === undefined
                            ?
                            <tr className="tr-user-owned tr-single-cell">
                                <td colSpan="7">Laden ...</td>
                            </tr>
                            :
                            isFirstFetchSuccess === false || usersMiningStanding === undefined
                            ?
                            <tr className="tr-user-owned tr-single-cell">
                                <td colSpan="7">
                                    Dein Mining-Fortschritt konnte nicht geladen werden.
                                </td>
                            </tr>
                            :
                            usersMiningStanding.points > 0 || isOverall
                            ?
                            <tr className="tr-user-owned">
                                <td>{usersMiningStanding.position}</td>
                                <td>{usersMiningStanding.username}</td>
                                <td>{usersMiningStanding.docPoints}</td>
                                <td className="hide-max-w-768px">{usersMiningStanding.examPoints}</td>
                                <td>{usersMiningStanding.exercisePoints}</td>
                                <td>{usersMiningStanding.forumPoints}</td>
                                <td>{usersMiningStanding.points}</td>
                            </tr>
                            :
                            <tr className="tr-user-owned tr-single-cell">
                                <td colSpan="7">
                                    Du hast in diesem Semester noch keinen Mining-Fortschritt erziehlt.
                                </td>
                            </tr>
                        }
                        {
                            miningStandings.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.position}</td>
                                    <td>{item.username}</td>
                                    <td>{item.docPoints}</td>
                                    <td className="hide-max-w-768px">{item.examPoints}</td>
                                    <td>{item.exercisePoints}</td>
                                    <td>{item.forumPoints}</td>
                                    <td>{item.points}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </DataStateCheckWrapper>
    )
}

export default TbRankingBase
