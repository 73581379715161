import React from 'react';
import PropTypes from 'prop-types';

import CSecBase from './CSecBase';
import DiscussionContentBtn from '../buttons/DiscussionContentBtn';
import { useIsFormOpenContext } from './context-provider/DiscussionNewPostContextProvider';

const CSecDiscussionNewPost = ({
    str_newBtnTitle,
    children
}) => {
    const isFormOpen = useIsFormOpenContext()
    return (
            <CSecBase
                str_id='post-section-id'
                str_classes='no-padding-bottom no-margin-bottom'
            >
                {
                    isFormOpen
                    ?
                    children
                    :
                    <DiscussionContentBtn str_btnTitle={str_newBtnTitle} />
                }
            </CSecBase>
    )
}

CSecDiscussionNewPost.propTypes = {
    str_newBtnTitle: PropTypes.string.isRequired
}

export default CSecDiscussionNewPost
