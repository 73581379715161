import React, { useState, useEffect, useRef } from 'react';

import { useIsOpenContext, useRunAnimationContext } from './context-provider/SbAnimationContextProvider';

const SbToggle = () => {

    const [isShow, setIsShow] = useState(true)
    const [isHide, setIsHide] = useState(false)
    const sbToggleRef = useRef()

    const isOpen = useIsOpenContext()
    const runAnimation = useRunAnimationContext()

    useEffect(() => {
        const handleWindowResize = () => {
            /**
             * If the kdom_sidebar is in a hidden state while the window size is increased 
             * to a size where the kdom_sidebar must always be displayed, make sure it is 
             * displayed. Otherwise, the use cannot access it anymore.
             * Only run once, as the resize behaviour is sufficient to be actived on 
             * onload - listen for the rest of the page lifetime afterwards. 
             */
            if (window.innerWidth > 768 && isHide) {
                sbToggleRef.current.click()
            }
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [isHide])

    useEffect(() => {
        if (isOpen) {
            setIsShow(true)
            setIsHide(false)
        } else {
            setIsShow(false)
            setTimeout(() => setIsHide(true), 600)
        }
    }, [isOpen])

    return (
        <div 
            ref={sbToggleRef}
            className={`sb-toggle ${isShow ? '' : 'sb-toggle-active'}`}
            onClick={() => runAnimation()}
        >
            <div className={`sb-toggle-show ${isShow ? '' : 'hidden'}`}></div>
            <div className={`sb-toggle-hide ${isHide ? '' : 'hidden'}`}></div>
        </div>
    )
}

export default SbToggle
