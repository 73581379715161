import React from 'react'
import PropTypes from 'prop-types'

const str_loadingMsg = 'Laden ...';
const str_stdNoDataMsg = 'Keine Daten verfügbar.';


const NoPPDataAvailable = ({
    str_message='',
    b_isLoadingData=false
}) => {
  return (
    <div className="no-available-pp-data">
        <p>
            {
                str_message
                ?
                str_message
                :
                b_isLoadingData
                ?
                str_loadingMsg
                :
                str_stdNoDataMsg
            }
        </p>
    </div>
  )
}

NoPPDataAvailable.propTypes = {
    str_message: PropTypes.string,
    b_isLoadingData: PropTypes.bool
}

export default NoPPDataAvailable
