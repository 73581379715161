import React from 'react';
import PropTypes from 'prop-types';

import CSecBase from '../../../content-sections/CSecBase';
import InputFormContextProvider from '../../../input-forms/context-provider/InputFormContextProvider';
import FilterFormMining from '../../filter-forms/mining/FilterFormMining';

import { FilterFormMiningData as FormData } from '../../../input-forms/util/form-config-data/InputFormFilterDataConfigs';
import { static2dynamicPanelConfig } from '../../../input-forms/util/input_form_data_handler';
import { useDataExistsContext } from '../../../context-provider/DynamicContentLoadContextProvider';

const FilterMining = ({ str_classes }) => {
    const dataExists = useDataExistsContext()
    return (
        dataExists &&
        <CSecBase str_classes={`no-padding no-margin-bottom ${str_classes}`}>
            <InputFormContextProvider
                {...static2dynamicPanelConfig(FormData)}
                obj_clearedState={FormData.obj_initState}
                b_isFilterContext={true}
            >
                <FilterFormMining />
            </InputFormContextProvider>
        </CSecBase>
    )
}

FilterMining.propTypes = {
    str_classes: PropTypes.string
}

export default FilterMining
