import React from 'react';

import PrivatePage from '../../../../components/network/PrivatePage';
import PageBase from '../../../../components/page-layout/PageBase';
import CSecBase from '../../../../components/content-sections/CSecBase';
import TbIndustryJobs from '../../../../components/tables/industry/jobs/TbIndustryJobs';
import FilterJobs from '../../../../components/filters/filters/industry/FilterJobs';
import DynamicContentLoadBtn from '../../../../components/buttons/DynamicContentLoadBtn';
import DynamicContentLoadContextProvider from '../../../../components/context-provider/DynamicContentLoadContextProvider';

import useSetBrowserTabTitle from '../../../../custom-hooks/useSetBrowserTabTitle';

import DynLoadPackageSizes from '../../../../util/config/data_load_package_sizes.json';
import Usertypes from '../../../../util/Usertypes';
import { AppIndustryLsKeys } from '../../../../util/LocalStorageVariables';
import AuthService from '../../../../util/authentication/auth_service';

const LS_KEY = AppIndustryLsKeys.jobsListing
const USERTYPE_STUDENT = Usertypes.student;
const URL = '/api/industry/job/list/';

const JobsIndustryListingPage = () => {
    const usertype = AuthService.getUsertype()
    const isStudentUser = usertype === USERTYPE_STUDENT
    useSetBrowserTabTitle('Jobangebote')
    return (
        <PrivatePage
            arr_usertypes={[Usertypes.company, Usertypes.student]}
            b_isStudentFreeTier={true}
        >
            <PageBase>
                <DynamicContentLoadContextProvider
                    str_queryURL={URL}
                    str_lsKey={LS_KEY}
                    b_useLS={true}
                    nbr_packageSize={DynLoadPackageSizes.industry.job.listPage}
                >
                    <CSecBase str_title='Jobs' b_isUsedAsTitle={true} />
                    <FilterJobs />
                    <CSecBase>
                        <TbIndustryJobs
                            b_hasCompany={true}
                            b_isDynamic={true}
                            b_hasPins={isStudentUser}
                        />
                        <DynamicContentLoadBtn />
                    </CSecBase>
                </DynamicContentLoadContextProvider>
            </PageBase>
        </PrivatePage>
    )
}

export default JobsIndustryListingPage
