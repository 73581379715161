import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import DataStateCheckWrapper from '../../components/general/DataStateCheckWrapper';
import CSecGridBase from '../../components/content-sections/CSecGridBase';
import CardAbo from '../../components/cards/CardAbo';

import useGetLSorDBdata from '../../custom-hooks/useGetLSorDBdata';
import AuthService from '../../util/authentication/auth_service';
import { IsPaymentSuccessKey } from '../../util/LocalStorageVariables';

const URL = '/api/profiles/abo-extension/gastronomer/';

const AboExtensionGastronomer = ({ b_isProfilePage=false }) => {
    
    const navigate = useNavigate()
    const [data, setData] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState()

    useGetLSorDBdata(URL, setData, setIsFirstFetchSuccess, setErrorMsg)

    const posted = (response) => {
        /* Set abo status in LS to valid (otherwise the PP cannot be accessed). */
        AuthService.setIsAboValid(true)
        localStorage.setItem(IsPaymentSuccessKey, true)
        navigate('success')
    }

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            str_errorMsg={errorMsg}
            b_isContentSection={true}
            firstQueryDataEntry={-1} /* Only placeholder value, not used. */
        >
            <CSecGridBase
                str_title={b_isProfilePage ? '' : 'Aboverlängerung'}
                str_gridClass={'pp-abo-card-grid one-col'}
                str_secBaseClasses={b_isProfilePage ? 'no-padding' : ''}
            >
                {
                    data &&
                    <div className="grid-item">
                        <CardAbo
                            nbr_extension={1}
                            str_extensionUnit='Semester'
                            nbr_price={data.couponBudget}
                            str_url={URL}
                            b_isLink={false}
                            fct_response2parent={posted}
                        />
                    </div>
                }
            </CSecGridBase>
        </DataStateCheckWrapper>
    )
}

AboExtensionGastronomer.propTypes = {
    str_title: PropTypes.string
}

export default AboExtensionGastronomer
