import React from 'react';

import CSecBase from '../content-sections/CSecBase';

const ProfileSetupSettingsChangeMsg = () => {
    return (
        <CSecBase str_classes='no-padding-top no-margin-bottom'>
            <div className="setup-msg">
                <p>Alle Einstellungen können später noch angepasst werden.</p>
            </div>
        </CSecBase>
    )
}

export default ProfileSetupSettingsChangeMsg
