import React from 'react';
import PropTypes from 'prop-types';
import PPMainContentBase from './PPMainContentBase';

import NoPPDataAvailable from './NoPPDataAvailable';
import { extractFileNameFromPath } from '../../util/data_handler';

const datatype2String = (data, b_returnEmptyStringForBoolFalse=false) => {
    /**
     * Converts data of different datypes (as served by the DB)
     * to strings so that they can be displayed on the front end. 
     * :Input
     *  data (different types)
     * :Returns
     *  data (str)
     */
    switch (typeof(data)){
        case 'boolean':
            if (data) {
                return 'Ja';
            } else {
                if (b_returnEmptyStringForBoolFalse) {
                    return '—';
                } else {
                    return 'Nein';
                }
            }
        case 'object': /* This is an array, JSON is not possible as input arg. */
            if (!data || !data.length) return '—'; /* data = null or [] */
            return data.join(', ');
        case 'string':
            if (data === '') return '—';
            return data;
        case 'undefined':
            return '—';
        default: /* number, string */
            return data;
    }
}

const hasData = (data) => {
    /**
     * Checks if any of the key value pairs has some valid data stored.
     * Valid data is data !== undefined/null/''.
     * :Input
     *  data (obj): Data of the PP data group.
     * :Returns
     *  Bool: True if data has at least one valid value.
     */
    let hasData = false;
    Object.values(data).forEach((value) => {
        if (value !== null && value !== '' && value !== undefined) {
            hasData = true;
        }
    })
    return hasData;
}

const PPDataGroup = ({
    str_header, /* The title of the data group of the profile page. */
    str_editUrl='',
    obj_data,
    obj_dataStructure=null, /* Contains sequence of displayed data. Values are the titles. */
    b_isDataFilePath=false  /* true if the data is a file path. */
}) => {
    const b_hasData = hasData(obj_data)
    return (
        <PPMainContentBase
            str_header={str_header}
            str_editUrl={str_editUrl}
        >
            {
                b_hasData
                ?
                Object.keys(obj_dataStructure).map((key, index) => (
                    <div key={index} className="pp-mc-group-item">
                        <div className="title">
                            {obj_dataStructure[key]}
                        </div>
                        <div className="content">
                            {
                                typeof(obj_dataStructure[key]) === 'string'
                                ?
                                datatype2String(obj_data[key])
                                :
                                obj_data[key]
                                ?
                                <a
                                    href={obj_data[key]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="page-link"
                                >
                                    {
                                        b_isDataFilePath
                                        ?
                                        extractFileNameFromPath(obj_data[key])
                                        :
                                        obj_data[key]
                                    }
                                </a>
                                :
                                datatype2String(obj_data[key])
                            }
                        </div>
                    </div>
                ))
                :
                <NoPPDataAvailable />
            }
        </PPMainContentBase>
    )
}

PPDataGroup.propTypes = {
    str_header: PropTypes.string.isRequired,
    str_editUrl: PropTypes.string,
    obj_data: PropTypes.object,
    obj_dataStructure: PropTypes.object,
    b_isDataFilePath: PropTypes.bool
}

export default PPDataGroup
