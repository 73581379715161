/**
 * Contains the data that is necessary for the post panel to work.
 */

export const kobj_keyMappingOptions = {
    '4': 'b',
    '5': 'i',
    '7': 'u',
    '8': 'l',
    '9': 'c'
};

export const kobj_classMapping = {
    'b' : ['*%', '%*'],
    'i' : ['~%', '%~'],
    'u' : ['_%', '%_'],
    'l' : ['&%', '%&'],
    'c' : ['#%', '%#']
};

export const karr_shortcutPanelSwitchKeys = ['.', ','];
