/**
 * This component is used to store the state of a page filter.
 * The state can thus be retrieved if URL changes and then get
 * changed back to the initial one.
 * 
 * This is, for instance, used for 'forum' pages.
 */
import React, { useState, useContext } from 'react';

const FilterStateContext = React.createContext();
const SetFilterStateContext = React.createContext();

export function useFilterStateContext() {
    return useContext(FilterStateContext);
}

export function useSetFilterStateContext() {
    return useContext(SetFilterStateContext);
}

const FilterStateContextProvider = ({ children }) => {
    const [filter, setFilter] = useState(null)
    return (
        <FilterStateContext.Provider value={filter}>
            <SetFilterStateContext.Provider value={setFilter}>
                {children}
            </SetFilterStateContext.Provider>
        </FilterStateContext.Provider>
    )
}

export default FilterStateContextProvider
