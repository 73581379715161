import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import CardBase from '../CardBase';
import CardFoodBody from './CardFoodBody';
import CardFooterPurchase from '../CardFooterPurchase';
import CardFoodFooterGastroInfo from './CardFoodFooterGastroInfo';
import CardFoodFooterGastroEdit from './CardFoodFooterGastroEdit';
import CardFoodFooterGastroRepeat from './CardFoodFooterGastroRepeat';
import CardFoodFooterRedeem from './CardFoodFooterRedeem';

import { useStopRepeatingContext, useDeleteOldContext } from '../../profile-pages/gastronomy/context-provider/GastroPPFoodOffersContextProvider';
import { useDeleteOldDgfsContext } from '../../profile-pages/gastronomy/context-provider/GastroPPDgfsContextProvider';

const OFFER_RESERVATION_BASE_URL = '/api/gastronomy/food-offers/detail/';

const CardFood = ({
    nbr_id, /* Food offer id. */
    str_usertype,
    str_title,
    str_eyebrow,
    str_cardClass,
    obj_bodyData,
    obj_footerData,
    b_isDgfs=false,
    /* Footer component used. */
    b_hasSellView=false,
    b_hasRedeemView=false,
    b_hasGastroInfoView=false,
    b_hasGastroRepeatView=false,
    /* Food offer redemption. */
    b_hasModalView=false,
    b_isRedeemSuccess,
    fct_handleRedeem
}) => {

    const cardRef = useRef()
    const stopRepeating = useStopRepeatingContext()
    const deleteOld = b_isDgfs ? useDeleteOldDgfsContext() : useDeleteOldContext()
    
    return (
        <CardBase
            cardRef={cardRef}
            str_title={str_title}
            str_eyebrow={str_eyebrow}
            str_cardClass={str_cardClass}
            comp_cardBody={<CardFoodBody {...obj_bodyData} />}
            comp_cardFooter={
                b_hasSellView
                ?
                <CardFooterPurchase
                    str_usertype={str_usertype}
                    str_cardTitle={str_title}
                    str_btnTitle='Reservieren'
                    str_baseUrl={OFFER_RESERVATION_BASE_URL}
                    nbr_id={nbr_id}
                    nbr_price={obj_footerData.price}
                    nbr_available={obj_footerData.numAvailable}
                    nbr_left={obj_footerData.numLeft}
                    b_isOwner={obj_footerData.isOwner}
                    b_isSubmitBtnActive={!obj_footerData.isPastOffer}
                />
                :
                b_hasRedeemView
                ?
                /* Student user only. Redeem a reserved food offer. */
                <CardFoodFooterRedeem
                    nbr_id={nbr_id}
                    nbr_price={obj_footerData.price}
                    b_isToday={obj_footerData.isToday}
                    b_isRedeemed={obj_footerData.isRedeemed}
                    b_isRedeemSuccess={b_isRedeemSuccess}
                    str_redeemTimestamp={obj_footerData.redeemTimestamp}
                    str_redeemUserFullName={obj_footerData.redeemUserFullName}
                    str_redeemCheckCode={obj_footerData.redeemCheckCode}
                    fct_handleRedeem={fct_handleRedeem}
                    b_hasModalView={b_hasModalView}
                />
                :
                b_hasGastroInfoView
                ?
                /* Gastronomer user only. General info about coupon and its selling numbers. */
                <CardFoodFooterGastroInfo
                    nbr_price={obj_footerData.price}
                    nbr_available={obj_footerData.numAvailable}
                    nbr_reserved={obj_footerData.numReserved}
                    nbr_redeemed={obj_footerData.numRedeemed}
                />
                :
                b_hasGastroRepeatView
                ?
                /* Gastronomer user only. Stop repeating offers. */
                <CardFoodFooterGastroRepeat
                    nbr_price={obj_footerData.price}
                    nbr_available={obj_footerData.numAvailable}
                    fct_handleStop={() => stopRepeating(nbr_id, cardRef)}
                />
                :
                /* Gastronomer user only. Reuse or delete old food offers. */
                <CardFoodFooterGastroEdit
                    nbr_id={nbr_id}
                    nbr_price={obj_footerData.price}
                    fct_handleDelete={() => deleteOld(nbr_id, cardRef)}
                />
            }
        />
    )
}

CardFood.propTypes = {
    nbr_id: PropTypes.number.isRequired,
    str_usertype: PropTypes.string,
    str_title: PropTypes.string.isRequired,
    str_eyebrow: PropTypes.string,
    str_cardClass: PropTypes.string,
    obj_bodyData: PropTypes.object.isRequired,
    obj_footerData: PropTypes.object.isRequired,
    b_isDgfs: PropTypes.bool,
    /* Footer component used. */
    b_hasSellView: PropTypes.bool,
    b_hasGastroInfoView: PropTypes.bool,
    b_hasGastroRepeatView: PropTypes.bool,
    b_hasRedeemView: PropTypes.bool,
    /* Food offer redemption. */
    b_hasModalView: PropTypes.bool,
    b_isRedeemSuccess: PropTypes.bool,
    fct_handleRedeem: PropTypes.func
}

export default CardFood
