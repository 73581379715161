import React, { useState, useEffect, useRef, useContext } from 'react';

import DataStateCheckWrapper from '../../../general/DataStateCheckWrapper';
import FoodOffersSerializer from '../../../../util/serializers/backend_serializers/food_offers_serializer';
import { fetchDBdata, deleteDBdata, sendData2db, setLSdata, fetchLSdata } from '../../../../util/db_ls_query_handler';
import { Messages } from '../util/data';
import { hasTimestampExpired, addDays2BrowserDateString } from '../../../../util/date_time_handler';
import { PPLsKeys, GastronomerLsKeys, TimestampLsKey } from '../../../../util/LocalStorageVariables';

const OffersThisWeekContext = React.createContext();
const OffersNextWeekContext = React.createContext();
const OffersRepeatingContext = React.createContext();
const OffersOldContext = React.createContext();
const StopRepeatingContext = React.createContext();
const DeleteOldContext = React.createContext();
const AddNewOfferContext = React.createContext();

export function useOffersThisWeekContext() {
    return useContext(OffersThisWeekContext);
}

export function useOffersNextWeekContext() {
    return useContext(OffersNextWeekContext);
}

export function useOffersRepeatingContext() {
    return useContext(OffersRepeatingContext);
}

export function useOffersOldContext() {
    return useContext(OffersOldContext);
}

export function useStopRepeatingContext() {
    return useContext(StopRepeatingContext);
}

export function useDeleteOldContext() {
    return useContext(DeleteOldContext);
}

export function useAddNewOfferContext() {
    return useContext(AddNewOfferContext);
}

/* LS keys. */
const PR_KEY = PPLsKeys.primaryDataKey;
const SEC_KEY = GastronomerLsKeys.pp.foodOffersRegular.base;
const THIS_WEEK_KEY = GastronomerLsKeys.pp.foodOffersRegular.thisWeek;
const NEXT_WEEK_KEY = GastronomerLsKeys.pp.foodOffersRegular.nextWeek;
const REPEATING_KEY = GastronomerLsKeys.pp.foodOffersRegular.repeating;
const OLD_KEY = GastronomerLsKeys.pp.foodOffersRegular.old;
const TIMESTAMP_KEY = TimestampLsKey;

const LIST_URL = '/api/gastronomy/gastronomer/food-offers/regular/';
const DETAIL_BASE_URL = '/api/gastronomy/gastronomer/food-offers/detail/';

const GastroPPFoodOffersContextProvider = ({ children }) => {

    const [offersThisWeek, setOffersThisWeek] = useState()
    const [offersNextWeek, setOffersNextWeek] = useState()
    const [offersRepeating, setOffersRepeating] = useState()
    const [offersOld, setOffersOld] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState(undefined)
    const [errorMsg, setErrorMsg] = useState('')
    const isButtonLockedRef = useRef(false)

    useEffect(() => {
        const makeDBrequest = async () => {
            const queryData = await fetchDBdata(LIST_URL)
            if (queryData.isQuerySuccess) {
                const data = queryData.response.data
                let serializedData

                serializedData = FoodOffersSerializer.dbList2foodOffersWeekdayObject(data.thisWeek)
                setOffersThisWeek(serializedData)
                setLSdata(serializedData, PR_KEY, SEC_KEY, THIS_WEEK_KEY)
                
                serializedData = FoodOffersSerializer.dbList2foodOffersWeekdayObject(data.nextWeek)
                setOffersNextWeek(serializedData)
                setLSdata(serializedData, PR_KEY, SEC_KEY, NEXT_WEEK_KEY)
                
                serializedData = FoodOffersSerializer.dbList2foodOfferCardsArray(data.repeating)
                setOffersRepeating(serializedData)
                setLSdata(serializedData, PR_KEY, SEC_KEY, REPEATING_KEY)
                
                serializedData = FoodOffersSerializer.dbList2foodOfferCardsArray(data.old)
                setOffersOld(serializedData)
                setLSdata(serializedData, PR_KEY, SEC_KEY, OLD_KEY)
                
                setIsFirstFetchSuccess(true)
                
                /* Set timestamp of query time. */
                const timestamp = new Date().getTime()
                setLSdata(timestamp, PR_KEY, SEC_KEY, TIMESTAMP_KEY)
            } else {
                setErrorMsg(queryData.errorMsg)
                setIsFirstFetchSuccess(false)
            }
        }

        const makeLSrequest = () => {
            const lsData = fetchLSdata(PR_KEY, SEC_KEY)
            setOffersThisWeek(lsData.thisWeek)
            setOffersNextWeek(lsData.nextWeek)
            setOffersRepeating(lsData.repeating)
            setOffersOld(lsData.old)
            setIsFirstFetchSuccess(true)
        }

        /* Fetch data from LS or DB depending on timestamp expiration. */
        const timestamp = fetchLSdata(PR_KEY, SEC_KEY, TIMESTAMP_KEY);
        if (timestamp && !hasTimestampExpired(timestamp, 10, 'min')) makeLSrequest()
        else makeDBrequest()
    }, [])

    const stopRepeatingOffer = async (nbr_offerId, cardRef) => {
        /**
         * Makes a DB PUT request to move the repeating offer from active to inactive. 
         * If DB query is success, react states and LS are updated.
         */
        if (isButtonLockedRef.current) return
        isButtonLockedRef.current = true

        if (!window.confirm(Messages.stopRepeatingConfirmMsg)) {
            isButtonLockedRef.current = false
            return
        }

        const queryData = await sendData2db('post', DETAIL_BASE_URL + `${nbr_offerId}/`)
        if (!queryData.isQuerySuccess) {
            alert(Messages.dbQueryErrorMsg)
            isButtonLockedRef.current = false
            return
        }

        /* Run animation, then move repeating offer to old offers (react state and LS). */
        runCardBlurAnimation(cardRef)
        setTimeout(() => {
            let newRepeating = []
            let target;
            offersRepeating.forEach((offer) => {
                if (offer.id !== nbr_offerId) {
                    newRepeating.push(offer)
                } else {
                    target = offer
                }
            })
            const newOld = [target, ...offersOld]
    
            setOffersRepeating(newRepeating)
            setOffersOld(newOld)
            setLSdata(newRepeating, PR_KEY, SEC_KEY, REPEATING_KEY)
            setLSdata(newOld, PR_KEY, SEC_KEY, OLD_KEY)
            
            isButtonLockedRef.current = false
        }, 900)
    }

    const addNewOffer = (response) => {
        /**
         * Add new offer to state and LS.
         * :Input
         *  response: Response received from the DB query function.
         */
        const offer = response.data

        if (offer.isPresent) {
            const offers = FoodOffersSerializer.addOffer2weekday(offersThisWeek, offer)
            setOffersThisWeek(offers)
            setLSdata(offers, PR_KEY, SEC_KEY, THIS_WEEK_KEY)
            /* If offer is repeat offer with 7 days, add it to the next week as well as it is 
             * visible on the webpage. */
            if (offer.repeatDays === 7) {
                const futureOffer = {...offer, date: addDays2BrowserDateString(offer.date, 7)}
                const futureOffers = FoodOffersSerializer.addOffer2weekday(offersNextWeek, futureOffer)
                setOffersNextWeek(futureOffers)
                setLSdata(futureOffers, PR_KEY, SEC_KEY, NEXT_WEEK_KEY)
            }
        } else {
            /* Future offers (next week). */
            const offers = FoodOffersSerializer.addOffer2weekday(offersNextWeek, offer)
            setOffersNextWeek(offers)
            setLSdata(offers, PR_KEY, SEC_KEY, NEXT_WEEK_KEY)
        }

        /* If offer is template, add it to templates as well. */
        if (offer.repeatDays) {
            const templates = [FoodOffersSerializer.dbJson2foodOfferCard(offer), ...offersRepeating]
            setOffersRepeating(templates)
            setLSdata(templates, PR_KEY, SEC_KEY, REPEATING_KEY)
        }
    }

    const deleteOldOffer = async (nbr_offerId, cardRef) => {
        /**
         * Makes a DB request to delete an old offer from the DB.
         * If DB query is success, react states and LS are updated.
         */
        if (isButtonLockedRef.current) return
        isButtonLockedRef.current = true

        if (!window.confirm(Messages.deleteConfirmMsg)) {
            isButtonLockedRef.current = false
            return
        }

        const queryData = await deleteDBdata(DETAIL_BASE_URL + `${nbr_offerId}/`)
        if (!queryData.isQuerySuccess) {
            alert(Messages.dbQueryErrorMsg)
            isButtonLockedRef.current = false
            return
        }

        /* Run animation, then delete old offer from react state and LS. */
        runCardBlurAnimation(cardRef)
        setTimeout(() => {
            const newOld = offersOld.filter(offer => offer.id !== nbr_offerId)
            setOffersOld(newOld)
            setLSdata(newOld, PR_KEY, SEC_KEY, OLD_KEY)
            isButtonLockedRef.current = false
        }, 900)
    }

    const runCardBlurAnimation = (cardRef) => {
        cardRef.current.style.animation = 'blur 1s'
    }

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            firstQueryDataEntry={-1} /* Placeholder to avoid 'no data' rendering (details handled by children). */
            str_errorMsg={errorMsg}
            b_isContentSection={true}
        >
            <OffersThisWeekContext.Provider value={offersThisWeek}>
                <OffersNextWeekContext.Provider value={offersNextWeek}>
                    <OffersRepeatingContext.Provider value={offersRepeating}>
                        <OffersOldContext.Provider value={offersOld}>
                            <StopRepeatingContext.Provider value={stopRepeatingOffer}>
                                <DeleteOldContext.Provider value={deleteOldOffer}>
                                    <AddNewOfferContext.Provider value={addNewOffer}>
                                        {children}
                                    </AddNewOfferContext.Provider>
                                </DeleteOldContext.Provider>
                            </StopRepeatingContext.Provider>
                        </OffersOldContext.Provider>
                    </OffersRepeatingContext.Provider>
                </OffersNextWeekContext.Provider>
            </OffersThisWeekContext.Provider>
        </DataStateCheckWrapper>
    )
}

export default GastroPPFoodOffersContextProvider
