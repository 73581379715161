import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const NoPageContentPlaceholder = ({ str_redirectUrl='' }) => {

    const navigate = useNavigate()

    useSetBrowserTabTitle('Gesuchte Seite existiert nicht')

    useEffect(() => {
        if (str_redirectUrl) navigate(str_redirectUrl, { replace: true })
    }, [])

    return (
        !str_redirectUrl &&
        <main>
            <div className="no-content-placeholder">
                <p>Hier scheint nichts zu sein.</p>
            </div>
        </main>
    )
}

NoPageContentPlaceholder.propTypes = {
    str_redirectUrl: PropTypes.string
}

export default NoPageContentPlaceholder
