import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';

import PPFormConfig from '../../input-forms/profile-pages/util/PPFormConfig';
import Usertypes from '../../../util/Usertypes';
import AuthService from '../../../util/authentication/auth_service';
import { fetchDBdata, sendData2db } from '../../../util/db_ls_query_handler';

const ConfigDataContext = React.createContext();
const SetupStepContext = React.createContext();
const NumTotalSetupStepsContext = React.createContext();
const Navigate2nextSetupPageContext = React.createContext();

export function useConfigDataContext() {
    return useContext(ConfigDataContext);
}

export function useSetupStepContext() {
    return useContext(SetupStepContext);
}

export function useNumTotalSetupStepsContext() {
    return useContext(NumTotalSetupStepsContext);
}

export function useNavigate2nextSetupPageContext() {
    return useContext(Navigate2nextSetupPageContext);
}

const URL = '/api/profiles/profile-setup/';

const ProfileSetupContextProvider = ({ children }) => {

    const [configData, setConfigData] = useState(null)
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    const [setupStep, setSetupStep] = useState()
    const [numSetupSteps, setNumSetupSteps] = useState()
    const navigate = useNavigate()

    const usertype = AuthService.getUsertype()

    useEffect(() => {
        const func = async () => {
            const queryData = await fetchDBdata(URL)
            if (queryData.isQuerySuccess) {
                setSetupStep(queryData.response.data.profileSetupStep)
                setDataAndSteps(usertype, setConfigData)
                setIsFirstFetchSuccess(true)
            } else {
                setIsFirstFetchSuccess(false)
                setErrorMsg(queryData.errorMsg)
            }
        }
        func()
    }, [])

    const setDataAndSteps = () => {
        /**
         * Set the config data and the num of setup steps according to the usertype.
         */
        switch (usertype) {
            case Usertypes.company:
                setConfigData(PPFormConfig.company)
                setNumSetupSteps(Object.keys(PPFormConfig.company).length)
                return
            case Usertypes.gastronomer:
                setConfigData(PPFormConfig.gastronomer)
                setNumSetupSteps(Object.keys(PPFormConfig.gastronomer).length)
                return
            case Usertypes.student:
                setConfigData(PPFormConfig.student)
                setNumSetupSteps(Object.keys(PPFormConfig.student).length)
                return
            default:
                return
        }
    }

    const navigate2nextSetupPage = async () => {
        /**
         * After a successful form submission of a setup page, this function is triggered.
         * Posts the next setup step key to the DB and, on success, links the user to 
         * the next setup step page. If the request has an error, the error message is shown
         * and the user is not linked to the next page (reload page and try again).
         */
        const nextSetupStep = configData[setupStep].psNextStep
        setSetupStep(nextSetupStep)
        
        /* The request to post the new key is treated as new first request to show the loading
         * message and handle the error accordingly if something goes wrong. */
        setIsFirstFetchSuccess(undefined)

        const pl = { 'profileSetupStep': nextSetupStep }
        const queryData = await sendData2db('put', URL, pl)
        if (queryData.isQuerySuccess) {
            setIsFirstFetchSuccess(true)
            /* If the next step is complete, the profile set-up ist complete. */
            if (nextSetupStep === 'complete') {
                /* Set abo of gastronomer and student users to valid so that user can go
                 * to the profile page. Company users must proceed with the abo payment
                 * before the abo is set to valid.
                 * Note: Students start with a 1 month test pro abo. */
                if (usertype !== Usertypes.company) AuthService.setIsAboValid(true)
                AuthService.setIsProfileSetup(true)
                navigate('success')
            } else {
                navigate(`${nextSetupStep}`)
            }
        } else {
            setIsFirstFetchSuccess(false)
            setErrorMsg(queryData.errorMsg)
        }
    }
    
    return (
        <>
            {/* Create space between the navbar and the loader. */}
            <DataStateCheckWrapper
                b_hasQueryCheck={true}
                b_isFirstFetchSuccess={isFirstFetchSuccess}
                b_isContentSection={true}
                str_errorMsg={errorMsg}
                firstQueryDataEntry={setupStep}
            >
                <ConfigDataContext.Provider value={configData}>
                    <SetupStepContext.Provider value={setupStep}>
                        <NumTotalSetupStepsContext.Provider value={numSetupSteps}>
                            <Navigate2nextSetupPageContext.Provider value={navigate2nextSetupPage}>
                                {children}
                            </Navigate2nextSetupPageContext.Provider>
                        </NumTotalSetupStepsContext.Provider>
                    </SetupStepContext.Provider>
                </ConfigDataContext.Provider>
            </DataStateCheckWrapper>
        </>
    )
}

export default ProfileSetupContextProvider
