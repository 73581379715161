import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import PrivatePage from '../../../../components/network/PrivatePage';
import PageBase from '../../../../components/page-layout/PageBase';
import JobHeader from '../../../../components/info-pages/jobs/JobHeader';
import JobFooter from '../../../../components/info-pages/jobs/JobFooter';
import JobBulletPoints from '../../../../components/info-pages/jobs/JobBulletPoints';
import JobPoster from '../../../../components/info-pages/jobs/JobPoster';
import ObjectSerializer from '../../../../util/serializers/ObjectSerializer';
import DataStateCheckWrapper from '../../../../components/general/DataStateCheckWrapper';
import CSecPageUpdateTimeInfo from '../../../../components/content-sections/CSecPageUpdateTimeInfo';

import Usertypes from '../../../../util/Usertypes';
import useGetDBdata from '../../../../custom-hooks/useGetDBdata';

const SERIALIZER_LAYOUT = {
    header: [
        'title',
        'isPaid',
        'isInternship',
        'thesisOptions',
        'startDate',
        'isStartDateFlexible',
        'timeModel',
        'duration',
        'location',
        'companyName',
        'webpage',
    ],
    footer: [
        'firstName',
        'lastName',
        'jobPosition',
        'workLocation',
        'phone',
        'email',
        'linkedin',
        'launchDate',
        'closingDate',
        'positionUrl',
        'numCompletedSemesters',
        'applicationDocs',
        'focusArea',
        'equalOpsMsg'
    ],
    intro: 'plain',
    addInfo: 'array',
    jobFile: 'plain',
    reqSkill: 4,
    assetSkill: 4,
    responsibility: 4
};

const BASE_URL = '/api/industry/job/detail/';

const JobIndustryDetailPage = () => {

    const params = useParams()
    const [data, setData] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    const url = BASE_URL + params.jobId + '/'
    useGetDBdata(url, setData, setIsFirstFetchSuccess, setErrorMsg, ObjectSerializer.serialize, SERIALIZER_LAYOUT)

    /* Set browser tab title. */
    const tabTitle = data ? `Jobstelle - ${data.header.title} | MUPLAZA` : 'Jobstelle | MUPLAZA'
    document.title = tabTitle

    return (
        <PrivatePage
            arr_usertypes={[Usertypes.company, Usertypes.student]}
            b_isStudentFreeTier={true}
        >
            <PageBase
                str_pageSpacingClasses='no-width-restriction'
                str_pageContentBgColorClasses='bg-col-black-100'
                str_mainContentWrapperClasses='mc-wrapper job-pos-posting-wrapper'
                b_useLightTheme={true}
            >
                <DataStateCheckWrapper
                    b_hasQueryCheck={true}
                    b_isFirstFetchSuccess={isFirstFetchSuccess}
                    b_isContentSection={true}
                    str_errorMsg={errorMsg}
                    firstQueryDataEntry={-1} /* Only placeholder value. */
                >
                    {
                        data &&
                        <>
                            <CSecPageUpdateTimeInfo str_duration='6 h' />
                            <JobHeader obj_data={data.header} />
                            <section className="job-pos-content">
                                <div className="job-pos-content-wrapper">
                                    <div className="job-pos-intro">
                                        <p>{data.intro}</p>
                                    </div>
                                    <JobBulletPoints
                                        str_title='Aufgabengebiete'
                                        arr_data={data.responsibility}
                                    />
                                    <JobBulletPoints
                                        str_title='Erforderliche Qualifikationen'
                                        arr_data={data.reqSkill}
                                    />
                                    <JobBulletPoints
                                        str_title='Vorteilhafte Qualifikationen'
                                        arr_data={data.assetSkill}
                                    />
                                    <JobPoster str_filePath={data.jobFile} />
                                    <JobBulletPoints
                                        str_title='Zusatzinfo'
                                        arr_data={data.addInfo}
                                    />
                                </div>
                            </section>
                            <JobFooter obj_data={data.footer} />
                        </>
                    }
                </DataStateCheckWrapper>
            </PageBase>
        </PrivatePage>
    )
}

export default JobIndustryDetailPage
