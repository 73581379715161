import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import FieldWrapper from './FieldWrapper';
import InFieldConfirmationValue from '../InFieldConfirmationValue';

import { standardizeTimeFormat } from '../../../util/date_time_handler';
import {
    isRequiredFulfilled,
    isTextTimeFormatValid,
    isTimeSpanPartValid
} from '../util/input_checks';
import { 
    useHandleInputChange,
    useAreRequiredErrorsActive
} from '../context-provider/InputFormContextProvider';
import { useIsConfirmationViewOpenContext } from '../context-provider/FormBaseContextProvider';
import FieldErrorMsgs from '../util/input_form_fields_error_msgs.json';


const createTopInfoText = (str_minTime, str_maxTime) => {
    /**
     * Creates a top info message for min. and max. time values if present.
     * :Returns
     *  Info message (str)
     */
    const str_minMsg = str_minTime ?
        'Min. Zeit: ' + str_minTime + '. ' : '';
    const str_maxMsg = str_maxTime ?
        'Max. Zeit: ' + str_maxTime + '.' : '';
    return str_minMsg + str_maxMsg;
}

const REQUIRED_EMSG = FieldErrorMsgs.required;
const FORMAT_EMSG = FieldErrorMsgs.datetime.time.format;
const MIN_TIME_EMSG = FieldErrorMsgs.datetime.time.tooSmall;
const MAX_TIME_EMSG = FieldErrorMsgs.datetime.time.tooBig;

const TimeField = ({
    str_id,
    str_fieldTitle='',
    str_time='',
    str_minTime,
    str_maxTime,
    str_errorMsg='',
    str_bottomInfoText='',
    b_isRequired=false,
    b_isSpanField=false,
    b_isStartTime=false,          /* Determines if component is start or end field. */
    b_hasSpanChanged=false,       /* Switches between true/false to check for changes. */
    fct_isTimeSpanPartValid=null, /* Function for parent component (span field). */
    fct_sendDataToParent=null     /* Required for span field. */
}) => {

    const [time, setTime] = useState(str_time)
    const [errorMsg, setErrorMsg] = useState(str_errorMsg)
    const timeInputFieldRef = useRef()
    
    const handleInputChange = useHandleInputChange()
    const isRequiredErrorActivate = useAreRequiredErrorsActive()
    const isConfirmationViewOpen = useIsConfirmationViewOpenContext()

    const str_topInfoText = createTopInfoText(str_minTime, str_maxTime)
    
    const isMinMaxValid = (currentTime) => {
        if (!currentTime) return true

        if (currentTime < str_minTime) {
            setErrorMsg(MIN_TIME_EMSG)
            return false
        } else if (str_maxTime && currentTime > str_maxTime) {
            setErrorMsg(MAX_TIME_EMSG)
            return false
        }
        return true
    }

    const hasError = () => {
         if (b_isRequired && !isRequiredFulfilled(time)) {
            setErrorMsg(REQUIRED_EMSG)
            return true
        }

        /* Check text input. */
        if (time !== '') {
            let str_timePickerFormat;
            if (timeInputFieldRef.current.type === 'text') {
                if (isTextTimeFormatValid(time)) {
                    str_timePickerFormat = standardizeTimeFormat(time)
                    setTime(str_timePickerFormat)
                } else {
                    setErrorMsg(FORMAT_EMSG)
                    return true    
                }
            } else {
                str_timePickerFormat = time
            }
            if (!isMinMaxValid(str_timePickerFormat)) return true
        }

        /* Clear the error message if all the above checks are valid (input is valid). 
         * The last check concerns the span and creates a specific error message if 
         * it is invalid. */
        setErrorMsg('')
        
        if (fct_isTimeSpanPartValid)
            if (!isTimeSpanPartValid(fct_isTimeSpanPartValid, time, b_isStartTime))
                return true

        return false
    }

    useMemo(() => {
        /* All the required fields that are blank and do not show erros,
         * are activated when a form is submitted. The line below is only 
         * executed once. After the first execution, the required field 
         * displays error messages until the input is correct. */
        if (isRequiredErrorActivate && b_isRequired) {
            /* Setting the error does not work w/o the setTimeout. 
             * Only the time fields have this issue ... */
            setTimeout(() => hasError(), 0)
        }
    }, [isRequiredErrorActivate])

    useMemo(() => {
        /* If new input is received, update the current char state. */
        setTime(str_time)
    }, [str_time])

    useEffect(() => {
        /* This is specifically necessary for the span fields. As the
         * parent can pass the error message down to the (this) child. 
         * Error messages that come from the span fiel parent, are only 
         * used to overwrite the error messages of the child if the child
         * does not have error messages or if it contains the word 
         * 'Zeitspanne'. Error messages of children have a higher priority. 
        */
       if (!errorMsg) setErrorMsg(str_errorMsg)
       else if (errorMsg.includes('Zeitspanne')) setErrorMsg(str_errorMsg)
    }, [b_hasSpanChanged])

    const handleOnBlur = (e) => {
        const b_hasError = hasError()
        const value = b_hasError ? e.target.value : standardizeTimeFormat(e.target.value)
        if (b_isSpanField) {
            /* This is the data span field parents request. */
            fct_sendDataToParent({
                isStart: b_isStartTime,
                value: value,
                hasError: b_hasError
            })
        } else {
            handleInputChange({
                id: str_id,
                value: value,
                hasError: b_hasError
            })
        }
    }

    const removeTimeOnClick = () => {
        /* Handles the behaviour when user click on the remove time element. */
        
        setTime('')
        
        if (b_isSpanField) {
            /* This is the data span field parents request. */
            if (b_isRequired && isTimeSpanPartValid(fct_isTimeSpanPartValid, '', b_isStartTime))
                setErrorMsg(REQUIRED_EMSG)
            
            fct_sendDataToParent({
                isStart: b_isStartTime,
                value: '',
                hasError: b_isRequired ? true : false
            })
        } else {
            if (b_isRequired) setErrorMsg(REQUIRED_EMSG)

            handleInputChange({
                id: str_id,
                value: '',
                hasError: b_isRequired ? true : false
            })
        }
    }

    return (
        isConfirmationViewOpen
        ?
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            b_isRequired={false}
        >
            <InFieldConfirmationValue value={time} />
        </FieldWrapper>
        :
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            str_errorMsg={errorMsg}
            str_topInfoText={str_topInfoText}
            str_bottomInfoText={str_bottomInfoText}
            b_isRequired={b_isRequired}
        >
            <div className="in-field in-field-timing in-field-time in-field-timing-single">
                <input
                    value={time}
                    ref={timeInputFieldRef}
                    id={str_id}
                    type="time"
                    name="time-field"
                    placeholder='HH:MM'
                    min={str_minTime}
                    max={str_maxTime}
                    required={b_isRequired}
                    onChange={() => setTime(timeInputFieldRef.current.value)}
                    onBlur={e => handleOnBlur(e)}
                />
                <div className={`remove-input remove-date-time
                                ${time !== '' ? '' : 'hidden'}`}
                >
                    <span onClick={removeTimeOnClick}>Remove time</span>
                </div>
            </div>
        </FieldWrapper>
    )
}

TimeField.propTypes = {
    str_id: PropTypes.string.isRequired,
    str_fieldTitle: PropTypes.string,
    str_time: PropTypes.string,
    str_minTime: PropTypes.string,
    str_maxTime: PropTypes.string,
    str_errorMsg: PropTypes.string,
    str_bottomInfoText: PropTypes.string,
    b_isRequired: PropTypes.bool,
    b_isSpanField: PropTypes.bool,
    b_isStartTime: PropTypes.bool,
    b_hasSpanChanged: PropTypes.bool,    
    fct_isTimeSpanPartValid: PropTypes.func,
    fct_sendDataToParent: PropTypes.func
}

export default TimeField
