import React from 'react';
import { useParams } from 'react-router-dom';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { useIsConfirmationViewOpenContext } from '../../context-provider/FormBaseContextProvider';
import { getCurrentDateInGermanFormat } from '../../../../util/data_handler';



const DocNameDisplay = () => {

    const params = useParams()
    const data = useInputData()
    const isConfirmationViewOpen = useIsConfirmationViewOpenContext()

    const genFileName = (fileData) => {
        /* Generates the file name as it is stored in the DB. */
        let fname = '';
        fname += params.name.replaceAll('-', '_') + '_';
        fname += fileData.title[0].replaceAll(' ', '_').replaceAll('-', '_') + '_';
        fname += fileData.category[0].replaceAll(' ', '_') + '_';
        fname += fileData.hasValidatedAnswers[0] ? 'validierte_Antworten_' : '';
        fname += fileData.isOfficial[0] ? 'offiziell_' : '';
        fname += fileData.isPeerReviewed[0] ? 'peer_reviewed_' : '';
        fname += 'qr' + fileData.qualityRank[0].toString() + '_';
        fname += getCurrentDateInGermanFormat().replaceAll('.', '_')
        return fname;
    }

    return (
        isConfirmationViewOpen &&
        <div className="in-form-file-name">
            <h2 className="title">Dateiname</h2>
            <h5 className="file-name">{genFileName(data)}</h5>
            <p>
                Die Datei wird mit diesem Namen gespeichert.
                <br />
                Auf der Plattform wird jedoch nur der angegebene Titel angezeigt.
            </p>
        </div>
    )
}

export default DocNameDisplay
