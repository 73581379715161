import React from 'react';
import { Outlet } from 'react-router-dom';

import PPMcNavbar from '../../navbars/PPMcNavbar';
import GastroPPFoodOffersContextProvider from './context-provider/GastroPPFoodOffersContextProvider';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';

const GastroPPFoodOffersRegular = () => {
    useSetBrowserTabTitle('Angebote', true)
    return (
        <GastroPPFoodOffersContextProvider>
            <PPMcNavbar str_dataKey='foodOffersRegular' />
            <Outlet />
        </GastroPPFoodOffersContextProvider>
    )
}

export default GastroPPFoodOffersRegular
