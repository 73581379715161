import React from 'react';
import PropTypes from 'prop-types';

import SbToggle from './SbToggle'
import { useIsOpenContext, useSbRefContext } from './context-provider/SbAnimationContextProvider';

const Sidebar = ({
    str_classes,
    b_hasDarkDesign=false,
    children
}) => {
    const isOpen = useIsOpenContext()
    const sbRef = useSbRefContext()
    return (
        <div
            ref={sbRef}
            className={`
                sb ${str_classes} ${isOpen ? '' : 'sb-inactive'}
                ${b_hasDarkDesign ? 'dark' : ''}
            `}>
            <div className="sb-wrapper">
                <SbToggle />
                {children}
            </div>
        </div>
    )
}

Sidebar.propTypes = {
    str_classes: PropTypes.string,
    b_hasDarkDesign: PropTypes.bool
}

export default Sidebar
