/**
 * This component handles the food offers (regular/dgfs) on the student profile page.
 * 
 * The data format of regular offers and dgfs offers is different.
 * 
 * Regular offers are stored in an object that has 7 keys (0 - 6) representing the weekdays (Monday = 0).
 * Each value is an array which stores JS objects. Each object represents one food offer.
 * 
 * Dgfs offers are stored in an array. Each item is one offer, which is a JS object.
 * 
 * This difference in data structures is the reason why the below code differentiates between
 * dgfs and regular offers.
 */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import CSecGridBase from '../../content-sections/CSecGridBase';
import PPMainContentBase from '../PPMainContentBase';
import NoPPDataAvailable from '../NoPPDataAvailable';
import CardFood from '../../cards/food/CardFood';
import Modal from '../../modal/Modal';

import {
    useOffersPresentContext,
    useOffersFutureContext,
    useSetOffersPresentContext,
    useSetOffersFutureContext
} from './context-provider/StudentPPFoodOffersContextProvider';
import { useIsModalOpenContext, useModalObjectId } from '../../modal/context-provider/ModalContextProvider';
import { gridColsFromNumItems } from '../../../util/data_handler';

const WEEKDAYS = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];

const StudentPPFoodOffers = ({
    b_isDgfs=false,
    b_isPresent=true
}) => {
    
    const [hasOffers, setHasOffers] = useState()
    const [modalItem, setModalItem] = useState()
    const [isRedeemSuccess, setIsRedeemSuccess] = useState()

    const isModalOpen = useIsModalOpenContext()
    const modalObjectId = useModalObjectId()
    const setOffersPresent = useSetOffersPresentContext()
    const setOffersFuture = useSetOffersFutureContext()
    const data = {
        present: useOffersPresentContext(),
        future: useOffersFutureContext()
    }
    const time = b_isPresent ? 'present' : 'future'
    const numGridCols = gridColsFromNumItems(2)

    useEffect(() => {
        /**
         * Checks if at least one offer exists for 'present'/'future'.
         * Whenever the component is switched between present/future, check 
         * if there are offers available.
         * If the data of the context provider changes (switch between dgfs and regualar),
         * check it as well.
         * Sets the state of hasOffers accordingly.
         */
        let b_hasOffers = false
        if (b_isDgfs) {
            /* Check dgfs array for entries. */
            b_hasOffers = data[time][0] === undefined ? false : true
        } else {
            /* Iterate over weekday dict. */
            if (data[time]) {
                Object.values(data[time]).forEach(offer => {
                    if (offer[0] !== undefined)
                        b_hasOffers = true
                })
            }
        }
        setHasOffers(b_hasOffers)
    }, [b_isPresent, data])

    /* Offer redemption. */

    useMemo(() => {
        /**
         * Get the modal item from all the available offers of the context provider.
         */
        if (b_isDgfs) {
            setModalItem(data[time].filter(item => item.id === modalObjectId)[0])
        } else {
            const limit = Object.keys(data[time]).length
            for (let i = 0; i < limit; i++) {
                const item = data[time][i].filter(item => item.id === modalObjectId)[0]
                /* Stop search if item is found. */
                if (item !== undefined) {
                    setModalItem(item)
                    break
                }
            }
        }
    }, [modalObjectId])

    useMemo(() => {
        /**
         * Remove the redeem try success/fail outcome when the modal is closed.
         * 'undefined' is the init state when the user interaction with the modal starts.
         */
        if (!isModalOpen) setIsRedeemSuccess(undefined)
    }, [isModalOpen])

    const redeem = (response) => {
        /**
         * Receives the successful response from the redemption request and
         * updatest the states of the food offers.
         * :Input
         *  id (nbr): Id of the food offer.
         *  response (obj): Data received from the backend after form submission.
         */
        modalItem.footer.isRedeemed = true
        modalItem.footer.redeemTimestamp = response.data.redeemTimestamp
        modalItem.footer.redeemUserFullName = response.data.userFullName
        modalItem.footer.redeemCheckCode = response.data.redeemCheckCode

        let newData
        if (b_isDgfs) {
            newData = data[time].map(item => item.id === modalItem.id ? modalItem : item)
        } else {
            newData = {}
            const limit = Object.keys(data[time]).length
            for (let i = 0; i < limit; i++)
                newData[i] = data[time][i].map(item => item.id === modalItem.id ? modalItem : item)
        }
        
        if (b_isPresent) setOffersPresent(newData)
        else setOffersFuture(newData)

        setIsRedeemSuccess(true)
    }

    return (
        <>
            {
                /* Display of the student's food offers. */
                hasOffers
                ?
                    b_isDgfs
                    ?
                    /* Prevent the code from running if there is no data. This is only relevant
                     * for the rendering that takes place directly before the DB query. The data
                     * has not yet changed between the formats of dgfs and regular; prevent any 
                     * rendering of the data before the query is done running. */
                    Array.isArray(data[time]) && data[time][0] !== undefined &&
                    <CSecGridBase
                        str_title='Angebote'
                        str_gridClass={`card-grid ${numGridCols}`}
                        str_secBaseClasses='pp-mc-section'
                    >
                        {
                            data[time].map((offer, index) => (
                                <CardFood
                                    key={index}
                                    nbr_id={offer.id}
                                    str_cardClass={`card-food ${b_isDgfs ? 'do-gfs' : ''}`}
                                    str_title={offer.title}
                                    b_hasRedeemView={true}
                                    b_isDgfs={b_isDgfs}
                                    str_eyebrow={offer.provider}
                                    obj_bodyData={offer.body}
                                    obj_footerData={offer.footer}
                                />
                            ))
                        }
                    </CSecGridBase>
                    :
                    WEEKDAYS.map((header, headerIndex) => (
                        !Array.isArray(data[time]) && /* Prevent arrays, this is during first render before query. */
                        data[time][0] && /* Make sure value is not an empty array (no data available). */
                        data[time][headerIndex][0] !== undefined && /* Empty days are not rendered. */
                        <CSecGridBase
                            key={headerIndex}
                            str_title={header}
                            str_gridClass={`card-grid ${numGridCols}`}
                            str_secBaseClasses='pp-mc-section'
                        >
                            {
                                data[time][headerIndex].map((offer, index) => (
                                    <CardFood
                                        key={index}
                                        nbr_id={offer.id}
                                        str_cardClass={`card-food ${b_isDgfs ? 'do-gfs' : ''}`}
                                        str_title={offer.title}
                                        b_hasRedeemView={true}
                                        b_isDgfs={b_isDgfs}
                                        str_eyebrow={offer.provider}
                                        obj_bodyData={offer.body}
                                        obj_footerData={offer.footer}
                                    />
                                ))
                            }
                        </CSecGridBase>

                    ))
                :
                hasOffers === false
                ?
                    <PPMainContentBase b_hasCenteredText={true}>
                        <NoPPDataAvailable />
                    </PPMainContentBase>
                :
                '' /* hasOffers is 'undefined' (first render) */
            }
            {
                /* Modal view of a card if it is supposed to be redeemed. */
                isModalOpen &&
                <Modal b_isSuccess={isRedeemSuccess}>
                    <CardFood
                        nbr_id={modalItem ? modalItem.id : -1} /* -1 is a placeholder, never used */
                        str_title={modalItem ? modalItem.title : ''}
                        str_eyebrow={modalItem ? modalItem.provider : ''}
                        str_cardClass={`card-food ${b_isDgfs ? 'do-gfs' : ''}`}
                        obj_bodyData={modalItem ? modalItem.body : {}}
                        obj_footerData={modalItem ? modalItem.footer : {}}
                        b_isDgfs={b_isDgfs}
                        b_hasRedeemView={true}
                        b_hasModalView={true}
                        b_isRedeemSuccess={isRedeemSuccess}
                        fct_handleRedeem={redeem}
                    />
                </Modal>
            }
        </>
    )
}

StudentPPFoodOffers.propTypes = {
    b_isDgfs: PropTypes.bool,
    b_isPresent: PropTypes.bool
}

export default StudentPPFoodOffers
