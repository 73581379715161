import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import InFieldsGroup from '../../../InFieldsGroup';
import FormBase from '../../../FormBase';
import TextField from '../../../fields/TextField';
import NumberField from '../../../fields/NumberField';
import FileField from '../../../fields/FileField';
import SingleChoiceField from '../../../fields/SingleChoiceField';
import MultipleChoiceField from '../../../fields/MultipleChoiceField';
import BoolField from '../../../fields/BoolField';
import DateField from '../../../fields/DateField';
import DateSpanFields from '../../../fields/DateSpanFields';
import JobFormLocationComponent from '../../../form-components/JobFormLocationComponent';
import JobFormContactPersonFormComponent from '../../../form-components/JobFormContactPersonFormComponent';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { IndustryJobFormData as FormData } from '../../../util/form-config-data/InputFormIndustryDataConfigs';
import { addDays2BrowserDateString } from '../../../../../util/date_time_handler';
import { MAX_NUM_ONLINE_DAYS } from '../../../util/form-config-data/InputFormIndustryDataConfigs';

const InputFormIndustryJob = ({
    str_title='Neuer Job',
    str_subtitle='',
    arr_headerInfos=[]
}) => {

    const [postDeadlineDatesConfig, setPostDeadlineDatesConfig] = useState({})
    const [startDateConfig, setStartDateConfig] = useState({})
    const params = useParams();
    const data = useInputData()

    useEffect(() => {
        const configPostDeadlineStartDates = () => {
            /**
             * Handles configuration of fields for edit/reuse of a job. 
             * 
             * Generate the correct configs for post, deadline, and start date depending
             * on wether a new job is created, or already created ones are editted or reused.
             * 
             * I.e. the configuration of the fields is adjusted here.
             * The data adjustment is done in 'IndustryPPJobForm.js'.
             */
            if (params.category && params.category === 'online') {
                let obj_postDeadlineDatesConfig, obj_startDateConfig
                /* Post and Deadline dates. */
                obj_postDeadlineDatesConfig = {...FormData.fields.postDeadlineDates}
                /* Fix the min/max Date values of postDate to the current post date so 
                    * that the user cannot change it. */
                const str_currentPostDate = data[FormData.fields.postDeadlineDates.str_id][0][0]
                obj_postDeadlineDatesConfig.str_startMinDate = str_currentPostDate
                obj_postDeadlineDatesConfig.str_startMaxDate = str_currentPostDate
                /* Fix the min deadlineDate to the current deadlineDate so that the user 
                    * cannot reduce the time limit.
                    * Set the tmax deadlineDate to current postDate + 120 d. */
                obj_postDeadlineDatesConfig.str_endMinDate = 
                    data[FormData.fields.postDeadlineDates.str_id][0][1]
                obj_postDeadlineDatesConfig.str_endMaxDate = addDays2BrowserDateString(
                    str_currentPostDate, MAX_NUM_ONLINE_DAYS
                )
                
                /* Job start date. */
                obj_startDateConfig = {...FormData.fields.startDate}
                obj_startDateConfig.str_minDate = addDays2BrowserDateString(
                    str_currentPostDate, 1 /* Start date can be one day after the post date. */
                )

                return {
                    obj_postDeadlineDatesConfig: obj_postDeadlineDatesConfig,
                    obj_startDateConfig: obj_startDateConfig
                }
            } else {
                /* Params category does not exist or was provided false, use static configs. */
                return {
                    obj_postDeadlineDatesConfig: {...FormData.fields.postDeadlineDates},
                    obj_startDateConfig: {...FormData.fields.startDate}
                }
            }
        }

        /* Set the date limits on mount. This function must not be executed again, as 
         * the values are taken from the context provider data, which is only correct
         * for the first rendering. After the user changes the data, the date limits
         * must not be adjusted to the altered data, but kept the same as for the first render. */
        const { obj_postDeadlineDatesConfig, obj_startDateConfig } = configPostDeadlineStartDates()
        setPostDeadlineDatesConfig(obj_postDeadlineDatesConfig)
        setStartDateConfig(obj_startDateConfig)
    }, [])

    return (
        <FormBase
            str_title={str_title}
            str_subtitle={str_subtitle}
            arr_headerInfos={arr_headerInfos}
            b_hasFileField={true}
        >
            {
                /* Post dates. */
                (params.category === undefined || params.category !== 'online') &&
                <InFieldsGroup>
                    <DateSpanFields
                        {...postDeadlineDatesConfig} /* Config depends on edit, reuse or blank. */
                        str_startDate={data[FormData.fields.postDeadlineDates.str_id][0][0]}
                        str_endDate={data[FormData.fields.postDeadlineDates.str_id][0][1]}
                    />
                </InFieldsGroup>
            }

            {/* General job infos. */}
            <InFieldsGroup str_title='Allgemeine Infos'>
                <TextField
                    {...FormData.fields.positionUrl}
                    str_chars={data[FormData.fields.positionUrl.str_id][0]}
                />
                <BoolField
                    {...FormData.fields.isInternship}
                    b_boolValue={data[FormData.fields.isInternship.str_id][0]}
                />
                <BoolField
                    {...FormData.fields.isPaid}
                    b_boolValue={data[FormData.fields.isPaid.str_id][0]}
                    b_isFieldDisplayed={data[FormData.fields.isInternship.str_id][0] === true}
                />
                <MultipleChoiceField
                    {...FormData.fields.thesisOptions}
                    arr_currentOptions={data[FormData.fields.thesisOptions.str_id][0]}
                />
                <DateField
                    {...startDateConfig} /* Config depends on edit, reuse or blank. */
                    str_date={data[FormData.fields.startDate.str_id][0]}
                />
                <BoolField
                    {...FormData.fields.isStartDateFlexible}
                    b_boolValue={data[FormData.fields.isStartDateFlexible.str_id][0]}
                />
                <SingleChoiceField
                    {...FormData.fields.timeModel}
                    str_currentOption={data[FormData.fields.timeModel.str_id][0]}
                />
                <SingleChoiceField
                    {...FormData.fields.duration}
                    str_currentOption={data[FormData.fields.duration.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.numCompletedSemesters}
                    nbr_value={data[FormData.fields.numCompletedSemesters.str_id][0]}
                />
                <SingleChoiceField
                    {...FormData.fields.focusArea}
                    str_currentOption={data[FormData.fields.focusArea.str_id][0]}
                />
                <MultipleChoiceField
                    {...FormData.fields.applicationDocs}
                    arr_currentOptions={data[FormData.fields.applicationDocs.str_id][0]}
                />
            </InFieldsGroup>

            {/* Job description. */}
            <InFieldsGroup str_title='Stellenbeschreibung'>
                <TextField
                    {...FormData.fields.title}
                    str_chars={data[FormData.fields.title.str_id][0]}
                />
                <TextField
                    {...FormData.fields.intro}
                    str_chars={data[FormData.fields.intro.str_id][0]}
                />
            </InFieldsGroup>

            {/* Location. */}
            <InFieldsGroup str_title='Standort'>
                <JobFormLocationComponent />
            </InFieldsGroup>

            {/* Contact person. */}
            <InFieldsGroup str_title='Kontaktperson'>
                <JobFormContactPersonFormComponent />
            </InFieldsGroup>

            {/* Job file. */}
            <InFieldsGroup b_hasBorder={false}>
            <FileField
                {...FormData.fields.jobFile}
                inFile={data[FormData.fields.jobFile.str_id][0]}
            />
            </InFieldsGroup>

            {/* Responsibilities. */}
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.responsibility0}
                    str_chars={data[FormData.fields.responsibility0.str_id][0]}
                />
                <TextField
                    {...FormData.fields.responsibility1}
                    str_chars={data[FormData.fields.responsibility1.str_id][0]}
                />
                <TextField
                    {...FormData.fields.responsibility2}
                    str_chars={data[FormData.fields.responsibility2.str_id][0]}
                />
                <TextField
                    {...FormData.fields.responsibility3}
                    str_chars={data[FormData.fields.responsibility3.str_id][0]}
                />
            </InFieldsGroup>

            {/* Required skills. */}
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.reqSkill0}
                    str_chars={data[FormData.fields.reqSkill0.str_id][0]}
                />
                <TextField
                    {...FormData.fields.reqSkill1}
                    str_chars={data[FormData.fields.reqSkill1.str_id][0]}
                />
                <TextField
                    {...FormData.fields.reqSkill2}
                    str_chars={data[FormData.fields.reqSkill2.str_id][0]}
                />
                <TextField
                    {...FormData.fields.reqSkill3}
                    str_chars={data[FormData.fields.reqSkill3.str_id][0]}
                />
            </InFieldsGroup>

            {/* Asset skills. */}
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.assetSkill0}
                    str_chars={data[FormData.fields.assetSkill0.str_id][0]}
                />
                <TextField
                    {...FormData.fields.assetSkill1}
                    str_chars={data[FormData.fields.assetSkill1.str_id][0]}
                />
                <TextField
                    {...FormData.fields.assetSkill2}
                    str_chars={data[FormData.fields.assetSkill2.str_id][0]}
                />
                <TextField
                    {...FormData.fields.assetSkill3}
                    str_chars={data[FormData.fields.assetSkill3.str_id][0]}
                />
            </InFieldsGroup>

            {/* Additional infos. */}
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.addInfo}
                    str_chars={data[FormData.fields.addInfo.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

InputFormIndustryJob.protoTypes = {
    str_title: PropTypes.string,
    str_subtitle: PropTypes.string,
    arr_headerInfos: PropTypes.array
}

export default InputFormIndustryJob
