import React from 'react';

import FormBase from '../../../FormBase';
import InFieldsGroup from '../../../InFieldsGroup';
import BoolField from '../../../fields/BoolField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { PrivacyTermsFormData as FormData } from '../../../util/form-config-data/InputFormAccountSettingsDataConfigs';

const InputFormPrivacyTermsAgreement = () => {
    const data = useInputData()
    return (
        <FormBase
            str_title='Datenschutz & Nutzungsbedingungen'
            b_hasPrivacyTerms={true}
        >
            <InFieldsGroup b_hasBorder={false}>
                <BoolField
                    {...FormData.fields.privacy}
                    b_boolValue={data[FormData.fields.privacy.str_id][0]}
                />
                <BoolField
                    {...FormData.fields.terms}
                    b_boolValue={data[FormData.fields.terms.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormPrivacyTermsAgreement
