import React from 'react';

import FormBase from '../../../input-forms/FormBase';
import InFieldsGroup from '../../../input-forms/InFieldsGroup';
import TextField from '../../../input-forms/fields/TextField';
import SingleChoiceField from '../../../input-forms/fields/SingleChoiceField';
import DateField from '../../../input-forms/fields/DateField';
import JobCountyDistrictFields from '../../../input-forms/fields/JobCountyDistrictFields';

import { useInputData } from '../../../input-forms/context-provider/InputFormContextProvider';
import { FilterFormIndustryJobData as FormData } from '../../../input-forms/util/form-config-data/InputFormFilterDataConfigs';

const FilterFormJob = () => {
    const data = useInputData()
    return (
        <FormBase
            b_isFilterForm={true}
            b_hasFilterGrid={true}
        >
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.companyName}
                    str_chars={data[FormData.fields.companyName.str_id][0]}
                />
                <SingleChoiceField
                    {...FormData.fields.isInternship}
                    str_currentOption={data[FormData.fields.isInternship.str_id][0]}
                />
                <SingleChoiceField
                    {...FormData.fields.thesisOptions}
                    str_currentOption={data[FormData.fields.thesisOptions.str_id][0]}
                />
                <DateField
                    {...FormData.fields.startDate}
                    str_date={data[FormData.fields.startDate.str_id][0]}
                    b_isRequired={false}
                />
                <SingleChoiceField
                    {...FormData.fields.timeModel}
                    str_currentOption={data[FormData.fields.timeModel.str_id][0]}
                />
                <JobCountyDistrictFields
                    str_isAbroadFieldId={FormData.fields.isAbroad.str_id}
                    str_countyFieldId={FormData.fields.county.str_id}
                    str_districtFieldId={FormData.fields.district.str_id}
                    str_currentAbroadJobOption={data[FormData.fields.isAbroad.str_id][0]}
                    str_currentCountyOption={data[FormData.fields.county.str_id][0]}
                    str_currentDistrictOption={data[FormData.fields.district.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default FilterFormJob
