import React from 'react';
import PropTypes from 'prop-types';

import FormBase from '../../FormBase';
import InFieldsGroup from '../../InFieldsGroup';
import TextField from '../../fields/TextField';
import NumberField from '../../fields/NumberField';
import SingleChoiceField from '../../fields/SingleChoiceField';
import FormBaseContextProvider from '../../context-provider/FormBaseContextProvider';

import { useInputData } from '../../context-provider/InputFormContextProvider'; 
import { CouponsFormData as FormData } from '../../util/form-config-data/InputFormCouponDataConfigs';

const INFO_MSG = 'Nach drücken von \'Eingabe\' werden die eingegebenen Daten noch einmal ' +
    'zusammengefasst angegeben und dir gezeigt. Solltest du einen Fehler finden, kannst du wieder ' +
    'auf diese Seite zurückkehren. Danach ist keine Änderung mehr möglich.';

const InputFormCoupon = ({ arr_semOptions }) => {
    const data = useInputData()
    return (
        <FormBaseContextProvider>
            <FormBase
                str_title='Neuer Gutschein'
                arr_headerInfos={[INFO_MSG]}
            >
                <InFieldsGroup b_hasBorder={false}>
                    <TextField
                        {...FormData.fields.title}
                        str_chars={data[FormData.fields.title.str_id][0]}
                    />
                    <TextField
                        {...FormData.fields.description}
                        str_chars={data[FormData.fields.description.str_id][0]}
                    />
                    <NumberField
                        {...FormData.fields.price}
                        nbr_value={data[FormData.fields.price.str_id][0]}
                    />
                    <NumberField
                        {...FormData.fields.numAvailable}
                        nbr_value={data[FormData.fields.numAvailable.str_id][0]}
                    />
                    {
                        arr_semOptions
                        ?
                        <SingleChoiceField
                            {...FormData.fields.semester}
                            str_currentOption={data[FormData.fields.semester.str_id][0]}
                            arr_options={arr_semOptions}
                        />
                        :
                        <SingleChoiceField
                            {...FormData.fields.semester}
                            str_currentOption={data[FormData.fields.semester.str_id][0]}
                        />
                    }
                </InFieldsGroup>
            </FormBase>
        </FormBaseContextProvider>
    )
}

InputFormCoupon.propTypes = {
    arr_semOptions: PropTypes.array
}

export default InputFormCoupon
