import React, { useState, useEffect } from 'react';

import BoolField from '../fields/BoolField';
import LocationFormComponent from './LocationFormComponent';

import {
    useInputData,
    useChangeData
} from '../context-provider/InputFormContextProvider';
import { triggerFieldErrors } from '../../../util/dom_interaction';
import { IndustryJobFormData as FormData } from '../util/form-config-data/InputFormIndustryDataConfigs';

const LOCATION_FIELD_IDS = [
    'country',
    'county',
    'district',
    'streetName',
    'streetNum',
    'postalCode',
    'cityName'
];

const USE_COMPANY_LOCATION_ID = 'useCompanyLocationAsJobLocation';

const JobFormLocationComponent = () => {

    const [errorCache, setErrorCache] = useState()
    const data = useInputData()
    const changeData = useChangeData()

    useEffect(() => {
        const useCompanyLocation = data[FormData.fields.useCompanyLocationAsJobLocation.str_id][0]
        if (useCompanyLocation) {
            let locationData = {}
            let errorCache = {}
            LOCATION_FIELD_IDS.forEach(fieldId => {
                locationData[fieldId] = [data[fieldId][0], false]
                errorCache[fieldId] = [data[fieldId][0], data[fieldId][1]]
            })
            changeData(locationData)
            setErrorCache(errorCache)
        } else if (errorCache) {
            let locationData = {}
            LOCATION_FIELD_IDS.forEach(fieldId => {
                locationData[fieldId] = errorCache[fieldId]
            })
            triggerFieldErrors(LOCATION_FIELD_IDS, FormData.fields.useCompanyLocationAsJobLocation.str_id)
            changeData(locationData)
        }
    }, [data[USE_COMPANY_LOCATION_ID]])
    
    return (
        <>
            <BoolField
                {...FormData.fields.useCompanyLocationAsJobLocation}
                b_boolValue={data[FormData.fields.useCompanyLocationAsJobLocation.str_id][0]}
            />
            <LocationFormComponent
                b_hasCountyDistrictOptions={true}
                obj_fields={FormData.fields}
                b_areFieldsDisplayed={!data[FormData.fields.useCompanyLocationAsJobLocation.str_id][0]}
            />
        </>
    )
}

export default JobFormLocationComponent
