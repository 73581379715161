import React from 'react';

import MessagesContextProvider from '../context-provider/MessagesContextProvider';
import MessageContextProvider from '../context-provider/MessageContextProvider';
import Message from '../Message';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import MessageStdBody from '../MessageStdBody';
import DocInfoMessageBody from '../docs/DocInfoMessageBody';
import QuestionMessageBody from './QuestionMessageBody';
import MessageStudyMaterialFooter from '../MessageStudyMaterialFooter';
import DynamicContentLoadBtn from '../../buttons/DynamicContentLoadBtn';

import { useFilterStateContext } from '../../filters/context-provider/FilterStateContextProvider';
import {
    useQueriedDataContext,
    useIsFirstFetchSuccessContext,
    useIsFirstFilterQueryExecutedContext
} from '../../context-provider/DynamicContentLoadContextProvider';

import { inputForm2dbData } from '../../input-forms/util/input_form_data_handler';
import AuthService from '../../../util/authentication/auth_service';

const VOTE_BASE_URLS = {
    'Unterlagen': '/api/coursedocs/vote/',
    'Prüfungen' : '/api/courseexams/questions/variants/vote/variant/',
    'Übungen'   : '/api/courseexercises/questions/variants/vote/variant/',
    'Forum'     : '/api/courseforums/vote/'
};

const MESSAGE_BODY_COMPONENT = {
    'Unterlagen': <DocInfoMessageBody />,
    'Prüfungen' : <QuestionMessageBody />,
    'Übungen'   : <QuestionMessageBody />,
    'Forum'     : <MessageStdBody />
};

const StudyMaterialMessages = () => {

    const user = AuthService.getUser()
    const msgs = useQueriedDataContext()
    const isFirstMsgsFetchSuccessRef = useIsFirstFetchSuccessContext()
    const isFirstFilterQueryExecutedRef = useIsFirstFilterQueryExecutedContext()
    const filterData = inputForm2dbData(useFilterStateContext())

    const courseMaterialCategory = filterData ? filterData['categories'] : undefined

    const hasMessageTextMarking = courseMaterialCategory === 'Forum'
    const bodyComponent = MESSAGE_BODY_COMPONENT[courseMaterialCategory]
    const voteBaseUrl = VOTE_BASE_URLS[courseMaterialCategory]

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstMsgsFetchSuccessRef.current}
            b_isFirstFilterQueryExecuted={isFirstFilterQueryExecutedRef.current}
            b_hasFilterOnlyQueries={true}
            firstQueryDataEntry={msgs[0]}
            b_isContentSection={true}
            b_isContentSectionForChildren={true}
            str_contentSectionClasses='discussion-content-section no-padding-top'
        >
            <MessagesContextProvider
                b_hasItems={!!msgs[0]}
                str_voteBaseUrl={voteBaseUrl}
            >
            {
                msgs.map((msg, index) => (
                    <MessageContextProvider
                        key={index}
                        obj_msg={msg}
                        b_isOwner={user.username === msg.username}
                    >
                        <Message
                            b_hasTextMarking={hasMessageTextMarking}
                            comp_body={bodyComponent}
                            comp_footer={<MessageStudyMaterialFooter />}
                        />
                    </MessageContextProvider>
                ))
            }
            </MessagesContextProvider>
            <DynamicContentLoadBtn />
        </DataStateCheckWrapper>
    )
}

export default StudyMaterialMessages
