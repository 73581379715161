import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import PPMainContentBase from '../PPMainContentBase';
import CSecGridBase from '../../content-sections/CSecGridBase';
import CardFood from '../../cards/food/CardFood';
import NoPPDataAvailable from '../NoPPDataAvailable';
import {
    useOffersThisWeekContext,
    useOffersNextWeekContext,
    useOffersRepeatingContext,
    useOffersOldContext
} from './context-provider/GastroPPFoodOffersContextProvider';
import {
    useDgfsTodayContext,
    useDgfsTomorrowContext,
    useDgfsOldContext
} from './context-provider/GastroPPDgfsContextProvider';
import { gridColsFromNumItems } from '../../../util/data_handler';

const WEEKDAYS = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];

const GastroPPFoodOffers = ({
    str_offerType='regular', /* Either 'regular' or 'dgfs' for data selection. */
    str_dataName             /* Key to select specific data from the context provider. */
}) => {

    const [data, setData] = useState()
    const [hasOffers, setHasOffers] = useState()
    const [hasMultipleSections, setHasMultipleSections] = useState()
    const [title, setTitle] = useState('')
    
    const arr_dataHooks = {
        regular: {
            thisWeek:  [useOffersThisWeekContext(),  true], /* [data, hasMultipleSections] */
            nextWeek:  [useOffersNextWeekContext(),  true],
            repeating: [useOffersRepeatingContext(), false, 'Wiederkehrende Angebote'], /* [data, hasMultipleSections, <CSecBase /> title] */
            old:       [useOffersOldContext(),       false, 'Alte Angebote']
        },
        dgfs: {
            today:     [useDgfsTodayContext(),    false, 'Angebote Heute'],
            tomorrow:  [useDgfsTomorrowContext(), false, 'Angebote Morgen'],
            old:       [useDgfsOldContext(),      false, 'Alte Angebote']
        }
    }

    const isDgfs = str_offerType === 'dgfs' ? true : false
    const numGridCols = gridColsFromNumItems(2)
    
    useEffect(() => {
        const arr = arr_dataHooks[str_offerType][str_dataName]
        setData(arr[0])

        if (arr[1]) {
            /* Check if offers exist. */
            if (!arr[0]) {
                setHasOffers(false)
                return
            }
            let b_hasOffers = false
            Object.values(arr[0]).forEach(item  => {
                if (item[0] !== undefined)
                    b_hasOffers = true
            })
            setHasOffers(b_hasOffers)
            setHasMultipleSections(true)
        } else {
            setHasOffers(arr[0] ? arr[0][0] !== undefined : false)
            setHasMultipleSections(false)
            setTitle(arr[2])
        }
    })

    return (
        hasOffers
        ?
            hasMultipleSections
            ?
            /* Regular offers. */
            WEEKDAYS.map((header, headerIndex) => (
                data[headerIndex][0] !== undefined &&
                <CSecGridBase
                    key={headerIndex}
                    str_title={header}
                    str_gridClass={`card-grid ${numGridCols}`}
                    str_secBaseClasses='pp-mc-section'
                >
                    {
                        data[headerIndex].map((offer, index) => (
                            <CardFood
                                key={index}
                                nbr_id={offer.id}
                                str_title={offer.title}
                                str_eyebrow={offer.provider}
                                str_cardClass={`card-food ${isDgfs ? 'do-gfs' : ''}`}
                                obj_bodyData={offer.body}
                                obj_footerData={offer.footer}
                                b_hasGastroInfoView={true}
                                b_isDgfs={isDgfs}
                            />
                        ))
                    }
                </CSecGridBase>
            ))
            :
            /* Dgfs offers. */
            <CSecGridBase
                str_title={title}
                str_gridClass={`card-grid ${numGridCols}`}
                str_secBaseClasses='pp-mc-section'
            >
                {
                    data.map((offer, index) => (
                        <CardFood
                            key={index}
                            nbr_id={offer.id}
                            str_title={offer.title}
                            str_eyebrow={offer.provider}
                            str_cardClass={`card-food ${isDgfs ? 'do-gfs' : ''}`}
                            b_hasGastroInfoView={str_dataName !== 'old' && str_dataName !== 'repeating'}
                            b_hasGastroRepeatView={str_dataName === 'repeating'}
                            b_isDgfs={isDgfs}
                            obj_bodyData={offer.body}
                            obj_footerData={offer.footer}
                        />
                    ))
                }
            </CSecGridBase>
        :
        hasOffers === false
        ?
            <PPMainContentBase b_hasCenteredText={true}>
                <NoPPDataAvailable />
            </PPMainContentBase>
        :
        '' /* hasOffers is 'undefined' (first render) */
    )
}

GastroPPFoodOffers.propTypes = {
    str_offerType: PropTypes.string,
    str_dataName: PropTypes.string.isRequired
}

export default GastroPPFoodOffers
