import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const BOTTOM_TEXT_LIM = 200; /* Threshold value for text shorting. */
const SHORT_TEXT_LEN = 80;   /* Hidden display length. */

const FieldWrapper = ({
    str_fieldId='', /* The id of the input field. */
    str_fieldTitle='',
    str_errorMsg='',
    str_topInfoText='',
    str_bottomInfoText='',
    str_classes='',
    b_displayBottomInfoText=false,
    b_isRequired=true,
    b_isFieldFocused, /* Is true if the input form field is focused. */
    children
}) => {

    const [shortInfoText, setShortInfoText] = useState('')
    const [isShortTextDisplayed, setIsShortTextDisplayed] = useState(false)
    /* Track number of user clicks on bottom text. */
    const clickCntRef = useRef(0)

    useEffect(() => {
        if (str_bottomInfoText.length > BOTTOM_TEXT_LIM) {
            setShortInfoText(str_bottomInfoText.slice(0, SHORT_TEXT_LEN) + '...')
            setIsShortTextDisplayed(true)
        }
    }, [str_bottomInfoText])

    useEffect(() => {
        /**
         * Expand the bottom info text if the input field is focused.
         * Shrink the bottom info text if the input field is blurred and
         * the number of clicks on the bottom info text is even. This ensures
         * that if the user expanded the text, it remains open after blurring.
         */
        if (b_isFieldFocused) {
            if (isShortTextDisplayed) setIsShortTextDisplayed(false)
        } else {
            if (shortInfoText && !(clickCntRef.current % 2)) setIsShortTextDisplayed(true)
        }
    }, [b_isFieldFocused])

    const display = () => {
        /**
         * Handle expansion and shrinkage of bottom info text.
         */
        if (!shortInfoText) return
        setIsShortTextDisplayed(!isShortTextDisplayed)
        clickCntRef.current++
    }
    
    return (
        <div
            id={str_fieldId ? `${str_fieldId}-field` : ''}
            className={`
                in-fields-group-item
                ${str_errorMsg ? 'in-field-error-active' : ''}
                ${str_classes}
            `}
        >
            {
                str_fieldTitle &&
                <div className="in-field-header">{str_fieldTitle}</div>
            }
            <div className="in-field-contents">
                {
                    str_topInfoText &&
                    <div className="in-field-top-info">
                        <p>{str_topInfoText}</p>
                    </div>
                }

                {/* Here comes the specific field content. */}
                {children}
                
                {
                    b_isRequired &&
                    <div className={`in-field-required ${b_isRequired ? "" : "hidden"}`}>pflichtfeld</div>
                }
                {
                    str_bottomInfoText
                    ?
                    b_displayBottomInfoText
                    ?
                    <div className="in-field-bottom-info">
                        <p>{str_bottomInfoText}</p>
                    </div>
                    :
                    <div className="in-field-bottom-info">
                        <p
                            className={`${shortInfoText ? 'bottom-info-is-expandable' : ''}`}
                            onClick={() => display()}
                        >
                            {isShortTextDisplayed ? shortInfoText : str_bottomInfoText}
                        </p>
                    </div>
                    :
                    ''
                }
            </div>
            {
                str_errorMsg &&
                <div className="err-msg">
                    <p>{str_errorMsg}</p>
                </div>
            }
        </div>
    )
}

FieldWrapper.propTypes = {
    str_fieldId: PropTypes.string,
    str_fieldTitle: PropTypes.string,
    str_errorMsg: PropTypes.string,
    str_topInfoText: PropTypes.string,
    str_bottomInfoText: PropTypes.string,
    str_classes: PropTypes.string,
    b_displayBottomInfoText: PropTypes.bool,
    b_isRequired: PropTypes.bool,
    b_isFieldFocused: PropTypes.bool
}

export default FieldWrapper
