import React, { useState, useRef } from 'react';

import DataStateCheckWrapper from '../general/DataStateCheckWrapper';
import PPDataGroup from './PPDataGroup';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';
import useGetPPdataGroupData from '../../custom-hooks/useGetPPdataGroupData';

import ContactData from './data/ContactData';
import AuthService from '../../util/authentication/auth_service';

const genComponentConfigForUserType = (str_userType) => {
    switch (str_userType) {
        case 'company':
            return {
                contactHeader: 'Firmenkontakt',
                contactEditUrl: 'industry/edit',
                url: '/api/profiles/company/profile-page/contact/'
            };
        case 'gastronomer':
            return {
                contactHeader: 'Betriebskontakt',
                contactEditUrl: 'gastronomer/edit',
                url: '/api/profiles/gastronomer/profile-page/contact/'
            };
        default:
            return null;
    }
}

const CATEGORY = 'contacts';

const PPContact = () => {

    const [data, setData] = useState(null)
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    const componentConfigRef = useRef(genComponentConfigForUserType(AuthService.getUsertype()))

    useSetBrowserTabTitle('Kontakt', true)
    useGetPPdataGroupData(componentConfigRef.current.url, CATEGORY, setData, setIsFirstFetchSuccess, setErrorMsg)

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            b_isContentSection={true}
            str_errorMsg={errorMsg}
            firstQueryDataEntry={1} /* Only placeholder value (data is always available here).*/
        >
            <PPDataGroup
                str_header={componentConfigRef.current.contactHeader}
                str_editUrl={componentConfigRef.current.contactEditUrl}
                obj_data={data ? data.entity : null}
                obj_dataStructure={ContactData.entity}
            />
            <PPDataGroup
                str_header='1. Kontaktperson'
                str_editUrl='contact-person-1/edit'
                obj_data={data ? data.contactPerson0 : null}
                obj_dataStructure={ContactData.person}
            />
            <PPDataGroup
                str_header='2. Kontaktperson'
                str_editUrl='contact-person-2/edit'
                obj_data={data ? data.contactPerson1 : null}
                obj_dataStructure={ContactData.person}
            />
        </DataStateCheckWrapper>
    )
}

export default PPContact
