/**
 * This file entails the functionality to serialize any kind of object.
 * 
 * The main function only requires the data of type object and a serializer config
 * object that provides the structure on how the data is supoosed to be serialized.
 * 
 * The configuration object must have the following form:
 * 
 * {
 *      // 1) Variant
 *      // Number that says how many of these items are prsent (e.g. assetSkill0, assetSkill1).
 *      // The key must match the base char sequence. (e.g. assetSkill).
 *      key: xxx (nbr),
 * 
 *      // 2) Variant
 *      // Array of keys that are used to extract values from the data to be serialized.
 *      key: [key0, key1, ... ],
 * 
 *      // 3) Variant
 *      // Array: The value of 'key' is returned like so: [ data[key] ]
 *      // Plain: The value of 'key' is returned like so: data[key]
 *      key: 'array' or 'plain'
 * 
 *      // 4) Variant
 *      // Value pair arrays that are used to return a title/data/linkText datastructure like so:
 *      // [ {title: ..., data: ..., linkText: ...}, ... ]
 *      // The linkText is only used if the data value is a URL (it is optional).
 *      key: [
 *          [title value, data value, (link text value)],
 *          ...
 *      ]
 * }
 * 
 * The return object has the following form:
 * 
 * {
 *      1) Variant
 *      key: {
 *          key0: value0,
 *          key1: value1,
 *          ...
 *      },
 *      2) Variant
 *      key: [value0, value1, ... ],
 *      
 *      3) Variant
 *      key: [ value ],
 *      key: value,
 * 
 *      4) Variant
 *      [
 *          {
 *              title: ...,
 *              data: ...,
 *              linkText: ...
 *          },
 *          ...
 *      ]
 * }
 */

import { formatPhoneNumber } from "../data_handler";

const keysSerializer = (data, keys) => {
    /**
     * Extracts items from 'data' for all the keys stored in 'keys'.
     * :Input
     *  data (obj): Variable that contains all the data.
     *  keys (arr): Entails strings which are the keys of data.
     * :Returns:
     *  Object: { keys[0]: ..., keys[1]: ..., ... }
     *  null  : In case of an error.
     */
    if (typeof(data) !== 'object' || !Array.isArray(keys)) return null;

    let robj = {};
    keys.forEach(key => {
        try {
            robj[key] = data[key];
        } catch {}
    });

    return robj;
}

const valueSeriesSerializer = (data, baseKey, numItems) => {
    /**
     * Serializes a series of data that has similar keys. E.g. assetSkill0 to assetSkill3.
     * :Input
     *  data (obj)
     *  baseKey (str): Key of the data series (e.g. assetSkill for assetSkillsXX)
     *  numItems (nbr): How many items are in this series? E.g. assetSkill0 to assetSkill3 (4 values).
     * :Returns
     *  Array: All the data of the different data entries of 'baseKey + numItem'.
     */
    if (
        typeof(data) !== 'object' ||
        typeof(baseKey) !== 'string' ||
        typeof(numItems) !== 'number'
    ) return [];
    
    /* Check if data is available. */
    let hasData = false;
    for (let i = 0; i < numItems; i++) {
        /* try as a key might not be present. */
        try {
            const key = baseKey + i;
            if (data[key] !== '' && data[key] !== null) {
                hasData = true;
                break;
            }
        } catch {}
    }
    if (!hasData) return [];

    const rarr = [];
    for (let i = 0; i < numItems; i++) {
        const key = baseKey + i;
        const d = data[key]
        if (d !== '' && d !== null) {
            rarr.push(d);
        }
    }
    return rarr;
}

const valueOrValueArrSerializer = (data, baseKey, valueString) => {
    /**
     * Gets either the plain value from data for baseKey or as value in array ([ data[baseKey] ])
     * :Input
     *  data (obj)
     *  baseKey (str): Key for value in data.
     *  valueString (str): Is either 'plain' or 'array'.
     * :Returns
     *  Value if valueString = 'plain'
     *  Array of value if valueString = 'array' ([ value ])
     */
    if (
        typeof(data) !== 'object' ||
        typeof(baseKey) !== 'string' ||
        typeof(valueString) !== 'string'
    ) return null;

    if (valueString === 'plain') {
        return data[baseKey];
    }
    else if (valueString === 'array') {
        return data[baseKey] ? [data[baseKey]] : null;
    } else {
        return null; /* Error flag. */
    }
}

const titleDataPairSerializer = (data, serializerLayout) => {
    /**
     * Creates title/data pairs from data.
     * A third entry is also present if the entry in data is a link. This 3rd element represents
     * the link text that is displayed on the front end, whereas the data value is the URL.
     * :Input
     *  serializerLayout (orray): [[value0, value1], ... ] (every item is array of length 2)
     * :Returns
     *  Array of objects: [ {title: ..., data: ...}, ... ]
     */
    let rarr = [];
    serializerLayout.forEach(item => {
        try {
            const entry = {
                title: item[0],
                data: data[item[1]],
                linkText: item[2]
            };
            rarr.push(entry)
        } catch {}
    })
    return rarr;
}

const serializeContact = (data, serializerLayout, hasSocialMedia=true) => {
    /**
     * Creates title/data pairs of contact data from data.
     * A 3rd enry also created, it is used for data that represents URLs.
     * This 3rd entry is the link text displayed on the front end.
     * :Input
     *  serializerLayout (orray): [[value0, value1, (value3)], ... ] (every item is array of length 2 or 3 (if url))
     *  hasSocialMedia (bool): If true data is searched for social media entries that are added.
     * :Returns
     *  Array of objects:
     *  [
     *      {title: ..., data: ..., linkText: ... },
     *      ...,
     *      {title: ..., data: {linkedin: ..., ... }, linkText: ...
     *  ]
     */
    let rarr = [];
    serializerLayout.forEach(item => {
        try {
            const entry = {
                title: item[0],
                data: item[1] === 'phone' ? formatPhoneNumber(data[item[1]]) : data[item[1]],
                linkText: item[2]
            };
            rarr.push(entry);
        } catch {}
    })

    /* Social media data. */
    if (!hasSocialMedia) return rarr;
    
    const smKeys = ['linkedin', 'twitter', 'facebook', 'instagram', 'tiktok'];
    let smObj = {};
    smKeys.forEach(smKey => {
        smObj[smKey] = data[smKey];
    })
    rarr.push({
        title: 'Social Media',
        data: smObj
    });

    return rarr;
}

const dataFromObjSerializer = (data, serializerLayout) => {
    /**
     * :Input
     *  data (obj): The data that is to be seriliazed.
     *  serializerLayout (obj): Structure on how the serialized data should look like.
     *      {
     *          baseKey: [key0, key1, ...], // If an object is returned.
     *          or
     *          baseKey: xxx (nbr) // If an array is returned (number of item series).
     *          or
     *          baseKey: 'plain' or 'array' // If plain value should be returned.
     *          or
     *          baseKey: [ [vlue0, value1], [value0, value1], ... ] // Arrays of value pairs.
     *      }
     */
    let robj = {};
    Object.keys(serializerLayout).forEach(baseKey => {
        if (typeof(serializerLayout[baseKey]) === 'number') {
            robj[baseKey] = valueSeriesSerializer(data, baseKey, serializerLayout[baseKey])
        }
        else if (Array.isArray(serializerLayout[baseKey])) {
            if (typeof(serializerLayout[baseKey][0]) === 'string') {
                robj[baseKey] = keysSerializer(data, serializerLayout[baseKey])
            } else {
                robj[baseKey] = titleDataPairSerializer(data, serializerLayout[baseKey])
            }
        }
        else if (typeof(serializerLayout[baseKey]) === 'string') {
            robj[baseKey] = valueOrValueArrSerializer(data, baseKey, serializerLayout[baseKey])
        }
    })
    return robj;
}

const dataFromObjSerializerMany = (data, serializerLayout) => {
    /**
     * Serializes the every item of data with 'dataFromObjSerializer'.
     * :Input
     *  data (arr): Entails multiple items for serialization.
     * :Returns
     *  Array of serialized items.
     */
    let serializedData = [];
    try {
        data.forEach(item => serializedData.push(dataFromObjSerializer(item, serializerLayout)))
    } catch {
        return null;
    }
    return serializedData;
}

const ObjectSerializer = {
    serialize: dataFromObjSerializer,
    serializeMany: dataFromObjSerializerMany,
    serializeContact: serializeContact
};

export default ObjectSerializer;