import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import PrivatePage from '../../components/network/PrivatePage';
import HeaderAndFooter from '../../components/page-layout/HeaderAndFooter';
import PageBase from '../../components/page-layout/PageBase';
import CSecBase from '../../components/content-sections/CSecBase';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const NotFoundPage = ({
    str_redirectUrl='',
    b_isOnlyContentSection=false
}) => {

    const navigate = useNavigate()

    useSetBrowserTabTitle('Gesuchte Seite existiert nicht')

    useEffect(() => {
        /**
         * Allows to avoid visiting this page and redirects the user to a new
         * target page instead.
         */
        if (str_redirectUrl) navigate(str_redirectUrl, { replace: true })
    }, [])

    return (
        !str_redirectUrl && (
            b_isOnlyContentSection
            ?
            <CSecBase
                str_title='Oops!'
                str_subtitle='Diese Seite existiert nicht ...'
            />
            :
            <PrivatePage b_checkAboValidity={false}>
                <HeaderAndFooter
                >
                    <PageBase>
                        <CSecBase
                            str_title='Oops!'
                            str_subtitle='Diese Seite existiert nicht ...'
                        />
                    </PageBase>
                </HeaderAndFooter>
            </PrivatePage>
        )
    )
}

NotFoundPage.propTypes = {
    str_redirectUrl: PropTypes.string,
    b_isOnlyContentSection: PropTypes.bool
}

export default NotFoundPage
