import React from 'react'
import PropTypes from 'prop-types'

const PanelTextHelpMessage = ({ arr_indicators=['all'] }) => {
    return (
        <div className="post-help-msg">
            {
                arr_indicators.length > 0 &&
                <div>
                    {/* Short description of the help text. */}
                    <p className="description-content">
                        Es ist möglich den Text mittels bestimmter Zeichen hervorzuheben.
                        Folgende Optionen stehen zum Hervorheben zur Verfügung. 
                        <br />
                        (1) Eingabe über die Tastatur. 
                        <br />
                        (2) Markieren des Textes und klicken der Symbole.
                        <br />
                        (3) Markieren des Textes und drücken einer Tastenkombination.
                    </p>
                </div>
            }
            {
                (arr_indicators.includes('all') || arr_indicators.includes('4')) &&
                <div>
                    <p className="description-header">B (Fett)</p>
                    <p className="description-content">
                        <span className="symbols">Zeichen: *% ... %*</span>
                        <span>(Short-cut: strg + 4)</span>
                    </p>
                </div>
            }
            {
                (arr_indicators.includes('all') || arr_indicators.includes('5')) &&
                <div>
                    <p className="description-header">I (Italic)</p>
                    <p className="description-content">
                        <span className="symbols">Zeichen: ~% ... %~</span>
                        <span>(Short-cut: strg + 5)</span>
                    </p>
                </div>
            }
            {
                (arr_indicators.includes('all') || arr_indicators.includes('7')) &&
                <div>
                    <p className="description-header">U (Underline)</p>
                    <p className="description-content">
                        <span className="symbols">Zeichen: _% ... %_</span>
                        <span>(Short-cut: strg + 7)</span>
                    </p>
                </div>
            }
            {
                (arr_indicators.includes('all') || arr_indicators.includes('8')) &&
                <div>
                    <p className="description-header">Link (Hyperlink)</p>
                    <p className="description-content">
                        <span className="symbols">Zeichen: &% ... %&</span>
                        <span>(Short-cut: strg + 8)</span>
                    </p>
                </div>
            }
            {
                (arr_indicators.includes('all') || arr_indicators.includes('9')) &&
                <div>
                    <p className="description-header">Code (Software code)</p>
                    <p className="description-content">
                        <span className="symbols">Zeichen: {'#% ... %#'}</span>
                        <span>(Short-cut: strg + 9)</span>
                    </p>
                </div>
            }
            {
                arr_indicators.length > 0 &&
                <div>
                    <p className="description-header">Tipp zum Merken der Short-cuts</p>
                    <p className="description-content description-(short-cuts">
                        Alle Short-cuts folgen dem Muster (strg + Ziffer). 
                        Die Position der Ziffern auf der Tastatur von links nach rechts 
                        entspricht der Reihenfolge der Symbole. 
                    </p>
                </div>
            }
            <div>
                <p className="description-header">Tipp zum Wechseln zwischen Edit. und Vorschau</p>
                <p className="description-content">
                    <span className="symbols">Wechsel zu Edit</span>
                    <span>(Short-cut: strg + ,)</span>
                </p>
                <p className="description-content">
                    <span className="symbols">Wechsel zur Vorschau</span>
                    <span>(Short-cut: strg + .)</span>
                </p>
            </div>
        </div>
    )
}

PanelTextHelpMessage.propTypes = {
    arr_indicators: PropTypes.array
}

export default PanelTextHelpMessage
