/**
 * This component handles message specific information and the appearance a messages.
 * I.e. what is displayed when (e.g. post input panels for edit/comment).
 */

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

const IsMsgBodyOpenContext = React.createContext();
const IsEditFormOpenContext = React.createContext();
const SetIsEditFormOpenContext = React.createContext();
const IsCommentFormOpenContext = React.createContext();
const SetIsCommentFormOpenContext = React.createContext();
const SetIsMsgBodyOpenContext = React.createContext();
const MsgContext = React.createContext();
const IsOwnerContext = React.createContext();
const RequestTypeContext = React.createContext();
const IsComment = React.createContext();

export function useIsMsgBodyOpenContext() {
    return useContext(IsMsgBodyOpenContext);
}

export function useIsEditFormOpenContext() {
    return useContext(IsEditFormOpenContext);
}

export function useSetIsEditFormOpenContext() {
    return useContext(SetIsEditFormOpenContext);
}

export function useIsCommentFormOpenContext() {
    return useContext(IsCommentFormOpenContext);
}

export function useSetIsCommentFormOpenContext() {
    return useContext(SetIsCommentFormOpenContext);
}

export function useSetIsMsgBodyOpenContext() {
    return useContext(SetIsMsgBodyOpenContext);
}

export function useMsgContext() {
    return useContext(MsgContext);
}

export function useIsMsgOwnerContext() {
    return useContext(IsOwnerContext);
}

export function useRequestTypeContext() {
    return useContext(RequestTypeContext);
}

export function useIsCommentContext() {
    return useContext(IsComment);
}

const MessageContextProvider = ({
    obj_msg,              /* Message data. */
    b_isMsgBodyOpen=true, /* true for reply messages (always open). */
    b_isOwner,            /* true if logged in user is author of this message. */
    b_isComment=false,    /* Is message comment of an other message. */
    children
}) => {

    const [isMsgBodyOpen, setIsMsgBodyOpen] = useState(b_isMsgBodyOpen)
    const [isEditFormOpen, setIsEditFormOpen] = useState(false)
    const [isCommentFormOpen, setIsCommentFormOpen] = useState(false)
    const [requestType, setRequestType] = useState()

    useEffect(() => {
        /**
         * Set the request type used for the network request.
         */
        if (b_isOwner) {
            /* If user owns the message, then he edits it. */
            setRequestType('put')
        } else {
            /* If user does not own the message, then he writes a new comment. */
            setRequestType('post')
        }
    }, [b_isOwner])
    
    return (
        <IsMsgBodyOpenContext.Provider value={isMsgBodyOpen}>
            <IsEditFormOpenContext.Provider value={isEditFormOpen}>
                <SetIsEditFormOpenContext.Provider value={setIsEditFormOpen}>
                    <IsCommentFormOpenContext.Provider value={isCommentFormOpen}>
                        <SetIsCommentFormOpenContext.Provider value={setIsCommentFormOpen}>
                            <SetIsMsgBodyOpenContext.Provider value={setIsMsgBodyOpen}>
                                <IsOwnerContext.Provider value={b_isOwner}>
                                    <RequestTypeContext.Provider value={requestType}>
                                        <MsgContext.Provider value={obj_msg}>
                                            <IsComment.Provider value={b_isComment}>
                                                {children}
                                            </IsComment.Provider>
                                        </MsgContext.Provider>
                                    </RequestTypeContext.Provider>
                                </IsOwnerContext.Provider>
                            </SetIsMsgBodyOpenContext.Provider>
                        </SetIsCommentFormOpenContext.Provider>
                    </IsCommentFormOpenContext.Provider>
                </SetIsEditFormOpenContext.Provider>
            </IsEditFormOpenContext.Provider>
        </IsMsgBodyOpenContext.Provider>
    )
}

MessageContextProvider.propTypes = {
    obj_msg: PropTypes.object.isRequired,
    b_isMsgBodyOpen: PropTypes.bool,
    b_isOwner: PropTypes.bool.isRequired,
    b_isComment: PropTypes.bool
}

export default MessageContextProvider
