import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import HeaderAndFooter from '../../../components/page-layout/HeaderAndFooter';
import SbProfilePage from '../../../components/sidebars/profile-pages/SbProfilePage';
import PPHeader from '../../../components/profile-pages/PPHeader';
import PPLayout from '../../../components/profile-pages/PPLayout';
import PrivatePage from '../../../components/network/PrivatePage';
import Usertypes from '../../../util/Usertypes';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';

const GastroPP = () => {
    const navigate = useNavigate()
    useSetBrowserTabTitle('', true)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [navigate])
    return (
        <PrivatePage arr_usertypes={[Usertypes.gastronomer]}>
            <HeaderAndFooter>
                <PPLayout sidebar={<SbProfilePage str_userCategory={Usertypes.gastronomer} />}>
                    <PPHeader />
                    <Outlet />
                </PPLayout>
            </HeaderAndFooter>
        </PrivatePage>
    )
}

export default GastroPP
