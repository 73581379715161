import React from 'react';
import PropTypes from 'prop-types';

import CardBase from './CardBase';
import CardBodyInfoList from './CardBodyInfoList';
import GridBase from '../grids/GridBase';

import { gridColsFromNumItems } from '../../util/data_handler';

const CardGridInfoList = ({
    arr_data,
    str_gridClass='card-grid'
}) => {
    
    const numGridCols = gridColsFromNumItems(arr_data.length)
    const gridClass = str_gridClass + ' ' + numGridCols

    return (
        <GridBase str_gridClass={gridClass}>
            {
                arr_data.map((item, index) => (
                    <div key={index} className="grid-item">
                        <CardBase
                            str_title={item.title}
                            comp_cardBody={<CardBodyInfoList arr_data={item.data} />}
                        />
                    </div>
                ))
            }
        </GridBase>
    )
}

CardGridInfoList.propTypes = {
    arr_data: PropTypes.array.isRequired,
    str_gridClass: PropTypes.string,
}

export default CardGridInfoList
