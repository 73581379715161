import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isActiveURLitem, string2URLstring } from '../../../../util/data_handler';
import { useRunAnimationContext } from '../../context-provider/SbAnimationContextProvider';

/* ID that identifies the active profile-page sidebar item. */
const ACTIVE_ITEM_ID = 'sb-item-active';

const SbDocsItem = ({ arr_data }) => {

    const location = useLocation()
    const history = useNavigate()
    const urlNameRef = useRef(arr_data[0])
    /* This line must come last! If it comes above the other two hooks, the page does not load. */
    const [isActive, setIsActive] = useState(() => isActiveURLitem(
        location.pathname, string2URLstring(arr_data[0])
    ))

    const runAnimation = useRunAnimationContext()

    useEffect(() => {
        if (isActiveURLitem(location.pathname, urlNameRef.current)) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [history])

    return (
        <Link to={string2URLstring(arr_data[0])}>
            <li
                className='sb-list-item sb-docs-item'
                id={`${isActive ? ACTIVE_ITEM_ID : ''}`}
                onClick={() => runAnimation()}
            >
                <div className='item-wrap'>
                    <div className='title'>{arr_data[0]}</div>
                    <div className='nbr'>{arr_data[1]}</div>
                </div>
            </li>
        </Link>
    )
}

SbDocsItem.propTypes = {
    arr_data: PropTypes.array.isRequired
}

export default SbDocsItem
