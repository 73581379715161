/**
 * This hook fetches the data for input forms on profile 
 * pages form the LS (if present) or from the DB.
 * 
 * It sets the dyn. config if the fetch is a success.
 * Otherwise, an error message is set. 
 * The state setting works via callbacks of the parent component.
 */

import { useEffect } from 'react';
import { fetchLSorDBdata } from '../util/db_ls_query_handler';
import { db2inputFormData } from '../components/input-forms/util/input_form_data_handler';
import { PPLsKeys } from '../util/LocalStorageVariables';

const PP_LS_KEY = PPLsKeys.primaryDataKey;

export default function useGetPPformData(
    str_queryURL,
    str_category,
    str_subcategory,
    fct_setFormData,
    fct_setIsFirstFetchSuccess,
    fct_setErrorMsg
) {
    useEffect(() => {
        const func = async () => {
            const queryData = await fetchLSorDBdata(str_queryURL, PP_LS_KEY, str_category, str_subcategory)
            if (queryData.isQuerySuccess) {
                const formData = genFormData(queryData.response.data)
                fct_setFormData(formData)
                fct_setIsFirstFetchSuccess(true)
            } else {
                fct_setErrorMsg(queryData.errorMsg)
                fct_setIsFirstFetchSuccess(false)
            }
        }
        func()
    }, [])

    const genFormData = (data) => {
        /* Converts the data received from LS or DB into input form format. */
        return {
            str_queryURL: str_queryURL,
            obj_initState: db2inputFormData(data)
        };
    }
}
