/**
 * Queries the data from the discussion cache (DiscussionCacheContextProvider.js)
 * or from the DB if it is not found in the cache.
 */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchDBdata } from '../util/db_ls_query_handler';
import {
    useGetDiscussionMsgContext,
    useUpdateDiscussionMsgsContext
} from '../components/discussion-pages/context-provider/DiscussionCacheContextProvider';

export default function useGetDiscussionCacheOrDBdata(
    nbr_id, /* Discussion msg id. */
    str_url,
    fct_setData,
    fct_setIsFirstFetchSuccess,
    fct_setErrorMsg,
    b_executeOnUrlChange=false
) {

    const getDiscussionMsg = useGetDiscussionMsgContext()
    const updateCache = useUpdateDiscussionMsgsContext()

    /* Decide if the react hook should only be executed on-moun or on every url change. */
    const activate = b_executeOnUrlChange ? [useNavigate()] : []

    useEffect(() => {
        if (!str_url) return

        const func = async () => {
            /* Fetch from discussion cache (if used). */
            if (getDiscussionMsg) {
                const data = getDiscussionMsg(nbr_id)
                if (data !== undefined) {
                    fct_setData(data)
                    fct_setIsFirstFetchSuccess(true)
                    return
                }
            }
            
            /* Fetch from DB. */
            const queryData = await fetchDBdata(str_url)
            if (queryData.isQuerySuccess) {
                if (updateCache) updateCache(queryData.response.data, nbr_id)
                fct_setData(queryData.response.data)
                fct_setIsFirstFetchSuccess(true)
            } else {
                fct_setIsFirstFetchSuccess(false)
                fct_setErrorMsg(queryData.errorMsg)
            }
        }

        func()
    }, activate)
}