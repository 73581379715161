import React from 'react';

import FormBase from '../../../FormBase';
import InFieldsGroup from '../../../InFieldsGroup';
import FileField from '../../../fields/FileField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { GastroFoodMenuFormData as FormData } from '../../../util/form-config-data/InputFormGastroDataConfigs';

const InputFormFoodMenus = () => {
    const data = useInputData()
    return (
        <FormBase str_title='Speisekarten'>
            <InFieldsGroup b_hasBorder={false}>
                <FileField
                    {...FormData.fields.foodMenu0}
                    inFile={data[FormData.fields.foodMenu0.str_id][0]}
                />
                <FileField
                    {...FormData.fields.foodMenu1}
                    inFile={data[FormData.fields.foodMenu1.str_id][0]}
                />
                <FileField
                    {...FormData.fields.foodMenu2}
                    inFile={data[FormData.fields.foodMenu2.str_id][0]}
                />
                <FileField
                    {...FormData.fields.foodMenu3}
                    inFile={data[FormData.fields.foodMenu3.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormFoodMenus
