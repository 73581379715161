/**
 * This component allows the user to type in a text and get prompted suggestion
 * that match the provided input text. The user must then select up to a provided
 * max number of suggestion before he submits the input form filter.
 * 
 * This component works with both the id and title/name of the backend data.
 * The title/name is displayed on the frontend and allows the user to understand
 * what is going on. However, only the ids are sent to the backend to speed up searches.
 */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { CgClose } from 'react-icons/cg';

import FieldWrapper from './FieldWrapper';
import InFieldProposal from '../InFieldProposal';

import {
    useAreRequiredErrorsActive,
    useHandleInputChange,
    useDiscardPressed
} from '../context-provider/InputFormContextProvider';
import { sendData2db } from '../../../util/db_ls_query_handler';

import FieldErrorMsgs from '../util/input_form_fields_error_msgs.json';

const REQUIRED_EMSG = FieldErrorMsgs.required;
const MIN_LEN_EMSG = FieldErrorMsgs.text.length.min;
const MAX_LEN_EMSG = FieldErrorMsgs.text.length.max;
const CHAR_RESTRICTION_INVALID_EMSG = FieldErrorMsgs.text.tags.format;
const DUPLICATE_EMSG = 'Diese Option ist bereits gewählt.';
const PROPOSAL_FORMAT_INCORRECT_EMSG = 'Es konnten keine Ähnlichen Begriffe geladen werden.';
const CLICKED_PROPOSAL_DOES_NOT_EXIST_EMSG = 'Die ausgewählte Option scheint nicht zu existieren.';
const COULD_NOT_BE_ADDED_EMSG = 'Diese Option konnte nicht ausgewählt werden.\n';

/* Wait time between changes of the input field for query of proposals. [ms] */
const QUERY_DECISION_DELAY = 180;

const alertProposalSelectionInvalid = (errorMsg) => {
    /**
     * Prompts the user an alert window with info why his proposal selection failed.
     */
    alert(COULD_NOT_BE_ADDED_EMSG + errorMsg);
}

const isCharRestrictionValid = (value, allowedChars) => {
    /**
     * Checks if 'value' contains only chars present in 'allowedChars'.
     */
    const limit = value.length;
    for (let i = 0; i < limit; i++) {
        const char = value.slice(i, i+1);
        if (!allowedChars.includes(char)) return false
    }
    return true;
}

const isValueAlreadySelected = (value, selectedValues, key) => {
    /**
     * Checks if value is in selectedValues.
     * :Input
     *  value (str): valueto be checked
     *  selectedValues (obj): {id: ... (nbr), name: ... (str)}
     */
    let isSelected = false;
    selectedValues.forEach(selectedValue => {
        if (value === selectedValue[key]) {
            isSelected = true;
        }
    })
    return isSelected;
}

const db2stateProposalFormat = (loadedProposals, charKey) => {
    /**
     * Converts the DB proposal format into the state proposal format.
     * The formats differ in the name of the key that stores the char value
     * (e.g. the name of some course).
     * :Input
     *  loadedProposals (arr): Array of objects that are in DB format.
     *      {
     *          id: ... (int),
     *          <someDbKey>: ... (str) // Char value of the item.
     *      }
     *  charKey (str): Key of the proposal chars.
     * :Returns
     *  Loaded proposals in the correct state format using the correct chars key.
     *      {
     *          id: ... (int),     // Same as DB id.
     *          charKey: ... (str) // Char value of the item.
     *      }
     */
    const firstProposal = loadedProposals[0];
    if (!firstProposal) return [];

    /* Get the db char key. */
    let dbKey;
    let numProposalKeys = 0;
    Object.keys(firstProposal).forEach(key => {
        if (key !== 'id') {
            dbKey = key;
        }
        numProposalKeys++;
    })

    if (numProposalKeys !== 2) return []; /* Only 2 keys expected. */

    /* Create proposals of state format. */
    let loadedProposalsStateFormat = [];
    loadedProposals.forEach(proposal => {
        let proposalStateFormat = { id: proposal.id };
        proposalStateFormat[charKey] = proposal[dbKey];
        loadedProposalsStateFormat.push(proposalStateFormat);
    })

    return loadedProposalsStateFormat;
}

const SelectiveProposalField = ({
    str_id,
    str_fieldTitle,
    str_bottomInfoText,
    str_proposalQueryUrl,
    str_requestDataKey='proposal', /* Key that is used to query the backend for proposals. */
    str_charKey='name',  /* Key to retrieve the char value of proposed items (e.g. name or title). */
    int_maxNumProposals, /* Number of suggested values that can be searchs simultaneously at most. */
    int_minLength,
    int_maxLength,
    arr_proposals=[],    /* Ability to provide already selected proposals. */
    arr_allowedChars,    /* Array of strings, which represent the chars allowed in the input field. */
    b_isRequired=false   /* true indicates that at least one value must be selected. */
}) => {

    const [chars, setChars] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const [loadedProposals, setLoadedProposals] = useState()
    const [selectedProposals, setSelectedProposals] = useState(db2stateProposalFormat(arr_proposals, str_charKey))
    const [loadedProposalsStore, setLoadedProposalsStore] = useState({})
    const [isInputBlocked, setIsInputBlocked] = useState(false)
    const [isLoadingProposals, setIsLoadingProposals] = useState(false)
    const [loadedPropsalsErrorMsg, setLoadedProposalsErrorMsg] = useState('')
    const [numCurrentChars, setNumCurrentChars] = useState(0)
    const [isNumCurrentCharsActive, setIsNumCurrentCharsActive] = useState(false)
    const minNumCharsRef = useRef(int_minLength ? int_minLength.toString() + '/' : '')
    const maxNumCharsRef = useRef('/' + int_maxLength.toString())
    const inputFieldRef = useRef()
    const prevInputValueRef = useRef('')
    const selectedProposalsRef = useRef()
    const addingSelectedProposalRef = useRef(false)

    const handleInputChange = useHandleInputChange()
    const isRequiredErrorActivate = useAreRequiredErrorsActive()
    const wasDiscardPressed = useDiscardPressed()

    /* Error handling. */

    const hasError = (inputValue, hasSetErrorMsg=true) => {
        let errorMsg;
        const value2check = (inputValue || inputValue === '') ? inputValue : chars
        const value2checkLen = value2check.length

        if (value2checkLen < int_minLength) {
            errorMsg = MIN_LEN_EMSG
        }
        else if (value2checkLen > int_maxLength) {
            errorMsg = MAX_LEN_EMSG
        }
        else if (arr_allowedChars && !isCharRestrictionValid(value2check, arr_allowedChars)) {
            errorMsg = CHAR_RESTRICTION_INVALID_EMSG
        }

        if (errorMsg) {
            if (hasSetErrorMsg) setErrorMsg(errorMsg)
            return true
        }
        if (hasSetErrorMsg) setErrorMsg('')
        return false
    }

    const isProposalSelectionValid = (proposal) => {
        /**
         * Checks if the clicked proposal is valid.
         */
        if (isValueAlreadySelected(proposal, selectedProposals, str_charKey)) {
            alertProposalSelectionInvalid(DUPLICATE_EMSG)
            return false
        }
        return true
    }

    /* Hooks. */

    useEffect(() => {
        /**
         * Reset states if user clicks the form discard button.
         */
        /* When the */
        if (wasDiscardPressed === undefined) return
        setSelectedProposals([])
        setIsLoadingProposals()
        setChars('')
        setNumCurrentChars(0)
    }, [wasDiscardPressed])

    useEffect(() => {
        /**
         * Remove the loaded proposal element from the DOM if user clicks some
         * element on the page other than the <input> or elements with
         * class 'proposal-el'.
         */
        const clickListener = (e) => {
            const target = e.target
            if (target.tagName === 'INPUT' && target.id === str_id) return
            resetLoadedProposalStates()
        }

        const keyDownListener = (e) => {
            /**
             * Remove the tag proposal element from the DOM on 'Escape' press.
             */
            if (e.key === 'Escape') resetLoadedProposalStates()
        }

        window.addEventListener('click', clickListener)
        window.addEventListener('keydown', keyDownListener)

        return () => {
            window.addEventListener('click', clickListener)
            window.removeEventListener('keydown', keyDownListener)
        }
    }, [])

    useMemo(() => {
        /* All the required fields that are blank and do not show erros,
         * are activated when a form is submitted. The line below is only 
         * executed once. After the first execution, the required field 
         * displays error messages until the input is correct. */
        if (isRequiredErrorActivate && b_isRequired && !selectedProposals.length) {
            setErrorMsg(REQUIRED_EMSG)
        }
    }, [isRequiredErrorActivate])

    useEffect(() => {
        /**
         * Blocks/unblocks the input field depending on whether the number of
         * currently selected proposals has reached the limit.
         * Sends the selected proposals to InputFormContextProvider.js.
         */
        const numSelectedProposals = selectedProposals.length
        
        if (numSelectedProposals > (int_maxNumProposals-1)) setIsInputBlocked(true)
        else if (setIsInputBlocked) setIsInputBlocked(false)

        if (selectedProposals.length) {
            handleInputChange(
                {
                    id: str_id,
                    value: selectedProposals,
                    hasError: false /* Selected options are stored error free. */
                }
            )
        } else {
            handleInputChange(
                {
                    id: str_id,
                    value: [],
                    hasError: b_isRequired
                }
            )
        }
    }, [selectedProposals])

    /* Helper functions. */

    const addProposals2loadedProposalsStore = (newProposals, chars) => {
        /**
         * Add the newly queried proposals from the DB to the react state.
         * :Input
         *  newProposals: The queried proposals from the DB.
         *  chars (str) : The current value of the <input>.
         */
        let updatedProposals = {...loadedProposalsStore}
        updatedProposals[chars] = newProposals
        setLoadedProposalsStore(updatedProposals)
    }

    const resetLoadedProposalStates = () => {
        /**
         * Sets the states that are involved in proposing items to the user
         * to their init. values, i.e. the values they are in when no proposal
         * process is under way.
         */
        if (isLoadingProposals) return
        setIsLoadingProposals(false)
        setLoadedProposalsErrorMsg('')
        setLoadedProposals()
    }

    const addProposal2selectedProposals = (newProposal) => {
        addingSelectedProposalRef.current = true
        setSelectedProposals([...selectedProposals, newProposal])
        setLoadedProposals()
        inputFieldRef.current.value = ''
        setChars('')
        setNumCurrentChars(0)
        if (b_isRequired) setErrorMsg('')
    }

    const existsLoadedProposalOfName = (proposalName) => {
        /**
         * Checks if a selected proposal of name 'proposalName' exists in the state.
         */
        let proposalExists = false
        Object.values(loadedProposals).forEach(loadedProposal => {
            if (loadedProposal[str_charKey] === proposalName) {
                proposalExists = true
            }
        })
        return proposalExists
    }

    const getLoadedProposalByName = (proposalName) => {
        let retProposal = null
        loadedProposals.forEach(loadedProposal => {
            if (loadedProposal[str_charKey] === proposalName) {
                retProposal = loadedProposal
            }
        })
        return retProposal
    }

    /* DB queries. */

    const propose = async (chars) => {
        /**
         * Load data from stored state or the DB given the <input> value.
         * If a value is fetched from the DB, store them in the react state.
         */
        const storedProposals = loadedProposalsStore[chars]
        if (storedProposals) {
            setLoadedProposals(storedProposals)
        } else {
            setIsLoadingProposals(true)
            let reqData = {}
            reqData[str_requestDataKey] = chars
            const queryData = await sendData2db('post', str_proposalQueryUrl, reqData)
            if (queryData.isQuerySuccess) {
                const loadedProposals = queryData.response.data
                if (Array.isArray(loadedProposals)) {
                    /* Propose db values only if no other query was started after this one. */
                    const loadedProposalsStateFormat = db2stateProposalFormat(loadedProposals, str_charKey)
                    if (chars === prevInputValueRef.current) {
                        setLoadedProposals(loadedProposalsStateFormat)
                        setLoadedProposalsErrorMsg('')
                    }
                    addProposals2loadedProposalsStore(loadedProposalsStateFormat, chars)
                } else {
                    setLoadedProposalsErrorMsg(PROPOSAL_FORMAT_INCORRECT_EMSG)
                }
            } else {
                setLoadedProposalsErrorMsg(queryData.errorMsg)
            }
            setIsLoadingProposals(false)
        }
    }

    const decideProposeExecution = (inputValue) => {
        /**
         * The query is only executed if the input value has not changed for
         * a specific amount of time. Otherwise, a new DB query is useless,
         * as the user might already expect a different result depending on the
         * new input value.
         * :Input
         *  char (str): The value of <input>.
         */
        setTimeout(() => {
            if (inputValue === prevInputValueRef.current) propose(inputValue)
        }, QUERY_DECISION_DELAY)
    }

    /* Input field user interaction. */

    const onClickDeleteOption = (e) => {
        /**
         * Removes a selected options if user clicks the remove cross.
         */
        const target = e.target
        let tag2removeId

        if (target.tagName === 'path') {
            tag2removeId = parseInt(target.parentElement.getAttribute('value'), 10)
        } else if (target.tagName === 'svg') {
            tag2removeId = parseInt(target.getAttribute('value'), 10)
        }

        const newSelectedProposals = selectedProposals.filter(proposal => proposal.id !== tag2removeId)
        setSelectedProposals(newSelectedProposals)

        if (!newSelectedProposals.length) setErrorMsg(REQUIRED_EMSG)

        /* Focus input after clicking a tag removal to enable user typing. */
        try {
            setTimeout(() => inputFieldRef.current.focus(), 150)
        } catch {
            /* If input field is not present due to the max. num of tags reached,
             * the focus of the input field might fail as its element is not in the DOM. */
        }
    }

    const selectProposal = (e) => {
        /**
         * Handle user's click on a proposed option
         */
        const clickedProposalName = e.target.getAttribute('value')
        
        /* Check the format of the proposal as user can tinker with it. */
        if (!isProposalSelectionValid(clickedProposalName)) {
            inputFieldRef.current.focus()
            return
        }

        /* Check if the name exists in the loaded proposals as user can tinker with it. */
        if (!existsLoadedProposalOfName(clickedProposalName)) {
            alert(CLICKED_PROPOSAL_DOES_NOT_EXIST_EMSG)
            inputFieldRef.current.focus()
            return
        }

        const loadedProposal = getLoadedProposalByName(clickedProposalName)
        addProposal2selectedProposals(loadedProposal)
        inputFieldRef.current.focus()
    }

    const onFocus = (e) => {
        if (!isNumCurrentCharsActive) setIsNumCurrentCharsActive(true)
        setNumCurrentChars(e.target.value.length)
    }

    const onBlur = (e) => {
        /* Add required error message if no proposal is selected. */
        if (!b_isRequired) {
            setErrorMsg('')
            return
        }
        setTimeout(() => {
            /* Wait a bit so that the selectedProposals state can be updated. */
            try {
                if (!selectedProposalsRef.current.innerHTML) setErrorMsg(REQUIRED_EMSG)
                else setErrorMsg('')
            } catch {
                /* The onBlur event can be a form submission, which means that the
                 * HTML code is already gone from the DOM, thus, the innerHTML of
                 * the selectedProposalRef is no longer reachable and throws an error. */
            }
        }, 250)
    }

    const onChange = (e) => {
        const inputValue = e.target.value.trimStart()
        setChars(inputValue)
        setNumCurrentChars(inputValue.length)
        if (hasError(inputValue)) {
            resetLoadedProposalStates()
        } else {
            prevInputValueRef.current = inputValue
            decideProposeExecution(inputValue)
        }
    }

    const onKeyDown = (e) => {
        /**
         * Close the loaded proposal window if the user leaves the <input>
         * via the tab key. On clicking somewhere else, is is closed, too,
         * but this function handles the tab leave.
         */
        if (e.key === 'Tab') setLoadedProposals()
    }

    return (
        <FieldWrapper
            str_fieldId={str_id}
            str_fieldTitle={str_fieldTitle}
            str_bottomInfoText={str_bottomInfoText}
            str_errorMsg={errorMsg}
            str_classes='in-field-proposal-wrapper'
            b_isRequired={b_isRequired}
        >
            {
                !isInputBlocked &&
                <div className="in-field in-field-char">
                    <input
                        ref={inputFieldRef}
                        type="text"
                        name="char-field"
                        minLength={int_minLength}
                        maxLength={int_maxLength}
                        required={b_isRequired}
                        id={str_id}
                        value={chars}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                    />
                    <div className={`in-field-char-count ${isNumCurrentCharsActive ? "" : "hidden"}`}>
                        {minNumCharsRef.current}{numCurrentChars}{maxNumCharsRef.current}
                    </div>
                </div>
            }

            {
                selectedProposals &&
                <div
                    ref={selectedProposalsRef}
                    className="selected-options-btns"
                >
                {
                    selectedProposals.map((sp, index) => (
                        <span key={index}>
                            <div className="selected-options-btn">
                                <span className="value">{sp[str_charKey]}</span>
                                <span className="close-icon">
                                    <CgClose
                                        value={sp.id}
                                        onClick={onClickDeleteOption}
                                    />
                                </span>
                            </div>
                        </span>
                    ))
                }
                </div>
            }

            <InFieldProposal
                b_isLoading={isLoadingProposals}
                str_errorMsg={loadedPropsalsErrorMsg}
                b_itemsFound={Array.isArray(loadedProposals)}
                b_hasItems={loadedProposals ? !!loadedProposals[0] : false}
            >
            {
                loadedProposals &&
                loadedProposals.map((lp, index) => (
                    <div
                        key={index}
                        className="proposal"
                        value={lp[str_charKey]}
                        onClick={selectProposal}
                    >
                        {lp[str_charKey]}
                    </div>
                ))
            }
            </InFieldProposal>
        </FieldWrapper>
    )
}

SelectiveProposalField.propTypes = {
    str_id: PropTypes.string.isRequired,
    str_fieldTitle: PropTypes.string,
    str_bottomInfoText: PropTypes.string,
    str_proposalQueryUrl: PropTypes.string.isRequired,
    str_requestDataKey: PropTypes.string,
    int_maxNumProposals: PropTypes.number,
    int_minLength: PropTypes.number.isRequired,
    int_maxLength: PropTypes.number.isRequired,
    arr_proposals: PropTypes.array.isRequired,
    arr_allowedChars: PropTypes.array,
    b_isRequired: PropTypes.bool.isRequired,
}

export default SelectiveProposalField
