import React from 'react';
import { useNavigate } from 'react-router-dom';

import InputFormLogin from '../input-forms/forms/welcome/InputFormLogin';
import InputFormContextProvider from '../input-forms/context-provider/InputFormContextProvider';
import { LoginFormData as FormData } from '../input-forms/util/form-config-data/InputFormWelcomeDataConfigs';
import { static2dynamicPanelConfig } from '../input-forms/util/input_form_data_handler';
import AuthService from '../../util/authentication/auth_service';

import Usertypes from '../../util/Usertypes';
import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const Login = () => {
    
    const navigate = useNavigate()

    useSetBrowserTabTitle('Login')

    const loginUser = (response) => {
        /* Clear localStorage if user loggs in so that all old data is cleared. */
        localStorage.clear()

        /* Store JWT and its info in the LS. */
        AuthService.genUserLs(response.data)

        /* Navigate based on user data. */
        const user = AuthService.getUser()
        if (user.isAboValid) {
            /* Navigate profile page. */
            const url = `/profile/${user.usertype}/${user.username}/${user.id}`
            navigate(url)
        } else if (user.isProfileSetup) {
            /* Student user can reach the profile page without a valid abo too. */
            if (user.usertype === Usertypes.student) {
                const url = `/profile/${user.usertype}/${user.username}/${user.id}`
                navigate(url)
            } else {
                /* Link to abo extension page. */
                const url = '/abo-extension'
                navigate(url)
            }
        } else {
            /* Link to profile setup page. */
            const url = `/profile-set-up/${user.usertype}/${user.username}/`
            navigate(url)
        }
    }

    return (
        <InputFormContextProvider
            {...static2dynamicPanelConfig(FormData)}
            str_requestType='post'
            str_queryURL='/api/token/'
            b_isPrivatePost={false}
            b_hasDiscardBtn={false}
            b_isLoginForm={true}
            fct_response2parent={loginUser}
        >
            <InputFormLogin />
        </InputFormContextProvider>
    )
}

export default Login
