import React from 'react';

import MessageStdHeader from '../MessageStdHeader';
import MessageStdFooter from '../MessageStdFooter';
import DocInfoMessageBody from './DocInfoMessageBody';

const DocInfoMessage = () => {
    return (
        <div className='msg'>
            <MessageStdHeader />
            <DocInfoMessageBody />
            <MessageStdFooter />
        </div>
    )
}

export default DocInfoMessage
