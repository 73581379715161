import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import PrivatePage from '../../../components/network/PrivatePage';
import PageBase from '../../../components/page-layout/PageBase';
import CardGridInfoList from '../../../components/cards/CardGridInfoList';
import IndustryPresInternshipInfos from '../../../components/profile-pages/industry/presentation/IndustryPresInternshipInfos';
import CSecBase from '../../../components/content-sections/CSecBase';
import CSecParagraphs from '../../../components/content-sections/CSecPragraphs';
import CSecGridNumbers from '../../../components/content-sections/CSecGridNumbers';
import CSecTextOrImage from '../../../components/content-sections/CSecTextOrImage';
import CSecPageUpdateTimeInfo from '../../../components/content-sections/CSecPageUpdateTimeInfo';
import TbIndustryJobs from '../../../components/tables/industry/jobs/TbIndustryJobs';
import DataStateCheckWrapper from '../../../components/general/DataStateCheckWrapper';

import ObjectSerializer from '../../../util/serializers/ObjectSerializer';
import Usertypes from '../../../util/Usertypes';

import { fetchDBdata } from '../../../util/db_ls_query_handler';

const SERIALIZER_LAYOUT = {
    jobs: 'plain',
    companyName: 'plain',
    logo: 'plain',
    slogan: 'array',
    description: 4,
    benefit: 4,
    numbers: [
        ['Gründungsjahr', 'yearFounded'],
        ['Mitarbeiter österreichweit', 'numEmployeesAut'],
        ['Mitarbeiter weltweit', 'numEmployeesWorld'],
        ['Büros österreichweit', 'numOfficesAut'],
        ['Büros weltweit', 'numOfficesWorld']
    ],
    internship: [
        'areInternshipsOffered',
        'ishipThesisOptions',
        'ishipSeasons',
        'ishipMinLength',
        'ishipMaxLength',
        'ishipIsPaid',
        'ishipHasRemoteWorkOption'
    ]
};

const SERIALIZER_LAYOUT_COMPANY_CONTACT = [
    ['Adresse', 'location'],
    ['Telefon', 'phone'],
    ['Email', 'email'],
    ['Webseite', 'webpage', 'Zur Seite']
];

const SERIALIZER_LAYOUT_CONTACT_PERSON = [
    ['Name', 'name'],
    ['Arbeitsstandort', 'workLocation'],
    ['Jobposition', 'jobPosition'],
    ['Telefon', 'phone'],
    ['Email', 'email']
];

const internshipDataSerializer = (data) => {
    /**
     * Serialize the internship object data to a format that can be parsed by the
     * react component for the internship options section.
     */
    if (!data.areInternshipsOffered) return null;

    const duration = {
        title: 'Dauer',
        data: [
            {
                title: `${data.ishipMinLength} - ${data.ishipMaxLength} Mo.`,
                isActive: true
            }
        ]
    };

    const seasons = {
        title: 'Jahreszeiten',
        data: [
            {
                title: 'Frühling',
                isActive: data.ishipSeasons.includes('Frühling')
            },
            {
                title: 'Sommer',
                isActive: data.ishipSeasons.includes('Sommer')
            },
            {
                title: 'Herbst',
                isActive: data.ishipSeasons.includes('Herbst')
            },
            {
                title: 'Winter',
                isActive: data.ishipSeasons.includes('Winter')
            }
        ]
    };

    const thesisOptions = {
        title: 'Abschlussarbeiten',
        data: [
            {
                title: 'Bachelor',
                isActive: data.ishipThesisOptions.includes('Bachelor')
            },
            {
                title: 'Master',
                isActive: data.ishipThesisOptions.includes('Master')
            },
            {
                title: 'Doktor',
                isActive: data.ishipThesisOptions.includes('Doktor')
            }
        ]
    };

    const homeOffice = {
        title: 'Home Office',
        data: [
            {
                title: 'Ja',
                isActive: data.ishipHasRemoteWorkOption
            },
            {
                title: 'Nein',
                isActive: !data.ishipHasRemoteWorkOption
            }
        ]
    };

    const payment = {
        title: 'Bezahlt',
        data: [
            {
                title: 'Ja',
                isActive: data.ishipIsPaid
            },
            {
                title: 'Nein',
                isActive: !data.ishipIsPaid
            }
        ]
    };

    return [
        duration,
        seasons,
        thesisOptions,
        homeOffice,
        payment
    ];
}

const BASE_URL = '/api/profiles/company/detail-page/';

const genUrl = (id) => {
    return BASE_URL + id + '/';
}



const IndustryProfileDetailPage = () => {

    const params = useParams()
    const [genData, setGenData] = useState()
    const [contactData, setContactData] = useState()
    const [internshipData, setInternshipData] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(() => {
        const func = async () => {
            const queryData = await fetchDBdata(genUrl(params.companyId))
            if (queryData.isQuerySuccess) {
                const data = queryData.response.data
                const genData = ObjectSerializer.serialize(data, SERIALIZER_LAYOUT)
                const contact = ObjectSerializer.serializeContact(data, SERIALIZER_LAYOUT_COMPANY_CONTACT)
                const contactPerson0 = ObjectSerializer.serializeContact(data.contactPersons[0], SERIALIZER_LAYOUT_CONTACT_PERSON)
                const contactPerson1 = ObjectSerializer.serializeContact(data.contactPersons[1], SERIALIZER_LAYOUT_CONTACT_PERSON)
                const contactData = [
                    {
                        title: 'Unternehmen',
                        data: contact
                    },
                    {
                        title: '1. Kontaktperson',
                        data: contactPerson0
                    },
                    {
                        title: '2. Kontaktperson',
                        data: contactPerson1
                    }
                ]
                const ishipData = internshipDataSerializer(genData.internship)
                document.title = genData.companyName + ' Infoseite | MUPLAZA'
                setGenData(genData)
                setContactData(contactData)
                setInternshipData(ishipData)
                setIsFirstFetchSuccess(true)
            } else {
                document.title = 'Infoseite Unternehmen | MUPLAZA'
                setIsFirstFetchSuccess(false)
                setErrorMsg(queryData.errroMsg)
            }
        }
        func()
    }, [])

    console.log('genData', genData)
    
    return (
        <PrivatePage
            arr_usertypes={[Usertypes.company, Usertypes.student]}
            b_isStudentFreeTier={true}
        >
            <PageBase
                str_pageSpacingClasses='no-width-restriction'
                str_pageContentBgColorClasses='page-bg-white'
                str_mainContentWrapperClasses='mc-wrapper info-page-wrapper'
                b_useLightTheme={true}
            >
                <DataStateCheckWrapper
                    b_hasQueryCheck={true}
                    b_isFirstFetchSuccess={isFirstFetchSuccess}
                    b_isContentSection={true}
                    str_errorMsg={errorMsg}
                    firstQueryDataEntry={-1} /* Only placeholder value. */
                >
                    {
                        genData &&
                        <>
                            <CSecPageUpdateTimeInfo str_duration='4 h' />
                            <CSecTextOrImage
                                str_title={genData.companyName}
                                str_imageURL={genData.logo}
                                str_classes='no-margin'
                            />
                            <CSecParagraphs
                                b_hasDarkDesign={false}
                                b_hasCenteredText={true}
                                arr_paragraphs={genData.slogan}
                            />
                            <CSecGridNumbers
                                str_classes='five-cols'
                                b_hasDarkDesign={true}
                                arr_gridItems={genData.numbers}
                            />
                            <CSecParagraphs
                                str_title='Wer wir sind'
                                str_classes='info-page-paragraphs-section'
                                arr_paragraphs={genData.description}
                            />
                            <CSecParagraphs
                                str_title='Welche Vorteile bieten wir'
                                str_classes='info-page-paragraphs-section'
                                arr_paragraphs={genData.benefit}
                            />
                            <CSecBase str_title='Kontaktdaten'>
                                <CardGridInfoList arr_data={contactData} str_title='Kontaktdaten' />
                            </CSecBase>
                            <IndustryPresInternshipInfos
                                str_title='Möglichkeiten für Praktika'
                                arr_gridItems={internshipData}
                            />
                            <CSecBase
                                str_title='jobs'
                                str_classes='no-margin'
                                b_hasDarkDesign={false}
                            >
                                <TbIndustryJobs
                                    arr_data={genData.jobs}
                                    b_isDynamic={false}
                                />
                            </CSecBase>
                        </>
                    }
                </DataStateCheckWrapper>
            </PageBase>
        </PrivatePage>
    )
}

export default IndustryProfileDetailPage
