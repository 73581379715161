import React from 'react';
import PropTypes from 'prop-types';

import SbBtnContent from '../../SbBtnContent';
import SbCourseTasksItem from './SbCourseTasksItem';
import { useTasksContext } from '../../context-provider/SbCourseTasksContextProvider';

const SbCourseTasks = ({
    str_identifier /* label of the task (e.g. 'exam' or 'exercise'). */
}) => {
    const tasks = useTasksContext()
    return (
        <SbBtnContent
            str_btnTitle={str_identifier === 'exam' ? 'Neue Prüfung' : 'Neue Übung'}
            str_sbClasses='sb-sub-items'
        >
            {
                tasks.map((task, index) => (
                    <SbCourseTasksItem
                        key={index}
                        obj_item={task}
                        str_identifier={str_identifier}
                    />
                ))
            }
        </SbBtnContent>
    )
}

SbCourseTasks.propTypes = {
    str_identifier: PropTypes.string
}

export default SbCourseTasks
