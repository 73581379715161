import React from 'react';
import PropTypes from 'prop-types';

import SbAnimationContextProvider from './context-provider/SbAnimationContextProvider';
import Sidebar from './Sidebar';
import SbActionButton from './SbActionButton';

const SbBtnContent = ({
    str_btnTitle='neu',
    str_sbClasses='',
    b_hasDarkDesign=false,
    children
}) => {
    return (
        <SbAnimationContextProvider>
            <Sidebar
                str_classes={str_sbClasses}
                b_hasDarkDesign={b_hasDarkDesign}
            >
                <ul className="sb-list">
                    <div className="sb-list-item sb-info-items">
                        <SbActionButton
                            str_name={str_btnTitle}
                            b_hasDarkDesign={b_hasDarkDesign}
                        />
                    </div>
                    {children}
                </ul>
            </Sidebar>
        </SbAnimationContextProvider>
    )
}

SbBtnContent.propTypes = {
    str_btnTitle: PropTypes.string,
    str_sbClasses: PropTypes.string,
    b_hasDarkDesign: PropTypes.bool
}

export default SbBtnContent
