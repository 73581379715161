import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlinePlus } from 'react-icons/ai';

import CardPriceTag from '../CardPriceTag';
import CardSellNums from '../CardSellNums';
import { useIncreaseNumAvailableOfCoupon } from '../../profile-pages/context-provider/PPCouponsContextProvider';

const MC_CURRENCY = 'MC';

const CardCouponFooterInfo = ({
    nbr_id,
    nbr_price,
    nbr_available,
    nbr_sold,
    nbr_redeemed
}) => {
    const increaseNumAvailable = useIncreaseNumAvailableOfCoupon()
    return (
        <div className="card-footer-offer">
            <CardPriceTag
                nbr_price={nbr_price}
                str_currency={MC_CURRENCY}
            />
            <CardSellNums
                nbr_available={nbr_available}
                nbr_soldOrReserved={nbr_sold}
                nbr_redeemed={nbr_redeemed}
            />
            {/* Buttons to add more instances of existing coupons. */}
            <div className="action-el">
                <div className="buttons-info-msg">
                    <p>Weitere Gutscheine hinzufügen.</p>
                </div>
                <div className="buttons">
                    <button
                        className="btn btn-sm btn-submit"
                        onClick={() => increaseNumAvailable(nbr_id, 1)}
                    >
                        <div className="align-content-horizontally">
                            <div><AiOutlinePlus /></div>
                            <div>1</div>
                        </div>
                    </button>
                    <button
                        className="btn btn-sm btn-submit"
                        onClick={() => increaseNumAvailable(nbr_id, 5)}
                    >
                        <div className="align-content-horizontally">
                            <div><AiOutlinePlus /></div>
                            <div>5</div>
                        </div>
                    </button>
                    <button
                        className="btn btn-sm btn-submit"
                        onClick={() => increaseNumAvailable(nbr_id, 10)}
                    >
                        <div className="align-content-horizontally">
                            <div><AiOutlinePlus /></div>
                            <div>10</div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    )
}

CardCouponFooterInfo.propTypes = {
    nbr_id: PropTypes.number.isRequired,
    nbr_price: PropTypes.number.isRequired,
    nbr_available: PropTypes.number.isRequired,
    nbr_sold: PropTypes.number.isRequired,
    nbr_redeemed: PropTypes.number.isRequired
}

export default CardCouponFooterInfo
