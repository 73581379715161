import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FiCalendar } from 'react-icons/fi';

import SbCourseTasksSubitem from './SbCourseTasksSubitem';
import { string2URLstring } from '../../../../util/data_handler';
import { useRunAnimationContext } from '../../context-provider/SbAnimationContextProvider';

const SbCourseTasksItem = ({
    obj_item,
    str_identifier /* Either 'exam' or 'exercise'. */
}) => {

    const [isOpen, setIsOpen] = useState(false)

    const handleOpening = (e) => {
        /* Don't open the dropdown if the info symbol is clicked. */
        if (e.target.tagName === 'DIV') setIsOpen(!isOpen)
    }

    /* Hook to hide sb when specific sb items are clicked (see onClick prop). */
    const runAnimation = useRunAnimationContext()

    return (
        <li className='sb-list-item has-sub-items'>
            <div
                className='sb-header multiple-items'
                onClick={(e) => handleOpening(e)}
            >
                <div className="text">{obj_item.date}</div>
                {/* Icon link to get to a page with extended info on the task. */}
                <div className="icon">
                    <Link to={`${str_identifier}/${obj_item.id}/${string2URLstring(obj_item.date)}/date-change`}>
                        <FiCalendar onClick={() => runAnimation()} />
                    </Link>
                </div>
            </div>
            {
                isOpen &&
                <ul className='sb-sub-list'>
                    {/* List of tasks (e.g. exams). */}
                    {
                        obj_item.questions.map((subtask, index) => (
                            <SbCourseTasksSubitem
                                key={index}
                                str_identifier={str_identifier}
                                str_date={obj_item.date}
                                nbr_id={obj_item.id}
                                obj_item={subtask}
                            />
                        ))
                    }
                    {/* Button to create a new subtask (e.g. an exam question). */}
                    <li className='sb-sub-list-item-btn'
                        onClick={() => runAnimation()}
                    >
                        <Link to={`${str_identifier}/${obj_item.id}/${string2URLstring(obj_item.date)}/new-question`}>
                            <button className="btn btn-sm">Neue Frage</button>
                        </Link>
                    </li>
                </ul>
            }
        </li>
    )
}

SbCourseTasksItem.propTypes = {
    obj_item: PropTypes.object.isRequired,
    str_identifier: PropTypes.string.isRequired
}

export default SbCourseTasksItem
