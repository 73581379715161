/**
 * Entails the form configs for all available coupons on the platform. 
 */
import FieldLimits from '../db_field_limits.json';
import BottomTexts from '../input_form_bottom_info_texts.json';
import PlaceholderTexts from '../input_form_placeholder_texts.json';
import { genFieldData } from "./InputFormBaseDataAndSerializer";

// export const SEMESTER_OPTIONS = ['Dieses Semester', 'Nächstes Semester'];
export const SEMESTER_OPTIONS = ['Nächstes Semester']; // Only one option for launch semester.

export const CouponsFormData = {
    str_localStorageKey: 'CoupoonsFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        title       : ['',        true],
        description : ['',        true],
        price       : [undefined, true],
        numAvailable: [undefined, true],
        semester    : ['',        true]
    },
    fields: {
        title: genFieldData('text', {
            str_id: 'title',
            str_fieldTitle: 'Titel',
            b_isTextarea: false,
            int_maxLength: FieldLimits.coupon.food.title
        }),
        description: genFieldData('text', {
            str_id: 'description',
            str_fieldTitle: 'Beschreibung',
            str_placeholder: PlaceholderTexts.description,
            int_maxLength: FieldLimits.coupon.food.description
        }),
        price: genFieldData('nbr', {
            str_id: 'price',
            str_fieldTitle: 'Preis in MC',
            str_bottomInfoText: BottomTexts.coupon.mc2euroRatio,
            nbr_minValue: FieldLimits.coupon.food.price.min,
            nbr_maxValue: FieldLimits.coupon.food.price.max
        }),
        numAvailable: genFieldData('nbr', {
            str_id: 'numAvailable',
            str_fieldTitle: 'Stückzahl',
            str_bottomInfoText: BottomTexts.coupon.numSold,
            nbr_minValue: FieldLimits.coupon.food.numAvailable.min,
            nbr_maxValue: FieldLimits.coupon.food.numAvailable.max
        }),
        semester: genFieldData('sc', {
            str_id: 'semester',
            str_fieldTitle: 'Semester',
            arr_options: SEMESTER_OPTIONS
        })
    }
};

export const RedemptionFormData = {
    str_localStorageKey: 'RedemptionFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        redemptionCode: ['', true]
    },
    fields: {
        redemptionCode: genFieldData('text', {
            str_id: 'redemptionCode',
            b_isTextarea: false,
            b_hasNumCharsDisplay: false,
            b_hasPasswordDisplay: true,
            int_maxLength: 10
        })
    }
};