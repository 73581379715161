/**
 * Entails all the input form configs of the gastro user.  
 */

import FieldLimits from '../db_field_limits.json';
import BottomTexts from '../input_form_bottom_info_texts.json';
import PlaceholderTexts from '../input_form_placeholder_texts.json';
import { genFieldData } from "./InputFormBaseDataAndSerializer";
import { TemplateFields } from "./InputFormTemplateConfigs";
import { dateFromTodayAsBrowserString } from "../../../../util/date_time_handler";

/* Variables. */

const REPEAT_INTERVAL_DAYS = ['1', '7', '14', '21', '28'];
const BASE_URL = '/api/profiles/gastronomer/';

/* Templates (used multiple times). */

const TConsumeOptions = {
    obj_initState: {
        hasLocalConsumption:    [false, false],
        hasPickupConsumption:   [false, false],
        hasDeliveryConsumption: [false, false]
    },
    fields: {
        hasLocalConsumption: genFieldData('b', {
            str_id: 'hasLocalConsumption',
            str_fieldTitle: 'Im Restaurant',
            str_boolInfoText: 'Kann im Restaurant komsumiert werden?'
        }),
        hasPickupConsumption: genFieldData('b', {
            str_id: 'hasPickupConsumption',
            str_fieldTitle: 'Abholung',
            str_boolInfoText: 'Können Produkte abgeholt werden?'
        }),
        hasDeliveryConsumption: genFieldData('b', {
            str_id: 'hasDeliveryConsumption',
            str_fieldTitle: 'Lieferung',
            str_boolInfoText: 'Liefert dein Betrieb?'
        })
    }
};

/* Forms. */

export const GastroContactPersonFormData = {
    str_localStorageKey: 'GastroContactPersonFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        firstName:   ['', true],
        lastName:    ['', true],
        jobPosition: ['', true],
        phone:       ['', true],
        email:       ['', true],
        linkedin:    ['', false],
        twitter:     ['', false],
        facebook:    ['', false],
        instagram:   ['', false],
        tiktok:      ['', false]
    },
    fields: {
        firstName: TemplateFields.person.firstName,
        lastName: TemplateFields.person.lastName,
        jobPosition: TemplateFields.work.jobPosition,
        email: TemplateFields.contact.email,
        phone: TemplateFields.contact.phone,
        linkedin: TemplateFields.socialMedia.linkedin,
        twitter: TemplateFields.socialMedia.twitter,
        facebook: TemplateFields.socialMedia.facebook,
        instagram: TemplateFields.socialMedia.instagram,
        tiktok: TemplateFields.socialMedia.tiktok
    }
};

export const GastroFoodMenuFormData = {
    str_localStorageKey: 'GastroFoodMenuFormData',
    str_queryURL: BASE_URL + 'food-menus/',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        foodMenu0: [null, false],
        foodMenu1: [null, false],
        foodMenu2: [null, false],
        foodMenu3: [null, false]
    },
    fields: {
        foodMenu0: genFieldData('file', {
            str_id: 'foodMenu0',
            str_fieldTitle: '1. Speisekarte',
            arr_allowedFileTypes: ['pdf'],
            nbr_maxSize: FieldLimits.user.gastronomer.menu.file.size,
            b_isRequired: false
        }),
        foodMenu1: genFieldData('file', {
            str_id:  'foodMenu1',
            str_fieldTitle: '2. Speisekarte',
            arr_allowedFileTypes: ['pdf'],
            nbr_maxSize: FieldLimits.user.gastronomer.menu.file.size,
            b_isRequired: false
        }),
        foodMenu2: genFieldData('file', {
            str_id:  'foodMenu2',
            str_fieldTitle: '3. Speisekarte',
            arr_allowedFileTypes: ['pdf'],
            nbr_maxSize: FieldLimits.user.gastronomer.menu.file.size,
            b_isRequired: false
        }),
        foodMenu3: genFieldData('file', {
            str_id:  'foodMenu3',
            str_fieldTitle: '4. Speisekarte',
            arr_allowedFileTypes: ['pdf'],
            nbr_maxSize: FieldLimits.user.gastronomer.menu.file.size,
            b_isRequired: false
        }),
    }
};

export const GastroDrinksMenuFormData = {
    str_localStorageKey: 'GastroDrinkMenuFormData',
    str_queryURL: BASE_URL + 'drinks-menus/',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        drinkMenu0: [null, false],
        drinkMenu1: [null, false],
        drinkMenu2: [null, false],
        drinkMenu3: [null, false]
    },
    fields: {
        drinkMenu0: genFieldData('file', {
            str_id:  'drinkMenu0',
            str_fieldTitle: '1. Getränkekarte',
            arr_allowedFileTypes: ['pdf'],
            nbr_maxSize: FieldLimits.user.gastronomer.menu.file.size,
            b_isRequired: false
        }),
        drinkMenu1: genFieldData('file', {
            str_id:  'drinkMenu1',
            str_fieldTitle: '2. Getränkekarte',
            arr_allowedFileTypes: ['pdf'],
            nbr_maxSize: FieldLimits.user.gastronomer.menu.file.size,
            b_isRequired: false
        }),
        drinkMenu2: genFieldData('file', {
            str_id:  'drinkMenu2',
            str_fieldTitle: '3. Geränkekarte',
            arr_allowedFileTypes: ['pdf'],
            nbr_maxSize: FieldLimits.user.gastronomer.menu.file.size,
            b_isRequired: false
        }),
        drinkMenu3: genFieldData('file', {
            str_id:  'drinkMenu3',
            str_fieldTitle: '4. Getränkekarte',
            arr_allowedFileTypes: ['pdf'],
            nbr_maxSize: FieldLimits.user.gastronomer.menu.file.size,
            b_isRequired: false
        }),
    }
};

export const GastroConsumeOptionsFormData = {
    str_localStorageKey: 'GastroConsumeOptionsFormData',
    str_queryURL: BASE_URL + 'consume-options/',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        ...TConsumeOptions.obj_initState
    },
    fields: {
        ...TConsumeOptions.fields
    }
};

export const GastroSpecialOffersFormData = {
    str_localStorageKey: 'GastroSpecialOffersFormData',
    str_queryURL: BASE_URL + 'special-offers/',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        specialOffer0: ['', false],
        specialOffer1: ['', false],
        specialOffer2: ['', false],
        specialOffer3: ['', false]
    },
    fields: {
        specialOffer0: genFieldData('text', {
            str_id: 'specialOffer0',
            str_fieldTitle: '1. Beschreibung',
            int_maxLength: FieldLimits.user.gastronomer.specialOffer,
            b_isRequired: false
        }),
        specialOffer1: genFieldData('text', {
            str_id: 'specialOffer1',
            str_fieldTitle: '2. Beschreibung',
            int_maxLength: FieldLimits.user.gastronomer.specialOffer,
            b_isRequired: false
        }),
        specialOffer2: genFieldData('text', {
            str_id: 'specialOffer2',
            str_fieldTitle: '3. Beschreibung',
            int_maxLength: FieldLimits.user.gastronomer.specialOffer,
            b_isRequired: false
        }),
        specialOffer3: genFieldData('text', {
            str_id: 'specialOffer3',
            str_fieldTitle: '4. Beschreibung',
            int_maxLength: FieldLimits.user.gastronomer.specialOffer,
            b_isRequired: false
        })
    }
};

export const GastroFoodOffersFormData = {
    str_localStorageKey: 'GastroFoodOffersFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        isDgfs          : [false    , false], /* No config needed, handled internally. */
        title           : [''       , true ],
        description     : [''       , true ],
        date            : [''       , true ],
        timeSpan        : [['', ''] , true ],
        repeatDays      : [''       , false],
        repeatOnWeekends: [false    , false],
        price           : [undefined, true ],
        numAvailable    : [undefined, false],
        isVegetarian    : [false    , false],
        isVegan         : [false    , false],
        ...TConsumeOptions.obj_initState
    },
    fields: {
        title: genFieldData('text', {
            str_id: 'title',
            str_fieldTitle: 'Titel',
            int_maxLength: FieldLimits.offer.food.title,
            b_isTextarea: false
        }),
        description: genFieldData('text', {
            str_id: 'description',
            str_fieldTitle: 'Beschreibung',
            str_placeholder: PlaceholderTexts.description,
            int_maxLength: FieldLimits.offer.food.description
        }),
        date: genFieldData('date', {
            str_id: 'date',
            str_fieldTitle: 'Datum',
            str_minDate: dateFromTodayAsBrowserString(0),
            /* str_maxDate: ... specified in the InputFormFoodOffers.js file (differentiate dgfs and regular offers) */ 
        }),
        repeatDays: genFieldData('sc', {
            str_id: 'repeatDays',
            str_fieldTitle: 'Wiederkehrend',
            str_bottomInfoText: BottomTexts.offer.repeating,
            arr_options: REPEAT_INTERVAL_DAYS,
            nbr_heightInPixels: 126,
            b_isRequired: false
        }),
        timeSpan: genFieldData('timeSpan', {
            str_id: 'timeSpan',
            str_startTitle: 'Zeit von',
            str_endTitle: 'Zeit bis'
        }),
        price: genFieldData('nbr', {
            str_id: 'price',
            str_fieldTitle: 'Preis in Euro',
            nbr_minValue: FieldLimits.offer.food.price.min,
            nbr_maxValue: FieldLimits.offer.food.price.max,
            b_isIntField: false
        }),
        numAvailable: genFieldData('nbr', {
            str_id: 'numAvailable',
            str_fieldTitle: 'Stückzahl',
            str_bottomInfoText: BottomTexts.offer.unlimited,
            nbr_minValue: FieldLimits.offer.food.numAvailable.min,
            nbr_maxValue: FieldLimits.offer.food.numAvailable.max,
            b_isRequired: false
        }),
        isVegetarian: genFieldData('b', {
            str_id: 'isVegetarian',
            str_fieldTitle: 'Vegetarisch',
            str_boolInfoText: 'Ist das Angebot vegetarisch?'
        }),
        isVegan: genFieldData('b', {
            str_id: 'isVegan',
            str_fieldTitle: 'Vegan',
            str_boolInfoText: 'Ist das Angebot vegan?',
            str_bottomInfoText: BottomTexts.offer.food.isVegan
        }),
        repeatOnWeekends: genFieldData('b', {
            str_id: 'repeatOnWeekends',
            str_fieldTitle: 'Wochenende',
            str_boolInfoText: 'Soll das wiederkehrende Angebot auch am Wochenende verfügbar sein?'
        }),
        ...TConsumeOptions.fields
    }
};