import React from 'react';
import PropTypes from 'prop-types';

const CSecBase = ({
    str_title='',
    str_subtitle='',
    str_classes='',
    str_id='',
    b_hasBrightDesign=false,
    b_hasDarkDesign=false,
    b_hasCenteredText=false,
    b_isUsedAsTitle=false,
    b_isDiscussionContentSection=false,
    children
}) => {
    return (
        <section
            id={str_id}
            className={`
                content-section
                ${b_hasBrightDesign ? 'bright' : ''}
                ${b_hasDarkDesign ? 'dark' : ''}
                ${b_hasCenteredText ? 'centered-text' : ''}
                ${b_isDiscussionContentSection ? 'discussion-content-section' : ''}
                ${b_isUsedAsTitle ? 'content-section-as-title' : ''}
                ${str_classes}
            `}
        >
            {
                (str_title || str_subtitle) &&
                <div className={`section-content-headers ${b_isUsedAsTitle ? 'is-used-as-title' : ''}`}>
                    {
                        str_title &&
                        <div className={`section-content-header ${str_subtitle ? '' : 'no-margin'}`}>
                            <h2>{str_title}</h2>
                        </div>
                    }
                    {
                        str_subtitle &&
                        <div className="section-content-subheader">
                            <h5>{str_subtitle}</h5>
                        </div>
                    }
                </div>
            }
            <div className="section-content">
                {children}
            </div>
        </section>
    )
}

CSecBase.propTypes = {
    str_title: PropTypes.string,
    str_subtitle: PropTypes.string,
    str_classes: PropTypes.string,
    str_id: PropTypes.string,
    b_hasBrightDesign: PropTypes.bool,
    b_hasDarkDesign: PropTypes.bool,
    b_hasCenteredText: PropTypes.bool,
    b_isUsedAsTitle: PropTypes.bool,
    b_isDiscussionContentSection: PropTypes.bool
}

export default CSecBase
