import React from 'react';
import PropTypes from 'prop-types';

import Sidebar from '../Sidebar';
import SbProfilePageItem from './SbProfilePageItem';
import SbAnimationContextProvider from '../context-provider/SbAnimationContextProvider';
import { SbDataProfilePages } from '../data/SbDataProfilePages';

import AuthService from '../../../util/authentication/auth_service';

const SbProfilePage = ({ str_userCategory }) => {
    const isAboValid = AuthService.isAboValid()
    return (
        <SbAnimationContextProvider>
            <Sidebar>
                <ul className="sb-list">
                    {
                        SbDataProfilePages[str_userCategory].map((item, index) => (
                            (item.isFreeTier || isAboValid) &&
                            <SbProfilePageItem key={index} obj_data={item} />
                        ))
                    }
                </ul>
            </Sidebar>
        </SbAnimationContextProvider>
    )
}

SbProfilePage.propTypes = {
    str_userCategory: PropTypes.string.isRequired,
}

export default SbProfilePage
