import React from 'react';

import FormBase from '../../FormBase';
import InFieldsGroup from '../../InFieldsGroup';
import DateField from '../../fields/DateField';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { TaskDateChangeFormData as FormData } from '../../util/form-config-data/InputFormCoursesDataConfigs';

const InputFormTaskDateChange = () => {
    const data = useInputData()
    return (
        <FormBase b_hasDarkDesign={true}>
            <InFieldsGroup b_hasBorder={false}>
                <DateField
                    {...FormData.fields.date}
                    str_date={data[FormData.fields.date.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormTaskDateChange
