/**
 * This file contains the settings objects for the contact data 
 * of profile pages (e.g. gastro and industry users). 
 * This data is used to determine which data is supposed
 * to be displayed in a profile page data group. 
 * It only entails the contents of object (key/value) data.
 *  
 * Arrays and strings are handled differently:
 * If type of value = string: Value not a link. 
 * If type of value = array:  Value is a link.
 */

const entity = {
    country   : 'country',
    county    : 'Bundesland',
    district  : 'Bezirk',
    streetName: 'Straße',
    streetNum : 'Hausnummer',
    postalCode: 'Postleitzahl',
    cityName  : 'Stadt',
    phone     : 'Telefon',
    email     : 'Email',
    webpage   : ['Webpage'],
    linkedin  : ['LinkedIn'],
    twitter   : ['twitter'],
    facebook  : ['facebook'],
    instagram : ['instagram'],
    tiktok    : ['TikTok']
};

const person = {
    firstName   : 'Vorname',
    lastName    : 'Nachname',
    jobPosition : 'Jobposition',
    workLocation: 'Arbeitsstandort',
    phone       : 'Telefon',
    email       : 'Email',
    linkedin    : ['LinkedIn'],
    twitter     : ['twitter'],
    facebook    : ['facebook'],
    instagram   : ['instagram'],
    tiktok      : ['TikTok']
};

const ContactData = {
    entity: entity,
    person: person
};

export default ContactData;