/**
 * This component provides helper functions to message components.
 */

import { genDynUrl } from "../../../util/db_ls_query_handler"

 const genDynConfig = (
    msgId,
    msgCategory,
    msgText,
    msgFiles,
    isOwner,
    baseUrls,
    staticFormConfig
) => {
    /**
     * Generates the dyn. form config for commenting/editing.
     * :Input
     *  msgId (int)
     *  msgCategory (str): The message category (e.g. 'Bemerkung').
     *  msgText     (str): The user's typed in text.
     *  msgFiles    (arr): Items are the file msg's file urls.
     *  isOwner    (bool): Is logged in user the owner of msg?
     *  baseUrls    (obj): Contains all base urls relevant for a message.
     *  staticFormConfig (obj): Post panel form config.
     * :Returns
     *  Dyn. config (obj)
     */
    if (!isOwner) {
        /* User wants to create new comment. */
        const url = genDynUrl(baseUrls.commentBaseUrl, msgId)
        const dynConfig = {str_queryURL: url}
        return dynConfig
    }

    /* User wants to edit his own message. */
    const url = genDynUrl(baseUrls.editBaseUrl, msgId)
    let dynConfig = {
        str_queryURL: url,
        obj_initState: {}
    }
    /* Create new init. states for the form config based on the message data. */
    const mapping = genMap(msgCategory, msgText, msgFiles)
    Object.keys(staticFormConfig.obj_initState).forEach(key => {
        dynConfig.obj_initState[key] = [mapping[key], false]
    })
    return dynConfig
}

const genMap = (msgCategory, msgText, msgFiles) => {
    /**
     * Create objects where keys are the FileField ids and the values
     * are the file urls.
     * :Input
     *  msgCategory (str): The message category (e.g. 'Bemerkung').
     *  msgText     (str): The user's typed in text.
     *  msgFiles    (arr): Items are the file msg's file urls.
     * :Returns
     *  Map (obj)
     */
    const map = {
        category: msgCategory,
        text: msgText
    }
    const baseString = 'file'
    msgFiles.forEach((fileUrl, index) => {
        const fileFieldName = baseString + index
        map[fileFieldName] = fileUrl
    })
    return map
}

const MessageService = {
    genDynPostPanelConfig: genDynConfig
};

export default MessageService;
