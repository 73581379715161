import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import PPFormBase from './PPFormBase';

import useGetPPformData from '../../../custom-hooks/useGetPPformData';
import { static2dynamicPanelConfig } from '../util/input_form_data_handler';

const PPForm = ({
    category,           /* Secondary key for LS. */
    subcategory='',     /* Tercary key for LS. */
    url,                /* URL to fetch data from API. */
    form,               /* Form component. */
    formConfig,         /* Config of form component. */
    isLSused,           /* Identifies if PP data is stored in the LS. */
    hasMediaData=false, /* True if files are uploaded. */
}) => {

    const [dynConfig, setDynConfig] = useState(null)
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    
    useGetPPformData(url, category, subcategory, setDynConfig, setIsFirstFetchSuccess, setErrorMsg)

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess} /* No error message is success. */
            firstQueryDataEntry={-1} /* Only placeholder value. */
            b_isContentSection={true}
            str_errorMsg={errorMsg}
        >
            <PPFormBase
                {...static2dynamicPanelConfig(formConfig, dynConfig)}
                formChild={form}
                arr_lsUpdateKeys={isLSused ? [category, subcategory] : undefined}
                b_hasMediaData={hasMediaData}
            />
        </DataStateCheckWrapper>
    )
}

PPForm.propTypes = {
    category: PropTypes.string.isRequired,
    subcategory: PropTypes.string,
    url: PropTypes.string.isRequired,
    formConfig: PropTypes.object.isRequired,
    isLSused: PropTypes.bool.isRequired
}

export default PPForm
