import React from 'react';

import PrivatePage from '../../network/PrivatePage';
import DynamicContentLoadContextProvider from '../../context-provider/DynamicContentLoadContextProvider';
import StudentPPCompanyAcquisitionCoupons from './StudentPPCompanyAcquisitionCoupons';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';

import Usertypes from '../../../util/Usertypes';
import DynLoadPackageSizes from '../../../util/config/data_load_package_sizes.json';
import { StudentLsKeys } from '../../../util/LocalStorageVariables';

const URL = '/api/industry/company-acquisition-coupon/list/';
const LS_KEY = StudentLsKeys.pp.companyAcquisitionCoupons;
const ALLOWED_USERTYPES = [Usertypes.student];

const serializeCoupon = (coupon) => {
    return {
        id: coupon.id,
        title: coupon.companyName,
        body: {
            str_createDate: coupon.createDate,
            str_expirationDate: coupon.expirationDate
        },
        footer: {
            nbr_id: coupon.id,
            nbr_price: coupon.value,
            b_isRedeemed: !!coupon.redeemDate,
            b_hasExpired: coupon.hasExpired,
            str_redeemTimestamp: coupon.redeemDate
        }
    };
}

const serializeCoupons = (coupons) => {
    /**
     * Serializes the coupon backend data format into a format that
     * can be parsed by the coupon card component.
     */
    return coupons.map(coupon => serializeCoupon(coupon))
}

const StudentPPCompanyAcquisitions = () => {
    useSetBrowserTabTitle('Firmenakquise', true)
    return (
        <PrivatePage arr_usertypes={ALLOWED_USERTYPES}>
            <DynamicContentLoadContextProvider
                str_queryURL={URL}
                nbr_packageSize={DynLoadPackageSizes.profilePage.student.companyAcquisitionCoupons}
                b_useLS={true}
                str_lsKey={LS_KEY}
                fct_serializer={serializeCoupons}
            >
                <StudentPPCompanyAcquisitionCoupons />
            </DynamicContentLoadContextProvider>
        </PrivatePage>
    )
}

export default StudentPPCompanyAcquisitions
