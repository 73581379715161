/**
 * This component stores the data for both 'regular' and 'DoG4S' food offers.
 * 
 * The difference in time is labelled by 'present' and 'future'.
 * By directing the DB query to different URLs the correct data is fetched.
 * 
 * The code comments show more on how this is used.
 */

import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import DataStateCheckWrapper from '../../../general/DataStateCheckWrapper';

import { fetchDBdata } from '../../../../util/db_ls_query_handler';
import FoodOffersSerializer from '../../../../util/serializers/backend_serializers/food_offers_serializer';

const OffersPresentContext = React.createContext();
const OffersFutureContext = React.createContext();
const SetOffersPresentContext = React.createContext();
const SetOffersFutureContext = React.createContext();

export function useOffersPresentContext() {
    return useContext(OffersPresentContext);
}

export function useOffersFutureContext() {
    return useContext(OffersFutureContext);
}

export function useSetOffersPresentContext() {
    return useContext(SetOffersPresentContext);
}

export function useSetOffersFutureContext() {
    return useContext(SetOffersFutureContext);
}

const BASE_URL = '/api/gastronomy/student/food-offers/';

const StudentPPFoodOffersContextProvider = ({
    b_isDgfs,
    children
}) => {
    
    const [offersPresent, setOffersPresent] = useState() /* regular: this week, dgfs: today */
    const [offersFuture, setOffersFuture] = useState()   /* regular: next weeek, dgfs: tomorrow */
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(() => {
        const makeDBrequest = async () => {
            const url = BASE_URL + `${b_isDgfs ? 'dgfs' : 'regular'}/`
            const queryData = await fetchDBdata(url)
            if (queryData.isQuerySuccess) {
                const data = queryData.response.data
                let serializedData
                if (b_isDgfs) {
                    serializedData = FoodOffersSerializer.dbList2foodOfferCardsArray(data.present)
                    setOffersPresent(serializedData)

                    serializedData = FoodOffersSerializer.dbList2foodOfferCardsArray(data.future)
                    setOffersFuture(serializedData)
                } else {
                    serializedData = FoodOffersSerializer.dbList2foodOffersWeekdayObject(data.present)
                    setOffersPresent(serializedData)
    
                    serializedData = FoodOffersSerializer.dbList2foodOffersWeekdayObject(data.future)
                    setOffersFuture(serializedData)
                }
                setIsFirstFetchSuccess(true)
            } else {
                setErrorMsg(queryData.errorMsg)
                setIsFirstFetchSuccess(false)
            }
        }
        makeDBrequest()
    }, [b_isDgfs])

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            firstQueryDataEntry={-1} /* Placeholder to avoid 'no data' rendering (details handled by children). */
            str_errorMsg={errorMsg}
            b_isContentSection={true}
        >
            <OffersPresentContext.Provider value={offersPresent}>
                <OffersFutureContext.Provider value={offersFuture}>
                    <SetOffersPresentContext.Provider value={setOffersPresent}>
                        <SetOffersFutureContext.Provider value={setOffersFuture}>
                            {children}
                        </SetOffersFutureContext.Provider>
                    </SetOffersPresentContext.Provider>
                </OffersFutureContext.Provider>
            </OffersPresentContext.Provider>
        </DataStateCheckWrapper>
    )
}

StudentPPFoodOffersContextProvider.propTypes = {
    b_isDgfs: PropTypes.bool.isRequired
}

export default StudentPPFoodOffersContextProvider
