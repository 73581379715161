import React from 'react';

import PrivatePage from '../../components/network/PrivatePage';
import PageBase from '../../components/page-layout/PageBase';
import CSecBase from '../../components/content-sections/CSecBase';
import FilterCompanies from '../../components/filters/filters/industry/FilterCompanies';
import TbCompanies from '../../components/tables/industry/companies/TbCompanies';
import DynamicContentLoadBtn from '../../components/buttons/DynamicContentLoadBtn';
import DynamicContentLoadContextProvider from '../../components/context-provider/DynamicContentLoadContextProvider';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

import DynLoadPackageSizes from '../../util/config/data_load_package_sizes.json';
import Usertypes from '../../util/Usertypes';
import { AppIndustryLsKeys } from '../../util/LocalStorageVariables';

const LS_KEY = AppIndustryLsKeys.companiesListing;
const URL = '/api/industry/company/list/';

const CompaniesListingPage = () => {
    useSetBrowserTabTitle('Unternehmen')
    return (
        <PrivatePage
            arr_usertypes={[Usertypes.company, Usertypes.student]}
            b_isStudentFreeTier={true}
        >
            <PageBase>
                <DynamicContentLoadContextProvider
                    str_queryURL={URL}
                    str_lsKey={LS_KEY}
                    b_useLS={true}
                    b_hasFetchById={false}
                    nbr_packageSize={DynLoadPackageSizes.industry.company.listPage}
                >
                    <CSecBase
                        b_isUsedAsTitle={true}
                        str_title='Unternehmen'
                        str_subtitle='Unsere Partner aus der Industrie'
                    />
                    <FilterCompanies />
                    <CSecBase>
                        <TbCompanies b_isDynamic={true} />
                        <DynamicContentLoadBtn />
                    </CSecBase>
                </DynamicContentLoadContextProvider>
            </PageBase>
        </PrivatePage>
    )
}

export default CompaniesListingPage
