import React from 'react';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageBase from '../../../components/page-layout/PageBase';
import SbMcGrid from '../../../components/grids/SbMcGrid';
import SbCourseTasks from '../../../components/sidebars/courses/exams/SbCourseTasks';
import SbCourseTaskContextProvider from '../../../components/sidebars/context-provider/SbCourseTasksContextProvider';
import DiscussionCacheContextProvider from '../../../components/discussion-pages/context-provider/DiscussionCacheContextProvider';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';

const TasksListingPage = ({
    str_baseUrl,
    str_identifier /* Identifies the task (e.g. exam or exercise). */
}) => {
    const params = useParams()
    const location = useLocation()
    const getTaskNameFromUrl = () => {
        return location.pathname.includes('exams') ? 'Prüfungen' : 'Übungen'
    }
    useSetBrowserTabTitle(getTaskNameFromUrl, false, true, true)
    return (
        <PageBase
            str_pageSpacingClasses='page-spacing-sb-wrapper'
            b_isGridPage={true}
        >
            <SbCourseTaskContextProvider str_baseUrl={str_baseUrl}>
                <SbMcGrid comp_sb={<SbCourseTasks str_identifier={str_identifier} />}>
                    <DiscussionCacheContextProvider str_pageTaskId={params.questionId}>
                        <Outlet />
                    </DiscussionCacheContextProvider>
                </SbMcGrid>
            </SbCourseTaskContextProvider>
        </PageBase>
    )
}

TasksListingPage.propTypes = {
    str_baseUrl: PropTypes.string.isRequired,
    str_identifier: PropTypes.string.isRequired
}

export default TasksListingPage
