import { useEffect } from 'react';
import { fetchLSorDBdata, setLSdata } from '../util/db_ls_query_handler';

const PP_LS_KEY = 'ppData';

export default function useGetPPdataGroupData(
    str_queryURL,
    str_category,
    fct_setData,
    fct_setIsFetchSuccess,
    fct_setErrorMsg,
    b_isLSused=true /* true if data should be stored in LS, false otherwise. */
) {
    useEffect(() => {
        const func = async () => {
            const queryData = await fetchLSorDBdata(str_queryURL, PP_LS_KEY, str_category)
            if (queryData.isQuerySuccess) {
                const data = queryData.response.data
                fct_setData(data)
                fct_setIsFetchSuccess(true)
                if (b_isLSused)
                    setLSdata(data, PP_LS_KEY, str_category)
            } else {
                fct_setIsFetchSuccess(false)
                fct_setErrorMsg(queryData.errorMsg)
            }
        }
        func()
    }, [])
}