import React from 'react';
import PropTypes from 'prop-types';

import TbRowIndustryOfflineJobs from './TbRowIndustryOfflineJobs';
import NoPPDataAvailable from '../../../profile-pages/NoPPDataAvailable';
import { useJobsData } from './IndustryPPJobsContextProvider';

const TbIndustryOfflineJobs = ({
    str_textAlignment='text-left',
    str_design='dark-grey'
}) => {
    const jobs = useJobsData()
    return (
        jobs.offline && jobs.offline[0] /* Key must be there and array must not be empty. */
        ?
        <div className='tb-wrapper'>
            <table cellSpacing="0" className={`tb-base ${str_textAlignment} ${str_design}`}>
                <thead>
                    <tr>
                        <td>Titel</td>
                        <td className="hide-max-w-576px">Gepostet</td>
                        <td className="hide-max-w-1024px">Start</td>
                        <td>Löschen</td>
                        <td>Wiederverwenden</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        jobs.offline.map((item, index) => (
                            <TbRowIndustryOfflineJobs
                                key={index}
                                obj_rowData={item}
                            />
                        ))
                    }
                </tbody>
            </table>
        </div>
        :
        <NoPPDataAvailable str_message='Du hast keine Jobs offline.' />
    )
}

TbIndustryOfflineJobs.propTypes = {
    str_textAlignment: PropTypes.string,
    str_design: PropTypes.string
}

export default TbIndustryOfflineJobs
