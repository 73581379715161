import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageBase from '../../../components/page-layout/PageBase';
import CSecBase from '../../../components/content-sections/CSecBase';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';

const TasksYearListingPage = ({
    str_title,
    comp_table
}) => {
    const location = useLocation()
    const getTaskNameFromUrl = () => {
        return location.pathname.includes('exams') ? 'Prüfungen' : 'Übungen'
    }
    useSetBrowserTabTitle(getTaskNameFromUrl, false, true, true)
    return (
        <PageBase>
            <CSecBase str_title={str_title}>
                {comp_table}
            </CSecBase>
        </PageBase>
    )
}

TasksYearListingPage.propTypes = {
    str_title: PropTypes.string.isRequired,
}

export default TasksYearListingPage
