import React, { useState } from 'react';

import PrivatePage from '../../network/PrivatePage';
import Modal from '../../modal/Modal';
import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import CardCoupon from '../../cards/store/CardCoupon';
import CSecGridBase from '../../content-sections/CSecGridBase';

import CouponRedeemService from '../../../util/coupons/coupon_redeem_service';
import Usertypes from '../../../util/Usertypes';
import CouponSerializer from '../../../util/serializers/backend_serializers/coupon_serializers';

import useGetDBdata from '../../../custom-hooks/useGetDBdata';
import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import { gridColsFromNumItems } from '../../../util/data_handler';
import {
    useIsModalOpenContext,
    useModalObjectId
} from '../../modal/context-provider/ModalContextProvider';

const URL = '/api/profiles/student/purchased-coupons/';

const USERTYPE_STUDENT = Usertypes.student;
const MC_CURRENCY = 'MC';

const TITLE = 'Gutscheine';
const SUBTITLE = 'Liste deiner im Store gekauften Gutscheine';
const NO_COUPONS_OWNED_MSG = 'Du besitzt im Moment keine Gutscheine.';

const StudentPPCoupons = () => {

    const [data, setData] = useState([])
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState()
    const [modalCoupon, setModalCoupon] = useState()
    const [isRedeemSuccess, setIsRedeemSuccess] = useState()

    const isModalOpen = useIsModalOpenContext()
    const modalObjectId = useModalObjectId()

    const numGridCols = gridColsFromNumItems(data.length === 0 ? 1 : 2)

    useSetBrowserTabTitle('Gutscheine', true)

    useGetDBdata(
        URL,
        setData,
        setIsFirstFetchSuccess,
        setErrorMsg,
        CouponSerializer.dbList2couponCardsArray
    )

    /* Coupon redemption. */

    CouponRedeemService.useSetModalCoupon(modalObjectId, data, setModalCoupon)
    CouponRedeemService.useRestoreModalInitState(isModalOpen, setIsRedeemSuccess)

    const updateModalCouponData = (modalCoupon) => {
        modalCoupon.footer.numAvailable--
        modalCoupon.footer.numRedeemed++
    }
    
    const redeem = (response) => {
        CouponRedeemService.redeemCoupon(
            response,
            modalCoupon,
            data,
            setData,
            updateModalCouponData
        )
        setIsRedeemSuccess(true)
    }

    return (
        <PrivatePage arr_usertypes={[Usertypes.student]}>
            <CSecGridBase
                str_title={TITLE}
                str_subtitle={SUBTITLE}
                str_gridClass={`card-grid ${numGridCols}`}
                str_secBaseClasses='pp-mc-section'
            >
                <DataStateCheckWrapper
                    b_hasQueryCheck={true}
                    b_isFirstFetchSuccess={isFirstFetchSuccess}
                    firstQueryDataEntry={data[0]} /* Placeholder value (never used). */
                    str_errorMsg={errorMsg}
                    str_noDataMsg={NO_COUPONS_OWNED_MSG}
                    b_isContentSection={true}
                >
                    {
                        data.map((coupon, index) => (
                            <CardCoupon
                                key={index}
                                nbr_id={coupon.id}
                                str_usertype={USERTYPE_STUDENT}
                                str_title={coupon.title}
                                str_currency={MC_CURRENCY}
                                str_eyebrow={coupon.provider}
                                obj_bodyData={coupon.body}
                                obj_footerData={coupon.footer}
                                b_hasRedeemView={true}
                            />
                        ))
                    }
                </DataStateCheckWrapper>
            </CSecGridBase>
            {
                /* Modal view of a coupon if it is supposed to be redeemed. */
                isModalOpen &&
                <Modal b_isSuccess={isRedeemSuccess}>
                    <CardCoupon
                        nbr_id={modalCoupon ? modalCoupon.id : -1} /* -1 is a placeholder, never used */
                        str_usertype={USERTYPE_STUDENT}
                        str_title={modalCoupon ? modalCoupon.title : ''}
                        str_currency={MC_CURRENCY}
                        str_eyebrow={modalCoupon ? modalCoupon.provider : ''}
                        obj_bodyData={modalCoupon ? modalCoupon.body : {}}
                        obj_footerData={modalCoupon ? modalCoupon.footer : {}}
                        b_hasRedeemView={true}
                        b_hasModalView={true}
                        b_isRedeemSuccess={isRedeemSuccess}
                        fct_handleRedeem={redeem}
                        />
                </Modal>
            }
        </PrivatePage>
    )
}

export default StudentPPCoupons