import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';

import WelcomeFooter from '../../components/welcome/WelcomeFooter';
import WelcomeContextProvider from '../../components/welcome/WelcomeContextProvider';
import { genEyebrow } from '../../components/welcome/util/welcome_util';

import logo from '../../assets/images/logos/welcome_page_logo.png';

const WelcomePage = () => {
    
    const location = useLocation()
    const history = useNavigate()
    const [eyebrow, setEyebrow] = useState(genEyebrow(location.pathname))

    useEffect(() => {
        setEyebrow(genEyebrow(location.pathname))
    }, [history])

    return (
        <div className="limiter">
            <div className="container-wf">
                <div className="wrap-wf">
                    <div className="header-wf">
                        <div className="eyebrow-wf">
                            <h4>{eyebrow}</h4>
                        </div>
                        <div className="muplaza-logo">
                            <img src={logo} alt="MUPLAZA Logo" />
                        </div>
                    </div>
                    <WelcomeContextProvider>
                        <Outlet />
                        <WelcomeFooter />
                    </WelcomeContextProvider>
                </div>
            </div>
        </div>
    )
}

export default WelcomePage
