/**
 * Queries data from the DB or the LS onmount of the component and sets the states accordingly.
 */

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchLSorDBdata, setLSdata } from '../util/db_ls_query_handler';
 
export default function useGetLSorDBdata(
    str_url,
    fct_setData,                /* Set the data of the component. */
    fct_setIsFirstFetchSuccess, /* Sets true/false depending on success of query. */
    fct_setErrorMsg,            /* Set error message the query yields upon failure. */
    b_storeQueryData2ls=false,  /* Should the data from the DB stored in the LS? */
    fct_dataSerializer,         /* Function to serialize the queried data (if desired). */
    serializerConfig,           /* Config data for the serializer (e.g. for ObjectSerializer.js). */
    str_prLsKey='',             /* Primary localStorage key. */ 
    str_secLsKey='',            /* Secondary localStorage key. */
    str_terLsKey='',            /* Tercary localStorage key. */
    b_executeOnUrlChange=false  /* Execute hook on url change. */
) {

    /* Decide when the react hook should be executed. */
    const navigate = useNavigate()
    const activate = b_executeOnUrlChange ? [navigate] : []

    useEffect(() => {
        if (!str_url) return

        const func = async () => {
            const queryData = await fetchLSorDBdata(
                str_url,
                str_prLsKey,
                str_secLsKey,
                str_terLsKey,
            )
            if (queryData.isQuerySuccess) {
                /* Store unserialized data in LS. */
                if (b_storeQueryData2ls) setLSdata(queryData.response.data, str_prLsKey, str_secLsKey, str_terLsKey)
                
                let data;
                if (fct_dataSerializer) {
                    if (serializerConfig) {
                        data = fct_dataSerializer(queryData.response.data, serializerConfig)
                    } else {
                        data = fct_dataSerializer(queryData.response.data)
                    }
                } else {
                    data = queryData.response.data
                }

                fct_setData(data)
                fct_setIsFirstFetchSuccess(true)
            } else {
                fct_setIsFirstFetchSuccess(false)
                fct_setErrorMsg(queryData.errorMsg)
            }
        }

        func()
    }, activate)
}