/**
 * This file contains the usertype names.
 * 
 * In case the string of a user type might change in the future,
 * only this file needs to be adjusted.
 * 
 */

const Usertypes = {
    company: 'company',
    gastronomer: 'gastronomer',
    student: 'student'
};

export default Usertypes;
