/**
 * In order to use this component easily, the following illustrates 
 * an array input for the icons. 
 * 
 * {
 *    title: 'Lieferung',                    // Text displayed below the icon (optional).
 *    icon: <TbTruckDelivery />,             // Selected from reac-icons (mandatory). 
 *    class: 'icon-gastro-delivery-consume', // This must be defined in the CSS (mandatory). 
 *    url: 'some url' // Only if it clicking the icon should link somewhere (optional).
 *  }
 * 
 */

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import GridBase from './GridBase';
import HandleNoData from '../general/HandleNoData';
import { gridColsFromNumItems } from '../../util/data_handler';

const IconGrid = ({
    str_gridClass='',
    arr_iconTitleAvailability=[] /* User defined option if icon data is available. */
}) => {
    const numGridCols = gridColsFromNumItems(arr_iconTitleAvailability.length)
    return (
        arr_iconTitleAvailability && arr_iconTitleAvailability[0] !== undefined
        ?
        <GridBase str_gridClass={`icon-grid ${numGridCols} ${str_gridClass}`} >
            {
                arr_iconTitleAvailability.map((item, index) => (
                    <div className="grid-item" key={index}>
                        <div
                            className={`content-wrapper ${item.class} ${item.data ? '' : 'icon-not-available'}`}
                        >
                            <div className="icon">
                                {
                                    item.url
                                    ?
                                    <Link to={item.url} target="_blank" rel="noopener noreferrer">
                                        {item.icon}
                                    </Link>
                                    :
                                    item.icon
                                }
                            </div>
                            <div className="icon-text">
                                <p>{item.title}</p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </GridBase>
        :
        <HandleNoData />
    )
}

IconGrid.propTypes = {
    str_gridClass: PropTypes.string,
    arr_iconTitleAvailability: PropTypes.array
}

export default IconGrid
