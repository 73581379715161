import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import FieldWrapper from './FieldWrapper';
import InFieldConfirmationValue from '../InFieldConfirmationValue';

import { nbrToStringWithThousanderSeparation } from '../../../util/data_handler';
import { 
    isRequiredFulfilled,
    isMinValueBoundValid,
    isMaxValueBoundValid
} from '../util/input_checks';
import { 
    useHandleInputChange,
    useAreRequiredErrorsActive,
    useHandleDiscardSubmitFct
} from '../context-provider/InputFormContextProvider';
import { useIsConfirmationViewOpenContext } from '../context-provider/FormBaseContextProvider';
import FieldErrorMsgs from '../util/input_form_fields_error_msgs.json';

const STD_FLOAT_INFO_MSG = 'Je nach Browser-Sprache muss als Komma ' +
    'entweder ein Beistrich (,) oder ein Punk (.) eingegeben werden.';
const REQUIRED_EMSG = FieldErrorMsgs.required;
const MIN_VALUE_EMSG = FieldErrorMsgs.number.min;
const MAX_VALUE_EMSG = FieldErrorMsgs.number.max;

const bottomInfoText = (isIntField, bottomInfoText) => {
    /**
     * :Input
     *  isIntField    (bool): The input prop of this component.
     *  bottomInfoText (str): The input prop of this component.
     */
    if (isIntField) return bottomInfoText;
    if (!bottomInfoText) return STD_FLOAT_INFO_MSG;
    return bottomInfoText + ' ' + STD_FLOAT_INFO_MSG;
}

const NumberField = ({
    str_id,
    str_fieldTitle='',
    nbr_value=undefined,
    nbr_minValue,
    nbr_maxValue,
    nbr_numDecimals=2,
    b_isIntField=false,
    b_isRequired=false,
    b_isYearField=false,
    b_isTopInfoTextDisplayed=true,
    str_bottomInfoText=''
}) => {

    const [value, setValue] = useState(nbr_value)
    const [errorMsg, setErrorMsg] = useState('')
    const [isFocused, setIsFocused] = useState(false)

    const handleInputChange = useHandleInputChange()
    const isRequiredErrorActivate = useAreRequiredErrorsActive()
    const handleDiscardSubmit = useHandleDiscardSubmitFct()
    const isConfirmationViewOpen = useIsConfirmationViewOpenContext()
        
    const hasError = (nbr_value) => {
        const v = nbr_value === undefined ? value : nbr_value
        if (b_isRequired && !isRequiredFulfilled(v)) {
            setErrorMsg(REQUIRED_EMSG)
            return true
        }
        if (v !== null && v !== undefined && v !== '') {
            if (!isMinValueBoundValid(v, nbr_minValue)) {
                setErrorMsg(MIN_VALUE_EMSG)
                return true
            }
            if (!isMaxValueBoundValid(v, nbr_maxValue)) {
                setErrorMsg(MAX_VALUE_EMSG)
                return true
            }
        }
        setErrorMsg('')
        return false
    }
    
    useMemo(() => {
        /* All the required fields that are blank and do not show erros,
         * are activated when a form is submitted. The line below is only 
         * executed once. After the first execution, the required field 
         * displays error messages until the input is correct. */
        if (isRequiredErrorActivate && b_isRequired) hasError()
    }, [isRequiredErrorActivate])

    useMemo(() => {
        /* If new input is received, update the current char state.
         * Resetting the value to 'undefined' does not change the value of
         * the <input>. Replace it by an empty string in this case. */
        setValue(typeof(nbr_value) === 'number' ? nbr_value : '')
    }, [nbr_value])

    const topInfoText = useMemo(() => {
        if (!b_isTopInfoTextDisplayed) return ''
        let str_topInfoText = ''
        if (nbr_minValue !== undefined) {
            const minValue = b_isYearField ? nbr_minValue : nbrToStringWithThousanderSeparation(nbr_minValue)
            str_topInfoText = 'Min. Wert: ' + minValue
        }
        if (nbr_maxValue !== undefined) {
            if (str_topInfoText !== '') str_topInfoText += ', '
            const maxValue = b_isYearField ? nbr_maxValue : nbrToStringWithThousanderSeparation(nbr_maxValue)
            str_topInfoText += 'Max. Wert: ' + maxValue
        }
        return str_topInfoText
    }, [nbr_minValue, nbr_maxValue])

    const onChange = (e) => {
        /* Set state to new value. */
        let value
        if (b_isIntField) {
            value = parseInt(e.target.value, 10)
        } else {
            value = parseFloat(e.target.value, 10)
        }
        value = isNaN(value) ? undefined : value
        value = value || value === 0 ? Number(Number(value).toFixed(nbr_numDecimals)) : undefined
        
        setValue(value)

        /* Send data to input form context provider. */
        handleInputChange({
            id: str_id,
            value: value,
            hasError: hasError(value)
        })
    }

    const onFocus = () => {
        setIsFocused(true)
    }

    const onBlur = () => {
        /**
         * Display errors if existent when the user leaves the input field.
         */
        hasError()
        setIsFocused(false)
        /* Set the value of the dom element field to a number with nbr_numDecimals. */
        const domEl = document.querySelector(`#${str_id}`)
        domEl.value = Number(Number(domEl.value).toFixed(nbr_numDecimals))
    }

    const onKeyDown = (e) => {
        /**
         * Submit the input form if user hits 'Enter'.
         */
        if (e.key === 'Enter') handleDiscardSubmit(true, e)
    }

    return (
        isConfirmationViewOpen
        ?
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            b_isRequired={false}
        >
            <InFieldConfirmationValue value={value} />
        </FieldWrapper>
        :
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            str_errorMsg={errorMsg}
            str_topInfoText={topInfoText}
            str_bottomInfoText={bottomInfoText(b_isIntField, str_bottomInfoText)}
            b_isRequired={b_isRequired}
            b_isFieldFocused={isFocused}
        >
            <div className={`in-field ${b_isIntField ? 'in-field-int' : 'in-field-float'}`}>
                <input
                    type="number"
                    name="int-field" 
                    min={nbr_minValue}
                    max={nbr_maxValue}
                    required={b_isRequired}
                    id={str_id}
                    value={value}
                    onKeyDown={(e) => onKeyDown(e)}
                    onChange={e => onChange(e)}
                    onFocus={() => onFocus()}
                    onBlur={() => onBlur()}
                />
            </div>
        </FieldWrapper>

    )
}

NumberField.propTypes = {
    str_id: PropTypes.string.isRequired,
    str_fieldTitle: PropTypes.string,
    nbr_value: PropTypes.number,
    nbr_minValue: PropTypes.number.isRequired,
    nbr_maxValue: PropTypes.number.isRequired,
    nbr_numDecimals: PropTypes.number,
    b_isIntField: PropTypes.bool,
    b_isRequired: PropTypes.bool,
    b_isYearField: PropTypes.bool,
    b_isTopInfoTextDisplayed: PropTypes.bool,
    str_bottomInfoText: PropTypes.string
}

export default NumberField

