import React from 'react';

import InFieldsGroup from '../../InFieldsGroup';
import FormBase from '../../FormBase';
import TextField from '../../fields/TextField';
import NumberField from '../../fields/NumberField';
import SelectiveProposalField from '../../fields/SelectiveProposalField';

import FieldLimits from '../../util/db_field_limits.json';
import { useInputData } from '../../context-provider/InputFormContextProvider';
import { CoursesTutoringFormData as FormData } from '../../util/form-config-data/InputFormCoursesDataConfigs';

const MAX_NUM_TUTOR_OFFERS = FieldLimits.course.swap.offer.maxNumOnline;
const MAX_NUM_DAYS_ONLINE = FieldLimits.course.swap.offer.maxNumDaysOnline;
const HEADER_INFOS = [
    `Du kannst max. ${MAX_NUM_TUTOR_OFFERS} Tutor-Angebote gleichzeitig posten.`,
    `Tutor-Angebote werden nach ${MAX_NUM_DAYS_ONLINE} Tagen automatisch von der ` +
    'Plattform genommen. Damit wird gewährleistet, dass nur aktuelle Angebote online sind.'
]

const InputFormCoursesTutoring = () => {
    const data = useInputData()
    return (
        <FormBase
            str_title='Werde Tutor'
            arr_headerInfos={HEADER_INFOS}
        >
            <InFieldsGroup b_hasBorder={false}>
                <SelectiveProposalField
                    {...FormData.fields.courseTitle}
                    arr_proposals={data[FormData.fields.courseTitle.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.yearCoursePassed}
                    nbr_value={data[FormData.fields.yearCoursePassed.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.numCompletedSemesters}
                    nbr_value={data[FormData.fields.numCompletedSemesters.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.numHoursTutored}
                    nbr_value={data[FormData.fields.numHoursTutored.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.pricePerHour}
                    nbr_value={data[FormData.fields.pricePerHour.str_id][0]}
                />
                <TextField
                    {...FormData.fields.email}
                    str_chars={data[FormData.fields.email.str_id][0]}
                />
                <TextField
                    {...FormData.fields.phone}
                    str_chars={data[FormData.fields.phone.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormCoursesTutoring
