import React from 'react';
import { Outlet } from 'react-router-dom';

import PrivatePage from '../../components/network/PrivatePage';
import PageBase from '../../components/page-layout/PageBase';
import FoodOffersListingPageContextProvider from '../../components/listing-pages/food/FoodOffersListingPageContextProvider';

import Usertypes from '../../util/Usertypes';
import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const ALLOWED_USERTYPES = [Usertypes.gastronomer, Usertypes.student];

const FoodOffersListingPage = () => {
    useSetBrowserTabTitle('Speiseangebote')
    return (
        <PrivatePage arr_usertypes={ALLOWED_USERTYPES}>
            <PageBase>
                <FoodOffersListingPageContextProvider>
                    <Outlet />
                </FoodOffersListingPageContextProvider>
            </PageBase>
        </PrivatePage>
    )
}

export default FoodOffersListingPage
