import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import CSecBase from '../content-sections/CSecBase';
import CSecGridBase from '../content-sections/CSecGridBase';
import NoPPDataAvailable from './NoPPDataAvailable';
import CardCoupon from '../cards/store/CardCoupon';

import {
    useCouponsThisSemesterContext,
    useCouponsNextSemesterContext,
    useCouponsOldContext,
    useCouponBudgetContext
} from './context-provider/PPCouponsContextProvider';
import { gridColsFromNumItems } from '../../util/data_handler';

const CURRENT_SEMESTER = 'currentSemester';
const NEXT_SEMESTER = 'nextSemester';
const OLD = 'old';

const genDataKey = (b_isOld, b_isCurrentSemester) => {
    return b_isOld ? OLD : b_isCurrentSemester ? CURRENT_SEMESTER : NEXT_SEMESTER;
}

const computeCouponValues = (arr_coupons) => {
    /**
     * Sums the value of all created coupons of a semester and returns it.
     */
    let totalValue = 0;
    arr_coupons.forEach(coupon => totalValue += coupon.footer.price * coupon.footer.numAvailable)
    return totalValue;
}

const genThisSemSubtitle = (arr_coupons, couponBudget) => {
    /**
     * Generates the subtitle string for this semester.
     */
    let totalAvailable = 0;
    let totalPurchased = 0;
    let totalRedeemed = 0;
    arr_coupons.forEach(coupon => {
        totalAvailable += coupon.footer.numAvailable
        totalPurchased += coupon.footer.numPurchased
        totalRedeemed += coupon.footer.numRedeemed
    })
    const usedCouponBudget = computeCouponValues(arr_coupons);
    const subtitle = `${usedCouponBudget}/${couponBudget} € Erstellt | ${totalAvailable} ` +
        `Ausgestellt | ${totalPurchased} Verkauft | ${totalRedeemed} Eingelöst`;
    return subtitle;
}

const genSubtitle = (arr_coupons, dataKey, couponBudget) => {
    /**
     * Generates information for every category that is useful for the voucher creator.
     * How many coupons are created, how much of the committed money have you spent,
     * how many coupons have been bought/redeemed.
     * :Returns
     *  String of the above info, which is displayed as subtitle of the content section.
     */
    if (dataKey === CURRENT_SEMESTER) {
        return genThisSemSubtitle(arr_coupons, couponBudget);
    }
    else if (dataKey === NEXT_SEMESTER) {
        return `${computeCouponValues(arr_coupons)}/${couponBudget} € Erstellt`;
    }
    else if (dataKey === OLD) {
        return `${arr_coupons.length}/20 Ausgeschöpft`;
    }
    else {
        return '';
    }
}

const PPCoupons = ({
    b_isCurrentSemester=true,
    b_isOld=false
}) => {
    
    const [data, setData] = useState()
    const [dataKey, setDataKey] = useState(genDataKey(b_isOld, b_isCurrentSemester))
    const navigate = useNavigate()

    const dataHooks = {
        currentSemester: useCouponsThisSemesterContext(),
        nextSemester: useCouponsNextSemesterContext(),
        old: useCouponsOldContext()
    }
    const couponBudget = useCouponBudgetContext()
    
    const numGridCols = gridColsFromNumItems(2)

    useEffect(() => {
        /**
         * Set data state for the current URL.
         */
        const newDataKey = genDataKey(b_isOld, b_isCurrentSemester)
        setDataKey(newDataKey)
        const arr_data = dataHooks[newDataKey]
        setData(arr_data)
    }, [navigate, dataHooks])

    return (
        Array.isArray(data) && data[0]
        ?
        <CSecGridBase
            str_title='Gutscheine'
            str_subtitle={genSubtitle(dataHooks[dataKey], dataKey, couponBudget)}
            str_gridClass={`card-grid ${numGridCols}`}
            str_secBaseClasses='pp-mc-section'
        >
            {
                data.map((coupon, index) => (
                    <CardCoupon
                        key={index}
                        nbr_id={coupon.id}
                        dataKey={dataKey}
                        str_title={coupon.title}
                        str_currency='MC'
                        str_eyebrow={coupon.provider}
                        obj_bodyData={coupon.body}
                        obj_footerData={coupon.footer}
                        b_hasInfoView={dataKey !== 'old'}
                    />
                ))
            }
        </CSecGridBase>
        :
        <CSecBase
            str_title='Gutscheine'
            str_subtitle={genSubtitle(dataHooks[dataKey], dataKey, couponBudget)}
            str_classes='pp-mc-section'
            b_hasCenteredText={true}
        >
            <NoPPDataAvailable />
        </CSecBase>
    )
}

PPCoupons.propTypes = {
    b_isCurrentSemester: PropTypes.bool,
    b_isOld: PropTypes.bool
}

export default PPCoupons
