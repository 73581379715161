import React, { useEffect } from 'react';

import InputFormPageBase from '../../components/input-forms/InputFormPageBase';
import InputFormGeneralContact from '../../components/input-forms/forms/contact/InputFormGeneralContact';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';
import { static2dynamicPanelConfig } from '../../components/input-forms/util/input_form_data_handler';

import { GeneralContactFormData as FormData } from '../../components/input-forms/util/form-config-data/InputFormContactDataConfigs';

const DYN_CONFIG = { 'str_queryURL': '/api/contact/contact-form/list/' }

const GeneralContactPage = () => {
    useSetBrowserTabTitle('Kontakt')
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <InputFormPageBase
            {...static2dynamicPanelConfig(FormData, DYN_CONFIG)}
            str_requestType='post'
            comp_form={<InputFormGeneralContact />}
            b_hasDiscardBtn={false}
        />
    )
}

export default GeneralContactPage
