import React from 'react';

import MessageStdBodyText from '../MessageStdBodyText';
import MessageStdBodyFiles from '../MessageStdBodyFiles';
import MessageTags from '../MessageTags';

import { useMsgContext } from '../context-provider/MessageContextProvider';
import { bool2yesNo } from '../../../util/data_handler';

const DocInfoMessageBody = () => {
    const msg = useMsgContext()
    return (
        <div className='msg-body doc-info-msg'>
            <div className='container-content grid-container doc-info-grid'>
                <div className='grid-item hard-fact'>
                    <div className='title'>Kategorie</div>
                    <div className='data'>{msg.category}</div>
                </div>
                <div className='grid-item hard-fact'>
                    <div className='title'>Offizielles Dokument</div>
                    <div className='data'>
                        {bool2yesNo(msg.isOfficial)}
                    </div>
                </div>
                <div className='grid-item hard-fact'>
                    <div className='title'>Validierte Antworten</div>
                    <div className='data'>
                        {bool2yesNo(msg.hasValidatedAnswers)}
                    </div>
                </div>
                <div className='grid-item hard-fact'>
                    <div className='title'>Peer reviewed</div>
                    <div className='data'>
                        {bool2yesNo(msg.isPeerReviewed)}
                    </div>
                </div>
                <div className='grid-item hard-fact'>
                    <div className='title'>Qualitätsstufe</div>
                    <div className='data'>{msg.qualityRank}</div>
                </div>
            </div>
            <div className='container-content'>
                <div className='regular-content'>
                    <div className='title'>Beschreibung</div>
                    <div className='data'>
                        <MessageStdBodyText text={msg.description} />
                    </div>
                </div>
            </div>
            <div className='container-content'>
                <div className='regular-content'>
                    <div className='title'>Dokument</div>
                    <div className='data'>
                        <MessageStdBodyFiles arr_files={[msg.docFile]} />
                    </div>
                </div>
            </div>
            <div className="container-content no-margin-bottom">
                <div className="regular-content">
                    <MessageTags str_tagnames={msg.tagnames} />
                </div>
            </div>
        </div>
    )
}

export default DocInfoMessageBody
