import React from 'react';
import PropTypes from 'prop-types';

import { useOpenCloseFormContext } from '../content-sections/context-provider/DiscussionNewPostContextProvider';

const DiscussionContentBtn = ({ str_btnTitle }) => {
    const openCloseForm = useOpenCloseFormContext()
    return (
        <div>
            <div className="new-bar answer-bar">
                <button
                    className="btn btn-md"
                    onClick={() => {openCloseForm(true)}}
                >
                    {str_btnTitle}
                </button>
            </div>
        </div>
    )
}

DiscussionContentBtn.propTypes = {
    str_btnTitle: PropTypes.string.isRequired
}

export default DiscussionContentBtn
