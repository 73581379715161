import React from 'react';
import PropTypes from 'prop-types';

const AccordionHeader = ({
    isDirectylyBelowAccordionKey=false,
    children
}) => {
    return (
        <h3 className={`${isDirectylyBelowAccordionKey ? 'below-accordion-key' : ''}`}>
            {children}
        </h3>
    )
}

AccordionHeader.propTypes = {
    isDirectylyBelowAccordionKey: PropTypes.bool.isRequired
}

export default AccordionHeader
