import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import FieldWrapper from './FieldWrapper';
import InFieldConfirmationValue from '../InFieldConfirmationValue';

import { 
    extractFileNameFromPath,
    nbrToStringWithThousanderSeparation,
    fileSizeToString,
    extractFileExtension
} from '../../../util/data_handler';
import { 
    useHandleInputChange,
    useAreRequiredErrorsActive
} from '../context-provider/InputFormContextProvider';
import { getNumBytesFromValue } from '../../../util/data_handler';
import { useIsConfirmationViewOpenContext } from '../context-provider/FormBaseContextProvider';
import { IMAGE_FILE_TYPES } from '../util/form-config-data/InputFormBaseDataAndSerializer';
import FieldErrorMsgs from '../util/input_form_fields_error_msgs.json';

const REQ_EMSG = FieldErrorMsgs.required;
const FILE_SIZE_EMSG = FieldErrorMsgs.file.size;
const FILE_TYPE_EMSG = FieldErrorMsgs.file.type;
const TYPE_OBJECT = 'object';

const fileSizeInfo = (str_fileSize, nbr_maxSize) => {
    /**
     * :Input
     *  str_fileSize: Outpuf from function 'fileSizeToString'
     *  nbr_maxSize: Function input argument. 
     * :Returns
     *  String of format (nbr/maxNbr Unit), e.g. (20.4/40 MB)
     */
    const int_len = str_fileSize.length;
    const str_lastTwoChars = str_fileSize.slice(int_len-2,);
    const str_digits = str_fileSize.split(' ')[0];
    let str_ending;
    if (str_lastTwoChars === 'KB') {
        const float_maxValue = nbr_maxSize / getNumBytesFromValue(1, 'kb');
        str_ending = nbrToStringWithThousanderSeparation(float_maxValue.toFixed(2)) + ' KB';
    } else if (str_lastTwoChars === 'MB') {
        const float_maxValue = nbr_maxSize / getNumBytesFromValue(1, 'mb');
        str_ending = nbrToStringWithThousanderSeparation(float_maxValue.toFixed(2)) + ' MB';
    } else {
        /* Size is 'B'. */
        str_ending = nbrToStringWithThousanderSeparation(nbr_maxSize.toFixed(2)) + ' B';
    }
    return ' (' + str_digits + ' / ' + str_ending + ')';
}

const createFileSizeErrorMsg = (nbr_size, nbr_maxFileSize) => {
    const str_fsize = fileSizeToString(nbr_size);
    const str_fsizeInfo = fileSizeInfo(str_fsize, nbr_maxFileSize);
    return FILE_SIZE_EMSG + str_fsizeInfo;
}

const createTopInfoText = (arr_fileTypes, nbr_maxFileSize) => {
    const str_fTypes = arr_fileTypes[0] ? 'Erlaubte Dateitypen: ' + arr_fileTypes.join(', ') + '. ' : '';
    const str_fSize = 'Max. Dateigröße: ' + fileSizeToString(nbr_maxFileSize * getNumBytesFromValue(1, 'mb'));
    if (str_fTypes && str_fSize) {
        return str_fTypes + '\n' + str_fSize;
    } else {
        return str_fTypes + str_fSize;
    }
}

const FileField = ({
    str_id,
    str_fieldTitle='',
    str_bottomInfoText='',
    inFile=null,             /* Either object (uploaded file), null (init. state) or string (URL from DB FileField). */
    nbr_maxSize,             /* Number must be put in as MB, e.g. nbr_maxSize = 1 is 1 MB. */
    arr_allowedFileTypes=[], /* Array of file extensions, e.g. ['jpg', 'png', 'pdf', ... ]. */
    b_isRequired=false,
    b_isImageField=false
}) => {

    const [file, setFile] = useState(inFile)
    const [fileName, setFileName] = useState(
        extractFileNameFromPath(inFile && typeof(inFile) === TYPE_OBJECT ? inFile.name : inFile)
    )
    const [errorMsg, setErrorMsg] = useState('')
    const [isClicked, setIsClicked] = useState(false)
    const fileInputRef = useRef()
    const fileInputButtonRef = useRef()
    
    const handleInputChange = useHandleInputChange()
    const isRequiredErrorActivate = useAreRequiredErrorsActive()
    const isConfirmationViewOpen = useIsConfirmationViewOpenContext()
    
    const maxFileSize = nbr_maxSize*1e6
    const allowedFileTypes = b_isImageField ? IMAGE_FILE_TYPES : arr_allowedFileTypes
    const topInfoText = createTopInfoText(b_isImageField ? IMAGE_FILE_TYPES : arr_allowedFileTypes, nbr_maxSize)
    
    const hasError = (file) => {
        /* File as input argument is necessary for the onChange handling (see fcts. below). */
        if (file) {
            if (!allowedFileTypes.includes(extractFileExtension(file.name))) {
                setErrorMsg(FILE_TYPE_EMSG)
                return true
            }
            else if (file.size > maxFileSize) {
                setErrorMsg(createFileSizeErrorMsg(file.size, maxFileSize))
                return true
            }
        } else if (b_isRequired) {
            setErrorMsg(REQ_EMSG)
            return true
        }
        setErrorMsg('')
        return false
    }

    useMemo(() => {
        /**
         * If new input is received from the context provider, update the file state.
         */
        setFileName(extractFileNameFromPath(inFile && typeof(inFile) === TYPE_OBJECT ? inFile.name : inFile))
    }, [inFile])
    
    useMemo(() => {
        /* All the required fields that are blank and do not show erros,
         * are activated when a form is submitted. The line below is only 
         * executed once. After the first execution, the required field 
         * displays error messages until the input is correct. */
        if (isRequiredErrorActivate && b_isRequired) hasError(file)
    }, [isRequiredErrorActivate])

    const fileInputOnChange = () => {
        /* Loop goes only over one file. (There is no other way to access it.) */
        for (const file of fileInputRef.current.files) {
            setFile(file)
            setFileName(file.name)
            const b_hasError = hasError(file)
            handleInputChange({id: str_id, value: file, hasError: b_hasError})
        }
    }

    const removeFileOnClick = () => {
        /* Clear all file information. */
        setFileName('')
        setFile(null)
        fileInputRef.current.value = ''
        /* Check errors and display message. */
        const b_hasError = b_isRequired ? true : false
        if (b_hasError) setErrorMsg(REQ_EMSG)
        else setErrorMsg('')
        /* Send data to parent element. */
        handleInputChange({id: str_id, value: null, hasError: b_hasError})
        /* Focus the button so that hitting enter allows file search. */
        fileInputButtonRef.current.focus()
    }

    const handleOnFocus = () => {
        /**
         * Make sure that on the first closing of the file search window, 
         * without selecting a file, the user is prompted the required err msg.
         */
        if (!fileName && b_isRequired && isClicked) setErrorMsg(REQ_EMSG)
        if (!isClicked) setIsClicked(true)
    }

    return (
        isConfirmationViewOpen
        ?
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            b_isRequired={false}
        >
            <InFieldConfirmationValue value={fileName} />
        </FieldWrapper>
        :
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            str_errorMsg={errorMsg}
            str_topInfoText={topInfoText}
            str_bottomInfoText={str_bottomInfoText}
            b_isRequired={b_isRequired}
        >
            <div className="in-field in-field-file">
                <div className="in-file-span-wrapper">
                    <span tabIndex="0" className="btn btn-sm btn-file-upload"
                        ref={fileInputButtonRef}
                        onFocus={handleOnFocus}
                        onMouseDown={() => fileInputRef.current.click()}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') { fileInputRef.current.click() }
                        }}
                    >
                        Dateiwahl
                        <input
                            ref={fileInputRef}
                            id={str_id}
                            type="file"
                            name="file-field"
                            required={b_isRequired}
                            onChange={fileInputOnChange}
                            accept={`
                                ${b_isImageField ? 'image/*' : ''}
                                ${allowedFileTypes[0] ?
                                ',.' + allowedFileTypes.join(',.') : ''}
                            `}
                        />
                    </span>
                    {
                        fileName === '' ?
                        <span className="displayed-file-name">Keine Datei gewählt</span> :
                        <span className="displayed-file-name">{fileName}</span>
                    }
                </div>
                <div className={`remove-input remove-file ${fileName ? '' : 'hidden'}`}>
                    <span
                        tabIndex={0}
                        onMouseDown={() => removeFileOnClick()}
                        onKeyDown={(e) => { if (e.key === 'Enter') { removeFileOnClick() } }}
                    >
                        datei entfernen
                    </span>
                </div>
            </div>
        </FieldWrapper>
    )
}

FileField.propTypes = {
    str_id: PropTypes.string.isRequired,
    str_fieldTitle: PropTypes.string,
    str_bottomInfoText: PropTypes.string,
    nbr_maxSize: PropTypes.number.isRequired,
    arr_allowedFileTypes: PropTypes.array,
    b_isRequired: PropTypes.bool,
    b_isImageField: PropTypes.bool
}

export default FileField
