/**
 * Wrapper component for several input fields that should be group together. 
 * E.g. information about a location of a job or a contact person.
 */

import React from 'react';
import PropTypes from 'prop-types';

const InFieldsGroup = ({
    str_title='',
    str_subtitle='',
    b_hasBorder=true,
    children
}) => {
    return (
        <div className="in-fields-group">
            {
                str_title &&
                <div className="in-fields-group-title">{str_title}</div>
            }
            {
                str_subtitle &&
                <div className="in-fields-group-subtitle">{str_subtitle}</div>
            }
            {children}
            {
                b_hasBorder &&
                <hr />
            }
        </div>
    )
}

InFieldsGroup.propTypes = {
    str_title: PropTypes.string,
    str_subtitle: PropTypes.string,
    b_hasBorder: PropTypes.bool,
    b_hasFilterGrid: PropTypes.bool
}

export default InFieldsGroup
