/**
 * This component displays a success message in any case.
 * Even if an email is not sent because the provided data is not valid, the message
 * is still a success message (security reason).
 */

import React, { useState } from 'react';

import InputFormPasswordForgot from '../input-forms/forms/welcome/InputFormPasswordForgot';
import InputFormContextProvider from '../input-forms/context-provider/InputFormContextProvider';
import { PasswordForgotFormData as FormData } from '../input-forms/util/form-config-data/InputFormWelcomeDataConfigs';
import { static2dynamicPanelConfig } from '../input-forms/util/input_form_data_handler';
import FormSuccessErrorMessage from '../input-forms/FormSuccessErrorMessage';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const URL = '/api/profiles/password/forgot/';
const SUCCESS_MSG = 'Es wurde ein Link an die E-Mail-Adresse deines Accounts gesendet.\n' +
    'Checke deine Inbox und auch den Spamordner innerhalb der nächsten Minuten.';

const PwForgot = () => {

    const [isPosted, setIsPosted] = useState(false)
    const posted = (response) => { setIsPosted(true) }

    useSetBrowserTabTitle('Passwort vergessen')

    return (
        isPosted
        ?
        <FormSuccessErrorMessage
            b_isSuccess={true}
            str_successMsg={SUCCESS_MSG}
        />
        :
        <InputFormContextProvider
            {...static2dynamicPanelConfig(FormData)}
            str_requestType='post'
            str_queryURL={URL}
            b_isPrivatePost={false}
            b_hasDiscardBtn={false}
            fct_response2parent={posted}
        >
            <InputFormPasswordForgot />
        </InputFormContextProvider>
    )
}

export default PwForgot
