import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useRunAnimationContext } from './context-provider/SbAnimationContextProvider';

const SbActionButton = ({
    str_name,
    b_hasDarkDesign=false,
    str_btnSize='md'
}) => {
    const runAnimation = useRunAnimationContext()
    return (
        <div className="btn-wrapper">
            <Link to={'new'}>
                <button
                    className={`btn btn-${str_btnSize} ${b_hasDarkDesign ? 'btn-dark-bg' : ''}`}
                    onClick={() => runAnimation()}
                >
                    {str_name}
                </button>
            </Link>
        </div>
    )
}

SbActionButton.propTypes = {
    str_name: PropTypes.string.isRequired,
    b_hasDarkDesign: PropTypes.bool,
    str_btnSize: PropTypes.string
}

export default SbActionButton
