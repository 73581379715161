import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import DateField from './DateField';

import { isSpanPartValid } from '../util/input_checks';
import { useHandleInputChange } from '../context-provider/InputFormContextProvider';
import FieldErrorMsgs from '../util/input_form_fields_error_msgs.json';

const START_EMSG = FieldErrorMsgs.datetime.timespan.startGTend;
const END_EMSG = FieldErrorMsgs.datetime.timespan.endSTstart;

const DateSpanFields = ({
    str_id,
    str_startTitle='',
    str_endTitle='',
    str_startDate='',
    str_endDate='',
    str_startMinDate,
    str_startMaxDate,
    str_endMinDate,
    str_endMaxDate,
    str_startBottomInfoText='',
    str_endBottomInfoText='',
    b_isRequired=false
}) => {
    
    const [startDate, setStartDate] = useState(str_startDate)
    const [endDate, setEndDate] = useState(str_endDate)
    /* State can only have an error if it is required and has no input value yet. 
     * This corresponds to the required message error. */
    const [hasStartDateError, setHasStartDateError] = useState(b_isRequired && !str_startDate)
    const [hasEndDateError, setHasEndDateError] = useState(b_isRequired && !str_endDate)
    const [startDateErrorMsg, setStartDateErrorMsg] = useState('')
    const [endDateErrorMsg, setEndDateErrorMsg] = useState('')
    const [hasStateChanged, setHasStateChanged] = useState(false)

    const handleInputChange = useHandleInputChange()

    useMemo(() => {
        /* If new input is received, update the date. */
        setStartDate(str_startDate)
    }, [str_startDate])

    useMemo(() => {
        /* If new input is received, update the date. */
        setEndDate(str_endDate)
    }, [str_endDate])

    const isDateSpanValidWrapper = (date, b_isStartDate) => {
        if (b_isStartDate) {
            return isSpanPartValid(
                date, endDate, b_isStartDate, START_EMSG, setStartDate, setStartDateErrorMsg,
                setEndDateErrorMsg, hasStateChanged, setHasStateChanged, b_isRequired
            )
        } else {
            return isSpanPartValid(
                date, startDate, b_isStartDate, END_EMSG, setEndDate, setStartDateErrorMsg,
                setEndDateErrorMsg, hasStateChanged, setHasStateChanged, b_isRequired
            )
        }
    }

    const collectDatePair = (obj_data) => {
        /* Update states. */
        if (obj_data.isStart) {
            setStartDate(obj_data.value)
            setHasStartDateError(obj_data.hasError)
        } else {
            setEndDate(obj_data.value)
            setHasEndDateError(obj_data.hasError)
        }
        
        /* Sent value has the data from e.target.value. This is necessary
         * as the setStartDate and setEndDate are too slow to update the 
         * state of the context provider real-time. */
        const arr_value = obj_data.isStart ? [obj_data.value, endDate] : [startDate, obj_data.value]
        const b_hasError = obj_data.isStart ?
                           obj_data.hasError || hasEndDateError :
                           obj_data.hasError || hasStartDateError
        handleInputChange({
            id: str_id,
            value: arr_value,
            hasError: b_hasError
        })
    }

    return (
        <>
            <DateField
                str_fieldTitle={str_startTitle}
                str_id={str_id + '-start'}
                str_date={startDate}
                str_minDate={str_startMinDate}
                str_maxDate={str_startMaxDate}
                str_bottomInfoText={str_startBottomInfoText}
                str_errorMsg={startDateErrorMsg}
                b_isRequired={b_isRequired}
                b_isSpanField={true}
                b_isStartDate={true}
                b_hasSpanChanged={hasStateChanged}
                fct_isDateSpanPartValid={isDateSpanValidWrapper}
                fct_sendDataToParent={collectDatePair}
            />
            <DateField
                str_fieldTitle={str_endTitle}
                str_id={str_id + '-end'}
                str_date={endDate}
                str_minDate={str_endMinDate}
                str_maxDate={str_endMaxDate}
                str_bottomInfoText={str_endBottomInfoText}
                str_errorMsg={endDateErrorMsg}
                b_isRequired={b_isRequired}
                b_isSpanField={true}
                b_isStartDate={false}
                b_hasSpanChanged={hasStateChanged}
                fct_isDateSpanPartValid={isDateSpanValidWrapper}
                fct_sendDataToParent={collectDatePair}
            />
        </>
    )
}

DateSpanFields.propTypes = {
    str_id: PropTypes.string,
    str_startTitle: PropTypes.string,
    str_endTitle: PropTypes.string,
    str_startDate: PropTypes.string,
    str_endDate: PropTypes.string,
    str_startMinDate: PropTypes.string,
    str_startMaxDate: PropTypes.string,
    str_endMinDate: PropTypes.string,
    str_endMaxDate: PropTypes.string,
    str_startBottomInfoText: PropTypes.string,
    str_endBottomInfoText: PropTypes.string,
    b_isRequired: PropTypes.bool
}

export default DateSpanFields
