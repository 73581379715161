import React from 'react';
import { Link } from 'react-router-dom';

import Votes from '../votes/Votes'; 

import { useBaseUrlsContext } from './context-provider/MessagesContextProvider';
import { useMsgContext } from './context-provider/MessageContextProvider';
import { genDynUrl } from '../../util/db_ls_query_handler';
import { string2URLstring } from '../../util/data_handler';
import VoteService from '../../util/voting/vote_service';

const MessageQuestionFooter = () => {
    const question = useMsgContext()
    const baseUrls = useBaseUrlsContext()
    const voteUrl = genDynUrl(baseUrls.voteBaseUrl, question.id)
    const voteProps = VoteService.voteSerializer(question, voteUrl)
    return (
        <div className="msg-footer">
            <div className="vote-info">
                <span className="vote-text nowrap">Vote die Fragenqualität</span>
                <Votes {...voteProps} />
            </div>
            <div className="discussion-content-new-link">
                <Link className="page-link" to={
                    `../question/${string2URLstring(question.qcategory)}/${question.number}/` +
                    `${question.questionId}/questions`}
                >
                    Vote die hochgeladenen Fragen oder erstelle deine eigene Frage
                </Link>
            </div>
        </div>
    )
}

export default MessageQuestionFooter
