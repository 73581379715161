import jwt_decode from 'jwt-decode';
import { fetchDBdata, fetchLSdata, setLSdata } from '../db_ls_query_handler';

const logout = () => {
    /* Clear localStorage once user logs out. */
    localStorage.clear()
}

const genUserLs = (tokens) => {
    /**
     * Extracts the encrypted data from the JWT access token and stores
     * it along with the tokens (access, refresh) in a JS object format
     * into the LS.
     * :Input
     *  tokens (obj): JWT access tokens {access: '...', refresh: '...'}.
     */
    const data = jwt_decode(tokens.access)
    const refreshToken = tokens.refresh ? tokens.refresh : getRefreshToken()
    /* Custom claims of the token are not updated, use them from the LS appropriately. */
    const hasDarkThemeLs = AuthService.hasDarkTheme()
    const hasDarkTheme = hasDarkThemeLs === null ? data.hasDarkTheme : hasDarkThemeLs
    const isAboValid = AuthService.isAboValid() ? true : data.isAboValid
    const isProfileSetup = AuthService.isProfileSetup() ? true : data.isProfileSetup

    const lsData = {
        accessToken: tokens.access,
        refreshToken: refreshToken,
        id: data.user_id,
        username: data.username,
        firstName: data.firstName,
        lastName: data.lastName,
        usertype: data.usertype,
        dateJoined: data.dateJoined,
        exp: data.exp,
        isAboValid: isAboValid,
        isProfileSetup: isProfileSetup,
        hasDarkTheme: hasDarkTheme
    }

    localStorage.setItem('user', JSON.stringify(lsData))
}

/* Getter. */

const getUser = () => {
    return JSON.parse(localStorage.getItem('user'));
}

const getUserId = () => {
    return fetchLSdata('user', 'id');
}

const getUsername = () => {
    return fetchLSdata('user', 'username');
}

const getFirstName = () => {
    return fetchDBdata('user', 'firstName');
}

const getLastName = () => {
    return fetchDBdata('user', 'lastName');
}

const getFullUserName = () => {
    const firstName = fetchLSdata('user', 'firstName');
    const lastName = fetchLSdata('user', 'lastName');
    return firstName + ' ' + lastName;
}

const getUsertype = () => {
    return fetchLSdata('user', 'usertype');
}

const getAccessToken = () => {
    return fetchLSdata('user', 'accessToken');
}

const getRefreshToken = () => {
    return fetchLSdata('user', 'refreshToken');
}

const getExpTime = () => {
    return fetchLSdata('user', 'exp');
}

const isAboValid = () => {
    return fetchLSdata('user', 'isAboValid');
}

const isProfileSetup = () => {
    return fetchLSdata('user', 'isProfileSetup');
}

const authHeader = () => {
    const accessToken = getAccessToken();
    if (accessToken) return { Authorization: 'Bearer ' + accessToken };
    return {};
}

const hasDarkTheme = () => {
    return fetchLSdata('user', 'hasDarkTheme')
}

const getProfileUrl = () => {
    const user = getUser();
    return `/profile/${user.usertype}/${user.username}/${user.id}`;
}

/* Setter. */

const setIsAboValid = (status) => {
    let user = getUser();
    user.isAboValid = status;
    setLSdata(user, 'user');
}

const setIsProfileSetup = (status) => {
    let user = getUser();
    user.isProfileSetup = status;
    setLSdata(user, 'user');
}

const setTheme = (isDark) => {
    let user = getUser();
    user.hasDarkTheme = isDark;
    setLSdata(user, 'user');
}

const AuthService = {
    logout: logout,
    genUserLs: genUserLs,
    getUser: getUser,
    getUserId: getUserId,
    getUsername: getUsername,
    getFirstName: getFirstName,
    getLastName: getLastName,
    getFullUserName: getFullUserName,
    getUsertype: getUsertype,
    getAccessToken: getAccessToken,
    getRefreshToken: getRefreshToken,
    getExpTime: getExpTime,
    isAboValid: isAboValid,
    isProfileSetup: isProfileSetup,
    authHeader: authHeader,
    hasDarkTheme: hasDarkTheme,
    getProfileUrl: getProfileUrl,
    setIsAboValid: setIsAboValid,
    setIsProfileSetup: setIsProfileSetup,
    setTheme: setTheme
};

export default AuthService;
