/**
 * This component is an addition to the DynamicContentLoadContextProvider.js component.
 * 
 * It allows the DynamicContentLoadContextProvider (DCLCP) to store the queried data here,
 * thus, this component acts as cache for the DCLCP.
 * 
 * The data is stored according to the filters applied for the dynamic data search.
 * Therefore, the state that stores the data is a JS object. Its keys are the filter
 * indicators as created by the DCLCP. The values represent the data that was queried
 * with the filter, i.e. the object key.
 */
import React, { useState, useContext } from 'react';
import { useFilterStateContext } from '../filters/context-provider/FilterStateContextProvider';

/* Context and hooks. */

const CachedFilterDataContext = React.createContext();
const GetDataFromFilterCacheContext = React.createContext();
const UpdateFilterCacheContext = React.createContext();
const UpdateFilterDataVotes = React.createContext();

export function useCachedFilterData() {
    return useContext(CachedFilterDataContext);
}

export function useGetDataFromFilterCacheContext() {
    return useContext(GetDataFromFilterCacheContext);
}

export function useUpdateFilterCacheContext() {
    return useContext(UpdateFilterCacheContext);
}

export function useUpdateFilterDataVotes() {
    return useContext(UpdateFilterDataVotes);
}

/* Helper functions. */

const filterKeyFromObject = (object) => {
    /**
     * Create a unique filter key from the object data of the used filter.
     * The filter is created from the ids of the obj data as they are unique.
     * :Input
     *  object (obj): The object received from a SelectiveProposalField.js.
     *      Format: { id: ... (nbr), chars: ... (str)}
     * :Returns
     *  Filter key (str).
     */
    let ids = [];
    object.forEach(item => { ids.push(item.id) });
    return ids.sort().join('-');
}

const filterKeyFromFilterData = (filterData) => {
    /**
     * Generates a unique filter key from the filter data the user provides
     * as input to the IFCP.
     * :Input
     *  filterData (obj): Filter data (from the IFCP) sent from the DCLCP.
     *      {
     *          tags (obj): { id: ... (nbr), chars: ... (str)}
     *          categories (str): ... (str),
     *          courseSelection (str): ... (str),
     *          selectedCourses (str): ... (str)
     *      }
     * :Return
     *  Filter key (str).
     */
    if (!filterData) return

    /* Only use the first letter of the single choice inputs, as they are unique. */
    const categoriesKey = filterData.categories.slice(0, 1);
    const courseSelectionKey = filterData.courseSelection.slice(0, 1);

    const tagsKey = filterKeyFromObject(filterData.tags);
    const selectedCoursesKey = filterKeyFromObject(filterData.selectedCourses);

    /* Split keys with dashes for readablity of the filters (no other reason). */
    return tagsKey + '_' + categoriesKey + '_' + courseSelectionKey + '_' + selectedCoursesKey;
}

const hasFilterCategory = (filterDataKey, categoryIdentifier) => {
    /**
     * Checks if the filterDataKey entails the currently set study material category.
     * :Input
     *  filterDataKey (str): Key of the filterData state.
     *  categoryIdentifier (str): The first letter of the selected study material category.
     */
    const filterDataKeyCategory = filterDataKey.split('_')[1];
    return filterDataKeyCategory === categoryIdentifier;
}

const updateFilterDataVoteValues = (filterDataValue, itemId, deltaUp, deltaDown, userVoted) => {
    filterDataValue[0].forEach(item => {
        if (item.id === itemId) {
            item.numUpvotes = item.numUpvotes + deltaUp
            item.numDownvotes = item.numDownvotes + deltaDown
            item.userVoted = userVoted
        }
    })
}

const FilterCacheDynamicContentLoadContextProvider = ({children}) => {

    const [filterData, setFilterData] = useState({})

    /* Current state of the filter used on the page. */
    const currentFilterState = useFilterStateContext()

    const getDataFromFilterCache = (inFilterData) => {
        /**
         * :Input
         *  inFilterData (obj): The filter data from the IFCP.
         * :Returns
         *  Filter value of filter key generated from the input filter data.
         */
        const filterKey = filterKeyFromFilterData(inFilterData)
        return filterData[filterKey]
    }

    const updateFilterCache = (newData, inFilterData) => {
        /**
         * Adds newly queried data to the state as value for a specific filter key or
         * updaes the value of the filter key with the latest data if it exists.
         * :Input
         *  newData    (obj): This format is generated by genStorageData() of the DCLCP.
         *  inFilterData (obj): The filter data from the IFCP.
         */
        if (
            !newData || typeof(newData) !== 'object' ||
            !inFilterData || typeof(inFilterData) !== 'object'
        ) return
        const filterKey = filterKeyFromFilterData(inFilterData)
        let newFilterData = {...filterData}
        newFilterData[filterKey] = newData
        setFilterData(newFilterData)
    }

    const updateFilterDataVotes = (itemId, deltaUp, deltaDown, userVoted) => {
        /* First value is the field value, second is true/false if field has error. */
        const inputFieldValue = currentFilterState.categories[0]
        const categoryIdentifier = inputFieldValue.slice(0, 1)
        let newFilterData = {...filterData}
        Object.keys(newFilterData).forEach(key => {
            if (hasFilterCategory(key, categoryIdentifier)) {
                updateFilterDataVoteValues(
                    newFilterData[key], itemId, deltaUp, deltaDown, userVoted
                )
            }
        })
        setFilterData(newFilterData)
    }

    return (
        <CachedFilterDataContext.Provider value={filterData}>
            <GetDataFromFilterCacheContext.Provider value={getDataFromFilterCache}>
                <UpdateFilterCacheContext.Provider value={updateFilterCache}>
                    <UpdateFilterDataVotes.Provider value={updateFilterDataVotes}>
                        {children}
                    </UpdateFilterDataVotes.Provider>
                </UpdateFilterCacheContext.Provider>
            </GetDataFromFilterCacheContext.Provider>
        </CachedFilterDataContext.Provider>
    )
}

export default FilterCacheDynamicContentLoadContextProvider
