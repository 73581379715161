import React from 'react';
import PropTypes from 'prop-types';

import { MdLocationOn, MdAccessTime, MdAutorenew } from 'react-icons/md';
import { TbTruckDelivery } from 'react-icons/tb';
import { BsBoxSeam } from 'react-icons/bs';
import { RiLeafFill, RiLeafLine } from 'react-icons/ri';
import { FiTool } from 'react-icons/fi';

import { hhmmss2hhmmTime, browserDateTime2weekdayWord } from '../../../util/date_time_handler';

const CardFoodBody = ({
    /* Props do not hav preleading types, as they are passed via 
     * the [...xyz] operator from the backend data. */
    description,
    date,
    startTime,
    endTime,
    address,
    hasLocalConsumption,
    hasPickupConsumption,
    hasDeliveryConsumption,
    isVegetarian,
    isVegan,
    repeatDays,
    repeatOnWeekends
}) => {
    return (
        <div className="card-body card-body-food">
            <div className="description">
                <p>{description}</p>
            </div>
            <div className="key-infos">
                <div className="key-value-pair">
                    <div className="key icon"><MdAccessTime /></div>
                    <div className="value">
                        <span className="start-time">{hhmmss2hhmmTime(startTime)}</span>
                        <span className="separator">-</span>
                        <span className="end-time">{hhmmss2hhmmTime(endTime)}</span>
                    </div>
                </div>
                {
                    /* Address is not shown on the gastronomer accounts page. */
                    address &&
                    <div className="key-value-pair">
                        <div className="key icon"><MdLocationOn /></div>
                        <div className="value">{address}</div>
                    </div>
                }
                {
                    isVegan
                    ?
                    <div className="key-value-pair">
                        <div className="key icon"><RiLeafFill /></div>
                        <div className="value">Vegan</div>
                    </div>
                    :
                    isVegetarian
                    ?
                    <div className="key-value-pair">
                        <div className="key icon"><RiLeafLine /></div>
                        <div className="value">Vegetarisch</div>
                    </div>
                    :
                    ''
                }
                {
                    repeatDays && date &&
                    <>
                        <div className="key-value-pair">
                            <div className="key icon"><MdAutorenew /></div>
                            <div className="value">
                            {
                                repeatDays === 1
                                ?
                                'Täglich'
                                :
                                `${browserDateTime2weekdayWord(date)} alle ${repeatDays} Tage`
                            }
                            </div>
                        </div>
                        {
                            repeatDays === 1 && !repeatOnWeekends &&
                            <div className="key-value-pair">
                                <div className="key icon"><FiTool /></div>
                                <div className="value">Nur Werktags</div>
                            </div>
                        }
                    </>
                }
            </div>
            <div className="consume-options">
                <div className={`icon icon-gastro-local-consume ${hasLocalConsumption ? '' : 'icon-not-available'}`}>
                    <MdLocationOn />
                </div>
                <div className={`icon icon-gastro-pickup-consume ${hasPickupConsumption ? '' : 'icon-not-available'}`}>
                    <BsBoxSeam />
                </div>
                <div className={`icon icon-gastro-delivery-consume ${hasDeliveryConsumption ? '' : 'icon-not-available'}`}>
                    <TbTruckDelivery />
                </div>
            </div>
        </div>
    )
}

CardFoodBody.propTypes = {
    description: PropTypes.string.isRequired,
    date: PropTypes.string,
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
    address: PropTypes.string,
    hasLocalConsumption: PropTypes.bool,
    hasPickupConsumption: PropTypes.bool,
    hasDeliveryConsumption: PropTypes.bool,
    isVegetarian: PropTypes.bool,
    isVegan: PropTypes.bool,
    repeatDays: PropTypes.number
}

export default CardFoodBody
