/**
 * Contains the sec navbar entries for all user pages. 
 * 
 * The 'title' is what is displayed on the front end (innerHTML). 
 * The 'name' is used to highlight the currently active item of the navbar. 
 * The 'name' must be featured in the URL, as the JS code checks the URL against
 * the 'name' and if it finds it, sets the active styling to this item.
 * 
 * 'symbol' (react icon) can be used instead of 'title'.
 * 
 * 'hasVerticalDisplay': True: Nav items are collapsed into a burger for smaller screens.
 *                       False: Nav items remain horizontally in the navbar.
 * 
 * If there are no items on the right of the sec navbar, then you MUST NOT
 * put the 'items' key into the object. The JS code checks if it is there
 * to make its decision. 
 * 
 */
export const SecNavbarData = {
    courses: {
        courses: {
            hasVerticalDisplay: true,
            isFreeTier: true,
            items: [
                {
                    title: 'Übersicht',
                    name: 'overview',
                    link: 'overview',
                    isFreeTier: false
                },
                {
                    title: 'Tutoring',
                    name: 'tutoring',
                    link: 'tutoring',
                    isFreeTier: true
                },
                {
                    title: 'Kurstausch',
                    name:'course-swap',
                    link: 'course-swap',
                    isFreeTier: true
                },
                {
                    title: 'Tags',
                    name: 'tags',
                    link: 'tags',
                    isFreeTier: false
                }
            ]
        }
    },
    course: {
        courseName: {
            urlParam: 'name',
            hasVerticalDisplay: true,
            isFreeTier: false,
            items: [
                {
                    title: 'Unterlagen',
                    name: 'docs',
                    link: 'docs',
                    isFreeTier: false
                },
                {
                    title: 'Prüfungen',
                    name: 'exams',
                    link: 'exams',
                    isFreeTier: false
                },
                {
                    title: 'Übungen',
                    name: 'exercises',
                    link: 'exercises',
                    isFreeTier: false
                },
                {
                    title: 'Forum',
                    name: 'forum',
                    link: 'forum',
                    isFreeTier: false
                },
                {
                    title: 'Tags',
                    name: 'tags',
                    link: 'tags',
                    isFreeTier: false
                }
            ]
        }
    },
    industry: {
        industry: {
            hasVerticalDisplay: true,
            isFreeTier: true,
            items: [
                {
                    title: 'Jobs',
                    name: 'jobs',
                    link: '/industry/jobs',
                    isFreeTier: true
                },
                {
                    title: 'Unternehmen',
                    name: 'companies',
                    link: '/industry/companies',
                    isFreeTier: true
                }
            ]   
        }
    },
    gastronomy: {
        regularOffers: {
            title: 'Angebote',
            name: 'regular',
            link: '/gastronomy/food-offers/regular/this-week',
            hasVerticalDisplay: true,
            isFreeTier: false,
            items: [
                {
                    title: 'Diese Woche',
                    name: 'this-week',
                    link: '/gastronomy/food-offers/regular/this-week',
                    isFreeTier: false
                },
                {
                    title: 'Nächste Woche',
                    name: 'next-week',
                    link: '/gastronomy/food-offers/regular/next-week',
                    isFreeTier: false
                },
                {
                    title: 'Täglich',
                    name: 'daily',
                    link: '/gastronomy/food-offers/regular/daily',
                    isFreeTier: false
                },
            ]
        },
        dgfsOffers: {
            title: 'DoG4S',
            name: 'do-good-for-students',
            link: '/gastronomy/food-offers/do-good-for-students/today',
            hasVerticalDisplay: true,
            isFreeTier: false,
            items: [
                {
                    title: 'Heute',
                    name: 'today',
                    link: '/gastronomy/food-offers/do-good-for-students/today',
                    isFreeTier: false
                },
                {
                    title: 'Morgen',
                    name: 'tomorrow',
                    link: '/gastronomy/food-offers/do-good-for-students/tomorrow',
                    isFreeTier: false
                }
            ]
        },
        gastronomer: {
            title: 'Gastronome',
            name: 'gastronomer',
            link: '/gastronomy/gastronomers',
            isFreeTier: false
        }
    },
    mining: {
        mining: {
            hasVerticalDisplay: false,
            isFreeTier: false,
            items: [
                {
                    title: 'Dieses Semester',
                    name: 'this-semester',
                    link: 'this-semester',
                    isFreeTier: false
                },
                {
                    title: 'Gesamt',
                    name: 'overall',
                    link: 'overall',
                    isFreeTier: false
                }
            ]
        }
    },
    contact: {
        contact: {
            hasVerticalDisplay: true,
            isFreeTier: true,
            items: [
                {
                    title: 'Kontakt',
                    name: 'general',
                    link: 'general',
                    isFreeTier: true
                },
                {
                    title: 'Verbesserungen & Vorschläge',
                    name: 'improvements',
                    link: 'improvements-and-suggestions',
                    isFreeTier: true
                },
                {
                    title: 'Fehlerbehebung',
                    name: 'bug',
                    link: 'bug-reporting',
                    isFreeTier: true
                },
                {
                    title: 'User melden',
                    name: 'user',
                    link: 'user-reporting',
                    isFreeTier: true
                }
            ]
        }
    },
    dataAndUsage: {
        dataAndUsage: {
            hasVerticalDisplay: true,
            isFreeTier: true,
            items: [
                {
                    title: 'Nutzeranleitung',
                    name: 'user-guide',
                    link: 'user-guide',
                    isFreeTier: true
                },
                {
                    title: 'Nutzungsbedingungen',
                    name: 'terms',
                    link: 'terms-of-usage',
                    isFreeTier: true
                },
                {
                    title: 'Datenschutzrichtlinie',
                    name: 'privacy',
                    link: 'privacy-policy',
                    isFreeTier: true
                }
            ]
        }
    }
}