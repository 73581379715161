import React from 'react';
import PropTypes from 'prop-types';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import TrCourses from './TrCourses';

import {
    useQueriedDataContext,
    useIsFirstFetchSuccessContext
} from '../../context-provider/DynamicContentLoadContextProvider';

const TbCourses = ({
    arr_data=[],
    str_design='',
    b_isDynamic=false,
    fct_deleteEntryFromState /* Used if a table row should be deleted from arr_data. */
}) => {
    const isFirstFetchSuccessRef = useIsFirstFetchSuccessContext()
    if (b_isDynamic) { arr_data = useQueriedDataContext() }
    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={b_isDynamic}
            b_isFirstFetchSuccess={isFirstFetchSuccessRef ? isFirstFetchSuccessRef.current : undefined}
            firstQueryDataEntry={arr_data[0]}
        >
            <div className="tb-wrapper">
                <table cellSpacing="0" className={`tb-base text-left ${str_design}`}>
                    <thead>
                        <tr>
                            <td>Kurs</td>
                            <td>Doks</td>
                            <td>Prüfungen</td>
                            <td>Übungen</td>
                            <td>Forum</td>
                            <td className="hide-max-w-576px">Pin</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            arr_data.map((item, index) => (
                                <TrCourses
                                    key={index}
                                    obj_data={item}
                                    fct_deleteEntryFromState={fct_deleteEntryFromState}
                                />
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </DataStateCheckWrapper>
    )
}

TbCourses.propTypes = {
    arr_data: PropTypes.array,
    str_design: PropTypes.string,
    b_isDynamic: PropTypes.bool,
    fct_deleteEntryFromState: PropTypes.func
}

export default TbCourses
