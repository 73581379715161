import React, { useState, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import TbPin from '../../TbPin';
import { string2URLstring } from '../../../../util/data_handler';
import { browserDateToGermanDateFormat } from '../../../../util/date_time_handler';
import { PPLsKeys, StudentLsKeys } from '../../../../util/LocalStorageVariables';

/* LS keys of the job pinned on student user's profile page. */
const PR_LS_KEY = PPLsKeys.primaryDataKey;
const SEC_LS_KEY = StudentLsKeys.pp.jobs;

const BASE_URL = '/api/industry/job/pin/detail/';

const genUrl = (id) => {
    return BASE_URL + id + '/';
}

const TrStudentPPIndustryJobs = ({
    obj_data,
    fct_deleteEntryFromState /* If <tr> can be removed by user. */
}) => {
    
    const [isCollapsed, setIsCollapsed] = useState(false)
    const tableRowRef = useRef()
    
    useMemo(() => {
        /* If the input arg changes due to a delete event on a <tr>,
         * remove collapsed class and set height of prev. animation. */
        setIsCollapsed(false)
        if (tableRowRef.current) tableRowRef.current.style.height = ''
    }, [obj_data])

    const unpin = () => {
        /* Animation. */
        /* Fix the height of <tr> so that removing the <td> does 
         * not collapse the <tr> before the animation. */
        const height = tableRowRef.current.clientHeight;
        tableRowRef.current.style.height = height + 'px';
        /* Run disappear animation by adding a class. */
        setIsCollapsed(true)
        /* Wait until animation is done running, then remove item from state. */
        setTimeout(() => {
            if (fct_deleteEntryFromState)
                fct_deleteEntryFromState(obj_data.id)
        }, 1500)
    }

    return (
        <tr ref={tableRowRef} className={`${isCollapsed ? 'tr-collapse-animation' : ''}`}>
            {
                !isCollapsed &&
                <>
                    <td>
                        <Link
                            to={`/industry/jobs/${string2URLstring(obj_data.companyName)}/${string2URLstring(obj_data.title)}/${obj_data.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="file-link"
                        >
                            {obj_data.title}
                        </Link>
                    </td>
                    <td className="hide-max-w-900px">
                        <Link
                            to={`/company/info-page/${string2URLstring(obj_data.companyName)}/${obj_data.companyId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="file-link"
                        >
                            {obj_data.companyName}
                        </Link>
                    </td>
                    <td>{browserDateToGermanDateFormat(obj_data.closingDate)}</td>
                    <TbPin
                        nbr_id={obj_data.id}
                        str_prLsKey={PR_LS_KEY}
                        str_secLsKey={SEC_LS_KEY}
                        str_url={genUrl(obj_data.id)}
                        str_confirmMsgDetails={obj_data.title}
                        b_isPinned={true}
                        fct_removeTr={() => unpin()}
                    />
                </>
            }
        </tr>
    )
}

TrStudentPPIndustryJobs.propTypes = {
    obj_data: PropTypes.object.isRequired,
    fct_deleteEntryFromState: PropTypes.func.isRequired
}

export default TrStudentPPIndustryJobs
