/**
 * Entails all the variables that are necessary to display the 
 * information for different states of the query. E.g.  'loading ...'.
 */

export const QueryVariables = {
    str_loadingMsg: 'Laden ...',
    str_stdNoDataMsg: 'Keine Daten verfügbar.',
    str_stdNoPersonalDataMsg: 'Deine Daten sind nicht verfügbar.',
    str_allDataLoadedMsg: 'Alle verfügbaren Daten werden angezeigt.',
    str_queryFailedMsg: 'Die Datenanfrage is fehlgeschlagen. Bitte lade die Seite erneut.\n' +
        'Sollte derselbe Fehler danach wieder auftreten, versuche es später noch einmal.',
    str_noOffersMsg: 'Zur Zeit sind keine Angebote verfügbar.',
};
