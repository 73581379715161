import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import FormBase from '../../FormBase';
import InFieldsGroup from '../../InFieldsGroup';
import TextField from '../../fields/TextField';
import NumberField from '../../fields/NumberField';
import DateField from '../../fields/DateField';
import FileField from '../../fields/FileField';
import SingleChoiceField from '../../fields/SingleChoiceField';
import BoolField from '../../fields/BoolField';
import TagField from '../../fields/TagField';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { TaskFormData as FormData } from '../../util/form-config-data/InputFormCoursesDataConfigs';

const NEW_TASK_SUBTITLE = 'Mit einer ersten Frage';

const InputFormTask = ({
    b_isNewTask=false,
    b_isExercise=false,
    b_isQuestionVariantForm=false, /* If the form is used to create a question variant (not the init. one). */
    str_formTitle
}) => {
    const data = useInputData()
    const params = useParams()
    
    const genQuestionSubtitle = () => {
        /**
         * Generates a subtitle for the new question form of a task.
         * Uses the date value from the URL.
         * :Returns
         *  Success: Subtitle string with task date.
         *  Fail: Empty string (no subtitle). 
         */
        if (params.date) {
            return 'Für den Termin am ' + params.date.replaceAll('-', '.');
        } else {
            return '';
        }
    }

    return (
        <FormBase
            str_title={b_isNewTask ? str_formTitle : 'Neue Frage'}
            str_subtitle={b_isNewTask ? NEW_TASK_SUBTITLE : genQuestionSubtitle()}
            b_hasFileField={true}
            b_hasScreenshots={true}
        >
            {
                b_isNewTask &&
                <InFieldsGroup b_hasBorder={true}>
                    <DateField
                        {...FormData.fields.date}
                        str_date={data[FormData.fields.date.str_id][0]}
                        /* Fix date to the exam year displayed in the url. */
                        str_minDate={`${params.year}-01-01`}
                        str_maxDate={`${params.year}-12-31`}
                    />
                </InFieldsGroup>
            }
            <InFieldsGroup b_hasBorder={false}>
                {
                    /* This field is to distinguish exercise questions from exercise exam
                     * questions (e.g. KNWs). */
                    (!b_isQuestionVariantForm && b_isExercise) &&
                    <BoolField
                        {...FormData.fields.isExerciseTestQuestion}
                        b_boolValue={data[FormData.fields.isExerciseTestQuestion.str_id][0]}
                    />
                }
                {
                    !b_isQuestionVariantForm &&
                    <SingleChoiceField
                        {...FormData.fields.category}
                        str_currentOption={data[FormData.fields.category.str_id][0]}
                    />
                }
                {
                    /* Creating question variants do not need the question number. */
                    !b_isQuestionVariantForm &&
                    <NumberField
                        {...FormData.fields.number}
                        nbr_value={data[FormData.fields.number.str_id][0]}
                    />
                }
                <TextField
                    {...FormData.fields.text}
                    str_chars={data[FormData.fields.text.str_id][0]}
                />
                <FileField
                    {...FormData.fields.file0}
                    inFile={data[FormData.fields.file0.str_id][0]}
                />
                <FileField
                    {...FormData.fields.file1}
                    inFile={data[FormData.fields.file1.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.completenessRank}
                    nbr_value={data[FormData.fields.completenessRank.str_id][0]}
                />
                <TextField
                    {...FormData.fields.comment}
                    str_chars={data[FormData.fields.comment.str_id][0]}
                />
                <TagField
                    {...FormData.fields.tagnames}
                    arr_tags={data[FormData.fields.tagnames.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

InputFormTask.propTypes = {
    b_isNewTask: PropTypes.bool,
    b_isExercise: PropTypes.bool,
    b_isQuestionVariantForm: PropTypes.bool,
    str_formTitle: PropTypes.string
}

export default InputFormTask
