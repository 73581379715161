/**
 * This file entails the configuration of the input props of the routes in App.js.
 */
import React from 'react';
import TbExamsYearListing from "../../components/tables/courses/TbExamsYearListing";
import TbExercisesYearListing from "../../components/tables/courses/TbExercisesYearListing";
import Usertypes from '../Usertypes';
import { FilterLsKeys, AppIndustryLsKeys } from '../LocalStorageVariables';

const permissions = {
    company: {
        arr_usertypes: [Usertypes.company]
    },
    gastronomer: {
        arr_usertypes: [Usertypes.gastronomer]
    },
    student: {
        arr_usertypes: [Usertypes.student]
    },
    companyStudent: {
        arr_usertypes: [Usertypes.company, Usertypes.student]
    },
    gastronomerStudent: {
        arr_usertypes: [Usertypes.gastronomer, Usertypes.student]
    }
}

const basePages = {
    gastronomy: {
        str_secNavPrKey: 'gastronomy',
        str_secNavSecKey: 'regularOffers'
    },
    industry: {
        b_isPrivatePage: false,
        str_secNavPrKey: 'industry',
        str_secNavSecKey: 'industry',
        arr_lsElementsToRemove: [
            AppIndustryLsKeys.companiesListing,
            AppIndustryLsKeys.jobsListing
        ]
    },
    course: {
        str_secNavPrKey: 'courses',
        str_secNavSecKey: 'courses',
        b_isPrivatePage: false,
        b_hasPageBase: true,
        arr_lsElementsToRemove: [
            FilterLsKeys.courseListing,
            FilterLsKeys.courseTutorMyOffers,
            FilterLsKeys.courseTutorOfferList,
            FilterLsKeys.courseSwapMyOffers,
            FilterLsKeys.courseSwapOfferList
        ]
    },
    mining: {
        // str_secNavPrKey: 'mining',
        // str_secNavSecKey: 'mining',
        b_hasPageBase: true
    },
    store: {
        b_hasPageBase: true
    },
    contact: {
        str_secNavPrKey: 'contact',
        str_secNavSecKey: 'contact'
    },
    dataAndUsage: {
        str_secNavPrKey: 'dataAndUsage',
        str_secNavSecKey: 'dataAndUsage',
        b_hasPageBase: true
    }
}

const studyMaterial = {
    TasksYearListingPage: {
        exercise: {
            str_title: 'Übungen',
            comp_table: <TbExercisesYearListing />
        },
        exam: {
            str_title: 'Prüfungen',
            comp_table: <TbExamsYearListing />
        }
    },
    TasksListingPage: {
        exercise: {
            str_baseUrl: '/api/courseexercises/list/course/year/',
            str_identifier: 'exercise'
        },
        exam: {
            str_baseUrl: '/api/courseexams/list/course/year/',
            str_identifier: 'exam'
        }
    },
    TasksNewPage: {
        task: {
            exercise: {
                b_isNewTask: true,
                str_urlParam: 'exerciseId',
                str_postNewTaskUrl: '/api/courseexercises/create/exercise/',
                str_postNewQuestionBaseUrl: '/api/courseexercises/questions/create/exercise/',
                str_formTitle: 'Neue Übung',
                b_isExercise: true
            },
            exam: {
                b_isNewTask: true,
                str_urlParam: 'examId',
                str_postNewTaskUrl: '/api/courseexams/create/exam/',
                str_postNewQuestionBaseUrl: '/api/courseexams/questions/create/exam/',
                str_formTitle: 'Neue Prüfung',
                b_isExercise: false
            }
        },
        question: {
            exercise: {
                b_isNewTask: false,
                str_urlParam: 'exerciseId',
                str_postNewTaskUrl: '/api/courseexercises/create/exercise/',
                str_postNewQuestionBaseUrl: '/api/courseexercises/questions/create/exercise/',
                b_isExercise: true
            },
            exam: {
                b_isNewTask: false,
                str_urlParam: 'examId',
                str_postNewTaskUrl: '/api/courseexams/create/exam/',
                str_postNewQuestionBaseUrl: '/api/courseexams/questions/create/exam/',
                b_isExercise: false
            }
        }
    },
    TasksDateChangePage: {
        exercise: {
            str_getUrl: '/api/courseexercises/date-changes/list/exercise/',
            str_postUrl: '/api/courseexercises/date-changes/create/exercise/',
            str_urlParam: 'exerciseId'
        },
        exam: {
            str_getUrl: '/api/courseexams/date-changes/list/exam/',
            str_postUrl: '/api/courseexams/date-changes/create/exam/',
            str_urlParam: 'examId'
        }
    },
    TasksQuestionVariantsPage: {
        exercise: {
            str_listBaseUrl: '/api/courseexercises/questions/variants/list/',
            str_createBaseUrl: '/api/courseexercises/questions/variants/create/',
            str_voteBaseUrl: '/api/courseexercises/questions/variants/vote/variant/',
            str_urlParam: 'questionId'
        },
        exam: {
            str_listBaseUrl: '/api/courseexams/questions/variants/list/',
            str_createBaseUrl: '/api/courseexams/questions/variants/create/',
            str_voteBaseUrl: '/api/courseexams/questions/variants/vote/variant/',
            str_urlParam: 'questionId'
        }
    },
    TasksDetailPage: {
        exercise: {
            str_questionDetailBaseUrl: '/api/courseexercises/questions/detail/question/',
            str_questionVoteBaseUrl: '/api/courseexercises/questions/variants/vote/variant/',
            str_msgListBaseUrl: '/api/courseexercises/messages/list/question/',
            str_msgNewBaseUrl: '/api/courseexercises/messages/create/question/',
            str_msgCommentBaseUrl: '/api/courseexercises/messages/create/question/comment/',
            str_msgEditBaseUrl: '/api/courseexercises/messages/update/message/',
            str_msgVoteBaseUrl: '/api/courseexercises/messages/vote/message/'
        },
        exam: {
            str_questionDetailBaseUrl: '/api/courseexams/questions/detail/question/',
            str_questionVoteBaseUrl: '/api/courseexams/questions/variants/vote/variant/',
            str_msgListBaseUrl: '/api/courseexams/messages/list/question/',
            str_msgNewBaseUrl: '/api/courseexams/messages/create/question/',
            str_msgCommentBaseUrl: '/api/courseexams/messages/create/question/comment/',
            str_msgEditBaseUrl: '/api/courseexams/messages/update/message/',
            str_msgVoteBaseUrl: '/api/courseexams/messages/vote/message/'
        }
    }
};

const paths = {
    courses: {
        tasksQuestionVariantsPage: 'question/:category/:numQuestion/:questionId/questions',
        tasksDetailPage: 'question/:category/:numQuestion/:questionId/discussion'
    }
};

const RoutesConfig = {
    basePages: basePages,
    paths: paths,
    permissions: permissions,
    studyMaterial: studyMaterial
};

export default RoutesConfig;
