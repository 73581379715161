import React from 'react';
import PropTypes from 'prop-types';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import TdEmailTextAndSymbol from '../td/TdEmailTextAndSymbol';
import TdPhoneTextAndSymbol from '../td/TdPhoneTextAndSymbol';
import TrCourseSwapMyOffers from './TrCourseSwapMyOffers';
import {
    useQueriedDataContext,
    useIsFirstFetchSuccessContext
} from '../../context-provider/DynamicContentLoadContextProvider';

const TbCoursesCourseSwap = ({
    arr_data=[],
    b_isDynamic=false,
    b_isMyOffer=false, /* Distinguish between my offers and general offers table data. */
    str_textAlignment='text-left',
    str_design='',
    fct_deleteMyOffer   /* Callback to remove an offer from myOffers (state and LS). */
}) => {

    const isFirstFetchSuccessRef = useIsFirstFetchSuccessContext()
    if (b_isDynamic)
        arr_data = useQueriedDataContext()

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={b_isDynamic}
            b_isFirstFetchSuccess={isFirstFetchSuccessRef ? isFirstFetchSuccessRef.current : true}
            firstQueryDataEntry={arr_data[0]}
        >
            <div className="tb-wrapper">
                <table cellSpacing="0" className={`tb-base tb-collapse-text-to-icon ${str_textAlignment} ${str_design}`}>
                    <thead>
                        <tr>
                            <td>Kurs</td>
                            {
                                !b_isMyOffer &&
                                <td className="hide-max-w-480px">User</td>
                            }
                            <td>Tausche</td>
                            <td>Gegen</td>
                            {
                                b_isMyOffer
                                ?
                                <>
                                    <td className="hide-max-w-576px">Email</td>
                                    <td className="hide-max-w-576px">Telefon</td>
                                </>
                                :
                                <>
                                    <td>Email</td>
                                    <td>Telefon</td>
                                </>
                            }
                            <td className="hide-max-w-1024px">Gepostet</td>
                            {
                                b_isMyOffer &&
                                <>
                                    <td>Edit</td>
                                    <td>Löschen</td>
                                </>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            b_isMyOffer
                            ?
                            arr_data.map((item, index) => (
                                <TrCourseSwapMyOffers
                                    key={index}
                                    obj_data={item}
                                    fct_deleteMyOffer={fct_deleteMyOffer}
                                />
                            ))
                            :
                            arr_data.map((item, index) => (
                                <tr key={index}>
                                    <td className="td-course-name">{item.courseTitle}</td>
                                    <td className="td-username hide-max-w-480px">{item.firstName} {item.lastName}</td>
                                    <td>{item.myGroup}</td>
                                    <td>{item.targetGroups}</td>
                                    <TdEmailTextAndSymbol str_email={item.email} />
                                    <TdPhoneTextAndSymbol str_phone={item.phone} />
                                    <td className="hide-max-w-1024px">{item.postedDate}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </DataStateCheckWrapper>
    )
}

TbCoursesCourseSwap.propTypes = {
    arr_data: PropTypes.array,
    b_isDynamic: PropTypes.bool,
    b_isMyOffer: PropTypes.bool,
    str_textAlignment: PropTypes.string,
    str_design: PropTypes.string,
    fct_deleteMyOffer: PropTypes.func
}

export default TbCoursesCourseSwap
