import React from 'react';
import { Outlet } from 'react-router-dom';

import PageBase from '../../../components/page-layout/PageBase';
import SbMcGrid from '../../../components/grids/SbMcGrid';
import SbDocs from '../../../components/sidebars/courses/docs/SbDocs';
import SbCourseDocsContextProvider from '../../../components/sidebars/context-provider/SbCourseDocsContextProvider';
import CourseDocsCacheContextProvider from '../../../components/context-provider/CourseDocsCacheContextProvider';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';

const DocsPage = () => {
    useSetBrowserTabTitle('Unterlagen', false, true)
    return (
        <PageBase
            str_pageSpacingClasses='page-spacing-sb-wrapper'
            b_isGridPage={true}
        >
            <SbCourseDocsContextProvider>
                <SbMcGrid comp_sb={<SbDocs />}>
                    <CourseDocsCacheContextProvider>
                        <Outlet />
                    </CourseDocsCacheContextProvider>
                </SbMcGrid>
            </SbCourseDocsContextProvider>
        </PageBase>
    )
}

export default DocsPage
