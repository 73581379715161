/**
 * This file contains the settings objects for all the profile page data
 * of industry users. This data is used to determine which data is supposed
 * to be displayed in a profile page data group. 
 * It only entails the contents of object (key/value) data. 
 * Arrays and strings are handled differently. 
 */

/** 
 * If type of value = string: Value not a link. 
 * If type of value = array:  Value is a link.
 */

const logo = {
    logo: ['']
};

const slogan = {
    slogan: ''
};

export const numbers = {
    yearFounded:       'Gründungsjahr',
    numEmployeesAut:   'Mitarbeiter österreichweit',
    numEmployeesWorld: 'Mitarbeiter weltweit',
    numOfficesAut:     'Büros österreichweit',
    numOfficesWorld:   'Büros weltweit'
};

export const descriptions = {
    description0: '1. Beschreibung', /* No title is displayed over the content. */
    description1: '2. Beschreibung',
    description2: '3. Beschreibung',
    description3: '4. Beschreibung'
};

export const benefits = {
    benefit0: '1. Vorteil', /* No title is displayed over the content. */
    benefit1: '2. Vorteil',
    benefit2: '3. Vorteil',
    benefit3: '4. Vorteil'
};

const areInternshipsOffered = {
    areInternshipsOffered: 'Werden Praktika angeboten'
};

const internshipOptions = {
    areInternshipsOffered:    'Werden Praktika angeboten',
    ishipMinLength:           'Min. Länge der Praktika',
    ishipMaxLength:           'Max. Länge der Praktika',
    ishipSeasons:             'Zu welchen Jahreszeiten',
    ishipThesisOptions:       'Möglichkeit von Abschlussarbeiten',
    ishipHasRemoteWorkOption: 'Möglichkeit von Home Office',
    ishipIsPaid:              'Sind Praktika bezahlt'
};

const equalOpsEmployer = {
    equalOpsMsg: ''
};

const IndustryProfileData = {
    logo: logo,
    slogan: slogan,
    numbers: numbers,
    descriptions: descriptions,
    benefits: benefits,
    equalOpsEmployer: equalOpsEmployer,
    areInternshipsOffered: areInternshipsOffered,
    internshipOptions: internshipOptions
};

export default IndustryProfileData;