/**
 * Queries data from the DB onmount of the component and sets the states accordingly.
 */

import { useEffect } from 'react';
import { fetchDBdata, setLSdata } from '../util/db_ls_query_handler';

export default function useGetDBdata(
    str_url,
    fct_setData,                /* Set the data of the component. */
    fct_setIsFirstFetchSuccess, /* Sets true/false depending on success of query. */
    fct_setErrorMsg,            /* Set error message the query yields upon failure. */
    fct_dataSerializer,         /* Function to serialize the queried data (if desired). */
    serializerConfig,           /* Config data for the serializer (e.g. for ObjectSerializer.js). */
    str_prLsKey,  /* Primary localStorage key. */ 
    str_secLsKey, /* Secondary localStorage key. */
    str_terLsKey, /* Tercary localStorage key. */
    b_storeQueryData2ls=false,  /* Should the data from the DB stored in the LS? */
) {
    useEffect(() => {
        const func = async () => {
            const queryData = await fetchDBdata(str_url)
            if (queryData.isQuerySuccess) {
                let data;
                if (fct_dataSerializer) {
                    if (serializerConfig) {
                        data = fct_dataSerializer(queryData.response.data, serializerConfig)
                    } else {
                        data = fct_dataSerializer(queryData.response.data)
                    }
                } else {
                    data = queryData.response.data
                }
                fct_setData(data)
                if (b_storeQueryData2ls) setLSdata(data, str_prLsKey, str_secLsKey, str_terLsKey)
                fct_setIsFirstFetchSuccess(true)
            } else {
                fct_setIsFirstFetchSuccess(false)
                fct_setErrorMsg(queryData.errorMsg)
            }
        }
        func()
    }, [])
}