import React from 'react';
import PropTypes from 'prop-types';

import { browserDateToGermanDateFormat, browserDTF2germanDTF } from '../../../util/date_time_handler';
import Usertypes from '../../../util/Usertypes';
import AuthService from '../../../util/authentication/auth_service';

const TbAbos = ({ arr_abos }) => {
    const usertype = AuthService.getUsertype()
    return (
        <div className='tb-wrapper'>
            <table cellSpacing="0" className="tb-base text-left">
                <thead>
                    <tr>
                        <td>Typ</td>
                        <td>Kaufdatum</td>
                        <td>Gültig bis</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        arr_abos &&
                        arr_abos.map((abo, index) => (
                            <tr key={index}>
                                {
                                    usertype === Usertypes.gastronomer
                                    ?
                                    <td>1 Semester</td>
                                    :
                                    <td>{abo.aboType} Monate</td>
                                }
                                <td>{browserDTF2germanDTF(abo.purchaseTimestamp, false)}</td>
                                <td>{browserDateToGermanDateFormat(abo.expirationDate)}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

TbAbos.propTypes = {
    arr_abos: PropTypes.array.isRequired
}

export default TbAbos
