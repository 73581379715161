import { useState, useEffect } from 'react';

const cleanup = (data) => {
    /**
     * Removes items from the localStorage.
     */
    try {
        if (Array.isArray(data)) {
            data.forEach(item => localStorage.removeItem(item))
        } else {
            localStorage.removeItem(data)
        }
    } catch {}
}

export default function useRemoveLSitemsUnmount(data, removeItemsOnRefresh=false) {
    /**
     * Removes items form the LS, if the input data changes or if the component gets unmounted.
     * It is possible to keep/delete the data on page refresh by setting the input arg accordingly.
     * :Input
     *  data (arr or string)
     *  removeItemsOnRefresh (bool): true if the LS items should be remove if page is refreshed.
     */
    const [lsData, setLsData] = useState(data)

    useEffect(() => {
        if (removeItemsOnRefresh) window.addEventListener('beforeunload', cleanup)

        return () => {
            cleanup(lsData)
            if (removeItemsOnRefresh) window.removeEventListener('beforeunload', cleanup)
        }
    }, [])

    useEffect(() => {
        cleanup(lsData)
        setLsData(data)
    }, [data])
}
