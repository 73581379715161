import React from 'react';
import PropTypes from 'prop-types';

import CSecBase from '../../content-sections/CSecBase';
import CSecGridBase from '../../content-sections/CSecGridBase';
import CardFood from '../../cards/food/CardFood';
import HandleNoData from '../../general/HandleNoData';

import {
    useRegularOffersThisWeek,
    useRegularOffersNextWeek,
    useRegularOffersDaily,
    useDgfsOffersToday,
    useDgfsOffersTomorrow
} from './FoodOffersListingPageContextProvider';
import { gridColsFromNumItems } from '../../../util/data_handler';
import { QueryVariables } from '../../../util/QueryVariables';
import AuthService from '../../../util/authentication/auth_service';

const DGFS_CLASS = 'do-gfs';
const DGFS_INPUT = 'dgfs';
const HEADER = {
    regular: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
    dgfs: ['Angebote']
};

const FoodOffers = ({
    str_offerType, /* Either 'regular' or 'dgfs'. */
    str_time,      /* Either 'now' (Regular: this week, Dgfs: today) or 'then' (Regular: next week, Dgfs: tomorrow). */
}) => {

    const obj_offers = {
        regular: {
            now: useRegularOffersThisWeek(),
            then: useRegularOffersNextWeek()
        },
        daily: {
            now: useRegularOffersDaily()
        },
        dgfs: {
            now: useDgfsOffersToday(),
            then: useDgfsOffersTomorrow()
        }
    }
    
    const usertype = AuthService.getUsertype();
    const numGridCols = gridColsFromNumItems(3)

    return (
        str_offerType === 'regular'
        ?
            /* Check 'null', 'undefined', and {}. */
            (obj_offers[str_offerType][str_time] && Object.keys(obj_offers[str_offerType][str_time]).length > 0)
            ?
            HEADER[str_offerType].map((weekDay, weekDayIndex) => (
                obj_offers[str_offerType][str_time][weekDayIndex][0] === undefined
                ?
                <CSecBase
                    key={weekDayIndex}
                    str_title={weekDay}
                >
                    <HandleNoData str_errorMsg={QueryVariables.str_noOffersMsg} />
                </CSecBase>
                :
                <CSecGridBase
                    key={weekDayIndex}
                    str_title={weekDay}
                    str_gridClass={`card-grid ${numGridCols}`}
                >
                    {
                        obj_offers[str_offerType][str_time][weekDayIndex].map((offer, index) => (
                            <CardFood
                                key={index}
                                nbr_id={offer.id}
                                str_usertype={usertype}
                                str_title={offer.title}
                                str_eyebrow={offer.provider}
                                str_cardClass={`card-food ${str_offerType === DGFS_INPUT ? DGFS_CLASS : ''}`}
                                obj_bodyData={offer.body}
                                obj_footerData={offer.footer}
                                b_hasSellView={true}
                            />
                        ))
                    }
                </CSecGridBase>
            ))
            :
            <HandleNoData str_errorMsg={QueryVariables.str_noOffersMsg} />
        :
        /* Dgfs cards. */
        obj_offers[str_offerType][str_time] && obj_offers[str_offerType][str_time][0] !== undefined
        ?
        <CSecGridBase
            str_title='Angebote'
            str_gridClass={`card-grid ${numGridCols}`}
        >
            {
                obj_offers[str_offerType][str_time].map((offer, index) => (
                    <CardFood
                        key={index}
                        nbr_id={offer.id}
                        str_usertype={usertype}
                        str_title={offer.title}
                        str_eyebrow={offer.provider}
                        str_cardClass={`card-food ${str_offerType === DGFS_INPUT ? DGFS_CLASS : ''}`}
                        obj_bodyData={offer.body}
                        obj_footerData={offer.footer}
                        b_hasSellView={true}
                    />
                ))
            }
        </CSecGridBase>
        :
        <HandleNoData str_errorMsg={QueryVariables.str_noOffersMsg} />
    )
}

FoodOffers.propTypes = {
    str_offerType: PropTypes.string.isRequired,
    str_time: PropTypes.string.isRequired
}

export default FoodOffers
