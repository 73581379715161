import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const PageNewBtn = ({
    str_urlPath,
    str_btnTitle='Neu'
}) => {
    return (
        <div>
            <div className="new-bar answer-bar">
                <Link to={str_urlPath}>
                    <button
                        className="btn btn-md"
                    >
                        {str_btnTitle}
                    </button>
                </Link>
            </div>
        </div>
    )
}

PageNewBtn.propTypes = {
    str_urlPath: PropTypes.string.isRequired,
    str_btnTitle: PropTypes.string
}

export default PageNewBtn
