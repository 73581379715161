import React from 'react';

import InFieldsGroup from '../../../InFieldsGroup';
import FormBase from '../../../FormBase';
import TextField from '../../../fields/TextField';
import PasswordCreationFields from '../../../fields/PasswordCreationFields';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { PasswordNewFormData as FormData } from '../../../util/form-config-data/InputFormPasswordDataConfigs';

const InputFormPasswordChange = () => {
    const data = useInputData()
    return (
        <FormBase str_title='Passwort ändern'>
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.passwordOld}
                    str_chars={data[FormData.fields.passwordOld.str_id][0]}
                />
                <PasswordCreationFields str_id='passwordReset' />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormPasswordChange
