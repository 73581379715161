import React, { useState } from 'react';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import PPDataGroup from '../PPDataGroup';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import useGetPPdataGroupData from '../../../custom-hooks/useGetPPdataGroupData';

import IndustryProfileData from './IndustryProfileData';

const CATEGORY = 'internshipOptions';
const URL = '/api/profiles/company/profile-page/internship-options/'

const IndustryPPInternshipOpts = () => {
    
    const [data, setData] = useState('')
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    useSetBrowserTabTitle('Praktikumsoptionen', true)
    useGetPPdataGroupData(URL, CATEGORY, setData, setIsFirstFetchSuccess, setErrorMsg)
    
    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            b_isContentSection={true}
            str_errorMsg={errorMsg}
            firstQueryDataEntry={1} /* Only placeholder value (data is always available here). */
        >
            <PPDataGroup
                str_header='Praktikumsoptionen'
                str_editUrl='edit'
                obj_data={data ? data.internshipOptions : null}
                obj_dataStructure={IndustryProfileData.internshipOptions}
            />
        </DataStateCheckWrapper>
    )
}

export default IndustryPPInternshipOpts
