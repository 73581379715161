import React from 'react';

import AboExtensionCompany from '../../components/abo/AboExtensionCompany';
import AboExtensionGastronomer from '../../components/abo/AboExtensionGastronomer';
import AboExtensionStudent from '../../components/abo/AboExtensionStudent';

import AuthService from '../../util/authentication/auth_service';
import Usertypes from '../../util/Usertypes';

const AboExtensionSelectionPage = () => {
    const usertype = AuthService.getUsertype()
    return (
        usertype === Usertypes.company
        ?
        <AboExtensionCompany
            str_title='Aboverlängerung'
            b_isProfilePage={false}
        />
        :
        usertype === Usertypes.gastronomer
        ?
        <AboExtensionGastronomer />
        :
        <AboExtensionStudent
            str_title='Pro-Abo Verlängerung'
            b_isProfilePage={false}
        />
    )
}

export default AboExtensionSelectionPage
