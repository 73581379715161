import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';

import useGetLSorDBdata from '../../../custom-hooks/useGetLSorDBdata';
import { genDynUrl } from '../../../util/db_ls_query_handler';

const BASE_URL = '/api/coursedocs/sidebar/';

const DocCategoriesContext = React.createContext();
const AddDocContext = React.createContext();

export function useDocCategoriesContext() {
    return useContext(DocCategoriesContext);
}

export function useAddDocContext() {
    return useContext(AddDocContext);
}

const SbCourseDocsContextProvider = ({ children }) => {

    const params = useParams()
    const [docCategories, setDocCategories] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    const url = genDynUrl(BASE_URL, params.id) /* id is course id. */
    useGetLSorDBdata(url, setDocCategories, setIsFirstFetchSuccess, setErrorMsg)

    const addDoc = (doc) => {
        /**
         * A new document of category 'category' was created.
         * Increment the number of docs of this category.
         * :Input
         *  doc (obj): Course document.
         */
        try {
            /* Capitalize category. */
            const category = doc.category.charAt(0).toUpperCase() +
                doc.category.toLowerCase().slice(1)
            
            if (docCategories[category] === undefined) return

            /* Category exists, increment num of docs of this category. */
            let newDocCategories = {}
            Object.keys(docCategories).forEach(key => {
                if (key === category) {
                    const newNumDocs = docCategories[key] + 1
                    newDocCategories[key] = newNumDocs
                } else {
                    newDocCategories[key] = docCategories[key]
                }
            })
            setDocCategories(newDocCategories)
        } catch {}
    }

    return (
        <DataStateCheckWrapper
            firstQueryDataEntry={-1} /* Placeholder, no data is not shown in the sb (just empty). */
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            str_errorMsg={errorMsg}
            b_hasContentSectionDarkDesign={true}
            b_isContentSection={true}
        >
            <DocCategoriesContext.Provider value={docCategories}>
                <AddDocContext.Provider value={addDoc}>
                    {children}
                </AddDocContext.Provider>
            </DocCategoriesContext.Provider>
        </DataStateCheckWrapper>
    )
}

export default SbCourseDocsContextProvider
