import React from 'react';
import PropTypes from 'prop-types';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';

const TbTaskDateChanges = ({ arr_data }) => {
    return (
        <DataStateCheckWrapper firstQueryDataEntry={arr_data[0]}>
            <div className="tb-wrapper">
                <table cellSpacing="0" className="tb-base">
                    <thead>
                        <tr>
                            <td>Datum</td>
                            <td>User</td>
                            <td>Geändert am</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            arr_data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.date}</td>
                                    <td>{item.author}</td>
                                    <td>{item.postDate}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </DataStateCheckWrapper>
    )
}

TbTaskDateChanges.propTypes = {
    arr_data: PropTypes.array.isRequired
}

export default TbTaskDateChanges
