import React from 'react';
import PropTypes from 'prop-types';

import InFieldsGroup from '../../../InFieldsGroup';
import FormBase from '../../../FormBase';
import TextField from '../../../fields/TextField';
import FileField from '../../../fields/FileField';
import SingleChoiceField from '../../../fields/SingleChoiceField';
import TagField from '../../../fields/TagField';
import PanelTextHelpMessage from '../../../../post-panels/PanelTextHelpMessage';

import { useInputData } from '../../../context-provider/InputFormContextProvider';

const InputFormPanel = ({
    text,       /* TextField config. */
    category,   /* Single choice field config. */
    fileFields, /* File fields config. */
    tagnames,   /* Tag field config. */
    b_hasDarkDesign=false
}) => {
    const data = useInputData()
    return (
        <FormBase
            b_isPanelForm={true}
            b_hasDarkDesign={b_hasDarkDesign}
            comp_helpMsg={<PanelTextHelpMessage />}
        >
            <InFieldsGroup b_hasBorder={false}>
                {
                    text &&
                    <TextField
                        {...text}
                        str_chars={data[text.str_id][0]}
                    />
                }

                {
                    fileFields &&
                    fileFields.map((fieldConfig, index) => (
                        <FileField
                            key={index}
                            {...fieldConfig}
                            inFile={data[fieldConfig.str_id][0]}
                        />
                    ))
                }

                {
                    category &&
                    <SingleChoiceField
                        {...category}
                        str_currentOption={data[category.str_id][0]}
                    />
                }

                {
                    tagnames &&
                    <TagField
                        {...tagnames}
                        str_tags={data[tagnames.str_id][0]}
                    />
                }
            </InFieldsGroup>
        </FormBase>
    )
}

InputFormPanel.propTypes = {
    text: PropTypes.object.isRequired,
    fileFields: PropTypes.array.isRequired,
    category: PropTypes.object.isRequired,
    b_hasDarkDesign: PropTypes.bool
}

export default InputFormPanel
