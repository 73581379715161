import React from 'react';
import PropTypes from 'prop-types';

import CardBase from '../CardBase';
import CardCompanyAcquisitionBody from './CardCompanyAcquisitionBody';
import CardCompanyAcquisitionFooter from './CardCompanyAcquisitionFooter';

const CardCompanyAcquisition = ({
    str_title,
    obj_bodyData,
    obj_footerData,
    b_hasModalView,
    b_isRedeemSuccess,
    str_redeemErrorMsg,
    fct_redeemCoupon
}) => {
    return (
        <CardBase
            str_title={str_title}
            str_cardClass={'company-acquisition card-store-coupon text-center'}
            b_hasBoldTitle={true}
            b_hasCardShadow={true}
            comp_cardBody={<CardCompanyAcquisitionBody {...obj_bodyData} />}
            comp_cardFooter={
                <CardCompanyAcquisitionFooter
                    {...obj_footerData}
                    b_hasModalView={b_hasModalView}
                    b_isRedeemSuccess={b_isRedeemSuccess}
                    str_redeemErrorMsg={str_redeemErrorMsg}
                    fct_redeemCoupon={fct_redeemCoupon}
                />
            }
        />
    )
}

CardCompanyAcquisition.propTypes = {
    str_title: PropTypes.string.isRequired,
    obj_bodyData: PropTypes.object.isRequired,
    obj_footerData: PropTypes.object.isRequired,
    b_hasModalView: PropTypes.bool.isRequired,
    b_isRedeemSuccess: PropTypes.bool,
    str_redeemErrorMsg: PropTypes.string,
    fct_redeemCoupon: PropTypes.func
}

export default CardCompanyAcquisition
