/**
 * Entaisl the basic configs of the SelectiveProposalField.js fields that
 * are used throughout the application. As fields in different places of
 * the application hit the same backend endpoint, similar data is stored
 * in this config file.
 */

const courseTitleConfig = {
    fieldTitle: 'Kurswahl',
    proposalQueryUrl: '/api/courses/course-title-search/',
    requestDataKey: 'coursetitles',
    charKey: 'title'
}

const SelectiveProposalFieldConfigData = {
    courseTitle: courseTitleConfig
};

export default SelectiveProposalFieldConfigData;
