import React from 'react';

import Accordion from '../../components/accordion/Accordion';
import AccordionHeader from '../../components/accordion/AccordionHeader';
import AccordionParagraph from '../../components/accordion/AccordionParagraph';
import AccordionList from '../../components/accordion/AccordionList';
import AccordionListItem from '../../components/accordion/AccordionListItem';

const StudentUserguide = () => {
    return (
        <>
            <Accordion str_title='Abonnement'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Ist MUPLAZA gratis?
                </AccordionHeader>
                <AccordionParagraph>
                    MUPLAZA hat sowohl kostenlose als auch kostenpflichtige Dienste und kann also kostenlos verwendet werden. Für den Zugang zu den kostenpflichtigen Diensten ist ein Pro-Abo erforderlich.
                </AccordionParagraph>

                <AccordionHeader>
                    Was ist ein Pro-Abo?
                </AccordionHeader>
                <AccordionParagraph>
                    Um bestimmte Dienste auf MUPLAZA nutzen zu können, muss ein Pro-Abo abgeschlossen werden. Ein Pro-Abo kann für folgende Zeiträume abgeschlossen werden:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>Pro-Abo für 6 Monate um 10.00 € (inkl. USt)</AccordionListItem>
                    <AccordionListItem>Pro-Abo für 12 Monate um 19.00 € (inkl. USt)</AccordionListItem>
                </AccordionList>

                <AccordionHeader>
                    Wie kann ich ein Pro-Abo erwerben?
                </AccordionHeader>
                <AccordionParagraph>
                    Gehe dazu auf deiner Profilseite auf den Menüpunkt „Abo“ in der Seitenleiste. Daraufhin öffnet sich deine persönliche Abo-Detailseite. Auf dieser Seite kannst du ein Pro-Abo erwerben. Wähle einfach eines der angebotenen Pro-Abos aus und folge den Anweisungen auf der nächsten Seite.
                </AccordionParagraph>
                
                <AccordionHeader>
                    Wann kann ich mein Pro-Abo verlängern?
                </AccordionHeader>
                <AccordionParagraph>
                    Ein Pro-Abo kann jederzeit verlängert werden, auch wenn dein aktuelles Pro-Abo noch gültig ist.
                </AccordionParagraph>

                <AccordionHeader>
                    Gibt es ein Probemonat?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja. Nach der Registrierung auf MUPLAZA erhält jeder neue Benutzer automatisch 1 Monat des Pro-Abos gratis.
                </AccordionParagraph>

                <AccordionHeader>
                    Was passiert nach Ablauf des Probemonats?
                </AccordionHeader>
                <AccordionParagraph>
                    Nach Ablauf des Probemonats wird das Pro-Abo automatisch in ein normales Abo umgewandelt. Du kannst dich also weiterhin ganz normal bei MUPLAZA anmelden und die kostenlosen Dienste nutzen.
                </AccordionParagraph>

                <AccordionHeader>
                    Kann ich die Kosten für mein Pro-Abo zurückerstattet bekommen?
                </AccordionHeader>
                <AccordionParagraph>
                    Auf MUPLAZA gibt es verschiedene Möglichkeiten, die investierten Pro-Abo-Kosten zurückerstattet zu bekommen, darunter Angebote unserer Gastronomiepartner, Firmenakquisitionen oder das Punktemining. Weitere Informationen zu den verschiedenen Möglichkeiten findest du in diesem Userguide.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Dokumente'>
                <AccordionParagraph>
                    Dieser Teil des Userguides bezieht sich nur auf den Dokumentenbereich der einzelnen Kurse auf MUPLAZA. Dokumente, die als Teil eines Forumbeitrags oder in den Lernbereichen für Prüfungen und Übungen hochgeladen werden, sind hier nicht enthalten.
                </AccordionParagraph>
                
                <AccordionHeader>
                    Wer kann Dokumente hochladen?
                </AccordionHeader>
                <AccordionParagraph>
                    Jeder Benutzer der Gruppe Studierende ist berechtig Unterlagen hochzuladen.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie viele Dokumente kann ich hochladen?
                </AccordionHeader>
                <AccordionParagraph>
                    Pro Benutzer und Semester können 8 Dokumente hochgeladen werden. In jeder der folgenden Kategorien können 4 Dokumente hochgeladen werden:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        <span className='text-bold'>1. Kategorie: </span>
                        Skript, Zusammenfassung, Cheatsheet, Prüfungsangabe, Protokoll, Programmiercode, Sonstiges;
                    </AccordionListItem>
                    <AccordionListItem>
                        <span className='text-bold'>2. Kategorie: </span>
                        Fragenkatalog, Prüfung, Übung, KNW, Kolloquium, Konversatorium, Repetitorium.
                    </AccordionListItem>
                </AccordionList>
                <AccordionParagraph>
                    Das Limit an hochladbaren Dokumenten ist aktiv, um die Anzahl der hochgeladenen Dokumente pro Semester übersichtlich zu halten.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie viele Dokumente können pro Kurs hochgeladen werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Die Dokumente werden beim Hochladen kategorisiert, um eine übersichtlichere Ablage anzubieten. Es gibt verschiedene Kategorien, wie z.B. Skript, Zusammenfassung, Fragenkatalog, etc. Für jede dieser Kategorien werden die hochgeladenen Dokumente in validierte und unvalidierte Dokumente unterteilt (siehe
                    <span className='text-italic'> „Wie funktioniert das Validierungssystem der Dokumente?“</span>
                    ). Die maximale Anzahl von Dokumenten für jede Kategorie, z.B. Skript, beträgt:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>Validierte Dokumente: 20</AccordionListItem>
                    <AccordionListItem>Unvalidierte Dokumente: 8</AccordionListItem>
                </AccordionList>
                <AccordionParagraph>
                    Jede Kategorie eines Kurses kann also maximal 28 Dokumente enthalten (28 Skripte, 28 Zusammenfassungen, 28 Fragenkataloge, usw.).
                </AccordionParagraph>
                <AccordionParagraph>
                    Wenn sich in einer Kategorie 8 unvalidierte Dokumente befinden, können für diese Kategorie vorerst keine weiteren Dokumente hochgeladen werden. Sobald ein unvalidiertes Dokument vom System gelöscht oder validiert wurde, kann ein weiteres Dokument in den freigewordenen Platz hochgeladen werden.
                </AccordionParagraph>
                <AccordionParagraph>
                    Beispiel: Wenn in der Kategorie Skript alle 8 Plätze belegt sind und es in der Kategorie Zusammenfassung weniger als 8 unvalidierte Dokumente gibt, können immer noch Dokumente in diese Kategorie hochgeladen werden.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie funktioniert das Validierungssystem der Dokumente?
                </AccordionHeader>
                <AccordionParagraph>
                    Ein Dokument kann einen von zwei Validierungszuständen annehmen:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        <span className='text-bold'>Validierte Dokumente. </span> 
                        Die Validierungsphase bestandene Dokumente.
                    </AccordionListItem>
                    <AccordionListItem>
                        <span className='text-bold'>Unvalidierte Dokumente. </span>
                        Dokumente in der Validierungsphase.
                    </AccordionListItem>
                </AccordionList>
                <AccordionParagraph>
                    Jedes neu hochgeladene Dokument durchläuft eine Validierungsphase von 180 Tagen. Nach Ablauf dieser 180 Tage erfolgt eine Qualitätsbewertung. Diese Bewertung basiert auf den Up- und Downvotes des Dokuments selbst, aber auch der anderen Dokumente derselben Kategorie. Bei ausreichender Qualität wird das Dokument validiert, andernfalls wird es von der Plattform entfernt. Im Falle einer Validierung ist das Dokument ab diesem Zeitpunkt unter den validierten Dokumenten verfügbar.
                </AccordionParagraph>
                <AccordionParagraph>
                    Was passiert, wenn sich in einer Kategorie bereits 20 validierte Dokumente befinden und ein unvalidiertes Dokument validiert wird? In diesem Fall werden alle bereits validierten Dokumente und das neu validierte Dokument gemeinsam einer Qualitätsbewertung unterzogen. Das Dokument mit der niedrigsten Qualität, wird dabei von der Plattform gelöscht.
                </AccordionParagraph>
                <AccordionParagraph>
                    Die Community entscheidet also durch ihre Bewertungen, ob ein Dokument eine ausreichende Qualität hat, um auf der Plattform zu verbleiben.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Lernbereich für Prüfungen und Übungen'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Warum gibt es einen Lernbereich?
                </AccordionHeader>
                <AccordionParagraph>
                    Hast du dich schon einmal gefragt, ob das Ergebnis in deinen Lernunterlagen richtig ist?
                </AccordionParagraph>
                <AccordionParagraph>
                    Hast du dich schon einmal gefragt, ob der Rechenweg in deinen Lernunterlagen richtig ist?
                </AccordionParagraph>
                <AccordionParagraph>
                    Hast du dich schon einmal gefragt, ob nicht auch eine andere Antwort richtig sein könnte?
                </AccordionParagraph>
                <AccordionParagraph>
                    Hast du schon einmal daran gedacht, dass Kommentare oder Beschreibungen in den Unterlagen gut wären?
                </AccordionParagraph>
                <AccordionParagraph>
                    Hast du dir schon einmal gewünscht, schnell eine Frage zu der vor dir liegenden Antwort stellen zu können?
                </AccordionParagraph>
                <AccordionParagraph>
                    Hast du dich schon einmal gefragt, welche Unterlagen du herunterladen musst, um eine Prüfung zu bestehen?
                </AccordionParagraph>
                <AccordionParagraph>
                    Hast du dich schon einmal gefragt, ob sich all diese Fragen nicht auch andere Studierende stellen?
                </AccordionParagraph>
                <AccordionParagraph>
                    Darum gibt es den Lernbereich.
                </AccordionParagraph>

                <AccordionHeader>
                    Was ist ein Lernbereich?
                </AccordionHeader>
                <AccordionParagraph>
                    Eine Kontrolle über richtige und falsche Antworten kann in hochgeladenen Dokumenten nur begrenzt gegeben werden, wenn diese nicht direkt von der Universität oder einer ähnlich zuverlässigen Quelle ausgegeben wurden.
                </AccordionParagraph>
                <AccordionParagraph>
                    Der Lernbereich wurde entwickelt, um schnell verlässliche Antworten auf Fragen und Aufgaben in Prüfungen und Übungen zu erhalten und nicht in Dokumenten suchen und hoffen zu müssen, dass alles richtig ist. Außerdem macht der Lernbereich das Herunterladen von Unterlagen überflüssig, da direkt mit den Informationen auf der Plattform interagiert werden kann.
                </AccordionParagraph>
                <AccordionParagraph>
                    Warum also mit Unterlagen arbeiten und nicht direkt mit den einzelnen Fragen von Prüfungen und Übungen lernen?
                </AccordionParagraph>

                <AccordionHeader>
                    Wie funktioniert der Lernbereich?
                </AccordionHeader>
                <AccordionParagraph>
                    Für die Prüfungen und Übungen eines jeden Kurses gibt es Lernbereiche. Die Lernbereiche befinden sich unter den Abschnitten „Prüfungen“ und „Übungen“ in der horizontalen Navigationsleiste am oberen Bildschirmrand, wenn du dich auf der Seite eines Kurses befindest.
                </AccordionParagraph>
                <AccordionParagraph>
                    Wenn du auf die Lernbereiche klickst, wird eine Jahresübersicht angezeigt. Wähle einfach das Jahr aus, in dem du Prüfungen oder Übungen machen möchtest. Danach werden dir in der Seitenleiste alle Termine angezeigt, die von Studierenden für dieses Jahr erstellt wurden. Jeder Termin enthält die Fragen bzw. Aufgaben dieses Termins. Klicke auf die Frage eines Termins, um die Frage und die dazu erstellten Antworten zu sehen. Diese Ansicht sollte dir helfen, schnell eine gute Antwort auf die Frage zu finden.
                </AccordionParagraph>

                <AccordionHeader>
                    Wer erstellt die Prüfungen, Übungen und Fragen für die Lernbereiche?
                </AccordionHeader>
                <AccordionParagraph>
                    Alle Studierende auf der Plattform mit einem Pro-Abo können diese Inhalte erstellen.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie wird die Qualität der Fragen gewährleistet?
                </AccordionHeader>
                <AccordionParagraph>
                    Zu jeder erstellten Frage können 3 Alternativen erstellt werden, d.h. es können 4 Varianten derselben Frage gleichzeitig online sein. Zur Bewertung der Qualität der Varianten einer Frage, z.B. einer Prüfungsfrage, werden deren Up- und Downvotes herangezogen. Nachdem eine Frage erstellt wurde, erhält sie eine TTL (Time-To-Live) von 120 Tagen. An jedem Tag, an dem eine Variante vom System nicht als beste Variante ausgewählt wird, verringert sich der TTL-Wert um einen Tag. Der TTL-Wert der besten Variante bleibt unverändert und diese Variante wird an diesem Tag über den Antworten angezeigt. Wenn der TTL-Wert einer Variante Null ist, wird diese Variante der Frage von der Plattform entfernt. Die Qualitätsbewertung der Varianten einer Frage wird täglich durchgeführt. Wenn also eine Fragevariante nie als beste Variante bewertet wird, bleibt sie maximal 120 Tage auf der Plattform online.
                </AccordionParagraph>

                <AccordionHeader>
                    Warum sollte ich Fragen in den Lernbereichen erstellen?
                </AccordionHeader>
                <AccordionParagraph>
                    Da jede erstellte Frage in den Lernbereichen dabei hilft, die Probleme unter
                    <span className='text-italic'> „Warum gibt es einen Lernbereich?“ </span>
                    zu lösen, wirken sich erstellte Fragen äußerst positiv auf deinen Mining-Fortschritt aus (siehe
                    <span className='text-italic'> „Wie wird der Mining-Fortschritt berechnet?“</span>).
                </AccordionParagraph>
                <AccordionParagraph>
                    Weiters können durch Erstellen von Prüfungs- und Übungsfragen Leoben Gutscheine gewonnen werden (siehe
                    <span className='text-italic'> „Was ist das Question-Mining?“</span>
                    ).
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Tags'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Was sind Tags?
                </AccordionHeader>
                <AccordionParagraph>
                    Tags sind eine Möglichkeit, den Inhalt eines Beitrags unter einem Begriff (dem Tag) oder mehreren Begriffen kurz zusammenzufassen. Wenn z.B. eine Prüfungsfrage in der Mechanik den Satz von Steiner behandelt, kann der Tag „satz-von-steiner“ zu dieser Frage hinzugefügt werden.
                </AccordionParagraph>

                <AccordionHeader>
                    Wann sollte ein neuer Tag erstellt werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Grundsätzlich sollten so wenig Tags wie möglich erstellt werden, die das gleiche Thema beschreiben, da dies den Benutzern die Suche nach Inhalten anhand von Tags erleichtert. Wenn z.B. bereits ein Tag mit dem Namen „satz-von-steiner“ existiert, ist es nicht sinnvoll, einen weiteren Tag mit dem Namen „steiner-satz“ anzulegen. Generell sollten Synonyme bei Tags möglichst vermieden werden.
                </AccordionParagraph>

                <AccordionHeader>
                    Für welche Posts können Tags erstellt werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Hochgeladene Dokumente für Kurse, Prüfungsfragen, Übungsfragen und Forum-Messages können mit Tags versehen werden.
                </AccordionParagraph>

                <AccordionHeader>
                    Welche Regeln gelten für Tags?
                </AccordionHeader>
                <AccordionParagraph>
                    Das Format der Tags unterliegt folgenden Richtlinien:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        Tags dürfen nur Kleinbuchstaben (ohne Umlaute) und Bindestriche, die als Worttrennung genutzt werden können, beinhalten.
                    </AccordionListItem>
                    <AccordionListItem>
                        Tags haben eine Mindestlänge von 3 Zeichen und eine Höchstlänge von 20 Zeichen.
                    </AccordionListItem>
                    <AccordionListItem>
                        Ein Beitrag kann mit maximal 4 Tags versehen werden (d.h. ist es sinnvoll, nur die Kernpunkte eines Beitrags zu „taggen“).
                    </AccordionListItem>
                </AccordionList>
            </Accordion>

            <Accordion str_title='Mining'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Was ist Mining?
                </AccordionHeader>
                <AccordionParagraph>
                    Das Mining auf MUPLAZA kann als Punkte-Mining verstanden werden und dient als Grundlage für das Belohnungssystem für gute Beiträge der Nutzer auf der Plattform.
                </AccordionParagraph>

                <AccordionHeader>
                    Warum sollte ich Minen?
                </AccordionHeader>
                <AccordionParagraph>
                    Die besten Miner werden am Ende jedes Semesters für ihre zahlreichen Beiträge mit einem Preisgeld belohnt. Dabei wird das auf der Plattform verfügbare Kontingent an MCs gleichmäßig auf die Gewinner verteilt. Mit den MCs können dann im Store Gutscheine gekauft werden.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie wird der Mining-Fortschritt berechnet?
                </AccordionHeader>
                <AccordionParagraph>
                    Der Mining-Fortschritt wird für jeden Benutzer auf Grundlage seiner Beiträge während des Semesters berechnet. Dabei spielen Art, Anzahl und Qualität der Beiträge eine Rolle. Zur Messung der Qualität der Beiträge werden die Up- und Downvotes der Nutzer herangezogen. Einige Beiträge gehen auch unabhängig von ihrer Nutzerbewertung positiv in das Mining ein.
                </AccordionParagraph>
                <AccordionParagraph>
                    Ein Mining-Fortschritt kann durch Beiträge in folgenden Bereichen erreicht werden:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        <span className='text-bold'>Dokumente. </span>
                        Durch das Hochladen guter Lerndokumente.
                    </AccordionListItem>
                    <AccordionListItem>
                        <span className='text-bold'>Übungen. </span>
                        Durch das Erstellen von Übungsfragen und allen Beiträgen, die hilfreich sind, um eine gute Antwort auf eine Übungsfragen zu finden.
                    </AccordionListItem>
                    <AccordionListItem>
                        <span className='text-bold'>Prüfungen. </span>
                        Durch das Erstellen von Prüfungsfragen und allen Beiträgen, die hilfreich sind, um eine gute Antwort auf eine Prüfungsfrage zu finden.
                    </AccordionListItem>
                    <AccordionListItem>
                        <span className='text-bold'>Forum. </span>
                        Durch gute Beiträge in den Foren der Kurse.
                    </AccordionListItem>
                </AccordionList>
                <AccordionParagraph>
                    Dabei ist zu beachten, dass durch Fragen und Bemerkungen genauso Punkte gesammelt werden können, wie durch Antworten.
                </AccordionParagraph>
                <AccordionParagraph>
                    Auch das bloße Erstellen von Übungs- und Prüfungsfragen ohne Antworten treibt das Mining voran.
                </AccordionParagraph>

                <AccordionHeader>
                    Kann der Mining-Fortschritt während des Semesters verfolgt werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja, der Mining-Fortschritt ist jederzeit unter dem Menüpunkt Mining ersichtlich. Der Mining-Fortschritt wird jeden Montag und Donnerstag aktualisiert.
                </AccordionParagraph>

                <AccordionHeader>
                    Geht der Mining-Fortschritt nach dem Semester verloren?
                </AccordionHeader>
                <AccordionParagraph>
                    Auf MUPLAZA gibt es zwei Arten von Mining. Zum einen gibt es das semesterbasierte Mining, das den Mining-Fortschritt durch die Beiträge des laufenden Semesters umfasst. Zum anderen wird der gesamte Mining-Fortschritt ab der Registrierung eines neuen Benutzers verfolgt.
                </AccordionParagraph>

                <AccordionHeader>
                    Was ist das Question-Mining?
                </AccordionHeader>
                <AccordionParagraph>
                    Da direkte Antworten auf einzelne Prüfungs- und Übungsfragen sehr nützlich sein können, wird für die Erstellung solcher Fragen ein separates Mining - das „Question-Mining“ - durchgeführt.
                </AccordionParagraph>
                <AccordionParagraph>
                    Die 100 besten Question-Miner erhalten Leoben Gutscheine im Wert von je bis zu 60 EUR. Die weiteren 100 besten Question-Miner erhalten Leoben Gutscheine im Wert von je bis zu 20 EUR. Insgesamt werden somit 100 erste und 100 zweite Plätze vergeben.
                </AccordionParagraph>
                <AccordionParagraph>
                    Die Platzierung im Question-Mining hängt von den Votes ab, die deine erstellten Fragen im Laufe des Semesters erhalten haben. Eine höhere Qualität der erstellten Fragen sowie eine höhere Anzahl an erstellten Fragen wirken sich also positiv auf die Platzierung aus. Um am Question-Mining eines Semesters teilzunehmen, musst du in diesem Semester mindestens 10 Fragen erstellen. Dabei spielt es keine Rolle, wie du die Fragen aufteilst, du kannst z.B. 10 Prüfungsfragen oder 6 Prüfungsfragen und 4 Übungsfragen erstellen, um die Mindestanzahl der Fragen zu erreichen.
                </AccordionParagraph>
                <AccordionParagraph>
                    Die Abrechnung des Question-Mining erfolgt immer in den ersten Tagen des Folgesemesters. Die Information über die Verteilung der Leoben Gutscheine erfolgt noch.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='MC'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Was sind MCs?
                </AccordionHeader>
                <AccordionParagraph>
                    MC steht für Montan Coin und ist die Währung auf MUPLAZA. Alle Preise auf MUPLAZA werden in MC angegeben. Der Umrechnungsfaktor von MC zu € ist 1:1, d.h. 1 € entspricht genau 1 MC.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie lange sind meine MCs gültig?
                </AccordionHeader>
                <AccordionParagraph>
                    Die MCs sind immer für ein Semester gültig. Am Ende des Semesters werden alle sich im Umlauf befindlichen MCs gelöscht und die neuen MCs an die besten Miner des vorherigen Semesters verteilt.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie können MCs erworben werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Nach jedem Semester wird der Endstand des Minings ausgewertet. Die verfügbaren MCs werden dann gleichmäßig an die besten Miner des vorherigen Semesters verteilt.
                </AccordionParagraph>

                <AccordionHeader>
                    Können MCs zwischen Benutzern transferiert werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Derzeit ist es nicht möglich, MCs zu verleihen, zu verschenken oder in anderer Form zu übertragen. Sobald sich das System eingespielt und bewährt hat, werden neue Funktionen hinzugefügt.
                </AccordionParagraph>

                <AccordionHeader>
                    Können MCs in eine andere Währung umgetauscht werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Nein. MCs sind ausschließlich zur Verwendung auf MUPLAZA bestimmt.
                </AccordionParagraph>
            </Accordion>
            
            <Accordion str_title='Store'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Was kann ich im Store erwerben?
                </AccordionHeader>
                <AccordionParagraph>
                    Im Store findest du zahlreiche Gutscheinangebote unserer Leobener Gastronomiepartner, die du mit deinen gewonnen MCs einlösen kannst.
                </AccordionParagraph>

                <AccordionHeader>
                    Gibt es Einschränkungen hinsichtlich der Käufe?
                </AccordionHeader>
                <AccordionParagraph>
                    Es gibt nur eine Einschränkung beim Kauf von Gutscheinen im Store. Während der ersten Semesterwoche auf MUPLAZA (01.03 – 07.03 und 01.10 – 07.10) kann nur ein Exemplar eines Gutscheins pro Benutzer gekauft werden. Damit ist sichergestellt, dass jeder MC-Besitzer im Store nach seinen Lieblingsprodukten suchen und stressfrei einkaufen kann.
                </AccordionParagraph>

                <AccordionHeader>
                    Wann ändert sich der Inhalt des Stores?
                </AccordionHeader>
                <AccordionParagraph>
                    Der Inhalt des Stores kann sich grundsätzlich täglich ändern, da jederzeit neue Gutscheine von unseren Gastronomiepartnern erstellt werden können.
                </AccordionParagraph>
                <AccordionParagraph>
                    Zu Beginn eines Semesters werden alle Gutscheine aus dem vergangenen Semester aus dem Store entfernt und die Gutscheine des neuen Semesters zur Verfügung gestellt.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie kann ich die im Store gekauften Gutscheine einlösen?
                </AccordionHeader>
                <AccordionParagraph>
                    Nachdem du einen Gutschein gekauft hast, findest du ihn auf deiner Profilseite unter dem Menüpunkt „Gutscheine“ in der Seitenleiste. Um den Gutschein einzulösen klickst du auf den Button „Einlösen.“ Der Gutschein wird nun groß auf dem Bildschirm deines Gerätes angezeigt. Zeige diese Ansicht nun dem Personal des Gastronomiepartners. Damit der Gutschein eingelöst werden kann, muss das Personal den Einlösecode auf deinem Gerät eingeben. Du kannst dem Personal dein Gerät entweder vorhalten oder es kurz übergeben. Nach Eingabe des Einlösecodes und Bestätigung durch das Personal erscheint der Einlösebildschirm. Die Daten auf dem Einlösebildschirm werden nun vom Personal überprüft. Nach erfolgreicher Prüfung der Daten auf dem Einlösebildschirm übergibt dir das Personal den Gutscheinwert.
                </AccordionParagraph>
                <AccordionParagraph>
                    Hinweis: Sollte der Gastronomiepartner zum Zeitpunkt des vom Studierenden geäußerten Einlösewunsches Zweifel an der Durchführung der Einlösung haben, weil der Studierende sich nicht in einem ausreichenden geistigen und/oder körperlichen Zustand befindet, weil er z.B. unter dem Einfluss von Suchtmitteln wie Alkohol steht, kann der Gastronomiepartner die Einlösung ablehnen. Der Gastronomiepartner ist verpflichtet, MUPLAZA e.U. in einem solchen Fall alsbald zu informieren. Dies gilt ebenso für das Einlösen der Gastronomie-Angebote.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Gastronomie-Angebote'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Wer kann ein Gastronomie-Angebot erwerben?
                </AccordionHeader>
                <AccordionParagraph>
                    Die Gastronomie-Angebote stehen nur Nutzern mit einem Pro-Abo zur Verfügung.
                </AccordionParagraph>

                <AccordionHeader>
                    Wann sind Gastronomie-Angebote verfügbar?
                </AccordionHeader>
                <AccordionParagraph>
                    Gastronomie-Angebote können jederzeit verfügbar sein.
                </AccordionParagraph>

                <AccordionHeader>
                    Muss ich ein Gastronomie-Angebot reservieren?
                </AccordionHeader>
                <AccordionParagraph>
                    Es werden sowohl Angebote mit als auch ohne Reservierung angeboten.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie kann ein unlimitiertes Gastronomie-Angebot erworben werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Der Erhalt der unlimitierten Gastronomie-Angebote erfolgt in 3 Schritten:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        <span className="text-bold">Studierendenausweis. </span>
                        Vorlage des gültigen Studierendenausweises beim Personal des Gastronomiepartners.
                    </AccordionListItem>
                    <AccordionListItem>
                        <span className="text-bold">Besitz des MUPLAZA Pro-Abos. </span>
                        Vorzeigen des Pro-Abos beim Personal des Gastronomiepartners. Dies tust du, indem du auf deiner Profilseite deinen Namen mit dem nebenstehenden Symbol „P“ zeigst.
                    </AccordionListItem>
                    <AccordionListItem>
                        <span className="text-bold">Animation. </span>
                        Als letzten Schritt tippst/klickst du am rechten oberen Bildschirmrand auf das Symbol mit den 3 horizontalen Streifen. Dadurch wird der Bildschirmhintergrund schwarz und die Optionen der Hauptnavigationsleiste werden angezeigt. Wenn dein Gerät eine Bildschirmgröße hat, die keine Animation zulässt, kann dir das Angebot nicht ausgegeben werden. Wir empfehlen dir, das Angebot mit deinem Smartphone einzulösen, da die Bildschirmgröße von Smartphones (fast) immer eine Animation zulässt.
                    </AccordionListItem>
                </AccordionList>
                <AccordionParagraph>
                    Sollte einer der 3 oben genannten Schritte nicht durchgeführt werden können, kann das Angebot nicht ausgegeben werden.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie viele Gastronomie-Angebote kann ich reservieren?
                </AccordionHeader>
                <AccordionParagraph>
                    Von jedem zur Verfügung stehenden Angebot kannst du ein Exemplar reservieren.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie löse ich ein reserviertes Gastronomie-Angebot ein?
                </AccordionHeader>
                <AccordionParagraph>
                    Um ein reserviertes Angebot einzulösen, musst du den Einlösebildschirm für ein reserviertes Angebot aufrufen und diesen vom Personal des Gastronomiepartners kontrollieren lassen. Den Einlösebildschirm erreichst du, indem du auf deiner Profilseite in der Seitenleiste auf „Essen & Trinken“ klickst. Dort findest du deine reservierten Angebote. Klicke bei dem entsprechenden reservierten Angebot auf „Einlösen.“ Dabei wird der das Angebot groß auf dem Bildschirm deines Geräts dargestellt. Diesen Bildschirm zeigst du nun dem Personal des Gastronomiepartners. Das Personal nennt dir nun den Einlösecode für das Angebot. Tippe den Code in das entsprechende Feld auf dem Gutschein auf deinem Gerät ein und bestätige mit Klick/Tippen auf „Jetzt einlösen.“ Danach erscheint der Einlösebildschirm (erkennbar am grünen Hintergrund). Nun zeigst du dem Personal den Einlösebildschirm zusammen mit deinem gültigen Studierendenausweis. Das Personal kontrolliert die angezeigten Daten und übergibt dir bei erfolgreicher Kontrolle das Angebot.
                </AccordionParagraph>
                <AccordionParagraph>
                    Der Einlösecode darf nach Erhalt vom Gastronomiepartner an niemanden weitergegeben werden. Eine Weitergabe dieses Codes kann zu einer Sperre des Benutzerkontos führen.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='DoG4S'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Was ist DoG4S?
                </AccordionHeader>
                <AccordionParagraph>
                    DoG4S ist eine Abkürzung für „Do Good for Students“, was übersetzt „Gutes für Studenten tun“ bedeutet. Es wird DoGAS ausgesprochen. DoG4S ist ein Konzept, das seinen Ursprung im finanziell limitierten Budget der Studierenden und der leider weit verbreiteten Lebensmittelverschwendung hat. Es ermöglicht Gastronomen Speisen und Getränke, die kurz vor Ladenschluss oder aufgrund von mangelnder optischer Qualität nicht mehr verkauft werden können, den Studierenden zu einem reduzierten Preis zur Verfügung zu stellen.
                </AccordionParagraph>

                <AccordionHeader>
                    Wer kann ein DoG4S-Angebot erwerben?
                </AccordionHeader>
                <AccordionParagraph>
                    Die Angebote von DoG4S stehen nur Nutzern mit einem Pro-Abo zur Verfügung.
                </AccordionParagraph>

                <AccordionHeader>
                    Wann sind DoG4S-Angebote verfügbar?
                </AccordionHeader>
                <AccordionParagraph>
                    Angebote von DoG4S können jederzeit verfügbar sein.
                </AccordionParagraph>

                <AccordionHeader>
                    Muss ich ein DoG4S-Angebot reservieren?
                </AccordionHeader>
                <AccordionParagraph>
                    Es werden sowohl Angebote ohne als auch mit Reservierung angeboten. Für reservierte Angebote musst du vor der Bestellung den Einlösebildschirm vorweisen.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie viele DoG4S-Angebote kann ich reservieren?
                </AccordionHeader>
                <AccordionParagraph>
                    Von jedem zur Verfügung stehenden Angebot kannst du maximal ein Exemplar reservieren.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie löse ich ein reserviertes DoG4S-Angebot ein?
                </AccordionHeader>
                <AccordionParagraph>
                    Der Vorgang geht analog zum Einlösevorgang von normalen Gastronomie-Angeboten (siehe
                    <span className='text-italic'>„Wie löse ich ein reserviertes Gastronomie-Angebot ein?“</span>
                    ).
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Industrie'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Kann ich MUPLAZA einem Unternehmen weiterempfehlen?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja, es ist möglich MUPLAZA an Unternehmen weiterzuempfehlen.
                </AccordionParagraph>
                <AccordionParagraph>
                    Wenn sich ein Unternehmen aufgrund deiner Bemühungen für eine Registrierung bei MUPLAZA entscheidet, kann es deinen Benutzernamen in der Registrierungsmaske für Unternehmen eingeben. Wenn das Unternehmen ein Abonnement abschließt, werden deinem MUPLAZA Konto 100 € in Form von Leoben Gutscheinen gutgeschrieben.
                </AccordionParagraph>
                <AccordionParagraph>
                    Der genaue Ablauf der Ausgabe der Leoben Gutscheine wird noch bekannt gegeben, da die Stadt Leoben derzeit an einer elektronischen Ausgabe der Leoben Gutscheine arbeitet, die in MUPLAZA integriert werden könnte. Wir werden dich informieren, sobald der Ablauf geklärt ist. Bis dahin findest du deine Firmenakquisen auf deiner Profilseite unter dem Punkt „Firmenakquise“ in der Seitenleiste.
                </AccordionParagraph>
                <AccordionParagraph>
                    Leoben Gutscheine werden nur an empfehlende Studierende ausgestellt, die zum Zeitpunkt der Registrierung der Firma im Besitz eines Pro-Abos waren. Als Zeitpunkt der Registrierung gilt das Datum und die Uhrzeit des Kaufs des Abonnements.
                </AccordionParagraph>
            </Accordion>
        </>
    )
}

export default StudentUserguide
