import React from 'react';

import CSecBase from '../content-sections/CSecBase';

import {
    useConfigDataContext,
    useSetupStepContext,
    useNumTotalSetupStepsContext
} from './context-provider/ProfileSetupContextProvider';

const ProfileSetupProgress = () => {
    const configData = useConfigDataContext()
    const setupStep = useSetupStepContext()
    const steps = useNumTotalSetupStepsContext()
    return (
        <CSecBase
            str_classes='section-profile-setup-step no-padding-bottom no-margin-bottom'
        >
            <div className="setup-progress">
                <span>Schritt </span> 
                <span className="number">{configData[setupStep].psStepIndex}</span> 
                <span> von </span> 
                <span className="number">{steps}</span>
            </div>
        </CSecBase>
    )
}

export default ProfileSetupProgress
