import React from 'react';
import PropTypes from 'prop-types';
import { num2StringWithNumDecimals } from '../../util/data_handler';

const MC_CURRENCY = 'MC';

const CardPriceTag = ({
    nbr_price,
    nbr_decimals=2,
    str_currency='€'
}) => {
    const numDecimals = (typeof(str_currency) === 'string' &&
                        str_currency.toUpperCase() === MC_CURRENCY) ?
                        0 : nbr_decimals
    const value = num2StringWithNumDecimals(nbr_price, numDecimals)
    
    return (
        <div className="price">
            <p>
                <span className="value">{value}</span>
                <span>{str_currency}</span>
            </p>
        </div>
    )
}

CardPriceTag.propTypes = {
    nbr_price: PropTypes.number.isRequired,
    nbr_decimals: PropTypes.number,
    str_currency: PropTypes.string
}

export default CardPriceTag
