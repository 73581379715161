import React, { useState, useEffect } from 'react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const getActiveRegistrationUser = (path) => {
    if (path.includes('stud')) return 's';
    else if (path.includes('indu')) return 'i';
    else if (path.includes('gast')) return 'g';
    else return 's';
}

const Registration = () => {

    const location = useLocation()
    const history = useNavigate()
    const [active, setActive] = useState(getActiveRegistrationUser(location.pathname))

    useSetBrowserTabTitle('Registrieren')

    useEffect(() => {
        setActive(getActiveRegistrationUser(location.pathname))
    }, [history])

    return (
        <>
            <div className="registration-types">
                <div className={`${active === 's' ? 'active' : ''}`}>
                    <Link to='student'>Student</Link>
                </div>
                <div className={`${active === 'i' ? 'active' : ''}`}>
                    <Link to='industry'>Industrie</Link>
                </div>
                {/* <div className={`${active === 'g' ? 'active' : ''}`}>
                    <Link to='gastronomy'>Gastronomie</Link>
                </div> */}
            </div>
            <Outlet />
        </>
    )
}

export default Registration
