import React from 'react';

import PrivatePage from '../../components/network/PrivatePage';
import CSecBase from '../../components/content-sections/CSecBase';
import TbCourses from '../../components/tables/courses/TbCourses';
import DynamicContentLoadContextProvider from '../../components/context-provider/DynamicContentLoadContextProvider';
import DynamicContentLoadBtn from '../../components/buttons/DynamicContentLoadBtn';
import FilterStdTextField from '../../components/filters/filters/std/FilterStdTextField';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

import Usertypes from '../../util/Usertypes';
import DynLoadPackageSizes from '../../util/config/data_load_package_sizes.json';
import { FilterLsKeys } from '../../util/LocalStorageVariables';

const LS_KEY = FilterLsKeys.courseListing;
const URL = '/api/courses/course/list/';

const CourseListingPage = () => {
    useSetBrowserTabTitle('Kurse')
    return (
        <PrivatePage arr_usertypes={[Usertypes.student]}>
            <DynamicContentLoadContextProvider
                str_queryURL={URL}
                str_lsKey={LS_KEY}
                b_useLS={true}
                b_hasFetchById={false}
                nbr_packageSize={DynLoadPackageSizes.courses.courses}
            >
                <CSecBase b_isUsedAsTitle={true} str_title='Kurse' />
                <FilterStdTextField
                    str_filterFieldName='Kursname'
                    str_classes='no-padding'
                />
                <CSecBase>
                    <TbCourses b_isDynamic={true} />
                    <DynamicContentLoadBtn />
                </CSecBase>
            </DynamicContentLoadContextProvider>
        </PrivatePage>
    )
}

export default CourseListingPage
