import React from 'react';
import PropTypes from 'prop-types';

import InFieldsGroup from '../../../input-forms/InFieldsGroup';
import FormBase from '../../../input-forms/FormBase';
import TextField from '../../../input-forms/fields/TextField';

import { useInputData } from '../../../input-forms/context-provider/InputFormContextProvider';
import { FilterFormStdTextFieldData as FormData } from '../../../input-forms/util/form-config-data/InputFormFilterDataConfigs';

const FilterFormStdTextField = ({
    str_filterTitle,
    str_filterFieldName,
    b_hasDarkDesign=false,
    b_isTransparent=false
}) => {
    const data = useInputData()
    return (
        <FormBase
            str_subtitle={str_filterTitle}
            b_isFilterForm={true}
            b_hasDarkDesign={b_hasDarkDesign}
            b_isTransparent={b_isTransparent}
        >
            <InFieldsGroup
                b_hasBorder={false}
            >
                <TextField
                    {...FormData.fields.keyword}
                    str_chars={data[FormData.fields.keyword.str_id][0]}
                    str_fieldTitle={str_filterFieldName}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

FilterFormStdTextField.propTypes = {
    str_filterTitle: PropTypes.string,
    str_filterFieldName: PropTypes.string,
    b_hasDarkDesign: PropTypes.bool,
    b_isTransparent: PropTypes.bool
}

export default FilterFormStdTextField
