/**
 * This component wraps all other routes.
 * If the stage name is the first part of the pathname of the current page,
 * this stage name is erased from the pathname and the user is redirected to
 * the new path without the stage name.
 * This is a necessary step to redirect the user to the correct React route
 * when he refreshes the page.
 */
import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

/* Add new stage name to this array or change entries in this array. */
const STAGE_NAMES = [
    'dev',
    'production'
];

const NUM_STAGE_NAMES = STAGE_NAMES.length;

const pathIncludesStageName = (stageName, path) => {
    const s = '/' + stageName + '/';
    return path.indexOf(s) > -1;
}

const createNewPath = (stageName, path) => {
    const s = '/' + stageName;
    return path.replace(s, '');
}

const StageRouteRedirect = () => {

    const [hasPermission, setHasPermission] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    
    useEffect(() => {
        setHasPermission(true)
        const path = location.pathname
        for (let i = 0; i < NUM_STAGE_NAMES; i++) {
            const stageName = STAGE_NAMES[i]
            if (pathIncludesStageName(stageName, path)) {
                const newPath = createNewPath(stageName, path)
                navigate(newPath, { replace: true })
                break
            }
        }
    }, [])
    
    return (
        hasPermission && <Outlet />
    )
}

export default StageRouteRedirect
