import React from 'react';

import FormBase from '../../../FormBase';
import InFieldsGroup from '../../../InFieldsGroup';
import TextField from '../../../fields/TextField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { IndustryContactPersonFormData as FormData } from '../../../util/form-config-data/InputFormIndustryDataConfigs';

const SUBTITLE = 'Die Daten der Kontaktperson sind für andere Nutzer auf der Plattform ' +
    'sichtbar, um mit einer bestimmten Person aus deinem Unternehmen Kontakt aufzunehmen.';

const InputFormIndustryContactPerson = () => {
    const data = useInputData()
    return (
        <FormBase 
            str_title='Kontaktperson'
            str_subtitle={SUBTITLE}
        >
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.firstName}
                    str_chars={data[FormData.fields.firstName.str_id][0]}
                />
                <TextField
                    {...FormData.fields.lastName}
                    str_chars={data[FormData.fields.lastName.str_id][0]}
                />
                <TextField
                    {...FormData.fields.jobPosition}
                    str_chars={data[FormData.fields.jobPosition.str_id][0]}
                />
                <TextField
                    {...FormData.fields.workLocation}
                    str_chars={data[FormData.fields.workLocation.str_id][0]}
                />
                <TextField
                    {...FormData.fields.phone}
                    str_chars={data[FormData.fields.phone.str_id][0]}
                />
                <TextField
                    {...FormData.fields.email}
                    str_chars={data[FormData.fields.email.str_id][0]}
                />
                <TextField
                    {...FormData.fields.linkedin}
                    str_chars={data[FormData.fields.linkedin.str_id][0]}
                />
                <TextField
                    {...FormData.fields.twitter}
                    str_chars={data[FormData.fields.twitter.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormIndustryContactPerson
