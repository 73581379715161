import React, { useState } from 'react';

import CSecBase from '../../../components/content-sections/CSecBase';
import TbCoursesDocs from '../../../components/tables/courses/TbCoursesDocs';
import DataStateCheckWrapper from '../../../components/general/DataStateCheckWrapper';
import useGetCourseDocsCacheOrDBdata from '../../../custom-hooks/useGetCourseDocsCacheOrDBdata';

const DocsListingPage = () => {

    const [data, setData] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState(undefined)
    const [errorMsg, setErrorMsg] = useState('')
    
    useGetCourseDocsCacheOrDBdata(setData, setIsFirstFetchSuccess, setErrorMsg)

    return (
        <DataStateCheckWrapper
            str_contentSectionTitle='Dokumente'
            b_isContentSection={true}
            b_isContentSectionForChildren={false}
            b_hasContentSectionDarkDesign={true}
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            str_errorMsg={errorMsg}
            firstQueryDataEntry={-1} /* -1 as placeholder. */
        >
            <CSecBase
                str_title='Dokumente'
                str_subtitle='Validiert'
            >
                <TbCoursesDocs arr_data={data ? data.validated : undefined} />
            </CSecBase>
            <CSecBase str_subtitle='Validierung'>
                <TbCoursesDocs arr_data={data ? data.unvalidated : undefined} />
            </CSecBase>
        </DataStateCheckWrapper>
    )
}

export default DocsListingPage
