import React from 'react';
import PropTypes from 'prop-types';

import { german2wordMonthDateFormat } from '../../util/date_time_handler';

const DocuPageHeader = ({
    str_title,
    str_date, /* Format dd.mm.yyyy. */
    children
}) => {
    return (
        <div className="header">
            <h5 className="muplaza-header">MUPLAZA</h5>
            <h1>{str_title}</h1>
            <h4>Letztes Update am {german2wordMonthDateFormat(str_date)}</h4>
            {children}
        </div>
    )
}

DocuPageHeader.propTypes = {
    str_title: PropTypes.string.isRequired,
    str_date: PropTypes.string.isRequired
}

export default DocuPageHeader
