import React from 'react';

import PageBase from '../page-layout/PageBase';

const PPLayout = ({ sidebar, children }) => {
    return (
        <PageBase b_isGridPage={true} str_pageSpacingClasses='page-spacing-sb-wrapper' >
            {/* Grid. */}
            <div className="page-sb-mc-grid">
                <div className="grid-item grid-sb sb-profile">
                    {sidebar}
                </div>
                <div className="grid-item grid-mc grid-profile">
                    <div id="main-content">
                        <div className="mc-wrapper">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </PageBase>
    )
}

export default PPLayout
