import React from 'react';

import InFieldsGroup from '../../../InFieldsGroup';
import FormBase from '../../../FormBase';
import NumberField from '../../../fields/NumberField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { IndustryNumbersFormData as FormData } from '../../../util/form-config-data/InputFormIndustryDataConfigs';


const InputFormIndustryNumbers = () => {
    const data = useInputData()
    return (
        <FormBase str_title='Zahlen'>
            <InFieldsGroup b_hasBorder={false}>
                <NumberField
                    {...FormData.fields.yearFounded}
                    nbr_value={data[FormData.fields.yearFounded.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.numEmployeesAut}
                    nbr_value={data[FormData.fields.numEmployeesAut.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.numEmployeesWorld}
                    nbr_value={data[FormData.fields.numEmployeesWorld.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.numOfficesAut}
                    nbr_value={data[FormData.fields.numOfficesAut.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.numOfficesWorld}
                    nbr_value={data[FormData.fields.numOfficesWorld.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormIndustryNumbers
