import React from 'react';

import PrivatePage from '../../network/PrivatePage';
import PPMainContentBase from '../PPMainContentBase';
import DynamicContentLoadContextProvider from '../../context-provider/DynamicContentLoadContextProvider';
import TbStudentPPUploadedDocs from '../../tables/student/TbStudentPPUploadedDocs';

import Usertypes from '../../../util/Usertypes';
import DynLoadPackageSizes from '../../../util/config/data_load_package_sizes.json';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';

const URL = '/api/coursedocs/profile-page/student/list/';

const StudentPPCourseUploadedDocs = () => {
    useSetBrowserTabTitle('Unterlagen', true)
    return (
        <PrivatePage arr_usertypes={[Usertypes.student]}>
            <PPMainContentBase
                str_header='Meine Unterlagen'
                str_subHeader='Liste deiner hochgeladenen Unterlagen'
            >
                <DynamicContentLoadContextProvider
                    str_queryURL={URL}
                    nbr_packageSize={DynLoadPackageSizes.coursedocs.docsStudentPP}
                >
                    <TbStudentPPUploadedDocs />
                </DynamicContentLoadContextProvider>
            </PPMainContentBase>
        </PrivatePage>
    )
}

export default StudentPPCourseUploadedDocs
