/**
 * Entails the configuration of password fields. 
 */

import FieldLimits from '../db_field_limits.json';
import BottomTexts from '../input_form_bottom_info_texts.json';
import { genFieldData } from "./InputFormBaseDataAndSerializer";

export const PasswordNewFormData = {
    str_localStorageKey: '',
    str_queryURL: '/api/profiles/password/change/',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        passwordOld:   ['',       true],
        passwordReset: [['', ''], true]
    },
    fields: {
        passwordOld: genFieldData('text', {
            str_id: 'passwordOld',
            str_fieldTitle: 'Altes Passwort',
            b_isTextarea: false,
            b_isPassword: true,
            int_minLength: FieldLimits.password.length.min,
            int_maxLength: FieldLimits.password.length.max
        })
    }
};

export const PasswordConfirmFormData = {
    str_localStorageKey: '',
    str_queryURL: '/password/reset',
    str_navigationURLonDiscard: '',
    obj_initState: {
        passwordReset: [['', ''], true],
        /* The hash is filled by using the according URL param (no input field). */
        hash: ['', false]
    }
};

export const PasswordCreationFormData = {
    str_localStorageKey: '',
    str_queryURL: '/password/reset',
    str_navigationURLonDiscard: '',
    obj_initState: {
        password:     ['', true],
        confirmation: ['', true]
    },
    fields: {
        password: genFieldData('text', {
            str_id: 'password',
            str_fieldTitle: 'Neues Passwort',
            str_bottomInfoText: BottomTexts.password.rules,
            b_isTextarea: false,
            b_isPassword: true,
            b_isPwConfirmation: false,
            int_minLength: FieldLimits.password.length.min,
            int_maxLength: FieldLimits.password.length.max
        }),
        confirmation: genFieldData('text', {
            str_id: 'confirmation',
            str_fieldTitle: 'Passwort bestätigen',
            str_bottomInfoText: BottomTexts.password.rules,
            b_isTextarea: false,
            b_isPassword: true,
            b_isPwConfirmation: true,
            int_minLength: FieldLimits.password.length.min,
            int_maxLength: FieldLimits.password.length.max
        })
    }
};