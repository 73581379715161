import React from 'react';
import PropTypes from 'prop-types';

import InputFormContextProvider from '../input-forms/context-provider/InputFormContextProvider';
import InputFormRedemption from '../input-forms/forms/redemption/InputFormRedemption';

import { useSetModalOpencloseIdContext } from '../modal/context-provider/ModalContextProvider';
import { RedemptionFormData } from '../input-forms/util/form-config-data/InputFormCouponDataConfigs';

const CardFooterRedeemButton = ({
    nbr_id,                 /* ID of the element the card represents (required for redemptions). */
    b_isRedeemed,           /* Relevant if only one item can be owned. */
    b_isRedeemSuccess,      /* Info if redemption worked. */
    str_redeemTimestamp,    /* Timestamp when of the requested redemption. */
    str_redeemUserFullName, /* Full name of user who redeemed the coupon (Max Mustermann). */
    str_redeemCheckCode,    /* Code that the gastronomer user owns to verify correct redemption. */
    str_queryUrl,           /* Url to send the redemption request to. */
    b_isCoupon=false,       /* Identifies coupons, which can always be redeemed. */
    b_hasCouponsLeft,       /* Relevant if multiple items can be owned. */
    b_hasExpired=false,     /* True if coupon has expired. */
    b_isToday,              /* Is date today? Identifies if the item can be redeemed today (food offers). */
    b_hasModalView=false,   /* Is coupon displayed in modal view? */
    fct_handleRedeem
}) => {
    const setModalOpenCloseId = useSetModalOpencloseIdContext()
    return (
        b_isToday || b_isCoupon
        ?
        <div className="action-el">
            {
                b_hasExpired
                ?
                <p className="sold-out">abgelaufen</p>
                :
                /* Modal window card. */
                b_isRedeemSuccess
                ?
                    /* Redeem success view in modal window. */
                    <div className="redeem-container">
                        <div className="coupon-msg">Eingelöst</div>
                        <div className="intro">von</div>
                        <div className="username">{str_redeemUserFullName}</div>
                        <div className="intro">am</div>
                        <div className="timestamp">{str_redeemTimestamp}</div>
                        <hr />
                        <div className="intro">Kontroll-Code</div>
                        <div className="check-code">{str_redeemCheckCode}</div>
                    </div>
                :
                /* Normal card. */
                b_hasCouponsLeft || b_isRedeemed === false
                ?
                    b_hasModalView
                    ?
                        <InputFormContextProvider
                            str_queryURL={str_queryUrl}
                            str_requestType='put'
                            obj_initState={RedemptionFormData.obj_initState}
                            obj_clearedState={RedemptionFormData.obj_initState}
                            b_hasDiscardBtn={false}
                            fct_response2parent={fct_handleRedeem}
                        >
                            <InputFormRedemption />
                        </InputFormContextProvider>
                    :
                    <button
                        className="btn btn-sm btn-submit"
                        onClick={() => setModalOpenCloseId(nbr_id)}
                    >
                        Einlösen
                    </button>
                :
                <p className="sold-out">
                    {
                        /* Display different messages depending on whether you can own one or more items. */
                        b_isRedeemed ? 'eingelöst' : 'alle eingelöst'
                    }
                </p>
            }
        </div>
        :
        /* Display a deactivated button for items that the user owns, but cannot redeeem today. */
        <div className="action-el">
            <button className="btn btn-sm btn-deactivated">Einlösen</button>
        </div>
    )
}

CardFooterRedeemButton.propTypes = {
    nbr_id: PropTypes.number.isRequired,
    b_isRedeemed: PropTypes.bool,
    b_isRedeemSuccess: PropTypes.bool,
    str_redeemTimestamp: PropTypes.string,
    str_redeemUserFullName: PropTypes.string,
    str_redeemCheckCode: PropTypes.string,
    str_queryUrl: PropTypes.string.isRequired,
    b_hasExpired: PropTypes.bool,
    b_isCoupon: PropTypes.bool,
    b_hasCouponsLeft: PropTypes.bool,
    b_isToday: PropTypes.bool,
    b_hasModalView: PropTypes.bool,
    fct_handleRedeem: PropTypes.func
}

export default CardFooterRedeemButton
