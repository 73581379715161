import React from 'react';

import FormBase from '../../FormBase';
import InFieldsGroup from '../../InFieldsGroup';
import TextField from '../../fields/TextField';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { RedemptionFormData as FormData } from '../../util/form-config-data/InputFormCouponDataConfigs';

const InputFormRedemption = () => {
    const data = useInputData()
    return (
        <FormBase str_btnTitle='Jetzt einlösen'>
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.redemptionCode}
                    str_chars={data[FormData.fields.redemptionCode.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormRedemption
