import React from 'react';

import FormBaseWelcome from '../../FormBaseWelcome';
import InFieldsGroup from '../../InFieldsGroup';
import TextField from '../../fields/TextField';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { PasswordForgotFormData as FormData } from '../../util/form-config-data/InputFormWelcomeDataConfigs';

const InputFormPasswordForgot = () => {
    const data = useInputData()
    return (
        <FormBaseWelcome b_isRequiredInfoShown={false}>
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.email}
                    str_chars={data[FormData.fields.email.str_id][0]}
                />
            </InFieldsGroup>
        </FormBaseWelcome>
    )
}

export default InputFormPasswordForgot
