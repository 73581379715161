import React from 'react';
import PropTypes from 'prop-types';

import InputFormSubmitButton from './InputFormSubmitButton';

const FormBaseWelcome = ({
    str_btnTitle='Eingabe',
    b_isRequiredInfoShown=true,
    children
}) => {
    return (
        <div className="wrap-form">
            <form className="wf" autoComplete='off'>
                <div className={`fields-wf ${b_isRequiredInfoShown ? '' : 'hide-required-info'}`}>
                    {children} {/* Input form. */}
                </div>
                <InputFormSubmitButton str_btnTitle={str_btnTitle} />
            </form>
        </div>
    )
}

FormBaseWelcome.propTypes = {
    str_btnTitle: PropTypes.string,
    str_submitErrorMsg: PropTypes.string,
    b_isRequiredInfoShown: PropTypes.bool,
}

export default FormBaseWelcome
