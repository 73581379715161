import React, { useState, useEffect, useContext } from 'react';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import { fetchDBdata } from '../../../util/db_ls_query_handler';
import FoodOffersSerializer from '../../../util/serializers/backend_serializers/food_offers_serializer';

const RegularOffersThisWeekContext = React.createContext();
const RegularOffersNextWeekContext = React.createContext();
const RegularOffersDailyContext = React.createContext();
const DgfsOffersTodayContext = React.createContext();
const DgfsOffersTomorrowContext = React.createContext();

export function useRegularOffersThisWeek() {
    return useContext(RegularOffersThisWeekContext);
}

export function useRegularOffersNextWeek() {
    return useContext(RegularOffersNextWeekContext);
}

export function useRegularOffersDaily() {
    return useContext(RegularOffersDailyContext);
}

export function useDgfsOffersToday() {
    return useContext(DgfsOffersTodayContext);
}

export function useDgfsOffersTomorrow() {
    return useContext(DgfsOffersTomorrowContext);
}

const URL = '/api/gastronomy/food-offers/list/';

const FoodOffersListingPageContextProvider = ({ children }) => {

    const [offersThisWeek, setOffersThisWeek] = useState()
    const [offersNextWeek, setOffersNextWeek] = useState()
    const [offersDaily, setOffersDaily] = useState()
    const [dgfsOffersToday, setDgfsOffersToday] = useState()
    const [dgfsOffersTomorrow, setDgfsOffersTomorrow] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(() => {
        const func = async () => {
            const queryData = await fetchDBdata(URL)
            if (queryData.isQuerySuccess) {
                const data = queryData.response.data
                let serializedData;

                serializedData = FoodOffersSerializer.dbList2foodOffersWeekdayObject(data.thisWeek)
                setOffersThisWeek(serializedData)

                serializedData = FoodOffersSerializer.dbList2foodOffersWeekdayObject(data.nextWeek)
                setOffersNextWeek(serializedData)

                serializedData = FoodOffersSerializer.dbList2foodOfferCardsArray(data.daily)
                setOffersDaily(serializedData)

                serializedData = FoodOffersSerializer.dbList2foodOfferCardsArray(data.today)
                setDgfsOffersToday(serializedData)
                
                serializedData = FoodOffersSerializer.dbList2foodOfferCardsArray(data.tomorrow)
                setDgfsOffersTomorrow(serializedData)

                setIsFirstFetchSuccess(true)
            } else {
                setIsFirstFetchSuccess(false)
                setErrorMsg(queryData.errorMsg)
            }
        }
        func()
    }, [])

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            firstQueryDataEntry={-1} /* Placeholder to avoid 'no data' rendering (details handled by children). */
            str_errorMsg={errorMsg}
            b_isContentSection={true}
        >
            <RegularOffersThisWeekContext.Provider value={offersThisWeek}>
                <RegularOffersNextWeekContext.Provider value={offersNextWeek}>
                    <RegularOffersDailyContext.Provider value={offersDaily}>
                        <DgfsOffersTodayContext.Provider value={dgfsOffersToday}>
                            <DgfsOffersTomorrowContext.Provider value={dgfsOffersTomorrow}>
                                {children}
                            </DgfsOffersTomorrowContext.Provider>
                        </DgfsOffersTodayContext.Provider>
                    </RegularOffersDailyContext.Provider>
                </RegularOffersNextWeekContext.Provider>
            </RegularOffersThisWeekContext.Provider>
        </DataStateCheckWrapper>
    )
}

export default FoodOffersListingPageContextProvider
