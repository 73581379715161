import React from 'react';
import PropTypes from 'prop-types';

import DataStateCheckWrapper from '../general/DataStateCheckWrapper';
import CSecBase from './CSecBase';

const CSecParagraphs = ({
    str_title='',
    str_subtitle='',
    str_classes='',
    b_hasDarkDesign=false,
    b_hasCenteredText=false,
    arr_paragraphs=[]
}) => {
    return (
        <CSecBase
            str_title={str_title}
            str_subtitle={str_subtitle}
            str_classes={str_classes}
            b_hasDarkDesign={b_hasDarkDesign}
            b_hasCenteredText={b_hasCenteredText}
        >
            <DataStateCheckWrapper
                firstQueryDataEntry={arr_paragraphs ? arr_paragraphs[0] : undefined}
            >
                {
                    arr_paragraphs &&
                    <ul>
                        {
                            arr_paragraphs.map((item, index) => (
                                <li key={index}>
                                    <p>
                                        {item}
                                    </p>
                                </li>
                            ))
                        }
                    </ul>
                }
            </DataStateCheckWrapper>
        </CSecBase>
    )
}

CSecParagraphs.propTypes = {
    str_title: PropTypes.string,
    str_subtitle: PropTypes.string,
    str_classes: PropTypes.string,
    b_hasDarkDesign: PropTypes.bool,
    b_hasCenteredText: PropTypes.bool,
    arr_paragraphs: PropTypes.array
}

export default CSecParagraphs
