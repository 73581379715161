import React from 'react'
import PropTypes from 'prop-types'

import InputFormHeader from './InputFormHeader'
import InputFormSubmitButton from './InputFormSubmitButton'

import { useIsConfirmationViewOpenContext } from './context-provider/FormBaseContextProvider'

const str_confirmationViewSubtitle = 'Sind die angegebenen Daten korrekt? Wenn ja, dann klicke auf \'Eingabe\'. ' +
    'Andernfalls kannst du mittels \'Zurück\' wieder zur Eingabeform gelangen.';

const FormBase = ({
    str_title='',
    str_subtitle='',
    b_isFilterForm=false,
    b_isPanelForm=false,
    b_hasFileField=false,
    b_hasScreenshots=false,
    b_hasPrivacyTerms=false,
    b_hasFilterGrid=false,
    b_isTransparent=false,
    arr_headerInfos=[],
    str_btnTitle='Eingabe',
    str_submitButtonSize='',
    comp_helpMsg='',
    children
}) => {
    const isConfirmationViewOpen = useIsConfirmationViewOpenContext()
    return (
        <div className="in-wrapper">
            <form className={`
                in-content in-form
                ${b_isFilterForm ? 'filter-form' : ''}
                ${b_isPanelForm ? 'panel-form' : ''}
                ${b_hasFilterGrid ? 'filter-grid' : ''}
                ${b_isTransparent ? 'transparent' : ''}
            `}>
                <InputFormHeader
                    str_title={str_title}
                    str_subtitle={isConfirmationViewOpen ? 'Entwurf' : str_subtitle}
                    arr_headerInfos={isConfirmationViewOpen ? [str_confirmationViewSubtitle] : arr_headerInfos}
                    b_isFilterForm={b_isFilterForm}
                    b_hasFileField={b_hasFileField}
                    b_hasScreenshots={b_hasScreenshots}
                    b_hasPrivacyTerms={b_hasPrivacyTerms}
                />
                <div className="in-fields">
                    {children}
                </div>
                <InputFormSubmitButton
                    str_btnSize={str_submitButtonSize}
                    str_btnTitle={str_btnTitle}
                    comp_helpMsg={comp_helpMsg}
                />
            </form>
        </div>
    )
}

FormBase.propTypes = {
    str_title: PropTypes.string,
    str_subtitle: PropTypes.string,
    b_isFilterForm: PropTypes.bool,
    b_isPanelForm: PropTypes.bool,
    b_hasFileField: PropTypes.bool,
    b_hasScreenshots: PropTypes.bool,
    b_hasPrivacyTerms: PropTypes.bool,
    b_hasFilterGrid: PropTypes.bool,
    b_isTransparent: PropTypes.bool,
    arr_headerInfos: PropTypes.array,
    str_btnTitle: PropTypes.string,
    str_submitButtonSize: PropTypes.string,
}

export default FormBase
