import React from 'react';
import PropTypes from 'prop-types';

import CardPriceTag from '../CardPriceTag';
import CardFooterRedeemButton from '../CardFooterRedeemButton';

const MC_CURRENCY = 'MC';
const BASE_REDEEM_URL = '/api/store/coupons/detail/';

const CardCouponFooterRedeem = ({
    nbr_id,
    nbr_price,
    nbr_purchased,
    nbr_redeemed,
    /* Handle redeem  action in modal view. */
    b_hasModalView=false,
    b_isRedeemSuccess,
    str_redeemTimestamp,
    str_redeemUserFullName,
    str_redeemCheckCode,
    fct_handleRedeem
}) => {
    const queryUrl = BASE_REDEEM_URL + nbr_id + '/'
    return (
        <div className="card-footer-offer">
            <CardPriceTag
                nbr_price={nbr_price}
                str_currency={MC_CURRENCY}
            />
            <CardFooterRedeemButton
                nbr_id={nbr_id}
                b_hasCouponsLeft={nbr_purchased - nbr_redeemed > 0 ? true : false}
                b_isCoupon={true}
                b_isRedeemSuccess={b_isRedeemSuccess}
                b_hasModalView={b_hasModalView}
                str_redeemTimestamp={str_redeemTimestamp}
                str_redeemUserFullName={str_redeemUserFullName}
                str_redeemCheckCode={str_redeemCheckCode}
                str_queryUrl={queryUrl}
                fct_handleRedeem={fct_handleRedeem}
            />
            {
                !b_hasModalView &&
                <div className="add-info">
                    <div className="add-info-wrapper">
                        <p className="nowrap">
                            <span className="info-nums">
                                <span className="info num-redeemed">{nbr_redeemed}</span>
                                <span className="info separator">/</span>
                                <span className="info num-purchased">{nbr_purchased}</span>
                            </span>
                            <span className="info num-title">eingelöst</span>
                        </p>
                    </div>
                </div>
            }
        </div>
    )
}

CardCouponFooterRedeem.propTypes = {
    nbr_id: PropTypes.number.isRequired,
    nbr_price: PropTypes.number.isRequired,
    nbr_purchased: PropTypes.number.isRequired,
    nbr_redeemed: PropTypes.number.isRequired,
    b_hasModalView: PropTypes.bool,
    b_isRedeemSuccess: PropTypes.bool,
    str_redeemTimestamp: PropTypes.string,
    str_redeemUserFullName: PropTypes.string,
    str_redeemCheckCode: PropTypes.string,
    fct_handleRedeem: PropTypes.func
}

export default CardCouponFooterRedeem
