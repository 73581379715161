import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

import PrivatePage from '../../network/PrivatePage';
import PPMcNavbar from '../../navbars/PPMcNavbar';
import StudentPPFoodOffersContextProvider from './context-provider/StudentPPFoodOffersContextProvider';

import Usertypes from '../../../util/Usertypes';
import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';

const StudentPPFoodOffersBase = ({ b_isDgfs=false }) => {
    useSetBrowserTabTitle('Reservierte Speisen', true)
    return (
        <PrivatePage arr_usertypes={[Usertypes.student]}>
            <StudentPPFoodOffersContextProvider b_isDgfs={b_isDgfs}>
                <PPMcNavbar
                    str_userType='student'
                    str_dataKey={b_isDgfs ? 'foodOffersDgfs' : 'foodOffersRegular'}
                />
                <Outlet />
            </StudentPPFoodOffersContextProvider>
        </PrivatePage>
    )
}

StudentPPFoodOffersBase.propTypes = {
    b_isDgfs: PropTypes.bool
}

export default StudentPPFoodOffersBase
