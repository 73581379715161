import React from 'react';
import PropTypes from  'prop-types';
import { AiOutlinePhone } from 'react-icons/ai';

import {
    formatPhoneNumber,
    fillBlankTableCell,
    copyData2Clipboard
} from '../../../util/data_handler';

const TdPhoneTextAndSymbol = ({
    str_phone,
    str_classes
}) => {
    
    const handleIconOnClick = (e) => {
        const str_value = e.target.closest('td').getAttribute('data-value')
        if (!str_value) return
        copyData2Clipboard(str_value)
    }
    
    return (
        <>
            {
                str_phone
                ?
                <td className={`td-text-lg-screen ${str_classes}`}>
                    {formatPhoneNumber(fillBlankTableCell(str_phone))}
                </td>
                :
                <td className={`td-text-lg-screen ${str_classes}`}>—</td>
            }
            {
                str_phone
                ?
                <td
                    className={`td-icon td-icon-sm-screen td-phone-icon ${str_classes}`}
                    data-value={str_phone}
                >
                    <AiOutlinePhone onClick={e => handleIconOnClick(e)} />
                </td>
                :
                <td className={`td-icon-sm-screen ${str_classes}`}>—</td>
            }
        </>
    )
}

TdPhoneTextAndSymbol.propTypes = {
    str_phone: PropTypes.string.isRequired,
    str_classes: PropTypes.string
}

export default TdPhoneTextAndSymbol
