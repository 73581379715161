/**
 * This file entails the functionality to redeem coupons.
 */
import { useMemo } from 'react';

const useSetModalCoupon = (couponId, coupons, setModalCoupon) => {
    useMemo(() => {
        /**
         * Get the coupon item from all the available items of the context provider.
         */
        setModalCoupon(coupons.filter(coupon => coupon.id === couponId)[0])
    }, [couponId])
}

const useRestoreModalInitState = (isModalOpen, setIsRedeemSuccess) => {
    /**
     * Remove the redeem try success/fail outcome when the modal is closed.
     * 'undefined' is the init state when the user interaction with the modal starts.
     */
    useMemo(() => {
        if (!isModalOpen) setIsRedeemSuccess(undefined)
    }, [isModalOpen])
}

const redeemCoupon = (
    response,
    modalCoupon,
    coupons,
    setCoupons,
    updateModalCouponData /* Callback function to apply specific updates to the modal coupon. */
) => {
    modalCoupon.footer.b_isRedeemed = true
    modalCoupon.footer.redeemTimestamp = response.data.redeemTimestamp
    modalCoupon.footer.redeemUserFullName = response.data.userFullName
    modalCoupon.footer.redeemCheckCode = response.data.redeemCheckCode
    if (updateModalCouponData) updateModalCouponData(modalCoupon)
    setCoupons(coupons.map(coupon => coupon.id === modalCoupon.id ? modalCoupon : coupon))
}

const CouponRedeemService = {
    useSetModalCoupon: useSetModalCoupon,
    useRestoreModalInitState: useRestoreModalInitState,
    redeemCoupon: redeemCoupon
};

export default CouponRedeemService;
