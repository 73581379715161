/**
 * This hook generates the dyn. config object for input forms.
 * It is used if input form data can be pre-filled in order to reuse the
 * data or edit it.
 * 
 * The data can either be fetched from the LS or the DB.
 * It is also possible to only provide the query URL without making any
 * request. The entry for the init. state is then 'null'.
 */

import { useEffect } from "react";
import { fetchLSorDBdata } from "../util/db_ls_query_handler";
import { db2inputFormData } from "../components/input-forms/util/input_form_data_handler";

export default function useConfigureDynInputFormData(
    b_hasQuery,                 /* True: Query LS/DB. False: Return init. data from object. */
    str_queryURL,
    fct_setDynConfig,           /* Dyn. config setter for the input form. */
    fct_setIsFirstFetchSuccess, /* Sets true/false depending on success of query. */
    fct_setErrorMsg,            /* Set error message the query yields upon failure. */
    nbr_id,                     /* Id of the object that could be stored in the LS. */
    str_prLsKey='',             /* Primary localStorage key. */ 
    str_secLsKey='',            /* Secondary localStorage key. */
    str_terLsKey='',            /* Tercary localStorage key. */
    fct_serializer              /* Serializer function to transform queried data. */
) {
    useEffect(() => {
        const genFormData = (str_queryURL, data) => {
            return {
                str_queryURL: str_queryURL,
                obj_initState: db2inputFormData(data)
            }
        }

        const func = async () => {
            const queryData = await fetchLSorDBdata(
                str_queryURL,
                str_prLsKey,
                str_secLsKey,
                str_terLsKey
            )
            if (queryData.isQuerySuccess) {
                let data;
                if (Array.isArray(queryData.response.data)) {
                    const nbrId = parseInt(nbr_id)
                    data = queryData.response.data.filter(item => item.id === nbrId)[0]
                } else {
                    data = queryData.response.data
                }

                if (fct_serializer) {
                    data = fct_serializer(data)
                }

                const dynConfig = genFormData(str_queryURL, data)
                fct_setDynConfig(dynConfig)
                fct_setIsFirstFetchSuccess(true)
            } else {
                fct_setIsFirstFetchSuccess(false)
                fct_setErrorMsg(queryData.errorMsg)
            }
        }

        if (b_hasQuery) {
            func()
        } else {
            const dynConfig = genFormData(str_queryURL, null)
            fct_setDynConfig(dynConfig)
            if (fct_setIsFirstFetchSuccess) {
                fct_setIsFirstFetchSuccess(true)
            }
        }
    }, [])
}