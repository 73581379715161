import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DataStateCheckWrapper from '../general/DataStateCheckWrapper';
import DiscussionHeader from './DiscussionHeader';
import DiscussionNewPost from './DiscussionNewPost';
import DiscussionContent from './DiscussionContent';
import Message from '../messages/Message';
import MessagesContextProvider from '../messages/context-provider/MessagesContextProvider';
import MessageContextProvider from '../messages/context-provider/MessageContextProvider';
import PanelPost from '../post-panels/PanelPost';

import {
    useQueriedDataContext,
    useIsFirstFetchSuccessContext
} from '../context-provider/DynamicContentLoadContextProvider';
import useGetDiscussionCacheOrDBdata from '../../custom-hooks/useGetDiscussionCacheOrDBdata';
import AuthService from '../../util/authentication/auth_service';

const Discussion = ({
    /* Post panel. */
    obj_postPanelFormConfig,          /* Config of the post panel to create/edit messages. */
    obj_postPanelDynConfig,           /* Dynamic config of the post panel. */
    str_newBtnTitle='Post',
    arr_indicators=['all'],
    comp_inputForm,                   /* If other form than the std. message form should be used. */
    /* Discussion topic message. */
    str_discussMsgId,
    str_discussMsgUrl,                /* Url to get the data for the discussion message from .*/
    str_discussMsgVoteBaseUrl,        /* Base url to post votes of the discussion message to. */
    comp_discussMsg,                  /* Message component if the std <Message /> should not be used. */
    b_hasDiscussMsgTextMarking=false, 
    /* Discussion messages. */
    str_msgCommentBaseUrl='',         /* If message is commentable, base url to create a comment message. */
    str_msgEditBaseUrl='',            /* If message is editable, base url to create an edit message. */
    str_msgVoteBaseUrl,               /* Url to post votes of messages to. */
    b_isMsgBodyHideable=true,
    b_isMsgCommentable=true,
    b_isMsgEditable=true,
    b_hasMsgTextMarking=true,
    comp_msg,                         /* Message component for the discussion if <Message /> should not be used. */
    b_hasDarkDesign=false,
    b_areMsgsDiscussionMsgs=false     /* true if the msgs are discussion msgs (e.g. for the exam question variants page). */
}) => {

    const [discussionMsg, setDiscussionMsg] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    /* Dyn. content load variables. */
    const msgs = useQueriedDataContext()
    const isFirstMsgsFetchSuccessRef = useIsFirstFetchSuccessContext()

    const user = AuthService.getUser()
    
    /* Query data for discussion content message. */
    useGetDiscussionCacheOrDBdata(
        str_discussMsgId,
        str_discussMsgUrl,
        setDiscussionMsg,
        setIsFirstFetchSuccess,
        setErrorMsg,
        true
    )

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={!!str_discussMsgUrl}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            str_errorMsg={errorMsg}
            firstQueryDataEntry={str_discussMsgUrl ? discussionMsg : -1} /* -1 is only a placeholder. */
            b_isContentSection={true}
            b_hasContentSectionDarkDesign={b_hasDarkDesign}
        >
            {
                discussionMsg &&
                <DiscussionHeader b_hasDarkDesign={b_hasDarkDesign}>
                    {
                        <MessagesContextProvider
                            str_voteBaseUrl={str_discussMsgVoteBaseUrl}
                            b_hasDarkDesign={b_hasDarkDesign}
                            b_isDiscussionMsg={true}
                        >
                            <MessageContextProvider
                                obj_msg={discussionMsg}
                                b_isOwner={user.username === discussionMsg.username}
                            >
                                {
                                    /* Use other msg components on demand as input prop. */
                                    comp_discussMsg
                                    ?
                                    comp_discussMsg
                                    :
                                    <Message b_hasTextMarking={b_hasDiscussMsgTextMarking} />
                                }
                            </MessageContextProvider>
                        </MessagesContextProvider>
                    }
                </DiscussionHeader>
            }

            <DiscussionNewPost
                str_newBtnTitle={str_newBtnTitle}
                b_hasDarkDesign={b_hasDarkDesign}
            >
                <PanelPost
                    obj_staticConfig={obj_postPanelFormConfig}
                    obj_dynConfig={obj_postPanelDynConfig}
                    arr_indicators={arr_indicators}
                    str_firstName={user.firstName}
                    str_lastName={user.lastName}
                    str_username={user.username}
                    b_hasDarkDesign={b_hasDarkDesign}
                    comp_inputForm={comp_inputForm}
                />
            </DiscussionNewPost>

            <DataStateCheckWrapper
                b_hasQueryCheck={true}
                b_isFirstFetchSuccess={isFirstMsgsFetchSuccessRef.current}
                firstQueryDataEntry={msgs[0]}
                b_isContentSection={true}
            >
                <DiscussionContent b_hasDarkDesign={b_hasDarkDesign}>
                    <MessagesContextProvider
                        obj_formConfig={obj_postPanelFormConfig}
                        arr_indicators={arr_indicators}
                        str_commentBaseUrl={str_msgCommentBaseUrl}
                        str_editBaseUrl={str_msgEditBaseUrl}
                        str_voteBaseUrl={str_msgVoteBaseUrl}
                        b_isBodyHideable={b_isMsgBodyHideable}
                        b_isCommentable={b_isMsgCommentable}
                        b_isEditable={b_isMsgEditable}
                        b_hasDarkDesign={b_hasDarkDesign}
                        b_isDiscussionMsg={b_areMsgsDiscussionMsgs}
                    >
                        {
                            msgs.map((msg, index) => (
                                <MessageContextProvider
                                    key={index}
                                    obj_msg={msg}
                                    b_isOwner={user.username === msg.username}
                                    /* Default is closed if hideable. */
                                    b_isMsgBodyOpen={!b_isMsgBodyHideable}
                                >
                                    {
                                        comp_msg
                                        ?
                                        comp_msg
                                        :
                                        <Message b_hasTextMarking={b_hasMsgTextMarking} />
                                    }
                                </MessageContextProvider>
                            ))
                        }
                    </MessagesContextProvider>
                </DiscussionContent>
            </DataStateCheckWrapper>
        </DataStateCheckWrapper>
    )
}

Discussion.propTypes = {
    obj_postPanelFormConfig: PropTypes.object.isRequired,
    obj_postPanelDynConfig: PropTypes.object.isRequired,
    str_newBtnTitle: PropTypes.string,
    str_discussMsgId: PropTypes.string,
    str_discussMsgUrl: PropTypes.string,
    str_discussMsgVoteUrl: PropTypes.string,
    comp_discussMsg: PropTypes.any,
    str_commentBaseUrl: PropTypes.string,
    str_editBaseUrl: PropTypes.string,
    str_msgVoteBaseUrl: PropTypes.string,
    b_isMsgBodyHideable: PropTypes.bool,
    b_isMsgCommentable: PropTypes.bool,
    b_isMsgEditable: PropTypes.bool,
    arr_indicators: PropTypes.array,
    b_hasDarkDesign: PropTypes.bool,
    b_areMsgsDiscussionMsgs: PropTypes.bool
}

export default Discussion
