/**
 * Contains all functions to deal with date, time, and datetime data.
 */

const obj_dateDigitToWord = {
    1: 'Januar',
    2: 'Februar',
    3: 'März',
    4: 'April',
    5: 'Mai',
    6: 'Juni',
    7: 'Juli',
    8: 'August',
    9: 'September',
    10: 'Oktober',
    11: 'November',
    12: 'Dezember'
};

const oneTimeDigitToTwoTimeDigits = (str_s) => {   
    if (str_s.length === 2)
        return str_s
    else
        return '0' + str_s
}

export const weekdayIndex2word = (weekdayIndex) => {
    switch(weekdayIndex) {
        case 0:
            return 'Sonntag';
        case 1:
            return 'Montag';
        case 2:
            return 'Dienstag';
        case 3:
            return 'Mittwoch';
        case 4:
            return 'Donnerstag';
        case 5:
            return 'Freitag';
        case 6:
            return 'Samstag';
        default:
            return '';
    }
}

export const browserDateTime2weekdayWord = (browserDateTime) => {
    /**
     * Converts a browser date time format YYYY.MM.DDTHH:MM:SS
     * into a weekday string (e.g. Monday).
     */
    if (typeof(browserDateTime) !== 'string') return '';
    try {
        const date = new Date(browserDateTime);
        const weekdayIndex = date.getDay();
        const weekdayString = weekdayIndex2word(weekdayIndex);
        return weekdayString;
    } catch {
        return '';
    }
}

export const standardizeDateFormat = (str_dateInput) => {
    /**
     * Takes the user's provided date format as input string and creates 
     * a standardized format out of it. 
     * :Input
     *  str_dateInput: String that represents the user's input format. Formats can be:
     *                  'dd.mm.yyyy', 'dd.m.yyyy', 'd.mm.yyyy', 'd.m.yyyy' or 
     *                  the equivalents with '-'. 
     * :Returns
     *  String of format 'dd.mm.yyyy' or 'yyyy-mm-dd'.
     */
    str_dateInput = str_dateInput.trim();
    if (!str_dateInput) return '';

    let arr_a;
    if (str_dateInput.includes('.')) {
        arr_a = str_dateInput.split('.');
        arr_a[0] = oneTimeDigitToTwoTimeDigits(arr_a[0]);
        arr_a[1] = oneTimeDigitToTwoTimeDigits(arr_a[1]);
        return arr_a.join('.');
    } else {
        arr_a = str_dateInput.split('-');
        arr_a[1] = oneTimeDigitToTwoTimeDigits(arr_a[1]);
        arr_a[2] = oneTimeDigitToTwoTimeDigits(arr_a[2]);
        return arr_a.join('-');
    }
}

export const hhmmss2hhmmTime = (time) => {
    /**
     * Connverts the time format HH:MM:SS to HH:MM.
     * :Input
     *  time (str): 'HH:MM:SS'
     * :Returns
     *  time (str): 'HH:MM' or '' if failure.
     */
    if (typeof(time) !== 'string') return '';

    const parts = time.split(':');
    if (parts.length !== 3) return '';

    return parts[0] + ':' + parts[1];
}

export const standardizeTimeFormat = (str_timeInput) => {
    /**
     * Takes the user's provided time format as input string and creates 
     * the standardized 'HH:MM' time format out of it. 
     * :Input
     *  str_timeInput: String that represents the user's input format. Formats can be:
     *                  'H:MM', 'HH:MM'
     * :Returns
     *  String of format 'HH:MM'. 
     */
    str_timeInput = str_timeInput.trim();
    if (!str_timeInput) return '';
    
    let str_filledTime;
    const karr_timeStrings = str_timeInput.split(':');
    if (karr_timeStrings[0].length === 1) {
        karr_timeStrings[0] = '0' + karr_timeStrings[0];
    }
    str_filledTime = karr_timeStrings.join(':');
    return str_filledTime;
}

export const standardizeDateTimeFormat = (str_dateTime) => {
    /**
     * Takes the datetime user input and formats it according to the set standards. 
     * Input is either 'dd.mm.yyyy HH:MM' or 'yyyy-mm-ddTHH:MM'. 
     * :Input
     *  dom_inputFieldWrapper: HTML DOM <div> that has the class '.in-field', which
     *                         acts as wrapper for all <input>.
     * :Returns
     *  Standardized date time string: dd.mm.yyyy HH:MM or yyyy-mm-ddTHH:MM
     *  depending on which input format is provided. 
     */
    
    str_dateTime = str_dateTime.trim();
    if (!str_dateTime) return '';

    /* Split datetime input into date and time strings. */
    let arr_dateTime;
    if (str_dateTime.indexOf('T') !== -1) {
        arr_dateTime = str_dateTime.split('T');
    } else {
        /* Date and time split by: ' '. */
        arr_dateTime = str_dateTime.split(' ');
    }
    const str_char = str_dateTime.indexOf('-') !== -1 ? 'T' : ' '; 
    const kstr_formattedDate = standardizeDateFormat(arr_dateTime[0]);
    const kstr_formattedTime = standardizeTimeFormat(arr_dateTime[1]);
    return kstr_formattedDate + str_char + kstr_formattedTime;
}

export const german2isoDate = (str_date) => {
    /**
     * :Input
     *  str_date (str): Valid standardized date format (dd.mm.yyyy or yyyy-mm-dd)
     * :Returns
     *  String of format 'yyyy-mm-dd'.
     */
    if (!str_date) return '';

    /* Check if format is already the date picker format. */
    if (str_date.includes('-')) return str_date;
    
    const karr_dateStrings = str_date.split('.');
    const kstr_datePickerFormat = karr_dateStrings[2] + '-' + karr_dateStrings[1] +
    '-' + karr_dateStrings[0];
    return kstr_datePickerFormat;
}

export const browserDateToGermanDateFormat = (str_browserDateFormat) => {
    /**
     * Takes in the browser value date format 'YYYY-MM-DD' and converts
     * it into the german date format 'DD.MM.YYY'.
     */
    if (!str_browserDateFormat) return '';
    const karr_a = str_browserDateFormat.split('-');
    return karr_a[2] + '.' + karr_a[1] + '.' + karr_a[0];
}

export const germanDTF2browserDTF = (str_germanDTF, str_separator='T') => {
    /**
     * Takes in the german datetime format: 'DD.MM.YYYY HH:MM' and 
     * converts it to the browser datetime format: 'YYYY-MM-DDTHH:MM'.
     * :Input
     *  str_german: Datetime format: DD.MM.YYYY HH:MM
     *  str_separator: Charactar to separate the date from time (default is T)
     */
    if (!str_germanDTF) return '';

    /* If browser format is passed, return it. */
    if (str_germanDTF.includes('T')) return str_germanDTF.replace('T', str_separator);

    const arr_a = str_germanDTF.split(' ');
    return german2isoDate(arr_a[0]) + str_separator + arr_a[1];
}

export const browserDTF2germanDTF = (str_browserDT, hasTime=true, hasTimeSeconds=false) => {
    /**
     * Takes in the browser datetime format: 'YYYY-MM-DDTHH:MM' and 
     * converts it to the german datetime format: 'DD.MM.YYYY HH:MM'.
     */
    if (!str_browserDT) return '';

    /* If german format is passed, return it. */
    if (str_browserDT.includes(' ')) return str_browserDT;

    const arr_a = str_browserDT.split('T');
    if (hasTime) return browserDateToGermanDateFormat(arr_a[0]) + ' ' + formatTime(arr_a[1], hasTimeSeconds);
    return browserDateToGermanDateFormat(arr_a[0]);
}

export const formatTime = (time, hasSeconds=false) => {
    /**
     * 
     */
    /* Get rid of milliseconds. */
    let newFormat = time.split('.')[0];
    if (!hasSeconds) {
        const splitArr = newFormat.split(':');
        newFormat = splitArr[0] + ':' + splitArr[1];
    }
    return newFormat;
}

export const dateFromTodayAsBrowserString = (nbr_addDays=0) => {
    /**
     * Creates a date in the browser string format counting from today.
     * :Input
     *  nbr_addDays: Number of days to add/subtract to/from the current Date.
     * :Returns
     *  Date (str) in the format: yyyy-mm-dd
     */
    if (typeof(nbr_addDays) !== 'number') return '';

    const date = new Date();
    date.setDate(date.getDate() + nbr_addDays);
    return date.toISOString().split('T')[0];
}

export const addDays2BrowserDateString = (str_date, nbr_addDays) => {
    /**
     * Adds nbr_addDays to str_date and returns the result as browser string.
     * :Input
     *  str_date: Date in browser format 'yyyy-mm-dd'.
     * :Returns
     *  date (str): Date in browser format with added nbr_addDays 'yyyy-mm-dd'. 
     */
    if (typeof(str_date) !== 'string' || typeof(nbr_addDays) !== 'number') return '';
    
    const nbr_date = Date.parse(str_date);
    if (isNaN(nbr_date)) return '';
    
    const date_date = new Date(nbr_date);
    date_date.setDate(date_date.getDate() + Math.floor(nbr_addDays));
    return date_date.toISOString().split('T')[0];
}

export const german2wordMonthDateFormat = (str_date) => {
    /**
     * Converts a german date format into one with the month as word.
     * :Input
     *  str_date: Date with format dd.mm.yyyy.
     * :Returns
     *  Date (str) in the format dd 'month' yyyy.
     */
    if (typeof(str_date) !== 'string') return str_date;

    const regex = /^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/;
    if (!regex.test(str_date)) return str_date;

    const arr_values = str_date.split('.');
    const str_monthDigit = arr_values[1];
    const str_monthWord = obj_dateDigitToWord[parseInt(str_monthDigit)];
    const str_s = arr_values[0] + '. ' + str_monthWord + ' ' + arr_values[2];
    return str_s;
}

const getDeltaTimeInMS = (maxDeltaTimeUnits, unit) => {
    /**
     * Converts a time delta in [ms] into the desired 'unit'.
     * :Input
     *  maxDeltaTimeUnits (nbr)
     *  unit (str): One of One of 's', 'min', 'h', 'd'
     */
    switch (unit) {
        case 'd':
            return maxDeltaTimeUnits * 24 * 60 * 60 * 1000;
        case 'h':
            return maxDeltaTimeUnits * 60 * 60 * 1000;
        case 'min':        
            return maxDeltaTimeUnits * 60 * 1000;
        case 's':
            return maxDeltaTimeUnits * 1000;    
        case 'ms':
            return maxDeltaTimeUnits;
        default:
            return null;
    }
}

export const hasTimestampExpired = (timestamp, maxDeltaTimeUnits, unit) => {
    /**
     * :Input
     *  timestamp (nbr): Value that is created by new Date().getTime() [ms]
     *  deltaTime (nbr): A positive number.
     *  unit (str): One of 's', 'min', 'h', 'd'
     */
    if (
        typeof(timestamp) !== 'number' ||
        timestamp < 0 ||
        typeof(maxDeltaTimeUnits) !== 'number' ||
        maxDeltaTimeUnits < 0
    ) return null;
            
    const maxDeltaTimeMS = getDeltaTimeInMS(maxDeltaTimeUnits, unit);
    if (maxDeltaTimeMS === null) return null;

    const now = new Date().getTime();
    const deltaTime = now - timestamp; /* [ms] */
    return deltaTime > maxDeltaTimeMS;
}

export const getWeekdayIndexEurope = (inDate) => {
    /**
     * Converts a string date into the European weedkay index (Monday = 0).
     * :Input
     *  inDate (str or date): Date representing a valid JS date.
     * :Returns
     *  Weekday index (nbr): 0 - 6.
     */
    try {
        const date = typeof(inDate) === 'string' ? new Date(inDate) : inDate;
        const weekdayIndexAmerican = date.getDay();
        const weekdayIndexEurope = (weekdayIndexAmerican + 6) % 7;
        return weekdayIndexEurope;
    } catch {
        return null;
    }
}