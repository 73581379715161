import React, { useState, useEffect, useRef, useContext } from 'react';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';

import CouponSerializer from '../../../util/serializers/backend_serializers/coupon_serializers';
import { deleteDBdata, fetchDBdata, fetchLSdata, sendData2db, setLSdata } from '../../../util/db_ls_query_handler';
import { hasTimestampExpired } from '../../../util/date_time_handler';
import { PPLsKeys, TimestampLsKey, AppStoreLsKeys } from '../../../util/LocalStorageVariables';

const CouponsThisSemesterContext = React.createContext();
const CouponsNextSemesterContext = React.createContext();
const CouponsOldContext = React.createContext();
const CouponBudgetContext = React.createContext();
const AddCouponContext = React.createContext();
const DeleteOldContext = React.createContext();
const IncreaseNumAvailableOfCoupon = React.createContext();

export function useCouponsThisSemesterContext() {
    return useContext(CouponsThisSemesterContext);
}

export function useCouponsNextSemesterContext() {
    return useContext(CouponsNextSemesterContext);
}

export function useCouponsOldContext() {
    return useContext(CouponsOldContext);
}

export function useCouponBudgetContext() {
    return useContext(CouponBudgetContext);
}

export function useAddCouponContext() {
    return useContext(AddCouponContext);
}

export function useDeleteOldContext() {
    return useContext(DeleteOldContext);
}

export function useIncreaseNumAvailableOfCoupon() {
    return useContext(IncreaseNumAvailableOfCoupon);
}

/* LS keys. */
const PR_KEY = PPLsKeys.primaryDataKey;
const SEC_KEY = AppStoreLsKeys.coupons.base;
const CURRENT_SEM_KEY = AppStoreLsKeys.coupons.currentSemester;
const NEXT_SEM_KEY = AppStoreLsKeys.coupons.nextSemester;
const OLD_KEY = AppStoreLsKeys.coupons.old;
const TIMESTAMP_KEY = TimestampLsKey;
const COUPON_BUDGET_KEY = AppStoreLsKeys.coupons.couponBudget;

const DETAIL_BASE_URL = '/api/store/gastronomer/coupons/detail/';
const LIST_URL = '/api/store/gastronomer/coupons/list/';

const dbErrorMsg = 'Der Gutschein konnte nicht offline genommen werden. Der Server konnte die Daten ' +
    'nicht verarbeiten. Bitte Lade die Seite neu und versuche es noch einmal. Sollte es erneut nicht klappen, ' +
    'probiere es bitte später wieder.';
const deleteConfirmMsg = 'Möchtest du diesen Gutschein wirklich unwiderruflich löschen?';

const genConfirmMsg = (num) => {
    const baseMsg = 'Möchtest du von diesem Gutschein wirklich weitere Exemplare erstellen?\n';
    if (num === 1) {
        return baseMsg + `Es wird ${num} weiteres Exemplar erstellt.`;
    } else {
        return baseMsg + `Es werden ${num} weitere Exemplare erstellt.`;
    }
}

const PPCouponsContextProvider = ({ children }) => {
    
    const [couponsCurrentSem, setCouponsCurrentSem] = useState()
    const [couponsNextSem, setCouponsNextSem] = useState()
    const [couponsOld, setCouponsOld] = useState()
    const [couponBudget, setCouponBudget] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState(undefined)
    const [errorMsg, setErrorMsg] = useState('')
    const isButtonLockedRef = useRef(false)

    useEffect(() => {
        const makeDBrequest = async () => {
            const queryData = await fetchDBdata(LIST_URL)
            if (queryData.isQuerySuccess) {
                const data = queryData.response.data

                let serializedData = CouponSerializer.dbList2couponCardsArray(data.currentSemester)
                setCouponsCurrentSem(serializedData)
                setLSdata(serializedData, PR_KEY, SEC_KEY, CURRENT_SEM_KEY)

                serializedData = CouponSerializer.dbList2couponCardsArray(data.nextSemester)
                setCouponsNextSem(serializedData)
                setLSdata(serializedData, PR_KEY, SEC_KEY, NEXT_SEM_KEY)

                serializedData = CouponSerializer.dbList2couponCardsArray(data.old)
                setCouponsOld(serializedData)
                setLSdata(serializedData, PR_KEY, SEC_KEY, OLD_KEY)

                setCouponBudget(data.couponBudget)

                setIsFirstFetchSuccess(true)
                setLSdata(data.couponBudget, PR_KEY, SEC_KEY, COUPON_BUDGET_KEY)

                /* Set timestamp for query time. */
                const timestamp  = new Date().getTime()
                setLSdata(timestamp, PR_KEY, SEC_KEY, TIMESTAMP_KEY)
            } else {
                setErrorMsg(queryData.errorMsg)
                setIsFirstFetchSuccess(false)
            }
        }

        const makeLSrequest = () => {
            const lsData = fetchLSdata(PR_KEY, SEC_KEY)
            setCouponsCurrentSem(lsData.currentSemester)
            setCouponsNextSem(lsData.nextSemester)
            setCouponsOld(lsData.old)
            setCouponBudget(lsData.couponBudget)
            setIsFirstFetchSuccess(true)
        }

        /* Fetch data from LS or DB depending on timestamp expiration. */
        const timestamp = fetchLSdata(PR_KEY, SEC_KEY, TIMESTAMP_KEY);
        if (timestamp && !hasTimestampExpired(timestamp, 10, 'min')) {
            makeLSrequest()
        } else {
            makeDBrequest()
        }
    }, [])

    const addCoupon = (response) => {
        /**
         * Add new coupon to state and LS.
         * :Input
         *  response: Response received from the DB query function.
         */
        const couponDBdata = response.data
        if (couponDBdata.isCurrentSemester) {
            const couponCardData = [CouponSerializer.dbJson2couponCard(couponDBdata), ...couponsCurrentSem]
            setCouponsCurrentSem(couponCardData)
            setLSdata(couponCardData, PR_KEY, SEC_KEY, CURRENT_SEM_KEY)
        } else {
            const couponCardData = [CouponSerializer.dbJson2couponCard(couponDBdata), ...couponsNextSem]
            setCouponsNextSem(couponCardData)
            setLSdata(couponCardData, PR_KEY, SEC_KEY, NEXT_SEM_KEY)
        }
    }

    const deleteOldCoupons = async (nbr_couponId, cardRef) => {
        /**
         * Makes a DB request to delete an old coupon from the DB.
         * If DB query is success, react states and LS are updated.
         */
        if (isButtonLockedRef.current) return
        isButtonLockedRef.current = true

        if (!window.confirm(deleteConfirmMsg)) {
            isButtonLockedRef.current = false
            return
        }

        const queryData = await deleteDBdata(DETAIL_BASE_URL + `${nbr_couponId}/`)
        if (!queryData.isQuerySuccess) {
            alert(dbErrorMsg)
            isButtonLockedRef.current = false
            return
        }

        /* Run animation, then delete old offer from react state and LS. */
        runCardBlurAnimation(cardRef)
        setTimeout(() => {
            const newOld = couponsOld.filter(coupon => coupon.id !== nbr_couponId)
            setCouponsOld(newOld)
            setLSdata(newOld, PR_KEY, SEC_KEY, OLD_KEY)
            isButtonLockedRef.current = false
        }, 900)
    }

    const increaseNumAvailableOfCoupon = async (nbr_couponId, nbr_numIncrease) => {
        /**
         * Increases the number of available coupons of a specific coupon.
         * If DB update is successful, the state and LS are updated as well.
         */
        if (isButtonLockedRef.current) return
        isButtonLockedRef.current = true

        if (!window.confirm(genConfirmMsg(nbr_numIncrease))) {
            isButtonLockedRef.current = false
            return
        }

        const url = DETAIL_BASE_URL + `${nbr_couponId}/`
        const payload = { numIncrease: nbr_numIncrease }
        const queryData = await sendData2db('put', url, payload)
        if (!queryData.isQuerySuccess) {
            alert(dbErrorMsg)
            isButtonLockedRef.current = false
            return
        }

        /* Update coupon in state and LS. */

        let newCoupons = []
        const isCurrentSemester = queryData.response.data.isCurrentSemester
        const [coupons, setFct] = isCurrentSemester ?
                                  [couponsCurrentSem, setCouponsCurrentSem] :
                                  [couponsNextSem, setCouponsNextSem]
        /* Increase the numAvailable of the concerned coupon. */                
        coupons.forEach((coupon) => {
            if (coupon.id === nbr_couponId) {
                coupon.footer.numAvailable += nbr_numIncrease
                newCoupons.push(coupon)
            } else {
                newCoupons.push(coupon)
            }
        })
        setFct(newCoupons)
        const lsKey = isCurrentSemester ? CURRENT_SEM_KEY : NEXT_SEM_KEY
        setLSdata(newCoupons, PR_KEY, SEC_KEY, lsKey)
        isButtonLockedRef.current = false
    }

    const runCardBlurAnimation = (cardRef) => {
        cardRef.current.style.animation = 'blur 1s'
    }
    
    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            firstQueryDataEntry={-1} /* Placeholder to avoid 'no data' rendering (details handled by children). */
            str_errorMsg={errorMsg}
            b_isContentSection={true}
        >
            <CouponsThisSemesterContext.Provider value={couponsCurrentSem}>
                <CouponsNextSemesterContext.Provider value={couponsNextSem}>
                    <CouponsOldContext.Provider value={couponsOld}>
                        <CouponBudgetContext.Provider value={couponBudget}>
                            <AddCouponContext.Provider value={addCoupon}>
                                <DeleteOldContext.Provider value={deleteOldCoupons}>
                                    <IncreaseNumAvailableOfCoupon.Provider value={increaseNumAvailableOfCoupon}>
                                        {children}
                                    </IncreaseNumAvailableOfCoupon.Provider>
                                </DeleteOldContext.Provider>
                            </AddCouponContext.Provider>
                        </CouponBudgetContext.Provider>
                    </CouponsOldContext.Provider>
                </CouponsNextSemesterContext.Provider>
            </CouponsThisSemesterContext.Provider>
        </DataStateCheckWrapper>
    )
}

export default PPCouponsContextProvider
