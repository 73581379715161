import React from 'react';
import PropTypes from 'prop-types';

import CardPriceTag from '../CardPriceTag';
import CardFooterRedeemButton from '../CardFooterRedeemButton';

const BASE_REDEEM_URL = '/api/gastronomy/food-offers/detail/';

const CardFoodFooterRedeem = ({
    nbr_id,
    nbr_price,
    b_isToday,    /* Is date today? Identifies if the item can be redeemed today. */
    b_isRedeemed, /* Is the reserved food offer redeemed? */
    b_isRedeemSuccess,
    str_redeemTimestamp,
    str_redeemUserFullName,
    str_redeemCheckCode,
    b_hasModalView=false,
    fct_handleRedeem,
}) => {
    const queryUrl = BASE_REDEEM_URL + nbr_id + '/'
    return (
        <div className="card-footer-offer">
            <CardPriceTag nbr_price={nbr_price} />
            <CardFooterRedeemButton
                nbr_id={nbr_id}
                b_isToday={b_isToday}
                b_isRedeemed={b_isRedeemed}
                b_isRedeemSuccess={b_isRedeemSuccess}
                str_redeemTimestamp={str_redeemTimestamp}
                str_redeemUserFullName={str_redeemUserFullName}
                str_redeemCheckCode={str_redeemCheckCode}
                str_queryUrl={queryUrl}
                fct_handleRedeem={fct_handleRedeem}
                b_hasModalView={b_hasModalView}
                b_hasOnlyOneCoupon={true}
            />
        </div>
    )
}

CardFoodFooterRedeem.propTypes = {
    nbr_id: PropTypes.number.isRequired,
    nbr_price: PropTypes.number.isRequired,
    b_isToday: PropTypes.bool.isRequired,
    b_isRedeemed: PropTypes.bool,
    b_isRedeemSuccess: PropTypes.bool,
    str_redeemTimestamp: PropTypes.string,
    str_redeemUserFullName: PropTypes.string,
    str_redeemCheckCode: PropTypes.string,
    fct_handleRedeem: PropTypes.func,
    b_hasModalView: PropTypes.bool
}

export default CardFoodFooterRedeem
