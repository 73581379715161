import React from 'react';

import Accordion from '../../components/accordion/Accordion';
import AccordionHeader from '../../components/accordion/AccordionHeader';
import AccordionParagraph from '../../components/accordion/AccordionParagraph';
// import AccordionList from '../../components/accordion/AccordionList';
// import AccordionListItem from '../../components/accordion/AccordionListItem';

const GastronomerUserguide = () => {
    return (
        <>
            <Accordion str_title='Abonnement'>
                <AccordionParagraph>
                    Das erste Abonnement eines Benutzers aus der Benutzergruppe „Gastronomie“ wird durch die Unterzeichnung des Registrierungsvertrages für Gastronomiepartner abgeschlossen. Dieses Abonnement ist bis zum Ende des laufenden Semesters gültig. Danach verlängert sich das Abonnement automatisch um jeweils ein weiteres Semester, wenn es nicht unter Einhaltung einer Frist von 30 Tagen vor seinem Ablauf gekündigt wird. Abonnements werden immer bis zum nächsten Semesterende verlängert.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Inhalte'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Welche Inhalte kann ich auf MUPLAZA posten?
                </AccordionHeader>
                <AccordionParagraph>
                    Auf MUPLAZA können allgemeine Informationen über deinen Betrieb, Getränke- und Speisekarten, Beschreibungen, Events, Speiseangebote und Kontaktdaten gepostet werden.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie viel Freiheit habe ich bei der Erstellung meiner Inhalte?
                </AccordionHeader>
                <AccordionParagraph>
                    Grundsätzlich steht es jedem Gastronom frei welche Informationen er auf der Plattform stellt. Einschränkungen in Art und Anzahl bestimmter Posts können in dieser Nutzeranleitung und auf der Plattform gefunden werden. Weiters dürfen die Posts nicht gegen die Nutzungsbedingungen oder sonstige vertragliche Vereinbarungen verstoßen.
                </AccordionParagraph>

                <AccordionHeader>
                    Wer sieht meine Inhalte?
                </AccordionHeader>
                <AccordionParagraph>
                    Alle auf der Plattform angemeldeten Studierenden der Montanuniversität Leoben. Es ist also möglich mehr als 4000 Studierende auf der Plattform zu erreichen.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Angebote'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Was kann angeboten werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Alle Produkte und Dienstleistungen, die dein Betrieb Kunden anbietet, können auf MUPLAZA angeboten werden. Es können auch spezielle Produkte und Dienstleistungen für Studierende angeboten werden, die regulären Kunden nicht zur Verfügung stehen.
                </AccordionParagraph>

                <AccordionHeader>
                    Für welche Tage kann ein Angebot erstellt werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Ein Angebot kann an jedem Tag der laufenden Woche und der folgenden Woche erstellt werden.
                </AccordionParagraph>
                
                <AccordionHeader>
                    Wie viele Angebote kann ich gleichzeitig online stellen?
                </AccordionHeader>
                <AccordionParagraph>
                    Es können maximal 10 Angebote von einem Gastronomiebetrieb gleichzeitig online sein. Diese Anzahl beinhaltet die Angebote der laufenden und der kommenden Woche. Es ist also möglich, an jedem Werktag der laufenden und der kommenden Woche ein Angebot zu erstellen.
                </AccordionParagraph>

                <AccordionHeader>
                    Was passiert, wenn die maximale Anzahl an geplanten Angeboten erreicht ist?
                </AccordionHeader>
                <AccordionParagraph>
                    An diesem Punkt können keine Angebote mehr erstellt werden. Angebote können nur erstellt werden, wenn die Anzahl der verfügbaren Angebote für diese und die nächste Woche unter der maximalen Anzahl von 10 Angeboten liegt.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie viele alte Angebote können für mein Benutzerkonto gespeichert werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Es können maximal 20 alte Angebote gespeichert werden.
                </AccordionParagraph>

                <AccordionHeader>
                    Was passiert wenn die maximale Anzahl an alten Angeboten erreicht ist?
                </AccordionHeader>
                <AccordionParagraph>
                    Es ist nicht möglich, ein Angebot zu erstellen, wenn die maximale Anzahl alter Angebote erreicht oder überschritten ist. In diesem Fall müssen die alten Angebote gelöscht werden, bis das Limit der alten Angebote wieder unterschritten ist.
                </AccordionParagraph>
                <AccordionParagraph>
                    Beispiel: Wenn 18/20 alte Angebotsplätze belegt sind, können noch 2 Angebote erstellt werden. Wenn 19/20 alte Angebotsplätze belegt sind, kann noch ein Angebot erstellt werden. Wenn 20/20 Plätze belegt sind, muss mindestens ein altes Angebot gelöscht werden, um ein neues Angebot erstellen zu können.
                </AccordionParagraph>

                <AccordionHeader>
                    Kann ein altes Angebot wiederverwendet werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja, du kannst dies tun, indem du in der Seitenleiste auf „Angebote“ und dann auf „Alt“ klickst. Jedes Angebot hat eine Schaltfläche mit einem Pfeilsymbol (ähnlich dem Recycling-Symbol). Wenn du darauf klickst, kommst du auf die Seite, auf der du das Angebot erstellen kannst. Die meisten Felder sind dann bereits ausgefüllt.
                </AccordionParagraph>

                <AccordionHeader>
                    Ist es möglich ein laufendes Angebot zu erstellen?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja, es ist möglich, ein Angebot zu erstellen, das in regelmäßigen Zeitabständen automatisch wieder online gestellt wird. Die Zeitabstände für diese laufenden Angebote sind 1 (täglich), 7, 14, 21 und 28 Tage.
                </AccordionParagraph>

                <AccordionHeader>
                    Was passiert, wenn ein laufendes Angebot von mir gestoppt wird?
                </AccordionHeader>
                <AccordionParagraph>
                    Ein laufendes Angebot kann jederzeit im Benutzerkonto gestoppt werden. Dabei ist zu beachten, dass alle für die Zukunft bereits geplanten Angebote weiterhin geschaltet bleiben. Ein Angebot wird immer an dem Tag für die Zukunft geplant, an dem es verfügbar ist. Ein Beispiel: Wenn du ein Angebot, das am Montag verfügbar ist, an diesem Montag stoppst, dann wird es noch einmal an einem Montag auf der Plattform angezeigt. An welchem Montag es genau nochmals angezeigt wird, hängt von der eingestellten Anzahl der Tage zwischen den Angeboten ab. Wenn du es am Sonntag davor stoppst, dann wird es nur noch am darauffolgenden Montag, d.h. am nächsten Tag, angezeigt.
                </AccordionParagraph>
                <AccordionParagraph>
                    Der Grund für dieses System liegt in der Anzeige der Angebote. Auf der Plattform können Angebote der laufenden Woche und der kommenden Woche eingesehen werden. Wäre es möglich, ein Angebot zu stoppen und damit alle bereits geplanten Angebote zu löschen, so wären einige Angebote zunächst sichtbar, würden aber nach dem Stoppen des Angebots von der Plattform verschwinden. Wenn sich ein Studierender nun auf ein Angebot der nächsten Woche freut und dieses dann plötzlich verschwindet, könnte dies zu Verwirrung unter den Benutzern führen.
                </AccordionParagraph>

                <AccordionHeader>
                    Kann die Anzahl eines Angebots limitiert werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja, es ist möglich die Anzahl eines Angebots zu limitieren. Beim Erstellen eines Angebots wird in Form eines Eingabefelds nach einer maximalen Anzahl für dieses Angebot gefragt. Die hier eingegebene Zahl repräsentiert die Stückzahl dieses Angebots. Wenn das Feld frei gelassen wird, dann ist das Angebot unlimitiert verfügbar. Limitierte Angebote können nur von Studierenden, die das Angebot reserviert haben, in Anspruch genommen werden.
                </AccordionParagraph>
                <AccordionParagraph>
                    Limitierte Angebote werden von den Studierenden online auf der Plattform reserviert. Nach der Reservierung steht dem Studierenden das Angebot in seinem Nutzerkonto zur Verfügung. Der Studierende kann das Angebot nur am Angebotstag einlösen.
                </AccordionParagraph>
                <AccordionParagraph>
                    Das Einlösen erfolgt nach demselben Prinzip wie das Einlösen der Gutscheine. Der Studierende zeigt den Einlösebildschirm des Gutscheins mit einem gültigen Studierendenausweis im Betrieb bei der Bestellung vor. Danach kann das Angebot vom Studierenden in Anspruch genommen werden.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie kann ein reserviertes Angebot eingelöst werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Ein Gastronomiepartner erhält täglich eine E-Mail mit den Codes, mit denen ein Studierender ein reserviertes Angebot einlösen kann. Die E-Mail enthält 2 Codes (den Einlösecode und den Prüfcode). Der Einlösecode wird dem Studierenden vom Personal nur dann mitgeteilt, wenn er das reservierte Angebot auf einem elektronischen Gerät vorweisen kann. Der Einlösecode ist also nur dem Personal des Verpflegungspartners und den Studierenden bekannt, die an diesem Tag über ein reserviertes Angebot verfügen. Der Studierende gibt den Einlösecode selbständig ein und erhält umgehend eine Bestätigung auf sein Gerät, ob die Einlösung erfolgreich war.
                </AccordionParagraph>
                <AccordionParagraph>
                    Der Studierende muss dann dem Personal den Einlösebildschirm zeigen. Das Personal überprüft die erfolgreiche Einlösung in 3 Schritten. 1) Vergleich des vollständigen Namens des Studierenden auf dem elektronischen Gutschein mit dem Studierendenausweis. Nur wenn der Name übereinstimmt, wird der Gutschein ausgegeben. 2) Kontrolle von Datum und Uhrzeit der Einlösung. Der angegebene Zeitpunkt (Datum und Uhrzeit) darf nicht mehr als 1 Minute von der aktuellen Uhrzeit abweichen. 3) Überprüfung des elektronischen Prüfcodes. Der auf dem digitalen Gutschein angezeigte Prüfcode muss mit dem täglich per E-Mail zugesandten Prüfcode übereinstimmen. Nur bei erfolgreicher Kontrolle aller 3 Schritte wird das Angebot an den Studierenden ausgegeben. Der Prüfcode ist ausschließlich für den Gastronomiepartner bestimmt und darf nicht an Personen weitergegeben werden, die nicht im Betrieb beschäftigt sind.
                </AccordionParagraph>
                <AccordionParagraph>
                    Die Kontrolle mittels Einlöse- und Prüfcode dient dazu, dass die Studierenden die Angebote nicht außerhalb des Betriebs des Gastronomiepartners einlösen können. Dies verhindert einerseits ein unbeabsichtigtes Einlösen, dient aber auch dazu, die Studierenden zu einer gewissenhaften Reservierung und Einlösung der Angebote anzuhalten. Bei mehrmaligem Nichteinlösen der Angebote erfolgt eine temporäre Sperrung des Benutzerkontos des Studierenden.
                </AccordionParagraph>

                <AccordionHeader>
                    Wer kann meine Angebote sehen?
                </AccordionHeader>
                <AccordionParagraph>
                    Alle Benutzer der Benutzergruppen "Gastronomie" und "Studierende".
                </AccordionParagraph>

                <AccordionHeader>
                    Wer erhält die Angebotspreise?
                </AccordionHeader>
                <AccordionParagraph>
                    Nur Studierende der Montanuniversität Leoben mit einem gültigen Studierendenausweis und einem gültigen MUPLAZA Pro-Abo. Der Studierendenausweis und das Pro-Abo müssen dem Personal vor der Bestellung gezeigt werden. Das Pro-Abo wird durch ein „P“ Symbol (eingekreistes „P“) neben dem Namen des Nutzers auf der Profilseite des Studierenden gekennzeichnet.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='DoG4S'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Was ist DoG4S?
                </AccordionHeader>
                <AccordionParagraph>
                    DoG4S ist eine Abkürzung für „Do Good for Students“, was übersetzt „Gutes für Studenten tun“ bedeutet. Es wird DoGAS ausgesprochen. DoG4S ist ein Konzept, das seinen Ursprung im finanziell limitierten Budget von Studierenden und der leider weit verbreiteten Lebensmittelverschwendung hat. Es ermöglicht Gastronomen Speisen und Getränke, die kurz vor Ladenschluss oder aufgrund von mangelnder optischer Qualität nicht mehr verkauft werden können, den Studierenden zu einem reduzierten Preis zur Verfügung zu stellen.
                </AccordionParagraph>

                <AccordionHeader>
                    Wann kann ein DoG4S Angebot erstellt werden?
                </AccordionHeader>
                <AccordionParagraph>
                    DoG4S-Angebote können für heute und morgen erstellt werden.
                </AccordionParagraph>

                <AccordionHeader>
                    Kann ein DoG4S Angebot als laufendes Angebot erstellt werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Nein.
                </AccordionParagraph>

                <AccordionHeader>
                    Wer kann meine DoG4S Angebote sehen?
                </AccordionHeader>
                <AccordionParagraph>
                    Alle Benutzer der Benutzergruppen "Gastronomie" und "Studierende".
                </AccordionParagraph>

                <AccordionHeader>
                    Wie kann ein DoG4S Angebote eingelöst werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Siehe hierfür die Antwort auf 
                    <span className='text-italic'> „Wie kann ein reserviertes Angebot eingelöst werden?“</span>. 
                    Der Vorgang für DoG4S-Angebote funktioniert analog und mit denselben Einlöse- und Prüfcodes.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Gutscheine'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Was sind MCs?
                </AccordionHeader>
                <AccordionParagraph>
                    MC steht für Montan Coin und ist die Währung auf MUPLAZA. Alle Preise auf MUPLAZA werden in MC angegeben. Der Umrechnungsfaktor von MC zu € ist 1:1, d.h. 1 € entspricht genau 1 MC.
                </AccordionParagraph>

                <AccordionHeader>
                    Können von einem bereits existierenden Gutschein mehr Exemplare erstellt werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja, die Erstellung von weiteren Exemplaren wird direkt auf dem Gutschein selbst angeboten. Wähle dazu in der Seitenleiste „Gutscheine“ aus. Auf den angezeigten Gutscheinen befinden sich Buttons mit den Werten +1, +5 und +10. Durch das Klicken dieser Buttons und das Bestätigen der darauffolgenden Frage, ob du mit deiner Auswahl einverstanden bist, werden weitere Exemplare dieses Gutscheins kreiert. Die Zahl im Button steht für die Anzahl der zusätzlich erstellten Exemplare. Beispiel: Durch Anklicken des +1 Buttons wird ein weiteres Exemplar dieses Gutscheins erstellt.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie funktioniert die Gutscheinerstellung?
                </AccordionHeader>
                <AccordionParagraph>
                    Um einen neuen Gutschein zu erstellen, wähle die Rubrik „Gutschein“ in der Seitenleiste und klicke dann auf den Button mit dem Titel „Neu“. Daraufhin erscheint die Eingabemaske zur Erstellung des Gutscheins.
                </AccordionParagraph>

                <AccordionHeader>
                    Kann eine höhere Gutscheinsumme zur Verfügung gestellt werden, als vertraglich vereinbart wurde?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja, der vertraglich vereinbarte Mindestwert stellt keine Obergrenze dar.
                </AccordionParagraph>

                <AccordionHeader>
                    Kann ein erstellter Gutschein bearbeitet werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Nein. Ein Gutschein kann nicht geändert werden, da er einen Vertrag zwischen dem Aussteller und dem Käufer darstellt. Wenn ein Gutschein nach dem Kauf in Art und Umfang geändert werden könnte, wüsste man nicht, was man kauft.
                </AccordionParagraph>

                <AccordionHeader>
                    Wer kann meine Gutscheine sehen?
                </AccordionHeader>
                <AccordionParagraph>
                    Alle Benutzer der Benutzergruppen "Gastronomie" und "Studierende". Alle Gutscheine sind im MUPLAZA Store ersichtlich.
                </AccordionParagraph>

                <AccordionHeader>
                    Wo kann eingesehen werden, welcher Gutscheinwert für ein Semester erstellt wurde und wie viele Gutscheine bereits verkauft und eingelöst wurden?
                </AccordionHeader>
                <AccordionParagraph>
                    Du kannst in der Seitenleiste „Gutscheine“ auswählen und dann zu „Dieses Semester“ navigieren. Dort siehst du deine Gutscheinstatistik über die von dir erstellten Gutscheine. Unter dem Menüpunkt „Nächstes Semester“ findest du an gleicher Stelle den Gutscheinwert, den du bereits für das nächste Semester erstellt hast.
                </AccordionParagraph>

                <AccordionHeader>
                    Wo können Studierende meine Gutscheine kaufen?
                </AccordionHeader>
                <AccordionParagraph>
                    Im MUPLAZA Store.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie wird ein Gutschein von einem Studierenden eingelöst?
                </AccordionHeader>
                <AccordionParagraph>
                    Die Studierenden können ihre Gutscheine über ihr Benutzerkonto einlösen. Jeder im MUPLAZA Store gekaufte Gutschein wird dem Benutzerkonto des Studierenden gutgeschrieben.
                </AccordionParagraph>
                <AccordionParagraph>
                    Die Einlösung erfolgt auf ähnliche Weise wie die Einlösung von reservierten Angeboten. Im Vergleich zur Einlösung von reservierten Angeboten wird der Einlösecode jedoch vom Personal am Gerät des Studierenden eingegeben. Der Einlösecode ist somit nur innerhalb des Verpflegungspartners bekannt. Der Einlösecode darf nicht an Studierende weitergegeben werden. Der Einlöse- sowie der Prüfcode werden aus Sicherheitsgründen jeden Monat am Monatsersten per E-Mail neu versandt. Die alten Codes sind dann nicht mehr gültig.
                </AccordionParagraph>
                <AccordionParagraph>
                    Das Personal überprüft die erfolgreiche Einlösung des Gutscheins in 3 Schritten. 1) Vergleich des vollständigen Namens des Studierenden auf dem elektronischen Gutschein mit dem Studierendenausweis. Nur wenn der Name übereinstimmt, wird der Gutschein ausgegeben. 2) Kontrolle von Datum und Uhrzeit der Einlösung. Der angegebene Zeitpunkt (Datum und Uhrzeit) darf nicht mehr als 1 Minute von der aktuellen Uhrzeit abweichen. 3) Überprüfung des elektronischen Prüfcodes. Der auf dem digitalen Gutschein angezeigte Prüfcode muss mit dem aktuellen Prüfcode für Gutscheine übereinstimmen. Nur bei erfolgreicher Kontrolle aller 3 Schritte wird der Gutscheinwert an den Studierenden ausgegeben.
                </AccordionParagraph>
                <AccordionParagraph>
                    Es kann immer nur ein Gutschein nach dem anderen eingelöst werden. Das bedeutet, dass jeder Bildschirm, der einen eingelösten Gutschein anzeigt, genau einen eingelösten Gutschein darstellt.
                </AccordionParagraph>
                <AccordionParagraph>
                    Hinweis: Sollte der Gastronomiepartner zum Zeitpunkt des vom Studierenden geäußerten Einlösewunsches Zweifel an der Durchführung der Einlösung haben, weil der Studierende sich nicht in einem ausreichenden geistigen und/oder körperlichen Zustand befindet, weil er z.B. unter dem Einfluss von Suchtmitteln wie Alkohol steht, kann der Gastronomiepartner die Einlösung ablehnen. Der Gastronomiepartner ist verpflichtet, MUPLAZA e.U. in einem solchen Fall alsbald schriftlich zu informieren. Dies gilt ebenso für das Einlösen der Gastronomie-Angebote.
                </AccordionParagraph>
            </Accordion>
        </>
    )
}

export default GastronomerUserguide
