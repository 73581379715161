/**
 * Queries the data either form the course docs cache or the DB.
 * If it is found in the cache, the DB query is avoided.
 */

import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchDBdata } from '../util/db_ls_query_handler';
import {
    useAddCategoryWithDocsContext,
    useGetDocsOfCategoryContext
} from '../components/context-provider/CourseDocsCacheContextProvider';
import { genDynUrl } from '../util/db_ls_query_handler';

const serializer = (docData) => {
    /**
     * Serializes the data of course documents. Thereby, the data is 
     * split into 2 arrays, which are the values of a JS object. As follows:
     * {
     *      validated  : [ ... ], // Validated docs.
     *      unvalidated: [ ... ]  // Unvalidated docs.
     * }
     * :Input
     *  docData (array): Items are JS objects.
     * :Returns
     *  JS objects with docs data split into validated/unvalidated (see above).
     */
    let res = {
        validated: [],
        unvalidated: []
    };
    
    Object.values(docData).forEach(docItem => {
        if (docItem.isValidated) {
            res.validated.push(docItem);
        } else {
            res.unvalidated.push(docItem);
        }
    });

    return res;
}

const BASE_URL = '/api/coursedocs/list/';

export default function useGetCourseDocsCacheOrDBdata(
    fct_setData,                /* Set the data of the component. */
    fct_setIsFirstFetchSuccess, /* Sets true/false depending on success of query. */
    fct_setErrorMsg,            /* Set error message the query yields upon failure. */
) {

    const params = useParams()
    const navigate = useNavigate()
    const addData2cache = useAddCategoryWithDocsContext()
    const getDataFromCache = useGetDocsOfCategoryContext()

    useEffect(() => {
        const func = async () => {
            /* Fetch data from cache. */
            const data = getDataFromCache()
            if (data) {
                fct_setData(serializer(data))
                fct_setIsFirstFetchSuccess(true)
                return
            }

            /* Fetch data from DB. */
            const url = genDynUrl(genDynUrl(BASE_URL, params.id), params.category)
            const queryData = await fetchDBdata(url)
            if (queryData.isQuerySuccess) {
                addData2cache(params.category, queryData.response.data)
                const serializedData = serializer(queryData.response.data)
                fct_setData(serializedData)
                fct_setIsFirstFetchSuccess(true)
            } else {
                fct_setIsFirstFetchSuccess(false)
                fct_setErrorMsg(queryData.errorMsg)
            }
        }
        func()
    }, [navigate])
}