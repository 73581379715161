import React from 'react';
import PropTypes from 'prop-types';

import FormBase from '../../../input-forms/FormBase';
import InFieldsGroup from '../../../input-forms/InFieldsGroup';
import SingleChoiceField from '../../../input-forms/fields/SingleChoiceField';
import SelectiveProposalField from '../../../input-forms/fields/SelectiveProposalField';
import CourseProposalField from '../../../input-forms/fields/CourseProposalField';

import { useInputData } from '../../../input-forms/context-provider/InputFormContextProvider';
import { FilterFormTagsData as FormData } from '../../../input-forms/util/form-config-data/InputFormFilterDataConfigs';

const FilterFormTags = ({ b_isTransparent=false }) => {
    const data = useInputData()
    return (
        <FormBase
            b_isFilterForm={true}
            b_hasFilterGrid={true}
            b_isTransparent={b_isTransparent}
        >
            <InFieldsGroup b_hasBorder={false}>
                <SelectiveProposalField
                    {...FormData.fields.tags}
                    arr_proposals={data[FormData.fields.tags.str_id][0]}
                />
                <SingleChoiceField
                    {...FormData.fields.categories}
                    str_currentOption={data[FormData.fields.categories.str_id][0]}
                />
                <CourseProposalField
                    str_choiceFieldId={FormData.fields.courseSelection.str_id}
                    str_proposalFieldId={FormData.fields.selectedCourses.str_id}
                    str_currentCourseOption={data[FormData.fields.courseSelection.str_id][0]}
                    arr_currentCourseProposals={data[FormData.fields.selectedCourses.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

FilterFormTags.propTypes = {
    b_isTransparent: PropTypes.bool
}

export default FilterFormTags
