import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import FieldLimits from '../../../input-forms/util/db_field_limits.json';
import { string2URLstring } from '../../../../util/data_handler';
import { useRunAnimationContext } from '../../context-provider/SbAnimationContextProvider';

const genURLidentityString = (str_date, str_category, nbr_numQuestion) => {
    return `${string2URLstring(str_date)}/question/${string2URLstring(str_category)}/${nbr_numQuestion}`;
}

const genDb2frontendCat = () => {
    const categories = FieldLimits.course.task.question.categories;
    const map = {};
    categories.forEach(category => {
        map[category[0]] = category;
    })
    return map;
}

const ACTIVE_ITEM_ID = 'sb-item-active';
const CATEGORY_MAP = genDb2frontendCat();

const SbCourseTasksSubitem = ({
    str_identifier,
    str_date,
    nbr_id,
    obj_item
}) => {
    
    const [isActive, setIsActive] = useState()
    const navigate = useNavigate()
    const params = useParams()

    /* Hook to hide sb when the user clicks an item. */
    const runAnimation = useRunAnimationContext()

    const urlIdentifier = genURLidentityString(
        str_date,
        CATEGORY_MAP[obj_item.category],
        obj_item.number
    )

    useEffect(() => {
        if (obj_item.id === parseInt(params.questionId, 10)) setIsActive(true)
        else setIsActive(false)
    }, [navigate])

    return (
        <Link to={`${str_identifier}/${nbr_id}/${urlIdentifier}/${obj_item.id}/discussion`}>
            <li
                id={`${isActive ? ACTIVE_ITEM_ID : ''}`}
                className='sb-sub-list-item'
                onClick={() => runAnimation()}
            >
                <div className='item-wrap'>
                    <div className='num-question'>
                        {obj_item.isExerciseTestQuestion ? 'T' : ''}{obj_item.number}. {CATEGORY_MAP[obj_item.category]}
                    </div>
                    <div className='num-msgs'>{obj_item.numMsgs}</div>
                </div>
            </li>
        </Link>
    )
}

SbCourseTasksSubitem.propTypes = {
    str_identifier: PropTypes.string.isRequired,
    str_date: PropTypes.string.isRequired,
    nbr_id: PropTypes.number.isRequired,
    obj_item: PropTypes.object.isRequired
}

export default SbCourseTasksSubitem
