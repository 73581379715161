/**
 * Entails the serializers to covnvert received backend data
 * into a format that can be parsed by the food offer components.
 * 
 * The input format expected by the food offers card 'CardFood.js' is:
 * {
        id: 0,
        title: 'Gratisbier',
        provider: 'the-kitchen',
        body: {
            description: 'Here comes some description of a food item.',
            startTime: '10:00',
            endTime: '12:00',
            location: 'Hauptplatz 1, 8700 Leoben',
            hasLocal: true,
            hasPickup: true,
            hasDelivery: false,
            isVegetarian: true,
            isVegan: false,
            numIntervalDays: 28
        },
        // Not all keys are required for every footer, but these are all possible ones.
        footer: {
            price: 2.50,
            numAvailable: 4,
            numRemaining: 1,
            numOwned: 0,
            numReserved: 3,
            numConsumed: 2
        }
    }
*/

import { getWeekdayIndexEurope } from "../../date_time_handler";

/**
 * Keys to access the values of the JSON sent by the backend.
 * 
 * If a key is no present in the data sent from the backend, it is simply ignored.
 * This is necessary as not all keys are used for every food offer. E.g. the repeat
 * days are only available if an offer is indeed a repeat offer.
 */

/* Body contains the general information about the food offer. */
const BODY_KEYS = [
    'description',
    'date',
    'repeatDays',
    'repeatOnWeekends',
    'startTime',
    'endTime',
    'address',
    'hasLocalConsumption',
    'hasPickupConsumption',
    'hasDeliveryConsumption',
    'isVegetarian',
    'isVegan',
];

/* Footer contains the information about the purchasing/reserving numbers. */
const FOOTER_KEYS = [
    'price',
    'numAvailable', /* No. of an item that is available (e.g. 10 items of coupon 'abc'). */
    'numLeft',      /* No. of an item that is still available for purchasing/reserving. */
    'numReserved',  /* No. of an item that is not available anymore. */
    'numOwned',     /* No. of an item that is owned by a user. */
    'numRedeemed',  /* No. of coupons a user has redeemed out of the no. purchased. */
    'isOwner',      /* Is user owner of an item, used if only one item can be purchased/reserved (food offers). */
    'isRedeemed',   /* Is the one item a user can own redeemed? */
    'isToday',      /* Is date today? Used to identify which items can be redeemed today. */
    'isPastOffer'   /* Was offer offered before today? */
];

const dbJson2foodOfferCard = (dbObj) => {
    /**
     * Converts the JSON data received from the backend into a JS object
     * that can be parsed by the food offer cards component 'CardFood.js'.
     * :Input
     *  dbOjb (obj): JS object received from the DB.
     * :Returns
     *  JS object that can be parsed by 'CardFood.js'.
     */
    let robj = {};
    let bodyObj = {};
    let footerObj = {};

    robj['id'] = dbObj.id;
    robj['title'] = dbObj.title;
    robj['provider'] = dbObj.provider;

    /* Serialize card body data. */
    BODY_KEYS.forEach((key) => {
        const value = dbObj[key];
        if (value || value === 0 || value === false)
            bodyObj[key] = value;
    })
    robj['body'] = bodyObj;

    /* Serialize card footer data. */
    FOOTER_KEYS.forEach((key) => {
        const value = dbObj[key];
        if (value || value === 0 || value === false)
            footerObj[key] = value;
    })
    robj['footer'] = footerObj;

    return robj;
}

const dbList2foodOfferCardsArray = (dbList) => {
    /**
     * Converts every JS object entry of an array into a JS object that
     * can be parsed by the food offer cards component 'CardFood.js'.
     * :Input
     *  dbList (array): Item are JS ojects with food offer data from the DB. 
     * :Returns
     *  Array of JS objects that can be parsed by 'CardFood.js'.
     */
    return dbList.map((item) => dbJson2foodOfferCard(item));
}

const dbList2foodOffersWeekdayObject = (dbList) => {
    /**
     * Converts every JSON entry of an array into a JS object that
     * can be parsed by the food offer cards component 'CardFood.js'.
     * :Input
     *  dbList (array): Item are JS objects with food offer data from the DB.
     * :Returns
     *  JS object.: Keys are 0 - 6 representing the weekdays (0 = Sunday).
     *      Values are JS objects that can be parsed by 'CardFood.js'.
     */
    let weekdayObj = {};
    for (let i = 0; i < 7; i++)
        weekdayObj[i] = [];
    
    dbList.forEach((item) => {
        const weekdayIndex = getWeekdayIndexEurope(item.date)
        weekdayObj[weekdayIndex].push(dbJson2foodOfferCard(item));
    })

    return weekdayObj;
}

const addOffer2weekday = (offers, dbOfferObj) => {
    /**
     * Adds a new offer to one of the weekday offers.
     * :Input
     *  offers (obj): State of week offers (thisWeek, nextWeek).
     *  dbOfferObj (obj): JS object received via DB request.
     * :Returns
     *  Updated week offers object.
     */
    let obj = {};
    const offer = dbJson2foodOfferCard(dbOfferObj);
    const weekdayIndex = getWeekdayIndexEurope(dbOfferObj.date).toString();
    Object.keys(offers).forEach((key) => {
        if (key === weekdayIndex) {
            obj[key] = [offer, ...offers[key]]
        } else {
            obj[key] = [...offers[key]]
        }
    })
    return obj;
}

const FoodOffersSerializer = {
    dbJson2foodOfferCard: dbJson2foodOfferCard,
    dbList2foodOfferCardsArray: dbList2foodOfferCardsArray,
    dbList2foodOffersWeekdayObject: dbList2foodOffersWeekdayObject,
    addOffer2weekday: addOffer2weekday
};

export default FoodOffersSerializer;