import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthService from '../../util/authentication/auth_service';
import { isActiveURLitem } from '../../util/data_handler';
import { PPMcNavbarData } from './data/PPMcNavbarData';

/* ID that identifies the active sec navbar item. */
const str_ppMcNavActiveItem = 'pp-mc-nav-item-active';

const PPMcNavbar = ({ str_dataKey }) => {

    const location = useLocation()
    const usertype = AuthService.getUsertype()
    const lenNavItems = Object.keys(PPMcNavbarData[usertype][str_dataKey]).length
    const str_locationPath = location.pathname
    const obj_navEntries = PPMcNavbarData[usertype][str_dataKey]

    return (
        <div className="pp-mc-nav-wrapper">
            <nav id="pp-mc-nav-id" className="pp-mc-nav">
                <ul className={`nav-links ${lenNavItems < 3 ? 'max-2-links' : ''}`}>
                    {
                        Object.values(obj_navEntries).map((entry, index) => (
                            <li
                                key={index}
                                className="nav-item"
                                id={isActiveURLitem(str_locationPath, entry.name) ? str_ppMcNavActiveItem : ''}
                            >
                                <Link to={entry.link}>
                                    {
                                        entry.isButton
                                        ?
                                        <button className="btn btn-sec-nav">
                                            {entry.title}
                                        </button>
                                        :
                                        entry.title
                                    }
                                </Link>
                            </li>
                        ))
                    }
                </ul>
            </nav>
        </div>
    )
}

PPMcNavbar.propTypes = {
    str_dataKey: PropTypes.string.isRequired
}

export default PPMcNavbar
