import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import CardBase from '../CardBase';
import CardCouponBody from './CardCouponBody';
import CardFooterPurchase from '../CardFooterPurchase';
import CardCouponFooterInfo from './CardCouponFooterInfo';
import CardCouponFooterEdit from './CardCouponFooterEdit';
import CardCouponFooterRedeem from './CardCouponFooterRedeem';

import { useDeleteOldContext } from '../../profile-pages/context-provider/PPCouponsContextProvider';

/* Base purchase URL, need to append the ID to complete it. */
const PURCHASE_BASE_URL = '/api/store/coupons/detail/';

const CardCoupon = ({
    nbr_id,
    str_usertype,
    str_title,
    str_eyebrow,
    str_currency='',
    obj_bodyData,
    obj_footerData,
    b_hasSellView=false,   /* View user sees in the store. */
    b_hasRedeemView=false, /* View student user sees on his PP. */
    b_hasInfoView=false,   /* View gastro user sees on his PP (all of his created coupons). */
    /* Redeem props. */
    b_hasModalView=false,
    b_isRedeemSuccess,
    fct_handleRedeem
}) => {
    const cardRef = useRef()
    const deleteOld = useDeleteOldContext()
    return (
        <CardBase
            cardRef={cardRef}
            str_title={str_title}
            str_eyebrow={str_eyebrow}
            b_hasCardShadow={true}
            b_hasBoldTitle={true}
            comp_cardBody={<CardCouponBody {...obj_bodyData} />}
            comp_cardFooter={
                b_hasSellView
                ?
                <CardFooterPurchase
                    str_baseUrl={PURCHASE_BASE_URL}
                    str_usertype={str_usertype}
                    str_cardTitle={str_title}
                    str_btnTitle='Kaufen'
                    str_currency={str_currency}
                    nbr_id={nbr_id}
                    nbr_price={obj_footerData.price}
                    nbr_available={obj_footerData.numAvailable}
                    nbr_left={obj_footerData.numLeft}
                    nbr_owned={obj_footerData.numOwned}
                />
                :
                b_hasRedeemView
                ?
                /* Student user only. */
                <CardCouponFooterRedeem
                    nbr_id={nbr_id}
                    nbr_price={obj_footerData.price}
                    nbr_purchased={obj_footerData.numPurchased}
                    nbr_redeemed={obj_footerData.numRedeemed}
                    b_hasModalView={b_hasModalView}
                    b_isRedeemSuccess={b_isRedeemSuccess}
                    str_redeemTimestamp={obj_footerData.redeemTimestamp}
                    str_redeemUserFullName={obj_footerData.redeemUserFullName}
                    str_redeemCheckCode={obj_footerData.redeemCheckCode}
                    fct_handleRedeem={fct_handleRedeem}
                />
                :
                b_hasInfoView
                ?
                /* Gastronomer user only. Currently online coupons. */
                <CardCouponFooterInfo
                    nbr_id={nbr_id}
                    nbr_price={obj_footerData.price}
                    nbr_available={obj_footerData.numAvailable}
                    nbr_sold={obj_footerData.numPurchased}
                    nbr_redeemed={obj_footerData.numRedeemed}
                />
                :
                /* Gastronomer user only. Edit view of old coupons. */
                <CardCouponFooterEdit
                    nbr_id={nbr_id}
                    nbr_price={obj_footerData.price}
                    nbr_available={obj_footerData.numAvailable}
                    nbr_sold={obj_footerData.numPurchased}
                    nbr_redeemed={obj_footerData.numRedeemed}
                    str_currency={str_currency}
                    fct_handleDelete={() => deleteOld(nbr_id, cardRef)}
                />
            }
        />
    )
}

CardCoupon.propTypes = {
    nbr_id: PropTypes.number.isRequired,
    str_usertype: PropTypes.string,
    str_title: PropTypes.string.isRequired,
    str_eyebrow: PropTypes.string,
    str_currency: PropTypes.string,
    obj_bodyData: PropTypes.object.isRequired,
    obj_footerData: PropTypes.object.isRequired,
    b_hasSellView: PropTypes.bool,
    b_hasInfoView: PropTypes.bool,
    b_hasModalView: PropTypes.bool,
    b_isRedeemSuccess: PropTypes.bool,
    fct_handleRedeem: PropTypes.func
}

export default CardCoupon
