/**
 * This file entails the confiurations for the input panels 
 * of discussions, i.e. the panels that are used to comment, edit,
 * and create new posts. 
 * One such config object exists for every discussion. Make sure to
 * import the right config oject in the corresponding discussion component.
 * 
 * Input panels exist of a 'TextField', a 'SingleChoiceField', and multiple
 * 'FileFields'. The configuration helps to select what is wanted. 
 * It helps to make the dev. experience more dynamic an abstract the naming
 * of the different forms away from the developer. 
 * 
 * Config objects do always have the following form:
 * <obj_name> = {
 *      hasTextField: true/false // If a TextField is wanted.
 *      hasSCField: true/false   // If a SCField is wanted (message types)
 *      numFiles: int            // #FileFields wanted for the form.
 * }
 * 
 * In any usecase, make sure that your form does actually exist in the 
 * 'InputFormPanelDataConfigs.js' file.
 * Not all different combinations are supported!
 * 
 */

import FieldLimits from '../input-forms/util/db_field_limits.json';

/* Course exercises. */

// const CourseExercisesDiscussionConfig = {
//     hasTextField: true,
//     hasSCField: true,
//     numFiles: 2
// };

// const CourseExamsDiscussionFileFieldConfig = {
//     nbr_maxSize: FieldLimits.course.exercise.file.size,
//     nbr_allowedFileTypes: FieldLimits.course.exercise.file.extensions
// };

/* Course exams. */

const CourseExamsDiscussionConfig = {
    hasTextField: true,
    hasSCField: true,
    numFiles: 2,
    hasTagField: false
};

const CourseExamsDiscussionFileFieldConfig = {
    nbr_maxSize: FieldLimits.course.task.file.size,
    nbr_allowedFileTypes: FieldLimits.course.task.file.extensions
};

/* Course docs. */

const CourseDocsDiscussionConfig = {
    hasTextField: true,
    hasSCField: true,
    numFiles: 1,
    hasTagField: false
};

const CourseDocsDiscussionFileFieldConfig = {
    nbr_maxSize: FieldLimits.course.doc.message.file.size,
    arr_allowedFileTypes: FieldLimits.course.doc.message.file.extensions
};

/* Course forums. */

const CourseForumsDiscussionConfig = {
    hasTextField: true,
    hasSCField: true,
    numFiles: 2,
    hasTagField: true
};

const CourseForumsMsgDiscussionConfig = {
    hasTextField: true,
    hasSCField: true,
    numFiles: 2,
    hasTagField: false
};

const CourseForumsDiscussionFileFieldConfig = {
    nbr_maxSize: FieldLimits.course.forum.messageFile.size,
    arr_allowedFileTypes: FieldLimits.course.forum.messageFile.extensions
};


const DiscussionConfigs = {
    // CourseExcercisesDiscussion: {
    //     formConfig: CourseExercisesDiscussionConfig,
    //     fileFieldConfig: CourseExercisesDiscussionFileFieldConfig
    // },
    CourseExamsDiscussion: {
        formConfig: CourseExamsDiscussionConfig,
        fileFieldConfig: CourseExamsDiscussionFileFieldConfig
    },
    CourseDocsDiscussion: {
        formConfig: CourseDocsDiscussionConfig,
        fileFieldConfig: CourseDocsDiscussionFileFieldConfig
    },
    CourseForumsDiscussion: {
        formConfig: CourseForumsDiscussionConfig,
        formMsgConfig: CourseForumsMsgDiscussionConfig,
        fileFieldConfig: CourseForumsDiscussionFileFieldConfig
    }
};

export default DiscussionConfigs;
