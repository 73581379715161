import React from 'react';
import PropTypes from 'prop-types';
import { browserDateToGermanDateFormat } from '../../../util/date_time_handler';
import { formatPhoneNumber } from '../../../util/data_handler';

const JobFooter = ({ obj_data }) => {
    return (
        <section className="job-pos-footer">
            <div className="job-pos-content-footer">
                <div className="application-info-header">Jetzt bewerben</div>

                {
                    obj_data.positionUrl &&
                    <div className="application-link">
                        <a
                            href={obj_data.positionUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="page-link"
                        >
                            Zur Jobanzeige
                        </a>
                    </div>
                }

                <div className="application-infos">
                    <div className="header">Timeline</div>
                    <div className="details">
                        <span className="info-pair">
                            <span className="key">Gepostet</span>
                            <span className="value">{browserDateToGermanDateFormat(obj_data.launchDate)}</span>
                            <span className="separator">|</span>
                        </span>
                        <span className="info-pair">
                            <span className="key">Deadline</span>
                            <span className="value">{browserDateToGermanDateFormat(obj_data.closingDate)}</span>
                        </span>
                    </div>
                </div>

                <div className="application-infos">
                    <div className="header">Anforderungen</div>
                    <div className="details">
                        <span className="info-pair">
                            <span className="key">Dokumente</span>
                            <span className="long-value">{obj_data.applicationDocs}</span>
                            <span className="separator">|</span>
                        </span>
                        <span className="info-pair">
                            <span className="key">Abgeschlossene Semester</span>
                            <span className="value">
                                {
                                    obj_data.numCompletedSemesters ?
                                    obj_data.numCompletedSemesters :
                                    'Keine Voraussetzung'
                                }
                            </span>
                        </span>
                    </div>
                </div>

                <div className="application-infos">
                    <div className="header">Schwerpunkt</div>
                    <div className="details">
                        <span className="info-pair">
                            <span className="key">Fachbereich</span>
                            <span className="value">{obj_data.focusArea}</span>
                        </span>
                    </div>
                </div>
                
                <div className="application-infos">
                    <div className="header">Kontaktperson</div>
                    <div className="details">
                        <span className="info-pair">
                            <span className="key">Name</span>
                            <span className="value">{obj_data.firstName}&nbsp;{obj_data.lastName}</span>
                            <span className="separator">|</span>
                        </span>
                        <span className="info-pair">
                            <span className="key">Position</span>
                            <span className="value">{obj_data.jobPosition}</span>
                            <span className="separator">|</span>
                        </span>
                        <span className="info-pair">
                            <span className="key">Standort</span>
                            <span className="value">{obj_data.workLocation}</span>
                            <span className="separator">|</span>
                        </span>
                        <span className="info-pair">
                            <span className="key">Telefon</span>
                            <span className="value">{formatPhoneNumber(obj_data.phone)}</span>
                            <span className="separator">|</span>
                        </span>
                        <span className="info-pair">
                            <span className="key">E-Mail</span>
                            <span className="value">{obj_data.email}</span>
                        </span>
                        {
                            obj_data.linkedin &&
                            <span className="info-pair">
                                <span className="separator">|</span>
                                <span className="value">
                                    <a 
                                        href={obj_data.linkedin}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="page-link"
                                    >
                                        LinkedIn
                                    </a>
                                </span>
                            </span>
                        }
                    </div>
                </div>

                <div className="application-add-infos">
                    <hr />
                    <p>{obj_data.equalOpsMsg}</p>
                </div>
            </div>
        </section>
    )
}

JobFooter.propTypes = {
    obj_data: PropTypes.object.isRequired
}

export default JobFooter
