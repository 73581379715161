import React, {  useRef, useContext } from 'react'

import {
    useHandleInputChange,
    useInputData,
    useAreRequiredErrorsActive,
    useSetAreRequiredErrorsActive
} from '../../input-forms/context-provider/InputFormContextProvider';
import {
    kobj_keyMappingOptions,
    kobj_classMapping
} from '../util/PanelData';

const TextFieldRefContext = React.createContext();
const MarkTextViaShortcutsContext = React.createContext();
const IndicateMarkedTextContext = React.createContext();
const HandlePanelSwitchContext = React.createContext();

export function useTextFieldRef() {
    return useContext(TextFieldRefContext);
}

export function useMarkTextViaShortcuts() {
    return useContext(MarkTextViaShortcutsContext);
}

export function useIndicateMarkedText() {
    return useContext(IndicateMarkedTextContext);
}

export function useHandlePanelSwitch() {
    return useContext(HandlePanelSwitchContext);
}


const extractObjectMappingFromOptions = (arr_usedOptions) => {
    /**
     * Creates a mapping object for user short-cuts to highlight input text. 
     * :Input
     *  arr_usedOptions (arr): Entails string numbers ('4', '5', '7', '8', '9') or 'all'. 
     */
    /* All options are used if 'all' or no entries (safety option for no entries. */
    if (arr_usedOptions[0] === 'all' || arr_usedOptions[0] === undefined)
        return kobj_keyMappingOptions;

    let obj_mapping = {};
    arr_usedOptions.forEach((item) => {
        /* Only add options if key does exist. */
        if (kobj_keyMappingOptions[item] !== undefined)
            obj_mapping[item] = kobj_keyMappingOptions[item];
    })
    return obj_mapping;
}

const str_previewAlertMsg = 'Eine Vorschau ist nur dann verfügbar, wenn alle ' + 
                            'Pflichtfelder ausgefüllt sind.';

                            
const PanelContextProvider = ({
    arr_indicators=['all'], /* Either ['all'] or array like ['b', 'i', ... ]. */
    children
}) => {
    
    const textFieldRef = useRef()
    const data = useInputData()
    const handleInputChange = useHandleInputChange()
    const areRequiredErrorsActive = useAreRequiredErrorsActive()
    const setAreRequiredErrorsActive = useSetAreRequiredErrorsActive()

    const kobj_keyMapping = extractObjectMappingFromOptions(arr_indicators)

    const markTextViaShortcuts = (e) => {
        /* If user presses specific shortcuts, the marked text in the 
         * textfield gets wrapped in indicators for html code identification.
         * This function is used by the textfield input form component. */
        if (e.ctrlKey) {
            const kstr_marker = kobj_keyMapping[e.key]
            if (kstr_marker !== undefined) {
                /* Prevent switching to a specific browser tab number. */
                e.preventDefault()
                indicateMarkedText(kstr_marker)
            }
        }
    }

    const indicateMarkedText = (str_marker) => {
        /**
         * Wraps the highlighted text between indicators. 
         * :Input
         *  str_marker: Character that determines which indicator must be used. 
         *              ('b' for bold, 'i' for italic, etc.)
         */
        /* Check empty value of textfield. */
        const str_textInput = textFieldRef.current.value
        if (!str_textInput) {
            textFieldRef.current.focus()
            return
        }

        /* Get the position of the start and end of the marked text. */
        const kint_selectionStart = textFieldRef.current.selectionStart
        const kint_selectionEnd = textFieldRef.current.selectionEnd

        /* Check if at least one character is selected. */
        if (kint_selectionStart === kint_selectionEnd) {
            textFieldRef.current.focus()
            textFieldRef.current.selectionEnd = kint_selectionEnd
            return
        }

        /* Extract the marked text. */
        const kstr_firstPart = str_textInput.slice(0, kint_selectionStart)
        const kstr_middlePart = str_textInput.slice(kint_selectionStart, kint_selectionEnd)
        const kstr_lastPart = str_textInput.slice(kint_selectionEnd,)

        /* Get the indicators. */
        const arr_indicators = kobj_classMapping[str_marker]

        /* Decide wether to add or remove the characteristic chars. 
         * If a text is wrapped with, e.g. *% ... %*, then clicking on bold removes the
         * chars, as a double wrapping does not make any sense. */
        let kstr_middlePartChars, int_cursorShift;
        if ((arr_indicators[0] === kstr_middlePart.slice(0, 2)) &&
                arr_indicators[1] === kstr_middlePart.slice(-2,))
        {
            kstr_middlePartChars = kstr_middlePart.slice(2, -2)
            int_cursorShift = -4
        } else {
            kstr_middlePartChars = arr_indicators[0] + kstr_middlePart + arr_indicators[1]
            int_cursorShift = 4
        }

        /* Set value of the textarea in 'data' of the InputFormContextProvider so that 
         * the new text with the markers is shown. */
        const kstr_result = kstr_firstPart + kstr_middlePartChars + kstr_lastPart
        handleInputChange({
            id: textFieldRef.current.id,
            value: kstr_result,
            hasError: false
        })

        /* Set users cursor at the end of the selection again. 
         * + 4 for the 4 characteristic chars added to highlight the text.
         * Wait a few ms for component rerender before the cursor is set. 
         */
        setTimeout(() => {
            textFieldRef.current.focus() /* Necessary if the user clicks a symbol. */
            textFieldRef.current.selectionEnd = kint_selectionEnd + int_cursorShift
        }, 30)
    }

    const handlePanelSwitch = (
        b_targetIsWriteView,
        b_isWriteViewOpen,
        fct_setIsWriteViewOpen
    ) => {
        /**
         * Handle which panel should be displayed (write or pre view).
         */
        if (b_targetIsWriteView && !b_isWriteViewOpen) {
            /* Switch to write view. */
            fct_setIsWriteViewOpen(!b_isWriteViewOpen)
            /* Timeout before focus to make sure the DOM element is in place. 
             * Check if post panel has a text field. */
            if (data.textField) setTimeout(() => {textFieldRef.current.focus()}, 100)
        }
        else if (!b_targetIsWriteView && b_isWriteViewOpen) {
            /* Switch to preview. */
            let b_hasError = false
            Object.values(data).forEach(val => {
                if (val[1]) { b_hasError = true }
            })
            if (!b_hasError) {
                fct_setIsWriteViewOpen(!b_isWriteViewOpen)
            } else {
                alert(str_previewAlertMsg)
                if (!areRequiredErrorsActive) setAreRequiredErrorsActive(true)
                if (data.textField) textFieldRef.current.focus()
            }
        }
    }
    
    return (
        <TextFieldRefContext.Provider value={textFieldRef}>
            <MarkTextViaShortcutsContext.Provider value={markTextViaShortcuts}>
                <IndicateMarkedTextContext.Provider value={indicateMarkedText}>
                    <HandlePanelSwitchContext.Provider value={handlePanelSwitch}>
                        {children}
                    </HandlePanelSwitchContext.Provider>
                </IndicateMarkedTextContext.Provider>
            </MarkTextViaShortcutsContext.Provider>
        </TextFieldRefContext.Provider>
    )
}

export default PanelContextProvider
