import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import TbPin from '../../TbPin';
import DataStateCheckWrapper from '../../../general/DataStateCheckWrapper';
import { string2URLstring } from '../../../../util/data_handler';
import { browserDateToGermanDateFormat } from '../../../../util/date_time_handler';
import {
    useQueriedDataContext,
    useIsFirstFetchSuccessContext,
    useInfiniteScrollContext
} from '../../../context-provider/DynamicContentLoadContextProvider';
import { PPLsKeys, StudentLsKeys } from '../../../../util/LocalStorageVariables';

/* LS keys of the job pinned on student user's profile page. */
const PR_LS_KEY = PPLsKeys.primaryDataKey;
const SEC_LS_KEY = StudentLsKeys.pp.jobs;

const BASE_URL = '/api/industry/job/pin/detail/';

const genUrl = (id) => {
    return BASE_URL + id + '/';
}

const TbIndustryJobs = ({
    arr_data=[],
    str_textAlignment='text-left',
    str_design='',
    b_isDynamic=false, /* If data is loaded in packages one after another. */
    b_hasCompany=false,
    b_hasPins=false
}) => {
    
    const isFirstFetchSuccessRef = useIsFirstFetchSuccessContext()
    const infiniteScrollRef = useInfiniteScrollContext()

    if (b_isDynamic) {
        arr_data = useQueriedDataContext()
    }

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={b_isDynamic}
            b_isFirstFetchSuccess={isFirstFetchSuccessRef ? isFirstFetchSuccessRef.current : undefined}
            firstQueryDataEntry={arr_data[0]}
        >
            <div className='tb-wrapper'>
                <table
                    ref={infiniteScrollRef}
                    cellSpacing="0"
                    className={`tb-base ${str_textAlignment} ${str_design}`}
                >
                    <thead>
                        <tr>
                            <td>Titel</td>
                            {
                                b_hasCompany &&
                                <td className="hide-max-w-576px">Unternehmen</td>
                            }
                            <td className="hide-max-w-768px">Zeitmodell</td>
                            <td className="hide-max-w-1024px">Land</td>
                            <td className="hide-max-w-1024px">Bezirk</td>
                            <td className="hide-max-w-768px">Frühester Beginn</td>
                            <td>Online bis</td>
                            {
                                b_hasPins &&
                                <td>Pin</td>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            arr_data.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <Link
                                            to={`/industry/jobs/${string2URLstring(item.companyName)}/${string2URLstring(item.title)}/${item.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="file-link job-title"
                                        >
                                            {item.title}
                                        </Link>
                                    </td>
                                    {
                                        b_hasCompany &&
                                        <td className="company-name hide-max-w-576px">
                                            <Link
                                                to={`/company/info-page/${string2URLstring(item.companyName)}/${item.companyId}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="file-link"
                                            >
                                                {item.companyName}
                                            </Link>
                                        </td>
                                    }
                                    <td className="time-model hide-max-w-768px">{item.timeModel}</td>
                                    <td className="county hide-max-w-1024px">{item.county ? item.county : '—'}</td>
                                    <td className="district hide-max-w-1024px">{item.district ? item.district : '—'}</td>
                                    <td className="start-date hide-max-w-768px">{browserDateToGermanDateFormat(item.startDate)}</td>
                                    <td className="closing-date">{browserDateToGermanDateFormat(item.closingDate)}</td>
                                    {
                                        b_hasPins &&
                                        <TbPin
                                            nbr_id={item.id}
                                            str_url={genUrl(item.id)}
                                            str_prLsKey={PR_LS_KEY}
                                            str_secLsKey={SEC_LS_KEY}
                                            str_confirmMsgDetails={item.title}
                                            b_isPinned={item.isPinned}
                                        />
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </DataStateCheckWrapper>
    )
}

TbIndustryJobs.propTypes = {
    arr_data: PropTypes.array,
    str_textAlignment: PropTypes.string,
    str_design: PropTypes.string,
    b_isDynamic: PropTypes.bool,
    b_hasPins: PropTypes.bool,
    b_hasCompany: PropTypes.bool
}

export default TbIndustryJobs
