import React from 'react';

import SbBtnContent from '../../SbBtnContent';
import SbDocsItem from './SbDocsItem';

import { useDocCategoriesContext } from '../../context-provider/SbCourseDocsContextProvider';
import useSbSetMinPageHeight from '../../../../custom-hooks/useSbSetMinPageHeight';

const SbDocs = () => {
    const sbItems = useDocCategoriesContext()
    useSbSetMinPageHeight(sbItems)
    return (
        <SbBtnContent
            str_btnTitle='neues dok'
            str_sbClasses='sb-with-info-items'
            b_hasDarkDesign={true}
        >
            {
                Object.entries(sbItems).map(([category, numDocs], index) => (
                    /* Show only doc categories that have at least one doc. */
                    numDocs
                    ?
                    <SbDocsItem
                        key={index}
                        arr_data={[category, numDocs]}
                    />
                    :
                    ''
                ))
            }
        </SbBtnContent>
    )
}

export default SbDocs
