/**
 * Entails the data to generate info messages depending on the browser path. 
 */

const str_emailMsg = 'Es wurde eine Email an die angegebene Emailaddress gesendet.\n' +
    'Bitte checke deinen Posteingang und Spamordner.\n' +
    'Sollte in den nächsten Minuten keine Email ankommen, lade diese Seite neu und sende ' +
    'die Email noch einmal. Stelle auch sicher, dass die Emailadresse korrekt ist.';

export const successMsgs = {
    studentRegistration: str_emailMsg,
    indGastRegistration: 'Die Registrierungsdaten wurden erfolgreich gespeichert. ' +
        'Die Registrierung wird in den nächsten Werktagen bearbeitet und wir kommen ' +
        'dann auf dich zurück.\nWir freuen uns, dich bald auf der Plattform begrüßen zu dürfen.',
    passwordForgot: str_emailMsg,
    passwordReset: 'Dein Passwort wurde erfolgreich geändert. Bitte gehe zum Login und ' +
        'versuche dich gleich damit anzumelden.',
};

export const errorMsgs = {
    login: 'Username oder Passwort falsch.',
    registration: 'Die Registrierung wurde nicht durchgeführt. Es ist entweder ein Netzwerk- ' +
        'oder Serverfehler aufgetreten. Bitte versuche es später erneut.',
    passwordForgot: 'Username oder Email falsch.',
    passwordReset: 'Das Passwort konnte nicht geändert werden. Es ist entweder ein Netzwerk- oder ' +
        'Serverfehler aufgetreten. Bitte versuche es später erneut.',
};

const eyebrows = {
    login: 'willkommen',
    regist: 'registrieren',
    reset: 'passwort reset',
    forgot: 'passwort vergessen',
};

const footers = {
    login: {
        firstEntry: {
            link: '/registration/student',
            innerHTML: 'Registrieren'
        },
        secondEntry: {
            link: '/password/forgot',
            innerHTML: 'Passwort vergessen'
        }
    },
    forgot: {
        firstEntry: {
            link: '/registration/student',
            innerHTML: 'Registrieren'
        },
        secondEntry: {
            link: '/login',
            innerHTML: 'Login'
        }
    },
    reset: {
        firstEntry: {
            link: '/login',
            innerHTML: 'Login'
        }
    },
    regist: {
        firstEntry: {
            link: '/login',
            innerHTML: 'Login'
        }
    }
};

export const genEyebrow = (path) => {
    let str_eyebrow = '';
    Object.keys(eyebrows).forEach(key => {
        if (path.includes(key))
            str_eyebrow = eyebrows[key]
    })
    if (!str_eyebrow) return eyebrows['login']; /* No login path, host only. */
    return str_eyebrow
}

export const genFooterData = (path) => {
    let obj_footer = null;
    Object.keys(footers).forEach(key => {
        if (path.includes(key))
            obj_footer = footers[key];
    })
    if (!obj_footer) return footers['login']; /* No login path, host only. */
    return obj_footer;
}
