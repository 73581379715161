/**
 * This component is used to cache loaded messages when unmounting the
 * component that stored them so that it can retrieve it later.
 * 
 * This is necessary if the user can update specific message data on a 
 * different page. Thus, it is necessary that the updated data is available
 * once the user navigates back to the original page that stored the data
 * into the cache. Now, this component is able to fetch the updated data
 * form the cache. 
 */
import React, { useState, useContext } from 'react';

const GetMessageCache = React.createContext();
const SetMessageCache = React.createContext();
const UpdateMessageVotes = React.createContext();
const IncrementMessageThreadNum = React.createContext();

export function useGetMessageCache() {
    return useContext(GetMessageCache);
}

export function useSetMessageCache() {
    return useContext(SetMessageCache);
}

export function useUpdateMessageVotes() {
    return useContext(UpdateMessageVotes);
}

export function useIncrementMessageThreadNum() {
    return useContext(IncrementMessageThreadNum);
}

const MessageCacheContextProvider = ({ children }) => {
    /**
     * Data is an array of 3 items:
     * 1) Array of msgs (arr).
     * 2) ref hook that stores the index or id of the last fetched item (nbr).
     * 3) ref hook that stores a if the last fetch carried out was successful (bool).
     */
    const [data, setData] = useState()
    
    const updateMessageVotes = (msgId, deltaUpvote, deltaDownvote, userVoted) => {
        /**
         * Updates the vote numbers of a specific message.
         * :Input
         *  msgId         (int): Id of the message that is to be updated.
         *  deltaUpvote   (int): 1 (vote up), 0 (not voted), -1 (voted down)
         *  deltaDownvote (int): 1 (vote up), 0 (not voted), -1 (voted down)
         *  userVoted    (bool): true (voted up), false (voted down), null (not voted)
         */
        if (!data) return
        const newMsgs = data[0].map(msg => {
            if (msg.id === msgId) {
                const newNumUpvotes = msg.numUpvotes + deltaUpvote
                const newNumDownvotes = msg.numDownvotes + deltaDownvote
                return {
                    ...msg,
                    numUpvotes: newNumUpvotes,
                    numDownvotes: newNumDownvotes,
                    userVoted: userVoted
                }
            }
            return msg
        })
        setData([newMsgs, data[1], data[2]])
    }

    const incrementMessageThreadNum = (msgId) => {
        /**
         * This function is only used for the forum/thread pages.
         * Increments the number of thread messages a forum message has.
         * :Input
         *  msgId (number)
         */
        if (!data || typeof(msgId) !== 'number') return
        const newMsgs = data[0].map(msg => {
            if (msg.id === msgId) {
                const newNumThreadMsgs = msg.numThreadMsgs + 1
                return { ...msg, numThreadMsgs: newNumThreadMsgs }
            }
            return msg
        })
        setData([newMsgs, data[1], data[2]])
    }

    return (
        <GetMessageCache.Provider value={data}>
            <SetMessageCache.Provider value={setData}>
                <UpdateMessageVotes.Provider value={updateMessageVotes}>
                    <IncrementMessageThreadNum.Provider value={incrementMessageThreadNum}>
                        {children}
                    </IncrementMessageThreadNum.Provider>
                </UpdateMessageVotes.Provider>
            </SetMessageCache.Provider>
        </GetMessageCache.Provider>
    )
}

export default MessageCacheContextProvider
