import React, { useState, useContext, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';

import { genFooterData } from './util/welcome_util';

const FooterDataContext = React.createContext();

export function useFooterDataContext() {
    return useContext(FooterDataContext);
}

const WelcomeContextProvider = ({ children }) => {
    
    const location = useLocation()
    const history = useNavigate()
    const [footerData, setFooterData] = useState(genFooterData(location.pathname))

    useEffect(() => {
        setFooterData(genFooterData(location.pathname))
    }, [history])

    return (
        <FooterDataContext.Provider value={footerData}>
            {children}
        </FooterDataContext.Provider>
    )
}

export default WelcomeContextProvider
