/**
 * This components prevents users accessing pages that require 
 * being logged in and having a particular usertype.
 * 
 * By providing this component as a wrapper around the actual page,
 * unnecessary DB requests are avoided.
 */
import PropTypes from 'prop-types';

import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import AuthService from '../../util/authentication/auth_service';
import Usertypes from '../../util/Usertypes';

const PrivatePage = ({
    arr_usertypes, /* Array of strings of usertypes (undefined: all users have permission). */
    b_checkAboValidity=true,
    b_isStudentFreeTier=false,
    children
}) => {

    const location = useLocation()
    const navigate = useNavigate()
    const [hasPermission, setHasPermission] = useState(false)

    useEffect(() => {
        const user = AuthService.getUser()
        if (!user) {
            /* No usertype available, no user logged in. */
            navigate('/login')
        }
        else if (b_isStudentFreeTier && user.usertype === Usertypes.student && user.isProfileSetup) {
            /* Page is part of the free tier, student users can access it. */
            setHasPermission(true)
        }
        else if ((b_checkAboValidity && !user.isAboValid) || (!user.isProfileSetup)) {
            /* Users abo has expired. */
            if (user.isProfileSetup) {
                /* If user is already on the abo extension page, grant access to it. */
                if (
                    location.pathname === '/abo-extension' ||
                    location.pathname === '/abo-extension/success'
                ) {
                    setHasPermission(true)
                } else if (Usertypes.student === user.usertype) {
                    navigate(AuthService.getProfileUrl())
                } else {
                    navigate('/abo-extension')
                }
            } else {
                /* User must still set-up the profile. */
                navigate(`/profile-set-up/${user.usertype}/${user.username}/`)
                setHasPermission(true)
            }
        }
        else if (arr_usertypes && !arr_usertypes.includes(user.usertype)) {
            /* Not the correct usertype for this URL, route to profile page. */
            navigate(AuthService.getProfileUrl())
        }
        else {
            setHasPermission(true)
        }
    }, [])
    
    return (hasPermission && children)
}

PrivatePage.propTypes = {
    arr_usertypes: PropTypes.array,
    b_checkAboValidity: PropTypes.bool
}

export default PrivatePage
