/**
 * Entails all the variables that are used to store data in the LS. 
 * Note: Make sure that no one can is used twice!
 */


/* General. */

export const TimestampLsKey = 'timestamp';

export const FilterLsKeys = {
    /* Data without a filter applied. */
    noFilter: 'noFilter',
    /* LS key for the data of what is stored. */
    data: 'data',
    /* Time value when the LS entry is created. */
    timestamp: 'timestamp',
    /* Course listing page. */
    courseListing: 'courseListing',
    /* Course tutoring. */
    courseTutorOfferList: 'courseTutorOfferList',
    courseTutorMyOffers: 'courseTutorMyOffers',
    /* Course swap. */
    courseSwapOfferList: 'courseSwapOfferList',
    courseSwapMyOffers: 'courseSwapMyOffers',
    /* LS key for course forum. This key is prepended to the course name. */
    courseForum: 'courseForum',
    /* LS key for the forum messages displayed on the student's profile page. */
    studentsForumThreads: 'studentsForumThreads',
    /* LS keys for mining. */
    thisSemesterMiningUser: 'tsMiningUser',
    overallMiningUser: 'ovMiningUser',
    thisSemesterMiningScoreboard: 'tsMining',
    overallMiningScoreboard: 'ovMining'
};

/* Key to identify if the payment has been successfully executed.
 * This value is checked for its presence on the payment success page.
 * If it is not present, the isAboValid LS key is not set to true. */
export const IsPaymentSuccessKey = 'isPaymentSuccessKey';

/* Apps. */

export const AppProfilesLsKeys = {};

export const AppGastronomyLsKeys = {};

export const AppIndustryLsKeys = {
    companiesListing: 'companiesListing',
    jobsListing: 'jobsListing'
};

export const AppStoreLsKeys = {
    coupons: {
        base: 'coupons',
        currentSemester: 'currentSemester',
        nextSemester: 'nextSemester',
        old: 'old',
        foodCoupons: {
            base: 'foodCoupons'
        },
        couponBudget: 'couponBudget'
    }
};

/* Profile pages. */

export const PPLsKeys = {
    primaryDataKey: 'ppData'
};

export const CompanyLsKeys = {
    pp: {
        associates: 'ppAssociates',
        jobPostings: 'ppJobPostings'
    }
};

export const GastronomerLsKeys = {
    pp: {
        foodOffersRegular: {
            base: 'foodOffersRegular',
            thisWeek: 'thisWeek',
            nextWeek: 'nextWeek',
            repeating: 'repeating',
            old: 'old'
        },
        foodOffersDgfs: {
            base: 'foodOffersDgfs',
            today: 'today',
            tomorrow: 'tomorrow',
            old: 'old'
        }
    }
};

export const StudentLsKeys = {
    pp: {
        jobs: 'jobs',
        courses: 'courses',
        companyAcquisitionCoupons: 'companyAcquisitionCoupons'
    }
};