/**
 * This component handles all the if statements that decide what 
 * kind of content will be rendered. 
 * 
 * This component is used as a wrapper of all compnents that 
 * display dynamically loaded content on the page. 
 */
import React from 'react';
import PropTypes from 'prop-types';

import HandleNoData from './HandleNoData';
import { QueryVariables } from '../../util/QueryVariables';
import CSecBase from '../content-sections/CSecBase';

/* Class to standardize styling of the no content section. */
const CLASS_NO_CONTENT_SECTION = 'content-section-no-content';

const DataStateCheckWrapper = ({
    b_hasQueryCheck=false, /* Is false if data is already present (no query loading/error possible). */
    b_isFirstFetchSuccess,
    b_isFirstFilterQueryExecuted=false,
    b_hasFilterOnlyQueries=false,
    b_isContentSection=false,
    b_isContentSectionForChildren=false, /* If you do not want to show the <section> for {children}. */
    b_hasContentSectionDarkDesign=false,
    str_contentSectionTitle='',
    str_contentSectionSubtitle='',
    str_errorMsg='',     /* Error message to be displayed (overwrites the default one). */
    str_noDataMsg='',    /* Custom message if no no data is available. */
    str_contentSectionClasses='',
    firstQueryDataEntry, /* Either 'undefined' for [], or some data item for [ ... ]. */
    children
}) => {
    return (
        (b_hasFilterOnlyQueries && !b_isFirstFilterQueryExecuted)
        ?
        ''
        :
        (b_hasQueryCheck && b_isFirstFetchSuccess === undefined) /* Has not yet been assigned a state. */
        ?
            b_isContentSection
            ?
            <CSecBase
                str_title={str_contentSectionTitle}
                str_subtitle={str_contentSectionSubtitle}
                b_hasDarkDesign={b_hasContentSectionDarkDesign}
                str_classes={`${CLASS_NO_CONTENT_SECTION} ${str_contentSectionClasses} no-border`}
            >
                <HandleNoData b_isLoadingData={true} />
            </CSecBase>
            :
            <HandleNoData b_isLoadingData={true} />
        :
        (b_hasQueryCheck && !b_isFirstFetchSuccess)
        ?
            b_isContentSection
            ?
            <CSecBase
                str_title={str_contentSectionTitle}
                str_subtitle={str_contentSectionSubtitle}
                b_hasDarkDesign={b_hasContentSectionDarkDesign}
                str_classes={`${CLASS_NO_CONTENT_SECTION} ${str_contentSectionClasses} no-border`}
            >
                <HandleNoData str_errorMsg={str_errorMsg ? str_errorMsg : QueryVariables.str_queryFailedMsg} />
            </CSecBase>
            :
            <HandleNoData str_errorMsg={str_errorMsg ? str_errorMsg : QueryVariables.str_queryFailedMsg} />
        :
        (firstQueryDataEntry !== undefined && firstQueryDataEntry !== null)
        ?
            b_isContentSection && b_isContentSectionForChildren
            ?
            <CSecBase
                str_title={str_contentSectionTitle}
                str_subtitle={str_contentSectionSubtitle}
                b_hasDarkDesign={b_hasContentSectionDarkDesign}
                str_classes={`${CLASS_NO_CONTENT_SECTION} ${str_contentSectionClasses}`}
            >
                {children}
            </CSecBase>
            :
            children /* No issues found, render the children. */
        :
            b_isContentSection
            ?
            <CSecBase
                str_title={str_contentSectionTitle}
                str_subtitle={str_contentSectionSubtitle}
                b_hasDarkDesign={b_hasContentSectionDarkDesign}
                str_classes={`${CLASS_NO_CONTENT_SECTION} ${str_contentSectionClasses} no-border`}
            >
                <HandleNoData str_errorMsg={str_noDataMsg} />
            </CSecBase>
            :
            <HandleNoData str_errorMsg={str_noDataMsg} />
    )
}

DataStateCheckWrapper.propTypes = {
    b_hasQueryCheck: PropTypes.bool,
    b_isFirstFetchSuccess: PropTypes.bool,
    b_isFirstFilterQueryExecuted: PropTypes.bool,
    b_hasFilterOnlyQueries: PropTypes.bool,
    b_isContentSection: PropTypes.bool,
    b_isContentSectionForChildren: PropTypes.bool,
    b_hasContentSectionDarkDesign: PropTypes.bool,
    str_contentSectionTitle: PropTypes.string,
    str_contentSectionSubtitle: PropTypes.string,
    str_contentSectionClasses: PropTypes.string,
    str_errorMsg: PropTypes.string,
    str_noDataMsg: PropTypes.string
}

export default DataStateCheckWrapper
