import React from 'react';

import FormBase from '../../../input-forms/FormBase';
import InFieldsGroup from '../../../input-forms/InFieldsGroup';
import TextField from '../../../input-forms/fields/TextField';
import NumberField from '../../../input-forms/fields/NumberField';
import JobCountyDistrictFields from '../../../input-forms/fields/JobCountyDistrictFields';

import { useInputData } from '../../../input-forms/context-provider/InputFormContextProvider';
import { FilterFormIndustryCompanyData as FormData } from '../../../input-forms/util/form-config-data/InputFormFilterDataConfigs';

const FilterFormCompany = () => {
    const data = useInputData()
    return (
        <FormBase
            b_isFilterForm={true}
            b_hasFilterGrid={true}
        >
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.companyName}
                    str_chars={data[FormData.fields.companyName.str_id][0]}
                />
                <JobCountyDistrictFields
                    str_isAbroadFieldId={FormData.fields.isAbroad.str_id}
                    str_countyFieldId={FormData.fields.county.str_id}
                    str_districtFieldId={FormData.fields.district.str_id}
                    str_currentAbroadJobOption={data[FormData.fields.isAbroad.str_id][0]}
                    str_currentCountyOption={data[FormData.fields.county.str_id][0]}
                    str_currentDistrictOption={data[FormData.fields.district.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.minNumEmployees}
                    nbr_value={data[FormData.fields.minNumEmployees.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default FilterFormCompany
