import React from 'react';
import PropTypes from 'prop-types';

const CardSellNums = ({
    nbr_available,
    nbr_soldOrReserved,
    nbr_redeemed,
    str_sellNumTitle='verkauft'
}) => {
    return (
        <div className="add-info">
            <div className="add-info-wrapper">
                <p className="nowrap">
                    <span className="info-nums">
                        <span className="info num-sold-or-reserved">{nbr_soldOrReserved}</span>
                        <span className="info separator">/</span>
                        <span className="info num-available">{nbr_available}</span>
                    </span>
                    <span className="info num-title">{str_sellNumTitle}</span>
                </p>
            </div>
            <div className="add-info-wrapper">
                <p className="nowrap">
                    <span className="info-nums">
                        <span className="info num-redeemed">{nbr_redeemed}</span>
                    </span>
                    <span className="info num-title">eingelöst</span>
                </p>
            </div>
        </div>
    )
}

CardSellNums.propTypes = {
    nbr_available: PropTypes.number.isRequired,
    nbr_soldOrReserved: PropTypes.number.isRequired,
    nbr_redeemed: PropTypes.number.isRequired,
    str_sellNumTitle: PropTypes.string
}

export default CardSellNums
