import React from 'react';
import PropTypes from 'prop-types';

import CSecGridBase from '../../../content-sections/CSecGridBase';
import CSecBase from '../../../content-sections/CSecBase';
import HandleNoData from '../../../general/HandleNoData';

const IndustryPresInternshipInfos = ({
    str_title,
    b_hasDarkDesign=true,
    str_gridClass='intern-opts-grid',
    str_secBaseClasses='section-intern-opts',
    arr_gridItems
}) => {
    return (
        !arr_gridItems
        ?
        <CSecBase
            str_title={str_title}
            b_hasDarkDesign={b_hasDarkDesign}
            b_hasCenteredText={true}
        >
            <HandleNoData str_errorMsg='Es werden leider keine Praktika angeboten.' />
        </CSecBase>
        :
        <CSecGridBase
            str_title={str_title}
            b_hasDarkDesign={b_hasDarkDesign}
            str_gridClass={str_gridClass}
            str_secBaseClasses={str_secBaseClasses}
        >
            {
                arr_gridItems.map((item, index) => (
                    <>
                        <div key={index} className="grid-item">
                            <div className="grid-item-title">{item.title}</div>
                        </div>
                        <div className="grid-item">
                            <div className="grid-item-content sub-grid-container intern-opts-sub-grid">
                                {
                                    item.data.map((subItem, subIndex) => (
                                        <div key={subIndex} className="grid-item">
                                            <div className={`
                                                grid-item-content 
                                                ${subItem.isActive ? 'grid-item-active' : ''}
                                            `}>
                                                {subItem.title}
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </>
                ))
            }
        </CSecGridBase>
    )
}

IndustryPresInternshipInfos.propTypes = {
    str_title: PropTypes.string.isRequired,
    b_hasDarkDesign: PropTypes.bool,
    str_gridClass: PropTypes.string,
    str_secBaseClasses: PropTypes.string,
    arr_gridItems: PropTypes.array
}

export default IndustryPresInternshipInfos
