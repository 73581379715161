import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import TimeField from './TimeField';

import { isSpanPartValid } from '../util/input_checks';
import { useHandleInputChange } from '../context-provider/InputFormContextProvider';
import FieldErrorMsgs from '../util/input_form_fields_error_msgs.json';

const START_EMSG = FieldErrorMsgs.datetime.timespan.startGTend;
const END_EMSG = FieldErrorMsgs.datetime.timespan.endSTstart;

const TimeSpanFields = ({
    str_id,
    str_startTitle='',
    str_endTitle='',
    str_startTime='',
    str_endTime='',
    str_startMinTime,
    str_startMaxTime,
    str_endMinTime,
    str_endMaxTime,
    str_startBottomInfoText='',
    str_endBottomInfoText='',
    b_isRequired=false
}) => {

    const [startTime, setStartTime] = useState(str_startTime)
    const [endTime, setEndTime] = useState(str_endTime)
    const [hasStartTimeError, setHasStartTimeError] = useState(b_isRequired && !str_startTime)
    const [hasEndTimeError, setHasEndTimeError] = useState(b_isRequired && !str_endTime)
    const [startTimeErrorMsg, setStartTimeErrorMsg] = useState('')
    const [endTimeErrorMsg, setEndTimeErrorMsg] = useState('')
    const [hasStateChanged, setHasStateChanged] = useState(false)

    const handleInputChange = useHandleInputChange()

    useMemo(() => {
        /* If new input is received, update the date. */
        setStartTime(str_startTime)
    }, [str_startTime])

    useMemo(() => {
        /* If new input is received, update the date. */
        setEndTime(str_endTime)
    }, [str_endTime])

    const isTimeSpanValidWrapper = (time, b_isStartTime) => {
        if (b_isStartTime) {
            return isSpanPartValid(
                time, endTime, b_isStartTime, START_EMSG, setStartTime, setStartTimeErrorMsg,
                setEndTimeErrorMsg, hasStateChanged, setHasStateChanged, b_isRequired
            )
        } else {
            return isSpanPartValid(
                time, startTime, b_isStartTime, END_EMSG, setEndTime, setStartTimeErrorMsg, 
                setEndTimeErrorMsg, hasStateChanged, setHasStateChanged, b_isRequired
            )
        }
    }

    const collectTimePair = (obj_data) => {
        /* Update states. */
        if (obj_data.isStart) {
            setStartTime(obj_data.value)
            setHasStartTimeError(obj_data.hasError)
        } else {
            setEndTime(obj_data.value)
            setHasEndTimeError(obj_data.hasError)
        }

        /* Sent value has the data from e.target.value. This is necessary
         * as the setStartDate and setEndDate are too slow to update the 
         * state of the context provider real-time. */
        const arr_value = obj_data.isStart ? [obj_data.value, endTime] : [startTime, obj_data.value]
        const b_hasError = obj_data.isStart ? obj_data.hasError || hasEndTimeError : obj_data.hasError || hasStartTimeError
        handleInputChange({
            id: str_id,
            value: arr_value,
            hasError: b_hasError
        })
    }

    return (
        <>
            <TimeField
                str_fieldTitle={str_startTitle}
                str_id={str_id + '-start'}
                str_time={startTime}
                str_minTime={str_startMinTime}
                str_maxTime={str_startMaxTime}
                str_bottomInfoText={str_startBottomInfoText}
                str_errorMsg={startTimeErrorMsg}
                b_isRequired={b_isRequired}
                b_isSpanField={true}
                b_isStartTime={true}
                b_hasSpanChanged={hasStateChanged}
                fct_isTimeSpanPartValid={isTimeSpanValidWrapper}
                fct_sendDataToParent={collectTimePair}
            />
            <TimeField
                str_fieldTitle={str_endTitle}
                str_id={str_id + '-end'}
                str_time={endTime}
                str_minTime={str_endMinTime}
                str_maxTime={str_endMaxTime}
                str_bottomInfoText={str_endBottomInfoText}
                str_errorMsg={endTimeErrorMsg}
                b_isRequired={b_isRequired}
                b_isSpanField={true}
                b_isStartTime={false}
                b_hasSpanChanged={hasStateChanged}
                fct_isTimeSpanPartValid={isTimeSpanValidWrapper}
                fct_sendDataToParent={collectTimePair}
            />
        </>
    )
}

TimeSpanFields.propTypes = {
    str_id: PropTypes.string.isRequired,
    str_startTitle: PropTypes.string,
    str_endTitle: PropTypes.string,
    str_startTime: PropTypes.string,
    str_endTime: PropTypes.string,
    str_startMinTime: PropTypes.string,
    str_startMaxTime: PropTypes.string,
    str_endMinTime: PropTypes.string,
    str_endMaxTime: PropTypes.string,
    str_startBottomInfoText: PropTypes.string,
    str_endBottomInfoText: PropTypes.string,
    b_isRequired: PropTypes.bool
}

export default TimeSpanFields
