import React from 'react';
import PropTypes from 'prop-types';

const MessageBodyGroup = ({
    str_header,
    str_classes='',
    children
}) => {
    return (
        <div className={`msg-body-group ${str_classes}`}>
            <div className='header'>{str_header}</div>
            <div className='data'>
                {children}
            </div>
        </div>
    )
}

MessageBodyGroup.propTypes = {
    str_header: PropTypes.string.isRequired,
    str_classes: PropTypes.string
}

export default MessageBodyGroup
