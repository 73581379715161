import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import InputFormPageBase from '../../../components/input-forms/InputFormPageBase';
import InputFormCoursesCourseSwap from '../../../components/input-forms/forms/courses/InputFormCoursesCourseSwap';
import DataStateCheckWrapper from '../../../components/general/DataStateCheckWrapper';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import useConfigureDynInputFormData from '../../../custom-hooks/useConfigureDynInputFormData';
import { static2dynamicPanelConfig } from '../../../components/input-forms/util/input_form_data_handler';

import { CoursesCourseSwapFormData as FormData } from '../../../components/input-forms/util/form-config-data/InputFormCoursesDataConfigs';
import { FilterLsKeys } from '../../../util/LocalStorageVariables';
import { fetchLSdata, setLSdata } from '../../../util/db_ls_query_handler';

const DETAIL_BASE_URL = '/api/courses/course-swap/detail/'; /* Url to update an existing offer. */
const CREATE_URL = '/api/courses/course-swap/create/';      /* Url to create new offer. */
const PR_LS_KEY = FilterLsKeys.courseSwapMyOffers;

const genDetailUrl = (id) => {
    return DETAIL_BASE_URL + id + '/';
}

const dynConfSerializer = (data) => {
    /**
     * Serialize course swap offer.
     * The course swap target groups are stored as comma separated string in the LS.
     * However, the DB sends it as an array.
     * Make sure that the output is an array so that the MultipleChoiceField.js can
     * parse the data format.
     */
    const targetGroups = data.targetGroups;

    /* If data type is an array, the correct format is already in place. */
    if (Array.isArray(targetGroups)) return data;

    if (typeof(targetGroups) === 'string') {
        let alteredData = targetGroups.replaceAll(' ', '');
        const targetGroupsArr = alteredData.split(',');
        data.targetGroups = targetGroupsArr
        return data;
    }

    /* If data type is neither Array nor string, just return the data. */
    return data;
}

const CourseSwapFormPage = () => {

    const params = useParams()
    const [dynConfig, setDynConfig] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    const courseSwapOfferId = params.id

    useSetBrowserTabTitle('Kurstausch erstellen')

    useConfigureDynInputFormData(
        courseSwapOfferId,
        courseSwapOfferId ? genDetailUrl(courseSwapOfferId) : CREATE_URL,
        setDynConfig,
        setIsFirstFetchSuccess,
        setErrorMsg,
        courseSwapOfferId,
        '',
        '',
        '',
        dynConfSerializer
    )

    const storeItem2ls = (response) => {
        /**
         * Stores newly posted course swap offer to the LS.
         * If the LS entry does not yet exist, it creates a new LS entry.
         */
        const responseData = response.data
        const lsData = fetchLSdata(PR_LS_KEY)
        const newData = lsData ? [responseData, ...lsData] : [responseData]
        setLSdata(newData, PR_LS_KEY)
    }

    const editLsEntry = (response) => {
        /**
         * Updates the LS with the edited entry.
         * The updated data item is received from the backend.
         */
        const responseItem = response.data
        const responseItemId = responseItem.id
        const lsData = fetchLSdata(PR_LS_KEY)
        
        if (!lsData) {
            setLSdata([responseItem], PR_LS_KEY)
            return
        }

        let newData = []
        lsData.forEach(item => {
            if (item.id === responseItemId) {
                newData.push(responseItem)
            } else {
                newData.push(item)
            }
        })
        
        setLSdata(newData, PR_LS_KEY)
    }

    return (
        courseSwapOfferId
        ?
        /* Params in URL: Edit offer, make DB/LS request to prefill form. */
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            b_isContentSection={true}
            str_errorMsg={errorMsg}
            firstQueryDataEntry={-1} /* Placeholder value. */
        >
            <InputFormPageBase
                {...static2dynamicPanelConfig(FormData, dynConfig)}
                comp_form={<InputFormCoursesCourseSwap />}
                str_requestType='put'
                fct_handlePost={editLsEntry}
            />
        </DataStateCheckWrapper>
        :
        /* No params in URL, just render form. */
        <InputFormPageBase
            {...static2dynamicPanelConfig(FormData, dynConfig)}
            comp_form={<InputFormCoursesCourseSwap />}
            fct_handlePost={storeItem2ls}
        />
    )
}

export default CourseSwapFormPage
