import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import DataStateCheckWrapper from '../../../general/DataStateCheckWrapper';
import PPFormBase from '../PPFormBase';
import InputFormIndustryJob from '../../forms/industry/jobs/InputFormIndustryJob';

import {
    static2dynamicPanelConfig,
    db2inputFormData,
} from '../../util/input_form_data_handler';
import { fetchDBdata, fetchLSdata, fetchLSorDBdata, setLSdata } from '../../../../util/db_ls_query_handler';

import { IndustryJobFormData as FormData } from '../../util/form-config-data/InputFormIndustryDataConfigs';
import { PPLsKeys, CompanyLsKeys } from '../../../../util/LocalStorageVariables';
import StoredNumLimits from '../../../../util/stored_item_num_limits.json';
import AuthService from '../../../../util/authentication/auth_service';

const LIST_URL = '/api/industry/job/list/company-profile-page/';
const DETAIL_BASE_URL = '/api/industry/job/detail/company-profile-page/';

const PR_LS_KEY = PPLsKeys.primaryDataKey;
const SEC_LS_KEY = CompanyLsKeys.pp.jobPostings;

const OFFLINE_HEADER_INFO = 'Es kann auch eine ganz andere Stelle basierend auf dieser ' +
    'Vorlage erstellt werden. Die bereits ausgefüllten Daten stellen lediglich eine Hilfe dar. ' +
    'Der wiederverwendete Job bleibt auch erhalten und es wird zusätzlich eine neuer Job erstellt.';
const URL_CATEGORY_PARAMS = ['online', 'offline', 'scheduled'];

const MAX_NUM_OFFLINE_JOBS = StoredNumLimits.company.jobs.offline.max.num;
const MAX_NUM_OFFLINE_JOBS_REACHED_MSG = StoredNumLimits.company.jobs.offline.max.msg;
const MAX_NUM_ONLINE_SCHEDULED_JOBS = StoredNumLimits.company.jobs.onlineScheduled.max.num;
const MAX_NUM_ONLINE_SCHEDULED_JOBS_REACHED_MSG = StoredNumLimits.company.jobs.onlineScheduled.max.msg;

const genDetailUrl = (id) => {
    return DETAIL_BASE_URL + id + '/';
}

const genFormTitle = (str_category) => {
    /**
     * Select a form title depending on the URL category (new, edit, reuse).
     */
    if (!str_category) {
        return 'Neuer Job'
    }
    else if (str_category === 'online') {
        return 'Edit online Job'
    }
    else if (str_category === 'scheduled') {
        return 'Edit geplanten Job'
    }
    else if (str_category === 'offline') {
        return 'Wiederverwende Job'
    }
}

const JobForm = () => {

    const [dynConfig, setDynConfig] = useState('')
    const [formConfig, setFormConfig] = useState({})
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    const params = useParams()
    let navigate = useNavigate()
    const requestTypeRef = useRef('')

    useEffect(() => {
        /**
         * Configure the input props of the input form as well as the dynamic config data.
         */
        const queryFormData = async () => {
            /* Check if URL category exists. */
            const str_category = params.category
            const b_urlExists = str_category === undefined ? true :
                                URL_CATEGORY_PARAMS.includes(str_category) ? true : false
            if (!b_urlExists) {
                /* Use isFirstFetchSuccess state to display the error message on the page. */
                setIsFirstFetchSuccess(false)
                setErrorMsg('Diese Seite existiert nicht.')
                return
            }
            
            let jobData = null
            if (URL_CATEGORY_PARAMS.includes(str_category)) {
                /* A category is featured in the URL, the user wants to edit or reuse
                * a job, so query the data of that job. */
               document.title = `@${AuthService.getUsername()} - Bearbeite Job | MUPLAZA`
                const jobId = params.jobId
                const url = genDetailUrl(jobId)
                const queryData = await fetchDBdata(url)
                if (queryData.isQuerySuccess) {
                    jobData = queryData.response.data
                    const obj_dynConfig = configDynObj(jobData)
                    const obj_formConfig = configInputFormProps(obj_dynConfig, str_category)
                    setDynConfig(obj_dynConfig)
                    setFormConfig(obj_formConfig)
                    setIsFirstFetchSuccess(true)
                } else {
                    setIsFirstFetchSuccess(false)
                    setErrorMsg(queryData.errorMsg)
                }
            } else {
                /* New blank job form is rendered. isFirstFetchSuccess must be true to render it. */
                document.title = `@${AuthService.getUsername()} - Neuer Job | MUPLAZA`
                setIsFirstFetchSuccess(true)
                setDynConfig(configDynObj(jobData))
            }
        }

        const func = async () => {
            const queryData = await fetchLSorDBdata(LIST_URL, PR_LS_KEY, SEC_LS_KEY, '')
            if (queryData.isQuerySuccess) {
                const data = queryData.response.data;
                /* Store data in LS as user has to delete jobs at this point anyway. */
                setLSdata(data, PR_LS_KEY, SEC_LS_KEY)
                
                /* Check offline jobs limit. */
                const numOfflineJobs = data.offline.length
                if (!(numOfflineJobs < MAX_NUM_OFFLINE_JOBS)) {
                    setErrorMsg(MAX_NUM_OFFLINE_JOBS_REACHED_MSG)
                    setIsFirstFetchSuccess(false)
                    return
                }
                
                /* Check online and scheduled jobs limit. */
                const numOnlineAndScheduledJobs = data.online.length + data.scheduled.length
                if (!(numOnlineAndScheduledJobs < MAX_NUM_ONLINE_SCHEDULED_JOBS)) {
                    setErrorMsg(MAX_NUM_ONLINE_SCHEDULED_JOBS_REACHED_MSG)
                    setIsFirstFetchSuccess(false)
                    return
                }
    
                queryFormData()
            } else {
                setIsFirstFetchSuccess(false)
                setErrorMsg(queryData.errorMsg)
            }
        }

        func()
    }, [navigate])

    const dbData2FormData = (obj_dbJobData) => {
        /**
         * Serialize DB format to input form format.
         */
        const arrayFields = ['applicationDocs', 'thesisOptions']
        const separator = ', '
        let obj_jobFormData = db2inputFormData(obj_dbJobData, arrayFields, separator)
        /* Adapt dyn. config jobData if an offline job is reused. */
        if (params.category === 'offline') {
            /* Add date span field. */
            obj_jobFormData['postDeadlineDates'] = FormData.obj_initState.postDeadlineDates
            /* Clear startDate of queried offline job. */
            obj_jobFormData['startDate'] = FormData.obj_initState.startDate
        }
        return obj_jobFormData
    }

    const configDynObj = (jobData) => {
        /**
         * Configure the dyn. configuration object to overwrite the static config where necessary.
         * :Input
         *  jobData (obj): Data queried from DB.
         */
        if (jobData === null) {
            /* Empty form for new job is rendered. */
            requestTypeRef.current = 'post'
            return {'str_queryURL': LIST_URL}
        }

        let obj_dynConfig = {}
        
        if (params.category === 'offline') {
            obj_dynConfig['str_queryURL'] = LIST_URL
            requestTypeRef.current = 'post'
        } else {
            obj_dynConfig['str_queryURL'] = genDetailUrl(params.jobId)
            requestTypeRef.current = 'put'
        }
        obj_dynConfig['obj_initState'] = dbData2FormData(jobData)
        
        return obj_dynConfig
    }

    const configInputFormProps = (obj_dynConfig, str_category) => {
        /**
         * Configure input form parameters as an object.
         */
        return {
            str_title: genFormTitle(str_category),
            /* Use job title as subtitle. */
            str_subtitle: str_category ? obj_dynConfig.obj_initState.title[0] : '',
            arr_headerInfos: str_category === 'offline' ? [OFFLINE_HEADER_INFO] : []
        }
    }

    /* Handle form post. */

    const formPost = (response) => {
        /**
         * Does one of 2 things:
         * 1) Adds newly created job to job postings in the LS.
         * 2) Overwrites edited job in the LS.
         */
        /* Fetch data from LS. */
        let jobData, lsKey
        const responseData = response.data
        if (responseData.online !== undefined) {
            jobData = responseData.online
            lsKey = 'online'
        } else if (responseData.scheduled !== undefined) {
            jobData = responseData.scheduled
            lsKey = 'scheduled'
        } else {
            /* Only online and scheduled jobs can be created/updated. */
            return
        }
        let lsData = fetchLSdata(PR_LS_KEY, SEC_LS_KEY, lsKey)

        /* Check if job is edited, i.e. if its id can be found in the stored LS items. */
        let i = 0
        const limit = lsData.length
        let isFound = false
        for (i; i < limit; i++) {
            if (lsData[i].id === jobData.id) {
                isFound = true
                break
            }
        }

        /* Update or add job. */
        if (isFound) {
            lsData[i] = jobData
        } else {
            lsData = [jobData, ...lsData]
        }
        setLSdata(lsData, PR_LS_KEY, SEC_LS_KEY, lsKey)
    }

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            firstQueryDataEntry={-1} /* Only a placeholder to render the children. */
            str_errorMsg={errorMsg}
            b_isContentSection={true}
        >
            <PPFormBase
                {...static2dynamicPanelConfig(FormData, dynConfig)}
                formChild={<InputFormIndustryJob {...formConfig} />}
                str_requestType={requestTypeRef.current}
                b_hasMediaData={true}
                fct_response2parent={formPost}
            />
        </DataStateCheckWrapper>
    )
}

export default JobForm
