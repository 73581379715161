import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';

import PageBase from '../../components/page-layout/PageBase';
import CSecGridBase from '../content-sections/CSecGridBase';
import InputFormContextProvider from '../../components/input-forms/context-provider/InputFormContextProvider';
import FormSuccessErrorMessage from './FormSuccessErrorMessage';

import AuthService from '../../util/authentication/auth_service';
import RegistrationProgressLadderCreator from './profile-pages/util/RegistrationProgressLadderCreator';

const InputFormPageBase = ({
    str_secBaseTitle='',
    str_localStorageKey,
    str_queryURL,
    str_requestType,
    str_navigationURLonDiscard,
    str_gridClass='', /* If used, the process ladder grid item is displayed. */
    b_hasMediaData=false,
    b_hasDiscardBtn=true,
    obj_initState,
    fct_handlePost, /* Act on successful data submission via the InputFormContextProvider.js. */
    comp_form,
    comp_header,
    comp_footer
}) => {

    const params = useParams()
    const navigate = useNavigate()
    const [progressLadder, setProgressLadder] = useState([])
    const [numCurrentStep, setNumCurrentStep] = useState(1)
    const [isDataPosted, setIsDataPosted] = useState(false)

    useEffect(() => {
        /**
         * Adjust the progress ladder on every
         */
        if (str_gridClass) {
            const usertype = AuthService.getUsertype()
            setProgressLadder(RegistrationProgressLadderCreator.progressLadder(usertype))
        }
    }, [])

    useEffect(() => {
        /**
         * Set the number of the current step.
         */
        if (str_gridClass) {
            const paramCurrentStep = params.setupStep
            Object.values(progressLadder).forEach((item, index) => {
                if (paramCurrentStep === item.urlParam) setNumCurrentStep(index+1)
            })
        }
    }, [navigate, progressLadder])

    const post = (response) => {
        setIsDataPosted(true)
        if (fct_handlePost) fct_handlePost(response)
    }

    return (
        <PageBase str_pageSpacingClasses='page-spacing-input-form-page'>
            {comp_header}

            <CSecGridBase
                str_title={str_secBaseTitle}
                str_gridClass={str_gridClass}
            >
                {
                    str_gridClass &&
                    <div className="progress-ladder-grid-item">
                    {
                        progressLadder.map((item, index) => (
                            <div
                                key={index}
                                className={
                                    item.stepIndex < numCurrentStep
                                    ?
                                    'done-step'
                                    :
                                    item.stepIndex > numCurrentStep
                                    ?
                                    'todo-step'
                                    :
                                    'doing-step'
                                }
                            >
                                {item.stepTitle}
                            </div>
                        ))
                    }
                    </div>
                }
                <div className={str_gridClass ? 'form-grid-item' : ''}>
                {
                    isDataPosted
                    ?
                    <FormSuccessErrorMessage b_isSuccess={true} />
                    :
                    <InputFormContextProvider
                        str_localStorageKey={str_localStorageKey}
                        str_queryURL={str_queryURL}
                        str_requestType={str_requestType}
                        str_navigationURLonDiscard={str_navigationURLonDiscard}
                        b_hasDiscardBtn={b_hasDiscardBtn}
                        b_hasMediaData={b_hasMediaData}
                        obj_initState={obj_initState}
                        fct_response2parent={post}
                    >
                        {comp_form}
                    </InputFormContextProvider>
                }
                </div>
            </CSecGridBase>

            {comp_footer}
        </PageBase>
    )
}

InputFormPageBase.propTypes = {
    str_secBaseTitle: PropTypes.string,
    str_localStorageKey: PropTypes.string.isRequired,
    str_queryURL: PropTypes.string.isRequired,
    str_requestType: PropTypes.string.isRequired,
    str_navigationURLonDiscard: PropTypes.string.isRequired,
    str_gridClass: PropTypes.string,
    b_hasDiscardBtn: PropTypes.bool,
    b_hasMediaData: PropTypes.bool,
    obj_initState: PropTypes.object.isRequired,
    fct_handlePost: PropTypes.func
}

export default InputFormPageBase
