/**
 * This file contains configs of obj_initState and fields for 
 * for data that is relevant for multiple forms. 
 */

import FieldLimits from '../db_field_limits.json';
import BottomTexts from '../input_form_bottom_info_texts.json';
import { genFieldData } from './InputFormBaseDataAndSerializer';

/* All templates start with T (e.g. TDummyTemplate). */

////////////////////////////////////////////////////////////////////////
/* Template fields. */

/* Person info. */

const TFfirstName = genFieldData('text', {
    str_id: 'firstName',
    str_fieldTitle: 'Vorname',
    b_isTextarea: false,
    int_maxLength: FieldLimits.name.firstName
});

const TFlastName = genFieldData('text', {
    str_id: 'lastName',
    str_fieldTitle: 'Nachname',
    b_isTextarea: false,
    int_maxLength: FieldLimits.name.lastName
});

/* Address. */

const TFstreetName = genFieldData('text', {
    str_id: 'streetName',
    str_fieldTitle: 'Straße',
    b_isTextarea: false,
    int_maxLength: FieldLimits.address.streetName
});

const TFstreetNum = genFieldData('text', {
    str_id: 'streetNum',
    str_fieldTitle: 'Hausnummer',
    b_isTextarea: false,
    int_maxLength: FieldLimits.address.streetNum
});

const TFpostalCode = genFieldData('text', {
    str_id: 'postalCode',
    str_fieldTitle: 'Postleitzahl',
    b_isTextarea: false,
    int_maxLength: FieldLimits.address.postalCode
});

const TFcityName = genFieldData('text', {
    str_id: 'cityName',
    str_fieldTitle: 'Stadt',
    b_isTextarea: false,
    int_maxLength: FieldLimits.address.cityName
});

const TFcountry = genFieldData('text', {
    str_id: 'country',
    str_fieldTitle: 'Staat',
    b_isTextarea: false,
    int_maxLength: FieldLimits.address.countryName
});

/* Contact. */

const TFemail = genFieldData('email', {
    str_id: 'email',
    str_fieldTitle: 'Email',
    b_isTextarea: false,
    b_isEmail: true,
    int_maxLength: FieldLimits.contact.email
});

const TFphone = genFieldData('text', {
    str_id: 'phone',
    str_fieldTitle: 'Telefon',
    b_isTextarea: false,
    b_isPhone: true,
    int_maxLength: FieldLimits.contact.phone,
    str_bottomInfoText: BottomTexts.contact.phone
});

const TFwebpage = genFieldData('text', {
    str_id: 'webpage',
    str_fieldTitle: 'Webseite',
    b_isTextarea: false,
    b_isLink: true,
    b_isRequired: false,
    int_maxLength: FieldLimits.contact.url
});

/* Social media. */

const TFlinkedin = genFieldData('text', {
    str_id: 'linkedin',
    str_fieldTitle: 'LinkedIn',
    b_isTextarea: false,
    b_isLink: true,
    b_isRequired: false,
    int_maxLength: FieldLimits.contact.url
});

const TFtwitter = genFieldData('text', {
    str_id: 'twitter',
    str_fieldTitle: 'Twitter',
    b_isTextarea: false,
    b_isLink: true,
    b_isRequired: false,
    int_maxLength: FieldLimits.contact.url
});

const TFfacebook = genFieldData('text', {
    str_id: 'facebook',
    str_fieldTitle: 'Facebook',
    b_isTextarea: false,
    b_isLink: true,
    b_isRequired: false,
    int_maxLength: FieldLimits.contact.url
});

const TFinstagram = genFieldData('text', {
    str_id: 'instagram',
    str_fieldTitle: 'Instagram',
    b_isTextarea: false,
    b_isLink: true,
    b_isRequired: false,
    int_maxLength: FieldLimits.contact.url
});

const TFtiktok = genFieldData('text', {
    str_id: 'tiktok',
    str_fieldTitle: 'TikTok',
    b_isTextarea: false,
    b_isLink: true,
    b_isRequired: false,
    int_maxLength: FieldLimits.contact.url
});

/* User. */

const TFcompanyName = genFieldData('text', {
    str_id: 'companyName',
    str_fieldTitle: 'Unternehmen',
    b_isTextarea: false,
    int_maxLength: FieldLimits.user.company.companyName
});

const TFgastronomerName = genFieldData('text', {
    str_id: 'gastronomerName',
    str_fieldTitle: 'Geschäftsname',
    b_isTextarea: false,
    int_maxLength: FieldLimits.user.gastronomer.name
});

const TFassociateUsername = genFieldData('text', {
    str_id: 'associateUsername',
    str_fieldTitle: 'Anderer Firmenaccount',
    str_bottomInfoText: BottomTexts.registration.associateUsername,
    b_isTextarea: false,
    b_isRequired: false,
    int_maxLength: FieldLimits.user.username
});

/* Work. */

const TFjobPosition = genFieldData('text', {
    str_id: 'jobPosition',
    str_fieldTitle: 'Jobposition',
    b_isTextarea: false,
    int_maxLength: FieldLimits.work.jobPosition
});

const TFworkLocation = genFieldData('text', {
    str_id: 'workLocation',
    str_fieldTitle: 'Standort',
    b_isTextarea: false,
    int_maxLength: FieldLimits.work.workLocation,
    str_bottomInfoText: BottomTexts.work.workLocation
});

export const TemplateFields = {
    person: {
        firstName: TFfirstName,
        lastName: TFlastName
    },
    address: {
        streetName: TFstreetName,
        streetNum: TFstreetNum,
        postalCode: TFpostalCode,
        cityName: TFcityName,
        country: TFcountry
    },
    contact: {
        email: TFemail,
        phone: TFphone,
        webpage: TFwebpage
    },
    socialMedia: {
        linkedin: TFlinkedin,
        twitter: TFtwitter,
        facebook: TFfacebook,
        instagram: TFinstagram,
        tiktok: TFtiktok
    },
    user: {
        company: {
            name: TFcompanyName,
            associateUsername: TFassociateUsername
        },
        gastronomer: {
            name: TFgastronomerName,
            associateUsername: TFassociateUsername
        }
    },
    work: {
        jobPosition: TFjobPosition,
        workLocation: TFworkLocation
    }
};