import React from 'react';

import FormBaseWelcome from '../../FormBaseWelcome';
import InFieldsGroup from '../../InFieldsGroup';
import TextField from '../../fields/TextField';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { IndustryUserRegistrationFormData as FormData } from '../../util/form-config-data/InputFormWelcomeDataConfigs';
import { errorMsgs } from '../../../welcome/util/welcome_util';

const InputFormIndustryUserRegistration = () => {
    const data = useInputData()
    return (
        <FormBaseWelcome
            str_btnTitle='registrieren'
            str_submitErrorMsg={errorMsgs.registration}
            b_isRequiredInfoShown={true}
        >
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.companyName}
                    str_chars={data[FormData.fields.companyName.str_id][0]}
                />
                <TextField
                    {...FormData.fields.firstName}
                    str_chars={data[FormData.fields.firstName.str_id][0]}
                />
                <TextField
                    {...FormData.fields.lastName}
                    str_chars={data[FormData.fields.lastName.str_id][0]}
                />
                <TextField
                    {...FormData.fields.phone}
                    str_chars={data[FormData.fields.phone.str_id][0]}
                />
                <TextField
                    {...FormData.fields.email}
                    str_chars={data[FormData.fields.email.str_id][0]}
                />
                <TextField
                    {...FormData.fields.webpage}
                    str_chars={data[FormData.fields.webpage.str_id][0]}
                />
                <TextField
                    {...FormData.fields.associateUsername}
                    str_chars={data[FormData.fields.associateUsername.str_id][0]}
                />
                <TextField
                    {...FormData.fields.acquirerUsername}
                    str_chars={data[FormData.fields.acquirerUsername.str_id][0]}
                />
            </InFieldsGroup>
        </FormBaseWelcome>
    )
}

export default InputFormIndustryUserRegistration
    