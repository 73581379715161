import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import InputFormContextProvider from '../input-forms/context-provider/InputFormContextProvider';
import InputFormPasswordReset from '../input-forms/forms/welcome/InputFormPasswordReset';
import FormSuccessErrorMessage from '../input-forms/FormSuccessErrorMessage';

import { PasswordConfirmFormData as FormData } from '../input-forms/util/form-config-data/InputFormPasswordDataConfigs';
import { static2dynamicPanelConfig } from '../input-forms/util/input_form_data_handler';
import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const URL = '/api/profiles/password/reset/';

const PwReset = () => {

    const [isPosted, setIsPosted] = useState(false)
    const [isPostSuccess, setIsPostSuccess] = useState()
    const [msg, setMsg] = useState('')
    const params = useParams()
    
    useSetBrowserTabTitle('Neues Passwort')

    /* Use URL hash value to complete the form data configuration. */
    const dynConfig = {
        obj_initState: {
            hash: [params.hash, false]
        }
    }

    const posted = (response) => {
        if (response.status === 200) {
            setIsPostSuccess(true)
        } else {
            setIsPostSuccess(false)
        }
        setMsg(response.data.message)
        setIsPosted(true)
    }

    return (
        isPosted
        ?
        <FormSuccessErrorMessage
            b_isSuccess={isPostSuccess}
            str_errorMsg={msg}
            str_successMsg={msg}
        />
        :
        <InputFormContextProvider
            {...static2dynamicPanelConfig(FormData, dynConfig)}
            str_requestType='post'
            str_queryURL={URL}
            b_isPrivatePost={false}
            b_hasDiscardBtn={false}
            fct_response2parent={posted}
        >
            <InputFormPasswordReset />
        </InputFormContextProvider>
    )
}

export default PwReset
