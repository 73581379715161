import React from 'react';
import PropTypes from 'prop-types';

import CardPriceTag from '../CardPriceTag';
// import CardFooterRedeemButton from '../CardFooterRedeemButton';

const CardCompanyAcquisitionFooter = ({
    nbr_id,
    nbr_price,
    b_isRedeemed,
    b_isRedeemSuccess,
    str_redeemErrorMsg,
    str_redeemTimestamp,
    str_redeemUserFullName,
    b_hasExpired,
    b_hasModalView,
    fct_redeemCoupon
}) => {
    return (
        <div className='card-footer'>
            <CardPriceTag
                nbr_price={nbr_price}
                nbr_decimals={0}    
            />
            {
                // <CardFooterRedeemButton
                //     nbr_id={nbr_id}
                //     b_isRedeemed={b_isRedeemed}
                //     b_isRedeemSuccess={b_isRedeemSuccess}
                //     str_redeemTimestamp={str_redeemTimestamp}
                //     str_redeemErrorMsg={str_redeemErrorMsg}
                //     str_redeemUserFullName={str_redeemUserFullName}
                //     b_hasExpired={b_hasExpired}
                //     b_hasModalView={b_hasModalView}
                //     b_isCoupon={true}
                //     fct_handleRedeem={fct_redeemCoupon}
                // />
            }
            {
                // !b_hasExpired &&
                // <div className='redeem-info-msg'>
                //     {
                //         b_isRedeemed
                //         ?
                //         <p>Eingelöst&nbsp;am {str_redeemTimestamp}</p> 
                //         :
                //         <p>Einlösbar im Rathaus</p>
                //     }
                // </div>
            }
        </div>
    )
}

CardCompanyAcquisitionFooter.propTypes = {
    nbr_id: PropTypes.number.isRequired,
    nbr_price: PropTypes.number.isRequired,
    b_isRedeemed: PropTypes.bool.isRequired,
    str_redeemErrorMsg: PropTypes.string,
    b_isRedeemSuccess: PropTypes.bool.isRequired,
    str_redeemTimestamp: PropTypes.string.isRequired,
    str_redeemUserFullName: PropTypes.string,
    b_hasExpired: PropTypes.bool,
    b_hasModalView: PropTypes.bool.isRequired,
    fct_redeemCoupon: PropTypes.func
}

export default CardCompanyAcquisitionFooter
