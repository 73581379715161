import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import useGetLSorDBdata from '../../../custom-hooks/useGetLSorDBdata';
import { genDynUrl } from '../../../util/db_ls_query_handler';

const BASE_URL = '/api/courseexams/list/course/';

const TbExamsYearListing = () => {

    const params = useParams()
    const [data, setData] = useState([])
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    
    const url = genDynUrl(BASE_URL, params.id)
    useGetLSorDBdata(url, setData, setIsFirstFetchSuccess, setErrorMsg)

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            str_errorMsg={errorMsg}
            firstQueryDataEntry={-1} /* Placeholder, not data is not possible in this case. */
        >
            <div className="tb-wrapper">
                <table cellSpacing="0" className="tb-base">
                    <thead>
                        <tr>
                            <td>Jahr</td>
                            <td>Prüfungen</td>
                            <td>Fragen</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.keys(data).reverse().map((year, index) => (
                                <tr key={index}>
                                    <td>
                                        <Link
                                            to={`${year}`}
                                            className="file-link"
                                        >
                                            {year}
                                        </Link>
                                    </td>
                                    <td>{data[year].numExams}</td>
                                    <td>{data[year].numQuestions}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </DataStateCheckWrapper>
    )
}

export default TbExamsYearListing
