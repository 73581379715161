import React from 'react';

import PrivatePage from '../../network/PrivatePage';
import PPMainContentBase from '../PPMainContentBase';
import DynamicContentLoadContextProvider from '../../context-provider/DynamicContentLoadContextProvider';
import StudentPPThreadMessages from './StudentPPThreadMessages';
import FilterStdTextField from '../../filters/filters/std/FilterStdTextField';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import useRemoveLSitemsUnmount from '../../../custom-hooks/useRemoveLSitemsUnmount';

import Usertypes from '../../../util/Usertypes';
import DynLoadPackageSizes from '../../../util/config/data_load_package_sizes.json';
import { FilterLsKeys } from '../../../util/LocalStorageVariables';

const URL = '/api/courseforums/profile-page/student/list/';
const LS_KEY = FilterLsKeys.studentsForumThreads;
const ALLOWED_USERTYPES = [Usertypes.student];

const StudentPPCourseThreads = () => {
    useSetBrowserTabTitle('Threads', true)
    useRemoveLSitemsUnmount(LS_KEY)
    return (
        <PrivatePage arr_usertypes={ALLOWED_USERTYPES}>
            <PPMainContentBase
                str_header='Meine Threads'
                str_subHeader='Liste deiner erstellten Forum-Threads'
            >
                <DynamicContentLoadContextProvider
                    str_queryURL={URL}
                    nbr_packageSize={DynLoadPackageSizes.courseforums.forumMsgsStudentPP}
                    b_useLS={true}
                    str_lsKey={LS_KEY}
                >
                    <FilterStdTextField
                        str_filterFieldName='Textsuche'
                        b_isTransparent={true}
                        b_hasMarginBottom={true}
                    />
                    <StudentPPThreadMessages />
                </DynamicContentLoadContextProvider>
            </PPMainContentBase>
        </PrivatePage>
    )
}

export default StudentPPCourseThreads
