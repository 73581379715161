/**
 * This file contains all the configs for the welcome input forms:
 * Login, registration (for different users), password reset, new password.
 */
import FieldLimits from '../db_field_limits.json';
import BottomTexts from '../input_form_bottom_info_texts.json';
import { TemplateFields } from './InputFormTemplateConfigs';
import { genFieldData } from './InputFormBaseDataAndSerializer';

export const LoginFormData = {
    str_localStorageKey: '',
    str_queryURL: '/login',
    str_navigationURLonDiscard: '',
    obj_initState: {
        username: ['', true],
        password: ['', true]
    },
    fields: {
        username: genFieldData('text', {
            str_id: 'username',
            str_fieldTitle: 'E-Mail-Adresse / Username',
            b_hasNumCharsDisplay: false,
            b_isTextarea: false,
            int_maxLength: FieldLimits.contact.email
        }),
        password: genFieldData('text', {
            str_id: 'password',
            str_fieldTitle: 'Passwort',
            b_hasNumCharsDisplay: false,
            b_isTextarea: false,
            b_isPassword: true,
            b_isPwErrorDisplayed: false,
            int_minLength: FieldLimits.password.fakeLength.min,
            int_maxLength: FieldLimits.password.fakeLength.max
        })
    }
};

export const StudentUserRegistrationFormData = {
    str_localStorageKey: '',
    str_queryURL: '/registration/student',
    str_navigationURLonDiscard: '',
    obj_initState: {
        emailName: ['', true]
    },
    fields: {
        emailName: genFieldData('text', {
            str_id: 'emailName',
            str_fieldTitle: 'Email',
            str_placeholder: 'max.mustermann',
            str_bottomInfoText: BottomTexts.registration.studentMsg,
            b_isTextarea: false,
            int_maxLength: FieldLimits.contact.emailName
        })
    }
};

export const IndustryUserRegistrationFormData = {
    str_localStorageKey: '',
    str_queryURL: '/registration/industry',
    str_navigationURLonDiscard: '',
    obj_initState: {
        companyName:       ['', true],
        firstName:         ['', true],
        lastName:          ['', true],
        phone:             ['', true],
        email:             ['', true],
        webpage:           ['', false],
        associateUsername: ['', false],
        acquirerUsername:  ['', false]
    },
    fields: {
        companyName: TemplateFields.user.company.name,
        firstName: TemplateFields.person.firstName,
        lastName: TemplateFields.person.lastName,
        email: TemplateFields.contact.email,
        phone: TemplateFields.contact.phone,
        webpage: TemplateFields.contact.webpage,
        associateUsername: TemplateFields.user.company.associateUsername,
        acquirerUsername: genFieldData('text', {
            str_id: 'acquirerUsername',
            str_fieldTitle: 'Empfehler (Studierender)',
            str_bottomInfoText: BottomTexts.registration.acquirerUsername,
            b_isTextarea: false,
            b_isRequired: false,
            int_maxLength: FieldLimits.user.username
        })
    }
};

export const GastronomyUserRegistrationFormData = {
    str_localStorageKey: '',
    str_queryURL: '/registration/gastronomy',
    str_navigationURLonDiscard: '',
    obj_initState: {
        gastronomerName: ['', true],
        firstName:       ['', true],
        lastName:        ['', true],
        phone:           ['', true],
        email:           ['', true],
        webpage:         ['', false]
    },
    fields: {
        gastronomerName: TemplateFields.user.gastronomer.name,
        firstName: TemplateFields.person.firstName,
        lastName: TemplateFields.person.lastName,
        email: TemplateFields.contact.email,
        phone: TemplateFields.contact.phone,
        webpage: TemplateFields.contact.webpage
    }
};

export const PasswordForgotFormData = {
    str_localStorageKey: '',
    str_queryURL: '/password/forgot',
    str_navigationURLonDiscard: '',
    obj_initState: {
        email: ['', true]
    },
    fields: {
        email: {
            ...TemplateFields.contact.email,
            str_bottomInfoText: BottomTexts.password.email,
            b_hasNumCharsDisplay: false
        }
    }
};