/**
 * This component queries the Db on mount to retrieve the available
 * category options that the user can select on the front-end.
 * 
 * This is done to ensure that the user cannot upload more than a
 * specified number of files in total, and neither for specific
 * categories.
 * 
 * There are 2 categories (check the models.py file for more info).
 * These 2 categories are not the same as the file category and are
 * referred to as upload categories.
 */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import DataStateCheckWrapper from '../../../components/general/DataStateCheckWrapper';
import FormSuccessErrorMessage from '../../../components/input-forms/FormSuccessErrorMessage';
import CSecBase from '../../../components/content-sections/CSecBase';
import InputFormCourseDocs from '../../../components/input-forms/forms/courses/InputFormCourseDocs';
import InputFormContextProvider from '../../../components/input-forms/context-provider/InputFormContextProvider';

import { CoursesDocsFormData as FormData } from '../../../components/input-forms/util/form-config-data/InputFormCoursesDataConfigs';
import useGetDBdata from '../../../custom-hooks/useGetDBdata';
import useConfigureDynInputFormData from '../../../custom-hooks/useConfigureDynInputFormData';
import { useAddDocContext } from '../../../components/sidebars/context-provider/SbCourseDocsContextProvider';
import { useAddDoc2categoryContext } from '../../../components/context-provider/CourseDocsCacheContextProvider';
import { static2dynamicPanelConfig } from '../../../components/input-forms/util/input_form_data_handler';
import { genDynUrl } from '../../../util/db_ls_query_handler';

const BASE_URL = '/api/coursedocs/new/';
const SUCCESS_MSG = 'Die Datei wurde erfolgreich hochgeladen. Danke für deinen Beitrag!';

const serializer = (data) => {
    /**
     * Extracts the categories of document types from the response data,
     * sorts it in alphabetical order, and returns it.
     */
    let categories = data['category'];
    categories.sort();
    return categories;
}

const DocsNewPage = () => {

    const params = useParams()
    const [isPosted, setIsPosted] = useState(false)
    const [dynConfig, setDynConfig] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    const [categoryOptions, setCategoryOptions] = useState([])

    const addDoc2sb = useAddDocContext()
    const addDoc2cache = useAddDoc2categoryContext()

    const url = genDynUrl(BASE_URL, params.id)
    useConfigureDynInputFormData(false, url, setDynConfig)

    useGetDBdata(url, setCategoryOptions, setIsFirstFetchSuccess, setErrorMsg, serializer)

    const posted = (response) => {
        addDoc2sb(response.data)
        addDoc2cache(response.data)
        setIsPosted(true)
    }

    return (
        <CSecBase>
            {
                isPosted
                ?
                <FormSuccessErrorMessage
                    b_isSuccess={true}
                    str_successMsg={SUCCESS_MSG}    
                />
                :
                <DataStateCheckWrapper
                    b_hasQueryCheck={true}
                    b_isFirstFetchSuccess={isFirstFetchSuccess}
                    str_errorMsg={errorMsg}
                    firstQueryDataEntry={-1}
                >
                    <InputFormContextProvider
                        {...static2dynamicPanelConfig(FormData, dynConfig)}
                        b_hasMediaData={true}
                        fct_response2parent={posted}
                    >
                        <InputFormCourseDocs arr_categoryOptions={categoryOptions} />
                    </InputFormContextProvider>
                </DataStateCheckWrapper>
            }
        </CSecBase>
    )
}

export default DocsNewPage
