import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ClipLoader from "react-spinners/ClipLoader";

import {
    useHandleDiscardSubmitFct,
    useHasFormDiscardBtn,
    useHasInputError,
    useRequestErrorMsg,
    useIsLoading
} from './context-provider/InputFormContextProvider';

import {
    useIsConfirmationViewOpenContext,
    useSetIsConfirmationViewOpenContext
} from './context-provider/FormBaseContextProvider';

import AuthService from '../../util/authentication/auth_service';

const InputFormSubmitButton = ({
    str_btnTitle='Eingabe',
    str_btnSize='', /* Vary button sizes according to input form. */
    comp_helpMsg='' /* Help message component for helper text. */
}) => {
    
    const [isHelpMsgOpen, setIsHelpMsgOpen] = useState()
    const [spinBtnStyle, setSpinBtnStyle] = useState('')

    const handleDiscardSubmit = useHandleDiscardSubmitFct()
    const hasDiscardBtn = useHasFormDiscardBtn()
    const isConfirmationViewOpen = useIsConfirmationViewOpenContext()
    const setIsConfirmationViewOpen = useSetIsConfirmationViewOpenContext()
    const hasInputError = useHasInputError()
    const requestErrorMsg = useRequestErrorMsg()
    const isLoading = useIsLoading()

    const discardSubmit = (b_isSubmit, e) => {
        /**
         * Handles the discard submit behaviour of input forms.
         * :Input
         *  b_isSubmit: Either true (submit clicked) or false (discard clicked).
         *  e (event)
         */
        e.preventDefault()

        /* If no confirmation view is used, submit normally. */
        if (!setIsConfirmationViewOpen) {
            handleDiscardSubmit(b_isSubmit, e)
            return
        }

        if (isConfirmationViewOpen) {
            if (b_isSubmit) {
                handleDiscardSubmit(b_isSubmit, e)
            } else {
                setIsConfirmationViewOpen(false) /* Clicked discard. */
            }
        } else {
            /* Confirmation view closed. */
            if (b_isSubmit) {
                if (hasInputError()) return
                setIsConfirmationViewOpen(true)
                window.scrollTo(0, 0)
            } else {
                handleDiscardSubmit(b_isSubmit, e)
            }
        }
    }

    useEffect(() => {
        /**
         * Extracts the height and width props of the submit button and
         * applies them to the button that is displayed when the form is submitted
         * and the loading spinner is displayed. This ensures that the laoding
         * spinner button does always have the same dimensions as the
         * button that displays e.g. Login or Submit.
         */
        const btn = document.querySelector('.btn-submit')
        const btnRect = btn.getBoundingClientRect()
        const btnHeight = btnRect.height
        const btnWidth = btnRect.width
        const style = {
            height: btnHeight,
            width: btnWidth
        }
        setSpinBtnStyle(style)
    }, [])

    return (
        <>
            {
                requestErrorMsg &&
                <div className="in-form-request-error">
                    <p>{requestErrorMsg}</p>
                </div>
            }
            <div className="in-footer">
                {
                    comp_helpMsg
                    ?
                    <div tabIndex={0} className="help" onClick={() => { setIsHelpMsgOpen(!isHelpMsgOpen) }}>
                        {isHelpMsgOpen ? 'hilfe schließen' : 'hilfe'}
                    </div>
                    :
                    <div></div> /* This placeholder makes sure that buttons are on the right. */
                }
                <div className="in-btn-submit">
                    {/* The first button in the form is clicked if the form is submit by hitting enter. */}
                    <button
                        className="in-form-btn-submit"
                        onClick={(e) => e.preventDefault()}
                    />

                    {/* Buttons the user interacts with. */}
                    {
                        hasDiscardBtn && !isLoading &&
                        <button
                            className={`btn ${str_btnSize ? str_btnSize : 'btn-sm'} btn-discard`}
                            onClick={(e) => discardSubmit(false, e)}
                        >
                            {isConfirmationViewOpen ? 'zurück' : 'verwerfen'}
                        </button>
                    }
                    {
                        isLoading
                        ?
                        <button
                            className={`btn ${str_btnSize ? str_btnSize : 'btn-sm'} btn-submit`}
                            style={spinBtnStyle}
                        >
                            <ClipLoader
                                loading={isLoading}
                                color={AuthService.hasDarkTheme() ? '#1a1a1a' : ''}
                                size="1.2rem"
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </button>
                        :
                        <button
                            type='submit'
                            className={`btn ${str_btnSize ? str_btnSize : 'btn-sm'} btn-submit`}
                            onClick={e => discardSubmit(true, e)}
                        >
                            {isConfirmationViewOpen || isConfirmationViewOpen === undefined ? str_btnTitle : 'Entwurf'}
                        </button>
                    }
                </div>
            </div>
            {/* If help message is not displayed, isHelpMsgOpen can never be true. */}
            {isHelpMsgOpen && comp_helpMsg}
        </>
    )
}

InputFormSubmitButton.propTypese = {
    str_btnTitle: PropTypes.string,
    str_btnSize: PropTypes.string,
}

export default InputFormSubmitButton
