import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CgClose } from 'react-icons/cg';

import FieldWrapper from './FieldWrapper';
import InFieldProposal from '../InFieldProposal';

import {
    useHandleInputChange,
    useAreRequiredErrorsActive
} from '../context-provider/InputFormContextProvider';
import { useIsConfirmationViewOpenContext } from '../context-provider/FormBaseContextProvider';

import { isRequiredFulfilled } from '../util/input_checks';
import { createAbc } from '../../../util/data_creator';

import FieldErrorMsgs from '../util/input_form_fields_error_msgs.json';
import InFieldConfirmationValue from '../InFieldConfirmationValue';
import { sendData2db } from '../../../util/db_ls_query_handler';

const NUM_SPACES_CONFIRM = 2;
const MAX_NUM_TAGS = 4;
const ABC_LOWERCASE = createAbc(true, true);

const REQUIRED_EMSG = FieldErrorMsgs.required;
const MIN_LEN_EMSG = FieldErrorMsgs.text.length.min;
const MAX_LEN_EMSG = FieldErrorMsgs.text.length.max;
const CHAR_RESTRICTION_INVALID_EMSG = FieldErrorMsgs.text.tags.format;
const DASH_POSITION_EMSG = FieldErrorMsgs.text.tags.dashPosition;
const DUPLICATE_EMSG = 'Dieser Tag wurde bereits eingegeben.';
const CLICKED_TAG_DOES_NOT_EXIST_EMSG = 'Der ausgewählte Tag scheint nicht zu existieren.';
/* Error msg if user tinkers with proposed tags. */
const TAG_FORMAT_INCORRECT_EMSG = 'Es ist ein Fehler aufgetreten. Der Tag konnte nicht hinzugefügt werden.';
/* If the tags delivered by the backend to not have the correct data format,
 * display a generic error to handle this issue and keep the page in working order. */
const TAG_REQUEST_DATA_FORMAT_EMSG = 'Die Tags konnten nicht geladen werden.';

const TAG_URL = '/api/courses/tags/';

const isCharRestrictionValid = (value) => {
    /**
     * Checks if 'value' only contains lowercase abc letters, dashes, and spaces.
     */
    const trimmedLen = value.trim().length;
    const spaceReplaced = value.replaceAll(' ', '');
    const spaceReplacedLen = spaceReplaced.length;
    if (spaceReplacedLen < trimmedLen) return false;
    
    const valueReplaced = spaceReplaced.replaceAll('-', '');
    const replacedLen = valueReplaced.length;
    for (let i = 0; i < replacedLen; i++) {
        const char = valueReplaced.slice(i, i+1);
        if (!ABC_LOWERCASE.includes(char)) return false;
    }

    return true;
}

const hasDashPositionError = (value) => {
    /**
     * Checks if value starts or ends with a '-'.
     * Returns:
     * True : If starts or ends with '-'.
     * False: Otherwise.
     */
    if (!value) return false;
    const d = '-';
    if (value.slice(0, 1) === d) return true;
    const valueLength = value.length;
    return value.slice(valueLength-1) === d;
}

const tagStr2tagArr = (tags) => {
    /**
     * Converts the tag string format (tag0,tag1,tag2,tag3) into
     * the tag array format ['tag0', ... ], which is used by the 'tags' state.
     */
    if (!tags) return [];
    return tags.split(',');
}

const tags2formFormat = (tags) => {
    /**
     * Converts the tags array in the string format that is sent to the backend.
     * The format is as follows:
     * tag1,tag2,tag3,tag3 (at most 4 tags possible)
     */
    if (!tags || (Array.isArray(tags) && !tags.length)) return '';
    return tags.join(',');
}

const TagField = ({
    str_id,
    str_fieldTitle,
    str_bottomInfoText,
    str_tags,
    int_minLength,
    int_maxLength,
    b_isRequired
}) => {

    const [chars, setChars] = useState('')
    const [tags, setTags] = useState(tagStr2tagArr(str_tags))
    const [currentNumChars, setCurrentNumChars] = useState(0)
    const [isCurrentNumCharsActive, setIsCurrentNumCharsActive] = useState(false)
    const [isInputBlocked, setIsInputBlocked] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [proposedTags, setProposedTags] = useState()
    const [proposedTagsStore, setProposedTagsStore] = useState({})
    const [tagDbRequestErrorMsg, setTagDbRequestErrorMsg] = useState('')
    const [isLoadingTags, setIsLoadingTags] = useState(false)
    const tagFieldRef = useRef()
    const minNumCharsRef = useRef(int_minLength ? int_minLength.toString() + '/' : '')
    const maxNumCharsRef = useRef('/' + (int_maxLength + NUM_SPACES_CONFIRM).toString())
    const maxLengthRef = useRef(int_maxLength + NUM_SPACES_CONFIRM)
    const prevCharStateRef = useRef('')

    const isConfirmationViewOpen = useIsConfirmationViewOpenContext()
    const handleInputChange = useHandleInputChange()
    const isRequiredErrorActivate = useAreRequiredErrorsActive()

    const hasError = (inputValue, hasSetErrorMsg=true) => {
        /**
         * Checks if the input field value has an error.
         * :Input
         *  inputValue (str): This is the value of the input field (field.value).
         *      It can be used instead of the state to work with the latest value
         *      in case the state has not been updated yet.
         */
        let errorMsg = '';
        const value2check = (inputValue || inputValue === '') ? inputValue : chars
        const value2checkWoSpaces = value2check.replaceAll(' ', '')
        const valueWoSpacesLen = value2checkWoSpaces.length

        if (b_isRequired && (!tags.length && !isRequiredFulfilled(value2checkWoSpaces))) {
            errorMsg = REQUIRED_EMSG
        }
        else if (!isCharRestrictionValid(value2check)) {
            errorMsg = CHAR_RESTRICTION_INVALID_EMSG
        }
        else if (value2checkWoSpaces && valueWoSpacesLen < int_minLength) {
            errorMsg = MIN_LEN_EMSG
        }
        else if (value2checkWoSpaces && valueWoSpacesLen > maxLengthRef.current) {
            errorMsg = MAX_LEN_EMSG
        }
        else if (tags.includes(value2checkWoSpaces)) {
            errorMsg = DUPLICATE_EMSG
        }
        else if (hasDashPositionError(value2checkWoSpaces)) {
            errorMsg = DASH_POSITION_EMSG
        }

        if (errorMsg) {
            if (hasSetErrorMsg) setErrorMsg(errorMsg)
            return true
        }
        if (hasSetErrorMsg) setErrorMsg('')
        return false
    }

    useEffect(() => {

        const clickListener = (e) => {
            /**
             * Remove the tag proposal element from the DOM if user clicks some
             * element on the page other than the tag <input> or elements with
             * class 'tag-el'.
             */
            const target = e.target
            if (target.classList.contains('tag-el')) return
            if (target.tagName === 'INPUT' && target.id === str_id) return
            resetTagProposalStates()
        }

        const keyDownListener = (e) => {
            /**
             * Remove the tag proposal element from the DOM on 'Escape' press.
             */
            if (e.key === 'Escape') resetTagProposalStates()
        }

        window.addEventListener('click', clickListener)
        window.addEventListener('keydown', keyDownListener)

        return () => {
            window.removeEventListener('click', clickListener)
            window.removeEventListener('keydown', keyDownListener)
        }

    }, [])

    useMemo(() => {
        /* All the required fields that are blank and do not show erros,
         * are activated when a form is submitted. The line below is only 
         * executed once. After the first execution, the required field 
         * displays error messages until the input is correct. */
        if (isRequiredErrorActivate && b_isRequired) hasError()
    }, [isRequiredErrorActivate])
    
    useEffect(() => {
        /**
         * Handles the blocking and unblocking of the input field.
         * If the max. num of tags is reached, the input field is blocked so that
         * the user cannot add new tags.
         */
        const numTags = tags.length
        
        if (numTags > (MAX_NUM_TAGS-1)) setIsInputBlocked(true)
        else if (setIsInputBlocked) setIsInputBlocked(false)
        
        /* Send tags in correct format to the InputFormContextProvider.js. */
        handleInputChange(
            {
                id: str_id,
                value: tags2formFormat(tags),
                hasError: false
            }
        )
    }, [tags])

    /* Helper functions. */

    const resetTagProposalStates = () => {
        /**
         * Sets the states that are involved in proposing tags to the user
         * to their init. values, i.e. the values they are in when no proposal
         * process is under way.
         */
        if (isLoadingTags) return
        setIsLoadingTags(false)
        setTagDbRequestErrorMsg('')
        setProposedTags()
    }

    const storeTags = (tags, searchChars) => {
        /**
         * Stores new tags fetched from the DB to the tags store state.
         * :Input
         *  tags (arr): Array of strings that are fetched from the DB.
         *  searchChars (str): Input field value.
         */
        let updatedTags = {...proposedTagsStore}
        updatedTags[searchChars] = tags
        setProposedTagsStore(updatedTags)
    }

    const proposeTags = async (inChars) => {
        /**
         * Present user with a selection of tags that could fit his input.
         * Tags are either fetched from the DB or from the tags store state of this comp.
         * :Input
         *  inChars (str): Input field value.
         */
        
        const storedTags = proposedTagsStore[inChars]
        if (storedTags) {
            setProposedTags(storedTags)
        } else {
            setIsLoadingTags(true)
            const reqData = { 'tagname': inChars }
            const queryData = await sendData2db('post', TAG_URL, reqData)
            if (queryData.isQuerySuccess) {
                const dbTags = queryData.response.data
                if (Array.isArray(dbTags)) {
                    /* User can stop the loading by e.g. clicking somewhere, thus, the
                     * proposal element is closed, do not show the tags, only store them. */
                    if (inChars === prevCharStateRef.current.replaceAll(' ', '')) {
                        setProposedTags(dbTags)
                        setTagDbRequestErrorMsg('')
                    }
                    storeTags(dbTags, inChars)
                } else {
                    setTagDbRequestErrorMsg(TAG_REQUEST_DATA_FORMAT_EMSG)
                }
            } else {
                /* User can stop the loading by e.g. clicking somewhere, thus, the
                 * proposal element is closed, do not show the error message in this case. */
                setTagDbRequestErrorMsg(queryData.errorMsg)
            }
            setIsLoadingTags(false)
        }
    }

    const updateTags = (newTag) => {
        const newTags = [...tags, newTag]
        setTags(newTags)
        setChars('')
        setCurrentNumChars(0)
    }

    const handleTagDraft = (chars) => {
        /**
         * This function is only called if the input is error free.
         */
        if (chars !== prevCharStateRef.current) return
        const charsWoSpaces = chars.replaceAll(' ', '')
        const lenCharsWoSpaces = charsWoSpaces.length
        const lenChars = chars.length
        const lenDelta = lenChars - lenCharsWoSpaces
        if (lenDelta === 1) {
            proposeTags(charsWoSpaces)
        } else if (lenDelta === 2) {
            updateTags(charsWoSpaces)
        }
    }

    const existsLoadedTagOfName = (tagName) => {
        let isFound = false
        proposedTags.forEach(proposedTag => {
            if (proposedTag.name === tagName) {
                isFound = true
            }
        })
        return isFound
    }

    /* Input field user interaction. */
    
    const onFocus = (e) => {
        if (!isCurrentNumCharsActive) setIsCurrentNumCharsActive(true)
        setCurrentNumChars(e.target.value.length)
    }

    const onBlur = (e) => {
        const chars = e.target.value.trimStart()
        setChars(chars)
    }

    const onChange = (e) => {
        /**
         * Post user error message while typing and update tags.
         * Update the states if the input is correct.
         * If the user types really fast and wants to enforce his tag wo looking at
         * existing tags, the DB request is avoided by comparing the current value
         * to the previous value that is stored in prevCharSateRef.
         * By typing this fast, the user might be able to type in more than 2 spaces
         * at the end, thus, it is necessary to trim the string.
         */
        /* Trim string to correct input value, if user types fast. */
        const fieldChars = e.target.value
        const charsStartTrimmed = fieldChars.trimStart()
        const charsTrimmed = charsStartTrimmed.trimEnd()
        const deltaLen = charsStartTrimmed.length - charsTrimmed.length
        let chars
        if (deltaLen > 2) {
            chars = charsTrimmed + '  '
        } else {
            chars = charsStartTrimmed
        }
        setChars(chars)
        setCurrentNumChars(chars.length)
        if (proposedTags || tagDbRequestErrorMsg) resetTagProposalStates()
        if (hasError(chars)) return
        prevCharStateRef.current = chars
        setTimeout(() => handleTagDraft(chars), 200)
    }

    const onClickDeleteTag = (e) => {
        /**
         * Removes a tag when the user clicks on the cross icon of its button.
         */
        const target = e.target
        let tag2remove;
        if (target.tagName === 'path') {
            tag2remove = target.parentElement.getAttribute('value')
        } else if (target.tagName === 'svg') {
            tag2remove = target.getAttribute('value')
        }
        const remainingTags = tags.filter(tag => tag !== tag2remove)
        setTags(remainingTags)
        /* Focus input after clicking a tag removal to enable user typing. */
        try {
            setTimeout(() => tagFieldRef.current.focus(), 150)
        } catch {
            /* If input field is not present due to the max. num of tags reached,
             * the focus of the input field might fail as its element is not in the DOM. */
        }
    }

    const selectProposal = (e) => {
        /**
         * Handle user's click on a proposed tag.
         */
        const tagValue = e.target.getAttribute('value');

        if (hasError(tagValue, false)) {
            alert(TAG_FORMAT_INCORRECT_EMSG)
            tagFieldRef.current.focus()
            return
        }

        if (!existsLoadedTagOfName(tagValue)) {
            alert(CLICKED_TAG_DOES_NOT_EXIST_EMSG)
            tagFieldRef.current.focus()
            return
        }

        updateTags(tagValue)
        setProposedTags()
        tagFieldRef.current.focus()
    }

    return (
        isConfirmationViewOpen
        ?
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            b_isRequired={false}
        >
            <InFieldConfirmationValue value={tags.join(', ')} />
        </FieldWrapper>
        :
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            str_bottomInfoText={str_bottomInfoText}
            str_errorMsg={errorMsg}
            b_isRequired={b_isRequired}
            b_displayBottomInfoText={true}
        >
            {
                !isInputBlocked &&
                <div className="in-field in-field-char">
                    <input
                        ref={tagFieldRef}
                        type='text'
                        name='char-field'
                        minLength={int_minLength}
                        maxLength={maxLengthRef.current}
                        required={b_isRequired}
                        id={str_id}
                        value={chars}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                    <div className={`in-field-char-count ${isCurrentNumCharsActive ? "" : "hidden"}`}>
                        {minNumCharsRef.current}{currentNumChars}{maxNumCharsRef.current}
                    </div>
                </div>
            }

            {
                tags &&
                <div className="selected-options-btns">
                {
                    tags &&
                    tags.map((item, index) => (
                        <span key={index}>
                            <div className="selected-options-btn">
                                <span className="value">{item}</span>
                                <span className="close-icon">
                                    <CgClose
                                        value={item}
                                        onClick={onClickDeleteTag}
                                    />
                                </span>
                            </div>
                        </span>
                    ))
                }
                </div>
            }

            <InFieldProposal
                b_isLoading={isLoadingTags}
                str_errorMsg={tagDbRequestErrorMsg}
                b_itemsFound={Array.isArray(proposedTags)}
                b_hasItems={proposedTags ? proposedTags[0] : proposedTags}
            >
                {
                    <div className="grid-wrapper tag-el ">
                        <div className="grid-container tag-el">
                        {
                            proposedTags &&
                            proposedTags.map((tag, index) => (
                                <div key={index} className="grid-item tag-item tag-el">
                                    <div className="tag-container tag-el">
                                        <div className="tag-header tag-el">
                                            <span
                                                className="tag-name"
                                                value={tag.name}
                                                onClick={selectProposal}
                                            >
                                                {tag.name}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        </div>
                    </div>
                }
            </InFieldProposal>
        </FieldWrapper>
    )
}

TagField.propTypes = {
    str_id: PropTypes.string.isRequired,
    str_fieldTitle: PropTypes.string,
    str_tags: PropTypes.string,
    str_bottomInfoText: PropTypes.string,
    b_isRequired: PropTypes.bool,
    int_minLength: PropTypes.number.isRequired,
    int_maxLength: PropTypes.number.isRequired
}

export default TagField
