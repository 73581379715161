/**
 * Contains different functions to work with input form data. 
 */

export const computeDropdownHeight = (nbr_length) => {
    /**
     * Computs the height of a dropdown menu on the frontend.
     * :Input
     *  nbr_length: Length of the array of dropdown items.
     * :Returns
     *  The height of the dropdown menu as a float.
     */
    if (typeof(nbr_length) === 'number') return nbr_length * 31.5;
    return undefined;
}

export const cleanTextInput = (str_input) => {
    /**
     * Cleans the text input string.
     * :Input
     *  str_input: Input string.
     * :Returns
     *  '': If string only contains ' ' and '\n\. 
     *  trimmed(str_input): Otherwise.
     */
    const str_trimmed = str_input.trim();
    const str_replaced = str_input.replaceAll(/\n/g, '').replaceAll(' ', '');
    if (str_replaced === '') {
        return '';
    } else {
        return str_trimmed;
    }
}

export const cleanPhoneInput = (str_input) => {
    /**
     * Cleans the #phone text input. 
     * :Input
     *  str_input: Input string.
     * :Returns
     *  Input string without ' ' and '\n'. 
     */
    return str_input.replaceAll(/\n/g, '').replaceAll(' ', '');
}

export const static2dynamicPanelConfig = (obj_staticConfig, obj_dynConfig=null) => {
    /**
     * Combines the static and dynamic configuration of the InputFormContextProvider.
     * :Input
     *  obj_staticConfig: Configuration from config file (empty init state). 
     *  obj_dynConfig: Configuration data that is received from another 
     *                 component at run time. 
     * :Returns
     *  Config (obj): Used as (destructured) input for InputFormContextProvider. 
     */
    /* If no dynConfig is provided, the initState equals the staticConfig initState. */
    if (!obj_dynConfig || obj_dynConfig === {})
        return {
            str_localStorageKey: obj_staticConfig.str_localStorageKey,
            str_queryURL: obj_staticConfig.str_queryURL,
            str_navigationURLonDiscard: obj_staticConfig.str_navigationURLonDiscard,
            obj_initState: obj_staticConfig.obj_initState
        }

    let obj_initState = {};
    /* Check if the dynamic config has an init. state. If not, use the static one. */
    if (obj_dynConfig.obj_initState) {
        Object.keys(obj_staticConfig.obj_initState).forEach(key => {
            obj_initState[key] = obj_dynConfig.obj_initState[key] ?  /* Has a key? */
                                 obj_dynConfig.obj_initState[key] :  /* Uset the dyn. config. */
                                 obj_staticConfig.obj_initState[key] /* Use the static config. */
        });
    } else {
        obj_initState = {...obj_staticConfig.obj_initState};
    }
    
    const obj_config = {
        str_localStorageKey: obj_dynConfig.str_localStorageKey ?
                             obj_dynConfig.str_localStorageKey :
                             obj_staticConfig.str_localStorageKey,
        str_queryURL: obj_dynConfig.str_queryURL ?
                      obj_dynConfig.str_queryURL :
                      obj_staticConfig.str_queryURL,
        str_navigationURLonDiscard: obj_dynConfig.str_navigationURLonDiscard ?
                                    obj_dynConfig.str_navigationURLonDiscard :
                                    obj_staticConfig.str_navigationURLonDiscard,
        obj_initState: obj_initState
    };
    return obj_config;
}

export const db2inputFormData = (obj_dbData, arrayFields, separator) => {
    /**
     * Converts the JSON formatted data from a DB query into the 
     * format required by the input form context provider. 
     * :Input
     *  obj_dbData: JSON data fetched from the DB.
     *  arrayFields (arr): Array of strings, which are the names of the field ids.
     *  separator   (str): String that separates the items of the array fields.
     * :Returns
     *  JSON (obj) that is of the format required by the input form 
     *  context provider { ..., [value, errorState (bool)],... }
     */
    if (typeof(obj_dbData) !== 'object' || !obj_dbData) return {};
    /* Data from DB is error free so that the 2nd array entry is: hasError = false. */
    let obj_provider = {};
    if (arrayFields) {
        Object.keys(obj_dbData).forEach(key => {
            const item = obj_dbData[key]
            if (arrayFields.includes(key) && !Array.isArray(item)) {
                /* Convert string from backend into array that can be parsed by select fields. */
                if (!item) {
                    obj_provider[key] = [[], false]
                } else {
                    const formFieldData = item.split(separator)
                    obj_provider[key] = [formFieldData, false]
                }
            } else {
                obj_provider[key] = [item, false]
            }
        })
    } else {
        Object.keys(obj_dbData).forEach(key => obj_provider[key] = [obj_dbData[key], false]);
    }
    return obj_provider;
}

export const inputForm2dbData = (obj_formData) => {
    /**
     * Converts JSON formatted input form data to JSON data 
     * that is sent to the DB. 
     * :Input
     *  obj_formData: JSON of input form context provider format:
     *                { ..., key: [value, true/false], ...}
     * :Returns
     *  JSON (obj) that is of DB format: { ..., key: value, ... }
     */
    if (!obj_formData || typeof(obj_formData) !== 'object') return null;
    /* Data sent to the DB does not have error states. DB POST is only 
     * possible if form has no errors anymore. */
    let obj_db = {};
    Object.keys(obj_formData).forEach(key => obj_db[key] = obj_formData[key][0]);
    return obj_db;
}
