import React from 'react';

import InFieldsGroup from '../../InFieldsGroup';
import FormBase from '../../FormBase';
import TextField from '../../fields/TextField';
import SingleChoiceField from '../../fields/SingleChoiceField';
import FileField from '../../fields/FileField';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { UserReportingFormData as FormData } from '../../util/form-config-data/InputFormContactDataConfigs';

const InputFormUserReporting = () => {
    const data = useInputData()
    return (
        <FormBase
            str_title='User melden'
            b_hasFileField={true}
        >
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.username}
                    str_chars={data[FormData.fields.username.str_id][0]}
                />
                <TextField
                    {...FormData.fields.title}
                    str_chars={data[FormData.fields.title.str_id][0]}
                />
                <TextField
                    {...FormData.fields.description}
                    str_chars={data[FormData.fields.description.str_id][0]}
                />
                <SingleChoiceField
                    {...FormData.fields.numMaxOccurrences}
                    str_currentOption={data[FormData.fields.numMaxOccurrences.str_id][0]}
                />
                <SingleChoiceField
                    {...FormData.fields.severityLevel}
                    str_currentOption={data[FormData.fields.severityLevel.str_id][0]}
                />
                <TextField
                    {...FormData.fields.url}
                    str_chars={data[FormData.fields.url.str_id][0]}
                />
                <FileField
                    {...FormData.fields.violationFile}
                    inFile={data[FormData.fields.violationFile.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormUserReporting
