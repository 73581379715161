import React from 'react';

import PPMainContentBase from '../PPMainContentBase';
import TbIndustryOnlineJobs from '../../tables/industry/profile-page/TbIndustryOnlineJobs';
import TbIndustryOfflineJobs from '../../tables/industry/profile-page/TbIndustryOfflineJobs';
import TbIndustryScheduledJobs from '../../tables/industry/profile-page/TbIndustryScheduledJobs';
import IndustryPPJobsContextProvider from '../../tables/industry/profile-page/IndustryPPJobsContextProvider';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';

const str_onlineJobsSubHeader = 'Können von anderen Usern auf der Plattform gesehen werden.';
const str_offlineJobsSubHeader = 'Können nur von diesem Account gesehen werden.';
const str_futureJobsSubHeader = 'Werden automatisch am angegebenen Datum online gestellt.';

const IndustryPPJobs = () => {
    useSetBrowserTabTitle('Jobs', true)
    return (
        <IndustryPPJobsContextProvider>
            <PPMainContentBase
                str_id={'online-jobs-section'}
                str_header='Online Jobs'
                str_subHeader={str_onlineJobsSubHeader}
            >
                <TbIndustryOnlineJobs />
            </PPMainContentBase>
            
            <PPMainContentBase
                str_id={'scheduled-jobs-section'}
                str_header='Geplante Jobs'
                str_subHeader={str_futureJobsSubHeader}
            >
                <TbIndustryScheduledJobs str_design='light-grey' />
            </PPMainContentBase>
            
            <PPMainContentBase
                str_id={'offline-jobs-section'}
                str_header='Offline Jobs'
                str_subHeader={str_offlineJobsSubHeader}
            >
                <TbIndustryOfflineJobs str_design='dark-grey' />
            </PPMainContentBase>
        </IndustryPPJobsContextProvider>
  )
}

export default IndustryPPJobs
