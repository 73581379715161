import React from 'react';
import { Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

import PrivatePage from '../network/PrivatePage';
import HeaderAndFooter from './HeaderAndFooter';
import PageBase from './PageBase';

import useRemoveLSitemsUnmount from '../../custom-hooks/useRemoveLSitemsUnmount';

const HeaderFooterPageBase = ({
    str_mainNavDataKey,
    str_secNavPrKey='',
    str_secNavSecKey='',
    b_hasMainNavbar=true,
    b_hasMainNavLogoLink=true,
    b_hasPageBase=false,
    b_hasFooter=true,
    b_isPrivatePage=true,
    b_checkAboValidity=true,
    arr_usertypes,         /* Usertypes with permission to view the page. */
    arr_lsElementsToRemove /* Entails keys for LS elements to remove (only primary keys). */
}) => {
    useRemoveLSitemsUnmount(arr_lsElementsToRemove)
    return (
        b_isPrivatePage
        ?
        <PrivatePage
            arr_usertypes={arr_usertypes}
            b_checkAboValidity={b_checkAboValidity}
        >
            <HeaderAndFooter
                str_mainNavDataKey={str_mainNavDataKey}
                str_secNavPrDataKey={str_secNavPrKey}
                str_secNavSecDataKey={str_secNavSecKey}
                b_hasMainNavbar={b_hasMainNavbar}
                b_hasMainNavLogoLink={b_hasMainNavLogoLink}
                b_hasFooter={b_hasFooter}
            >
                {
                    b_hasPageBase
                    ?
                    <PageBase>
                        <Outlet />
                    </PageBase>
                    :
                    <Outlet />
            }
            </HeaderAndFooter>
        </PrivatePage>
        :
        <HeaderAndFooter
            str_mainNavDataKey={str_mainNavDataKey}
            str_secNavPrDataKey={str_secNavPrKey}
            str_secNavSecDataKey={str_secNavSecKey}
            b_hasMainNavbar={b_hasMainNavbar}
            b_hasMainNavLogoLink={b_hasMainNavLogoLink}
            b_hasFooter={b_hasFooter}
        >
            {
                b_hasPageBase
                ?
                <PageBase>
                    <Outlet />
                </PageBase>
                :
                <Outlet />
        }
        </HeaderAndFooter>
    )
}

HeaderFooterPageBase.propTypes = {
    str_mainNavDataKey: PropTypes.string,
    str_secNavPrKey: PropTypes.string,
    str_secNavSecKey: PropTypes.string,
    b_hasMainNavbar: PropTypes.bool,
    b_hasMainNavLogoLink: PropTypes.bool,
    b_hasFooter: PropTypes.bool,
    b_hasPageBase: PropTypes.bool,
    arr_usertypes: PropTypes.array,
    arr_lsElementsToRemove: PropTypes.array
}

export default HeaderFooterPageBase
