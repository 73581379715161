import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import TbPin from '../TbPin';
import { PPLsKeys, StudentLsKeys } from '../../../util/LocalStorageVariables';
import { string2URLstring } from '../../../util/data_handler';

const BASE_URL = '/api/courses/course/pin/detail/';
const PR_LS_KEY = PPLsKeys.primaryDataKey;
const SEC_LS_KEY = StudentLsKeys.pp.courses;

const genUrl = (id) => {
    return BASE_URL + id + '/';
}

const TrCourses = ({
    obj_data,
    fct_deleteEntryFromState /* If table row can be removed by user. */
}) => {

    const [isCollapsed, setIsCollapsed] = useState(false)
    const tableRowRef = useRef()

    useMemo(() => {
        if (!fct_deleteEntryFromState) return
        /* If the input arg changes due to a delete event on a <tr>,
         * remove collapsed class and set height of prev. animation. */
        setIsCollapsed(false)
        if (tableRowRef.current) {
            tableRowRef.current.style.height = ''
        }
    }, [obj_data])

    const unpin = () => {
        /* Animation. */
        /* Fix the height of <tr> so that removing the <td> does 
         * not collapse the <tr> before the animation. */
        const height = tableRowRef.current.clientHeight;
        tableRowRef.current.style.height = height + 'px';
        /* Run disappear animation by adding a class. */
        setIsCollapsed(true)
        /* Wait until animation is done running, then remove item from state. */
        setTimeout(() => {
            fct_deleteEntryFromState(obj_data.id)
        }, 1500)
    }
    
    return (
        <tr ref={tableRowRef} className={`${isCollapsed ? 'tr-collapse-animation' : ''}`}>
            {
                !isCollapsed &&
                <>
                    <td>{obj_data.title}</td>
                    <td>
                        <Link
                            to={`/courses/${string2URLstring(obj_data.title)}/${obj_data.id}/docs`}
                            className="file-link"
                        >
                            Doks
                        </Link>
                    </td>
                    <td>
                        <Link
                            to={`/courses/${string2URLstring(obj_data.title)}/${obj_data.id}/exams`}
                            className="file-link"
                        >
                            Prüfungen
                        </Link>
                    </td>
                    <td>
                        <Link
                            to={`/courses/${string2URLstring(obj_data.title)}/${obj_data.id}/exercises`}
                            className="file-link"
                        >
                            Übungen
                        </Link>
                    </td>
                    <td>
                        <Link
                            to={`/courses/${string2URLstring(obj_data.title)}/${obj_data.id}/forum`}
                            className="file-link"
                        >
                            Forum
                        </Link>
                    </td>
                    <TbPin
                        nbr_id={obj_data.id}
                        str_url={genUrl(obj_data.id)}
                        str_confirmMsgDetails={obj_data.title}
                        str_prLsKey={PR_LS_KEY}
                        str_secLsKey={SEC_LS_KEY}
                        str_classes='hide-max-w-576px'
                        b_isPinned={obj_data.isPinned}
                        fct_removeTr={fct_deleteEntryFromState ? unpin : undefined}
                    />
                </>
            }
        </tr>
    )
}

TrCourses.propTypes = {
    obj_data: PropTypes.object.isRequired,
    fct_deleteEntryFromState: PropTypes.func
}

export default TrCourses
