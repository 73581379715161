

/********************************************************************
 * The following functions turn a plane user input string with 
 * indicators, such as '*%', '*&', etc., into valid HTML code 
 * This is, for instance, used to display user input as a valid 
 * HTML message in the DOM. 
 */

/* Handle indicators that identify parts of user input string that 
 * are meant to be transformed into valid HTML code. 
 */

const isOpeningIndicator = (kstr_indicator) => {
    /**
     * Checks if the input string is an opening indicator. 
     * :Input
     *  kstr_indicator (str): Opening indicator, e.g.: *% or &%
     * :Returns
     *  True: If is opening indicator. 
     *  False: Otherwise. 
     */
    if (kstr_indicator.charAt(1) === '%') {
        return true;
    } else {
        return false;
    }
}

const reverseIndicator = (str_indicator) => {
    /**
     * Reverses the indicator. E.g.: *% -> %*
     * :Input
     *  str_indicator (str): One of the indicators. 
     * :Returns
     *  The reversed indicator. 
     */
    const kstr_firstChar = str_indicator.charAt(0);
    const kstr_secondChar = str_indicator.charAt(1);
    const kstr_reversedIndicator = kstr_secondChar + kstr_firstChar;
    return kstr_reversedIndicator;
}

const hasClosingIndicator = (index, arr_splitString, kobj_mapping) => {
    /**
     * Checks if a closing indicator exists for a specific opening indicator. 
     * If a valid closing indicator is found, map it to its html. 
     * :Input
     *  index (int): Index at the current array position. 
     *  limit (int): Len of the split user input array. 
     * :Returns
     *  True: If closing indicator could be found. 
     *  False: Otherwise. 
     */
    const kstr_openingIndicator = arr_splitString[index];
    const kstr_closingIndicator = reverseIndicator(kstr_openingIndicator);
    const kint_limit = arr_splitString.length;
    for (let i = index+1; i < kint_limit; i++) {
        const kstr_s = arr_splitString[i];
        if (kstr_s === kstr_openingIndicator) {
            /* Two opening indicators after another, the first one must be ignored. */
            return false;
        } 
        else if (kstr_s === kstr_closingIndicator) {
            /* Found valid closing indicator. */
            /* Map closing indicator to its corresponding html. */
            arr_splitString[i] = kobj_mapping[kstr_s];
            return true;
        }
    }
    /* No closing indicator found in the split array. */
    return false;
}

/* Handle the link formatting of the user input string. */

const findLink = (int_startIndex, int_endIndex, arr_splitString) => {
    /**
     * Searches for the link in the split string array and returns info on 
     * whether the link could be found, no link but closing symbol found, or 
     * no link and no closing symbol found. 
     * arr_splitString is not provided as arg to avoid memory copying. 
     * :Input
     *  int_startIndex: Index of where to start searching for the link in arr_splitString.
     *  int_endIndex: The length of arr_splitString. Limit if no valid or closing symbol
     *      %& can be found. 
     * :Returns
     *  Array of length two
     *      [0] : 0 link found, 1 no link but symbol, 2 no link and no symbol.  
     *      [1] : Index of either found link or found closing symbol. 
     */
    let arr_r = [2, 0];
    for (let i = int_startIndex; i < int_endIndex; i++) {
        const kstr_s = arr_splitString[i];
        if (kstr_s === '%&') {
            /* End of link sequence found, without a valid link in between. */
            arr_r[0] = 1;
            arr_r[1] = i;
            break;
        }
        else if (kstr_s === '&%') {
            /* Another link starts, i.e. first link could not be found. */
            break;
        }
        else if ((kstr_s.length > 3) && (kstr_s.trim().slice(0, 4) === 'http')) {
            /* Valid link found. */
            arr_r[0] = 0;
            arr_r[1] = i;
            break;
        }
    }
    return arr_r;
}

const findLinkWrapper = (loop_index, arr_splitString, kobj_mapping) => {
    /**
     * Searches for a valid link between the &% ... %& symbols. 
     * :Input
     *  loop_index: Int index where the start symbol &% was found. 
     *  arr_splitString (arr): Array of strings. 
     *  kobj_mapping (obj): Dictionary that maps indicators to their valid HTML code. 
     * :Returns
     *  Array of 4 entries. 
     *      [0] : loop_index
     *      [1] : 0 or the end index where %& was found
     *      [2] : 0 link found, 1 no link found but symbol found, 2 no link and no end symbol found
     *      [3] : '' if no valid link is found, otherwise string of valid link
     */
    let arr_return = [loop_index, 0, 0, ''];
    const kint_limit = arr_splitString.length;
    const karr_link = findLink(loop_index+1, kint_limit, arr_splitString);

    if (karr_link[0] === 0) {
        /* Valid link was found. */
        const kint_linkIndex = karr_link[1];
        const kstr_linkTag = kobj_mapping['&%'] + arr_splitString[kint_linkIndex] 
                                + kobj_mapping['lc'];
        arr_return[3] = kstr_linkTag;
    } else if(karr_link[0] === 1) {
        /* No valid link found between the link symbols. Closing symbol found. */
        const kint_linkCloseSymbolIndex = karr_link[1];
        arr_return[1] = kint_linkCloseSymbolIndex;
        arr_return[2] = 1;
    } else {
        /* No link and no closing symbol found. */
        arr_return[2] = 2;
    }

    return arr_return;
}

const handleLink = (int_loopIndex, arr_splitString, kobj_mapping) => {
    /**
     * Handles the formatting of links in the <textarea> post panel. 
     * :Input
     *  int_loopIndex: Current loop variable for the arr_splitString. 
     *  arr_splitString (arr): Array of strings. 
     *  kobj_mapping (obj): Dictionary that maps indicators to their valid HTML code. 
     * :Returns
     *  int: Loop index for arr_splitString where to continue after link handling. 
     */
    const karr_handleLink = findLinkWrapper(int_loopIndex, arr_splitString, kobj_mapping);
    if (karr_handleLink[2] === 0) {
        /* Valid link was found. */
        arr_splitString[int_loopIndex] = karr_handleLink[3];
    }
    else if (karr_handleLink[2] === 1) {
        /* No link was found, but closing symbol was found. */
        /* Overwrite start and end of link (avoid the link). */
        const kint_startIndex = karr_handleLink[0];
        const kint_endIndex = karr_handleLink[1];
        arr_splitString[kint_startIndex] = '';
        arr_splitString[kint_endIndex] = '';
        /* Overwrite all string elements of the array between start and end. */
        for (let j = kint_startIndex+1; j < kint_endIndex; j++) {
            const kstr_mapped = kobj_mapping[arr_splitString[j]];
            if (kstr_mapped !== undefined) {
                arr_splitString[j] = kstr_mapped;
            }
        }
        int_loopIndex = kint_endIndex;
    }
    else {
        /* Neither link nor link closing found. */
        arr_splitString[int_loopIndex] = '';
    }
    return int_loopIndex;
}

/* Convert a user input string with validators into valid HTML code. */

export const string2Html = (str_input) => {
    /**
     * Takes the user input string and reformats it into an html 
     * parse ready string. The characteristic characters of the user input
     * are replaced by valid html tags. The chararacteristic chars are as follows:
     * Bold text       : *% ... %*
     * Italic text     : ~% ... %~
     * Underlined text : _% ... %_
     * Link text       : &% ... %&
     * Code text       : <% ... %>
     * :Input
     *  str_input: User input string. 
     * :Returns
     *  String that is ready to be parsed to valid html. 
     */
    const type = typeof(str_input)
    if (type !== 'string') {
        if (type !== 'number') return
        /* Input must be a string for the function to work. */
        str_input = str_input.toString()
    }

    const kobj_mapping = {
        /* Key-value pairs of styling indicators. */
        '*%' : '<span class="preview-bold">',
        '%*' : '</span>',
        '~%' : '<span class="preview-italic">',
        '%~' : '</span>',
        '_%' : '<span class="preview-underline">',
        '%_' : '</span>',
        '&%' : '<a class="page-link preview-link" href="',
        'lc' : '" target="blank">', /* lc ... complete link */
        '%&' : '</a>',
        '#%' : '<div class="preview-code"><code>',
        '%#' : '</code></div>',
    };

    const kregex = /(\*%|%\*|~%|%~|_%|%_|&%|%&|#%|%#)/;
    const kstr_inputTrimmed = str_input.trim();
    let arr_splitString = kstr_inputTrimmed.split(kregex);
    const kint_limit = arr_splitString.length;

    for (let i = 0; i < kint_limit; i++) {
        const kstr_s = arr_splitString[i];
        const kstr_mapped = kobj_mapping[kstr_s];
        if (kstr_mapped !== undefined) {
            /* Only transform the string if a closing indicator follows. */
            if (isOpeningIndicator(kstr_s) && 
                hasClosingIndicator(i, arr_splitString, kobj_mapping)
            ) {
                /* Links need to be handled differently, as the href must be filled. */
                if (kstr_s === '&%') {
                    i = handleLink(i, arr_splitString, kobj_mapping);
                } else {
                    arr_splitString[i] = kstr_mapped;
                }
            }
        }
    }
    return arr_splitString.join('');
}
