import React from 'react';
import { Link } from 'react-router-dom';

import Votes from '../votes/Votes';

import { useBaseUrlsContext } from './context-provider/MessagesContextProvider';
import { useMsgContext } from './context-provider/MessageContextProvider';
import { useFilterStateContext } from '../filters/context-provider/FilterStateContextProvider';

import { genDynUrl } from '../../util/db_ls_query_handler';
import { inputForm2dbData } from '../input-forms/util/input_form_data_handler';
import { string2URLstring } from '../../util/data_handler';
import VoteService from '../../util/voting/vote_service';

const documentUrl = (msg) => {
    const category = string2URLstring(msg.category);
    const title = string2URLstring(msg.title);
    const docId = msg.id;
    return `../${category}/${title}/${docId}/discussion`;
}

const forumUrl = (msg) => {
    return `../thread/${msg.id}`;
}

const examUrl = (msg) => {
    const category = string2URLstring(msg.qcategory);
    return `exam/question/${category}/${msg.number}/${msg.questionId}/discussion`;
}

const exerciseUrl = (msg) => {
    const category = string2URLstring(msg.qcategory);
    return `exercise/question/${category}/${msg.number}/${msg.questionId}/discussion`;
}

const discussionUrl = (category, msg) => {
    /**
     * :Input
     *  category (str): Learning material category.
     *  msg (obj): Message data.
     * :Retunrs
     *  Url that links user to the detail/discussion page of the
     *  study material a specific message refers to.
     */
    switch (category) {
        case 'Unterlagen':
            return documentUrl(msg);
        case 'Forum':
            return forumUrl(msg);
        case 'Prüfungen':
            return examUrl(msg);
        case 'Übungen':
            return exerciseUrl(msg);
        default:
            return '';
    }
}

const MessageStudyMaterialFooter = () => {

    const msg = useMsgContext()
    const baseUrls = useBaseUrlsContext()
    const filterData = inputForm2dbData(useFilterStateContext())

    const voteUrl = genDynUrl(baseUrls.voteBaseUrl, msg.id)
    const voteProps = VoteService.voteSerializer(msg, voteUrl)
    const url = discussionUrl(filterData['categories'], msg)

    return (
        <div className="msg-footer">
            <div className="vote-edit-comment">
                {
                    <div className="vote-info">
                        <div className="vote-text">vote</div>
                        <Votes {...voteProps} />
                    </div>
                }
                <div className="msg-link">
                    <Link
                        to={url}
                        className="page-link"
                    >
                        Diskussion
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default MessageStudyMaterialFooter
