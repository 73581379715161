import React from 'react';
import PropTypes from 'prop-types';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import TdPhoneTextAndSymbol from '../td/TdPhoneTextAndSymbol';
import TdEmailTextAndSymbol from '../td/TdEmailTextAndSymbol';
import TrCourseTutorMyOffer from './TrCourseTutorMyOffer';
import {
    useQueriedDataContext,
    useIsFirstFetchSuccessContext
} from '../../context-provider/DynamicContentLoadContextProvider';
import { fillBlankTableCell } from '../../../util/data_handler';

const TbCoursesTutoring = ({
    arr_data=[],        /* If data is not loaded in packages via the DynLoadContextProvider. */
    b_isDynamic=false,  /* If data is loaded in packages one after another. */
    b_isMyOffer=false, /* Distinguish between my offers and general offers table data. */
    str_textAlignment='text-left',
    str_design='',
    fct_deleteMyOffer /* Callback to remove an offer from myOffers (state and LS) */
}) => {

    const isFirstFetchSuccessRef = useIsFirstFetchSuccessContext()
    if (b_isDynamic)
        arr_data = useQueriedDataContext()

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={b_isDynamic}
            b_isFirstFetchSuccess={isFirstFetchSuccessRef ? isFirstFetchSuccessRef.current : true}
            firstQueryDataEntry={arr_data[0]}
        >
            <div className='tb-wrapper'>
                <table cellSpacing="0" className={`tb-base tb-collapse-text-to-icon ${str_textAlignment} ${str_design}`}>
                    <thead>
                        <tr>
                            <td>Kurs</td>
                            {
                                !b_isMyOffer &&
                                <td>User</td>
                            }
                            <td className="hide-max-w-576px">Bestanden</td>
                            <td className="hide-max-w-1024px">Sem.</td>
                            <td className="hide-max-w-1024px">Tutorstunden</td>
                            <td className="hide-max-w-480px">&#8364;/h</td>
                            {
                                /* Display contact info for small screen only for other offers.
                                 * The edit and delete button are displayed instead. */
                                b_isMyOffer
                                ?
                                <>
                                    <td className="hide-max-w-576px">Email</td>
                                    <td className="hide-max-w-576px">Telefon</td>
                                </>
                                :
                                <>
                                    <td>Email</td>
                                    <td>Telefon</td>
                                </>
                            }
                            {
                                b_isMyOffer &&
                                <td className="hide-max-w-768px">Gepostet</td>
                            }
                            {
                                b_isMyOffer &&
                                <>
                                    <td>Edit</td>
                                    <td>Löschen</td>
                                </>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            b_isMyOffer
                            ?
                            arr_data.map((item, index) => (
                                <TrCourseTutorMyOffer
                                    key={index}
                                    obj_data={item}
                                    fct_deleteMyOffer={fct_deleteMyOffer}
                                />
                            ))
                            :
                            arr_data.map((item, index) => (
                                <tr key={index} className={item.isUsersPost ? 'tr-user-owned' : ''}>
                                    <td>{item.courseTitle}</td>
                                    <td className="td-username">{item.firstName} {item.lastName}</td>
                                    <td className="hide-max-w-576px">{item.yearCoursePassed}</td>
                                    <td className="hide-max-w-1024px">{fillBlankTableCell(item.numCompletedSemesters)}</td>
                                    <td className="hide-max-w-1024px">{item.numHoursTutored}</td>
                                    <td className="hide-max-w-480px">{fillBlankTableCell(item.pricePerHour)}</td>
                                    {
                                        b_isMyOffer
                                        ?
                                        <>
                                            <TdEmailTextAndSymbol
                                                className="hide-max-w-576px"
                                                str_email={item.email}
                                            />
                                            <TdPhoneTextAndSymbol
                                                className="hide-max-w-576px"
                                                str_phone={item.phone}
                                            />
                                        </>
                                        :
                                        <>
                                            <TdEmailTextAndSymbol str_email={item.email} />
                                            <TdPhoneTextAndSymbol str_phone={item.phone} />
                                        </>
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </DataStateCheckWrapper>
    )
}

TbCoursesTutoring.propTypes = {
    arr_data: PropTypes.array,
    b_isDynamic: PropTypes.bool,
    b_isMyOffer: PropTypes.bool,
    str_textAlignment: PropTypes.string,
    str_design: PropTypes.string,
    fct_deleteMyOffer: PropTypes.func
}

export default TbCoursesTutoring
