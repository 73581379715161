import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MessageStdBodyText from './MessageStdBodyText';
import MessageStdBodyImages from './MessageStdBodyImages';
import MessageStdBodyFiles from './MessageStdBodyFiles';
import MessageTags from './MessageTags';
import PanelPost from '../post-panels/PanelPost';

import { isImageFile } from '../input-forms/util/input_checks';
import {
    useStaticFormConfigContext,
    useUserContext,
    useTextIndicatorsContext,
    useHasDarkDesignContext,
    useBaseUrlsContext
} from './context-provider/MessagesContextProvider';
import {
    useIsEditFormOpenContext,
    useIsMsgOwnerContext,
    useRequestTypeContext,
    useMsgContext
} from './context-provider/MessageContextProvider';
import MessageService from './util/message_service';

const MessageStdBody = ({
    b_isContentOnly=false,
    b_hasTextMarking=true
}) => {
    
    const [imgFiles, setImgFiles] = useState([])
    const [nonImgFiles, setNonImgFiles] = useState([])
    
    const staticFormConfig = useStaticFormConfigContext()
    const user = useUserContext()
    const textIndicators = useTextIndicatorsContext()
    const hasDarkDesign = useHasDarkDesignContext()
    const baseUrls = useBaseUrlsContext()

    const msg = useMsgContext()
    const requestType = useRequestTypeContext()
    const isEditFormOpen = useIsEditFormOpenContext()
    const isOwner = useIsMsgOwnerContext()

    useEffect(() => {
        /**
         * Separate img and non-img files into their own arrays.
         */
        let imgFiles = [];
        let nonImgFiles = [];
        if (msg.files) {
            msg.files.forEach(fileUrl => {
                const b_isImageFile = isImageFile(fileUrl);
                if (b_isImageFile !== null) { /* Check error in file name/path. */
                    if (b_isImageFile) {
                        imgFiles.push(fileUrl);
                    } else {
                        nonImgFiles.push(fileUrl);
                    }
                }
            })
        }
        setImgFiles(imgFiles)
        setNonImgFiles(nonImgFiles)
    }, [msg])

    return (
        <div className={`${b_isContentOnly ? '' : 'msg-body'}`}>
            {
                (!isEditFormOpen || (isEditFormOpen && !isOwner)) &&
                <>
                    <MessageStdBodyText
                        text={msg.text}
                        b_hasTextMarking={b_hasTextMarking}
                    />
                    <MessageStdBodyFiles arr_files={nonImgFiles} />
                    <MessageStdBodyImages arr_files={imgFiles} />
                    <MessageTags str_tagnames={msg.tagnames} />
                </>
            }
            {
                /* Post panel to edit message. */
                isEditFormOpen &&
                <PanelPost
                    obj_staticConfig={staticFormConfig}
                    obj_dynConfig={MessageService.genDynPostPanelConfig(
                        msg.id, msg.category, msg.text, msg.files,
                        isOwner, baseUrls, staticFormConfig
                    )}
                    arr_indicators={textIndicators}
                    str_requestType={requestType}
                    str_firstName={user.firstName}
                    str_lastName={user.lastName}
                    str_username={user.username}
                    b_hasDarkDesign={hasDarkDesign}
                />
            }
        </div>
    )
}

MessageStdBody.propTypes = {
    b_isContentOnly: PropTypes.bool,
    b_hasTextMarking: PropTypes.bool
}

export default MessageStdBody
