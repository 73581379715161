/**
 * Contains all the base function that are required to 
 * query data form the DB or the LS. 
 */

import axios from 'axios';
import AuthService from './authentication/auth_service';
import AuthInterceptor from './authentication/auth_interceptor';
import ErrorMsgs from './error_msgs.json';

export const genDynUrl = (baseUrl, param) => {
    /**
     * Creates a dynamic url given a base url that does not change and
     * a dynamic parameter (e.g. and id from the URL).
     * :Input
     *  baseUrl (str)
     *  parma (str)
     */
    if (typeof(baseUrl) !== 'string') return '';
    if (typeof(param) !== 'string' && typeof(param) !== 'number') return '';

    const len = baseUrl.length;
    if (baseUrl[len-1] !== '/') {
        return baseUrl + '/' + param + '/';
    }
    return baseUrl + param + '/';
}

/* Database request error handling. */

const getResponseErrorMsg = (statusCode) => {
    /* Creates an error message for the provided error status code. */
    switch (statusCode) {
        case 400:
            return ErrorMsgs.request.standard.badRequest400;
        case 401:
            return ErrorMsgs.request.standard.unauthenticated401;
        case 403:
            return ErrorMsgs.request.standard.forbidden403;
        case 404:
            return ErrorMsgs.request.standard.notFound404;
        case 405:
            return ErrorMsgs.request.standard.notAllowed405;
        case 451:
            return ErrorMsgs.request.standard.unavailableForLegalReasons451;
        case 500:
            return ErrorMsgs.request.standard.serverInternalError500;
        case 503:
            return ErrorMsgs.request.standard.serverUnavailable503;
        case 511:
            return ErrorMsgs.request.standard.networkAuthRequired511;
        case statusCode > 499:
            return ErrorMsgs.request.standard.otherServerError;
        case statusCode > 399:
            return ErrorMsgs.request.standard.otherRequestError;
        default:
            return ErrorMsgs.request.standard.unknownError;
    }
}

export const handleRequestError = (error) => {
    /**
     * Inspects the axios error for its errr type and creates
     * a suitable error message for it.
     * :Returns
     *  { statusCode: error status code (nbr) or 'null' if none, errorMsg: error message (str) }
     */
    let statusCode, errorMsg;
    if (error.response) {
        statusCode = error.response.status;
        /* The data key does, if all goes right, only exist if the backend sends an error message back.
         * If an error message is returned, then use this one to display it on the frontend. */
        try {
            const len = Object.keys(error.response.data).length;
            if (len === 1) {
                Object.values(error.response.data).forEach(item => errorMsg = item)
            } else {
                errorMsg = getResponseErrorMsg(error.response.status);    
            }
        } catch {
            errorMsg = getResponseErrorMsg(error.response.status);
        }
    }
    else if (error.request) {
        statusCode = null;
        errorMsg = ErrorMsgs.request.standard.noResponseError;
    }
    else {
        statusCode = null;
        errorMsg = ErrorMsgs.request.standard.requestSetupError
    }

    return {
        statusCode: statusCode,
        errorMsg: errorMsg
    };
}

/* Local storage (LS) functions. */

export const fetchLSdata = (str_firstKey, str_secondKey='', str_thirdKey='') => {
    /**
     * Fetch data from LS at different object levels.
     * :Input
     *  str_firstKey : Key for LS item.
     *  str_secondKey: Key for item of LS item.
     *  str_thirdKey : Key for item of item of LS item.
     * :Returns
     *  Data if found, otherwise return null.
     */
    if (!str_firstKey) return null;
    
    const firstValue = JSON.parse(localStorage.getItem(str_firstKey));
    if (typeof(firstValue) !== 'object' || Array.isArray(firstValue)) return firstValue;
    
    if (firstValue) {
        if (!str_secondKey) return firstValue;

        const secondValue = firstValue[str_secondKey];
        if (typeof(secondValue) !== 'object' || Array.isArray(secondValue)) return secondValue;

        if (secondValue) {
            if (!str_thirdKey) return secondValue;
            
            const thirdValue = secondValue[str_thirdKey];
            if (typeof(thirdValue) !== 'object' || Array.isArray(thirdValue)) return thirdValue;

            if (thirdValue) return thirdValue;
        }
    }
    
    return null;
};

export const setLSdata = (value, str_firstKey, str_secondKey='', str_thirdKey='') => {
    /**
     * Set LS value at the given key level.
     * :Input
     *  value: Value to be set in LS. 
     *  str_firstKey : Key for LS item.
     *  str_secondKey: Key for item of LS item. 
     *  str_thirdKey : Key for item of item of LS item. 
     */
    /* Without value and key, an LS item cannot be set. */
    if (!value || !str_firstKey) return;

    /* Set single key/value pair. */
    if (!str_secondKey) {
        const str_storageValue = typeof(value) === 'object' ? JSON.stringify(value) : value;
        localStorage.setItem(str_firstKey, str_storageValue);
        return;
    }

    let firstValue = JSON.parse(localStorage.getItem(str_firstKey));
    let obj_new = {};
    
    /* Store format: LS = {str_firstKey: {str_secondKey: value}}. */
    if (!str_thirdKey) {
        if (firstValue) {
            firstValue[str_secondKey] = value;
            localStorage.setItem(str_firstKey, JSON.stringify(firstValue));
        } else {
            obj_new[str_secondKey] = value;
            localStorage.setItem(str_firstKey, JSON.stringify(obj_new));
        }
        return;
    }

    /* Store format: LS = {str_firstKey: {str_secondKey: {str_thirdKey: value}}}. */
    if (firstValue) {
        if (firstValue[str_secondKey]) {
            firstValue[str_secondKey][str_thirdKey] = value;
        } else {
            obj_new[str_thirdKey] = value;
            firstValue[str_secondKey] = obj_new;
        }
        localStorage.setItem(str_firstKey, JSON.stringify(firstValue));
    } else {
        obj_new[str_secondKey] = {};
        obj_new[str_secondKey][str_thirdKey] = value;
        localStorage.setItem(str_firstKey, JSON.stringify(obj_new))
    }
};

/* Database (DB) request functions. */

const handleAxiosResponse = (response) => {
    return {
        isQuerySuccess: true,
        response: response
    };
}

const handleAxiosError = (error) => {
    const errInfo = handleRequestError(error);
    return {
        isQuerySuccess: false,
        errCode: errInfo.statusCode,
        errorMsg: errInfo.errorMsg
    }
}

const makeDeleteOrGetRequest = async (str_url, b_isPrivateFetch, str_requestType) => {
    /**
     * Fetches data from the DB at str_url.
     * Checks request for user authentication if b_isPrivateFetch = true.
     * :Returns
     *  Success (obj): {
     *      isQuerySuccess: true
     *      response: axios response
     *  }
     *  Error (obj): {
     *      isQuerySucess: false,
     *      errCode (nbr): HTML error code of request
     *      errorMsg  (str): Error message for the error code.
     *  }
     */
    if (b_isPrivateFetch) {
        /* Check for authentication before sending the request. */
        return AuthInterceptor.interceptRequest().request({
            method: str_requestType,
            url: str_url,
            headers: AuthService.authHeader()
        })
        .then((response) => { return handleAxiosResponse(response); })
        .catch((error) => { return handleAxiosError(error); })
    } else {
        return axios.request({
            method: str_requestType,
            url: str_url
        })
        .then((response) => { return handleAxiosResponse(response); })
        .catch((error) => { return handleAxiosError(error); })
    }
}

export const deleteDBdata = async (str_url, b_isPrivateFetch=true) => {
    return await makeDeleteOrGetRequest(str_url, b_isPrivateFetch, 'delete');
}

export const fetchDBdata = async (str_url, b_isPrivateFetch=true) => {
    return await makeDeleteOrGetRequest(str_url, b_isPrivateFetch, 'get')
};

export const sendData2db = async (str_requestType, str_url, obj_data=null, b_isPrivateFetch=true) => {
    /**
     * Sends data to DB via put or post request.
     * Checks request for user authentication if b_isPrivateFetch = true.
     * :Returns
     *  Invalid request type: null
     *  Success (obj): {
     *      isQuerySuccess: true
     *      response: axios response
     *  }
     *  Error (obj): {
     *      isQuerySucess: false,
     *      errCode (nbr): HTML error code of request
     *      errorMsg  (str): Error message for the error code.
     *  }
     */

    /* Flag for wrong request type. */
    const str_reqType = str_requestType.toLowerCase();
    if ((str_reqType !== 'put' && str_reqType !== 'post') || !str_url) {
        /* Return the same format as for DB request errors. */
        return {
            isQuerySuccess: false,
            errCode: 405,
            errorMsg: ErrorMsgs.request.standard.notAllowed405
        };
    }
    
    if (b_isPrivateFetch) {
        return AuthInterceptor.interceptRequest().request({
            method: str_requestType,
            url: str_url,
            data: obj_data,
            headers: AuthService.authHeader()
        })
        .then((response) => { return handleAxiosResponse(response); })
        .catch((error) => { return handleAxiosError(error); })
    } else {
        return axios({
            method: str_requestType,
            url: str_url,
            data: obj_data,
        })
        .then((response) => { return handleAxiosResponse(response); })
        .catch((error) => { return handleAxiosError(error); })
    }
};

const genFormData = (obj_formSubmitData) => {
    /**
     * Add all the data from the submit form to a FormData object.
     * :Input
     *  obj_formSubmitData (obj): The object that is generated via input forms.
     * :Returns
     *  FormData to be sent via axios request.
     */
    let formData = new FormData();
    Object.keys(obj_formSubmitData).forEach(key => {
        formData.append(key, obj_formSubmitData[key])
    });
    return formData;
}

export const sendFileData2db = async (str_requestType, str_url, obj_data) => {
    /**
     * Sends data that includes files to DB via put or post request.
     * :Returns
     *  Invalid request type: null
     *  Success (obj): {
     *      isQuerySuccess: true
     *      response: axios response
     *  }
     *  Error (obj): {
     *      isQuerySucess: false,
     *      errCode (nbr): HTML error code of request
     *      errorMsg  (str): Error message for the error code.
     *  }
     */
    /* Flag for wrong request type. */
    const str_reqType = str_requestType.toLowerCase();
    if (str_reqType !== 'put' && str_reqType !== 'post') {
        /* Return the same format as for DB request errors. */
        return {
            isQuerySuccess: false,
            errCode: 405,
            errorMsg: ErrorMsgs.request.standard.notAllowed405
        };
    }

    const formData = genFormData(obj_data);
    const headers = {
        'Content-Type': 'multipart/form-data',
        ...AuthService.authHeader()
    };

    return AuthInterceptor.interceptRequest().request({
        method: str_requestType,
        url: str_url,
        data: formData,
        headers: headers
    })
    .then((response) => { return handleAxiosResponse(response); })
    .catch((error) => { return handleAxiosError(error); })
}

/* Fetch data from LS or DB. */

export const fetchLSorDBdata = (
    str_url,
    str_firstKey,
    str_secondKey='',
    str_thirdKey='',
) => {
    /**
     * Fetches data from LS if present, otherwise from the DB.
     * :Input
     *  str_url      : Target URL for DB query.
     *  str_firstKey : Key for LS item.
     *  str_secondKey: Key for item of LS item.
     *  str_thirdKey : Key for item of item of LS item.
     * :Returns
     *  LS value: Can be of any type.
     *  or
     *  DB value (obj): Look at the respective function header.
     */
    /* Query LS. */
    if (str_firstKey) {
        const data = fetchLSdata(str_firstKey, str_secondKey, str_thirdKey)
        if (data) {
            /* Convert LS data into DB response format. */
            return {
                isQuerySuccess: true,
                response: { data: data }
            };
        }
    }
    /* Query DB. */
    return fetchDBdata(str_url);
};
