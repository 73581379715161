import React, { useState } from 'react';

import FormSuccessErrorMessage from '../input-forms/FormSuccessErrorMessage';
import InputFormIndustryUserRegistration from '../input-forms/forms/welcome/InputFormIndustryUserRegistration'
import InputFormContextProvider from '../input-forms/context-provider/InputFormContextProvider';
import { IndustryUserRegistrationFormData as FormData } from '../input-forms/util/form-config-data/InputFormWelcomeDataConfigs';
import { static2dynamicPanelConfig } from '../input-forms/util/input_form_data_handler';

const URL = '/api/profiles/company/registration/';
const SUCCESS_MSG = 'Die Daten wurden erfolgreich gespeichert und werden nun bearbeitet.\n' +
    'Wir kommen in den nächsten Tagen mit den Zugangsdaten auf dich zu.\n\n' +
    'Wir freuen uns euch bald bei MUPLAZA begrüßen zu dürfen!';

const RegistrationIndustry = () => {
    
    const [isPosted, setIsPosted] = useState(false)
    const posted = (response) => { setIsPosted(true) }
    
    return (
        isPosted
        ?
        <FormSuccessErrorMessage
            b_isSuccess={true}
            str_successMsg={SUCCESS_MSG}
        />
        :
        <InputFormContextProvider
            {...static2dynamicPanelConfig(FormData)}
            str_queryURL={URL}
            str_requestType='post'
            b_isPrivatePost={false}
            b_hasDiscardBtn={false}
            fct_response2parent={posted}
        >
            <InputFormIndustryUserRegistration />
        </InputFormContextProvider>
    )
}

export default RegistrationIndustry
