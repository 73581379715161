import React from 'react';
import PropTypes from 'prop-types';

import AuthService from '../../util/authentication/auth_service';

const PageBase = ({
    str_pageMainClasses='',
    str_pageSpacingClasses='',
    str_pageContentBgColorClasses='',
    str_mainContentWrapperClasses='',
    b_isGridPage=false,
    b_useLightTheme=false,
    children
}) => {
    const hasDarkTheme = b_useLightTheme ? false : AuthService.hasDarkTheme();
    return (
        <main
            id="page-content"
            className={`
                page-content ${str_pageMainClasses} ${hasDarkTheme ? 'dark-theme' : ''}
            `}>
            <div className={`page-spacing-wrapper ${str_pageSpacingClasses}`}>
                <div className={`page-content-wrapper ${str_pageContentBgColorClasses}`}>
                    {
                        b_isGridPage
                        ?
                        <>
                            {children}
                        </>
                        :
                        <div className="page-no-grid">
                            <div id="main-content">
                                <div className={`
                                    ${str_mainContentWrapperClasses ? 
                                    str_mainContentWrapperClasses : 
                                    'mc-wrapper'}
                                `}>
                                    {children}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </main>
    )
}

PageBase.propTypes = {
    str_pageMainClasses: PropTypes.string,
    str_pageSpacingClasses: PropTypes.string,
    str_pageContentBgColorClasses: PropTypes.string,
    str_mainContentWrapperClasses: PropTypes.string,
    b_isGridPage: PropTypes.bool,
    b_useLightTheme: PropTypes.bool
}

export default PageBase
