import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { GoPin } from 'react-icons/go'
import { deleteDBdata, fetchLSdata, sendData2db, setLSdata } from '../../util/db_ls_query_handler';

const CONFIRM_BASE_MSG = 'Möchtest du folgenden Eintrag tatsächlich ';
const CONFIRM_MSG_PIN = CONFIRM_BASE_MSG + 'an deine Pinnwand anheften?\n\n';
const CONFIRM_MSG_UNPIN = CONFIRM_BASE_MSG + 'von deiner Pinnwand lösen?\n\n';

const genConfirmMsg = (isPinned, dataEntry) => {
    /**
     * Returns the pin/unpin message of the confirmation window if
     * user clicks the pin needle.
     */
    if (isPinned) return CONFIRM_MSG_UNPIN + dataEntry;
    return CONFIRM_MSG_PIN + dataEntry;
}

const genErrorMsg = (isPinned, errorMsg) => {
    /**
     * Returns the error message for the alert window if the pinning/unpinning failed.
     */
    const state = isPinned ? 'gelöst' : 'gepinnt';
    return `Oops! Der Eintrag konnte nicht ${state} werden.\n${errorMsg}`;
}

const TbPin = ({
    nbr_id,                   /* ID of the item. */
    str_url,
    str_confirmMsgDetails='', /* Added to the standard confirm message. */
    str_prLsKey='',           /* Key to check if LS items exist for the pin elements. */
    str_secLsKey='',
    str_terLsKey='',
    str_classes='',
    b_isPinned,
    fct_removeTr              /* Triggers function in parent to remove <tr> (animation). */
}) => {

    const [isPinned, setIsPinned] = useState(b_isPinned)
    const isLockedRef = useRef(false)

    const handleOnClick = async () => {
        if (isLockedRef.current) return
        isLockedRef.current = true

        if (!window.confirm(genConfirmMsg(isPinned, str_confirmMsgDetails))) {
            isLockedRef.current = false
            return
        }

        /* Query DB. */
        let queryData
        const lsData = fetchLSdata(str_prLsKey, str_secLsKey, str_terLsKey)
        if (isPinned) {
            queryData = await deleteDBdata(str_url)
        } else {
            const pl = lsData ? {hasLsData: true} : {hasLsData: false}
            queryData = await sendData2db('put', str_url, pl)
        }

        /* Handle query response. */
        if (queryData.isQuerySuccess) {
            if (fct_removeTr) fct_removeTr()
            updateLs(lsData, queryData.response.data)
            setIsPinned(!isPinned)
        } else {
            displayAlertErrorMsgs(queryData.errorMsg)
        }

        isLockedRef.current = false
    }

    const updateLs = (lsData, newItem) => {
        /**
         * Updates the localStorage items according to pin/unpin if LS data is present.
         * If an item gets unpinned, the item is removed from the LS.
         * If an item gets pinned, the item is added to the LS.
         */
        let newLsData;
        if (lsData) {
            if (isPinned) {
                /* Remove unpinned item from LS. */
                newLsData = lsData.filter(item => item.id !== nbr_id)
            } else {
                /* Store newly pinned item in LS. */
                newLsData = [...lsData, newItem]
            }
            setLSdata(newLsData, str_prLsKey, str_secLsKey, str_terLsKey)
        }
    }

    const displayAlertErrorMsgs = (errorMsg) => {
        /**
         * Prompts the user with an alert message if the pin/unpin failed.
         */
        if (isPinned) {
            alert(genErrorMsg(true, errorMsg))
        } else {
            alert(genErrorMsg(false, errorMsg))
        }
    }

    return (
        <td className={`td-pin ${str_classes} ${isPinned ? 'is-pinned' : ''}`}>
            <GoPin onClick={handleOnClick}/>
        </td>
    )
}

TbPin.propTypes = {
    nbr_id: PropTypes.number.isRequired,
    str_url: PropTypes.string.isRequired,
    str_confirmMsgDetails: PropTypes.string,
    str_prLsKey: PropTypes.string,
    str_secLsKey: PropTypes.string,
    str_terLsKey: PropTypes.string,
    str_classes: PropTypes.string,
    b_isPinned: PropTypes.bool.isRequired,
    fct_removeTr: PropTypes.func
}

export default TbPin
