/**
 * Entails the serializers to covnvert received backend data
 * into a format that can be parsed by the food offer components.
 * 
 * The input format expected by the coupon card 'CardCoupon.js' is:
 * {
        id: 0,
        title: 'Steakessen',
        provider: 'the-kitchen',
        body: {
            description: 'Here comes the description of the coupon.'
        },
        // Footer example, all keys can be found in FOOTER_KEYS.
        footer: {
            price: 10,
            numAvailable: 10,
            numPurchased: 2,
            numRedeemed: 1
        }
    }
 */

/* Keys to access the values of the JSON sent by the backend. */

const BODY_KEYS = ['description'];
const FOOTER_KEYS = [
    'price',
    'numAvailable', /* How many coupons are available of a specific instance? */
    'numPurchased', /* How many coupons has a user purchased? */
    'numLeft',      /* How many coupons are still left for purchasing? */
    'isOwner',      /* Has the user purchased the coupon yet? */
    'numOwned',     /* How many coupons does a user own of a spcific instance? */
    'numRedeemed'   /* How many coupons has a user redeemed? */
];

const dbJson2couponCard = (dbObj) => {
    let robj = {};
    let bodyObj = {};
    let footerObj = {};

    robj['id'] = dbObj.id;
    robj['title'] = dbObj.title;
    robj['provider'] = dbObj.provider;

    /* Serialize card body data. */
    BODY_KEYS.forEach((key) => {
        const value = dbObj[key];
        if (value || value === 0)
            bodyObj[key] = value;
    })
    robj['body'] = bodyObj;

    /* Serialize card footer data. */
    FOOTER_KEYS.forEach((key) => {
        const value = dbObj[key];
        if (value || value === 0)
            footerObj[key] = value;
    })
    robj['footer'] = footerObj;

    return robj;
}

const dbList2couponCardsArray = (dbList) => {
    /**
     * Converts every JS object entry of an array into a JS object that
     * can be parsed by the coupon cards component 'CardCoupon.js'.
     * :Input
     *  dbList (array): Item are JS ojects with coupon data from the DB. 
     * :Returns
     *  Array of JS objects that can be parsed by 'CardCoupon.js'.
     */
    return dbList.map((item) => dbJson2couponCard(item));
}

const CouponSerializer = {
    dbJson2couponCard: dbJson2couponCard,
    dbList2couponCardsArray: dbList2couponCardsArray
};

export default CouponSerializer;