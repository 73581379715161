import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useRunAnimationContext } from '../context-provider/SbAnimationContextProvider';
import { isActiveURLitem } from '../../../util/data_handler';

/* ID that identifies the active profile-page sidebar item. */
const str_id = 'sb-item-active';

const SbProfilePageItem = ({ obj_data }) => {

    const location = useLocation()
    const history = useNavigate()
    /* This line must come last! If it comes above the other two hooks, the page does not load. */
    const [isActive, setIsActive] = useState(() => isActiveURLitem(location.pathname, obj_data.name))

    const runAnimation = useRunAnimationContext()

    useEffect(() => {
        if (isActiveURLitem(location.pathname, obj_data.name)) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }, [history])

    return (
        <li
            className={`sb-list-item ${obj_data.isButton ? 'sb-list-item-btn' : ''}`}
            id={`${isActive ? str_id : ''}`}
            onClick={() => runAnimation()}
        >
            {
                obj_data.link !== undefined
                ?
                <Link to={obj_data.link}>
                    <div className="sb-header sb-list-header list-item-data">
                        {
                            obj_data.isButton
                            ?
                            <div className="btn-wrapper">
                                <button className="btn btn-md btn-sb-profile">
                                    {obj_data.title}
                                </button>
                            </div>
                            :
                            <>
                                <span className="icon">{obj_data.icon}</span>
                                <span className="label">{obj_data.title}</span>
                            </>
                        }
                    </div>
                </Link>
                :
                <a href={obj_data.atag()} target="_blank" rel="noopener noreferrer">
                    <div className="sb-header sb-list-header list-item-data">
                        <span className="icon">{obj_data.icon}</span>
                        <span className="label">{obj_data.title}</span>
                    </div>
                </a>
            }
        </li>
    )
}

SbProfilePageItem.propTypes = {
    obj_data: PropTypes.object
}

export default SbProfilePageItem
