import React, { useState } from 'react';

import DataStateCheckWrapper from '../general/DataStateCheckWrapper';
import PPMainContentBase from './PPMainContentBase';
import AboExtensionStudent from '../abo/AboExtensionStudent';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';
import useGetDBdata from '../../custom-hooks/useGetDBdata';
import { browserDateToGermanDateFormat, browserDTF2germanDTF } from '../../util/date_time_handler';

import AuthService from '../../util/authentication/auth_service';
import Usertypes from '../../util/Usertypes';

const URL = '/api/profiles/abo/profile-page/';

const PPAboSettings = () => {

    const [data, setData] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    
    const usertype = AuthService.getUsertype()
    const isStudentUser = usertype === Usertypes.student
    const isAboValid = AuthService.isAboValid()
    
    useGetDBdata(URL, setData, setIsFirstFetchSuccess, setErrorMsg)
    useSetBrowserTabTitle('Abo', true)

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            b_isContentSection={true}
            str_errorMsg={errorMsg}
            firstQueryDataEntry={1} /* Only placeholder value (data is always available here). */
        >
            {
                isStudentUser &&
                <PPMainContentBase str_header='Pro-Abo Status'>
                    <div className="pp-abo-details">
                        <div className="abo-data">
                            <div className="abo-validity nowrap">
                                <span className="key">status</span>
                                <span className="value">
                                    <span className='valid-text'>
                                        {
                                            isAboValid ? 'gültig' : 'ungültig'
                                        }
                                    </span>
                                </span>
                            </div>
                            <div className="abo-end">
                                <span className="key">Gültig bis</span>
                                <span className="value">
                                    {
                                        data &&
                                        browserDateToGermanDateFormat(data.abos[0].expirationDate)
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </PPMainContentBase>
            }

            {
                usertype === Usertypes.student &&
                <PPMainContentBase str_header='Pro-Abo Verlängerung'>
                    <AboExtensionStudent b_isProfilePage={true} />
                </PPMainContentBase>
            }

            <PPMainContentBase str_header={isStudentUser ? 'Pro-Abo Abos' : 'Abos'}>
                <div className='tb-wrapper'>
                    <table cellSpacing="0" className="tb-base text-left">
                        <thead>
                            <tr>
                                <td>Typ</td>
                                <td>Kaufdatum</td>
                                <td>Gültig bis</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data &&
                                data.abos.map((item, index) => (
                                    <tr key={index}>
                                        {
                                            usertype === Usertypes.gastronomer
                                            ?
                                            <td>1 Semester</td>
                                            :
                                            <td>{item.aboType} Monate</td>
                                        }
                                        <td>{browserDTF2germanDTF(item.purchaseTimestamp, false)}</td>
                                        <td>{browserDateToGermanDateFormat(item.expirationDate)}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </PPMainContentBase>
        </DataStateCheckWrapper>
    )
}

export default PPAboSettings
