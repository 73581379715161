import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import PrivatePage from '../../components/network/PrivatePage';
import HeaderAndFooter from '../../components/page-layout/HeaderAndFooter';
import PageBase from '../../components/page-layout/PageBase';

import Usertypes from '../../util/Usertypes';
import AuthService from '../../util/authentication/auth_service';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const AboExtensionPage = () => {
    
    const navigate = useNavigate()
    const [hasPermission, setHasPermission] = useState(false)

    const user = AuthService.getUser()

    useSetBrowserTabTitle('Aboverlängerung')

    useEffect(() => {
        if (!user) {
            navigate('/login')
        }
        else if (
            (user.usertype === Usertypes.gastronomer || user.usertype === Usertypes.company)
            && user.isAboValid
        ) {
            /* Gastronomer and company can only renew but not extend their abo. */
            navigate(AuthService.getProfileUrl())
        }
        else {
            setHasPermission(true)
        }
    }, [])
    
    return (
        hasPermission &&
        <PrivatePage b_checkAboValidity={false}>
            <HeaderAndFooter
                str_mainNavUserCat='profileSetup'
                b_hasMainNavLogoLink={user.isAboValid}
            >
                <PageBase>
                    <Outlet />
                </PageBase>
            </HeaderAndFooter>
        </PrivatePage>
    )
}

export default AboExtensionPage
