import React from 'react';
import PropTypes from 'prop-types';

import FormBaseContextProvider from '../../../context-provider/FormBaseContextProvider';
import FormBase from '../../../FormBase';
import InFieldsGroup from '../../../InFieldsGroup';
import TextField from '../../../fields/TextField';
import NumberField from '../../../fields/NumberField';
import DateField from '../../../fields/DateField';
import TimeSpanFields from '../../../fields/TimeSpanFields';
import BoolField from '../../../fields/BoolField';
import SingleChoiceField from '../../../fields/SingleChoiceField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { GastroFoodOffersFormData as FormData } from '../../../util/form-config-data/InputFormGastroDataConfigs';
import { dateFromTodayAsBrowserString, getWeekdayIndexEurope } from '../../../../../util/date_time_handler';

const computeNumDaysIntoFuture = () => {
    /** 
     * Computes the number of days that should be available in 
     * the datepicker. The coming days of this week and the next
     * week are considered.
     */
    const dateToday = new Date();
    const weekdayIndex = getWeekdayIndexEurope(dateToday);
    const daysIntoFuture = 13 - weekdayIndex;
    return daysIntoFuture;
}

const InputFormFoodOffers = ({
    b_isDgfs=false,
    b_hasRepeatOptions=true
}) => {
    const data = useInputData()
    return (
        <FormBaseContextProvider>
            <FormBase
                str_title='Neues Angebot'
            >
                <InFieldsGroup>
                    <TextField
                        {...FormData.fields.title}
                        str_chars={data[FormData.fields.title.str_id][0]}
                    />
                    <TextField
                        {...FormData.fields.description}
                        str_chars={data[FormData.fields.description.str_id][0]}
                    />
                </InFieldsGroup>
                <InFieldsGroup str_title='Konsummöglichkeiten'>
                    <BoolField
                        {...FormData.fields.hasLocalConsumption}
                        b_boolValue={data[FormData.fields.hasLocalConsumption.str_id][0]}
                    />
                    <BoolField
                        {...FormData.fields.hasPickupConsumption}
                        b_boolValue={data[FormData.fields.hasPickupConsumption.str_id][0]}
                    />
                    <BoolField
                        {...FormData.fields.hasDeliveryConsumption}
                        b_boolValue={data[FormData.fields.hasDeliveryConsumption.str_id][0]}
                    />
                </InFieldsGroup>
                <InFieldsGroup str_title='Vegetarisch oder Vegan'>
                    <BoolField
                        {...FormData.fields.isVegetarian}
                        b_boolValue={data[FormData.fields.isVegetarian.str_id][0]}
                    />
                    <BoolField
                        {...FormData.fields.isVegan}
                        b_boolValue={data[FormData.fields.isVegan.str_id][0]}
                    />
                </InFieldsGroup>
                <InFieldsGroup str_title='Preis und Verfügbarkeit'>
                    <NumberField
                        {...FormData.fields.price}
                        nbr_value={data[FormData.fields.price.str_id][0]}
                    />
                    <NumberField
                        {...FormData.fields.numAvailable}
                        nbr_value={data[FormData.fields.numAvailable.str_id][0]}
                    />
                </InFieldsGroup>
                <InFieldsGroup str_title='Wann' b_hasBorder={false}>
                    <DateField
                        {...FormData.fields.date}
                        str_date={data[FormData.fields.date.str_id][0]}
                        str_maxDate={b_isDgfs ? dateFromTodayAsBrowserString(1) : dateFromTodayAsBrowserString(computeNumDaysIntoFuture())}
                    />
                    <TimeSpanFields
                        {...FormData.fields.timeSpan}
                        str_startTime={data[FormData.fields.timeSpan.str_id][0][0]}
                        str_endTime={data[FormData.fields.timeSpan.str_id][0][1]}
                    />
                    {
                        b_hasRepeatOptions &&
                        <>
                            <SingleChoiceField
                                {...FormData.fields.repeatDays}
                                str_currentOption={data[FormData.fields.repeatDays.str_id][0]}
                            />
                            <BoolField
                                {...FormData.fields.repeatOnWeekends}
                                b_boolValue={data[FormData.fields.repeatOnWeekends.str_id][0]}
                                b_isFieldDisplayed={data[FormData.fields.repeatDays.str_id][0] === '1'}
                            />
                        </>
                    }
                </InFieldsGroup>
            </FormBase>
        </FormBaseContextProvider>
    )
}

InputFormFoodOffers.propTypes = {
    b_isDgfs: PropTypes.bool,
    b_hasRepeatOptions: PropTypes.bool
}

export default InputFormFoodOffers
