import React from 'react';
import PropTypes from 'prop-types';

import { extractFileNameFromPath } from '../../util/data_handler';

const MessageStdBodyFiles = ({
    arr_files /* Items are the file urls (str). */
}) => {
    return (
        arr_files && arr_files[0] &&
        <div className="msg-files">
            {
                arr_files.map((fileUrl, index) => (
                    fileUrl !== null && /* Back-end value for empty fiel field is null. */
                    <div key={index} className="msg-file">
                        <a href={fileUrl} target="blank" className="file-link">
                            {extractFileNameFromPath(fileUrl)}
                        </a>
                    </div>
                ))
            }
        </div>
    )
}

MessageStdBodyFiles.propTypes = {
    arr_files: PropTypes.array.isRequired
}

export default MessageStdBodyFiles
