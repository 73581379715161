/**
 * This hook adapts the main content page height according to the heigh of
 * the sidebar, i.e. it dynamically adjusts the page height to fit the whole
 * sidebar in it.
 * Without this component, sb items could disappear below the footer as the
 * page height is not large enough.
 */

import { useEffect } from "react";

export default function useSbSetMinPageHeight(sbItems) {
    useEffect(() => {
        /* Height of sb items. */
        const sbListHeight = document.querySelector('.sb-list').clientHeight
        /* Height of padding of sb wrapper. */
        const sbWrapperEl = document.querySelector('.sb-wrapper')
        const padTop = parseInt(window.getComputedStyle(sbWrapperEl, null)
                            .getPropertyValue('padding-top').slice(0, -2))
        const padBottom = parseInt(window.getComputedStyle(sbWrapperEl, null)
                            .getPropertyValue('padding-bottom').slice(0, -2))
        const padHeight = padTop + padBottom
        
        const height = sbListHeight + padHeight + 'px'
        /* Set height of element that has the styling of the min page height. */
        const el = document.querySelector('.page-sb-mc-grid')
        el.style.minHeight = height
    }, [sbItems])
}