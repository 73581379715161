import React from 'react';

import FormBase from '../../../input-forms/FormBase';
import InFieldsGroup from '../../../input-forms/InFieldsGroup';
import NumberField from '../../../input-forms/fields/NumberField';

import { useInputData } from '../../../input-forms/context-provider/InputFormContextProvider';
import { FilterFormMiningData as FormData } from '../../../input-forms/util/form-config-data/InputFormFilterDataConfigs';

const FilterFormMining = () => {
    const data = useInputData()
    return (
        <FormBase
            b_hasDarkDesign={true}
            b_isFilterForm={true}
        >
            <InFieldsGroup b_hasBorder={false}>
                <NumberField
                    {...FormData.fields.position}
                    nbr_value={data[FormData.fields.position.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
};

export default FilterFormMining
