/**
 * Contains the configs of all filter input forms. 
 */
import BottomTexts from '../input_form_bottom_info_texts.json';
import FieldLimits from '../db_field_limits.json';
import { genFieldData } from './InputFormBaseDataAndSerializer';
import { dateFromTodayAsBrowserString } from '../../../../util/date_time_handler';
import { createAbc } from '../../../../util/data_creator';

/* Variables. */

const KEYWORD_PLACEHOLDER = 'Suche nach Schlüsselwort ...';
const TEXTFIELD_MIN_LEN = 3;
const TEXTFIELD_MAX_LEN = 8;

const TAG_CATEGORIES = ['Unterlagen', 'Prüfungen', 'Übungen', 'Forum'];
const TAG_ALLOWED_CHARS = [...createAbc(true, true), '-'];
const TAG_FILTER_FIELD_URL = '/api/courses/tags/';

const MAX_NUM_TAG_PROPOSALS = 2;
const TAG_PROPOSALS_BOTTOM_INFO_TEXT = `Wähle bis zu ${MAX_NUM_TAG_PROPOSALS} Tags gleichzeitig. Nur Kleinbuchstaben ohne Umlaute und Bindestriche erlaubt.`

const CompanyNameConfig = genFieldData('text', {
    str_id: 'companyName',
    str_fieldTitle: 'Firmenname',
    str_placeholder: KEYWORD_PLACEHOLDER,
    int_minLength: TEXTFIELD_MIN_LEN,
    int_maxLength: TEXTFIELD_MAX_LEN,
    b_isRequired: false,
    b_isTextarea: false
});

const JOB_TIME_MODELS = FieldLimits.work.job.timeModels.slice(0, FieldLimits.work.job.timeModels.length-1);

/* Forms. */

export const FilterFormStdTextFieldData = {
    str_localStorageKey: 'FilterFormStdTextFieldData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        keyword: ['', false]
    },
    fields: {
        keyword: genFieldData('text', {
            str_id: 'keyword',
            str_placeholder: KEYWORD_PLACEHOLDER,
            int_minLength: TEXTFIELD_MIN_LEN,
            int_maxLength: TEXTFIELD_MAX_LEN,
            b_isTextarea: false,
            b_isRequired: false
        })
    }
};

export const FilterFormIndustryJobData = {
    str_localStorageKey: 'FilterFormIndustryJobData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        companyName  : ['', false],
        isInternship : ['', false],
        thesisOptions: ['', false],
        timeModel    : ['', false],
        startDate    : ['', false],
        isAbroad     : ['', false],
        county       : ['', false],
        district     : ['', false]
    },
    fields: {
        companyName: CompanyNameConfig,
        isInternship: genFieldData('sc', {
            str_id: 'isInternship',
            str_fieldTitle: 'Praktikum',
            arr_options: ['Ja', 'Nein'],
            b_isRequired: false
        }),
        thesisOptions: genFieldData('sc', {
            str_id: 'thesisOptions',
            str_fieldTitle: 'Abschlussarbeit',
            arr_options: ['BSc', 'MSc', 'PhD'],
            b_isRequired: false
        }),
        timeModel: genFieldData('sc', {
            str_id: 'timeModel',
            str_fieldTitle: 'Arbeitszeitmodell',
            arr_options: JOB_TIME_MODELS,
            b_isRequired: false
        }),
        startDate: genFieldData('date', {
            str_id: 'startDate',
            str_fieldTitle: 'Jobstart ab',
            str_minDate: dateFromTodayAsBrowserString(),
            str_maxDate: dateFromTodayAsBrowserString(365),
            b_displayTopInfoText: false,
            b_displayBottomInfoText: false
        }),
        isAbroad: genFieldData('sc', {
            str_id: 'isAbroad'
        }),
        county: genFieldData('sc', {
            str_id: 'county'
        }),
        district: genFieldData('sc', {
            str_id: 'district'
        })
    }
};

export const FilterFormIndustryCompanyData = {
    str_localStorageKey: 'FilterFormIndustryJobData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        companyName    : [''       , false],
        isAbroad       : [''       , false],
        county         : [''       , false],
        district       : [''       , false],
        minNumEmployees: [undefined, false]
    },
    fields: {
        companyName: CompanyNameConfig,
        isAbroad: genFieldData('sc', {
            str_id: 'isAbroad'
        }),
        county: genFieldData('sc', {
            str_id: 'county'
        }),
        district: genFieldData('sc', {
            str_id: 'district'
        }),
        minNumEmployees: genFieldData('nbr', {
            str_id: 'minNumEmployees',
            str_fieldTitle: 'Min. Anzahl Mitarbeiter',
            nbr_minValue: 1,
            nbr_maxValue: 100000,
            b_isIntField: true,
            b_isRequired: false,
            b_isTopInfoTextDisplayed: false
        })
    }
};

export const FilterFormCourseSwapData = {
    str_localStorageKey: 'FilterFormCourseSwapData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        courseTitle:  ['',        false],
        targetGroup:  [undefined, false]
    },
    fields: {
        courseTitle: genFieldData('text', {
            str_id: 'courseTitle',
            str_fieldTitle: 'Kurs',
            str_placeholder: KEYWORD_PLACEHOLDER,
            int_minLength: TEXTFIELD_MIN_LEN,
            int_maxLength: TEXTFIELD_MAX_LEN,
            b_isTextarea: false,
            b_isRequired: false
        }),
        targetGroup: genFieldData('nbr', {
            str_id: 'targetGroup',
            str_fieldTitle: 'Meine Zielgruppe',
            nbr_minValue: FieldLimits.course.group.min,
            nbr_maxValue: FieldLimits.course.group.max,
            b_isRequired: false,
            str_bottomInfoText: BottomTexts.course.swap.filter.targetGroup
        })
    }
};

export const FilterFormMiningData = {
    str_localStorageKey: 'FilterFormMiningData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        position: [undefined, false]
    },
    fields: {
        position: genFieldData('nbr', {
            str_id: 'position',
            str_fieldTitle: 'Position',
            b_isRequired: false,
            nbr_minValue: 1,
            nbr_maxValue: 5000,
            str_bottomInfoText: BottomTexts.mining.positionFilter
        })
    }
};

export const FilterFormCoursesData = {
    str_localStorageKey: 'FilterFormCoursesData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        courseName: ['', false]
    },
    fields: {
        courseName: genFieldData('text', {
            str_id: 'courseName',
            str_fieldTitle: 'Kursname',
            str_placeholder: KEYWORD_PLACEHOLDER,
            int_minLength: TEXTFIELD_MIN_LEN,
            int_maxLength: TEXTFIELD_MAX_LEN,
            b_isTextarea: false,
            b_isRequired: false
        })
    }
};

export const FilterFormForumData = {
    str_localStorageKey: 'FilterFormForumData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        keyword:    ['', false],
        latestDate: ['', false]
    },
    fields: {
        keyword: genFieldData('text', {
            str_id: 'keyword',
            str_fieldTitle: 'Textsuche',
            str_placeholder: KEYWORD_PLACEHOLDER,
            int_minLength: TEXTFIELD_MIN_LEN,
            int_maxLength: TEXTFIELD_MAX_LEN,
            b_isTextarea: false,
            b_isRequired: false
        }),
        latestDate: genFieldData('date', {
            str_id: 'latestDate',
            str_fieldTitle: 'Aktuellstes Datum',
            str_minDate: '',
            str_maxDate: dateFromTodayAsBrowserString(),
            str_bottomInfoText: BottomTexts.filter.earliestSearchDate,
            b_isRequired: false
        })
    }
};

export const FilterFormTagsData = {
    str_localStorageKey: 'FilterFormTagsData',
    str_queryURL: '',
    str_navigationURLonDiscard: '',
    obj_initState: {
        tags      :      [[], true ],
        categories:      ['', true ],
        courseSelection: ['', true ],
        selectedCourses: [[], false]
    },
    fields: {
        tags: {
            str_id: 'tags',
            str_fieldTitle: 'Tags',
            str_bottomInfoText: TAG_PROPOSALS_BOTTOM_INFO_TEXT,
            str_proposalQueryUrl: TAG_FILTER_FIELD_URL,
            str_requestDataKey: 'tagname',
            str_charKey: 'chars',
            int_maxNumProposals: MAX_NUM_TAG_PROPOSALS,
            int_minLength: 3,
            int_maxLength: 12,
            arr_allowedChars: TAG_ALLOWED_CHARS,
            b_isRequired: true
        },
        categories: genFieldData('sc', {
            str_id: 'categories',
            str_fieldTitle: 'Kategorie',
            arr_options: TAG_CATEGORIES,
            b_displayTopInfoText: false
        }),
        courseSelection: { str_id: 'courseSelection' },
        selectedCourses: { str_id: 'selectedCourses' }
    }
};
