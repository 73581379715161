import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import DataStateCheckWrapper from '../../../general/DataStateCheckWrapper';
import CSecBase from '../../../content-sections/CSecBase';
import PPFormBase from '../PPFormBase';
import InputFormFoodOffers from '../../forms/gastro/food-offers/InputFormFoodOffers';
import NoPPDataAvailable from '../../../profile-pages/NoPPDataAvailable';

import { GastroFoodOffersFormData as FormData } from '../../util/form-config-data/InputFormGastroDataConfigs';
import {
    useOffersThisWeekContext,
    useOffersNextWeekContext,
    useOffersOldContext,
    useAddNewOfferContext
} from '../../../profile-pages/gastronomy/context-provider/GastroPPFoodOffersContextProvider';
import {
    useDgfsTodayContext,
    useDgfsTomorrowContext,
    useDgfsOldContext,
    useAddNewDgfsOfferContext
} from '../../../profile-pages/gastronomy/context-provider/GastroPPDgfsContextProvider';
import { static2dynamicPanelConfig } from '../../util/input_form_data_handler';
import { db2inputFormData } from '../../util/input_form_data_handler';
import { fetchDBdata } from '../../../../util/db_ls_query_handler';

const MAX_NUM_OLD_OFFERS = 20;
const MSG_TOO_MANY_OLD_OFFERS = 'Es können keine neuen Angebote erstellt werden, da die momentane Anzahl deiner ' +
    'alten Angebote das Limit von 20 übersteigt. Bitte lösche soviele deiner alten Angebote, sodass max. 20 existieren. ' +
    'Danach kannst du wieder Angebote erstellen.';

const MAX_NUM_PLANNED_OFFERS = 10;
const MSG_TOO_MANY_PLANNED_OFFERS_REGULAR = 'Es können keine neuen Angebote erstellt werden, da die momentane Anzahl deiner ' +
    'geplanten Angebote die max. Anzahl von 10 erreicht hat. Sobald mit Beginn der nächsten Woche weniger als 10 Angebote ' +
    'geplant sind, können wieder neue Angebote erstellt werden.';
const MSG_TOO_MANY_PLANNED_OFFERS_DGFS = 'Es können keine neuen Angebote erstellt werden, da die momentane Anzahl deiner ' +
    'geplanten Angebote die max. Anzahl von 10 erreicht hat. Sobald mit dem morgigen Tag weniger als 10 Angebote ' +
    'geplant sind, können wieder neue Angebote erstellt werden.';

const LIST_URL = '/api/gastronomy/gastronomer/food-offers/';
const DETAIL_BASE_URL = '/api/gastronomy/gastronomer/food-offers/detail/';

const getNumPlannedOffersRegular = (obj_offers1, obj_offers2) => {
    let numOffers = 0;
    /* Count only if obj is not null. */
    if (obj_offers1)
        Object.values(obj_offers1).forEach(item => numOffers += item.length);
    if (obj_offers2)
        Object.values(obj_offers2).forEach(item => numOffers += item.length);
    return numOffers;
}

const getNumPlannedOffersDgfs = (arr_offers1, arr_offers2) => {
    let numOffers = 0;
    if (arr_offers1)
        numOffers += arr_offers1.length;
    if (arr_offers2)
        numOffers += arr_offers2.length;
    return numOffers;
}

const genQueryURL = (b_isDgfs) => {
    if (b_isDgfs) return LIST_URL + 'dgfs/';
    return LIST_URL + 'regular/'
}

const FoodOffersForm = ({
    b_isDgfs=false,    /* If false, regular offers are queried. */
    b_hasRepeatOptions /* Dgfs offers do not have a repeat option. */
}) => {
    
    const params = useParams()
    const [data, setData] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    const [hasQuery, setHasQuery] = useState(true) /* True first to show 'loading' while query decision is made. */
    
    /* Regular food offers. */
    const offersThisWeek = useOffersThisWeekContext()
    const offersNextWeek = useOffersNextWeekContext()
    const oldOffers = useOffersOldContext()
    const addNewRegularOffer = useAddNewOfferContext()
    /* Dgfs. */
    const dgfsToday = useDgfsTodayContext()
    const dgfsTomorrow = useDgfsTomorrowContext()
    const oldDgfs = useDgfsOldContext()
    const addNewDgfsOffer = useAddNewDgfsOfferContext()

    const queryURL = genQueryURL(b_isDgfs)
    
    /* Check data num. limits to know if user is allowed to create a new food offer. */
    const numOffersPlanned = offersThisWeek ?
                             getNumPlannedOffersRegular(offersThisWeek, offersNextWeek) :
                             getNumPlannedOffersDgfs(dgfsToday, dgfsTomorrow)
    const numOldOffers = offersThisWeek ? oldOffers.length : oldDgfs.length

    /* Configure form data. */

    useEffect(() => {
        const fetchFormData = async () => {
            const queryData = await fetchDBdata(DETAIL_BASE_URL + `${params.offerId}/`)
            if (queryData.isQuerySuccess) {
                setData(queryData.response.data)
                setIsFirstFetchSuccess(true)
            } else {
                setErrorMsg(queryData.errorMsg)
                setIsFirstFetchSuccess(false)
            }
        }

        /* Check if DB query to reuse data should be executed. */
        const hasQuery = params.offerId !== undefined &&
            numOldOffers < MAX_NUM_OLD_OFFERS &&
            numOffersPlanned < MAX_NUM_PLANNED_OFFERS
        
        if (hasQuery) {
            fetchFormData()
        } else {
            setHasQuery(false) /* Render form instead of displaying the 'loading ...' message. */
        }
    }, [])

    let dynConfig = { str_queryURL: queryURL }
    /* If an old offer is reused, then overwrite the initState with this data (it is correct). */
    if (hasQuery && isFirstFetchSuccess)
        dynConfig['obj_initState'] = db2inputFormData(data)

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={hasQuery}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            firstQueryDataEntry={-1} /* Only a placeholder to render the children. */
            str_errorMsg={errorMsg}
            b_isContentSection={true}
        >
            {
                numOldOffers >= MAX_NUM_OLD_OFFERS
                ?
                <CSecBase>
                    <NoPPDataAvailable str_message={MSG_TOO_MANY_OLD_OFFERS} />
                </CSecBase>
                :
                numOffersPlanned >= MAX_NUM_PLANNED_OFFERS
                ?
                <CSecBase>
                    <NoPPDataAvailable str_message={
                        b_isDgfs ?
                        MSG_TOO_MANY_PLANNED_OFFERS_DGFS :
                        MSG_TOO_MANY_PLANNED_OFFERS_REGULAR}
                    />
                </CSecBase>
                :
                <PPFormBase
                    {...static2dynamicPanelConfig(FormData, dynConfig)}
                    formChild={<InputFormFoodOffers b_isDgfs={b_isDgfs} b_hasRepeatOptions={b_hasRepeatOptions} />}
                    str_requestType='post'
                    fct_response2parent={b_isDgfs ? addNewDgfsOffer : addNewRegularOffer}
                />
            }
        </DataStateCheckWrapper>
    )
}

FoodOffersForm.propTypes = {
    b_isDgfs: PropTypes.bool,
    b_hasRepeatOptions: PropTypes.bool
}

export default FoodOffersForm
