import React from 'react';
import { Outlet } from 'react-router-dom';

import PageBase from '../../components/page-layout/PageBase';
import FilterStateContextProvider from '../../components/filters/context-provider/FilterStateContextProvider';
import FilterCacheDynamicContentLoadContextProvider from '../../components/context-provider/FilterCacheDynamicContentLoadContextProvider';
import DiscussionCacheContextProvider from '../../components/discussion-pages/context-provider/DiscussionCacheContextProvider';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const TagsPage = () => {
    useSetBrowserTabTitle('Tags')
    return (
        <PageBase>
            <FilterStateContextProvider>
                <FilterCacheDynamicContentLoadContextProvider>
                    <DiscussionCacheContextProvider>
                        <Outlet />
                    </DiscussionCacheContextProvider>
                </FilterCacheDynamicContentLoadContextProvider>
            </FilterStateContextProvider>
        </PageBase>
    )
}

export default TagsPage
