import React from 'react';
import PropTypes from 'prop-types';

import MessageStdHeader from './MessageStdHeader';
import MessageStdBody from './MessageStdBody';
import MessageStdFooter from './MessageStdFooter';
import MessageComments from './MessageComments';
import PanelPost from '../post-panels/PanelPost';

import {
    useStaticFormConfigContext,
    useTextIndicatorsContext,
    useHasDarkDesignContext,
    useUserContext,
    useBaseUrlsContext
} from './context-provider/MessagesContextProvider';
import {
    useIsCommentFormOpenContext,
    useIsMsgBodyOpenContext,
    useRequestTypeContext,
    useIsMsgOwnerContext,
    useMsgContext
} from './context-provider/MessageContextProvider';
import MessageService from './util/message_service';

const Message = ({
    b_hasTextMarking=true,
    comp_body,
    comp_footer
}) => {

    const staticFormConfig = useStaticFormConfigContext()
    const user = useUserContext()
    const textIndicators = useTextIndicatorsContext()
    const hasDarkDesign = useHasDarkDesignContext()
    const baseUrls = useBaseUrlsContext()
    
    const msg = useMsgContext()
    const isOwner = useIsMsgOwnerContext()
    const isCommentFormOpen = useIsCommentFormOpenContext()
    const isBodyOpen = useIsMsgBodyOpenContext()
    const requestType = useRequestTypeContext()

    return (
        msg &&
        <div className="msg">
            <MessageStdHeader />
            
            {
                isBodyOpen && (
                    comp_body
                    ?
                    comp_body
                    :
                    <MessageStdBody b_hasTextMarking={b_hasTextMarking} />
                )
            }

            {
                isBodyOpen && msg.comments && msg.comments[0] !== undefined &&
                <MessageComments arr_comments={msg.comments} />
            }

            {
                /* Post panel to create a comment. */
                isCommentFormOpen &&
                <PanelPost
                    obj_staticConfig={staticFormConfig}
                    obj_dynConfig={MessageService.genDynPostPanelConfig(
                        msg.id, msg.category, msg.text, msg.files,
                        isOwner, baseUrls, staticFormConfig
                    )}
                    arr_indicators={textIndicators}
                    str_requestType={requestType}
                    str_firstName={user.firstName}
                    str_lastName={user.lastName}
                    str_username={user.username}
                    b_hasDarkDesign={hasDarkDesign}
                />
            }

            {
                comp_footer
                ?
                comp_footer
                :
                <MessageStdFooter />
            }
        </div>
    )
}

Message.propTypes = {
    b_hasTextMarking: PropTypes.bool
}

export default Message
