import React from 'react';
import { useParams } from 'react-router-dom';

import CSecOverviewBackLink from '../../components/content-sections/CSecOverviewBackLink';
import DynamicContentLoadContextProvider from '../../components/context-provider/DynamicContentLoadContextProvider';
import DiscussionContextProvider from '../../components/discussion-pages/context-provider/DiscussionContextProvider';
import Discussion from '../../components/discussion-pages/Discussion';

import DiscussionConfigs from '../../components/discussion-pages/DiscussionConfigs';
import DynLoadPackageSizes from '../../util/config/data_load_package_sizes.json';

import { getFormData } from '../../components/input-forms/util/form-config-data/InputFormPanelDataConfigs';
import { genDynUrl } from '../../util/db_ls_query_handler';

const FORUM_MSG_BASE_URL = '/api/courseforums/thread/forum-msg/';
const FORUM_MSG_VOTE_BASE_URL = '/api/courseforums/vote/';
const BASE_LIST_URL = '/api/courseforums/thread/list/';
const BASE_POST_URL = '/api/courseforums/thread/new-message/';
const MSG_COMMENT_BASE_URL = '/api/courseforums/thread/new-comment/';
const MSG_VOTE_BASE_URL = '/api/courseforums/thread/vote/';

const PROP_BACKLINK_TEXT = 'Zurück zum Forum';

const CourseForumThreadPage = ({ str_backLinkText=PROP_BACKLINK_TEXT }) => {
    const params = useParams()
    const discussMsgQueryUrl = genDynUrl(FORUM_MSG_BASE_URL, params.forumMsgId)
    const threadMsgsListUrl = genDynUrl(BASE_LIST_URL, params.forumMsgId)
    const newThreadMsgPostUrl = genDynUrl(BASE_POST_URL, params.forumMsgId)
    const postPanelFormConfig = getFormData(
        DiscussionConfigs.CourseForumsDiscussion.formMsgConfig,
        DiscussionConfigs.CourseForumsDiscussion.fileFieldConfig
    )
    const dynConfig = { str_queryURL: newThreadMsgPostUrl }
    return (
        <>
            <CSecOverviewBackLink str_linkText={str_backLinkText} />
            <DynamicContentLoadContextProvider
                str_queryURL={threadMsgsListUrl}
                b_hasInfiniteScroll={true}
                nbr_packageSize={DynLoadPackageSizes.courseforums.threadMsgs}
            >
                <DiscussionContextProvider>
                    <Discussion
                        obj_postPanelFormConfig={postPanelFormConfig}
                        obj_postPanelDynConfig={dynConfig}
                        str_discussMsgUrl={discussMsgQueryUrl}
                        str_discussMsgVoteBaseUrl={FORUM_MSG_VOTE_BASE_URL}
                        b_hasDiscussMsgTextMarking={true}
                        str_msgCommentBaseUrl={MSG_COMMENT_BASE_URL}
                        str_msgVoteBaseUrl={MSG_VOTE_BASE_URL}
                        b_isMsgBodyHideable={false}
                        b_isMsgCommentable={true}
                        b_isMsgEditable={false}
                    />
                </DiscussionContextProvider>
            </DynamicContentLoadContextProvider>
        </>
    )
}

export default CourseForumThreadPage
