import React, { useEffect } from 'react';

import InputFormPageBase from '../../components/input-forms/InputFormPageBase';
import InputFormBugReporting from '../../components/input-forms/forms/contact/InputFormBugReporting';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';
import { static2dynamicPanelConfig } from '../../components/input-forms/util/input_form_data_handler';

import { BugReportingFormData as FormData } from '../../components/input-forms/util/form-config-data/InputFormContactDataConfigs';

const DYN_CONFIG = { str_queryURL: '/api/contact/bugs/list/' };

const BugReportingPage = () => {
    useSetBrowserTabTitle('Feedback - Fehlerbehebung')
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <InputFormPageBase
            {...static2dynamicPanelConfig(FormData, DYN_CONFIG)}
            comp_form={<InputFormBugReporting />}
            str_requestType='post'
            b_hasMediaData={true}
            b_hasDiscardBtn={false}
        />
    )
}

export default BugReportingPage
