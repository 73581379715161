/**
 * This file entails the configuration of all user profile sidebars.
 * 
 * The 'title' is what is displayed on the front end (innerHTML). 
 * The 'name' is used to highlight the currently active item of the navbar. 
 * The 'name' must be featured in the URL, as the JS code checks the URL against
 *  the 'name' and if it finds it, sets the active styling to this item.
 * The 'isFreeTier' decideds if the sb item is also visible if the user does not own
 *  a valid abo.
 */
import React from 'react';
import { RiUserFill, RiTeamFill } from 'react-icons/ri';
import { FaGraduationCap, FaLeaf, FaIndustry } from 'react-icons/fa';
import { GoPin } from 'react-icons/go';
import { MdOutlineArrowForwardIos, MdAutorenew, MdOutlineFastfood, MdOutlineAttachMoney, MdCreate } from 'react-icons/md';
import { VscGitMerge } from 'react-icons/vsc';
import { CgLock, CgFileDocument } from 'react-icons/cg';
import { BiBookOpen } from 'react-icons/bi';
import { BsChatDots } from 'react-icons/bs';
import AuthService from '../../../util/authentication/auth_service';
import Usertypes from '../../../util/Usertypes';

const genInfoPageUrl = () => {
    /**
     * Generate the URL that lets a user open his account's info page form the pp.
     */
    const user = AuthService.getUser();
    const usertype = user.usertype
    if (usertype === Usertypes.company) {
        return `/company/info-page/${user.firstName}/${user.id}`;
    } else if (usertype === Usertypes.gastronomer) {
        return `/gastronomer/info-page/${user.firstName}/${user.id}`;
    } else {
        return '';
    }
}

/* Standard items. */

const obj_abo = {
    icon: <MdAutorenew />,
    link: 'abo',
    title: 'abo',
    name: 'abo',
    isFreeTier: true,
    isButton: false
};

const obj_password = {
    icon: <CgLock />,
    title: 'Passwort',
    name: 'password',
    link: 'password/change',
    isFreeTier: true,
    isButton: false
};

const obj_contact = {
    icon: <BsChatDots />,
    title: 'Kontakt',
    name: 'contact',
    link: 'contact',
    isFreeTier: false,
    isButton: false
};

export const SbDataProfilePages = {
    student: [
        {
            icon: <CgFileDocument />,
            title: 'Meine Unterlagen',
            name: 'my-docs',
            link: 'course/my-docs',
            isFreeTier: false
        },
        {
            icon: <BsChatDots />,
            title: 'Meine Threads',
            name: 'my-threads',
            link: 'course/my-threads',
            isFreeTier: false
        },
        {
            icon: <FaGraduationCap />,
            title: 'Fixierte Kurse',
            name: 'course/pinned',
            link: 'course/pinned',
            isFreeTier: false
        },
        {
            icon: <FaIndustry />,
            title: 'Fixierte Jobs',
            name: 'pinned-jobs',
            link: 'pinned-jobs',
            isFreeTier: true
        },
        {   
            icon:  <VscGitMerge />,
            title: 'Firmenakquise',
            name: 'acquisition',
            link: 'company-acquisitions',
            isFreeTier: false
        },
        {
            icon: <MdOutlineFastfood />,
            title: 'Essen & Trinken',
            name: 'food-offers/regular',
            link: 'food-offers/regular/this-week',
            isFreeTier: false
        },
        {
            icon: <FaLeaf />,
            title: 'DoG4S',
            name: 'good-for-students',
            link: 'food-offers/do-good-for-students/today',
            isFreeTier: false
        },
        {
            icon: <MdOutlineAttachMoney />,
            title: 'Gutscheine',
            name: 'coupons',
            link: 'coupons',
            isFreeTier: false
        },
        obj_abo,
        obj_password
    ],
    gastronomer: [
        {
            icon: <MdOutlineArrowForwardIos />, /* Navbar icon, to the left of the text. */
            atag: genInfoPageUrl,               /* Link to a page on click. */
            title: 'Meine Infoseite',           /* Sb item text. */
            name: 'show my page',               /* Sb item string to identify the active item. */
            isButton: false                     /* Is item a button or a normal text. */
        },
        {
            icon: <RiUserFill />,
            title: 'info',
            name: 'info',
            link: 'info',
            isButton: false
        },
        obj_contact,
        {
            icon: <BiBookOpen />,
            title: 'Karten',
            name: 'menus',
            link: 'menus'
        },
        {
            icon: <MdOutlineFastfood />,
            title: 'Angebote',
            name: 'food-offer/regular',
            link: 'food-offer/regular/this-week',
        },
        {
            icon: <FaLeaf />,
            title: 'DoG4S',
            name: 'food-offer/do-good',
            link: 'food-offer/do-good-for-students/today',
        },
        {
            icon: <MdOutlineAttachMoney />,
            title: 'Gutscheine',
            name: 'coupons',
            link: 'coupons/this-semester'
        }
    ],
    company: [
        {
            icon: <MdOutlineArrowForwardIos />,
            atag: genInfoPageUrl,
            title: 'Meine Infoseite',
            name: 'show my page',
            isButton: false
        },
        {
            icon: <RiUserFill />,
            title: 'info',
            name: 'info',
            link: 'info',
            isButton: false
        },
        obj_contact,
        {
            icon: <RiTeamFill />,
            title: 'Weitere Accounts',
            name: 'associates',
            link: 'associates',
            isButton: false
        },
        {
            icon: <FaGraduationCap />,
            title: 'Praktikumsoptionen',
            name: 'internship options',
            link: 'internship-options',
            isButton: false
        },
        {
            icon: <GoPin />,
            title: 'Jobs',
            name: 'job postings',
            link: 'job-postings',
            isButton: false
        },
        {
            icon: <MdCreate />,
            title: 'Neuer Job',
            name: 'new job posting',
            link: 'new-job-posting',
            isButton: false
        }
    ]
}