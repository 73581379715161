/**
 * This component stores the data that is rendered by the
 * sidebar of corse task pages.
 * 
 * It is the sink where changed data flows in and updates
 * the data of the sidebar. E.g. if a new task (e.g. exam) is
 * added, it is updated in this component to render out the
 * latest state of the data.
 */

import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';

import useGetLSorDBdata from '../../../custom-hooks/useGetLSorDBdata';
import { genDynUrl } from '../../../util/db_ls_query_handler';
import { german2isoDate } from '../../../util/date_time_handler';

const TasksContext = React.createContext();
const AddTaskContext = React.createContext();
const AddSubtaskContext = React.createContext();
const IncrementSubtaskMsgNumContext = React.createContext();
const UpdateTaskDateContext = React.createContext();

export function useTasksContext() {
    return useContext(TasksContext);
}

export function useAddTaskContext() {
    return useContext(AddTaskContext);
}

export function useAddSubtaskContext() {
    return useContext(AddSubtaskContext);
}

export function useIncrementSubtaskMsgNumContext() {
    return useContext(IncrementSubtaskMsgNumContext);
}

export function useUpdateTaskDateContext() {
    return useContext(UpdateTaskDateContext);
}

const SbCourseTaskContextProvider = ({
    str_baseUrl,
    children
}) => {
    
    const params = useParams()
    const [tasks, setTasks] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    /* Query data from DB (no LS usage). */
    const url = genDynUrl(genDynUrl(str_baseUrl, params.id), params.year)
    useGetLSorDBdata(url, setTasks, setIsFirstFetchSuccess, setErrorMsg)

    /* Task update functions. */

    const addTask = (task) => {
        /**
         * Adds a new task to tasks.
         * The task is added so that the ascending order wrt the
         * task date is preserved.
         * :Input
         *  task (obj): Data of the new task.
         */
        const taskDate = Date.parse(german2isoDate(task.date))
        const len = tasks.length
        for (let i = 0; i < len; i++) {
            const otherTaskDate = Date.parse(german2isoDate(tasks[i].date))
            if (otherTaskDate > taskDate) {
                const newTasks = [...tasks.slice(0, i), task, ...tasks.slice(i)]
                setTasks(newTasks)
                return
            }
        }
        /* Task must be appended. */
        const newTasks = [...tasks, task]
        setTasks(newTasks)
    }

    const addSubtask = (taskId, subtask) => {
        /**
         * Adds a new subtask to the task of id taskId.
         * The subtask is added so that the ascending order wrt the
         * subtask category and number is preserved. 
         * :Input
         *  subtask (obj): Data of the new subtask.
         */
        let newQuestions
        const questions = tasks.filter(task => task.id === taskId)[0].questions
        const refStr = subtask.category + subtask.number
        const len = questions.length
        for (let i = 0; i < len; i++) {
            const question = questions[i]
            if (subtask.isExerciseTestQuestion === question.isExerciseTestQuestion) {
                const compStr = question.category + question.number
                const res = refStr.localeCompare(compStr)
                if (res < 0) {
                    /* refStr occurs before compStr. */
                    newQuestions = [...questions.slice(0, i), subtask, ...questions.slice(i)]
                    break
                }
            }
        }
        /* Check if question must be appended. */
        if (!newQuestions) {
            /* Test question are positioned after normal question.s */
            if (subtask.isExerciseTestQuestion) {
                newQuestions = [...questions, subtask]
            } else {
                /* Count how many non-test question are stored. */
                let cnt = 0
                for (let i = 0; i < len; i++) {
                    if (!questions[i].isExerciseTestQuestion) {
                        cnt++
                    }
                }
                newQuestions = [...questions.slice(0, cnt), subtask, ...questions.slice(cnt)]
            }
        }
        updateTaskQuestions(taskId, newQuestions)
    }

    const incrementSubtaskMsgNum = (subtaskId) => {
        /**
         * Increments the number of messages a subtask holds.
         */
        let isFound = false
        let updateTaskId, updatedQuestions
        const lenTasks = tasks.length
        for (let i = 0; i < lenTasks; i++) {
            const questions = tasks[i].questions
            let lenQuestions = questions.length
            for (let j = 0; j < lenQuestions; j++) {
                if (questions[j].id === subtaskId) {
                    const updatedQuestion = {...questions[j], numMsgs: ++questions[j].numMsgs}
                    updatedQuestions = [...questions.slice(0, j), updatedQuestion, ...questions.slice(j+1)]
                    updateTaskId = tasks[i].id
                    isFound = true
                    break
                }
            }
            if (isFound) break
        }
        updateTaskQuestions(updateTaskId, updatedQuestions)
    }

    const updateTaskDate = (taskId, date) => {
        /**
         * Updates the date of an existing task.
         * This is necessary if a user chagnes the date of a task on the front-end.
         */
        setTasks(
            tasks.map(task => {
                if (task.id === taskId) return {...task, date: date}
                return task
            })
        )
    }

    /* Helper functions. */

    const updateTaskQuestions = (taskId, updatedQuestions) => {
        /**
         * The questions of task taskId are replaced by updatedQuestions.
         * Sets the task state with this update.
         */
        setTasks(
            tasks.map(task => {
                if (task.id === taskId) return {...task, questions: updatedQuestions}
                return task
            })
        )
    }
    
    return (
        /* If data query fails, do not permit any sb functionality. */
        <DataStateCheckWrapper
            firstQueryDataEntry={-1} /* Placeholder, no data is not shown in the sb (just empty). */
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            str_errorMsg={errorMsg}
            b_hasContentSectionDarkDesign={true}
            b_isContentSection={true}
        >
            <TasksContext.Provider value={tasks}>
                <AddTaskContext.Provider value={addTask}>
                    <AddSubtaskContext.Provider value={addSubtask}>
                        <IncrementSubtaskMsgNumContext.Provider value={incrementSubtaskMsgNum}>
                            <UpdateTaskDateContext.Provider value={updateTaskDate}>
                                {children}
                            </UpdateTaskDateContext.Provider>
                        </IncrementSubtaskMsgNumContext.Provider>
                    </AddSubtaskContext.Provider>
                </AddTaskContext.Provider>
            </TasksContext.Provider>
        </DataStateCheckWrapper>
    )
}

SbCourseTaskContextProvider.propTypes = {
    str_baseUrl: PropTypes.string.isRequired
}

export default SbCourseTaskContextProvider
