import React from 'react';

import {
    useQueriedDataContext,
    useIsAllDataLoadedContext,
    useIsFirstFetchSuccessContext,
    useInfinteScrollErrorLimReachedContext
} from '../context-provider/DynamicContentLoadContextProvider';
import { QueryVariables } from '../../util/QueryVariables';


const InfiniteScrollInfoMessages = () => {
    
    const queriedData = useQueriedDataContext()
    const isAllDataLoadedRef = useIsAllDataLoadedContext()
    const isFirstFetchSuccessRef = useIsFirstFetchSuccessContext()
    const isInfScrollErrLimReachedRef = useInfinteScrollErrorLimReachedContext()

    return (
        <div className="dyn-content-load-msg">
            {
                isFirstFetchSuccessRef.current
                ?
                    isInfScrollErrLimReachedRef.current
                    ?
                    <p>{QueryVariables.str_queryFailedMsg}</p>
                    :
                    isAllDataLoadedRef.current && queriedData[0] &&
                    <p>{QueryVariables.str_allDataLoadedMsg}</p>
                :
                '' /* Do not show info if first fetch fails. Other comps handle message. */
            }
        </div>
    )
}

export default InfiniteScrollInfoMessages
