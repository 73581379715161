/**
 * This component handles both the questions for exams and exercises.
 */

import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import CSecBase from '../../../components/content-sections/CSecBase';
import DynamicContentLoadContextProvider from '../../../components/context-provider/DynamicContentLoadContextProvider';
import DiscussionContextProvider from '../../../components/discussion-pages/context-provider/DiscussionContextProvider';
import Discussion from '../../../components/discussion-pages/Discussion';
import QuestionMessage from '../../../components/messages/courses/QuestionMessage';
import InputFormTask from '../../../components/input-forms/forms/courses/InputFormTask';

import AuthService from '../../../util/authentication/auth_service';
import { TaskFormData as FormData } from '../../../components/input-forms/util/form-config-data/InputFormCoursesDataConfigs';
import { genDynUrl } from '../../../util/db_ls_query_handler';

const ERROR_MSG = 'Es können max. 4 Varianten gleichzeitig online sein und ' +
    'pro User ist nur eine Variante möglich.';

const isNewPostPermitted = (msgs) => {
    /**
     * Checks if the conditions for a new message creation are fulfilled.
     * Conditions are:
     * 1) < 4 messages exist
     * 2) User has not created a question yet.
     * :Input
     *  msgs (arr): Items are the loaded messages (obj).
     * :Returns
     *  Array: [true] If condition is fulfilled.
     *  False: [false, errorMsg] Otherwise.
     */
    const len = msgs.length;
    /* Check max. num messages condition. */
    if (len > 3) return [false, ERROR_MSG];
    /* Check user can only create one message condition. */
    const username = AuthService.getUsername();
    for (let i = 0; i < len; i++) {
        if (msgs[i]['username'] === username) return [false, ERROR_MSG];
    }
    return [true];
}

const TasksQuestionVariantsPage = ({
    str_listBaseUrl,
    str_createBaseUrl,
    str_voteBaseUrl,
    str_urlParam /* Parameter to select the task id from the url (e.g. examId). */
}) => {
    const params = useParams()
    const questionsListUrl = genDynUrl(str_listBaseUrl, params[str_urlParam])
    const newPostUrl = genDynUrl(str_createBaseUrl, params[str_urlParam])
    const dynConfig = {
        str_queryURL: newPostUrl,
        obj_initState: {
            date    : ['', false],
            category: ['', false],
            number  : [undefined, false],
        }
    }
    return (
        <>
            <CSecBase
                str_title='Varianten der Frage'
                str_subtitle='Die beste Frage wird auf der Antwortseite angezeigt.'
                str_classes='discussion-title-section'
            />
            <DynamicContentLoadContextProvider str_queryURL={questionsListUrl} >
                <DiscussionContextProvider fct_isNewPostPermitted={isNewPostPermitted}>
                    <Discussion
                        obj_postPanelFormConfig={FormData}
                        obj_postPanelDynConfig={dynConfig}
                        comp_inputForm={<InputFormTask b_isQuestionVariantForm={true} />}
                        str_msgVoteBaseUrl={str_voteBaseUrl}
                        b_isMsgBodyHideable={false}
                        b_isMsgCommentable={false}
                        b_isMsgEditable={false}
                        b_hasMsgTextMarking={false}
                        comp_msg={<QuestionMessage b_hasStdFooter={true} />}
                        b_areMsgsDiscussionMsgs={true}
                    />
                </DiscussionContextProvider>
            </DynamicContentLoadContextProvider>
        </>
    )
}

TasksQuestionVariantsPage.propTypes = {
    str_listBaseUrl: PropTypes.string.isRequired,
    str_createBaseUrl: PropTypes.string.isRequired,
    str_voteBaseUrl: PropTypes.string.isRequired,
    str_urlParam: PropTypes.string.isRequired
}

export default TasksQuestionVariantsPage
