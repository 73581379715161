import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import {
    useQueriedDataContext,
    useSetQueriedDataContext
} from '../../context-provider/DynamicContentLoadContextProvider'; 
import { useIncrementMessageThreadNum } from '../../messages/context-provider/MessageCacheContextProvider';
import { useIncrementSubtaskMsgNumContext } from '../../sidebars/context-provider/SbCourseTasksContextProvider';

const UpdatePostsContext = React.createContext();
const UpdatePostVotesContext = React.createContext();
const IsNewPostPermittedContext = React.createContext();

export function useUpdatePostsContext() {
    return useContext(UpdatePostsContext);
}

export function useUpdatePostVotesContext() {
    return useContext(UpdatePostVotesContext);
}

export function useIsNewPostPermitted() {
    return useContext(IsNewPostPermittedContext);
}

const DiscussionContextProvider = ({
    fct_isNewPostPermitted, /* Callback to decide if the new post button is shown. */
    children
}) => {

    const params = useParams()
    /* Dyn. content load data. */
    const posts = useQueriedDataContext()
    const setPosts = useSetQueriedDataContext()
    /* Hook to increment the num. of thread message a forum message has.
     * This is only relevant for the forum/thread pages. */
    const incrementMsgThreadNum = useIncrementMessageThreadNum()
    /* Increment the number of messages that a subtask has in the sidebar.
     * E.g.the number of messages of an exam. */
    const incrementSubtaskMsgNum = useIncrementSubtaskMsgNumContext()

    const updatePosts = (newPost, isNew) => {
        /**
         * Add new message, add new comment or edit a message.
         * :Input
         *  newPost (obj): Post object (e.g. of a message).
         *  isNew  (bool): True if message is a new message.
         *                 False: If message is a comment or if a message is edited.
         */
        /* New message. */
        if (isNew) {
            setPosts([newPost, ...posts])
            if (incrementMsgThreadNum) {
                const msgId = parseInt(params.forumMsgId)
                incrementMsgThreadNum(msgId)
            }
            if (incrementSubtaskMsgNum) incrementSubtaskMsgNum(newPost.questionId)
            return
        }

        /* Edit, comment message. */
        const isComment = newPost.commentAssignee === null ? false : true
        if (isComment) {
            const updatedPosts = posts.map((post) => {
                if (post.id !== newPost.commentAssignee) return post
                
                /* Update existing comment or add a new one. */
                
                /* Create checker to distinguish edit from new comment. */
                let b_isEdit = false
                const updatedComments = post.comments.map((comment) => {
                    if (comment.id !== newPost.id) return comment
                    b_isEdit = true
                    return newPost
                })
                /* Message is edited. */
                if (b_isEdit) return {...post, comments: updatedComments}
                /* Message is new reply. */
                return {...post, comments: [...updatedComments, newPost]}
            })
            setPosts(updatedPosts)
        } else {
            const updatedPosts = posts.map(post => {
                if (post.id !== newPost.id) return post
                /* Assign reply messages to the post. */
                newPost['replies'] = post.comments
                return newPost
            })
            setPosts(updatedPosts)
        }
    }

    const updatePostVotes = (postId, deltaUp, deltaDown, userVoted) => {
        /**
         * Updates the vote data of a particular post.
         * :Input
         *  postId    (int): Id of the post.
         *  deltaUp   (int): Delta to add to the current number of upvotes.
         *  deltaDown (int): Delta to add to the current number of downvotes.
         *  userVoted (bool or null): true: voted up, false: voted down, null: not voted
         */
        setPosts(
            posts.map(post => {
                if (post.id === postId) {
                    const newNumUpvotes = post.numUpvotes + deltaUp
                    const newNumDownvotes = post.numDownvotes + deltaDown
                    return {
                        ...post,
                        numUpvotes: newNumUpvotes,
                        numDownvotes: newNumDownvotes,
                        userVoted: userVoted
                    }
                }
                
                if (post.comments) {
                    post.comments.forEach((comment, index) => {
                        if (comment.id === postId) {
                            let comments = [...post.comments]
                            comments[index].numUpvotes = comment.numUpvotes + deltaUp
                            comments[index].numDownvotes = comment.numDownvotes + deltaDown
                            comments[index].userVoted = userVoted
                            return {
                                ...post,
                                comments: comments
                            }
                        }
                    })
                }
                
                return post
            })
        )
    }

    /* Is new post permitted? */
    const isNewPostPermitted = !fct_isNewPostPermitted ? [true, ''] : fct_isNewPostPermitted(posts)

    return (
        <UpdatePostsContext.Provider value={updatePosts}>
            <UpdatePostVotesContext.Provider value={updatePostVotes}>
                <IsNewPostPermittedContext.Provider value={isNewPostPermitted}>
                    {children}
                </IsNewPostPermittedContext.Provider>
            </UpdatePostVotesContext.Provider>
        </UpdatePostsContext.Provider>
    )
}

export default DiscussionContextProvider
