import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

const GetDocsOfCategoryContext = React.createContext();
const AddCategoryWithDocsContext = React.createContext();
const AddDoc2categoryContext = React.createContext();
const UpdateDocVoteContext = React.createContext();

export function useGetDocsOfCategoryContext() {
    return useContext(GetDocsOfCategoryContext);
}

export function useAddCategoryWithDocsContext() {
    return useContext(AddCategoryWithDocsContext);
}

export function useAddDoc2categoryContext() {
    return useContext(AddDoc2categoryContext);
}

export function useUpdateDocVoteContext() {
    return useContext(UpdateDocVoteContext);
}

const CourseDocsCacheContextProvider = ({ children }) => {

    const params = useParams()
    const [docs, setDocs] = useState({})

    const getDocsOfCategory = () => {
        const category = params.category.toLowerCase()
        return docs[category]
    }

    const addCategoryWithDocs = (category, inDocs) => {
        /**
         * Adds new category with documents to the docs state.
         * :Input
         *  category (str): Category of course document.
         *  docs (arr): Items are the individual documents (obj).
         */
        category = category.toLowerCase()
        let newDocs = {...docs}
        newDocs[category] = inDocs
        setDocs(newDocs)
    }

    const addDoc2category = (doc) => {
        /**
         * Add document to an existing documents category.
         * :Input
         *  doc (obj): Document data.
         */
        const category = doc.category.toLowerCase()
        const categoryData = docs[category]
        
        /* If category does not exist, do no add the document to the cache.
         * This would block loading all other docs of this category. Thist state
         * is reached if the user went straight to the form page without visiting
         * the page of this document category. */
        if (categoryData === undefined) return
        
        /* Category already exists, add doc to this category. */
        let newDocs = {}
        Object.keys(docs).forEach(key => {
            if (category === key) {
                newDocs[key] = [...docs[key], doc]
            } else {
                newDocs[key] = docs[key]
            }
        })
        setDocs(newDocs)
    }

    const updateDocVote = (docId, deltaUp, deltaDown, userVoted) => {
        /**
         * Updates the vote data of a particular document.
         * :Input
         *  docId     (int)
         *  deltaUp   (int): Delta to add to the current number of upvotes.
         *  deltaDown (int): Delta to add to the current number of downvotes.
         *  userVoted (bool or null): true: voted up, false: voted down, null: not voted
         */

        const category = params.category.toLowerCase()
        try {
            const updatedCategory = docs[category].map(doc => {
                if (doc.id === docId) {
                    const newNumUpvotes = doc.numUpvotes + deltaUp
                    const newNumDownvotes = doc.numDownvotes + deltaDown
                    return {
                        ...doc,
                        numUpvotes: newNumUpvotes,
                        numDownvotes: newNumDownvotes,
                        userVoted: userVoted
                    }
                }
                return doc
            })

            let newDocs = {}
            Object.keys(docs).forEach(key => {
                if (key === category) {
                    newDocs[key] = updatedCategory
                } else {
                    newDocs[key] = docs[key]
                }
            })
            setDocs(newDocs)
        } catch {}
    }

    return (
        <GetDocsOfCategoryContext.Provider value={getDocsOfCategory}>
            <AddCategoryWithDocsContext.Provider value={addCategoryWithDocs}>
                <AddDoc2categoryContext.Provider value={addDoc2category}>
                    <UpdateDocVoteContext.Provider value={updateDocVote}>
                        {children}
                    </UpdateDocVoteContext.Provider>
                </AddDoc2categoryContext.Provider>
            </AddCategoryWithDocsContext.Provider>
        </GetDocsOfCategoryContext.Provider>
    )
}

export default CourseDocsCacheContextProvider
