import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

const IsFormOpenContext = React.createContext();
const OpenCloseFormContext = React.createContext();

export function useIsFormOpenContext() {
    return useContext(IsFormOpenContext);
}

export function useOpenCloseFormContext() {
    return useContext(OpenCloseFormContext);
}

const DiscussionNewPostContextProvider = ({
    b_isFormOpen=false, /* In case the form should be open by default. */
    children
}) => {
    const [isFormOpen, setIsFormOpen] = useState(b_isFormOpen)
    return (
        <IsFormOpenContext.Provider value={isFormOpen}>
            <OpenCloseFormContext.Provider value={setIsFormOpen}>
                {children}
            </OpenCloseFormContext.Provider>
        </IsFormOpenContext.Provider>
    )
}

DiscussionNewPostContextProvider.propTypes = {
    b_isFormOpen: PropTypes.bool
}

export default DiscussionNewPostContextProvider
