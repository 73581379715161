import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import { setLSdata } from "../../../../util/db_ls_query_handler";
import { IsPaymentSuccessKey as PAYMENT_SUCCESS_LS_KEY } from "../../../../util/LocalStorageVariables";

const CheckoutForm = () => {

    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        /* Stripe.js has not yet loaded.
         * Make sure to disable form submission until Stripe.js has loaded. */
        if (!stripe || !elements) return

        setIsLoading(true);

        /* Set an LS value that is checked for existence on the success page
         * after redirection after a successful payment.
         * If this value is not present, the success page does not update the
         * isAboValid localStorage value to true. */
        setLSdata(true, PAYMENT_SUCCESS_LS_KEY)

        const hostname = window.location.hostname;
        const urlStart = hostname === 'localhost' ? 'http://localhost:3000' : `https://${hostname}`
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${urlStart}/abo-extension/success`,
            },
        });

        /* This point will only be reached if there is an immediate error when
         * confirming the payment. Otherwise, your customer will be redirected to
         * your `return_url`. For some payment methods like iDEAL, your customer will
         * be redirected to an intermediate site first to authorize the payment, then
         * redirected to the `return_url`. */
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
            localStorage.removeItem(PAYMENT_SUCCESS_LS_KEY)
        } else {
            setMessage("Es ist ein unerwarteter Fehler aufgetreten.");
            localStorage.removeItem(PAYMENT_SUCCESS_LS_KEY)
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <button disabled={isLoading || !stripe || !elements} id="submit">
                <span id="button-text">
                    {
                        isLoading
                        ?
                        <div className="spinner" id="spinner"></div>
                        :
                        "Jetzt bezahlen"
                    }
                </span>
            </button>
            {
                /* Error and success messages. */
                message &&
                <div id="payment-message">{message}</div>
            }
        </form>
    );
}

export default CheckoutForm
