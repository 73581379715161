/**
 * Contains the main navbar entries for all users types.
 * 
 * The 'title' is what is displayed on the front end (innerHTML). 
 * The 'name' is used to highlight the currently active item of the navbar. 
 * The 'name' must be featured in the URL, as the JS code checks the URL against
 * the 'name' and if it finds it, sets the active styling to this item.
 * 
 * 'symbol' (react icon) can be used instead of 'title' (like so: symbol: <TbLogout />,)
 */

/* Used icons for the icon navbar variant. */
// import { CgProfile } from 'react-icons/cg';
// import { TbLogout } from 'react-icons/tb';

import AuthService from '../../../util/authentication/auth_service';

export const MainNavbarData = {
    'aboInvalid': {
        'company': [
            {
                title: 'Abo',
                name: 'abo',
                link: '/abo-extension'
            },
            {
                title: 'Logout',
                name: '/logout',
                link: '/login',
                onClick: AuthService.logout
            }
        ],
        'gastronomer': [
            {
                title: 'Abo',
                name: 'abo',
                link: '/abo-extension'
            },
            {
                title: 'Logout',
                name: '/logout',
                link: '/login',
                onClick: AuthService.logout
            }
        ],
        'student': [
            {
                title: 'Gastronomie',
                name: 'gastronomy',
                link: '/gastronomy/gastronomers',
            },
            {
                title: 'Industrie',
                name: 'industry',
                link: '/industry/jobs'
            },
            {
                title: 'Kurse',
                name: 'courses',
                link: '/courses/tutoring'
            },
            {
                title: 'Logout',
                name: '/logout',
                link: '/login',
                onClick: AuthService.logout
            }
        ],
        'profileSetup': [
            {
                title: 'Logout',
                name: '/logout',
                link: '/login',
                onClick: AuthService.logout
            }
        ],
        'profileSetupLegal': []
    },
    'profileSetup': [
        {
            title: 'Logout',
            name: '/logout',
            link: '/login',
            onClick: AuthService.logout
        }
    ],
    'profileSetupLegal': [],
    'student': [
        {
            title: 'Gastronomie',
            name: 'gastronomy',
            link: '/gastronomy/food-offers/regular/this-week',
        },
        {
            title: 'Industrie',
            name: 'industry',
            link: '/industry/jobs'
        },
        {
            title: 'Kurse',
            name: 'courses',
            link: '/courses/overview'
        },
        {
            title: 'Mining',
            name: 'mining',
            link: '/mining/this-semester'
        },
        {
            title: 'Store',
            name: 'store',
            link: '/store'
        },
        {
            title: 'Logout',
            name: '/logout',
            link: '/login',
            onClick: AuthService.logout
        }
    ],
    'company': [
        {
            title: 'Industrieportal',
            name: 'jobs',
            link: '/industry/jobs'
        },
        {
            title: 'Logout',
            name: 'logout',
            link: '/login',
            onClick: AuthService.logout
        }
    ],
    'gastronomer': [
        {
            title: 'Gastronomie',
            name: 'gastronomy',
            link: '/gastronomy/food-offers/regular/this-week'
        },
        {
            title: 'Store',
            name: 'store',
            link: '/store'
        },
        {
            title: 'Logout',
            name: 'logout',
            link: '/login',
            onClick: AuthService.logout
        }
    ]
};
