/**
 * Creates the progress ladder data from the profile setup config data
 * that is stored in PPFormConfig.js.
 */
import PPFormConfig from "./PPFormConfig";
import Usertypes from "../../../../util/Usertypes";

const genProgressLadder = (progressStepsConfig) => {
    /**
     * Generates the data for the progress ladder that is displayed during
     * the user profile setup process.
     * :Input
     *  progressStepsConfig (arr): Items are objects.
     * :Returns
     *  Array of the following format
     *  {
     *      stepTitle: ... (str), // Title to on the progress ladder.
     *      stepIndex: ... (nbr), // Number of the current process step.
     *      urlParam : ... (str)  // Value of the URL parameter setupStep.
     *  }
     */
    let progressLadder = [];
    let configStepKey = 'password';
    while (true) {
        const stepConfigData = progressStepsConfig[configStepKey];
        
        const psStepIndex = stepConfigData.psStepIndex;
        if (!psStepIndex) return [];
        const stepTitle = stepConfigData.title ? stepConfigData.title : `Schritt ${psStepIndex}`;

        progressLadder.push(
            {
                stepTitle: stepTitle,
                stepIndex: psStepIndex,
                urlParam: stepConfigData.urlFormat
            }
        )

        configStepKey = stepConfigData.psNextStep;
        if (configStepKey === 'complete') break;
    }
    return progressLadder;
}

const progressLadder = (usertype) => {
    /**
     * Generates the data for the progress ladder that is displayed during
     * the user profile setup process.
     * :Input
     *  usertype (str): One of the usertypes available in Usertypes.js
     * :Returns
     *  Array of the following format
     *  {
     *      stepTitle: ... (str),
     *      stepIndex: ... (nbr)
     *  }
     */
    switch (usertype) {
        case Usertypes.company:
            return genProgressLadder(PPFormConfig.company);
        case Usertypes.gastronomer:
            return genProgressLadder(PPFormConfig.gastronomer);
        case Usertypes.student:
            return genProgressLadder(PPFormConfig.student);
        default:
            return null;
    }
}

const RegistrationProgressLadderCreator = {
    progressLadder: progressLadder
};

export default RegistrationProgressLadderCreator;
