import React from 'react';

import PPFormBase from '../../../profile-pages/PPFormBase';
import InputFormPasswordChange from './InputFormPasswordChange';

import useSetBrowserTabTitle from '../../../../../custom-hooks/useSetBrowserTabTitle';

import { PasswordNewFormData as FormData } from '../../../util/form-config-data/InputFormPasswordDataConfigs';
import { static2dynamicPanelConfig } from '../../../util/input_form_data_handler';

const PPPasswordForm = () => {
    useSetBrowserTabTitle('Passwort ändern', true)
    return (
        <PPFormBase
            {...static2dynamicPanelConfig(FormData)}
            formChild={<InputFormPasswordChange />}
        />
    )
}

export default PPPasswordForm
