import React from 'react';
import PropTypes from 'prop-types';

import TrStudentPPIndustryJobs from './TrStudentPPIndustryJobs';

const TbStudentPPIndustryJobs = ({
    arr_data,
    fct_deleteEntryFromState
}) => {
    return (
        <div className="tb-wrapper">
            <table cellSpacing="0" className='tb-base text-left'>
                <thead>
                    <tr>
                        <td>Titel</td>
                        <td className="hide-max-w-900px">Unternehmen</td>
                        <td>Deadline</td>
                        <td>Pin</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        arr_data.map((item, index) => (
                            <TrStudentPPIndustryJobs
                                key={index}
                                obj_data={item}
                                fct_deleteEntryFromState={fct_deleteEntryFromState}
                            />
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

TbStudentPPIndustryJobs.propTypes = {
    arr_data: PropTypes.array.isRequired,
    fct_deleteEntryFromState: PropTypes.func.isRequired
}

export default TbStudentPPIndustryJobs
