import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import CardPriceTag from './CardPriceTag';
import { useNumCoinsContext, useUpdateNumCoinsContext } from '../context-provider/CoinBudgetContextProvider';
import { useUpdateItem } from '../context-provider/DynamicContentLoadContextProvider';
import { sendData2db } from '../../util/db_ls_query_handler';
import Usertypes from '../../util/Usertypes';

const genConfirmationMsg = (str_title, nbr_price, str_currency) => {
    /**
     * Generate the confirmation message the user is prompted when attempting
     * to purchase/reserve an item.
     */
    let baseString = 'Möchtest du den folgenden Artikel wirklich erwerben?\n';
    const name = 'Titel: ' + str_title + '\n';
    const price = 'Preis: ' + nbr_price.toString() + ' ' + str_currency;
    const msg = baseString + name + price;
    return msg;
}

const USERTYPE_STUDENT = Usertypes.student;
const ERROR_MSG_INTRO = 'Oops!\nEs ist ein Fehler aufgetreten. Die Aktion konnte nicht durchgeführt werden.\n';
const MC_CURRENCY = 'MC';

const CardFooterPurchase = ({
    str_usertype,  /* No buy button might be available for some usertypes. */
    str_cardTitle,
    str_btnTitle='Kaufen',
    str_cardFooterClass='',
    str_currency='€',
    str_baseUrl,   /* Need to attach the id (nbr_id) of the item to obtain the full URL. */
    nbr_id,
    nbr_price,
    nbr_priceDecimals=2,
    nbr_available, /* How many instances of this item can be purchased in totl? */
    nbr_left,      /* How many instances of this item can be purchased at the moment? */
    nbr_owned,     /* If mulitple items can be owned. */
    b_isOwner,     /* If only one item can be owned. */
    b_isSubmitBtnActive=true /* If the btn is indeed clickable to make a submit. */
}) => {

    const [numLeft, setNumLeft] = useState(nbr_left)
    const [numOwned, setNumOwned] = useState(nbr_owned) /* If mulitple items can be owned. */
    const [isOwner, setIsOwner] = useState(b_isOwner)   /* If only one item can be owned. */
    const isBlockedRef = useRef(false)

    /* Access info about a user's coin budget if the items are coupons (not relevant for food offers). */
    const numCoins = useNumCoinsContext()
    const updateCoins = useUpdateNumCoinsContext()

    /* If card data is loaded by the DynamicContentLoadContextProvider.js. */
    const updateItemDynamicContext = useUpdateItem()

    const numPriceDecimals = str_currency.toUpperCase() === MC_CURRENCY ? 0 : nbr_priceDecimals

    useEffect(() => {
        /**
         * The input values can only change if this component is used together with
         * the DynamicContentLoadContextProvider.js.
         * If one value changes, update all as it goes in one render.
         */
        setNumLeft(nbr_left)
        setNumOwned(nbr_owned)
        setIsOwner(b_isOwner)
    }, [nbr_left, nbr_owned, b_isOwner])

    const handleOnClick = async () => {
        /**
         * Send post request to DB and purchase/reserve an item.
         */
        if (isBlockedRef.current) return
        isBlockedRef.current = true

        const confirmMsg = genConfirmationMsg(str_cardTitle, nbr_price, str_currency)
        if (!window.confirm(confirmMsg)) {
            isBlockedRef.current = false
            return
        }

        const pl = {pk: nbr_id}
        const url = str_baseUrl + `${nbr_id}/`
        const queryData = await sendData2db('post', url, pl)
        if (queryData.isQuerySuccess) {
            /* If items are loaded via the DynamicContentLoadContextProvider.js, the item must be 
             * updated there. The rerender updates this component then. Only updating this component
             * causes it to be overwritten when the context provider rerenders. */
            if (updateItemDynamicContext) {
                const updateData = genUpdateData()
                updateItemDynamicContext(nbr_id, updateData)
            } else {
                /* Set states of this component, no dynamic loading in place. */
                setNumLeft((numLeft) => { return --numLeft })
                /* Select between user can own multiple items (coupons) or only one (food offers). */
                if (numOwned !== undefined) {
                    setNumOwned((numOwned) => { return ++numOwned})
                } else {
                    setIsOwner(true)
                }
            }
            /* Update user's #coins after the update. */
            if (updateCoins) updateCoins(nbr_price)
        } else {
            const errorMsg = ERROR_MSG_INTRO + queryData.errorMsg
            alert(errorMsg)
        }

        isBlockedRef.current = false;
    }

    const genUpdateData = () => {
        /**
         * Creates an obj with the new values of the item of the
         * DynamicContentLoadContextProvider.js that is to be updated.
         */
        const newNumLeft = numLeft - 1
        let updateValues = { numLeft: newNumLeft }
        /* Differentiate between user can own only one or multiple items. */
        if (numOwned === undefined) {
            updateValues['isOwner'] = true
        } else {
            updateValues['numOwned'] = numOwned + 1
        }
        return updateValues
    }

    return (
        <div className={`card-footer-offer card-footer-purchase ${str_cardFooterClass}`}>
            <CardPriceTag
                nbr_price={nbr_price}
                nbr_decimals={numPriceDecimals}
                str_currency={str_currency}
            />
            {
                /* Do not display a button if the offer number is not restricted. */
                nbr_available !== undefined &&
                <div className="action-el">
                    {
                        numLeft === 0 &&
                        <p className="sold-out">Ausverkauft</p>
                    }
                    {
                        str_usertype === USERTYPE_STUDENT
                        ?
                            isOwner
                            ?
                                /* Only shown for food offers (user can only own one). */
                                <p className="is-owner">In Besitz</p>
                            :
                            numCoins === undefined && numLeft > 0
                            ?
                                /* Food offers. */
                                <>
                                    {
                                        b_isSubmitBtnActive
                                        ?
                                        <button
                                            className="btn btn-sm btn-submit"
                                            onClick={handleOnClick}
                                        >
                                            {str_btnTitle}
                                        </button>
                                        :
                                        <button className="btn btn-sm btn-deactivated">
                                            {str_btnTitle}
                                        </button>
                                    }
                                </>
                            :
                            numCoins > 0 && numLeft > 0
                            ?
                                /* User owns coins. */
                                numCoins < nbr_price
                                ?
                                    /* Too few coins owned for purchasing this item. Show deactivated button. */
                                    <>
                                        <button className="btn btn-sm btn-deactivated">
                                            {str_btnTitle}
                                        </button>
                                        <div className="too-few-coins">
                                            <p>Zu wenige MC</p>
                                        </div>
                                    </>
                                :
                                <button
                                    className="btn btn-sm btn-submit"
                                    onClick={handleOnClick}
                                >
                                    {str_btnTitle}
                                </button>
                            :
                            '' /* No coins owned, no button shown. */
                        :
                        ''
                    }
                </div>
            }
            {
                /* Information about availability and owner numbers. */

                nbr_available === undefined /* Is offer number restricted? */
                ?
                <div className="add-info">
                    <div className="add-info-available">
                        <p>
                            <span className="info num-title">unlimitiert</span>
                        </p>
                    </div>
                </div>
                :
                <div className="add-info">
                    <div className="add-info-available">
                        <p>
                            <span className={`info-nums ${numLeft > 0 ? '' : 'zero-num-remaining'}`}>
                                <span className="info num num-left">{numLeft}</span>
                                <span className="info separator">/</span>
                                <span className="info num num-available">{nbr_available}</span>
                            </span>
                            <span className="info num-title">verfügbar</span>
                        </p>
                    </div>
                    {
                        /* Show how many items the user owns. */
                        numOwned !== undefined &&
                        <div className="add-info-owned">
                            <p className="nowrap">
                                <span className="info-nums">
                                    <span className="info num-owned">{numOwned}</span>
                                </span>
                                <span className="info num-title">in Besitz</span>
                            </p>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

CardFooterPurchase.propTypes = {
    str_usertype: PropTypes.string.isRequired,
    str_cardTitle: PropTypes.string,
    str_btnTitle: PropTypes.string,
    str_cardFooterClass: PropTypes.string,
    str_currency: PropTypes.string,
    str_baseUrl: PropTypes.string.isRequired,
    nbr_id: PropTypes.number.isRequired,
    nbr_price: PropTypes.number.isRequired,
    nbr_priceDecimals: PropTypes.number,
    nbr_available: PropTypes.number,
    nbr_left: PropTypes.number,
    nbr_owned: PropTypes.number,
    b_isOwner: PropTypes.bool,
    b_isSubmitBtnActive: PropTypes.bool
}

export default CardFooterPurchase
