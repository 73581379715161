/**
 * This hook sets the browser search params (in the url) from a
 * given filter state object. Keys are the input field ids,
 * values are the filter values provided to these fields.
 * 
 * This is necessary if a filter is set on one page, then the user
 * navigates to another page, and then comes back to the original
 * page where the filter was set. If the filter data is preserved
 * in this component, the filter can access it and write it to the 
 * browser search bar.
 */

import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function useSetBrowserSearchParamsFromFilterState(filterState) {
    const [, setSearchParams] = useSearchParams()
    useEffect(() => {
        /**
         * Write data from filter state to browser search bar.
         */
        if (!filterState) return
        let params = {}
        Object.keys(filterState).forEach(fieldId => {
            /* ContextProvider state [value, bool]. */
            const filterFieldValue = filterState[fieldId][0]
            if (filterFieldValue && filterFieldValue !== [])
                params[fieldId] = filterFieldValue
        })
        setSearchParams(params)
    }, [])
}