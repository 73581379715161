import React, { useState } from 'react';
import { RiProductHuntLine } from 'react-icons/ri';
import { BsMoon } from 'react-icons/bs';
import { ImSun } from 'react-icons/im';

import AuthService from '../../util/authentication/auth_service';
import Usertypes from '../../util/Usertypes';

import { german2wordMonthDateFormat } from '../../util/date_time_handler';
import { sendData2db } from '../../util/db_ls_query_handler';

const API_URL = '/api/profiles/appearance-theme/'
const THEME_CLASS_NAME = 'dark-theme';

const PPHeader = () => {

    const [hasDarkTheme, setHasDarkTheme] = useState(AuthService.hasDarkTheme())

    const user = AuthService.getUser()

    const switchTheme = async () => {
        const mainEl = document.querySelector('#page-content')
        const footerEl = document.querySelector('footer')
        const hasDarkTheme = AuthService.hasDarkTheme()
        if (hasDarkTheme) {
            AuthService.setTheme(false)
            setHasDarkTheme(false)
            mainEl.classList.remove(THEME_CLASS_NAME)
            footerEl.classList.remove(THEME_CLASS_NAME)
        } else {
            AuthService.setTheme(true)
            setHasDarkTheme(true)
            if (!mainEl.classList.contains(THEME_CLASS_NAME)) {
                mainEl.classList.add(THEME_CLASS_NAME)
            }
            if (!footerEl.classList.contains(THEME_CLASS_NAME)) {
                footerEl.classList.add(THEME_CLASS_NAME)
            }
        }
        const data = {'hasDarkTheme': !hasDarkTheme}
        sendData2db('post', API_URL, data)
    }

    return (
        <section id="pp-user-info-header">
            <div className="pp-user-info-header-wrapper">
                <div className="name">
                    <div className="full-name">
                        <span className="first-name">{user.firstName}</span>
                        &nbsp;
                        <span className="last-name">{user.lastName}</span>
                        &nbsp;
                        {
                            user.usertype === Usertypes.student && user.isAboValid &&
                            <span>
                                <RiProductHuntLine />
                            </span>
                        }
                    </div>
                    <div className="username">
                        <span>@</span>
                        <span>{user.username}</span>
                    </div>
                </div>
                <div className="sign-up-info">
                    <div className="sign-up-date">
                        <span className="nowrap">Mitglied seit dem</span>&nbsp;
                        <span className="nowrap">{german2wordMonthDateFormat(user.dateJoined)}</span>
                    </div>
                </div>
                <button
                    className={`theme-switch ${hasDarkTheme ? 'dark-theme' : 'light-theme'}`}
                    onClick={() => switchTheme()}
                >
                    <span className="text">
                        {hasDarkTheme ? 'Dunkel' : 'Hell'}
                    </span>
                    <span className="icon">
                        {
                            hasDarkTheme
                            ?
                            <BsMoon />
                            :
                            <ImSun />
                        }
                    </span>
                </button>
            </div>
        </section>
    )
}

export default PPHeader
