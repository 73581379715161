import React from 'react';

import MessageStdBody from '../MessageStdBody';
import MessageBodyGroup from '../MessageBodyGroup';
import MessageStdBodyText from '../MessageStdBodyText';

import { useMsgContext } from '../context-provider/MessageContextProvider';

const QuestionMessageBody = () => {
    const msg = useMsgContext()
    return (
        <div className='msg-body'>
            <div className='msg-body-group-grid'>
                <MessageBodyGroup str_header={'Kategorie'}>
                    <MessageStdBodyText text={msg.qcategory} />
                </MessageBodyGroup>
                <MessageBodyGroup str_header={'Frage Nummer'}>
                    <MessageStdBodyText text={msg.isExerciseTestQuestion ? `T${msg.number}` : `${msg.number}`} />
                </MessageBodyGroup>
                <MessageBodyGroup str_header={'Vollständigkeit'}>
                    <MessageStdBodyText text={msg.completenessRank} />
                </MessageBodyGroup>
            </div>
            {
                msg.comment &&
                <MessageBodyGroup str_header='Kommentar'>
                    <MessageStdBodyText text={msg.comment} />
                </MessageBodyGroup>
            }
            <MessageBodyGroup str_header='Frage' str_classes='no-margin-bottom'>
                <MessageStdBody b_isContentOnly={true} />
            </MessageBodyGroup>
        </div>
    )
}

export default QuestionMessageBody
