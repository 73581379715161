import React from 'react';
import { Link } from 'react-router-dom';
import AuthService from '../../util/authentication/auth_service';

const FOOTER_URLS = {
    termsPrivacy: {
        userGuid: '/data-and-usage/user-guide',
        termsOfUsage: '/data-and-usage/terms-of-usage',
        privacyPolicy: '/data-and-usage/privacy-policy'
    },
    contact: {
        general: '/contact/general',
        improvements: '/contact/improvements-and-suggestions',
        bugReporting: '/contact/bug-reporting',
        userReporting: '/contact/user-reporting'
    }
};

const MainFooter = () => {
    const hasDarkTheme = AuthService.hasDarkTheme();
    return (
        <footer className={`footer ${hasDarkTheme ? 'dark-theme' : ''}`}>
            <div className="footer-wrapper">
                <ul className="footer-content">
                    <li className="footer-item footer-company">
                        <h4 className="footer-header logo">muplaza (beta)</h4>
                        <ul className="slogan">
                            <li>
                                <p>Weil wir in Leoben gemeinsam studieren.</p>
                            </li>
                        </ul>
                        <hr />
                    </li>
                    <li className="footer-item footer-links">
                        <h4 className="footer-header">Nutzung & Datenschutz</h4>
                        <ul className="terms-and-privacy">
                            <li>
                                <Link to={FOOTER_URLS.termsPrivacy.userGuid}>Nutzeranleitung</Link>
                            </li>
                            <li>
                                <Link to={FOOTER_URLS.termsPrivacy.termsOfUsage}>Nutzungsbedingungen</Link>
                            </li>
                            <li>
                                <Link to={FOOTER_URLS.termsPrivacy.privacyPolicy}>Datenschutzrichtlinie</Link>
                            </li>
                        </ul>
                        <hr />
                    </li>
                    <li className="footer-item footer-links">
                        <h4 className="footer-header">Quick Links</h4>
                        <ul className="useful-links">
                            <li>
                                <a
                                    href="https://www.unileoben.ac.at/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Montanuniversität Leoben
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://online.unileoben.ac.at/mu_online/ee/ui/ca2/app/desktop/#/login"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    MUonline
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://studmail.unileoben.ac.at/login.php"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Studmail
                                </a>
                            </li>
                            {/* <li>
                                <a
                                    href="https://www.oeh-leoben.at/de/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    ÖH Leoben
                                </a>
                            </li> */}
                        </ul>
                        <hr />
                    </li>
                    <li className="footer-item">
                        <h4 className="footer-header">Kontakt</h4>
                        <ul className="contact">
                            <li>
                                <Link to={FOOTER_URLS.contact.general}>Kontaktformular</Link>
                            </li>
                            <li>
                                <Link to={FOOTER_URLS.contact.improvements}>Verbesserungen & Vorschläge</Link>
                            </li>
                            <li>
                                <Link to={FOOTER_URLS.contact.bugReporting}>Fehler melden</Link>
                            </li>
                            <li>
                                <Link to={FOOTER_URLS.contact.userReporting}>User melden</Link>
                            </li>
                        </ul>
                    </li>
                </ul>
                <hr />
                <div className="footer-bottom">
                    <p>copyright &copy; 2023 MUPLAZA&nbsp;e.U. Alle Rechte vorbehalten.</p>
                </div>
            </div>
        </footer>        
    )
}

export default MainFooter
