import React from 'react';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import Message from '../../messages/Message';
import MessagesContextProvider from '../../messages/context-provider/MessagesContextProvider';
import MessageContextProvider from '../../messages/context-provider/MessageContextProvider';
import DynamicContentLoadBtn from '../../buttons/DynamicContentLoadBtn';

import {
    useQueriedDataContext,
    useIsFirstFetchSuccessContext
} from '../../context-provider/DynamicContentLoadContextProvider';

const VOTE_BASE_URL = '/api/courseforums/vote/';

const StudentPPThreadMessages = () => {
    const msgs = useQueriedDataContext()
    const isFirstFetchSuccessRef = useIsFirstFetchSuccessContext()
    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccessRef.current}
            firstQueryDataEntry={msgs[0]}
        >
            <MessagesContextProvider
                str_voteBaseUrl={VOTE_BASE_URL}
                b_hasDarkDesign={false}
            >
                {
                    msgs.map((msg, index) => (
                        <MessageContextProvider
                            key={index}
                            obj_msg={msg}
                            b_isOwner={true}
                        >
                            <Message />
                        </MessageContextProvider>
                    ))
                }
            </MessagesContextProvider>
            <DynamicContentLoadBtn />
        </DataStateCheckWrapper>
    )
}

export default StudentPPThreadMessages
