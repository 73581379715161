import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { GoBold } from 'react-icons/go';
import { AiOutlineItalic, AiOutlineUnderline } from 'react-icons/ai';
import { BiLink, BiCodeAlt } from 'react-icons/bi';

import InputFormPanel from '../input-forms/forms/general/panels/InputFormPanel';
import PanelPreview from './PanelPreview';

import {
    useIndicateMarkedText,
    useHandlePanelSwitch
} from './context-provider/PanelContextProvider';


const PanelBase = ({
    obj_fieldsConfig, /* Config of text, file, and single choice field. */
    arr_indicators=['all'],
    str_firstName,
    str_lastName,
    str_username,
    b_hasDarkDesign=false
}) => {

    const [isWriteViewOpen, setIsWriteViewOpen] = useState(true)
    const postPanelRef = useRef()

    const indicateMarkedText = useIndicateMarkedText()
    const handlePanelSwitch = useHandlePanelSwitch()

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    })

    const handleKeyDown = (e) => {
        /* Only treat panels that are rendered on the page. */
        if ((postPanelRef.current !== undefined) && (e.ctrlKey)) {
            /* Choose which panel of all the ones part of the DOM 
             * should be switched from one view to another. */
            if (!isSwitchedPanel()) return
            /* Open the preview panel if it is closed. */
            if (e.key === '.' && isWriteViewOpen)
                handlePanelSwitch(false, isWriteViewOpen, setIsWriteViewOpen)
            /* Open the write view panel if it is closed. */
            else if (e.key === ',' && !isWriteViewOpen)
                handlePanelSwitch(true, isWriteViewOpen, setIsWriteViewOpen)
        }
    }
    
    const computePostPanelScreenOverlap = (dom_postPanel) => {
        /**
         * Computes the overlap of a dom_postPanel with the current screen view. 
         * :Input
         *  dom_postPanel: Dom post panel element. 
         * :Returns
         *  Integer that corresponds to the post panel - screen overlap. 
         */
        const kint_screenHeight = window.innerHeight;
        const kobj_panelSize = dom_postPanel.getBoundingClientRect();
        const kint_panelHeight = kobj_panelSize.height;
        const kint_panelTop = kobj_panelSize.top;

        let kint_overlap;
        
        if ((kint_panelTop > kint_screenHeight) || ((kint_panelTop + kint_panelHeight) < 0)) {
            kint_overlap = 0;
        }
        else if (kint_panelTop < 0) {
            const kint_unconstrainedOverlap = kint_panelTop + kint_panelHeight;
            if (kint_unconstrainedOverlap > kint_screenHeight) {
                kint_overlap = kint_screenHeight;
            } else {
                kint_overlap = kint_unconstrainedOverlap;
            }
        }
        else {
            const kint_maxPotentialOverlap = kint_screenHeight - kint_panelTop;
            if ((kint_maxPotentialOverlap - kint_panelHeight) < 0) {
                kint_overlap = kint_maxPotentialOverlap;
            } else {
                kint_overlap = kint_panelHeight;
            }
        }
        return kint_overlap;
    }

    const isSwitchedPanel = () => {
        /** 
         * Decide if this input panel should switch its view (write/preview).
         * :Returns
         *  True: It should switch its view. 
         *  False: Otherwise. 
         */
        let float_maxOverlap = 0
        const karr_postPanels = document.querySelectorAll('.post')
        const kint_len = karr_postPanels.length
        for (let i = 0; i < kint_len; i++) {
            const kfloat_overlap = computePostPanelScreenOverlap(karr_postPanels[i])
            if (kfloat_overlap > float_maxOverlap) {
                float_maxOverlap = kfloat_overlap
            }
        }
        if (float_maxOverlap > 0) {
            /* Compare this post panel to the max. one computed above. If the max. panel and
            * this panel are different, do not switch the view of this panel. */
           const kfloat_thisOverlap = computePostPanelScreenOverlap(postPanelRef.current)
           if (kfloat_thisOverlap.toFixed(2) !== float_maxOverlap.toFixed(2)) {
                return false
            }
        } else {
            /* No post panel is visible in the window, do not do anything. */
            return false
        }
        return true
    }

    return (
        <div ref={postPanelRef} className="post">

            {/* General user information that is displayed both in write and preview. */}
            <div className="post-header">
                <span className="key">(entwurf)</span>
                <span className="author-name">
                    <span className="first-name">{str_firstName}</span>
                    &nbsp;
                    <span className="last-name">{str_lastName}</span>
                </span>
                <span className="username">@{str_username}</span>
            </div>

            <div className="post-msg-wrapper">
                {/* Header that contains the action items for formatting the text 
                and for switching between the write view and preview. */}
                <div className="post-msg-header">
                    {/* Elements that help formatting the text, have the '-action' ending. */}
                    <ul className="write-preview">
                        <li 
                            className={`write-action ${isWriteViewOpen ? 'active-action' : ''}`}
                            onClick={() => handlePanelSwitch(true, isWriteViewOpen, setIsWriteViewOpen)}
                        >
                            edit.
                        </li>
                        <li 
                            className={`preview-action ${!isWriteViewOpen ? 'active-action' : ''}`}
                            onClick={() => handlePanelSwitch(false, isWriteViewOpen, setIsWriteViewOpen)}
                        >
                            vorschau
                        </li>
                    </ul>
                    {
                        isWriteViewOpen &&
                        <ul className="text-marking">
                            {/* These items must only have one className for js to work correctly. */}
                            {
                                (arr_indicators.includes('all') || arr_indicators.includes('4')) &&
                                <li className="bold-action" onClick={() => indicateMarkedText('b')}>
                                    <GoBold />
                                </li>
                            }
                            {
                                (arr_indicators.includes('all') || arr_indicators.includes('5')) &&
                                <li className="italic-action" onClick={() => indicateMarkedText('i')}>
                                    <AiOutlineItalic />
                                </li>
                            }
                            {
                                (arr_indicators.includes('all') || arr_indicators.includes('7')) &&
                                <li className="underline-action" onClick={() => indicateMarkedText('u')}>
                                    <AiOutlineUnderline />
                                </li>
                            }
                            {
                                (arr_indicators.includes('all') || arr_indicators.includes('8')) &&
                                <li className="link-action" onClick={() => indicateMarkedText('l')}>
                                    <BiLink />
                                </li>
                            }
                            {
                                (arr_indicators.includes('all') || arr_indicators.includes('9')) &&
                                <li className="code-action" onClick={() => indicateMarkedText('c')}>
                                    <BiCodeAlt />
                                </li>
                            }
                        </ul>
                    }
                </div>

                {/* Write view and preview content. */}
                {
                    isWriteViewOpen
                    ?
                    <InputFormPanel
                        {...obj_fieldsConfig}
                        b_hasDarkDesign={b_hasDarkDesign}
                    />
                    :
                    <PanelPreview />
                }
            </div>
        </div>
    )
}

PanelBase.propTypes = {
    obj_fieldsConfig: PropTypes.object.isRequired,
    str_firstName: PropTypes.string.isRequired,
    str_lastName: PropTypes.string.isRequired,
    str_username: PropTypes.string.isRequired,
    arr_indicators: PropTypes.array,
    b_hasDarkDesign: PropTypes.bool
}

export default PanelBase
