import React from 'react';
import { useParams } from 'react-router-dom';

import Discussion from '../../../components/discussion-pages/Discussion';
import DiscussionContextProvider from '../../../components/discussion-pages/context-provider/DiscussionContextProvider';
import DynamicContentLoadContextProvider from '../../../components/context-provider/DynamicContentLoadContextProvider';
import QuestionMessage from '../../../components/messages/courses/QuestionMessage';

import DynLoadPackageSizes from '../../../util/config/data_load_package_sizes.json';
import DiscussionConfigs from '../../../components/discussion-pages/DiscussionConfigs';

import { getFormData } from '../../../components/input-forms/util/form-config-data/InputFormPanelDataConfigs';
import { genDynUrl } from '../../../util/db_ls_query_handler';

const  TasksDetailPage = ({
    str_questionDetailBaseUrl,
    str_questionVoteBaseUrl,
    str_msgListBaseUrl,
    str_msgNewBaseUrl,
    str_msgCommentBaseUrl,
    str_msgEditBaseUrl,
    str_msgVoteBaseUrl
}) => {
    
    const params = useParams()
    const questionId = params.questionId
    const questionMsgQueryUrl = genDynUrl(str_questionDetailBaseUrl, questionId)
    const newMsgPostUrl = genDynUrl(str_msgNewBaseUrl, questionId)
    const msgsListUrl = genDynUrl(str_msgListBaseUrl, questionId)
    const postPanelFormConfig = getFormData(
        DiscussionConfigs.CourseExamsDiscussion.formConfig,
        DiscussionConfigs.CourseExamsDiscussion.fileFieldConfig
    )
    const dynConfig = { str_queryURL: newMsgPostUrl }

    return (
        <DynamicContentLoadContextProvider
            str_queryURL={msgsListUrl}
            b_hasInfiniteScroll={true}
            b_useDiscussionMsgCache={true}
            nbr_packageSize={DynLoadPackageSizes.tasks.questionMsgs}
        >
            <DiscussionContextProvider>
                <Discussion
                    obj_postPanelFormConfig={postPanelFormConfig}
                    obj_postPanelDynConfig={dynConfig}
                    nbr_discussMsgId={questionId}
                    str_discussMsgUrl={questionMsgQueryUrl}
                    str_discussMsgVoteBaseUrl={str_questionVoteBaseUrl}
                    comp_discussMsg={<QuestionMessage />}
                    str_msgCommentBaseUrl={str_msgCommentBaseUrl}
                    str_msgEditBaseUrl={str_msgEditBaseUrl}
                    str_msgVoteBaseUrl={str_msgVoteBaseUrl}
                    b_isMsgBodyHideable={false}
                />
            </DiscussionContextProvider>
        </DynamicContentLoadContextProvider>
    )
}

export default TasksDetailPage
