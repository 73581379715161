import React from 'react';
import PropTypes from 'prop-types';

import TbRowIndustryScheduledJobs from './TbRowIndustryScheduledJobs';
import NoPPDataAvailable from '../../../profile-pages/NoPPDataAvailable';
import { useJobsData } from './IndustryPPJobsContextProvider';


const TbIndustryScheduledJobs = ({
    str_textAlignment='text-left',
    str_design='light-grey'
}) => {

    const jobs = useJobsData()

    return (
        jobs.scheduled && jobs.scheduled[0] /* Key must exist and array must not be empty. */
        ?
        <div className='tb-wrapper'>
            <table cellSpacing="0" className={`tb-base ${str_textAlignment} ${str_design}`}>
                <thead>
                    <tr>
                        <td>Titel</td>
                        <td>Wird gepostet</td>
                        <td className="hide-max-w-1024px">Start</td>
                        <td>Edit</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        jobs.scheduled.map((item, index) => (
                            <TbRowIndustryScheduledJobs
                                key={index}
                                obj_rowData={item}
                            />
                        ))
                    }
                </tbody>
            </table>
        </div>
        :
        <NoPPDataAvailable str_message='Du hast keine Jobs geplant.' />
    )
}

TbIndustryScheduledJobs.propTypes = {
    str_textAlignment: PropTypes.string,
    str_design: PropTypes.string
}

export default TbIndustryScheduledJobs
