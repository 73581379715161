import React from 'react';
import PropTypes from 'prop-types';

import HeaderWrapper from '../navbars/HeaderWrapper';
import MainNavbar from '../navbars/MainNavbar';
import SecondaryNavbar from '../navbars/SecondaryNavbar';
import MainFooter from '../footers/MainFooter';

import AuthService from '../../util/authentication/auth_service';

const HeaderAndFooter = ({
    str_mainNavDataKey='',
    str_secNavPrDataKey='',
    str_secNavSecDataKey='',
    b_hasMainNavbar=true,
    b_hasMainNavLogoLink=true,
    b_hasFooter=true,
    children
}) => {
    const usertype = AuthService.getUsertype();
    return (
        <>
            <HeaderWrapper>
                {
                b_hasMainNavbar &&
                <MainNavbar
                    str_userCategory={str_mainNavDataKey ? str_mainNavDataKey : usertype}
                    b_hasLogoLink={b_hasMainNavLogoLink}
                />
                }
                {
                    str_secNavPrDataKey &&
                    <SecondaryNavbar
                        str_prDataKey={str_secNavPrDataKey}
                        str_secDataKey={str_secNavSecDataKey}
                    />
                }
            </HeaderWrapper>
            {children}
            {
                b_hasFooter &&
                <MainFooter />
            }
        </>
    )
}

HeaderAndFooter.propTypes = {
    str_mainNavDataKey: PropTypes.string,
    str_secNavPrDataKey: PropTypes.string,
    str_secNavSecDataKey: PropTypes.string,
    b_hasMainNavbar: PropTypes.bool,
    b_hasMainNavLogoLink: PropTypes.bool,
    b_hasFooter: PropTypes.bool,
}

export default HeaderAndFooter
