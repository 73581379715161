/*
 * This script contains all the functions that are necessary
 * in order to check the different input JSX components. 
 * This corresponds to the different input fields used 
 * on the page. 
 */
import { extractFileExtension } from '../../../util/data_handler.js';
import FieldErrorMsgs from './input_form_fields_error_msgs.json';
import FieldLimits from './db_field_limits.json';

const REQUIRED_EMSG = FieldErrorMsgs.required;
const MISSING_EMSG = FieldErrorMsgs.datetime.timespan.fieldMissing;

const IMAGE_FILE_EXTENSIONS = [
    'apng', 'avif', 'jpg', 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'webp'
];

export const isRequiredFulfilled = (value, sc=false, mc=false) => {
    /**
     * Check if field has a value that is not empty.
     * :Input
     *  value: Input field value.
     *  setErrorMsg: Function pointer to the err message state.  
     *  sc (bool): Determines if a single choice field should be checked. 
     *  mc (bool): Determines if a multiple choice field should be checked. 
     * :Returns
     *  True : If value is not empty. 
     *  False: Otherwise. 
     */
    if (!value && value !== 0) { /* undefined, null, '' */
        return false
    }
    else if (sc && (value === 'Keine Option gewählt')) {
        return false
    }
    else if (mc && (value.length === 0)) {
        return false
    } else {
        if (value.toString().length === 0) return false
    }
    return true
}

export const isMinValueBoundValid = (value, minValue) => {
    /**
     * Checks if the input field value is above the min. allowed value. 
     * :Inputs
     *  value (number): Value of the html input field. 
     *  minValue (number): Min. allowed value of html input field. 
     */
    if (value < minValue) return false
    return true
}

export const isMaxValueBoundValid = (value, maxValue) => {
    /**
     * Checks if the input field value is below the max allowed value. 
     * :Inputs
     *  value (number): Value of the html input field.
     *  maxValue (number): Max. allowed value of html input field. 
     */
    if (value > maxValue) return false
    return true
}

export const hasComma = (value) => {
    /**
     * Checks if a comma character  (',', '.') is in the input string. 
     * :Inputs
     *  value (str): Input value to be checked for comma char. 
     */
    let b_hasComma = false
    const str_intInput = value.toString();
    [...str_intInput].forEach((char) => {
        if ((char === ',') || (char === '.')) {
            b_hasComma = true
            return
        }
    });
    return b_hasComma
}

export const isEmailFormatValid = (str_email) => {
    /**
     * Check if the provided email format is valid. 
     * Check if format contains one '@', a '.' after the '@' and the '.'
     * is not the last character of the input string. 
     * :Input
     *  str_email (str): Email input string. 
     */
    let b_emailFormatIsValid = false
    if (str_email.includes('@')) {
        const kint_countAt = (str_email.match(/@/g) || []).length
        if (kint_countAt === 1) {
            const karr_splitString = str_email.split('@')
            const kstr_lastPart = karr_splitString[karr_splitString.length-1]
            if (kstr_lastPart.includes('.')) {
                if (kstr_lastPart[kstr_lastPart.length-1] !== '.') {
                    b_emailFormatIsValid = true
                }
            }
        }
    }
    return b_emailFormatIsValid
}

export const isPhoneNumberFormatValid = (str_phoneNumber) => {
    /**
     * Checks if the provided phone number format is correct. 
     * Valid formats consist only of digits [0 - 9] and '+', as the
     * internationl phone number format is required. 
     * :Input
     *  str_phoneNumber (str)
     */
    str_phoneNumber = str_phoneNumber.replaceAll(' ', '');
    const kre_regex = /\+[0-9]+$/;
    if (kre_regex.test(str_phoneNumber)) {
        return true;
    } else {
        return false;
    }
}

/* Date checks. */

const isBrowserDateFormatValid = (str_date) => {
    /**
     * Checks if the browser standard date format 'yyyy-mm-dd' is correct. 
     * :Input
     *  str_date (str): Date. 
     * :Returns
     *  True: If format is correct.
     *  False: Otherwise. 
     */
    const kre_fourTwoTwo = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
    if (kre_fourTwoTwo.test(str_date)) return true;

    const kre_fourTwoOne = /^[0-9]{4}-(0[1-9]|1[0-2])-([1-9])$/;
    if (kre_fourTwoOne.test(str_date)) return true;

    const kre_fourOneTwo = /^[0-9]{4}-([1-9])-(0[1-9]|[1-2][0-9]|3[0-1])$/;
    if (kre_fourOneTwo.test(str_date)) return true;

    const kre_fourOneOne = /^[0-9]{4}-([1-9])-([1-9])$/;
    if (kre_fourOneOne.test(str_date)) return true;

    return false;
}

const isGermanDateFormatValid = (str_date) => {
    /**
     * Checks if the german date format 'dd.mm.yyyy' is correct. 
     * :Input
     *  str_date (str): Date. 
     * :Returns
     *  True: If format is correct. 
     *  False: Otherwise. 
     */
    const kre_twoTwoFour = /^(0[1-9]|[1-2][0-9]|3[0-1])\.(0[1-9]|1[0-2])\.[0-9]{4}$/;
    if (kre_twoTwoFour.test(str_date)) return true;
    
    const kre_oneTwoFour = /^([1-9])\.(0[1-9]|1[0-2])\.[0-9]{4}$/;
    if (kre_oneTwoFour.test(str_date)) return true;
    
    const kre_twoOneFour = /^(0[1-9]|[1-2][0-9]|3[0-1])\.([1-9])\.[0-9]{4}$/;
    if (kre_twoOneFour.test(str_date)) return true;
    
    const kre_oneOneFour = /^([1-9])\.([1-9])\.[0-9]{4}$/;
    if (kre_oneOneFour.test(str_date)) return true;

    return false;
}

export const isTextDateFormatValid = (str_date) => {
    /**
     * Checks if a user's date input format is correct. 
     * Allowed formats are: 'dd.mm.yyyy', 'dd.m.yyyy', 'd.mm.yyyy', 'd.m.yyyy'. 
     * :Input
     *  str_date (str): Date to be checked. 
     * :Returns
     *  True: If format is correct. 
     *  False: Otherwise. 
     */
    str_date = str_date.trim();

    /* If the input field is empty, say that it is valid. */
    if (str_date === '') return true

    /* Check valid numbers at their positions. */
    var int_year, int_month, int_day
    if (str_date.includes('-')) {
        if (!isBrowserDateFormatValid(str_date)) {
            return false
        } else {
            const karr_splitDate = str_date.split('-')
            int_year = parseInt(karr_splitDate[0], 10)
            int_month = parseInt(karr_splitDate[1], 10)
            int_day = parseInt(karr_splitDate[2], 10)
        }
    } else {
        if (!isGermanDateFormatValid(str_date)) {
            return false
        } else {
            const karr_splitDate = str_date.split('.')
            int_year = parseInt(karr_splitDate[2], 10)
            int_month = parseInt(karr_splitDate[1], 10)
            int_day = parseInt(karr_splitDate[0], 10)
        }
    }
    
    /* Check day value for months that only have 30 days. */
    const kint_limit31days = 31
    const karr_30dayMonths = [4, 6, 9, 11]
    if (karr_30dayMonths.includes(int_month)) {
        /* Check if day is 31 although it is not possible for the month. */
        if (int_day === kint_limit31days) {
            return false
        }
    }

    if (int_month === 2) {
        /* Check day value for month February. */
        /* Check gap year. */
        let kint_limitFebruary
        if ((int_year % 4) === 0) {
            kint_limitFebruary = 29
        } else {
            kint_limitFebruary = 28
        }

        if (int_day > kint_limitFebruary) {
            return false
        }
    }

    return true
}

export const isTextTimeFormatValid = (str_time) => {
    /**
     * Checks if a user's time input format is correct. 
     * Allowed formats are: 'H:M', 'H:MM', 'HH:M', 'HH:MM'. 
     * :Input
     *  dom_inputFieldWrapper: HTML DOM <div> that has the class '.in-field', which
     *                         acts as wrapper for all <input>.
     * :Returns
     *  True: If user provided a valid time format. 
     *  False: Otherwise. 
     */
    str_time = str_time.trim()
    /* Map time input against valid time formats. */
    const kre_regex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
    if (kre_regex.test(str_time)) return true
    else return false
}

export const isTextDateTimeFormatValid = (str_dateTime) => {
    /**
     * Checks if a user's date time input format is correct. 
     * :Input
     *  str_dateTime (str): Represents date time format.
     * :Returns
     *  True: If user provided a valid date time format. 
     *  False: Otherwise. 
     */
    /**
     * str_dateTime must only contain either one ' ' or one 'T'.
     * These chars identify the separation between date and time. 
     * ' ' or 'T' depends on the input of the datetime field. 
     */
    let arr_a;
    str_dateTime = str_dateTime.trim();
    /* Check for space char. */
    arr_a = str_dateTime.split(' ');
    if (arr_a.length !== 2) {
        /* Check for T char. */
        arr_a = str_dateTime.split('T');
        if (arr_a.length !== 2) {
            return false
        }
    }
    return (isTextDateFormatValid(arr_a[0]) && isTextTimeFormatValid(arr_a[1]))
}

export const isTimeSpanPartValid = (fct_isTimeSpanValid, timePoint, b_isStartDateTime) => {
    /**
     * Handle date-time span fields. Wrapper function for the parent component. 
     * The child components are the start and end time/date components. 
     * fct_isTimeSpanValid: Function to check validity and set error messages. 
     * Returns
     * :True: if the time span is valid, i.e. the start point is smaller 
     *        than the endpoint. 
     * :False: Otherwise. 
     */
    if (fct_isTimeSpanValid) {
        if (fct_isTimeSpanValid(timePoint, b_isStartDateTime)) return true;
        return false;
    }
    return true;
}

const setSpanPartsErrorMessages = (
    /**
     * Sets the error message for the span part that is lacking input.
     * Clears the value of the other. 
     */
    b_isTimePointStart,
    fct_setErrorMsg1,
    fct_setErrorMsg2
) => {
    if (b_isTimePointStart) {
        fct_setErrorMsg1(MISSING_EMSG);
        fct_setErrorMsg2('');
    } else {
        fct_setErrorMsg2(MISSING_EMSG);
        fct_setErrorMsg1('');
    }
}

export const isSpanPartValid = (
    timePoint,
    timeLimit,
    b_isTimePointStart,
    str_errorMsg,
    fct_setTimePoint,
    fct_setStartErrorMsg,
    fct_setEndErrorMsg,
    b_hasSpanChanged,
    fct_setHasSpanChanged,
    b_isRequired
) => {
    /**
     * Checks if the span field is valid and sets error message states 
     * according to the encountered errors.  
     * :Input
     *  timePoint: Represents either a date, time or dateTime. 
     *  timeLimit: Either a max. or min. date, time or dateTime. 
     *  b_isTimePointStart: Is time point the start or end of the span field. 
     *  str_errorMsg: Error message for this specific case. 
     *  fct_setTimePoint: Sets the time point state. 
     *  fct_setStartErrorMsg: Sets the error message of the start point. 
     *  fct_setEndErrorMsg: Sets the error message of the end point.
     *  b_hasSpanChanged: Keeps track of state changes of the span component. 
     *  fct_setHasSpanChanged: Function to change the value of b_hasSpanChanged. 
     *  b_isRequired: Are the error fields of the span required.  
     * :Returns
     *  True: If this part of the time span is valid. 
     *  False: Otherwise. 
     */

    /* Set start/end date of span field. */
    fct_setTimePoint(timePoint);

    /* This function changes at least one state of the span component,
     * therefore the change flag is swapped in its value (true/false). */
    fct_setHasSpanChanged(!b_hasSpanChanged)

    /* Handle empty/empty, full/empty, empty/full cases. */
    if (!timePoint) {
        if (timeLimit) {
            /* Invalid: empty/full case (this part is empty, error). */
            setSpanPartsErrorMessages(
                b_isTimePointStart,
                fct_setStartErrorMsg,
                fct_setEndErrorMsg
            )
            return false;
        } else {
            /* Valid: both empty (no time comparison necessary). */
            if (b_isTimePointStart) {
                if (b_isRequired) fct_setEndErrorMsg(REQUIRED_EMSG);
                else fct_setEndErrorMsg('');
            } else {
                if (b_isRequired) fct_setStartErrorMsg(REQUIRED_EMSG);
                else fct_setStartErrorMsg('');
            }
            return true;
        }
    } else {
        if (!timeLimit) {
            /* Valid: full/empty case (this part is full, no error). */
            setSpanPartsErrorMessages(
                b_isTimePointStart, 
                fct_setEndErrorMsg, 
                fct_setStartErrorMsg
            )
            return true;
        }
    }

    /* Handle full/full case. */
    let time1, time2, setErrorMsg;
    if (b_isTimePointStart) {
        time1 = timePoint; /* Time. */
        time2 = timeLimit; /* End time. */
        setErrorMsg = fct_setStartErrorMsg;
    } else {
        time1 = timeLimit; /* Start time. */
        time2 = timePoint; /* Time. */
        setErrorMsg = fct_setEndErrorMsg;
    }
    if (time1 < time2) {
        /* Valid: Time comparison succeeded (clear error of both parts). */
        fct_setStartErrorMsg('');
        fct_setEndErrorMsg('');
        return true;
    } else {
        /* Invalid: Time comparison failed for this part. */
        setErrorMsg(str_errorMsg);
        return false;
    }
}

export const isImageFile = (str_fileName) => {
    /**
     * Checks if the input file is an image file.
     * :Returns
     *  True: If file is an image file. 
     *  False: Otherwise. 
     */
    if (typeof(str_fileName) !== 'string') return null;
    const extension = extractFileExtension(str_fileName);
    return IMAGE_FILE_EXTENSIONS.includes(extension);
}

export const isPasswordFormatValid = (str_pw) => {
    const int_len = str_pw.length;
    if (int_len < FieldLimits.password.length.min || int_len > FieldLimits.password.length.max)
        return false;
    
    const regexLettersDigits = /(?=.*[A-Z]+)(?=.*[a-z]+)(?=.*[0-9]+)/;
    if (!regexLettersDigits.test(str_pw)) return false;
    
    const regexSpecialChars = /(?=.*[,;:+*_#$%&~()-]+)/;
    if (!regexSpecialChars.test(str_pw)) return false;
    
    /* Must not include. */
    const regexExcludedChars = /[^A-Za-z0-9,;:+*_#$%&~()-]+/;
    if (regexExcludedChars.test(str_pw)) return false;
    
    return true;
}

export const isCourseDocumentTitleFormatValid = (str_title) => {
    /**
     * Checks the input title for a document that is uploaded to a course.
     * :Input
     *  str_title (str): Title of the document that is visible on the platform.
     */
    if (typeof(str_title) !== 'string') return false;
    /* All chars apart from the one in the list must not appear in the input string. */
    const regexExcludedChars = /[^A-Za-z0-9 _-]+/;
    if (regexExcludedChars.test(str_title)) return false;
    return true;
}
