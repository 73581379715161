import React from 'react';
import PropTypes from 'prop-types';

import InputFormContextProvider from '../input-forms/context-provider/InputFormContextProvider';
import PanelContextProvider from './context-provider/PanelContextProvider';
import PanelBase from './PanelBase';

import { static2dynamicPanelConfig } from '../input-forms/util/input_form_data_handler';

const PanelPost = ({
    obj_staticConfig,       /* Static config from config file. */
    obj_dynConfig=null,     /* Dynamic data (passed on the go) that overrides the static config. */
    arr_indicators=['all'], /* Which text format options are available. */
    str_requestType='post', /* Either post or put request is used for data submission. */
    str_firstName,          /* User names for panel header. */
    str_lastName,
    str_username,
    b_hasDarkDesign=false,
    comp_inputForm          /* If the std. message input form should not be used. */
}) => {
    return (
        <InputFormContextProvider
            {...static2dynamicPanelConfig(obj_staticConfig, obj_dynConfig)}
            str_requestType={str_requestType}
            b_isDiscussionContext={true}
            b_hasMediaData={true}
        >
            {
                comp_inputForm
                ?
                comp_inputForm
                :
                <PanelContextProvider arr_indicators={arr_indicators}>
                    <PanelBase
                        obj_fieldsConfig={obj_staticConfig.fields}
                        arr_indicators={arr_indicators}
                        str_firstName={str_firstName}
                        str_lastName={str_lastName}
                        str_username={str_username}
                        b_hasDarkDesign={b_hasDarkDesign}
                    />
                </PanelContextProvider>
            }
        </InputFormContextProvider>
    )
}

PanelPost.propTypes = {
    obj_staticConfig: PropTypes.object.isRequired,
    obj_dynConfig: PropTypes.object,
    arr_indicators: PropTypes.array,
    str_requestType: PropTypes.string,
    str_firstName: PropTypes.string.isRequired,
    str_lastName: PropTypes.string.isRequired,
    str_username: PropTypes.string.isRequired,
    b_hasDarkDesign: PropTypes.bool
}

export default PanelPost
