import React from 'react';

import FormBase from '../../../FormBase';
import InFieldsGroup from '../../../InFieldsGroup';
import FileField from '../../../fields/FileField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { GastroDrinksMenuFormData as FormData } from '../../../util/form-config-data/InputFormGastroDataConfigs';

const InputFormDrinkMenus = () => {
    const data = useInputData()
    return (
        <FormBase str_title='Getränkekarten'>
            <InFieldsGroup b_hasBorder={false}>
                <FileField
                    {...FormData.fields.drinkMenu0}
                    inFile={data[FormData.fields.drinkMenu0.str_id][0]}
                />
                <FileField
                    {...FormData.fields.drinkMenu1}
                    inFile={data[FormData.fields.drinkMenu1.str_id][0]}
                />
                <FileField
                    {...FormData.fields.drinkMenu2}
                    inFile={data[FormData.fields.drinkMenu2.str_id][0]}
                />
                <FileField
                    {...FormData.fields.drinkMenu3}
                    inFile={data[FormData.fields.drinkMenu3.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormDrinkMenus
