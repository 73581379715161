import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import DataStateCheckWrapper from '../../../general/DataStateCheckWrapper';

import { string2URLstring } from '../../../../util/data_handler';
import {
    useQueriedDataContext,
    useIsFirstFetchSuccessContext,
    useInfiniteScrollContext
} from '../../../context-provider/DynamicContentLoadContextProvider';

const TbCompanies = ({
    arr_data=[],
    str_textAlignment='text-left',
    str_design='',
    b_isDynamic=false
}) => {

    const isFirstFetchSuccessRef = useIsFirstFetchSuccessContext()
    const infiniteScrollRef = useInfiniteScrollContext()

    if (b_isDynamic)
        arr_data = useQueriedDataContext()

    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={b_isDynamic}
            b_isFirstFetchSuccess={isFirstFetchSuccessRef.current}
            firstQueryDataEntry={arr_data[0]}
        >
            <div className="tb-wrapper">
                <table ref={infiniteScrollRef} cellSpacing="0" className={`tb-base ${str_textAlignment} ${str_design}`}>
                    <thead>
                        <tr>
                            <td>Unternehmen</td>
                            <td>Standort</td>
                            <td>Land</td>
                            <td>Bezirk</td>
                            <td className="hide-max-w-576px">Gegründet</td>
                            <td className="hide-max-w-480px">Mitarbeiter</td>
                            <td className="hide-max-w-768px">Büros</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            arr_data.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <Link
                                            to={`/company/info-page/${string2URLstring(item.companyName)}/${item.id}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="file-link"
                                        >
                                            {item.companyName}
                                        </Link>
                                    </td>
                                    <td>{item.location}</td>
                                    <td>{item.county ? item.county : '—'}</td>
                                    <td>{item.district ? item.district : '—'}</td>
                                    <td className="hide-max-w-576px">{item.yearFounded}</td>
                                    <td className="hide-max-w-480px">{item.numEmployeesWorld}</td>
                                    <td className="hide-max-w-768px">{item.numOfficesWorld}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </DataStateCheckWrapper>
    )
}

TbCompanies.propTypes = {
    arr_data: PropTypes.array,
    str_textAlignment: PropTypes.string,
    str_design: PropTypes.string,
    b_isDynamic: PropTypes.bool
}

export default TbCompanies
