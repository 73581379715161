import React from 'react';
import PropTypes from 'prop-types';

import Message from './Message';
import MessageContextProvider from './context-provider/MessageContextProvider';
import AuthService from '../../util/authentication/auth_service';

const MessageComments = ({ arr_comments }) => {
    const username = AuthService.getUsername()
    return (
        <div className="msg-reply">
            {
                arr_comments.map((msg, index) => (
                    <MessageContextProvider
                        key={index}
                        obj_msg={msg}
                        b_isOwner={username === msg.username}
                        b_isMsgBodyOpen={true} /* Comments are always open. */
                        b_isComment={true}
                    >
                        <Message />
                    </MessageContextProvider>
                ))
            }
        </div>
    )
}

MessageComments.propTypes = {
    arr_comments: PropTypes.array.isRequired
}

export default MessageComments
