/**
 * This component handles the data serialization for vote components.
 * It is, for instance, for messages. Here data is extracted from the
 * message object and serialized to a format that can be parsed by
 * the Vote.js component.
 */

import AuthService from "../authentication/auth_service";

const voteSerializer = (data, voteUrl, prLsKey, secLsKey, terLsKey) => {
    /**
     * Extracts the data relevant for the Vote.js component form
     * an object and returns it as an object.
     * :Input
     *  data (obj)    : Entails the vote data.
     *  voteUrl (str) : Url to sent the post request to.
     *  prLsKey (str) : First level LS key.
     *  secLsKey (str): Second level LS key.
     *  terLsKey (str): Tercary level LS key.
     * :Returns
     *  Vote data (obj)
     */
    if (data === null || typeof(data) !== 'object' || !voteUrl) return {};

    /* Variable to extract data from input variable 'data' and store it
     * in the return object. */
    const keys = [
        ['nbr_itemId'      , 'id'          ],
        ['nbr_itemAuthorId', 'authorId'    ],
        ['nbr_upvotes'     , 'numUpvotes'  ],
        ['nbr_downvotes'   , 'numDownvotes'],
        ['b_userVoted'     , 'userVoted'   ],
    ];

    let voteData = {};
    voteData['nbr_userId'] = AuthService.getUserId();
    voteData['str_url'] = voteUrl;
    voteData['str_prLsKey'] = prLsKey;
    voteData['str_secLsKey'] = secLsKey;
    voteData['str_terLsKey'] = terLsKey;
    keys.forEach(keyPair => {
        voteData[keyPair[0]] = data[keyPair[1]];
    })

    return voteData;
}

const votesSerializer = (data, voteUrl, prLsKey, secLsKey, terLsKey) => {
    /**
     * :Input
     *  data (array): Items are the JS objects to be serialized.
     * :Returns
     *  Array of serialied vote objects.
     */
    let votesData = [];
    data.forEach(dataItem => {
        const serializedVote = voteSerializer(dataItem, voteUrl, prLsKey, secLsKey, terLsKey);
        votesData.push(serializedVote);
    })
    return votesData;
}

const VoteService = {
    voteSerializer: voteSerializer,  /* Serialize one data instance. */
    votesSerializer: votesSerializer /* Serialize multiple data instances at once. */
};

export default VoteService;
