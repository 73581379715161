import React from 'react';
import PropTypes from 'prop-types';

const GridBase = ({
    str_gridClass='',
    children
}) => {
    return (
        <div className="grid-wrapper">
            <div className={`grid-container ${str_gridClass}`}>
                {children}
            </div>
        </div>
    )
}

GridBase.propTypes = {
    str_gridClass: PropTypes.string
}

export default GridBase
