import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import CSecBase from '../../../components/content-sections/CSecBase';
import InputFormContextProvider from '../../../components/input-forms/context-provider/InputFormContextProvider';
import InputFormTask from '../../../components/input-forms/forms/courses/InputFormTask';
import FormSuccessErrorMessage from '../../../components/input-forms/FormSuccessErrorMessage';

import { TaskFormData as FormData } from '../../../components/input-forms/util/form-config-data/InputFormCoursesDataConfigs';
import { static2dynamicPanelConfig } from '../../../components/input-forms/util/input_form_data_handler';
import { genDynUrl } from '../../../util/db_ls_query_handler';
import {
    useAddTaskContext,
    useAddSubtaskContext
} from '../../../components/sidebars/context-provider/SbCourseTasksContextProvider';
import {
    useUpdateDiscussionMsgsContext,
    useUpdateMsgsContext
} from '../../../components/discussion-pages/context-provider/DiscussionCacheContextProvider';

const TasksNewPage = ({
    b_isNewTask=false,
    b_isExercise=false,
    str_urlParam, /* Url param used to update the sb with newly created posts. */
    str_postNewQuestionBaseUrl,
    str_postNewTaskUrl,
    str_formTitle,
}) => {

    const params = useParams()
    const [isDataPosted, setIsDataPosted] = useState(false)
    
    /* Sidebar context provider hooks. */
    const addTask2sb = useAddTaskContext()
    const addSubtask2sb = useAddSubtaskContext()
    /* Discussion cache context provider hooks. */
    const updateDiscussionMsgs = useUpdateDiscussionMsgsContext()
    const updateMsgs = useUpdateMsgsContext()

    const url = params[str_urlParam] ?
                genDynUrl(str_postNewQuestionBaseUrl, params[str_urlParam]) :
                genDynUrl(genDynUrl(str_postNewTaskUrl, params.id), params.year) /* id is course id */
    
    const dynConfig = {
        str_queryURL: url,
        obj_initState: {
            /* Question only form must not have 'date' as required. */
            date: ['', b_isNewTask ? true : false]
        }
    }
    
    const post = (response) => {
        setIsDataPosted(true)
        /* Update stateof sb. */
        const taskId = params[str_urlParam]
        if (taskId !== undefined) {
            addSubtask2sb(response.data.sb.id, response.data.sb.question)
        } else {
            addTask2sb(response.data.sb)
        }
        /* Update discussion cache. */
        updateDiscussionMsgs(response.data.question.data, response.data.question.id)
        const initDynLoadArr = [
            [],  /* No msgs available on create. */
            1,   /* Placeholder: Value of the id of the last dyn. fetched item. */
            true /* Is all data loaded: true as no data is available. */
        ]
        updateMsgs(initDynLoadArr, response.data.question.id)
    }

    return (
        <CSecBase>
            {
                isDataPosted
                ?
                <FormSuccessErrorMessage b_isSuccess={true} />
                :
                <InputFormContextProvider
                    {...static2dynamicPanelConfig(FormData, dynConfig)}
                    fct_response2parent={post}
                    b_hasMediaData={true}
                >
                    <InputFormTask
                        b_isNewTask={b_isNewTask}
                        b_isExercise={b_isExercise}
                        str_formTitle={str_formTitle}
                    />
                </InputFormContextProvider>
            }
        </CSecBase>
    )
}

TasksNewPage.propTypes = {
    b_isNewTask: PropTypes.bool,
    b_isExercise: PropTypes.bool,
    str_urlParam: PropTypes.string.isRequired,
    str_postNewQuestionBaseUrl: PropTypes.string.isRequired,
    str_postNewTaskUrl: PropTypes.string.isRequired,
    str_formTitle: PropTypes.string
}

export default TasksNewPage
