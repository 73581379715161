import React from 'react';
import PropTypes from 'prop-types';
import ClipLoader from "react-spinners/ClipLoader";

import { QueryVariables } from '../../util/QueryVariables';

const HandleNoData = ({
    str_errorMsg='',
    str_classes='',
    b_isLoadingData=false
}) => {
    return (
        <div className={`no-data-available-msg ${str_classes}`}>
            {
                str_errorMsg
                ?
                <p>{str_errorMsg}</p>
                :
                    b_isLoadingData
                    ?
                    <p>
                        <ClipLoader
                            loading={true}
                            color={"#99ccff"}
                            size="1.2rem"
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </p>
                    :
                    <p>{QueryVariables.str_stdNoDataMsg}</p>
            }
        </div>
    )
}

HandleNoData.propTypes = {
    str_errorMsg: PropTypes.string,
    str_classes: PropTypes.string,
    b_isLoadingData: PropTypes.bool
}

export default HandleNoData
