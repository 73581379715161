import React from 'react';

import FormBaseWelcome from '../../FormBaseWelcome';
import InFieldsGroup from '../../InFieldsGroup';
import TextField from '../../fields/TextField';

import { useInputData } from '../../context-provider/InputFormContextProvider';
import { StudentUserRegistrationFormData as FormData } from '../../util/form-config-data/InputFormWelcomeDataConfigs';

const InputFormStudentUserRegistration = () => {
    const data = useInputData()
    return (
        <FormBaseWelcome
            str_btnTitle='Registrieren'
            b_isRequiredInfoShown={false}
        >
            {
                <InFieldsGroup b_hasBorder={false}>
                    <TextField
                        {...FormData.fields.emailName}
                        str_chars={data[FormData.fields.emailName.str_id][0]}
                    />
                </InFieldsGroup>
            }
        </FormBaseWelcome>
    )
}

export default InputFormStudentUserRegistration
