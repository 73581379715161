/**
 * This file entails the configuration of the forms of profile pages. 
 * E.g. for the form where a user can change the slogan.
 * 
 * The file that makes use of this config file is PPForm.js.
 * 
 * Every config object must have the following structure, which is defined
 * by the props of PPForm.js:
 * 
 * {
 *      category (str): ...,         // Secondary key for LS.
 *      subcategory (str): ...,      // Tercary key for LS.
 *      url (str): ...,              // API URL.
 *      form (react component): ..., // React form component.
 *      formConfig (obj): ...        // Config object for the used form. 
 *      isLSused (bool): ...         // Should data be stored in the LS?
 *      hasMediaData (bool): ...     // Are files uploaded?
 *      // Profile setup key/value pair (only used when the profile is set up).
 *      psNextStep: 'slogan',        // Next profile setup step to be done by the user.
        psStepIndex: 1               // Index of the profile setup step.
 * }
 */

import React from 'react';

/* All users. */

import InputFormPrivacyTermsAgreement from '../../forms/general/account-settings/InputFormPrivacyTermsAgreement';
import InputFormSlogan from "../../forms/general/account-settings/InputFormSlogan";
import InputFormLogo from '../../forms/general/account-settings/InputFormLogo';
import InputFormEntityContact from '../../forms/general/account-settings/InputFormEntityContact';
import InputFormPasswordChange from '../../forms/general/password/InputFormPasswordChange';
import {
    PrivacyTermsFormData,
    SloganFormData,
    LogoFormData,
    EntityContactFormData
} from "../../util/form-config-data/InputFormAccountSettingsDataConfigs";
import { PasswordNewFormData } from '../../util/form-config-data/InputFormPasswordDataConfigs';

/* Company. */
import InputFormIndustryNumbers from '../../forms/industry/account-settings/InputFormIndustryNumbers';
import InputFormIndustryDescriptions from '../../forms/industry/account-settings/InputFormIndustryDescriptions';
import InputFormIndustryBenefits from '../../forms/industry/account-settings/InputFormIndustryBenefits';
import InputFormIndustryEqualOps from '../../forms/industry/account-settings/InputFormIndustryEqualOps';
import InputFormIndustryInternshipOptions from '../../forms/industry/account-settings/InputFormIndustryInternshipOptions';
import InputFormIndustryContactPerson from '../../forms/industry/account-settings/InputFormIndustryContactPerson';
import {
    IndustryNumbersFormData,
    IndustryDescriptionsFormData,
    IndustryBenefitsFormData,
    IndustryEqualOpportunityEmployerData,
    IndustryInternshipOptionsFormData,
    IndustryContactPersonFormData
} from '../../util/form-config-data/InputFormIndustryDataConfigs';

/* Gastronomer. */
import InputFormConsumeOptions from '../../forms/gastro/account-settings/InputFormConsumeOptions';
import InputFormSpecialOffers from '../../forms/gastro/account-settings/InputFormSpecialOffers';
import InputFormGastroContactPerson from '../../forms/gastro/account-settings/InputFormGastroContactPerson';
import InputFormFoodMenus from '../../forms/gastro/account-settings/InputFormFoodMenus';
import InputFormDrinkMenus from '../../forms/gastro/account-settings/InputFormDrinkMenus';
import {
    GastroConsumeOptionsFormData,
    GastroSpecialOffersFormData,
    GastroContactPersonFormData,
    GastroFoodMenuFormData,
    GastroDrinksMenuFormData
} from '../../util/form-config-data/InputFormGastroDataConfigs';


/* Configs. */

const BASE_URL = '/api/profiles/';
const GASTRONOMER_URL = BASE_URL + 'gastronomer/';
const COMPANY_URL = BASE_URL + 'company/';

/* All users. */

const password = {
    url: BASE_URL + 'password/change/',
    form: <InputFormPasswordChange />,
    formConfig: PasswordNewFormData,
    urlFormat: 'password',
    title: 'Neues Passwort'
};

const logo = {
    category: 'info',
    subcategory: 'logo',
    urlFormat: 'logo',
    form: <InputFormLogo />,
    formConfig: LogoFormData,
    isLSused: true,
    hasMediaData: true,
    psNextStep: 'privacyTermsAgreement',
    title: 'Logo'
};

const slogan = {
    category: 'info',
    subcategory: 'slogan',
    form: <InputFormSlogan />,
    formConfig: SloganFormData,
    isLSused: true,
    title: 'Slogan'
};

const contact = {
    category: 'contacts',
    subcategory: 'entity',
    form: <InputFormEntityContact />,
    formConfig: EntityContactFormData,
    isLSused: true,
    title: 'Betriebskontakt'
};

const privacyTermsAgreement = {
    url: BASE_URL + 'privacy-terms-agreement/',
    urlFormat: 'privacy-and-terms',
    form: <InputFormPrivacyTermsAgreement />,
    formConfig: PrivacyTermsFormData,
    isLSused: false,
    psNextStep: 'complete',
    title: 'Datenschutz & Nutzungsbedingungen'
};

/* Company. */

const companyPassword = {
    ...password,
    psNextStep: 'slogan',
    psStepIndex: 1
};

const companySlogan = {
    ...slogan,
    url: COMPANY_URL + 'slogan/',
    urlFormat: 'slogan',
    psNextStep: 'numbers',
    psStepIndex: 2
};

const companyNumbers = {
    category: 'info',
    subcategory: 'numbers',
    url: COMPANY_URL + 'numbers/',
    form: <InputFormIndustryNumbers />,
    formConfig: IndustryNumbersFormData,
    isLSused: true,
    urlFormat: 'numbers',
    psNextStep: 'descriptions',
    psStepIndex: 3,
    title: 'Zahlen'
};

const companyDescriptions = {
    category: 'info',
    subcategory: 'descriptions',
    url: COMPANY_URL + 'descriptions/',
    form: <InputFormIndustryDescriptions />,
    formConfig: IndustryDescriptionsFormData,
    isLSused: true,
    urlFormat: 'descriptions',
    psNextStep: 'benefits',
    psStepIndex: 4,
    title: 'Beschreibungen'
};

const companyBenefits = {
    category: 'info',
    subcategory: 'benefits',
    url: COMPANY_URL + 'benefits/',
    form: <InputFormIndustryBenefits />,
    formConfig: IndustryBenefitsFormData,
    isLSused: true,
    urlFormat: 'benefits',
    psNextStep: 'equalOpsEmployer',
    psStepIndex: 5,
    title: 'Mitarbeitervorteile'
};

const equalOpsEmployer = {
    category: 'info',
    subcategory: 'equalOpsEmployer',
    url: COMPANY_URL + 'equal-opportunity-employer/',
    form: <InputFormIndustryEqualOps />,
    formConfig: IndustryEqualOpportunityEmployerData,
    isLSused: true,
    urlFormat: 'equal-opportunity-employer',
    psNextStep: 'internshipOptions',
    psStepIndex: 6,
    title: 'Gleichberechtigung'
};

const internshipOptions = {
    category: 'internshipOptions',
    subcategory: 'internshipOptions',
    url: COMPANY_URL + 'internship-options/',
    form: <InputFormIndustryInternshipOptions />,
    formConfig: IndustryInternshipOptionsFormData,
    isLSused: true,
    urlFormat: 'internship-options',
    psNextStep: 'contact',
    psStepIndex: 7,
    title: 'Praktikumsoptionen'
};

const companyContact = {
    ...contact,
    url: COMPANY_URL + 'contact/',
    urlFormat: 'contact',
    psNextStep: 'contactPerson0',
    psStepIndex: 8
};

const companyContactPerson = {
    category: 'contacts',
    form: <InputFormIndustryContactPerson />,
    formConfig: IndustryContactPersonFormData,
    isLSused: true
};

const companyContactPerson0 = {
    ...companyContactPerson,
    subcategory: 'contactPerson0',
    url: COMPANY_URL + 'contact-person/0/',
    urlFormat: 'contact-person-0',
    psNextStep: 'contactPerson1',
    psStepIndex: 9,
    title: 'Kontaktperson 1'
};

const companyContactPerson1 = {
    ...companyContactPerson,
    subcategory: 'contactPerson1',
    url: COMPANY_URL + 'contact-person/1/',
    urlFormat: 'contact-person-1',
    psNextStep: 'logo',
    psStepIndex: 10,
    title: ' Kontaktperson 2'
};

const companyLogo = {
    ...logo,
    url: COMPANY_URL + 'logo/',
    psStepIndex: 11
};

const companyPrivacyTermsAgreement = {
    ...privacyTermsAgreement,
    psStepIndex: 12
};

/* Gastronomer. */

const gastroPassword = {
    ...password,
    psNextStep: 'slogan',
    psStepIndex: 1
};

const gastroSlogan = {
    ...slogan,
    url: GASTRONOMER_URL + 'slogan/',
    urlFormat: 'slogan',
    psNextStep: 'consumeOptions',
    psStepIndex: 2
};

const consumeOptions = {
    category: 'info',
    subcategory: 'consumeOptions',
    url: GASTRONOMER_URL + 'consume-options/',
    form: <InputFormConsumeOptions />,
    formConfig: GastroConsumeOptionsFormData,
    isLSused: true,
    urlFormat: 'consume-options',
    psNextStep: 'specialOffers',
    psStepIndex: 3,
    title: 'Konsummöglichkeiten'
};

const specialOffers = {
    category: 'info',
    subcategory: 'specialOffers',
    url: GASTRONOMER_URL + 'special-offers/',
    form: <InputFormSpecialOffers />,
    formConfig: GastroSpecialOffersFormData,
    isLSused: true,
    urlFormat: 'special-offers',
    psNextStep: 'foodMenus',
    psStepIndex: 4,
    title: 'Beschreibungen'
};

const foodMenus = {
    category: 'menus',
    subcategory: 'food',
    url: GASTRONOMER_URL + 'food-menus/',
    form: <InputFormFoodMenus />,
    formConfig: GastroFoodMenuFormData,
    isLSused: true,
    hasMediaData: true,
    urlFormat: 'food-menus',
    psNextStep: 'drinksMenus',
    psStepIndex: 5,
    title: 'Speisekarten'
}

const drinksMenus = {
    category: 'menus',
    subcategory: 'drinks',
    url: GASTRONOMER_URL + 'drinks-menus/',
    form: <InputFormDrinkMenus />,
    formConfig: GastroDrinksMenuFormData,
    isLSused: true,
    hasMediaData: true,
    urlFormat: 'drinks-menus',
    psNextStep: 'contact',
    psStepIndex: 6,
    title: 'Getränkekarten'
}

const gastroContact = {
    ...contact,
    url: GASTRONOMER_URL + 'contact/',
    urlFormat: 'contact',
    psNextStep: 'contactPerson0',
    psStepIndex: 7
};

const gastroContactPerson = {
    category: 'contacts',
    form: <InputFormGastroContactPerson />,
    formConfig: GastroContactPersonFormData,
    isLSused: true,
};

const gastroContactPerson0 = {
    ...gastroContactPerson,
    subcategory: 'contactPerson0',
    url: GASTRONOMER_URL + 'contact-person/0/',
    urlFormat: 'contact-person-0',
    psNextStep: 'contactPerson1',
    psStepIndex: 8,
    title: 'Kontaktperson 1'
};

const gastroContactPerson1 = {
    ...gastroContactPerson,
    subcategory: 'contactPerson1',
    url: GASTRONOMER_URL + 'contact-person/1/',
    urlFormat: 'contact-person-1',
    psNextStep: 'logo',
    psStepIndex: 9,
    title: 'Kontaktperson 2'
};

const gastroLogo = {
    ...logo,
    url: GASTRONOMER_URL + 'logo/',
    psStepIndex: 10
};

const gastroPrivacyTermsAgreement = {
    ...privacyTermsAgreement,
    psStepIndex: 11
};

/* Student. */

const studentPassword = {
    ...password,
    psNextStep: 'privacyTermsAgreement',
    psStepIndex: 1
};

const studentPrivacyTermsAgreement = {
    ...privacyTermsAgreement,
    psStepIndex: 2
};

/* Config object. */

const PPFormConfig = {
    allUsers: {
        
    },
    company: {
        password: companyPassword,
        slogan: companySlogan,
        numbers: companyNumbers,
        descriptions: companyDescriptions,
        benefits: companyBenefits,
        equalOpsEmployer: equalOpsEmployer,
        internshipOptions: internshipOptions,
        contact: companyContact,
        contactPerson0: companyContactPerson0,
        contactPerson1: companyContactPerson1,
        logo: companyLogo,
        privacyTermsAgreement: companyPrivacyTermsAgreement
    },
    gastronomer: {
        password: gastroPassword,
        slogan: gastroSlogan,
        consumeOptions: consumeOptions,
        specialOffers: specialOffers,
        foodMenus: foodMenus,
        drinksMenus: drinksMenus,
        contact: gastroContact,
        contactPerson0: gastroContactPerson0,
        contactPerson1: gastroContactPerson1,
        logo: gastroLogo,
        privacyTermsAgreement: gastroPrivacyTermsAgreement
    },
    student: {
        password: studentPassword,
        privacyTermsAgreement: studentPrivacyTermsAgreement
    }
};

export default PPFormConfig;
