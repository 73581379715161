import React, { useState, useContext } from 'react';

import DataStateCheckWrapper from '../../../general/DataStateCheckWrapper';
import useGetLSorDBdata from '../../../../custom-hooks/useGetLSorDBdata';
import { CompanyLsKeys, PPLsKeys } from '../../../../util/LocalStorageVariables';
import { setLSdata } from '../../../../util/db_ls_query_handler';

const JobsDataContext = React.createContext();
const HandleTakeOfflineContext = React.createContext();
const HandleDeleteContext = React.createContext();

export function useJobsData() {
    return useContext(JobsDataContext);
}

export function useHandleTakeOffline() {
    return useContext(HandleTakeOfflineContext);
}

export function useHandleDelete() {
    return useContext(HandleDeleteContext);
}

const LIST_URL = '/api/industry/job/list/company-profile-page/';
const PR_LS_KEY = PPLsKeys.primaryDataKey;
const SEC_LS_KEY = CompanyLsKeys.pp.jobPostings;

const IndustryPPJobsContextProvider = ({ children }) => {
    
    const [jobs, setJobs] = useState('')
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    useGetLSorDBdata(LIST_URL, setJobs, setIsFirstFetchSuccess, setErrorMsg, true, null, null, PR_LS_KEY, SEC_LS_KEY)

    const handleTakeOffline = async (jobId) => {
        /**
         * Moves the job with jobId from the online jobs to the offline ones in LS and state.
         */
        const updatedData = {
            online: jobs.online.filter(job => job.id !== jobId),
            offline: jobs.online.filter(job => job.id === jobId).concat(jobs.offline),
            scheduled: jobs.scheduled
        }
        setJobs(updatedData)
        setLSdata(updatedData, PR_LS_KEY, SEC_LS_KEY)
    }

    const handleDelete = async (jobId) => {
        /**
         * Removes job of jobId from LS and state.
         */
        const updatedData = {
            online: jobs.online,
            offline: jobs.offline.filter(job => job.id !== jobId),
            scheduled: jobs.scheduled
        }
        setJobs(updatedData)
        setLSdata(updatedData, PR_LS_KEY, SEC_LS_KEY)
    }
  
    return (
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            b_isContentSection={true}
            str_errorMsg={errorMsg}
            firstQueryDataEntry={-1} /* Only a placeholder value. */
        >
            <JobsDataContext.Provider value={jobs}>
                <HandleTakeOfflineContext.Provider value={handleTakeOffline}>
                    <HandleDeleteContext.Provider value={handleDelete}>
                        {children}
                    </HandleDeleteContext.Provider>
                </HandleTakeOfflineContext.Provider>
            </JobsDataContext.Provider>
        </DataStateCheckWrapper>
    )
}

export default IndustryPPJobsContextProvider
