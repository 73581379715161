import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import { MdAutorenew } from 'react-icons/md';

import CardPriceTag from '../CardPriceTag';

const CardFoodFooterGastroEdit = ({
    nbr_id,
    nbr_price,
    fct_handleDelete
}) => {
    const navigate = useNavigate()
    return (
        <div className="card-footer-offer">
            <CardPriceTag nbr_price={nbr_price} />
            <div className="action-el">
                <div className="buttons">
                    <button
                        className="btn btn-sm btn-submit btn-icon"
                        onClick={() => navigate(`../reuse/${nbr_id}`)}
                    >
                        <MdAutorenew />
                    </button>
                    <button
                        className="btn btn-sm btn-submit btn-icon btn-cross"
                        onClick={fct_handleDelete}
                    >
                        <AiOutlinePlus />
                    </button>
                </div>
            </div>
        </div>
    )
}

CardFoodFooterGastroEdit.propTypes = {
    nbr_id: PropTypes.number.isRequired,
    nbr_price: PropTypes.number.isRequired,
    fct_handleDelete: PropTypes.func.isRequired
}

export default CardFoodFooterGastroEdit
