import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import FieldWrapper from './FieldWrapper';
import InFieldConfirmationValue from '../InFieldConfirmationValue';

import {
    useHandleInputChange,
    useAreRequiredErrorsActive
} from '../context-provider/InputFormContextProvider';
import { useIsConfirmationViewOpenContext } from '../context-provider/FormBaseContextProvider';

const BOTTOM_TEXT_LIM = 160; /* Threshold value for text shorting. */
const SHORT_TEXT_LEN = 80;   /* Hidden display length. */
const REQUIRED_EMSG = 'Dieses Kästchen muss angekreuzt werden.';

const BoolField = ({
    str_id,
    str_fieldTitle='',
    str_boolInfoText='',
    str_bottomInfoText='',
    b_boolValue=false,
    b_isRequired=false,
    b_isFieldDisplayed=true /* Option to hide field (e.g. given other field inputs). */
}) => {

    const [bool, setBool] = useState(b_boolValue)
    const [isFocused, setIsFocused] = useState(false)
    const [shortInfoText, setShortInfoText] = useState('')
    const [isShortTextDisplayed, setIsShortTextDisplayed] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')

    const handleInputChange = useHandleInputChange()
    const isConfirmationViewOpen = useIsConfirmationViewOpenContext()
    const isRequiredErrorActivate = useAreRequiredErrorsActive()

    const hasError = (fieldBool) => {
        /**
         * If state is not yet set, the input value allows to
         * work with the state of the html <input>.
         * :Input
         *  fieldBool (bool): The value of the html <input>
         */
        if (b_isRequired) {
            if (fieldBool !== undefined) {
                if (!fieldBool) {
                    setErrorMsg(REQUIRED_EMSG)
                    return true
                }
            } else if (!bool) {
                setErrorMsg(REQUIRED_EMSG)
                return true
            }
        }
        if (errorMsg) setErrorMsg('')
        return false
    }

    useEffect(() => {
        if (str_bottomInfoText.length > BOTTOM_TEXT_LIM) {
            setShortInfoText(str_bottomInfoText.slice(0, SHORT_TEXT_LEN) + '...')
            setIsShortTextDisplayed(true)
        }
    }, [str_bottomInfoText])

    useMemo(() => {
        /* If new input is received, update the current char state. */
        setBool(b_boolValue)
    }, [b_boolValue])

    useMemo(() => {
        /* All the required fields that are blank and do not show erros,
         * are activated when a form is submitted. The line below is only 
         * executed once. After the first execution, the required field 
         * displays error messages until the input is correct. */
        if (isRequiredErrorActivate && b_isRequired) hasError()
    }, [isRequiredErrorActivate])

    const handleOnChange = () =>  {
        const b_bool = !bool
        setBool(!bool)
        handleInputChange({id: str_id, value: b_bool, hasError: hasError(b_bool)})
    }

    const handleOnKeyDown = (e) => {
        if (e.key === 'Enter') handleOnChange()
    }

    const display = () => {
        if (!shortInfoText) return
        setIsShortTextDisplayed(!isShortTextDisplayed)
    }

    return (
        isConfirmationViewOpen
        ?
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            b_isRequired={false}
        >
            <InFieldConfirmationValue value={bool ? 'Ja' : 'Nein'} />
        </FieldWrapper>
        :
        b_isFieldDisplayed &&
        <div className={`in-fields-group-item ${errorMsg ? 'in-field-error-active' : ''}`}>
            <div className="in-field-header">{str_fieldTitle}</div>
            <div className="in-field-contents">
                <div className="in-field-top-info">
                    <p></p>
                </div>
                <div className="in-field in-field-bool">
                    <input className={`${isFocused ? 'checkbox-focused' : ''}`}
                        tabIndex={0}
                        id={str_id}
                        type="checkbox"
                        name="bool-field"
                        onChange={handleOnChange}
                        onKeyDown={(e) => handleOnKeyDown(e)}
                        checked={bool}
                        onFocus={() => { setIsFocused(true) }}
                        onBlur={() => { setIsFocused(false) }}
                    />
                    <span className="in-bool-text">
                        <p>
                            {str_boolInfoText}
                        </p>
                    </span>
                </div>
                {
                    b_isRequired &&
                    <div className={`in-field-required ${b_isRequired ? "" : "hidden"}`}>pflichtfeld</div>
                }
                {
                    str_bottomInfoText &&
                    <div className="in-field-bottom-info">
                        <p
                            className={`${shortInfoText ? 'bottom-info-is-expandable' : ''}`}
                            onClick={() => display()}
                        >
                            {isShortTextDisplayed ? shortInfoText : str_bottomInfoText}
                        </p>
                    </div>
                }
            </div>
            {
                errorMsg &&
                <div className="err-msg">
                    <p>{errorMsg}</p>
                </div>
            }
        </div>
    )
}

BoolField.propTypes = {
    str_id: PropTypes.string.isRequired,
    str_fieldTitle: PropTypes.string,
    str_boolInfoText: PropTypes.string,
    str_bottomInfoText: PropTypes.string,
    b_boolValue: PropTypes.bool,
    b_isRequired: PropTypes.bool,
    b_isFieldDisplayed: PropTypes.bool
}

export default BoolField
