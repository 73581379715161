import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../fields/TextField';
import { useInputData } from '../context-provider/InputFormContextProvider';

const ContactPersonFormComponent = ({
    obj_fields,
    b_areFieldsDisplayed=true
}) => {
    const data = useInputData()
    return (
        b_areFieldsDisplayed &&
        <>
            <TextField
                {...obj_fields.firstName}
                str_chars={data[obj_fields.firstName.str_id][0]}
            />
            <TextField
                {...obj_fields.lastName}
                str_chars={data[obj_fields.lastName.str_id][0]}
            />
            <TextField
                {...obj_fields.jobPosition}
                str_chars={data[obj_fields.jobPosition.str_id][0]}
            />
            <TextField
                {...obj_fields.workLocation}
                str_chars={data[obj_fields.workLocation.str_id][0]}
            />
            <TextField
                {...obj_fields.phone}
                str_chars={data[obj_fields.phone.str_id][0]}
            />
            <TextField
                {...obj_fields.email}
                str_chars={data[obj_fields.email.str_id][0]}
            />
            <TextField
                {...obj_fields.linkedin}
                str_chars={data[obj_fields.linkedin.str_id][0]}
            />
        </>
    )
}

ContactPersonFormComponent.propTypes = {
    obj_fields: PropTypes.object,
    b_areFieldsDisplayed: PropTypes.bool
}

export default ContactPersonFormComponent
