import React from 'react';
import { Outlet } from 'react-router-dom';

import PageBase from '../../components/page-layout/PageBase';
import FilterStateContextProvider from '../../components/filters/context-provider/FilterStateContextProvider';
import MessageCacheContextProvider from '../../components/messages/context-provider/MessageCacheContextProvider';

import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const ForumPage = () => {
    useSetBrowserTabTitle('Forum', false, true)
    return (
        <PageBase>
            <FilterStateContextProvider>
                <MessageCacheContextProvider>
                    <Outlet />
                </MessageCacheContextProvider>
            </FilterStateContextProvider>
        </PageBase>
    )
}

export default ForumPage
