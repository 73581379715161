import React from 'react';
import PropTypes from 'prop-types';

const JobPoster = ({ str_filePath }) => {
    return (
        str_filePath &&
        <div className="content">
            <div className="content-header">Jobposter</div>
            <div className="content-data">
                <a href={str_filePath} target="blank" className="file-link">
                    Jobposter
                </a>
            </div>
        </div>
    )
}

JobPoster.propTypes = {
    str_filePath: PropTypes.string
}

export default JobPoster
