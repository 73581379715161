import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { isActiveURLitem } from '../../util/data_handler';
import { MainNavbarData } from './data/MainNavbarData';
import AuthService from '../../util/authentication/auth_service';
import Usertypes from '../../util/Usertypes';

import logo from '../../assets/images/logos/main_navbar_logo.png';

const genProfilePageUrl = () => {
    const user = AuthService.getUser();
    if (!user) return '';
    const usertype = user.usertype;
    if (usertype === Usertypes.company) {
        return `/profile/company/${user.username}/${user.id}`;
    } else if (usertype === Usertypes.gastronomer) {
        return `/profile/gastronomer/${user.username}/${user.id}`;
    } else {
        return `/profile/student/${user.username}/${user.id}`;
    }
}

/* ID that identifies the active main navbar item. */
const ACTIVE_NAV_ITEM_ID = 'main-nav-item-active'

const MainNavbar = ({
    str_userCategory,
    b_hasLogoLink=true
}) => {

    const [isVerticalDisplayOn, setIsVerticalDisplayOn] = useState(false)
    const wasScrollBlockedRef = useRef()
    const entriesRef = useRef([])
    const navigate = useNavigate()
    const [navigateFlag, setNavigateFlag] = useState(false) /* Only used to highlight correct nav item. */

    const str_locationPath = window.location.pathname

    const user = AuthService.getUser()
    const usertype = user.usertype
    const isAboValid = user.isAboValid
    const navItems = isAboValid ?
        MainNavbarData[str_userCategory ? str_userCategory : usertype] :
        MainNavbarData['aboInvalid'][str_userCategory ? str_userCategory : usertype]
    const kint_lenEntries = navItems.length

    useEffect(() => {
        /**
         * Listen to url changes and set a dummy state to rerender the component
         * so that the correct nav item gets highlighted.
         */
        setNavigateFlag(!navigateFlag)
    }, [navigate])

    const navbarAnimation = () => {
        /* If the page scroll is already blocked, this block must not be released
         * when the vertical display of the main nav is removed. */
        if (isVerticalDisplayOn) {
            if (!wasScrollBlockedRef.current) {
                document.body.style.overflowY = ''
            }
            wasScrollBlockedRef.current = undefined
        } else {
            if (document.body.style.overflowY === 'hidden') {
                wasScrollBlockedRef.current = true
            } else {
                wasScrollBlockedRef.current = false
                document.body.style.overflowY = 'hidden'
            }
        }
        
        setIsVerticalDisplayOn(!isVerticalDisplayOn)

        /* Run/remove animation. */
        for (let i = 0; i < kint_lenEntries; i++) {
            if (entriesRef.current[i].style.animation) {
                entriesRef.current[i].style.animation = ''
            } else {
                entriesRef.current[i].style.animation = 
                `navFade 0.5s ease forwards ${i/kint_lenEntries + 0.3}s`;
            }
        }
    }

    const handleItemOnClick = (fct_itemOnClick) => {
        if (isVerticalDisplayOn) navbarAnimation()
        /* Trigger specific behaviour of a navbar item. Those functions are
         * defined in MainNavbarData.js as value of the key 'onClick'. */
        if (fct_itemOnClick) fct_itemOnClick()
    }

    return (
        <div className="main-nav-wrapper">
            <nav id="main-nav-id" className="main-nav">
                {/* Navbar title. */}
                <div className="logo">
                    {
                        b_hasLogoLink
                        ?
                        <Link to={genProfilePageUrl()}>
                            <img src={logo} alt="MUPLAZA Logo" />
                        </Link>
                        :
                        <img src={logo} alt="MUPLAZA Logo" />
                    }
                </div>

                {/* Navbar entries. */}
                <ul className={
                    `nav-links nav-links-right
                    ${isVerticalDisplayOn ? 'nav-active' : ''}`
                }>
                    {
                        navItems.map((entry, index) => (
                            <li 
                                key={index}
                                ref={e => entriesRef.current[index] = e}
                                className="nav-item"
                                id={isActiveURLitem(str_locationPath, entry.name) ? ACTIVE_NAV_ITEM_ID : ''}
                                onClick={() => handleItemOnClick(entry.onClick)}
                            >
                                {/* Call function to create profile page URLs dynmically (see MainNavbarData.js). */}
                                <Link to={typeof(entry.link) === 'string' ? entry.link : entry.link()}>
                                    {entry.symbol ? entry.symbol : entry.title}
                                </Link>
                            </li>        
                        ))
                    }
                </ul>

                {/* Burger for small screens. */}
                <div className={
                    `nav-collapse-burger ${isVerticalDisplayOn ? 'burger-toggle' : ''}`}
                    onClick={() => navbarAnimation()}
                >
                    <div className="line-one"></div>
                    <div className="line-two"></div>
                    <div className="line-three"></div>
                </div>
            </nav>
        </div>
    )
}

MainNavbar.propTypes = {
    str_userCategory: PropTypes.string.isRequired,
    b_hasLogoLink: PropTypes.bool
}

export default MainNavbar
