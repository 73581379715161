import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';

import { useFilterStateContext } from '../filters/context-provider/FilterStateContextProvider';
import { fetchLSdata, setLSdata, sendData2db } from '../../util/db_ls_query_handler';
import { inputForm2dbData } from '../input-forms/util/input_form_data_handler';
import { FilterLsKeys } from '../../util/LocalStorageVariables';
import {
    useGetMessageCache,
    useSetMessageCache
} from '../messages/context-provider/MessageCacheContextProvider';
import {
    useUpdateMsgsContext,
    useGetMsgsContext
} from '../discussion-pages/context-provider/DiscussionCacheContextProvider';
import {
    useGetDataFromFilterCacheContext,
    useUpdateFilterCacheContext
} from './FilterCacheDynamicContentLoadContextProvider';

const QueryDataContext = React.createContext();
const QueriedDataContext = React.createContext();
const SetQueriedDataContext = React.createContext();
const SetFilterDataContext = React.createContext();
const IsAllDataLoadedContext = React.createContext();
const IsFirstFetchSuccessContext = React.createContext();
const IsFirstFilterQueryExecutedContext = React.createContext();
const IsLastFetchSuccessContext = React.createContext();
const InfiniteScrollContext = React.createContext();
const InfiniteScrollErrorLimReachedContext = React.createContext();
const UpdateItem = React.createContext();
const DataExistsContext = React.createContext();
const IsDynContextLoadingContext = React.createContext();
const LoadedFromCacheTriggerContext = React.createContext();

export function useQueryDataContext() {
    return useContext(QueryDataContext);
}

export function useQueriedDataContext() {
    return useContext(QueriedDataContext);
}

export function useSetQueriedDataContext() {
    return useContext(SetQueriedDataContext);
}

export function useSetFilterDataContext() {
    return useContext(SetFilterDataContext);
}

export function useIsAllDataLoadedContext() {
    return useContext(IsAllDataLoadedContext);
}

export function useIsFirstFetchSuccessContext() {
    return useContext(IsFirstFetchSuccessContext);
}

export function useIsFirstFilterQueryExecutedContext() {
    return useContext(IsFirstFilterQueryExecutedContext);
}

export function useIsLastFetchSuccessContext() {
    return useContext(IsLastFetchSuccessContext);
}

export function useInfiniteScrollContext() {
    return useContext(InfiniteScrollContext);
}

export function useInfinteScrollErrorLimReachedContext() {
    return useContext(InfiniteScrollErrorLimReachedContext);
}

export function useUpdateItem() {
    return useContext(UpdateItem);
}

export function useDataExistsContext() {
    return useContext(DataExistsContext);
}

export function useIsDynContextLoading() {
    return useContext(IsDynContextLoadingContext)
}

export function useLoadedFromCacheTriggerContext() {
    return useContext(LoadedFromCacheTriggerContext)
}

const hasFilterKeyValue = (value) => {
    switch(value) {
        case '':
            return false;
        case undefined:
            return false;
        case null:
            return false;
        default:
            if (value.constructor === Array)
                return value[0] !== undefined
            return true;
    }
}

const genUniqueFilterKey = (obj_filterData) => {
    /**
     * Generate a uniquer filter search key for the LS from 
     * the filter values. 
     * :Input
     *  obj_filterData: JSON filter object. 
     * :Returns
     *  Filter key (str): Uniquely identifies the filter value combination. 
     */
    if (!obj_filterData) return FilterLsKeys.noFilter;

    /* Count the #empty filter values to check for no filter applied. */
    let int_numNonEmptyValues = 0;
    let str_filterKey = '';
    let str_nextKey = '';

    Object.values(obj_filterData).forEach((value, index) => {
        /* Filter values are separated by 'F<indexOfFilterValue>' to create unique keys. */
        str_nextKey = 'F' + index;
        if (value === undefined || value === null) /* 'undefined' for NumberField */
            str_nextKey += ''
        else
            str_nextKey += value.constructor === Array ? value.sort().join(',') : value;
        str_filterKey += str_nextKey;
        if (hasFilterKeyValue(value)) int_numNonEmptyValues++;
    });

    if (!int_numNonEmptyValues) return FilterLsKeys.noFilter;
    return str_filterKey;
};

/**
 * This height in px determines when new content is loaded.
 * If the user scrolls down and the distance to the bottom of the last item that
 * is loaded dynamically is < SCROll_DELTA_HEIGHT, new content is loaded.
 * The bigger it is, the earlier content is loaded.
 */
const SCROLL_DELTA_HEIGHT = 500;

/**
 * The index for the first fetch is 0.
 * This allows the DB to carry out a greater than search for indexes.
 * Index of the DB start at 1.
 */
const INIT_QUERY_INDEX = 0

const DynamicContentLoadContextProvider = ({
    str_queryURL,
    nbr_packageSize=20,               /* #data-elements loaded by one DB query. */
    b_loadAllAtOnce=false,            /* Option to load all the data on the first fetch. */
    b_queryOnlyIfFilterExists=false,  /* DB queries are only launched if filter data is not null. */
    b_useFilterCache=false,           /* true if queried data is supposed to be stored in the filter cache. */
    b_useMsgCache=false,              /* Is queried data be stored in msg cache (context provider)? */
    b_useDiscussionMsgCache=false,    /* Is the msg cache for the Discussion component used? */
    b_useLS=false,                    /* Is queried stored in the LS? */
    str_lsKey='',                     /* Key for localStorage. */
    b_hasInfiniteScroll=false,        /* Load content when a specific scroll position is reached. */
    b_hasFetchById=true,              /* Fetch DB data according to id if true; by index otherwise. */
    nbr_avoidRefreshForMin=0.6e6,     /* Prevent refreshing for x min (use LS). 3.6e6 = 1 h (default 10 min). */
    fct_serializer,                   /* Callback to serialize queried data to a specific format. */
    fct_updateItem,                   /* Callback that updates an item of the queried data. */
    children
}) => {
    
    const [queriedData, setQueriedData] = useState([])
    const [filterData, setFilterData] = useState(useFilterStateContext ? inputForm2dbData(useFilterStateContext()) : null)
    const [dataExists, setDataExists] = useState(false) /* Turns to true if at least one data entry exists in the DB. */
    /* Defines the state of the data request, can be used by other components to know if
     * this component is done loading. */
    const [isLoading, setIsLoading] = useState(false)
    /* State that is switched if data is loaded from a cache instead of from the DB.
     * This is used by other components to react to this change (e.g. InputFormContextProvider.js). */
    const [loadedFromCacheTrigger, setLoadedFromCacheTrigger] = useState(false)
    const isFirstFetchSuccessRef = useRef()
    const isLastFetchSuccessRef = useRef()
    const lastIdOrIndexFetchedRef = useRef(INIT_QUERY_INDEX) /* -1 means no fetch has been carried out yet. */
    const isAllDataLoadedRef = useRef(false)
    const infiniteScrollRef = useRef() /* Assigned to content for position extraction (infinite scroll). */
    const cntInfScrollFetchErrorsRef = useRef(0) /* # of consecutively failed inf. scroll fetches. */
    const isInfScrollFetchErrorLimReachedRef = useRef(false) /* Flag to stop fetching with inf. scroll. */
    const isOnmountStateChangeRef = useRef(true)
    /* Ref to check if the first query with a filter was executed. */
    const isFirstFilterQueryExecutedRef = useRef(false)
    /* 0 can be used to trigger az DB query for all records. */
    const packageSize = b_loadAllAtOnce ? INIT_QUERY_INDEX : nbr_packageSize

    /* Filter cache hooks. */
    const updateFilterCache = useUpdateFilterCacheContext()
    const getDataFromFilterCache = useGetDataFromFilterCacheContext()
    /* Message cache hooks. */
    const cachedMsgs = useGetMessageCache()
    const setMsgCache = useSetMessageCache()
    /* Disucssion message cache hook. */
    const updateMsgsOfDiscussion = useUpdateMsgsContext()
    const getMsgsOfDiscussion = useGetMsgsContext()

    useEffect(() => {
        /**
         * Init local storage and add event listener for inf. scroll (if used).
         */
        const initLsState = () => {
            const lsValue = fetchLSdata(str_lsKey)
            if (lsValue) {
                const int_now = new Date().getTime()
                const int_timestamp = lsValue[FilterLsKeys.timestamp]
                /* After the first query when the page loads, only the 'data'
                 * key is present. 'timestamp' is still missing, set it. */
                if (!int_timestamp) {
                    setLSdata(int_now, str_lsKey, FilterLsKeys.timestamp)
                    return
                }
                const int_timeDelta = int_now - int_timestamp
                const int_maxtDelta = nbr_avoidRefreshForMin
                /* Keep current LS value if time delta is < nbr_avoidRefreshForMin, i.e.
                 * the page was visited by the user within nbr_avoidRefreshForMin. */
                if (int_maxtDelta - int_timeDelta > 0) return
            }
            /* Init. new LS value. */
            const obj_initLsValue = {
                timestamp: new Date().getTime(),
                data: null
            }
            setLSdata(obj_initLsValue, str_lsKey)
        }

        if (b_useLS) {
            initLsState()
        } else if (str_lsKey) {
            /* Clear LS value if not used. */
            localStorage.removeItem(str_lsKey)
        }
        
        if (b_hasInfiniteScroll) {
            const handleInfiniteScroll = () => {
                /* Stop calling query function if all data is loaded or too many queries failed. */
                if (isAllDataLoadedRef.current || isInfScrollFetchErrorLimReachedRef.current) return
                /* Break if ref is not yet defined during the onmount procedures. */
                if (!infiniteScrollRef.current) return
                /* Compute bottom y-coordinate difference between window and dyn. component. */
                const int_yWindowBottom = window.scrollY + window.innerHeight
                const int_contentBCR = infiniteScrollRef.current.getBoundingClientRect()
                const int_yContentBottom = int_contentBCR.top + int_contentBCR.height + window.scrollY
                const int_yDelta = int_yContentBottom - int_yWindowBottom
                if (int_yDelta < SCROLL_DELTA_HEIGHT) queryData()
            }
    
            window.addEventListener('scroll', handleInfiniteScroll)
            
            return () => {
                window.removeEventListener('scroll', handleInfiniteScroll)
            }
        }
    }, [])
    
    useEffect(() => {
        /**
         * Update data caches that are used with this component.
         */
        if (b_useLS) {
            setLSdata(genStorageData(), str_lsKey, FilterLsKeys.data, genUniqueFilterKey(filterData))
        }
        else if (b_useMsgCache && setMsgCache) {
            setMsgCache(genStorageData())
        }
        else if (b_useDiscussionMsgCache && updateMsgsOfDiscussion) {
            updateMsgsOfDiscussion(genStorageData())
        }
        else if (b_useFilterCache && updateFilterCache) {
            updateFilterCache(genStorageData(), filterData)
        }
    }, [queriedData])

    const queryData = async (b_initStates=false) => {
        /**
         * Query data from the DB or LS and stores it in the queriedData state.
         * :Input
         *  b_initStates: True if init. states are used, otherwise the value 
         *                of the current states are used.  
         */
        if (!b_initStates && isAllDataLoadedRef.current) return
        
        /* Handle only filter search parameter. */
        if (b_queryOnlyIfFilterExists && !filterData) return
        else if (!isFirstFilterQueryExecutedRef.current) {
            isFirstFilterQueryExecutedRef.current = true
        }

        /* Init. variables. */
        const lastIdOrIndexFetched = b_initStates ? INIT_QUERY_INDEX : lastIdOrIndexFetchedRef.current
        const b_isAllDataLoaded = b_initStates ? false : isAllDataLoadedRef.current
        
        /* Do not query if all data has already been loaded. */
        if (b_isAllDataLoaded) return

        setIsLoading(true)

        /* Query DB. */
        const pl = {
            filter: filterData,
            lastIdOrIndexFetched: lastIdOrIndexFetched,
            packageSize: packageSize
        }
        const queryData = await sendData2db('post', str_queryURL, pl)
        const isQuerySuccess = queryData.isQuerySuccess
        
        /* Update success state of fetches. */

        /* First fetch. */
        if (lastIdOrIndexFetched === INIT_QUERY_INDEX) {
            if (isQuerySuccess) {
                isFirstFetchSuccessRef.current = true
                /* Check at least one data (unfiltered) exists in DB. */
                if (filterData === null && queryData.response.data[0] !== undefined) {
                    setDataExists(true)
                }
            } else {
                isFirstFetchSuccessRef.current = false
            }
        }
        /* Last fetch carried out. */
        isLastFetchSuccessRef.current = isQuerySuccess

        /* Handle query error. */
        if (!isQuerySuccess) {
            /* Do not change any states if no new data was loaded. */

            /* Check query failures of inf. scroll. */
            if (b_hasInfiniteScroll) handleInfScrollFetchError(lastIdOrIndexFetched)
            
            /* Trigger a rerendering of the involved components to display error
             * messages correctly (although the state does not change here). */
            setQueriedData(queriedData => [...queriedData])

            setIsLoading(false)
            return
        }
        
        /* Query is successful, update variables and states. */

        let data = queryData.response.data
        const dataLen = data.length
        
        /**
         * Check if all data has been loaded.
         * If loaded package < package size, all loaded.
         * If loaded package > package size, all loaded (all data loaded in one go)
         */
        isAllDataLoadedRef.current = dataLen !== packageSize ? true : false

        /* Reset error counter upon successful fetch. */
        if (cntInfScrollFetchErrorsRef.current) {
            cntInfScrollFetchErrorsRef.current = 0
        }

        /* Update next query id or index (if dataLen = 0, all the data is already loaded). */
        if (dataLen > 0) {
            if (b_hasFetchById) {
                lastIdOrIndexFetchedRef.current = data[dataLen - 1].id
            } else {
                lastIdOrIndexFetchedRef.current += dataLen
            }
        }
        
        /* Serialize data into a format that is required by the context receiver components. */
        if (fct_serializer) {
            data = fct_serializer(data)
        }

        if (b_initStates) {
            setQueriedData(data)
        } else {
            setQueriedData(queriedData => [...queriedData, ...data])
        }
        
        setIsLoading(false)
    }

    const handleInfScrollFetchError = (lastIdOrIndexFetched) => {
        /**
         * Checks if inf. scroll query fails.
         * If the query fails, the failed query counter is incremented. 
         * If 3 queries failed, a flag is set to avoid new querying. 
         * The user must refresh the page or switch to a different filter 
         * (if filters are used) to leave this state.
         * 
         * For the first fetch the failure is handled by the receiver compoonent.
         * No data is shown. Inf. scroll errors are shown right below the already 
         * queried data. Thus, skip the first fetch error here.
         */
        if (lastIdOrIndexFetched === INIT_QUERY_INDEX) return

        if (++cntInfScrollFetchErrorsRef.current > 2) {
            isInfScrollFetchErrorLimReachedRef.current = true
        }
    }

    /* Set data from storages (message cache, discussion cache or localStorage). */

    const areStatesSetFromFilterCache = () => {
        if (!b_useFilterCache) return false
        const data = getDataFromFilterCache(filterData)
        if (!data) return false
        initWithStoredData(data)
        setLoadedFromCacheTrigger(!loadedFromCacheTrigger)
        return true
    }

    const areStatesSetFromMsgCache = () => {
        if (!b_useMsgCache || !setMsgCache || !cachedMsgs) return false
        initWithStoredData(cachedMsgs)
        /* As cachedMsgs is only set with the non-filtered data, no data means no data exists. */
        if (cachedMsgs[0][0] === undefined) {
            setDataExists(false)
        } else {
            setDataExists(true)
        }
        setLoadedFromCacheTrigger(!loadedFromCacheTrigger)
        return true
    }

    const areStatesSetFromDiscussionMsgCache = () => {
        if (!b_useDiscussionMsgCache || !getMsgsOfDiscussion) return false
        const data = getMsgsOfDiscussion()
        if (!data) return false
        initWithStoredData(data)
        setLoadedFromCacheTrigger(!loadedFromCacheTrigger)
        return true
    }

    const areStatesSetFromLS = (inputFilterData) => {
        if (!b_useLS) return
        const fdata = inputFilterData === undefined ? filterData : inputFilterData
        const str_filterKey = genUniqueFilterKey(fdata)
        const lsData = fetchLSdata(str_lsKey, FilterLsKeys.data, str_filterKey)
        if (!lsData) return false
        initWithStoredData(lsData)
        /* If a filter was applied, data exists as a filter panel is only shown if
         * the first fetch from the DB has at least one data entry. Also, if the
         * filter is 'noFilter', which is the second clause of the order, and data
         * is found, data exists. */
        if (str_filterKey !== FilterLsKeys.noFilter || lsData[0][0] !== undefined) {
            setDataExists(true)
        } else {
            setDataExists(false)
        }
        setLoadedFromCacheTrigger(!loadedFromCacheTrigger)
        return true
    }

    const initWithStoredData = (storedData) => {
        /**
         * Initializes all the states and refs to values if the first fetch is successful.
         */
        setQueriedData(storedData[0])
        lastIdOrIndexFetchedRef.current = storedData[1]
        isAllDataLoadedRef.current = storedData[2]
        /* Fetch from storage is success, thus first and last fetches are successful. */
        isFirstFetchSuccessRef.current = true
        isLastFetchSuccessRef.current = true
        /* If data has been loaded, the first filter query has been executed. */
        isFirstFilterQueryExecutedRef.current = true
        /* As data is newly fetched, the inf. scroll has no errors. */
        cntInfScrollFetchErrorsRef.current = 0
        isInfScrollFetchErrorLimReachedRef.current = false
    }

    const genStorageData = () => {
        /**
         * Returns an array of data that is to be stored.
         */
        return [queriedData, lastIdOrIndexFetchedRef.current, isAllDataLoadedRef.current]
    }

    /* Hooks that handle state setting when the component is mounted or a new url is visited. */

    const resetStates = (hasUrlChanged=false) => {
        /**
         * Resets the states to their init. values.
         * If the url has changes, additional states are reset.
         */
        setQueriedData([])
        isFirstFetchSuccessRef.current = undefined
        isLastFetchSuccessRef.current = undefined
        lastIdOrIndexFetchedRef.current = INIT_QUERY_INDEX
        isAllDataLoadedRef.current = false
        if (b_hasInfiniteScroll) {
            cntInfScrollFetchErrorsRef.current = 0
            isInfScrollFetchErrorLimReachedRef.current = false
        }
        if (hasUrlChanged) {
            isFirstFilterQueryExecutedRef.current = false
        }
    }

    useMemo(() => {
        /**
         * Queries data on URL change. The data is either taken from a react cache,
         * the LS or from the DB (if no cached data could be found).
         */
        if (
            areStatesSetFromMsgCache() ||
            areStatesSetFromDiscussionMsgCache() ||
            areStatesSetFromFilterCache() ||
            areStatesSetFromLS()
        ) return
        resetStates(true)
        if (filterData === null) queryData(true)
        else setFilterData(null) /* Query data by triggering the useMemo [filterData] hook. */
    }, [str_queryURL])

    useMemo(() => {
        /**
         * Whenever the InputFormContextProvider.js submits new filter data, 
         * this function is called and queries data with the provided filter data.
         * 
         * This function is also called on URL changes if a non-null filter was cleared.
         * In this case the useMemo [str_queryURL] hook acts as the trigger.
         */
        if (isOnmountStateChangeRef.current) {
            isOnmountStateChangeRef.current = false
            return
        }
        if (areStatesSetFromFilterCache() || areStatesSetFromLS()) return
        resetStates(false)
        queryData(true)
    }, [filterData])

    /* Functions to update items of the queried data. */

    const updateItem = (id, updateData) => {
        /**
         * Updates an item of the queried elements (state queriedData).
         * :Input
         *  id (nbr): The id of the item that is to be updated.
         *  updateData (any): Type and values must match what the callback
         *      function expects as inputs. updateValues and the callback
         *      do always go hand-in-hand.
         */
        if (fct_updateItem) {
            setQueriedData(
                queriedData.map((item) => {
                    if (item.id === id) return fct_updateItem(item, updateData)
                    return item
                })
            )
        }
    }

    return (
        <QueryDataContext.Provider value={queryData}>
            <QueriedDataContext.Provider value={queriedData}>
                <SetQueriedDataContext.Provider value={setQueriedData}>
                    <SetFilterDataContext.Provider value={setFilterData}>
                        <IsAllDataLoadedContext.Provider value={isAllDataLoadedRef}>
                            <IsFirstFetchSuccessContext.Provider value={isFirstFetchSuccessRef}>
                                <IsFirstFilterQueryExecutedContext.Provider value={isFirstFilterQueryExecutedRef}>
                                    <IsLastFetchSuccessContext.Provider value={isLastFetchSuccessRef}>
                                        <InfiniteScrollContext.Provider value={infiniteScrollRef}>
                                            <InfiniteScrollErrorLimReachedContext.Provider value={isInfScrollFetchErrorLimReachedRef}>
                                                <UpdateItem.Provider value={updateItem}>
                                                    <DataExistsContext.Provider value={dataExists}>
                                                        <IsDynContextLoadingContext.Provider value={isLoading}>
                                                            <LoadedFromCacheTriggerContext.Provider value={loadedFromCacheTrigger}>
                                                                {children}
                                                            </LoadedFromCacheTriggerContext.Provider>
                                                        </IsDynContextLoadingContext.Provider>
                                                    </DataExistsContext.Provider>
                                                </UpdateItem.Provider>
                                            </InfiniteScrollErrorLimReachedContext.Provider>
                                        </InfiniteScrollContext.Provider>
                                    </IsLastFetchSuccessContext.Provider>
                                </IsFirstFilterQueryExecutedContext.Provider>
                            </IsFirstFetchSuccessContext.Provider>
                        </IsAllDataLoadedContext.Provider>
                    </SetFilterDataContext.Provider>
                </SetQueriedDataContext.Provider>
            </QueriedDataContext.Provider>
        </QueryDataContext.Provider>
    )
}

DynamicContentLoadContextProvider.propTypes = {
    str_queryURL: PropTypes.string.isRequired,
    str_lsKey: PropTypes.string,
    nbr_packageSize: PropTypes.number,
    nbr_avoidRefreshForMin: PropTypes.number,
    b_loadAllAtOnce: PropTypes.bool,
    b_queryOnlyIfFilterExists: PropTypes.bool,
    b_useFilterCache: PropTypes.bool,
    b_useMsgCache: PropTypes.bool,
    b_useDiscussionMsgCache: PropTypes.bool,
    b_useLS: PropTypes.bool,
    b_hasFetchById: PropTypes.bool,
    b_hasInfiniteScroll: PropTypes.bool,
    fct_serializer: PropTypes.func,
    fct_updateItem: PropTypes.func
}

export default DynamicContentLoadContextProvider
