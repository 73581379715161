/**
 * This file contains the settings objects for all the profile page data
 * of industry users. This data is used to determine which data is supposed
 * to be displayed in a profile page data group. 
 * It only entails the contents of object (key/value) data. 
 * 
 * Arrays and strings are handled differently:
 * If type of value = string: Value not a link. 
 * If type of value = array:  Value is a link.
 */

const slogan = {
    slogan: ''
};

const consumeOptions = {
    hasLocalConsumption:    'Im Restaurant',
    hasPickupConsumption:   'Abholung',
    hasDeliveryConsumption: 'Lieferung'
};

const specialOffers = {
    specialOffer0: '1. Spezialangebot',
    specialOffer1: '2. Spezialangebot',
    specialOffer2: '3. Spezialangebot',
    specialOffer3: '4. Spezialangebot',
};

const foodMenus = {
    foodMenu0: ['1. Speisekarte'],
    foodMenu1: ['2. Speisekarte'],
    foodMenu2: ['3. Speisekarte'],
    foodMenu3: ['4. Speisekarte']
};

const drinksMenus = {
    drinkMenu0: ['1. Getränkekarte'],
    drinkMenu1: ['2. Getränkekarte'],
    drinkMenu2: ['3. Getränkekarte'],
    drinkMenu3: ['4. Getränkekarte'],
};

const logo = {
    logo: ['']
};

const GastroProfileData = {
    slogan: slogan,
    consumeOptions: consumeOptions,
    specialOffers: specialOffers,
    foodMenus: foodMenus,
    drinksMenus: drinksMenus,
    logo: logo
};

export default GastroProfileData;