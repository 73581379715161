import React, { useState, useRef } from 'react';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import CSecGridBase from '../../content-sections/CSecGridBase';
import CardCompanyAcquisition from '../../cards/company-acquisition/CardCompanyAcquisition';
import Modal from '../../modal/Modal';

import CouponRedeemService from '../../../util/coupons/coupon_redeem_service';

import {
    useQueriedDataContext,
    useSetQueriedDataContext,
    useIsFirstFetchSuccessContext
} from '../../context-provider/DynamicContentLoadContextProvider';
import {
    useIsModalOpenContext,
    useModalObjectId
} from '../../modal/context-provider/ModalContextProvider';
import { gridColsFromNumItems } from '../../../util/data_handler';

const BASE_REDEEM_URL = '/api/industry/company-acquisition-coupon/detail/';
const HEADER = 'Firmenakquise';
const SUB_HEADER = 'Liste der Gutscheine deiner Firmen-Akquisitionen';

const StudentPPCompanyAcquisitionCoupons = () => {
    
    const [modalCoupon, setModalCoupon] = useState()
    const [isRedeemSuccess, setIsRedeemSuccess] = useState()
    const [redeemErrorMsg, setRedeemErrorMsg] = useState('')
    const isLockedRef = useRef(false)
    
    const coupons = useQueriedDataContext()
    const setCoupons = useSetQueriedDataContext()
    const isFirstFetchSuccessRef = useIsFirstFetchSuccessContext()
    
    const isModalOpen = useIsModalOpenContext()
    const modalObjectId = useModalObjectId()
    
    /* If the DB query fails, display only one grid column
     * in order to place the error msg in the page center. */
    const numGridCols = gridColsFromNumItems(
        (isFirstFetchSuccessRef.current && coupons[0] !== undefined) ? 2 : 1
    )
    
    /* Coupon redemption. */

    CouponRedeemService.useSetModalCoupon(modalObjectId, coupons, setModalCoupon)
    CouponRedeemService.useRestoreModalInitState(isModalOpen, setIsRedeemSuccess, setRedeemErrorMsg)
    
    const redeem = (couponId) => {
        CouponRedeemService.redeemCoupon(
            couponId,
            modalCoupon,
            coupons,
            setCoupons,
            setIsRedeemSuccess,
            setRedeemErrorMsg,
            isLockedRef,
            BASE_REDEEM_URL
        )
    }

    return (
        <>
            <CSecGridBase
                str_title={HEADER}
                str_subtitle={SUB_HEADER}
                str_gridClass={`card-grid ${numGridCols}`}
                str_secBaseClasses='pp-mc-section'
                b_isContentLoadedDynamically={true}
            >
                <DataStateCheckWrapper
                    b_hasQueryCheck={true}
                    b_isFirstFetchSuccess={isFirstFetchSuccessRef.current}
                    b_isContentSectionForChildren={true}
                    firstQueryDataEntry={coupons[0]}
                >
                    {
                        coupons.map((coupon, index) => (
                            <CardCompanyAcquisition
                                key={index}
                                str_title={coupon.title}
                                obj_bodyData={coupon.body}
                                obj_footerData={coupon.footer}
                                b_hasModalView={false}
                            />
                        ))
                    }
                </DataStateCheckWrapper>
            </CSecGridBase>
            {
                /* Modal view of a coupon if it is supposed to be redeemed. */
                isModalOpen &&
                <Modal b_isSuccess={isRedeemSuccess}>
                    <CardCompanyAcquisition
                        str_title={modalCoupon ? modalCoupon.title : ''}
                        obj_bodyData={modalCoupon ? modalCoupon.body : ''}
                        obj_footerData={modalCoupon ? modalCoupon.footer : ''}
                        b_hasModalView={true}
                        b_isRedeemSuccess={isRedeemSuccess}
                        str_redeemErrorMsg={redeemErrorMsg}
                        fct_redeemCoupon={redeem}
                    />
                </Modal>
            }
        </>
    )
}

export default StudentPPCompanyAcquisitionCoupons
