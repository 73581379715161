/**
 * This component provides the number of a user's coin budget of the current semester
 * to other components that are part of the store.
 */
import React, { useContext, useEffect, useState } from 'react';

import DataStateCheckWrapper from '../general/DataStateCheckWrapper';
import { fetchDBdata } from '../../util/db_ls_query_handler';
import AuthService from '../../util/authentication/auth_service';
import Usertypes from '../../util/Usertypes';

const NumCoinsContext = React.createContext();
const UpdateNumCoinsContext = React.createContext();

export function useNumCoinsContext() {
    return useContext(NumCoinsContext);
}

export function useUpdateNumCoinsContext() {
    return useContext(UpdateNumCoinsContext);
}

const URL = '/api/profiles/student/num-coins/';
const USERTYPE_STUDENT = Usertypes.student;

const CoinBudgetContextProvider = ({ children }) => {
    
    const [numCoins, setNumCoins] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    const usertype = AuthService.getUsertype()

    useEffect(() => {
        if (usertype !== USERTYPE_STUDENT) return

        const func = async () => {
            const queryData = await fetchDBdata(URL)
            if (queryData.isQuerySuccess) {
                setNumCoins(queryData.response.data.numCoins)
                setIsFirstFetchSuccess(true)
            } else {
                setIsFirstFetchSuccess(false)
                setErrorMsg(queryData.errorMsg)
            }
        }
        func()
    }, [])

    const updateNumCoins = (purchasePrice) => {
        /**
         * Updates the user's number of coins once he buys an item in the store.
         * This function is only called when the purchase was accepted by the DB.
         * :Input
         *  purchasePrice (nbr): Price of the item the user has just purchased.
         */
        if (typeof(purchasePrice) !== 'number' || purchasePrice <= 0) return
        const newNumCoins = numCoins - purchasePrice
        setNumCoins(newNumCoins)
    }
    
    return (
        usertype === USERTYPE_STUDENT
        ?
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            firstQueryDataEntry={-1} /* Placeholder to avoid 'no data' rendering (details handled by children). */
            str_errorMsg={errorMsg}
            b_isContentSection={true}
        >
            <NumCoinsContext.Provider value={numCoins}>
                <UpdateNumCoinsContext.Provider value={updateNumCoins}>
                    {children}
                </UpdateNumCoinsContext.Provider>
            </NumCoinsContext.Provider>
        </DataStateCheckWrapper>
        :
        children
    )
}

export default CoinBudgetContextProvider
