/**
 * This component provides messages that are similar (e.g. are part of the same discussion)
 * with data that is used and also the same for every messages of this array of messages.
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import AuthService from '../../../util/authentication/auth_service';

const StaticFormConfigContext = React.createContext();
const TextIndicatorsContext = React.createContext();
const VisibilityOptionsContext = React.createContext();
const BaseUrlContext = React.createContext();
const HasDarkDesignContext = React.createContext();
const UserContext = React.createContext();
const IsDiscussionMsgContext = React.createContext();

export function useStaticFormConfigContext() {
    return useContext(StaticFormConfigContext);
}

export function useTextIndicatorsContext() {
    return useContext(TextIndicatorsContext);
}

export function useVisibilityOptionsContext() {
    return useContext(VisibilityOptionsContext);
}

export function useBaseUrlsContext() {
    return useContext(BaseUrlContext);
}

export function useHasDarkDesignContext() {
    return useContext(HasDarkDesignContext);
}

export function useUserContext() {
    return useContext(UserContext);
}

export function useIsDiscussionMsgContext() {
    return useContext(IsDiscussionMsgContext);
}

const MessagesContextProvider = ({
    obj_formConfig,           /* Config of message input form. */
    arr_indicators=['all'],   /* Available textarea highlighting tools. */
    str_commentBaseUrl,       /* Url to send new comment message create to. */
    str_editBaseUrl,          /* Url to send message update (edit) to. */
    str_voteBaseUrl,          /* Url to send message votes to. */
    b_isBodyHideable=false,
    b_isCommentable=false,
    b_isEditable=false,
    b_isDiscussionMsg=false,  /* This is e.g. an exam question. */
    b_hasDarkDesign=true,
    children
}) => {
    
    const visibilityOptions = {
        isBodyHideable: b_isBodyHideable,
        isCommentable: b_isCommentable,
        isEditable: b_isEditable
    }
    const baseUrls = {
        commentBaseUrl: str_commentBaseUrl,
        editBaseUrl: str_editBaseUrl,
        voteBaseUrl: str_voteBaseUrl
    }

    /* Provide logged in user as context. */
    const user = AuthService.getUser()
    
    return (
        <div className="msgs-container">
            <StaticFormConfigContext.Provider value={obj_formConfig}>
                <TextIndicatorsContext.Provider value={arr_indicators}>
                    <BaseUrlContext.Provider value={baseUrls}>
                        <VisibilityOptionsContext.Provider value={visibilityOptions}>
                            <HasDarkDesignContext.Provider value={b_hasDarkDesign}>
                                <UserContext.Provider value={user}>
                                    <IsDiscussionMsgContext.Provider value={b_isDiscussionMsg}>
                                        {children}
                                    </IsDiscussionMsgContext.Provider>
                                </UserContext.Provider>
                            </HasDarkDesignContext.Provider>
                        </VisibilityOptionsContext.Provider>
                    </BaseUrlContext.Provider>
                </TextIndicatorsContext.Provider>
            </StaticFormConfigContext.Provider>
        </div>
    )
}

MessagesContextProvider.propTypes = {
    obj_formConfig: PropTypes.object,
    arr_indicators: PropTypes.array,
    str_commentBaseUrl: PropTypes.string,
    str_editBaseUrl: PropTypes.string,
    str_voteBaseUrl: PropTypes.string,
    b_isBodyHideable: PropTypes.bool,
    b_isCommentable: PropTypes.bool,
    b_isEditable: PropTypes.bool,
    b_isDiscussionMsg: PropTypes.bool,
    b_hasDarkDesign: PropTypes.bool
}

export default MessagesContextProvider
