import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import "../../css/stripe.css";

import DataStateCheckWrapper from "../../components/general/DataStateCheckWrapper";
import CheckoutForm from "../../components/input-forms/forms/payment/CheckoutForm";

import Usertypes from "../../util/Usertypes";
import AuthService from "../../util/authentication/auth_service";

import useGetLSorDBdata from "../../custom-hooks/useGetLSorDBdata";
import { genDynUrl } from "../../util/db_ls_query_handler";
import CSecBase from "../../components/content-sections/CSecBase";

/* Make sure to call loadStripe outside of a component’s render to avoid
 * recreating the Stripe object on every render.
 *
 * When react is bundled, the environment variables from the project's roote folder 
 * file .env are available. If working with the combination of localhost:3000 and
 * localhost:8000, the env variables are not available.
 * This is the reason for the double if below.
 */
const STRIPE_PUBLIC_KEY_DEV = 'pk_test_51MHpJUJLDCSlIpMBApvchYEuv4cqnEL5Ty4oCglK0ufnxQQZypCaqYPaRoLirdw1pWQyQGClvHwWgZZ0PntgRcnx001McK3nEl';
const STRIPE_PROMISE = loadStripe(
    process.env.REACT_APP_ENV !== undefined
    ?
        process.env.REACT_APP_ENV === 'dev' ?
        STRIPE_PUBLIC_KEY_DEV :
        process.env.REACT_APP_STRIPE_PUBLIC_KEY_PRODUCTION
    :
    STRIPE_PUBLIC_KEY_DEV
);

const BASE_URL = '/api/payments/create-payment-intent/';

const abonameForSubtitle = (usertype, aboDuration) => {
    /**
     * Creates information about the abo provided the usertype and abo duration.
     * :Retuns
     *  Arrays: [abo title (str), price (int)]
     */
    if (aboDuration !== '6' && aboDuration !== '12') return null;
    
    let aboTitle;
    if (usertype === Usertypes.company) {
        aboTitle = 'Firmenabo';
    } else if (usertype === Usertypes.student) {
        aboTitle = 'Studentenabo';
    } else {
        return null; /* Only company and student abos allowed/possible. */
    }

    return aboTitle;
}

const genAboSubtitle = (usertype, aboPrice, aboDuration) => {
    /**
     * Generates the subtitle on the payment page with information
     * about the abo that is being purchased.
     * :Input
     *  usertype    (str): Retrieved from LS.
     *  aboPrice    (nbr): Price of the abo to be purchased.
     *  aboDuration (str): Number that refers to the abo duration in months.
     * :Returns
     *  Subtitle (str)
     */
    const aboname = abonameForSubtitle(usertype, aboDuration);
    if (aboname === null) return '';
    return `${aboname} für ${aboDuration} Monate um ${aboPrice/100} €`;
}

const AboExtensionPaymentPage = () => {

    const params = useParams()
    const [data, setData] = useState()
    const [options, setOptions] = useState()
    const [subtitle, setSubtitle] = useState('')
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    const usertype = AuthService.getUsertype()

    useGetLSorDBdata(
        genDynUrl(BASE_URL, params.aboDuration),
        setData,
        setIsFirstFetchSuccess,
        setErrorMsg,
        false
    )

    useEffect(() => {
        if (!data) return
        const appearance = { theme: 'flat' }
        const clientSecret = data['clientSecret']
        setOptions(
            {
                clientSecret,
                appearance
            }
        )
        setSubtitle(genAboSubtitle(AuthService.getUsertype(), data['aboPrice'], params.aboDuration))
    }, [data])

    return (
        <CSecBase
            str_title={usertype === Usertypes.student ? 'Pro-Abo Verlängerung' : 'Aboverlängerung'}
            str_subtitle={subtitle}
        >
            <DataStateCheckWrapper
                b_hasQueryCheck={true}    
                b_isFirstFetchSuccess={isFirstFetchSuccess}
                str_errorMsg={errorMsg}
                firstQueryDataEntry={-1} /* Only placeholder (not used). */
            >
                <div className="payment-form-container">
                    {
                        data &&
                        (
                            <Elements options={options} stripe={STRIPE_PROMISE}>
                                <CheckoutForm />
                            </Elements>
                        )
                    }
                </div>
            </DataStateCheckWrapper>
        </CSecBase>
    );
}

export default AboExtensionPaymentPage
