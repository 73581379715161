import React, { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';

import FieldWrapper from './FieldWrapper';
import InFieldConfirmationValue from '../InFieldConfirmationValue';

import {
    isRequiredFulfilled,
    isEmailFormatValid,
    isPhoneNumberFormatValid,
    isPasswordFormatValid
} from '../util/input_checks';
import { cleanTextInput, cleanPhoneInput } from '../util/input_form_data_handler';
import { 
    useHandleInputChange,
    useAreRequiredErrorsActive,
    useHandleDiscardSubmitFct
} from '../context-provider/InputFormContextProvider';
import {
    useTextFieldRef,
    useMarkTextViaShortcuts
} from '../../post-panels/context-provider/PanelContextProvider';
import { useIsConfirmationViewOpenContext } from '../context-provider/FormBaseContextProvider';
import FieldErrorMsgs from '../util/input_form_fields_error_msgs.json';
import { karr_shortcutPanelSwitchKeys } from '../../post-panels/util/PanelData';


const REQUIRED_EMSG = FieldErrorMsgs.required;
const MIN_LEN_EMSG = FieldErrorMsgs.text.length.min;
const MAX_LEN_EMSG = FieldErrorMsgs.text.length.max;
const FORMAT_EMSG = FieldErrorMsgs.text.format
const LINK_EMSG = FieldErrorMsgs.link;
const EMAIL_EMSG = FieldErrorMsgs.email;
const PHONE_EMSG = FieldErrorMsgs.phone;
const PW_FORMAT_EMSG = FieldErrorMsgs.password.format;
const PW_CONFIRM_EMSG = FieldErrorMsgs.password.confirmNotEqual;

const TOP_INFO_TEXT = 'Ein Link muss mit \'http\' oder \'https\' beginnen, um korrekt ' + 
    'erkannt zu werden. Der Link kann z.B. in der Suchleiste des Browsers durch ' +
    'Anklicken ausgewählt werden. Damit erhält man automatisch die Variante mit ' +
    '\'http\' oder \'https\' am Anfang.';

const TextField = ({
    str_id,
    str_fieldTitle='',
    str_chars='',
    str_placeholder='',        /* For the <input> itself. */
    str_bottomInfoText='',
    b_hasNumCharsDisplay=true, /* Show the current #chars of the input below the field. */
    b_isTextarea=false,        /* Determines <input> or <textarea>. */
    b_isLink=false,            /* If the field contains only a link (e.g. to LinkedIn). */
    b_isEmail=false,           /* If the field contains an email address. */
    b_isPhone=false,           /* If the field contains a phone number. */
    b_isPassword=false,
    b_hasPasswordDisplay=false, /* If true, the field input is hidden (this is only to obfuscate the input, not error check apply). */
    b_isPwConfirmation=false,   /* Only for password fields (true if pw field is confirmation field). */
    b_isPwErrorDisplayed=true,  /* Switch pw error on/off. E.g. for login panel it is off. */
    b_isRequired=false,
    int_minLength,             /* E.g. necessary for filter keyqword search. */
    int_maxLength,             /* Always necessary, do not allow random number of chars. */
    fct_isInputFormatValid,          /* Checks the format of the input string. */
    b_hasPwConfirmEqualError='',     /* Only rel. for password fields (true if pw != confirm). */
    fct_isPwConfirmEqual=null,       /* Only rel. for password fields (pw, confirmation check). */
    fct_pwData2ContextProvider=null  /* Only rel. for password fields (pw, confirmation check). */
}) => {
    
    const [chars, setChars] = useState(str_chars)
    const [currentNumChars, setCurrentNumChars] = useState(str_chars.length)
    const [isCurrentNumCharsActive, setIsCurrentNumCharsActive] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [isFocused, setIsFocused] = useState(false)
    const charCountRef = useRef()
    const minNumCharsRef = useRef(int_minLength ? int_minLength.toString() + '/' : '')
    const maxNumCharsRef = useRef('/' + int_maxLength.toString())
    const isSubmitWithEnterRef = useRef(false)
    const isFocusedRef = useRef(false)
    /* Only rel. for password fields. State to keep track of errors of this field. */
    const hasErrorRef = useRef(false)

    /* Hooks to report input data to the InputFormContextProvider. */
    const handleInputChange = useHandleInputChange()
    const isRequiredErrorActivate = useAreRequiredErrorsActive()
    const handleDiscardSubmit = useHandleDiscardSubmitFct()
    /* Hook to switch between the two views of the input form. */
    const isConfirmationViewOpen = useIsConfirmationViewOpenContext()
    /* Hook to track shortucts for input panels. */
    const markTextViaShortcuts = useMarkTextViaShortcuts()
    const textFieldRef = useTextFieldRef()

    const hasError = () => {
        const int_charsLength = chars.length
        if (b_isRequired && !isRequiredFulfilled(chars)) {
            hasErrorRef.current = true
            setErrorMsg(REQUIRED_EMSG)
            return true
        }
        else if (int_minLength && int_charsLength && int_charsLength < int_minLength) {
            setErrorMsg(MIN_LEN_EMSG)
            return true
        }
        else if (int_maxLength && int_charsLength > int_maxLength) {
            setErrorMsg(MAX_LEN_EMSG)
            return true
        }
        else if (fct_isInputFormatValid && !fct_isInputFormatValid(chars)) {
            setErrorMsg(FORMAT_EMSG)
            return true
        }
        else if (b_isLink && int_charsLength > 0 && chars.indexOf('http') !== 0) {
            setErrorMsg(LINK_EMSG)
            return true
        }
        else if (b_isEmail && int_charsLength > 0 && !isEmailFormatValid(chars)) {
            setErrorMsg(EMAIL_EMSG)
            return true
        }
        else if (b_isPhone && int_charsLength > 0 && !isPhoneNumberFormatValid(chars)) {
            setErrorMsg(PHONE_EMSG)
            return true
        }
        else if (b_isPassword && b_isPwErrorDisplayed) {
            if (!isPasswordFormatValid(chars)) {
                setErrorMsg(PW_FORMAT_EMSG)
                return true
            }
        }
        setErrorMsg('')
        return false
    }

    useMemo(() => {
        /* All the required fields that are blank and do not show erros,
         * are activated when a form is submitted. The line below is only 
         * executed once. After the first execution, the required field 
         * displays error messages until the input is correct. */
        if (isRequiredErrorActivate && b_isRequired) hasError()
    }, [isRequiredErrorActivate])
    
    useMemo(() => {
        /* If new input is received, update the current char state. */
        setChars(str_chars)
        setCurrentNumChars(str_chars.length)
    }, [str_chars])

    const checkPwConfirmEquality = () => {
        /* Only rel. for password fields. The confirmation error is provided 
         * by the parent element that compares the pw against the confirmation. */
        if (!b_isPassword || (!errorMsg && !chars && b_isRequired)) return
        if (b_isPwConfirmation && b_hasPwConfirmEqualError) {
            /* Keep this if in the body, otherwise react has unlimted rerenders ...
             * I do not know why ... */
            if (!errorMsg) setErrorMsg(PW_CONFIRM_EMSG)
        } else {
            if (hasErrorRef.current) return
            if (errorMsg !== '') {
                setErrorMsg('')
            }
        }
    }
    checkPwConfirmEquality()

    const handleFormSubmit = (e) => {
        /* This is only used for <input> and not for <textarea>. */
        if (e.key === 'Enter') {
            if (fct_pwData2ContextProvider) {
                fct_pwData2ContextProvider({
                    isPwConfirmation: b_isPwConfirmation,
                    value: chars,
                    hasError: hasError()
                })
            } else {
                handleInputChange({id: str_id, value: chars, hasError: hasError()})
            }
            /* Set flag for useMemo rerender to exec. a handleDiscardSubmit(). */
            isSubmitWithEnterRef.current = true
        }
    }

    const handleKeyDown = (e) => {
        /* Keydown short-cuts are not rel. for password fields. */
        if (fct_isPwConfirmEqual) return

        /* Send chars onBlur and on ctrlKey (for input panel) down. 
         * Sending it onChange makes the input panel super slow! Avoid this. */
        if (e.ctrlKey && markTextViaShortcuts) markTextViaShortcuts(e)
        /* Whenever the user types a specific key */
        if (e.ctrlKey && karr_shortcutPanelSwitchKeys.includes(e.key))
            handleInputChange({id: str_id, value: chars, hasError: hasError()})
    }

    const onFocus = (e) => {
        isFocusedRef.current = true
        /* Display the char count and update the data.
         * The data length can be different onFocus from the previous
         * onBlur if text indicators (post panel) are introduced. */
        if (b_hasNumCharsDisplay) {
            setCurrentNumChars(e.target.value.length)
            setIsCurrentNumCharsActive(true)
        }
        setIsFocused(true)
    }
    
    const onBlur = (e) => {        
        /* Send chars onBlur and on ctrlKey down. Sending it onChange
         * makes the input panel super slow! Avoid this. */
       if (fct_pwData2ContextProvider) {
           fct_pwData2ContextProvider({
               isPwConfirmation: b_isPwConfirmation,
               value: chars,
               hasError: hasError()
            })
        } else {
            /* Clean the input string. */
            const str_chars = e.target.value
            let str_charsCleaned = b_isPhone ? cleanPhoneInput(str_chars) : cleanTextInput(str_chars)
            /* Links and emails are lowercase. */
            if (b_isLink || b_isEmail) {
                str_charsCleaned = str_charsCleaned.toLowerCase()
            }
            setChars(str_charsCleaned)
            handleInputChange({
                id: str_id,
                value: str_charsCleaned,
                hasError: hasError()
            })
        }
        setIsFocused(false)      
    }

    const onChange = (e) => {
        const str_inputValue = e.target.value
        const int_numChars = str_inputValue.length
        /* Check errors while user is typing. */
        if (str_inputValue) {
            if (int_minLength && int_numChars < int_minLength) {
                hasErrorRef.current = true
                setErrorMsg(MIN_LEN_EMSG)
            } else if (
                b_isPassword && b_isPwErrorDisplayed && !isPasswordFormatValid(str_inputValue)
            ) {
                hasErrorRef.current = true
                setErrorMsg(PW_FORMAT_EMSG)
            } else if (int_maxLength && int_numChars > int_maxLength) {
                hasErrorRef.current = true
                setErrorMsg(MAX_LEN_EMSG)
            } else if (fct_isInputFormatValid && !fct_isInputFormatValid(str_inputValue)) {
                hasErrorRef.current = true
                setErrorMsg(FORMAT_EMSG)
            } else {
                hasErrorRef.current = false
                if (fct_isPwConfirmEqual) {
                    fct_isPwConfirmEqual(str_inputValue, b_isPwConfirmation)
                } else {
                    setErrorMsg('')
                }
            }
        } else {
            if (b_isRequired) setErrorMsg(REQUIRED_EMSG)
            else setErrorMsg('')
        }
        setChars(str_inputValue)
        setCurrentNumChars(int_numChars)
    }

    const checkEnterSubmit = () => {
        /* Submit the context if the user hit enter. */
        if (isSubmitWithEnterRef.current) {
            handleDiscardSubmit(true, null)
            isSubmitWithEnterRef.current = false
        }
    }
    checkEnterSubmit()

    return (
        isConfirmationViewOpen
        ?
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            b_isRequired={false}
        >
            <InFieldConfirmationValue value={chars} />
        </FieldWrapper>
        :
        <FieldWrapper
            str_fieldTitle={str_fieldTitle}
            str_errorMsg={errorMsg}
            str_topInfoText={b_isLink ? TOP_INFO_TEXT : ''}
            str_bottomInfoText={str_bottomInfoText}
            b_isRequired={b_isRequired}
            b_isFieldFocused={isFocused}
        >
            <div className="in-field in-field-char">
                {
                    b_isTextarea ? (
                        <div className="textarea-wrap">
                            <textarea
                                ref={textFieldRef}
                                name="textField"
                                minLength={int_minLength}
                                maxLength={int_maxLength}
                                required={b_isRequired}
                                id={str_id}
                                value={chars}
                                onFocus={onFocus}
                                onBlur={onBlur}
                                onChange={onChange}
                                onKeyDown={handleKeyDown}
                                placeholder={str_placeholder}
                            />
                        </div>
                    ) : (
                        <input
                            type={(b_isPassword || b_hasPasswordDisplay) ? 'password' : 'text'}
                            name="char-field"
                            minLength={int_minLength}
                            maxLength={int_maxLength}
                            required={b_isRequired}
                            id={str_id}
                            value={chars}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onChange={onChange}
                            onKeyDown={handleFormSubmit}
                            placeholder={str_placeholder}
                        />
                    )
                }
                <div
                    ref={charCountRef}
                    className={`in-field-char-count ${isCurrentNumCharsActive ? "" : "hidden"}`}
                >
                    {
                        int_minLength
                        ?
                        <>
                            {minNumCharsRef.current}{currentNumChars}{maxNumCharsRef.current}
                        </>
                        :
                        <>
                            {currentNumChars}{maxNumCharsRef.current}
                        </>
                    }
                </div>
                {
                    b_isLink && chars && !errorMsg &&
                    <div className="in-field-link-preview">
                        <div className="in-field-link-header">Teste deinen Link</div>
                        <div>
                            <a
                                href={chars}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="page-link"
                            >
                                {chars}
                            </a>
                        </div>
                    </div>
                }
            </div>
        </FieldWrapper>
    )
}

TextField.propTypes = {
    str_id: PropTypes.string.isRequired,
    str_fieldTitle: PropTypes.string,
    str_chars: PropTypes.string,
    str_placeholder: PropTypes.string,
    str_bottomInfoText: PropTypes.string,
    b_hasNumCharsDisplay: PropTypes.bool,
    b_isTextarea: PropTypes.bool,
    b_isLink: PropTypes.bool,
    b_isEmail: PropTypes.bool,
    b_isPhone: PropTypes.bool,
    b_isPassword: PropTypes.bool,
    b_hasPasswordDisplay: PropTypes.bool,
    b_isPwConfirmation: PropTypes.bool,
    b_isPwErrorDisplayed: PropTypes.bool,
    b_isRequired: PropTypes.bool,
    int_minLength: PropTypes.number,
    int_maxLength: PropTypes.number.isRequired,
    fct_isInputFormatValid: PropTypes.func,
    b_hasPwConfirmEqualError: PropTypes.bool,
    fct_isPwConfirmEqual: PropTypes.func,
    fct_pwData2ContextProvider: PropTypes.func
}

export default TextField
