import React, { useState } from 'react';

import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import PPMainContentBase from '../PPMainContentBase';
import TbStudentPPIndustryJobs from '../../tables/industry/jobs/TbStudentPPIndustryJobs';

import useGetLSorDBdata from '../../../custom-hooks/useGetLSorDBdata';
import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import { PPLsKeys, StudentLsKeys } from '../../../util/LocalStorageVariables';

const PR_LS_KEY = PPLsKeys.primaryDataKey;
const SEC_LS_KEY = StudentLsKeys.pp.jobs;

const URL = '/api/profiles/student/profile-page/jobs/';
const NO_DATA_FOUND_MSG = 'Im Moment sind keine Stellen an der Pinnwand angeheftet.';

const StudentPPJobs = () => {

    const [data, setData] = useState([])
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState(undefined)
    const [errorMsg, setErrorMsg] = useState('')

    useSetBrowserTabTitle('Jobs', true)

    useGetLSorDBdata(
        URL,
        setData,
        setIsFirstFetchSuccess,
        setErrorMsg,
        true,
        null,
        null,
        PR_LS_KEY,
        SEC_LS_KEY
    )
    
    const removeJobFromPinwall = (id) => {
        setData(data.filter(item => item.id !== id))
    }

    return (
        <PPMainContentBase
            str_header='Fixierte Jobs'
            str_subHeader='Pinnwand deiner fixierten Jobs'
        >
            <DataStateCheckWrapper
                b_hasQueryCheck={true}
                b_isFirstFetchSuccess={isFirstFetchSuccess}
                str_errorMsg={errorMsg}
                str_noDataMsg={NO_DATA_FOUND_MSG}
                firstQueryDataEntry={data[0]}
            >
                <TbStudentPPIndustryJobs
                    arr_data={data}
                    fct_deleteEntryFromState={removeJobFromPinwall}
                />
            </DataStateCheckWrapper>
        </PPMainContentBase>
    )
}

export default StudentPPJobs
