import React, { useEffect, useState } from 'react';

import {
    useQueryDataContext,
    useQueriedDataContext,
    useIsAllDataLoadedContext,
    useIsFirstFetchSuccessContext,
    useIsLastFetchSuccessContext
} from '../context-provider/DynamicContentLoadContextProvider';
import { QueryVariables } from '../../util/QueryVariables';

const DynamicContentLoadBtn = () => {

    const [isLoading, setIsLoading] = useState(false)

    const queryData = useQueryDataContext()
    const queriedData = useQueriedDataContext()
    const isAllDataLoadedRef = useIsAllDataLoadedContext()
    const isFirstFetchSuccessRef = useIsFirstFetchSuccessContext()
    const isLastFetchSuccessRef = useIsLastFetchSuccessContext()

    useEffect(() => {
        /* When new content is loaded, the queryData is set and thus changes. */
        setIsLoading(false)
    }, [queriedData])

    const handleOnClick = () => {
        setIsLoading(true)
        queryData()
    }

    return (
        <div className="dyn-content-load-msg">
            {
                isFirstFetchSuccessRef.current
                ?
                    isLastFetchSuccessRef.current
                    ?
                        isAllDataLoadedRef.current
                        ?
                            queriedData[0] && /* If no data in DB, dyn. content displays info msg. */
                            <p>{QueryVariables.str_allDataLoadedMsg}</p>
                        :
                        isLoading
                        ?
                            <p>{QueryVariables.str_loadingMsg}</p>
                        :
                        <button
                            className="btn btn-sm btn-dyn-load"
                            onClick={handleOnClick}
                        >
                            Mehr
                        </button>
                    :
                    <>
                        <p>
                            Die Daten konnten nicht erfolgreich geladen werden.
                            <br />
                            Bitte klicke den Button und versuche es noch einmal.
                            Sollte es erneut nicht klappen, versuche es bitte später erneut.
                        </p>
                        <button
                            className="btn btn-sm btn-dyn-load"
                            onClick={handleOnClick}
                        >
                            Mehr
                        </button>
                    </>
                :
                '' /* Do not show info if first fetch fails. Other comps handle message. */
            }
        </div>
    )
}

export default DynamicContentLoadBtn
