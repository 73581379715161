/**
 * This component stores the discussion message and the messages of the
 * discussion in a state each. This allows the Discussion.js component to
 * load the data from these states when the user goes back to a specific
 * discussion that had its content loaded already.
 * This means that switching, e.g. between different exam questions, loads
 * the data from this context provider and does not make a network request.
 * 
 * Both states are JS objects. The keys of these objects are the task ids.
 * A task id is, for instance, the id of an exam or exercise question.
 * The states are split so that when one e.g. the messages are updated,
 * the discussion msgs are left unchanged and vice-versa.
 * 
 * The discussionMsgs state has an object as value, the data of this object
 * represents all the data of the msg (e.g. of an exam question variant).
 * 
 * The msgs state has an array of array as values. The array items have
 * 3 items each, these are according to the data stored by the 
 * DynamicContentLoadContextProvider.js component:
 * [data (arr), id of last fetched item (int), is all data loaded (bool)]
 */
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types';

const GetDiscussionMsgContext = React.createContext();
const GetMsgsContext = React.createContext();
const UpdateDiscussionMsgsContext = React.createContext();
const UpdateMsgsContext = React.createContext();
const UpdateDiscussionMsgVotesContext = React.createContext();
const ClearCacheContext = React.createContext();

export function useGetDiscussionMsgContext() {
    return useContext(GetDiscussionMsgContext);
}

export function useGetMsgsContext() {
    return useContext(GetMsgsContext);
}

export function useUpdateDiscussionMsgsContext() {
    return useContext(UpdateDiscussionMsgsContext);
}

export function useUpdateMsgsContext() {
    return useContext(UpdateMsgsContext);
}

export function useUpdateDiscussionMsgVotesContext() {
    return useContext(UpdateDiscussionMsgVotesContext);
}

export function useClearCachContextProvider() {
    return useContext(ClearCacheContext);
}

const DiscussionCacheContextProvider = ({
    str_pageTaskId, /* Task the current URL refers to (extracted from URL param). */
    children
}) => {

    const [msgs, setMsgs] = useState({})
    const [discussionMsgs, setDiscussionMsgs] = useState({})

    const getDiscussionMsg = (taskId=null) => {
        taskId = taskId === null ? str_pageTaskId : taskId
        return discussionMsgs[taskId]
    }

    const getMsgs = (taskId=null) => {
        taskId = taskId === null ? str_pageTaskId : taskId
        return msgs[taskId]
    }

    const updateDiscussionMsgs = (discussionMsg, taskId=null) => {
        taskId = taskId === null ? str_pageTaskId : taskId
        let newDmsgs = {...discussionMsgs}
        newDmsgs[taskId] = discussionMsg
        setDiscussionMsgs(newDmsgs)
    }
    
    const updateTaskMsgs = (taskMsgs, taskId=null) => {
        taskId = taskId === null ? str_pageTaskId : taskId
        let newMsgs = {...msgs}
        newMsgs[taskId] = taskMsgs
        setMsgs(newMsgs)
    }

    const updateDiscussionMsgVotes = (msgId, deltaUp, deltaDown, userVoted) => {
        /**
         * Update the vote data of a discussion topic message.
         * :Input
         *  msgId     (int): Id of the discussion message.
         *  deltaUp   (int): Delta to add to the current number of upvotes.
         *  deltaDown (int): Delta to add to the current number of downvotes.
         *  userVoted (bool or null): true: voted up, false: voted down, null: not voted
         */
        let updateMsg = discussionMsgs[msgId]
        if (!updateMsg) return
        
        updateMsg.numUpvotes = updateMsg.numUpvotes + deltaUp
        updateMsg.numDownvotes = updateMsg.numDownvotes + deltaDown
        updateMsg.userVoted = userVoted

        let updatedDiscussionMsgs = {...discussionMsgs}
        updatedDiscussionMsgs[msgId] = updateMsg
        setDiscussionMsgs(updatedDiscussionMsgs)
    }

    const clearCache = () => {
        setMsgs({})
        setDiscussionMsgs({})
    }

    return (
        <GetDiscussionMsgContext.Provider value={getDiscussionMsg}>
            <GetMsgsContext.Provider value={getMsgs}>
                <UpdateDiscussionMsgsContext.Provider value={updateDiscussionMsgs}>
                    <UpdateMsgsContext.Provider value={updateTaskMsgs}>
                        <UpdateDiscussionMsgVotesContext.Provider value={updateDiscussionMsgVotes}>
                            <ClearCacheContext.Provider value={clearCache}>
                                {children}
                            </ClearCacheContext.Provider>
                        </UpdateDiscussionMsgVotesContext.Provider>
                    </UpdateMsgsContext.Provider>
                </UpdateDiscussionMsgsContext.Provider>
            </GetMsgsContext.Provider>
        </GetDiscussionMsgContext.Provider>
    )
}

DiscussionCacheContextProvider.propTypes = {
    str_pageTaskId: PropTypes.string
}

export default DiscussionCacheContextProvider
