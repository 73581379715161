import React from 'react'
import PropTypes from 'prop-types'

const JobBulletPoints = ({
    str_title,
    arr_data,
    str_class='' /* If you need more flexiblity with styling, add a class. */
}) => {
    return (
        arr_data && arr_data[0] !== undefined &&
        <div className={`content ${str_class}`}>
            {
                str_title &&
                <div className="content-header">{str_title}</div>
            }
            <div className="content-data">
                <ul>
                    {
                        arr_data.map((item, index) => (
                            <li key={index}>
                                <p>{item}</p>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

JobBulletPoints.propTypes = {
    str_title: PropTypes.string,
    arr_data: PropTypes.array,
    str_class: PropTypes.string
}

export default JobBulletPoints
