import React from 'react';
import { Link } from 'react-router-dom';
import { TbMessageCircle } from 'react-icons/tb';

import Votes from '../votes/Votes';

import {
    useVisibilityOptionsContext,
    useBaseUrlsContext
} from './context-provider/MessagesContextProvider';
import {
    useIsMsgBodyOpenContext,
    useIsEditFormOpenContext,
    useIsCommentFormOpenContext,
    useSetIsEditFormOpenContext,
    useSetIsCommentFormOpenContext,
    useSetIsMsgBodyOpenContext,
    useMsgContext,
    useIsMsgOwnerContext,
    useIsCommentContext
} from './context-provider/MessageContextProvider';
import { genDynUrl } from '../../util/db_ls_query_handler';
import { string2URLstring } from '../../util/data_handler';
import VoteService from '../../util/voting/vote_service';

const MessageStdFooter = () => {

    const isBodyOpen = useIsMsgBodyOpenContext()
    const isEditFormOpen = useIsEditFormOpenContext()
    const isCommentFormOpen = useIsCommentFormOpenContext()
    const setIsEditFormOpen = useSetIsEditFormOpenContext()
    const setIsCommentFormOpen = useSetIsCommentFormOpenContext()
    const setIsBodyOpen = useSetIsMsgBodyOpenContext()
    const visibilityOptions = useVisibilityOptionsContext()
    const msg = useMsgContext()
    const isOwner = useIsMsgOwnerContext()
    const baseUrls = useBaseUrlsContext()
    const isComment = useIsCommentContext()

    const areVotesShown = !visibilityOptions.isBodyHideable
    const hasHiddenContent = visibilityOptions.isBodyHideable
    const voteBaseUrl = baseUrls.voteBaseUrl

    const voteUrl = genDynUrl(voteBaseUrl, msg.id)
    const voteProps = VoteService.voteSerializer(msg, voteUrl)

    const genThreadUrl = () => {
        /* Create abs. or rel. URL for the thread of this message. */
        if (msg.courseId === undefined)
            return `thread/${msg.id}`
        return `/courses/${string2URLstring(msg.courseTitle)}/${msg.courseId}/forum/thread/${msg.id}`
    }

    return (
        <div className="msg-footer">
            <div className="vote-edit-comment">
                {
                    /* Votes. */
                    voteUrl && (areVotesShown || isBodyOpen) &&
                    <div className="vote-info">
                        <div className="vote-text">vote</div>
                        <Votes {...voteProps} />
                    </div>
                }
                
                {
                    /* Edit/comment element. */
                    isOwner
                    ?
                    visibilityOptions.isEditable &&
                    !isEditFormOpen &&
                    isBodyOpen &&
                    <div
                        className="edit"
                        onClick={() => setIsEditFormOpen(!isEditFormOpen)}
                    >
                        bearbeiten
                    </div>
                    :
                    visibilityOptions.isCommentable &&
                    !isCommentFormOpen &&
                    isBodyOpen &&
                    !isComment && /* Comments cannot be commented. */
                    <div
                        className="comment"
                        onClick={() => setIsCommentFormOpen(!isCommentFormOpen)}
                    >
                        kommentieren
                    </div>
                }

                {
                    /* Time to live (ttl). */
                    msg.ttl &&
                    <div className='ttl'>
                        <span>Noch </span>
                        <span className='ttl-time'>{msg.ttl} Tage </span>
                        <span>online</span>
                    </div>
                }

                {
                    /* Number of thread messages of a forum message. */
                    msg.numThreadMsgs !== undefined &&
                    <div className="thread-info">
                        <div><TbMessageCircle /></div>
                        <div className="num-msgs">{msg.numThreadMsgs}</div>
                        <div>
                            <Link
                                to={genThreadUrl()}
                                className="page-link"
                            >
                                Thread
                            </Link>
                        </div>
                    </div>
                }
            </div>
            {
                /* Button to hide/show the message content. */
                hasHiddenContent && !isComment &&
                <div className="hide-show">
                    <button
                        className="btn btn-sm btn-show-hide"
                        onClick={() => setIsBodyOpen(!isBodyOpen)}
                    >
                        {isBodyOpen ? 'verbergen' : 'anzeigen'}
                    </button>
                </div>
            }
        </div>
    )
}

export default MessageStdFooter
