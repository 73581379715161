import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { TbTruckDelivery } from 'react-icons/tb';
import { MdLocationOn } from 'react-icons/md';
import { BsBoxSeam } from 'react-icons/bs';

import PrivatePage from '../../../components/network/PrivatePage'
import DataStateCheckWrapper from '../../../components/general/DataStateCheckWrapper'
import PageBase from '../../../components/page-layout/PageBase'
import CSecTextOrImage from '../../../components/content-sections/CSecTextOrImage'
import CSecParagraphs from '../../../components/content-sections/CSecPragraphs'
import CSecBase from '../../../components/content-sections/CSecBase'
import CSecPageUpdateTimeInfo from '../../../components/content-sections/CSecPageUpdateTimeInfo';
import WordGrid from '../../../components/grids/WordGrid'
import IconGrid from '../../../components/grids/IconGrid'
import CardGridInfoList from '../../../components/cards/CardGridInfoList'

import Usertypes from '../../../util/Usertypes'
import ObjectSerializer from '../../../util/serializers/ObjectSerializer';

import { fetchDBdata } from '../../../util/db_ls_query_handler';

const ICON_GRID_DATA = {
    Lokal: {
        icon: <MdLocationOn />,
        class: 'icon-gastro-local-consume',
        url: ''
    },
    Abholung: {
        icon: <BsBoxSeam />,
        class: 'icon-gastro-pickup-consume',
        url: ''
    },
    Lieferung: {
        icon: <TbTruckDelivery />,
        class: 'icon-gastro-delivery-consume',
        url: ''
    }
};

const SERIALIZER_LAYOUT = {
    gastronomerName: 'plain',
    logo: 'plain',
    slogan: 'array',
    consumeOptions: [
        ['Lokal', 'hasLocalConsumption'],
        ['Abholung', 'hasPickupConsumption'],
        ['Lieferung', 'hasDeliveryConsumption']
    ],
    specialOffer: 4,
    drinkMenu: 4,
    foodMenu: 4
};

const SERIALIZER_LAYOUT_COMPANY_CONTACT = [
    ['Adresse', 'location'],
    ['Telefon', 'phone'],
    ['Email', 'email'],
    ['Webseite', 'webpage', 'Zur Seite']
];

const SERIALIZER_LAYOUT_CONTACT_PERSON = [
    ['Name', 'name'],
    ['Telefon', 'phone'],
    ['Email', 'email'],
    ['Jobposition', 'jobPosition'],
];

const BASE_URL = '/api/profiles/gastronomer/detail-page/';

const genUrl = (id) => {
    return BASE_URL + id + '/';
}

const serializeConsumeOptions = (consumeOptions) => {
    /**
     * Combines the standard serialized data from ObjectSerializer.js with
     * the data stored in ICON_GRID_DATA.
     */
    return consumeOptions.map(itemObj => {
        const iconDataKey = itemObj.title;
        itemObj = {...itemObj, ...ICON_GRID_DATA[iconDataKey]};
        return itemObj;
    });
}

const GastroProfileDetailPage = () => {

    const [genData, setGenData] = useState()
    const [contactData, setContactData] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    const params = useParams()

    useEffect(() => {
        const func = async () => {
            const queryData = await fetchDBdata(genUrl(params.userId))
            if (queryData.isQuerySuccess) {
                const data = queryData.response.data
                const genData = ObjectSerializer.serialize(data, SERIALIZER_LAYOUT)
                genData.consumeOptions = serializeConsumeOptions(genData.consumeOptions)
                const contact = ObjectSerializer.serializeContact(data, SERIALIZER_LAYOUT_COMPANY_CONTACT)
                const contactPerson0 = ObjectSerializer.serializeContact(data.contactPersons[0], SERIALIZER_LAYOUT_CONTACT_PERSON)
                const contactPerson1 = ObjectSerializer.serializeContact(data.contactPersons[1], SERIALIZER_LAYOUT_CONTACT_PERSON)
                const contactData = [
                    {
                        title: 'Gastronomer',
                        data: contact
                    },
                    {
                        title: '1. Kontaktperson',
                        data: contactPerson0
                    },
                    {
                        title: '2. Kontaktperson',
                        data: contactPerson1
                    }
                ]

                document.title = genData.gastronomerName + ' Infoseite | MUPLAZA'

                setGenData(genData)
                setContactData(contactData)
                setIsFirstFetchSuccess(true)
            } else {
                document.title = 'Infoseite Gastronomer | MUPLAZA'
                setIsFirstFetchSuccess(false)
                setErrorMsg(queryData.errorMsg)
            }
        }
        func()
    }, [])
    
    return (
        <PrivatePage 
            arr_usertypes={[Usertypes.gastronomer, Usertypes.student]}
            b_isStudentFreeTier={true}
        >
            <PageBase
                str_pageSpacingClasses='no-width-restriction'
                str_pageContentBgColorClasses='page-bg-white'
                str_mainContentWrapperClasses='mc-wrapper info-page-wrapper'
                b_useLightTheme={true}
            >
                <DataStateCheckWrapper
                    b_hasQueryCheck={true}
                    b_isFirstFetchSuccess={isFirstFetchSuccess}
                    str_errorMsg={errorMsg}
                    b_isContentSection={true}
                    firstQueryDataEntry={1} /* Only placeholder value (data is always available here). */
                >
                    {
                        genData &&
                        <>
                            <CSecPageUpdateTimeInfo str_duration='4 h' />
                            <CSecTextOrImage
                                str_title={genData.gastronomerName}
                                str_imageURL={genData.logo}
                                str_classes='no-margin'
                            />
                            <CSecParagraphs
                                b_hasCenteredText={true}
                                str_classes='info-page-paragraphs-section'
                                arr_paragraphs={genData.slogan}
                            />
                            <CSecBase str_title='Getränkekarten' b_hasDarkDesign={true}>
                                <WordGrid
                                    arr_data={genData.drinkMenu}
                                    b_areItemsUrls={true}
                                />
                            </CSecBase>
                            <CSecBase str_title='Speisekarten' b_hasDarkDesign={true}>
                                <WordGrid
                                    arr_data={genData.foodMenu}
                                    b_areItemsUrls={true}
                                />
                            </CSecBase>
                            <CSecBase str_title='Unser Service'>
                                <IconGrid
                                    arr_iconData={ICON_GRID_DATA}
                                    arr_iconTitleAvailability={genData.consumeOptions}
                                />
                            </CSecBase>
                            <CSecParagraphs
                                str_title='Wir bieten auch'
                                str_classes='info-page-paragraphs-section'
                                arr_paragraphs={genData.specialOffer}
                            />
                            <CSecBase
                                str_title='Kontaktdaten'
                                str_classes='no-margin'
                            >
                                <CardGridInfoList arr_data={contactData} str_title='Kontaktdaten' />
                            </CSecBase>
                        </>
                    }
                </DataStateCheckWrapper>
            </PageBase>
        </PrivatePage>
    )
}

export default GastroProfileDetailPage
