import React from 'react';
import PropTypes from 'prop-types';

import TbRowIndustryOnlineJobs from './TbRowIndustryOnlineJobs';
import NoPPDataAvailable from '../../../profile-pages/NoPPDataAvailable';
import { useJobsData } from './IndustryPPJobsContextProvider';


const TbIndustryOnlineJobs = ({
    str_textAlignment='text-left',
    str_design='white'
}) => {
    const jobs = useJobsData()
    return (
        jobs.online && jobs.online[0] /* Key must be there and array must not be empty. */
        ?
        <div className='tb-wrapper'>
            <table cellSpacing="0" className={`tb-base ${str_textAlignment} ${str_design}`}>
                <thead>
                    <tr>
                        <td>Titel</td>
                        <td className="hide-max-w-576px">Gepostet</td>
                        <td className="hide-max-w-1024px">Start</td>
                        <td>Offline nehmen</td>
                        <td>Edit</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        jobs.online.map((item, index) => (
                            <TbRowIndustryOnlineJobs
                                key={index}
                                obj_rowData={item}
                            />
                        ))
                    }
                </tbody>
            </table>
        </div>
        :
        <NoPPDataAvailable str_message='Du hast keine Jobs online.' />
    )
}

TbIndustryOnlineJobs.propTypes = {
    str_textAlignment: PropTypes.string,
    str_design: PropTypes.string
}

export default TbIndustryOnlineJobs
