import React from 'react';
import { useParams } from 'react-router-dom';

import CSecBase from '../../components/content-sections/CSecBase';
import DynamicContentLoadContextProvider from '../../components/context-provider/DynamicContentLoadContextProvider';
import DiscussionContextProvider from '../../components/discussion-pages/context-provider/DiscussionContextProvider';
import Discussion from '../../components/discussion-pages/Discussion';
import FilterStdTextField from '../../components/filters/filters/std/FilterStdTextField';

import DynLoadPackageSizes from '../../util/config/data_load_package_sizes.json';
import DiscussionConfigs from '../../components/discussion-pages/DiscussionConfigs';

import { getFormData } from '../../components/input-forms/util/form-config-data/InputFormPanelDataConfigs';
import { genDynUrl } from '../../util/db_ls_query_handler';

const BASE_LIST_URL = '/api/courseforums/list/';
const BASE_POST_URL = '/api/courseforums/new-message/';
const MSG_VOTE_BASE_URL = '/api/courseforums/vote/'

const CourseForumPage = () => {
    const params = useParams()
    const listUrl = genDynUrl(BASE_LIST_URL, params.id)
    const postUrl = genDynUrl(BASE_POST_URL, params.id)
    const postPanelFormConfig = getFormData(
        DiscussionConfigs.CourseForumsDiscussion.formConfig,
        DiscussionConfigs.CourseForumsDiscussion.fileFieldConfig
    )
    const dynConfig = { str_queryURL: postUrl }
    return (
        <DynamicContentLoadContextProvider
            str_queryURL={listUrl}
            b_hasInfiniteScroll={true}
            b_useMsgCache={true}
            nbr_packageSize={DynLoadPackageSizes.courseforums.forumMsgs}
        >
            <CSecBase str_title='Forum' b_isUsedAsTitle={true} />
            <FilterStdTextField
                str_filterFieldName='Textsuche'
                str_classes='no-padding-top'
                b_isDiscussionContentSection={true}
                b_hasPadding={true}
                b_isTransparent={true}
            />
            <DiscussionContextProvider>
                <Discussion
                    obj_postPanelFormConfig={postPanelFormConfig}
                    obj_postPanelDynConfig={dynConfig}
                    str_msgVoteBaseUrl={MSG_VOTE_BASE_URL}
                    b_isMsgBodyHideable={false}
                    b_isMsgCommentable={false}
                    b_isMsgEditable={false}
                />
            </DiscussionContextProvider>
        </DynamicContentLoadContextProvider>
    )
}

export default CourseForumPage
