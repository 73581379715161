import React from 'react';
import PropTypes from 'prop-types';

import { string2Html } from '../util/user_input_handler';

const MessageStdBodyText = ({
    text, /* Can be number or text, thus no PropType. */
    b_hasTextMarking=true
}) => {
    return (
        text &&
        <div className='msg-text'>
            {
                b_hasTextMarking
                ?
                <p className='marked-text'
                    dangerouslySetInnerHTML={{ __html: string2Html(text) }}
                />
                :
                <p className='marked-text'>{text}</p>
            }
        </div>
    )
}

MessageStdBodyText.propTypes = {
    b_hasTextMarking: PropTypes.bool
}

export default MessageStdBodyText
