import React from 'react';

import FormBase from '../../../FormBase';
import InFieldsGroup from '../../../InFieldsGroup';
import TextField from '../../../fields/TextField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { GastroSpecialOffersFormData as FormData } from '../../../util/form-config-data/InputFormGastroDataConfigs';

const INFO_MSG = 'Hier hast du die Möglichkeit, deinen Betrieb zu beschreiben. ' +
    'Alle wissenswerten Dinge über deinen Betrieb können hier angegeben werden. ' +
    'Auch Informationen über die Infrastruktur wie z.B. die Möglichkeit Stammtische, ' +
    'Präsentationen etc. abzuhalten können hier angegeben werden.';

const InputFormSpecialOffers = () => {
    const data = useInputData()
    return (
        <FormBase
            str_title='Beschreibungen'
            arr_headerInfos={[INFO_MSG]}
        >
            <InFieldsGroup b_hasBorder={false}>
                <TextField
                    {...FormData.fields.specialOffer0}
                    str_chars={data[FormData.fields.specialOffer0.str_id][0]}
                />
                <TextField
                    {...FormData.fields.specialOffer1}
                    str_chars={data[FormData.fields.specialOffer1.str_id][0]}
                />
                <TextField
                    {...FormData.fields.specialOffer2}
                    str_chars={data[FormData.fields.specialOffer2.str_id][0]}
                />
                <TextField
                    {...FormData.fields.specialOffer3}
                    str_chars={data[FormData.fields.specialOffer3.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormSpecialOffers
