import React, { useState } from 'react';

import PrivatePage from '../../network/PrivatePage';
import DataStateCheckWrapper from '../../general/DataStateCheckWrapper';
import PPMainContentBase from '../PPMainContentBase';
import TbCourses from '../../tables/courses/TbCourses';

import Usertypes from '../../../util/Usertypes';
import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import useGetLSorDBdata from '../../../custom-hooks/useGetLSorDBdata';
import { PPLsKeys, StudentLsKeys } from '../../../util/LocalStorageVariables';

const PR_LS_KEY = PPLsKeys.primaryDataKey;
const SEC_LS_KEY = StudentLsKeys.pp.courses;

const URL = '/api/profiles/student/profile-page/courses/pinwall/';
const NOT_DATA_FOUND_MSG = 'Im Moment sind keine Kurse an der Pinnwand angeheftet.';

const StudentPPCourses = () => {

    const [data, setData] = useState([])
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    useSetBrowserTabTitle('Kurse', true)

    useGetLSorDBdata(
        URL,
        setData,
        setIsFirstFetchSuccess,
        setErrorMsg,
        true,
        null,
        null,
        PR_LS_KEY,
        SEC_LS_KEY
    )

    const removeCourseFromPinwall = (id) => {
        setData(data.filter(item => item.id !== id))
    }

    return (
        <PrivatePage arr_usertypes={[Usertypes.student]}>
            <PPMainContentBase
                str_header='Fixierte Kurse'
                str_subHeader='Pinnwand deiner fixierten Kurse'
            >
                <DataStateCheckWrapper
                    b_hasQueryCheck={true}
                    b_isFirstFetchSuccess={isFirstFetchSuccess}
                    b_
                    str_errorMsg={errorMsg}
                    str_noDataMsg={NOT_DATA_FOUND_MSG}
                    firstQueryDataEntry={data[0]}
                >
                    <TbCourses
                        arr_data={data}
                        fct_deleteEntryFromState={removeCourseFromPinwall}
                    />
                </DataStateCheckWrapper>
            </PPMainContentBase>
        </PrivatePage>
    )
}

export default StudentPPCourses
