import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import InputFormPageBase from '../../../components/input-forms/InputFormPageBase';
import InputFormCoursesTutoring from '../../../components/input-forms/forms/courses/InputFormCoursesTutoring';
import DataStateCheckWrapper from '../../../components/general/DataStateCheckWrapper';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';
import useConfigureDynInputFormData from '../../../custom-hooks/useConfigureDynInputFormData';
import { static2dynamicPanelConfig } from '../../../components/input-forms/util/input_form_data_handler';

import { CoursesTutoringFormData as FormData } from '../../../components/input-forms/util/form-config-data/InputFormCoursesDataConfigs';
import { FilterLsKeys } from '../../../util/LocalStorageVariables';
import { fetchLSdata, setLSdata } from '../../../util/db_ls_query_handler';

const DETAIL_BASE_URL = '/api/courses/tutoring/detail/'; /* Url to update an existing offer. */
const CREATE_URL = '/api/courses/tutoring/create/';      /* Url to create a new offer. */
const PR_LS_KEY = FilterLsKeys.courseTutorMyOffers;

const genDetailUrl = (id) => {
    return DETAIL_BASE_URL + id + '/';
}

const CourseTutoringFormPage = () => {
    
    const params = useParams()
    const [dynConfig, setDynConfig] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')
    
    const tutorOfferId = params.id

    useSetBrowserTabTitle('Tutorangebot erstellen')

    useConfigureDynInputFormData(
        tutorOfferId !== undefined,
        tutorOfferId ? genDetailUrl(tutorOfferId) : CREATE_URL,
        setDynConfig,
        setIsFirstFetchSuccess,
        setErrorMsg,
        tutorOfferId
    )

    const storeItem2ls = (response) => {
        /**
         * Stores newly posted course tutor offer to the LS.
         * If the LS entry does not yet exist, it creates a new LS entry.
         */
        const responseData = response.data
        const lsData = fetchLSdata(PR_LS_KEY)
        const newData = lsData ? [responseData, ...lsData] : [responseData]
        setLSdata(newData, PR_LS_KEY)
    }

    const editLsEntry = (response) => {
        /**
         * Updates the LS with the edited entry.
         * The updated data item is received from the backend.
         */
        const responseItem = response.data
        const responseItemId = responseItem.id
        const lsData = fetchLSdata(PR_LS_KEY)
        
        if (!lsData) {
            setLSdata([responseItem], PR_LS_KEY)
            return
        }

        let newData = []
        lsData.forEach(item => {
            if (item.id === responseItemId) {
                newData.push(responseItem)
            } else {
                newData.push(item)
            }
        })
        
        setLSdata(newData, PR_LS_KEY)
    }

    return (
        tutorOfferId
        ?
        /* Params in URL: Edit offer, make DB/LS request to prefill form. */
        <DataStateCheckWrapper
            b_hasQueryCheck={true}
            b_isFirstFetchSuccess={isFirstFetchSuccess}
            b_isContentSection={true}
            str_errorMsg={errorMsg}
            firstQueryDataEntry={-1} /* Placeholder value. */
        >
            <InputFormPageBase
                {...static2dynamicPanelConfig(FormData, dynConfig)}
                comp_form={<InputFormCoursesTutoring />}
                str_requestType='put'
                fct_handlePost={editLsEntry}
            />
        </DataStateCheckWrapper>
        :
        /* No params in URL, just render form. */
        <InputFormPageBase
            {...static2dynamicPanelConfig(FormData, dynConfig)}
            comp_form={<InputFormCoursesTutoring />}
            fct_handlePost={storeItem2ls}
        />
    )
}

export default CourseTutoringFormPage
