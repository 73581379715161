import React, { useState, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { MdAutorenew } from 'react-icons/md';
import { RiDeleteBack2Line } from 'react-icons/ri';

import { useHandleDelete } from './IndustryPPJobsContextProvider';
import { deleteDBdata } from '../../../../util/db_ls_query_handler';
import { string2URLstring } from '../../../../util/data_handler';
import { browserDateToGermanDateFormat } from '../../../../util/date_time_handler';

const DETAIL_BASE_URL = '/api/industry/job/detail/company-profile-page/';
const DELETE_ERROR_MSG = 'Der Job konnte nicht gelöscht werden! ' +
    'Bitte versuche es durch klicken noch einmal. ' +
    'Sollte derselbe Fehler danach wieder auftauchen, dann versuche es später wieder.';

const genDetailUrl = (jobId) => {
    return DETAIL_BASE_URL + jobId + '/';
}

const TbRowIndustryOfflineJobs = ({ obj_rowData }) => {
    
    const [isCollapsed, setIsCollapsed] = useState(false)
    const isDeleteLockedRef = useRef(false) /* Prevent multiple clicks on 'delete'. */
    const tableRowRef = useRef()

    const id = obj_rowData.id
    const handleDelete = useHandleDelete()
    const str_confirmQuestion = `Möchtest du folgende Stelle wirklich unwiderruflich löschen?\n${obj_rowData.title}`

    useMemo(() => {
        /**
         * If the input arg changes due to a delete event on a <tr>,
         * remove collapsed class and set height of prev. animation.
         * This happens as the different table data elements move up by
         * one item (the one that got deleted).
         */
        setIsCollapsed(false)
        if (tableRowRef.current) {
            tableRowRef.current.style.height = ''
        }
    }, [obj_rowData])

    const deleteJob = async () => {
        if (isDeleteLockedRef.current) return

        /* Check if user really intends to delete the position. */
        if (!window.confirm(str_confirmQuestion)) return

        isDeleteLockedRef.current = true

        const url = genDetailUrl(id)
        const queryData = await deleteDBdata(url)
        if (queryData.isQuerySuccess) {
            /* Animation. */
            /* Fix the height of <tr> so that removing the <td> does 
             * not collapse the <tr> before the animation. */
            const height = tableRowRef.current.clientHeight;
            tableRowRef.current.style.height = height + 'px';
            /* Run disappear animation by adding a class. */
            setIsCollapsed(true)
            /* Wait for animation to finish before context provider state is updated. */
            setTimeout(() => {
                handleDelete(id)
                isDeleteLockedRef.current = false
            }, 1500)
        } else {
            alert(DELETE_ERROR_MSG)
            isDeleteLockedRef.current = false
        }
    }

    return (
        <tr ref={tableRowRef} className={`${isCollapsed ? "tr-collapse-animation" : ""}`}>
            {
                !isCollapsed &&
                <>
                    <td>
                        <Link
                            to={`/industry/jobs/${string2URLstring(obj_rowData.companyName)}/${string2URLstring(obj_rowData.title)}/${obj_rowData.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="file-link"
                        >
                            {obj_rowData.title}
                        </Link>
                    </td>
                    <td className="hide-max-w-576px">{browserDateToGermanDateFormat(obj_rowData.launchDate)}</td>
                    <td className="hide-max-w-1024px">{browserDateToGermanDateFormat(obj_rowData.startDate)}</td>
                    <td>
                        <RiDeleteBack2Line onClick={() => deleteJob(id)} />
                    </td>
                    <td>
                        <Link to={`offline/job/${obj_rowData.id}/reuse`}>
                            <MdAutorenew />
                        </Link>
                    </td>
                </>
            }
        </tr>
    )
}

TbRowIndustryOfflineJobs.propTypes = {
    obj_rowData: PropTypes.object
}

export default TbRowIndustryOfflineJobs
