/**
 * This file contains all the configs for input form related to the 'industry' user. 
 */

import FieldLimits from '../db_field_limits.json';
import BottomTexts from '../input_form_bottom_info_texts.json';
import PlaceholderTexts from '../input_form_placeholder_texts.json';
import { TemplateFields } from './InputFormTemplateConfigs';
import { dateFromTodayAsBrowserString } from '../../../../util/date_time_handler';
import { genFieldData } from './InputFormBaseDataAndSerializer';

/* General data. */

export const MAX_NUM_ONLINE_DAYS = 120; /* days */

/* Forms. */

export const IndustryContactPersonFormData = {
    str_localStorageKey: 'IndustryContactPersonFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        firstName:    ['', true],
        lastName:     ['', true],
        jobPosition:  ['', true],
        workLocation: ['', true],
        phone:        ['', true],
        email:        ['', true],
        linkedin:     ['', false],
        twitter:      ['', false],
    },
    fields: {
        firstName   : TemplateFields.person.firstName,
        lastName    : TemplateFields.person.lastName,
        jobPosition : TemplateFields.work.jobPosition,
        workLocation: TemplateFields.work.workLocation,
        email       : TemplateFields.contact.email,
        phone       : TemplateFields.contact.phone,
        linkedin    : TemplateFields.socialMedia.linkedin,
        twitter     : TemplateFields.socialMedia.twitter
    }
}

export const IndustryNumbersFormData = {
    str_localStorageKey: 'IndustryNumbersFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        yearFounded:       [undefined, true],
        numEmployeesAut:   [undefined, true],
        numEmployeesWorld: [undefined, true],
        numOfficesAut:     [undefined, true],
        numOfficesWorld:   [undefined, true]
    },
    fields: {
        yearFounded: genFieldData('nbr', {
            str_id: 'yearFounded',
            str_fieldTitle: 'Gründungsjahr',
            nbr_minValue: FieldLimits.user.yearFounded.min,
            nbr_maxValue: FieldLimits.user.yearFounded.max,
            b_isYearField: true
        }),
        numEmployeesAut: genFieldData('nbr', {
            str_id: 'numEmployeesAut',
            str_fieldTitle: 'Mitarbeiter Österreich',
            nbr_minValue: FieldLimits.work.numEmployeesAut.min,
            nbr_maxValue: FieldLimits.work.numEmployeesAut.max
        }),
        numEmployeesWorld: genFieldData('nbr', {
            str_id: 'numEmployeesWorld',
            str_fieldTitle: 'Mitarbeiter weltweit',
            nbr_minValue: FieldLimits.work.numEmployeesWorld.min,
            nbr_maxValue: FieldLimits.work.numEmployeesWorld.max
        }),
        numOfficesAut: genFieldData('nbr', {
            str_id: 'numOfficesAut',
            str_fieldTitle: 'Büros Österreich',
            nbr_minValue: FieldLimits.work.numOfficesAut.min,
            nbr_maxValue: FieldLimits.work.numOfficesAut.max
        }),
        numOfficesWorld: genFieldData('nbr', {
            str_id: 'numOfficesWorld',
            str_fieldTitle: 'Büros weltweit',
            nbr_minValue: FieldLimits.work.numOfficesWorld.min,
            nbr_maxValue: FieldLimits.work.numOfficesWorld.max
        })
    }
};

export const IndustryEqualOpportunityEmployerData = {
    str_localStorageKey: 'EqualOpportunityEmployerData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        equalOpsMsg: ['', true]
    },
    fields: {
        equalOpsMsg: genFieldData('text', {
            str_id: 'equalOpsMsg',
            str_fieldTitle: 'Chancengleichheit',
            str_placeholder: PlaceholderTexts.work.equalOps,
            str_bottomInfoText: BottomTexts.work.equalOps,
            int_maxLength: FieldLimits.work.equalOpsMsg
        })
    }
};

export const IndustryDescriptionsFormData = {
    str_localStorageKey: 'IndustryDescriptionsFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        description0: ['', true ],
        description1: ['', false],
        description2: ['', false],
        description3: ['', false]
    },
    fields: {
        description0: genFieldData('text', {
            str_id: 'description0',
            str_fieldTitle: '1. Beschreibung',
            str_placeholder: PlaceholderTexts.description,
            int_maxLength: FieldLimits.user.company.description
        }),
        description1: genFieldData('text', {
            str_id: 'description1',
            str_fieldTitle: '2. Beschreibung',
            str_placeholder: PlaceholderTexts.description,
            int_maxLength: FieldLimits.user.company.description
        }),
        description2: genFieldData('text', {
            str_id: 'description2',
            str_fieldTitle: '3. Beschreibung',
            str_placeholder: PlaceholderTexts.description,
            int_maxLength: FieldLimits.user.company.description,
            b_isRequired: false
        }),
        description3: genFieldData('text', {
            str_id: 'description3',
            str_fieldTitle: '4. Beschreibung',
            str_placeholder: PlaceholderTexts.description,
            int_maxLength: FieldLimits.user.company.description,
            b_isRequired: false
        })
    }
};

export const IndustryBenefitsFormData = {
    str_localStorageKey: 'IndustryBenefitsFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        benefit0: ['', true],
        benefit1: ['', true],
        benefit2: ['', false],
        benefit3: ['', false]
    },
    fields: {
        benefit0: genFieldData('text', {
            str_id: 'benefit0',
            str_fieldTitle: '1. Vorteil',
            str_placeholder: PlaceholderTexts.work.benefit,
            int_maxLength: FieldLimits.user.company.benefit
        }),
        benefit1: genFieldData('text', {
            str_id: 'benefit1',
            str_fieldTitle: '2. Vorteil',
            str_placeholder: PlaceholderTexts.work.benefit,
            int_maxLength: FieldLimits.user.company.benefit
        }),
        benefit2: genFieldData('text', {
            str_id: 'benefit2',
            str_fieldTitle: '3. Vorteil',
            str_placeholder: PlaceholderTexts.work.benefit,
            b_isRequired: false,
            int_maxLength: FieldLimits.user.company.benefit
        }),
        benefit3: genFieldData('text', {
            str_id: 'benefit3',
            str_fieldTitle: '4. Vorteil',
            str_placeholder: PlaceholderTexts.work.benefit,
            b_isRequired: false,
            int_maxLength: FieldLimits.user.company.benefit
        })
    }
};

export const IndustryInternshipOptionsFormData = {
    str_localStorageKey: 'IndustryInternshipOptionsFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        areInternshipsOffered:    [false,     false],
        ishipMinLength:           [undefined, false],
        ishipMaxLength:           [undefined, false],
        ishipSeasons:             [[],        false],
        ishipThesisOptions:       [[],        false],
        ishipHasRemoteWorkOption: [false,     false],
        ishipIsPaid:              [false,     false]
    },
    fields: {
        areInternshipsOffered: genFieldData('b', {
            str_id: 'areInternshipsOffered',
            str_fieldTitle: 'Praktika',
            str_boolInfoText: 'Werden Praktika angeboten?',
            str_bottomInfoText: BottomTexts.work.internship.areInternshipsOffered
        }),
        ishipMinLength: genFieldData('nbr', {
            str_id: 'ishipMinLength',
            str_fieldTitle: 'Min. Dauer der Praktika (Monate)',
            nbr_minValue: FieldLimits.work.job.internship.length.min,
            nbr_maxValue: FieldLimits.work.job.internship.length.max,
            b_isRequired: false,
            str_bottomInfoText: BottomTexts.work.internship.noLengthOptionProvided
        }),
        ishipMaxLength: genFieldData('nbr', {
            str_id: 'ishipMaxLength',
            str_fieldTitle: 'Max. Dauer der Praktika (Monate)',
            nbr_minValue: FieldLimits.work.job.internship.length.min,
            nbr_maxValue: FieldLimits.work.job.internship.length.max,
            b_isRequired: false,
            str_bottomInfoText: BottomTexts.work.internship.noLengthOptionProvided
        }),
        ishipSeasons: genFieldData('mc', {
            str_id: 'ishipSeasons',
            str_fieldTitle: 'Zu welchen Jahreszeiten',
            arr_options : FieldLimits.work.job.internship.seasons,
            b_isRequired: false,
            str_bottomInfoText: BottomTexts.work.internship.seasons
        }),
        ishipThesisOptions: genFieldData('mc', {
            str_id: 'ishipThesisOptions',
            str_fieldTitle: 'Abschlussarbeiten',
            arr_options: FieldLimits.work.job.thesisOptions,
            b_isRequired: false
        }),
        ishipHasRemoteWorkOption: genFieldData('b', {
            str_id: 'ishipHasRemoteWorkOption',
            str_fieldTitle: 'Home Office',
            str_boolInfoText: 'Ist Home Office möglich?'
        }),
        ishipIsPaid: genFieldData('b', {
            str_id: 'ishipIsPaid',
            str_fieldTitle: 'bezahlung',
            str_boolInfoText: 'Werden Praktika bezahlt?'
        })
    }
};

export const IndustryJobFormData = {
    str_localStorage: 'IndustryJobFormData',
    str_queryURL: '',
    str_navigationURLonDiscard: '..',
    obj_initState: {
        postDeadlineDates: [['', ''], true],
        
        /* General. */
        positionUrl          : [''       , false],
        isInternship         : [false    , false],
        isPaid               : [false    , false],
        thesisOptions        : [[]       , false],
        startDate            : [''       , true ],
        isStartDateFlexible  : [false    , false],
        timeModel            : [''       , true ],
        duration             : [''       , true ],
        numCompletedSemesters: [undefined, false],
        focusArea            : [''       , true ],
        applicationDocs      : [[]       , true ],
        
        title:           ['',    true],
        intro:           ['',    true],
        
        responsibility0: ['',   false],
        responsibility1: ['',   false],
        responsibility2: ['',   false],
        responsibility3: ['',   false],
        
        reqSkill0:       ['',   false],
        reqSkill1:       ['',   false],
        reqSkill2:       ['',   false],
        reqSkill3:       ['',   false],
        
        assetSkill0:     ['',   false],
        assetSkill1:     ['',   false],
        assetSkill2:     ['',   false],
        assetSkill3:     ['',   false],
        
        addInfo:         ['',   false],

        jobFile:         [null, false],

        /* Location. */
        useCompanyLocationAsJobLocation: [false, false],
        country   :      ['',   true ],
        county    :      ['',   false],
        district  :      ['',   false],
        streetName:      ['',   true ],
        streetNum :      ['',   true ],
        postalCode:      ['',   true ],
        cityName  :      ['',   true ],

        /* Contact person. */
        useCompanyContactPersonAsJobContactPerson: [false, false],
        numContactPerson: ['1', false],
        firstName       : ['' , true ],
        lastName        : ['' , true ],
        jobPosition     : ['' , true ],
        workLocation    : ['' , true ],
        phone           : ['' , true ],
        email           : ['' , true ],
        linkedin        : ['' , false]
    },
    fields: {
        /* Post and deadline dates. */
        postDeadlineDates: genFieldData('dateSpan', {
            str_id: 'postDeadlineDates',
            str_startTitle: 'Online ab dem',
            str_endTitle: 'Online bis zum',
            str_startBottomInfoText: BottomTexts.work.job.maxDaysInAdvancePosting,
            str_endBottomInfoText: BottomTexts.work.job.maxDaysPostingOnline,
            str_startMinDate: dateFromTodayAsBrowserString(FieldLimits.work.job.launchDate.minDaysFromToday),
            str_startMaxDate: dateFromTodayAsBrowserString(FieldLimits.work.job.launchDate.maxDaysFromToday), /* Two weeks time span for planned jobs. */
            str_endMinDate: dateFromTodayAsBrowserString(FieldLimits.work.job.closingDate.minDaysFromToday),
            str_endMaxDate: dateFromTodayAsBrowserString(FieldLimits.work.job.closingDate.maxDaysFromToday)
        }),
        
        /* General. */
        positionUrl: genFieldData('text', {
            str_id: 'positionUrl',
            str_fieldTitle: 'Link zur Jobposition',
            str_placeholder: PlaceholderTexts.link,
            str_bottomInfoText: BottomTexts.work.job.emailApplication,
            b_isTextarea: false,
            b_isLink: true,
            b_isRequired: false,
            int_maxLength: FieldLimits.contact.url
        }),
        isInternship: genFieldData('b', {
            str_id: 'isInternship',
            str_fieldTitle: 'Praktikum',
            str_boolInfoText: 'Ist der Job eine Praktikum?',
            str_bottomInfoText: BottomTexts.work.job.internshipNote
        }),
        isPaid: genFieldData('b', {
            str_id: 'isPaid',
            str_fieldTitle: 'Bezahlung Praktikum',
            str_boolInfoText: 'Wird das Praktikum bezahlt?'
        }),
        thesisOptions: genFieldData('mc', {
            str_id: 'thesisOptions',
            str_fieldTitle: 'Abschlussarbeit',
            str_bottomInfoText: BottomTexts.work.job.hasThesisOption,
            b_isRequired: false,
            arr_options: FieldLimits.work.job.thesisOptions
        }),
        startDate: genFieldData('date', {
            str_id: 'startDate',
            str_fieldTitle: 'Frühester Beginn',
            str_bottomInfoText: BottomTexts.work.job.startDate,
            str_minDate: dateFromTodayAsBrowserString(FieldLimits.work.job.startDate.minDaysFromToday),
            str_maxDate: dateFromTodayAsBrowserString(FieldLimits.work.job.startDate.maxDaysFromToday)
        }),
        isStartDateFlexible: genFieldData('b', {
            str_id: 'isStartDateFlexible',
            str_boolInfoText: 'Ist das Startdatum flexibel?'
        }),
        timeModel: genFieldData('sc', {
            str_id: 'timeModel',
            str_fieldTitle: 'Arbeitszeitmodell',
            arr_options: FieldLimits.work.job.timeModels
        }),
        duration: genFieldData('sc', {
            str_id: 'duration',
            str_fieldTitle: 'Dauer',
            str_bottomInfoText: BottomTexts.work.job.duration,
            arr_options: FieldLimits.work.job.durations
        }),
        numCompletedSemesters: genFieldData('nbr', {
            str_id: 'numCompletedSemesters',
            str_fieldTitle: 'Abgeschlossene Semester',
            str_bottomInfoText: BottomTexts.work.job.numCompletedSemesters,
            nbr_minValue: FieldLimits.user.student.numCompletedSemesters.min,
            nbr_maxValue: FieldLimits.user.student.numCompletedSemesters.max,
            b_isRequired: false
        }),
        focusArea: genFieldData('sc', {
            str_id: 'focusArea',
            str_fieldTitle: 'Fachbereich',
            str_bottomInfoText: BottomTexts.work.job.focusArea,
            arr_options: FieldLimits.work.job.focusAreas
        }),
        applicationDocs: genFieldData('mc', {
            str_id: 'applicationDocs',
            str_fieldTitle: 'Bewerbungsunterlagen',
            str_bottomInfoText: BottomTexts.work.job.requiredApplicationDocs,
            nbr_heightInPixels: 126,
            arr_options: FieldLimits.work.job.applicationDocs
        }),

        /* Job infos. */
        title: genFieldData('text', {
            str_id: 'title',
            str_fieldTitle: 'Titel',
            b_isTextarea: false,
            int_maxLength: FieldLimits.work.job.title
        }),
        intro: genFieldData('text', {
            str_id: 'intro',
            str_fieldTitle: 'Kurzbeschreibung',
            int_maxLength: FieldLimits.work.job.intro
        }),
        
        responsibility0: genFieldData('text', {
            str_id: 'responsibility0',
            str_fieldTitle: 'Aufgabengebiete',
            str_placeholder: PlaceholderTexts.work.job.responsibility,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.responsibility
        }),
        responsibility1: genFieldData('text', {
            str_id: 'responsibility1',
            str_placeholder: PlaceholderTexts.work.job.responsibility,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.responsibility
        }),
        responsibility2: genFieldData('text', {
            str_id: 'responsibility2',
            str_placeholder: PlaceholderTexts.work.job.responsibility,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.responsibility
        }),
        responsibility3: genFieldData('text', {
            str_id: 'responsibility3',
            str_placeholder: PlaceholderTexts.work.job.responsibility,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.responsibility
        }),
        
        reqSkill0: genFieldData('text', {
            str_id: 'reqSkill0',
            str_fieldTitle: 'Erforderliche Qualifikationen',
            str_placeholder: PlaceholderTexts.work.job.qualification,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.reqSkill
        }),
        reqSkill1: genFieldData('text', {
            str_id: 'reqSkill1',
            str_placeholder: PlaceholderTexts.work.job.qualification,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.reqSkill
        }),
        reqSkill2: genFieldData('text', {
            str_id: 'reqSkill2',
            str_placeholder: PlaceholderTexts.work.job.qualification,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.reqSkill
        }),
        reqSkill3: genFieldData('text', {
            str_id: 'reqSkill3',
            str_placeholder: PlaceholderTexts.work.job.qualification,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.reqSkill
        }),
        
        assetSkill0: genFieldData('text', {
            str_id: 'assetSkill0',
            str_fieldTitle: 'Vorteilhafte Qualifikationen',
            str_placeholder: PlaceholderTexts.work.job.qualification,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.assetSkill
        }),
        assetSkill1: genFieldData('text', {
            str_id: 'assetSkill1',
            str_placeholder: PlaceholderTexts.work.job.qualification,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.assetSkill
        }),
        assetSkill2: genFieldData('text', {
            str_id: 'assetSkill2',
            str_placeholder: PlaceholderTexts.work.job.qualification,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.assetSkill
        }),
        assetSkill3: genFieldData('text', {
            str_id: 'assetSkill3',
            str_placeholder: PlaceholderTexts.work.job.qualification,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.assetSkill
        }),
        
        addInfo: genFieldData('text', {
            str_id: 'addInfo',
            str_fieldTitle: 'Zusatzinformationen',
            str_bottomInfoText: BottomTexts.work.job.addInfos,
            b_isRequired: false,
            int_maxLength: FieldLimits.work.job.addInfo
        }),
        
        jobFile: genFieldData('file', {
            str_id: 'jobFile',
            str_fieldTitle: 'Poster der Jobstelle',
            str_bottomInfoText: BottomTexts.work.job.postInfo,
            arr_allowedFileTypes: ['pdf'],
            b_isRequired: false
        }),

        /* Location. */
        useCompanyLocationAsJobLocation: genFieldData('b', {
            str_id: 'useCompanyLocationAsJobLocation',
            str_fieldTitle: 'Verwende Firmenstandort',
            str_boolInfoText: 'Soll der Firmenstandort als Jobstandort verwendet werden?'
        }),
        country   : TemplateFields.address.country,
        county    : genFieldData('sc', { str_id: 'county' }),
        district  : genFieldData('sc', { str_id: 'district' }),
        streetName: TemplateFields.address.streetName,
        streetNum : TemplateFields.address.streetNum,
        postalCode: TemplateFields.address.postalCode,
        cityName  : TemplateFields.address.cityName,
    
        /* Contact person. */
        useCompanyContactPersonAsJobContactPerson: genFieldData('b', {
            str_id: 'useCompanyContactPersonAsJobContactPerson',
            str_fieldTitle: 'Verwende Firmenkontaktperson',
            str_boolInfoText: 'Soll eine mit diesem Konto verknüpfte Kontakperson verwendet werden?'
        }),
        numContactPerson: genFieldData('sc', {
            str_id: 'numContactPerson',
            str_fieldTitle: 'Kontaktperson',
            str_bottomInfoText: BottomTexts.work.job.numContactPerson,
            arr_options: ['1', '2']
        }),
        firstName   : TemplateFields.person.firstName,
        lastName    : TemplateFields.person.lastName,
        jobPosition : TemplateFields.work.jobPosition,
        workLocation: TemplateFields.work.workLocation,
        phone       : TemplateFields.contact.phone,
        email       : TemplateFields.contact.email,
        linkedin    : TemplateFields.socialMedia.linkedin
    }
};