import React from 'react';
import { Outlet } from 'react-router-dom';

import PPCouponsContextProvider from '../context-provider/PPCouponsContextProvider';
import PPMcNavbar from '../../navbars/PPMcNavbar';

import useSetBrowserTabTitle from '../../../custom-hooks/useSetBrowserTabTitle';

const GastroPPCouponsPage = () => {
    useSetBrowserTabTitle('Gutscheine', true)
    return (
        <PPCouponsContextProvider>
            <PPMcNavbar str_dataKey='coupons' />
            <Outlet />
        </PPCouponsContextProvider>
    )
}

export default GastroPPCouponsPage
