import React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useSetModalOpenCloseContext } from './context-provider/ModalContextProvider';

const Modal = ({
    b_isSuccess,
    children
}) => {

    const setModalOpen = useSetModalOpenCloseContext()
    
    const handleModalClosing = (e) => {
        if (
            e.target.tagName === 'svg' ||  /* Click on closing cross. */
            e.target.tagName === 'path' || /* Click on closing cross. */
            e.target.classList.contains('modal-background')
        ) setModalOpen(false)
    }

    return (
        <div 
            className={`
                modal-background
                ${b_isSuccess ? 'modal-bg-success' : b_isSuccess === false ? 'modal-bg-fail' : ''}
            `}
            onClick={(e) => handleModalClosing(e)}
        >
            <div className="modal-container">
                {children}
            </div>
            <div className="modal-close-cross">
                <AiOutlinePlus />
            </div>
        </div>
    )
}

export default Modal
