/**
 * Entails all the data used by the gastro profile page code. 
 */

export const Messages = {
    dbQueryErrorMsg: 'Das Angebot konnte nicht offline genommen werden. Der Server konnte die Daten ' +
        'nicht verarbeiten. Bitte lade die Seite neu und versuche es noch einmal. Sollte es erneut nicht klappen, ' +
        'probiere es bitte später wieder.',
    stopRepeatingConfirmMsg: 'Möchtest du dieses wiederkehrende Angebot wirklich stoppen?\n'  + 
        'Das Angebot wird dadurch nicht mehr automatisch kreiert. Du findest dieses Angebot dann unter ' +
        'der Rubrik \'Alt\', wo es nach bedarf wiederverwendet werden kann.',
    deleteConfirmMsg: 'Möchtest du dieses Angebot wirklich unwiderruflich löschen?'
};
