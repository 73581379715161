import React from 'react'
import PropTypes from 'prop-types'

const CSecTextOrImage = ({
    str_title='',
    str_imageURL='',
    str_classes='',
}) => {
    return (
        <section className={`content-section title-or-img-section ${str_classes}`}>
            <div className="wrapper">
                {
                    str_imageURL
                    ?
                    <div className="img-wrapper">
                        <div className="img">
                            <img src={str_imageURL} alt="company logo" />
                        </div>
                        <div className="title">{str_title}</div>
                    </div>
                    :
                    <div className="title">{str_title}</div>
                }
            </div>
        </section>
    )
}

CSecTextOrImage.propTypes = {
    str_title: PropTypes.string,
    str_imageURL: PropTypes.string,
    str_classes: PropTypes.string
}

export default CSecTextOrImage
