import React from 'react';

import InFieldsGroup from '../../../InFieldsGroup';
import FormBase from '../../../FormBase';
import MultipleChoiceField from '../../../fields/MultipleChoiceField';
import BoolField from '../../../fields/BoolField';
import NumberField from '../../../fields/NumberField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { IndustryInternshipOptionsFormData as FormData } from '../../../util/form-config-data/InputFormIndustryDataConfigs';


const InputFormIndustryInternshipOptions = () => {
    const data = useInputData()
    return (
        <FormBase str_title='Praktikumsoptionen'>
            <InFieldsGroup b_hasBorder={false}>
                <BoolField
                    {...FormData.fields.areInternshipsOffered}
                    b_boolValue={data[FormData.fields.areInternshipsOffered.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.ishipMinLength}
                    arr_currentOptions={data[FormData.fields.ishipMinLength.str_id][0]}
                />
                <NumberField
                    {...FormData.fields.ishipMaxLength}
                    arr_currentOptions={data[FormData.fields.ishipMaxLength.str_id][0]}
                />
                <MultipleChoiceField
                    {...FormData.fields.ishipSeasons}
                    arr_currentOptions={data[FormData.fields.ishipSeasons.str_id][0]}
                />
                <MultipleChoiceField
                    {...FormData.fields.ishipThesisOptions}
                    arr_currentOptions={data[FormData.fields.ishipThesisOptions.str_id][0]}
                />
                <BoolField
                    {...FormData.fields.ishipHasRemoteWorkOption}
                    b_boolValue={data[FormData.fields.ishipHasRemoteWorkOption.str_id][0]}
                />
                <BoolField
                    {...FormData.fields.ishipIsPaid}
                    b_boolValue={data[FormData.fields.ishipIsPaid.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormIndustryInternshipOptions
