import React, { useState } from 'react';

import PrivatePage from '../../components/network/PrivatePage';
import DataStateCheckWrapper from '../../components/general/DataStateCheckWrapper';
import PageBase from '../../components/page-layout/PageBase';
import CSecBase from '../../components/content-sections/CSecBase';
import TbGastronomy from '../../components/tables/gastronomy/TbGastronomy';

import Usertypes from '../../util/Usertypes';
import useGetDBdata from '../../custom-hooks/useGetDBdata';
import useSetBrowserTabTitle from '../../custom-hooks/useSetBrowserTabTitle';

const URL = '/api/gastronomy/gastronomer/list/';

const GastroListingPage = () => {

    const [data, setData] = useState()
    const [isFirstFetchSuccess, setIsFirstFetchSuccess] = useState()
    const [errorMsg, setErrorMsg] = useState('')

    useSetBrowserTabTitle('Gastronome')
    useGetDBdata(URL, setData, setIsFirstFetchSuccess, setErrorMsg)

    return (
        <PrivatePage
            arr_usertypes={[Usertypes.gastronomer, Usertypes.student]}
            b_isStudentFreeTier={true}
        >
            <PageBase>
                <DataStateCheckWrapper
                    b_hasQueryCheck={true}
                    b_isFirstFetchSuccess={isFirstFetchSuccess}
                    firstQueryDataEntry={data ? data[0] : undefined}
                    str_errrorMsg={errorMsg}
                    b_isContentSection={true}
                >
                    <CSecBase
                        str_title='Gastronome'
                        str_subtitle='Unsere Partner aus der Leobener Gastronomie'
                    >
                        <TbGastronomy arr_data={data} />
                    </CSecBase>
                </DataStateCheckWrapper>
            </PageBase>
        </PrivatePage>
    )
}

export default GastroListingPage
