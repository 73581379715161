import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const InputFormHeader = ({
    str_title='',
    str_subtitle='',
    arr_headerInfos=[],
    b_isFilterForm=false,
    b_hasFileField=false,   /* User help info. */
    b_hasScreenshots=false, /* User help info. */
    b_hasPrivacyTerms=false /* User help info. */
}) => {
    return (
        <div className={`in-header ${b_isFilterForm ? 'filter-header' : ''}`}>
            <div className="in-titles">
                {
                    str_title &&
                    <div className="in-title">{str_title}</div>
                }
                {
                    str_subtitle &&
                    <div className="in-subtitle">{str_subtitle}</div>
                }
            </div>
            {/* Additional info about how to use this input form. */}
            <div className="in-add-info">
                {
                    arr_headerInfos[0] !== undefined &&
                    arr_headerInfos.map((item, index) => (
                        <p key={index}>{item}</p>
                    ))
                }
                {/* File upload information. */}
                {
                    b_hasFileField &&
                    <p id="in-file-info">
                        Wenn eine hochgeladene Datei zu groß ist oder nicht den 
                        richtigen Dateitypen hat, dann bietet die Webseite&nbsp;
                        <a className="page-link" href="https://smallpdf.com/" target="blank">
                            smallpdf.com
                        </a>
                        &nbsp;verschiedene Optionen die Größe zu komprimieren oder die Datei 
                        in einen anderen Typen umzuwandeln.
                    </p>
                }
                {
                    b_hasScreenshots &&
                    <p>
                        Wenn nur ein Ausschnitt eines Bildes hochgeladen werden soll, dann kann auf
                        Windows dafür das 'Snipping Tool' verwendet werden. Du findest es durch Eingabe
                        von 'snipping' im Suchbar der Taskleiste.
                    </p>
                }
                {
                    b_hasPrivacyTerms &&
                    <p>
                        <span>Die </span>
                        <Link className="page-link" to="/profile-set-up/legal-info/privacy-policy" target="_blank">
                            Datenschutzrichtlinie
                        </Link>
                        <span>, die </span>
                        <Link className="page-link" to="/profile-set-up/legal-info/terms-of-usage" target="_blank">
                            Nutzungsbedingungen
                        </Link>
                        <span> und den </span>
                        <Link className="page-link" to="/profile-set-up/legal-info/user-guide" target="_blank">
                            Userguide
                        </Link>
                        <span> findest du hier.</span>
                    </p>
                }
            </div>
        </div>
    )
}

InputFormHeader.propTypes = {
    str_title: PropTypes.string,
    str_subtitle: PropTypes.string,
    arr_headerInfos: PropTypes.array,
    b_isFilterForm: PropTypes.bool,
    b_hasFileField: PropTypes.bool,
    b_hasScreenshots: PropTypes.bool,
    b_hasPrivacyTerms: PropTypes.bool
}

export default InputFormHeader
