import React from 'react';
import { Link } from 'react-router-dom';

import Accordion from '../../components/accordion/Accordion';
import AccordionHeader from '../../components/accordion/AccordionHeader';
import AccordionParagraph from '../../components/accordion/AccordionParagraph';
import AccordionList from '../../components/accordion/AccordionList';
import AccordionListItem from '../../components/accordion/AccordionListItem';

const CompanyUserguide = () => {
    return (
        <>
            <Accordion str_title='Abonnement'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Welche Abonnements gibt es zur Auswahl?
                </AccordionHeader>
                <AccordionParagraph>
                    Auf MUPLAZA werden folgende Abos angeboten (inkl. USt):
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>Halbjahres-Abonnement (6-Monate-Abo) um 600.00 €;</AccordionListItem>
                    <AccordionListItem>Jahres-Abonnement (12-Monate-Abo) um 1080.00 €.</AccordionListItem>
                </AccordionList>
                <AccordionParagraph>
                    Diese Preise können durch Rabatte, die einem Unternehmen für mehrere aktive Nutzerkonten gewährt werden, abweichen (siehe dazu 
                    <span className='text-italic'> „Gibt es Rabatte für die Eröffnung mehrerer Nutzerkonten?“</span>
                    ).
                </AccordionParagraph>

                <AccordionHeader>
                    Wann kann ich mein Abo verlängern?
                </AccordionHeader>
                <AccordionParagraph>
                    Nach Ablauf deines letzten gültigen Abos wirst du auf der Plattform aufgefordert, dein Abo online zu verlängern. Diese entsprechende Nachricht siehst du nach dem Einloggen ohne gültiges Abo.
                </AccordionParagraph>

                <AccordionHeader>
                    Was passiert wenn mein Abo nicht mehr gültig ist?
                </AccordionHeader>
                <AccordionParagraph>
                    Wenn du dich ohne gültiges Abo einloggst, wirst du automatisch auf die Seite zur Aboverlängerung weitergeleitet. Alle anderen Bereiche der Plattform sind ohne gültiges Abo nicht mehr zugänglich.
                </AccordionParagraph>

                <AccordionHeader>
                    Brauche ich für jedes meiner Nutzerkonten ein neues Abo?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja. Jedes Benutzerkonto wird als eigenständiges Benutzerkonto betrachtet und daher ist für jedes Benutzerkonto ein Abo erforderlich.
                </AccordionParagraph>

                <AccordionHeader>
                    Wer bestimmt die Anzahl der Nutzerkonten für ein Unternehmen?
                </AccordionHeader>
                <AccordionParagraph isDirectylyBelowAccordionKey={true}>
                    Wie viele Nutzerkonten pro Unternehmen notwendig sind, wird unter anderem durch die Mitarbeiteranzahl, die Anzahl und der Größe der Standorte sowie Art und Umfang unterschiedlicher Unternehmensabteilungen bestimmt. Die genaue Anzahl der Nutzerkonten ist nach Rücksprache mit MUPLAZA e.U. individuell zu vereinbaren.
                </AccordionParagraph>
                <AccordionParagraph>
                    Gute Indikatoren für mehr als ein Nutzerkonto sind:
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        Abteilungen zur Herstellung verschiedener Produkte oder zur Bedienung verschiedener Industriezweige;
                    </AccordionListItem>
                    <AccordionListItem>
                        Standorte, die unterschiedliche Aufgabenbereiche abdecken (z.B. an einem Standort findet Forschung statt, an einem anderen Standort Produktion oder Rohstoffgewinnung);
                    </AccordionListItem>
                    <AccordionListItem>
                        Zuständigkeitsbereiche der Recruiter/Personaler (Recruiter arbeiten oft mit verschiedenen Teams im Unternehmen zusammen; wenn eine zu besetzende Stelle nicht in den Zuständigkeitsbereich eines Recruiters fällt, sondern von jemand anderem bearbeitet wird, kann dies ein guter Hinweis für die Einrichtung eines weiteren Nutzerkontos sein);
                    </AccordionListItem>
                    <AccordionListItem>
                        der Wunsch nach einer aufschlussreichen Trennung von Informationen. Die Nutzerkonten werden mit separaten Benutzernamen angelegt, die bereits für den Verantwortungsbereich stehen können (z.B. xyzforschung, xyzproduktion, xyzsales, xyzbusiness) und somit mehr Aufschluss darüber geben, welche Inhalte sich dahinter verbergen. Darüber hinaus kann die MUPLAZA-eigene Seite zur Unternehmenspräsentation spezifisch für die verschiedenen Nutzerkonten eines Unternehmens angepasst werden, was insbesondere bei den Kontaktdaten von Vorteil sein kann.
                    </AccordionListItem>
                </AccordionList>

                <AccordionHeader>
                    Gibt es Rabatte für die Eröffnung mehrerer Nutzerkonten?
                </AccordionHeader>
                <AccordionParagraph isDirectylyBelowAccordionKey={true}>
                    Ja, ein Unternehmen erhält Preisvorteile, sobald es mehr als ein Nutzerkonto eröffnet. Die Preisnachlässe gelten sowohl für die Eröffnung eines neuen Nutzerkontos als auch für die künftige Verlängerung von Abonnements bestehender Nutzerkonten. Beim Kauf eines neuen Abonnements richtet sich der Preisnachlass nach der Anzahl der zu diesem Zeitpunkt aktiven Nutzerkonten des Unternehmens. Besitzt ein Unternehmen z.B. 5 Nutzerkonten und sind zum Zeitpunkt der Verlängerung eines dieser Nutzerkonten 2 Nutzerkonten aktiv und 2 Nutzerkonten inaktiv, so werden die beiden aktiven Nutzerkonten für die Preisreduktion herangezogen. Das Unternehmen profitiert also von einer höheren Aktivität auf der Plattform.
                </AccordionParagraph>
                <AccordionParagraph>
                    Folgende Preisnachlässe gelten für neu erworbene Abonnements (Ersterwerb oder Verlängerung) (Alle Preise inkl. USt; JA: Jahresabonnement, HA: Halbjahresabonnement.):
                </AccordionParagraph>
                <AccordionList>
                    <AccordionListItem>
                        0 Aktive Konten: 0 % Rabatt; Preis JA: 1080.00 EUR; Preis HA: 600.00 EUR
                    </AccordionListItem>
                    <AccordionListItem>
                        1 Aktives Konto: 10 % Rabatt; Preis JA: 972.00 EUR; Preis HA: 540.00 EUR
                    </AccordionListItem>
                    <AccordionListItem>
                        2 Aktive Konten: 15 % Rabatt; Preis JA: 918.00 EUR; Preis HA: 510.00 EUR
                    </AccordionListItem>
                    <AccordionListItem>
                        3 - 5 Aktive Konten: 25 % Rabatt; Preis JA: 810.00 EUR; Preis HA: 450.00 EUR
                    </AccordionListItem>
                    <AccordionListItem>
                        6 - 8 Aktive Konten: 40 % Rabatt; Preis JA: 648.00 EUR; Preis HA: 360.00 EUR
                    </AccordionListItem>
                </AccordionList>
                <AccordionParagraph>
                    Die Preise für mehr als 8 Nutzerkonten sind individuell mit MUPLAZA e.U. zu vereinbaren.
                </AccordionParagraph>
                <AccordionParagraph>
                    Hinweis: Da die Rabatte nur für aktive Nutzerkonten berechnet werden, müssen die Abonnements nacheinander gekauft werden. Dies ist in der Regel der Fall, da bereits wenige Millisekunden als Differenz zwischen den Käufen ausreichen. Es ist jedoch zu beachten, dass bei gleichzeitigem Zugriff mehrerer Nutzerkonten auf die Aboverlängerungsseite keine Rabatte berücksichtigt werden. Warum? Beispiel: Ein Unternehmen besitzt 2 Nutzerkonten. Pro Nutzerkonto wird gleichzeitig ein Abonnement abgeschlossen. Das 2. Nutzerkonto ist zu diesem Zeitpunkt für das 1. inaktiv. Das 1. Nutzerkonto ist für das 2. Nutzerkonto ebenfalls inaktiv. Die beiden Konten wissen also nicht, dass sie demnächst gleichzeitig aktiv werden. D.h. es werden keine Rabatte gewährt. Wird nun nach den ersten beiden Nutzerkonten ein 3. Nutzerkonto abgeschlossen, so erhält dieses den Rabatt für 2 aktive Nutzerkonten. Es gilt immer die Anzahl der aktiven Nutzerkonten. MUPLAZA e.U. übernimmt keine Kostenersparnis, die dem Unternehmen auf diese Weise entgeht.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie viele Personen dürfen MUPLAZA mit demselben Nutzerkonto verwenden?
                </AccordionHeader>
                <AccordionParagraph>
                    Wie unter
                    <span className='text-italic'>„Wer bestimmt die Anzahl der Nutzerkonten für ein Unternehmen?“</span>
                    beschrieben, spielen bei der Verteilung der Nutzerkonten für ein Unternehmen verschiedene Faktoren eine Rolle. Mitarbeiter des Unternehmens, die aufgrund ihrer Position, ihres Standorts und ihrer Tätigkeit zu einem dieser Nutzerkonten gehören, sind berechtigt, dieses Nutzerkonto zu verwenden. Es ist also möglich, dass ein Nutzerkonto von mehreren Mitarbeitern des Unternehmens verwendet wird. Aus Sicherheitsgründen wird jedoch empfohlen, den Zugriff auf die einzelnen Nutzerkonten auf möglichst wenige Personen zu beschränken.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Inhalte'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Welche Inhalte kann ich auf MUPLAZA posten?
                </AccordionHeader>
                <AccordionParagraph>
                    Auf MUPLAZA können allgemeine Informationen über dein Unternehmen, Beschreibungen, Events, Kontaktdaten und Jobstellen gepostet werden.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie viel Freiheit habe ich bei der Erstellung meiner Inhalte?
                </AccordionHeader>
                <AccordionParagraph>
                Grundsätzlich steht es jedem Unternehmen frei welche Informationen es auf der Plattform online stellt. Einschränkungen in Art und Anzahl bestimmter Posts können in dieser Nutzeranleitung und auf der Plattform gefunden werden. Weiters dürfen die Posts nicht gegen die Nutzungsbedingungen verstoßen oder sonstige vertragliche Vereinbarungen verstoßen.
                </AccordionParagraph>

                <AccordionHeader>
                    Wer sieht meine Inhalte?
                </AccordionHeader>
                <AccordionParagraph>
                    Alle auf der Plattform angemeldeten Studierenden der Montanuniversität Leoben. Es ist also möglich mehr als 4000 Studierende auf der Plattform zu erreichen.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Jobstellen'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Wie viele Jobstellen kann ich gleichzeitig ausschreiben?
                </AccordionHeader>
                <AccordionParagraph>
                    Ein Unternehmen kann maximal 12 Stellenangebote gleichzeitig veröffentlichen. Als ausgeschriebene Stellen gelten sowohl Stellen, die bereits auf der Plattform öffentlich sichtbar sind, als auch Stellen, die für die Zukunft geplant sind und demnächst automatisch online gestellt werden (geplante Stellen).
                </AccordionParagraph>

                <AccordionHeader>
                    Wie lange kann eine Jobstelle maximal online sein?
                </AccordionHeader>
                <AccordionParagraph>
                    Ein Stellenangebot kann maximal 120 Tage in Folge online sein. Danach ist es nur noch in deinem Benutzerkonto unter der Rubrik „Offline Jobs“ sichtbar. Du kannst das Stellenangebot erneut veröffentlichen (siehe
                    <span className='text-italic'> "Kann ich ein altes Stellenangebot wiederverwenden?“</span>).
                </AccordionParagraph>

                <AccordionHeader>
                    Wie viele Tage im Voraus kann ein Stellenangebot erstellt werden, bevor es online geht?
                </AccordionHeader>
                <AccordionParagraph>
                    Der Planungszeitraum für Jobstellen beträgt 14 Tage. Beispiel: Wenn du an einem Montag eine Jobstelle erstellst, kannst du das Datum, ab dem die Stelle auf der Plattform für andere Benutzer sichtbar ist, auf einen beliebigen Tag der laufenden oder der kommenden Woche einstellen.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie viele alte Jobstellen kann ich maximal speichern?
                </AccordionHeader>
                <AccordionParagraph>
                    Pro Benutzerkonto können maximal 20 alte Stellenangebote gespeichert werden. Alte Stellenangebote bezeichnen hierbei Stellenangebote, die du entweder manuell offline genommen hast oder deren letzter Ausschreibungstag in der Vergangenheit liegt.
                </AccordionParagraph>

                <AccordionHeader>
                    Kann eine Jobstelle bearbeitet werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja. Stellenangebote können grundsätzlich bearbeitet werden. Dabei ist zu beachten, dass nur Stellen bearbeitet werden können, die online oder geplant sind. Alte Stellen können nicht mehr bearbeitet werden, bieten jedoch die Möglichkeit, als neue Stelle wieder gepostet zu werden, wobei die eingegebenen Daten angepasst werden können (siehe
                    <span className='text-italic'> „Kann eine alte Stelle wieder verwendet werden?“</span>).
                </AccordionParagraph>
                <AccordionParagraph>
                    Bei Stellen, die bereits online sind, können die Daten des ersten und des letzten Tages, an dem die Stelle online ist, nicht geändert werden. Bei geplanten Stellenangeboten können alle Angaben geändert werden. Wenn du eine Stelle offline nehmen möchtest, siehe
                    <span className='text-italic'> „Kann eine Stelle offline genommen werden?“</span>.
                </AccordionParagraph>

                <AccordionHeader>
                    Kann eine Stelle offline genommen werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja. Ein Stellenangebot kann jederzeit offline genommen werden. Wähle dazu in der Seitenleiste „Jobs“ und gehe zur Rubrik „Online Jobs.“ In der angezeigten Tabelle gibt es eine Spalte, in der Stellen offline genommen werden können. Klicke auf das Symbol dieser Spalte und der jeweiligen Stelle, um das Stellenangebot offline zu nehmen. Nachdem die Stelle offline genommen wurde, ist sie auf derselben Seite unter der Rubrik „Offline Jobs“ zu finden.
                </AccordionParagraph>

                <AccordionHeader>
                    Kann eine alte Jobstelle wiederverwendet werden?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja. Es ist möglich, ein altes Stellenangebot erneut zu veröffentlichen oder als Vorlage für andere Stellenangebote zu verwenden. Wähle dazu in der Seitenleiste „Jobs“ aus und gehe dann in die Kategorie „Offline Jobs“. In der angezeigten Tabelle ist jedes aufgelistete Stellenangebot mit einem Pfeilsymbol versehen. Klicke auf dieses Pfeilsymbol, um das Stellenangebot als Vorlage zu verwenden. Du wirst dann zur Eingabemaske für eine neue Stelle weitergeleitet, in der alle relevanten Felder bereits ausgefüllt sind. Hinweis: Die erstellte Stelle ist eine völlig neue Stelle und hat keine Verbindung zur verwendeten Vorlage. Die verwendete Vorlage bzw. der alte Job bleibt unter „Offline Jobs“ gespeichert und geht nicht verloren.
                </AccordionParagraph>

                <AccordionHeader>
                    Wer kann meine Jobstellen sehen?
                </AccordionHeader>
                <AccordionParagraph>
                    Online Jobs können von allen Nutzern der Nutzergruppen Studierende und Industrie jederzeit auf dem Stellenportal eingesehen werden.
                </AccordionParagraph>
                <AccordionParagraph>
                    Geplante Jobs und Offline Jobs können nur vom Autor des Stellenangebots, d.h. vom Unternehmen, das die Jobstelle erstellt hat, eingesehen werden.
                </AccordionParagraph>

                <AccordionHeader>
                    Muss ich Praktika anbieten?
                </AccordionHeader>
                <AccordionParagraph>
                    Nein. Es steht jedem Unternehmen frei, welche Stellenangebote es anbietet. Hierbei gibt es, bis auf die in diesem Userguide genannten Maximalwerte, keine Einschränkungen.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Firmenkonten'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Kann ein Unternehmen mehrere Benutzerkonten besitzen?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja, das ist ohne weiteres möglich. Ein Unternehmen kann z.B. für verschiedene Standorte oder Abteilungen jeweils ein Benutzerkonto erstellen.
                </AccordionParagraph>

                <AccordionHeader>
                    Kann ich die anderen Konten meines Unternehmens auf der Plattform sehen?
                </AccordionHeader>
                <AccordionParagraph>
                    Ja. Gehe hierfür in der Seitenleiste auf “Weitere Accounts.” Daraufhin werden auf derselben Seite alle registrierten Benutzerkonten deines Unternehmens angezeigt.
                </AccordionParagraph>

                <AccordionHeader>
                    Wie erfolgt die Verknüpfung zwischen den verschiedenen Firmenkonten?
                </AccordionHeader>
                <AccordionParagraph>
                    Um ein neues Konto zu den anderen Konten des Unternehmens hinzuzufügen, muss bei der Registrierung der Benutzername eines anderen Benutzerkontos des	 Unternehmens angegeben werden. Wenn das Unternehmen bereits über mehrere Konten verfügt, ist es unerheblich, welcher Benutzername dieser Konten angegeben wird.
                </AccordionParagraph>

                <AccordionHeader>
                    Kann ich an einem anderen Benutzerkonto des Unternehmens Änderungen vornehmen?
                </AccordionHeader>
                <AccordionParagraph>
                    Nein. Änderungen können nur an dem Benutzerkonto vorgenommen werden, mit dem man angemeldet ist. Es ist also nötig die Zugangsdaten für ein Konto zu besitzen, um Änderungen vornehmen zu können. Der Grund dafür ist die Sicherheit der Benutzerkonten. Sollten die Zugangsdaten für ein Konto in die falschen Hände geraten, ist nur dieses Konto betroffen.
                </AccordionParagraph>
            </Accordion>

            <Accordion str_title='Sponsoring'>
                <AccordionHeader isDirectylyBelowAccordionKey={true}>
                    Kann ich MUPLAZA durch Sponsoring weiterhelfen?
                </AccordionHeader>
                <AccordionParagraph>
                    <span>Ja, bitte kontaktiere uns dazu über unser </span>
                    <Link to='../../contact/general' target='_blank' className='page-link'>Kontaktformular</Link>.
                </AccordionParagraph>
            </Accordion>
        </>
    )
}

export default CompanyUserguide
