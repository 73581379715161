import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../fields/TextField';
import CountryCountyDistrictFields from '../fields/CountryCountyDistrictFields';
import { useInputData } from '../context-provider/InputFormContextProvider';

const LocationFormComponent = ({
    obj_fields,
    b_hasCountry=true,
    b_hasCountyDistrictOptions=false,
    b_areFieldsDisplayed=true,
}) => {
    /**
     * :Input
     *  obj_fields: Stores the key/value pairs of the fields configuration
     *              of InputFormConfgs.
     */
    const data = useInputData()
    return (
        b_areFieldsDisplayed &&
        <>
            {
                b_hasCountry 
                ?
                    b_hasCountyDistrictOptions
                    ?
                    <CountryCountyDistrictFields
                        str_countryFieldId={obj_fields.country.str_id}
                        str_countyFieldId={obj_fields.county.str_id}
                        str_districtFieldId={obj_fields.district.str_id}
                        str_countryValue={data[obj_fields.country.str_id][0]}
                        str_currentCountyOption={data[obj_fields.county.str_id][0]}
                        str_currentDistrictOption={data[obj_fields.district.str_id][0]}
                    />
                    :
                    <TextField
                        {...obj_fields.country}
                        str_chars={data[obj_fields.country.str_id][0]}
                    />
                :
                ''
            }
            <TextField
                {...obj_fields.streetName}
                str_chars={data[obj_fields.streetName.str_id][0]}
            />
            <TextField
                {...obj_fields.streetNum}
                str_chars={data[obj_fields.streetNum.str_id][0]}
            />
            <TextField
                {...obj_fields.postalCode}
                str_chars={data[obj_fields.postalCode.str_id][0]}
            />
            <TextField
                {...obj_fields.cityName}
                str_chars={data[obj_fields.cityName.str_id][0]}
            />
        </>
    )
}

LocationFormComponent.propTypes = {
    obj_fields: PropTypes.object,
    b_hasCountry: PropTypes.bool,
    b_hasCountyDistrictOptions: PropTypes.bool,
    b_areFieldsDisplayed: PropTypes.bool
}

export default LocationFormComponent
