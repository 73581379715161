import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { string2URLstring } from '../../../util/data_handler';

const TbGastronomy = ({
    arr_data,
    str_textAlignment='text-left',
    str_design=''
}) => {
    return (
        arr_data &&
        <div className="tb-wrapper">
            <table cellSpacing={0}  className={`tb-base ${str_textAlignment} ${str_design}`}>
                <thead>
                    <tr>
                        <td>Restaurant</td>
                        <td className="hide-max-w-768px">Adresse</td>
                        <td className="text-center">Angebote Semester</td>
                        <td className="text-center">Angebote Gesamt</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        arr_data.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <Link
                                        to={`/gastronomer/info-page/${string2URLstring(item.name)}/${item.id}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="file-link"
                                    >
                                        {item.name}
                                    </Link>
                                </td>
                                <td className="hide-max-w-768px">{item.address}</td>
                                <td className="text-center">{item.numFoodOffersCurrentSemester}</td>
                                <td className="text-center">{item.numFoodOffersTotal}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

TbGastronomy.propTypes = {
    arr_data: PropTypes.array,
    str_textAlignment: PropTypes.string,
    str_design: PropTypes.string
}

export default TbGastronomy
