import React from 'react';

import FormBase from '../../../FormBase';
import InFieldsGroup from '../../../InFieldsGroup';
import FileField from '../../../fields/FileField';

import { useInputData } from '../../../context-provider/InputFormContextProvider';
import { LogoFormData as FormData } from '../../../util/form-config-data/InputFormAccountSettingsDataConfigs';

const InputFormLogo = () => {
    const data = useInputData()
    return (
        <FormBase str_title='Logo'>
            <InFieldsGroup b_hasBorder={false}>
                <FileField
                    {...FormData.fields.logo}
                    inFile={data[FormData.fields.logo.str_id][0]}
                />
            </InFieldsGroup>
        </FormBase>
    )
}

export default InputFormLogo
